import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconSettings04 = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 16"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M1 4L13 4M13 4C13 5.65686 14.3431 7 16 7C17.6569 7 19 5.65685 19 4C19 2.34315 17.6569 1 16 1C14.3431 1 13 2.34315 13 4ZM7 12L19 12M7 12C7 13.6569 5.65685 15 4 15C2.34315 15 1 13.6569 1 12C1 10.3431 2.34315 9 4 9C5.65685 9 7 10.3431 7 12Z"
      stroke="currentColor"
      strokeWidth={props.stroke ?? '1.4'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
