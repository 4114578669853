import { Box, Stack } from '@mui/material'

export const CircleDivider = ({
  children,
  color,
}: {
  children: React.ReactNode
  color?: string
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0.5}
      divider={
        <Box
          sx={{
            width: 4,
            height: 4,
            bgcolor: color ?? 'mischka',
            borderRadius: '50%',
          }}
        />
      }
    >
      {children}
    </Stack>
  )
}
