import { createListSearchParams, ListPagination } from 'api/list-params'
import { pipe } from 'fp-ts/function'
import { decodeJson, decodeJsonWithTotal, get, post } from 'lib/request'

import { JobId } from '../jobs.api'
import {
  TPublishedCompany,
  TPublishedJob,
  TPublishedJobShort,
} from './published-jobs.codecs'

export type GetPublishedCompanyInput = {
  companySlug: string
}

export const getPublishedCompany = async ({
  companySlug,
}: GetPublishedCompanyInput) => {
  return pipe(
    await get('api/careers/companies/:companySlug', {
      params: { companySlug },
    }),
    decodeJson(TPublishedCompany),
  )
}

export type GetPublishedJobsListInput = {
  companySlug: string
  pagination: ListPagination
}

export const getPublishedJobsList = async ({
  companySlug,
  pagination,
}: GetPublishedJobsListInput) => {
  const params = createListSearchParams({ pagination })

  return pipe(
    await get('api/careers/companies/:companySlug/jobs', {
      params: { companySlug },
      query: params,
    }),
    decodeJsonWithTotal(TPublishedJobShort, pagination),
  )
}

export type GetPublishedJobInput = JobId & {
  companySlug: string
  positionSlug: string
}

export const getPublishedJob = async ({
  jobId,
  companySlug,
  positionSlug,
}: GetPublishedJobInput) => {
  return pipe(
    await get('api/careers/companies/:companySlug/jobs/:positionSlug/:jobId', {
      params: { jobId, companySlug, positionSlug },
    }),
    decodeJson(TPublishedJob),
  )
}

type ApplyContactToPublicJobInput = JobId & {
  body: {
    name: string
    countryCode: string
    cityName: string
    phone: string
    email: string
    file: File
  }
}

export const applyContactToPublicJob = async ({
  jobId,
  body,
}: ApplyContactToPublicJobInput) => {
  return await post('api/careers/companies/jobs/:jobId/contacts', {
    params: { jobId },
    body,
    type: 'file',
  })
}
