import SvgIcon from '@mui/material/SvgIcon'

import { FilledIcon } from '../icon-props'

export const IconTalentsPool = ({ filled, ...props }: FilledIcon) =>
  filled ? (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_5120_57785)">
        <path
          d="M0 13C0 13.3978 0.158035 13.7794 0.43934 14.0607C0.720644 14.342 1.10218 14.5 1.5 14.5H14.5C14.8978 14.5 15.2794 14.342 15.5607 14.0607C15.842 13.7794 16 13.3978 16 13V6C16 5.60218 15.842 5.22064 15.5607 4.93934C15.2794 4.65804 14.8978 4.5 14.5 4.5H1.5C1.10218 4.5 0.720644 4.65804 0.43934 4.93934C0.158035 5.22064 0 5.60218 0 6L0 13ZM2 3C2 3.13261 2.05268 3.25979 2.14645 3.35355C2.24021 3.44732 2.36739 3.5 2.5 3.5H13.5C13.6326 3.5 13.7598 3.44732 13.8536 3.35355C13.9473 3.25979 14 3.13261 14 3C14 2.86739 13.9473 2.74021 13.8536 2.64645C13.7598 2.55268 13.6326 2.5 13.5 2.5H2.5C2.36739 2.5 2.24021 2.55268 2.14645 2.64645C2.05268 2.74021 2 2.86739 2 3ZM4 1C4 1.13261 4.05268 1.25979 4.14645 1.35355C4.24021 1.44732 4.36739 1.5 4.5 1.5H11.5C11.6326 1.5 11.7598 1.44732 11.8536 1.35355C11.9473 1.25979 12 1.13261 12 1C12 0.867392 11.9473 0.740215 11.8536 0.646447C11.7598 0.552678 11.6326 0.5 11.5 0.5H4.5C4.36739 0.5 4.24021 0.552678 4.14645 0.646447C4.05268 0.740215 4 0.867392 4 1Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5120_57785">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  ) : (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <g clipPath="url(#clip0_5150_57813)">
        <path
          d="M2.4082 3.8125C2.29217 3.8125 2.18089 3.76641 2.09884 3.68436C2.0168 3.60231 1.9707 3.49103 1.9707 3.375C1.9707 3.25897 2.0168 3.14769 2.09884 3.06564C2.18089 2.98359 2.29217 2.9375 2.4082 2.9375H12.0332C12.1492 2.9375 12.2605 2.98359 12.3426 3.06564C12.4246 3.14769 12.4707 3.25897 12.4707 3.375C12.4707 3.49103 12.4246 3.60231 12.3426 3.68436C12.2605 3.76641 12.1492 3.8125 12.0332 3.8125H2.4082ZM4.1582 2.0625C4.04217 2.0625 3.93089 2.01641 3.84884 1.93436C3.7668 1.85231 3.7207 1.74103 3.7207 1.625C3.7207 1.50897 3.7668 1.39769 3.84884 1.31564C3.93089 1.23359 4.04217 1.1875 4.1582 1.1875H10.2832C10.3992 1.1875 10.5105 1.23359 10.5926 1.31564C10.6746 1.39769 10.7207 1.50897 10.7207 1.625C10.7207 1.74103 10.6746 1.85231 10.5926 1.93436C10.5105 2.01641 10.3992 2.0625 10.2832 2.0625H4.1582ZM0.220703 12.125C0.220703 12.4731 0.358984 12.8069 0.605125 13.0531C0.851267 13.2992 1.18511 13.4375 1.5332 13.4375H12.9082C13.2563 13.4375 13.5901 13.2992 13.8363 13.0531C14.0824 12.8069 14.2207 12.4731 14.2207 12.125V6C14.2207 5.6519 14.0824 5.31806 13.8363 5.07192C13.5901 4.82578 13.2563 4.6875 12.9082 4.6875H1.5332C1.18511 4.6875 0.851267 4.82578 0.605125 5.07192C0.358984 5.31806 0.220703 5.6519 0.220703 6L0.220703 12.125ZM1.5332 12.5625C1.41717 12.5625 1.30589 12.5164 1.22384 12.4344C1.1418 12.3523 1.0957 12.241 1.0957 12.125V6C1.0957 5.88397 1.1418 5.77269 1.22384 5.69064C1.30589 5.60859 1.41717 5.5625 1.5332 5.5625H12.9082C13.0242 5.5625 13.1355 5.60859 13.2176 5.69064C13.2996 5.77269 13.3457 5.88397 13.3457 6V12.125C13.3457 12.241 13.2996 12.3523 13.2176 12.4344C13.1355 12.5164 13.0242 12.5625 12.9082 12.5625H1.5332Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5150_57813">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.220703 0.75)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  )
