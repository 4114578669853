import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { qk, searchGlobally } from 'api'
import { paths } from 'app/routes'
import loupeImage from 'assets/images/loupe.png'
import { ContactProfile } from 'components/contacts'
import { JobStatus } from 'components/jobs'
import { ErrorTemplateSmall } from 'components/templates'
import { renderQueryResult } from 'lib/react-query-utils'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { Link } from 'ui/navigation'

import { CategoryResults } from './category-results'

const NoDataContainer = styled(Box)({
  height: 320,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

type Props = Readonly<{
  searchValue: string
  onClose: () => void
  refetchHistory: () => void
}>

export const GlobalSearchResult = ({
  searchValue,
  onClose,
  refetchHistory,
}: Props) => {
  const { t } = useTranslation()

  const $search = useQuery(
    qk.globalSearch.search.toKeyWithArgs({ search: searchValue }),
    () => searchGlobally({ search: searchValue }),
    {
      onSuccess: () => {
        refetchHistory()
      },
    },
  )

  return (
    <Paper sx={{ borderColor: 'primary.main', borderRadius: '8px' }}>
      {renderQueryResult($search, {
        loading: () => (
          <NoDataContainer>
            <CircularProgress size={32} />
          </NoDataContainer>
        ),
        error: error => (
          <NoDataContainer>
            <ErrorTemplateSmall error={error} />
          </NoDataContainer>
        ),
        success: data => {
          if (
            data.contactSearchResult.count === 0 &&
            data.jobSearchResult.count === 0
          ) {
            return (
              <NoDataContainer>
                <Box>
                  <Box component="img" src={loupeImage} width={170} />
                  <Typography
                    mt={1}
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                  >
                    {t('common.no_results')}
                  </Typography>
                </Box>
              </NoDataContainer>
            )
          }

          return (
            <Stack divider={<Divider />}>
              {data.contactSearchResult.count > 0 && (
                <CategoryResults
                  name={t('common.contacts')}
                  totalCount={data.contactSearchResult.count}
                  allResultsLink={`${
                    paths.contacts
                  }?search=${encodeURIComponent(searchValue)}`}
                  onClickAllResults={onClose}
                >
                  {data.contactSearchResult.content.map(contact => (
                    <Link
                      key={contact.candidateProfileId}
                      onClick={onClose}
                      to={generatePath(paths.contactDetails, {
                        contactId: contact.candidateProfileId,
                      })}
                      sx={{
                        display: 'block',
                        py: 0.5,
                        px: 1,
                        '&:hover': {
                          bgcolor: theme => theme.palette.mediumPurple[200],
                        },
                      }}
                    >
                      <ContactProfile
                        avatar={contact.imageUri}
                        name={contact.name}
                        position={contact.position.name}
                      />
                    </Link>
                  ))}
                </CategoryResults>
              )}

              {data.jobSearchResult.count > 0 && (
                <CategoryResults
                  name={t('common.jobs')}
                  totalCount={data.jobSearchResult.count}
                  // When user clicks "View all results", we need to show search results with no filters applied
                  // `&statuses=` is required to turn off default "OPEN" filter for jobs list
                  allResultsLink={`${paths.jobs}?search=${encodeURIComponent(
                    searchValue,
                  )}&statuses=`}
                  onClickAllResults={onClose}
                >
                  {data.jobSearchResult.content.map(job => {
                    const [recruiter] = job.recruiters
                    return (
                      <Link
                        key={job.jobId}
                        to={generatePath(paths.jobFlow, { id: job.jobId })}
                        onClick={onClose}
                        sx={{
                          display: 'block',
                          py: 0.5,
                          px: 1,
                          '&:hover': {
                            bgcolor: theme => theme.palette.mediumPurple[200],
                          },
                        }}
                      >
                        <Box display="flex" alignItems="center">
                          <JobStatus
                            jobStatusCode={job.status}
                            iconSx={{ fontSize: '24px' }}
                            containerSx={{
                              width: 32,
                              justifyContent: 'center',
                            }}
                          />

                          <Box ml={1}>
                            <Typography variant="body2">
                              {job.position.name}
                            </Typography>
                            {recruiter && (
                              <Typography
                                variant="caption"
                                color="text.secondary"
                                mt={0.25}
                              >
                                {recruiter.role}: {recruiter.fullName}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Link>
                    )
                  })}
                </CategoryResults>
              )}
            </Stack>
          )
        },
      })}
    </Paper>
  )
}
