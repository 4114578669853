import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconPhotoFill = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <g clipPath="url(#clip0_3173_23749)">
      <path
        d="M6.00195 5.5C6.00195 5.89782 5.84392 6.27936 5.56261 6.56066C5.28131 6.84196 4.89978 7 4.50195 7C4.10413 7 3.7226 6.84196 3.44129 6.56066C3.15999 6.27936 3.00195 5.89782 3.00195 5.5C3.00195 5.10218 3.15999 4.72064 3.44129 4.43934C3.7226 4.15804 4.10413 4 4.50195 4C4.89978 4 5.28131 4.15804 5.56261 4.43934C5.84392 4.72064 6.00195 5.10218 6.00195 5.5Z"
        fill="currentColor"
      />
      <path
        d="M1.5 2C1.10218 2 0.720644 2.15804 0.43934 2.43934C0.158035 2.72064 0 3.10218 0 3.5L0 12.5C0 12.8978 0.158035 13.2794 0.43934 13.5607C0.720644 13.842 1.10218 14 1.5 14H14.5C14.8978 14 15.2794 13.842 15.5607 13.5607C15.842 13.2794 16 12.8978 16 12.5V3.5C16 3.10218 15.842 2.72064 15.5607 2.43934C15.2794 2.15804 14.8978 2 14.5 2H1.5ZM14.5 3C14.6326 3 14.7598 3.05268 14.8536 3.14645C14.9473 3.24021 15 3.36739 15 3.5V9.5L11.225 7.553C11.1312 7.50602 11.025 7.48973 10.9215 7.50642C10.8179 7.52311 10.7223 7.57194 10.648 7.646L6.938 11.356L4.278 9.584C4.18196 9.52006 4.06676 9.4913 3.95194 9.5026C3.83712 9.5139 3.72973 9.56456 3.648 9.646L1.002 12V12.54C1.0008 12.5267 1.00014 12.5134 1 12.5V3.5C1 3.36739 1.05268 3.24021 1.14645 3.14645C1.24021 3.05268 1.36739 3 1.5 3H14.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3173_23749">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
