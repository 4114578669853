import { Box, Stack, Typography } from '@mui/material'
import { showToast } from 'lib/toast'
import { useDropzone } from 'react-dropzone'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'
import { RejectedFiles } from 'ui/inputs/drop-zone/rejected-files'

type Props = {
  onDrop: (files: Array<File>) => void
  onDelete?: () => void
}

export const AvatarDropZone = ({ onDrop, onDelete }: Props) => {
  const { t } = useTranslation()

  const maxSize = 2 * Math.pow(1024, 2)

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    onDropAccepted: onDrop,
    maxSize,
    onDropRejected: rejections => {
      showToast(
        {
          type: 'error',
          title: t('validations.image_format_is_not_supported'),
          body: (
            <RejectedFiles
              rejections={rejections}
              maxSize={maxSize}
              acceptedFileFormats={t(
                'validations.contact_avatar_supported_formats',
                { types: 'jpeg, jpg or png' },
              )}
            />
          ),
        },
        { autoClose: false },
      )
    },
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    maxFiles: 1,
  })

  return (
    <Box {...getRootProps()} display="flex" height="120px">
      <input {...getInputProps()} />

      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'center', sm: 'flex-start', md: 'center' }}
        justifyContent={{ xs: 'flex-start', md: 'space-between' }}
        alignSelf="center"
        gap={2}
        py={{ sx: 0, md: 2 }}
        flex={1}
      >
        <Stack spacing={1} textAlign={{ xs: 'center', sm: 'start' }}>
          <Typography variant="body2" color="text.secondary">
            <Trans
              i18nKey="common.click_to_upload_or_drag"
              components={{
                button: (
                  <Typography
                    onClick={open}
                    fontSize={14}
                    display="inline"
                    sx={{
                      cursor: 'pointer',
                      color: 'primary.main',
                      ':hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  />
                ),
              }}
            />
          </Typography>

          <Typography variant="caption" color="text.secondary" mt={1}>
            {t('common.avatar_requirements', {
              maxSize: '2MB',
            })}
          </Typography>
        </Stack>

        <Stack spacing={1} direction="row">
          <Button onClick={open}>{t('common.upload_photo')}</Button>

          {onDelete && (
            <Button onClick={onDelete} color="error" variant="outlined">
              {t('common.delete')}
            </Button>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}
