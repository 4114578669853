import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconWarningCircleOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g clipPath="url(#clip0_6359_56196)">
      <path
        d="M12 22.5C9.21523 22.5 6.54451 21.3938 4.57538 19.4246C2.60625 17.4555 1.5 14.7848 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
        fill="currentColor"
      />
      <path
        d="M10.5039 16.4997C10.5039 16.3027 10.5427 16.1077 10.6181 15.9257C10.6935 15.7437 10.804 15.5783 10.9432 15.439C11.0825 15.2997 11.2479 15.1892 11.4299 15.1139C11.6119 15.0385 11.8069 14.9997 12.0039 14.9997C12.2009 14.9997 12.3959 15.0385 12.5779 15.1139C12.7599 15.1892 12.9253 15.2997 13.0646 15.439C13.2039 15.5783 13.3143 15.7437 13.3897 15.9257C13.4651 16.1077 13.5039 16.3027 13.5039 16.4997C13.5039 16.8975 13.3459 17.279 13.0646 17.5603C12.7833 17.8417 12.4017 17.9997 12.0039 17.9997C11.6061 17.9997 11.2246 17.8417 10.9432 17.5603C10.6619 17.279 10.5039 16.8975 10.5039 16.4997ZM10.6509 7.49219C10.6309 7.30295 10.651 7.11163 10.7097 6.93063C10.7684 6.74963 10.8646 6.58301 10.9919 6.44157C11.1192 6.30013 11.2748 6.18703 11.4486 6.10961C11.6225 6.03219 11.8106 5.99219 12.0009 5.99219C12.1912 5.99219 12.3794 6.03219 12.5532 6.10961C12.727 6.18703 12.8826 6.30013 13.0099 6.44157C13.1372 6.58301 13.2334 6.74963 13.2921 6.93063C13.3508 7.11163 13.3709 7.30295 13.3509 7.49219L12.8259 12.7527C12.8083 12.9593 12.7137 13.1519 12.5609 13.2921C12.4082 13.4324 12.2083 13.5103 12.0009 13.5103C11.7935 13.5103 11.5936 13.4324 11.4409 13.2921C11.2881 13.1519 11.1935 12.9593 11.1759 12.7527L10.6509 7.49219Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6359_56196">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
