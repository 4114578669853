import { Box, Tooltip, Typography } from '@mui/material'
import { Scope, useScopes } from 'app/scopes'
import { IconSettings } from 'assets/icons'
import { TFuncKey, useTranslation } from 'react-i18next'
import { To } from 'react-router-dom'
import { Link } from 'ui/navigation'

export const createConfigurationHint = ({
  linkTo,
  scope,
  textWithAccess,
  textNoAccess,
}: {
  linkTo: To
  scope: Scope
  textWithAccess: TFuncKey
  textNoAccess: TFuncKey
}) => {
  return function ConfigurationHint({
    variant = 'tooltip',
  }: {
    variant?: 'tooltip' | 'full'
  }) {
    const { t } = useTranslation()
    const scopes = useScopes()
    const hasAccess = scopes[scope]
    const text = t(hasAccess ? textWithAccess : textNoAccess).toString()

    const iconStyles = {
      fontSize: '14px',
      color: 'greyBlue.light',
    }

    if (variant === 'tooltip') {
      return (
        <Tooltip title={text} placement="bottom-start">
          {hasAccess ? (
            <Link to={linkTo} target="_blank" display="flex">
              <IconSettings
                sx={{
                  ...iconStyles,
                  '&:hover': {
                    color: 'blue.main',
                  },
                }}
              />
            </Link>
          ) : (
            <Box display="flex">
              <IconSettings sx={iconStyles} />
            </Box>
          )}
        </Tooltip>
      )
    }

    return hasAccess ? (
      <Link
        to={linkTo}
        target="_blank"
        typography="caption"
        sx={{
          color: 'text.secondary',
          display: 'flex',
          alignItems: 'center',
          '&:hover, &:hover svg': {
            color: 'blue.main',
          },
        }}
      >
        <IconSettings sx={iconStyles} />
        <Typography variant="inherit" ml={0.75}>
          {text}
        </Typography>
      </Link>
    ) : (
      <Typography variant="caption" color="text.secondary">
        {text}
      </Typography>
    )
  }
}
