import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconArrowRightShort = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M9.54541 5L16.0454 12L9.54541 19"
      stroke="currentColor"
      strokeWidth={props.strokeWidth ?? 1}
      strokeLinecap="round"
      fill="none"
    />
  </SvgIcon>
)
