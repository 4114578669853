import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { styled } from '@mui/material'
import { ConfirmDialogProps, useConfirmationDialog } from 'lib/app-helpers'
import { ForwardedRef, forwardRef, MouseEvent } from 'react'

/**
 * Adding a New Button color-variant:
 * ----------------------------
 * Follow these steps to introduce a new button variant:
 *
 * 1. Theme Adjustments:
 *    - Extend the theme object by incorporating the new button variant's properties and styles.
 *      props: { variant: 'BUTTON_VARIANT', color: 'NEW_COLOR' },
 *
 * 2. Palette Modifications:
 *    - Update the Palette and PaletteOptions interfaces within the '@mui/material/styles' module
 *      to encompass the color definition for the fresh button variant.
 *    - Extend the ButtonPropsColorOverrides interface to include the newly added color.
 *
 * 3. StyledButton Styling:
 *    - Inside the StyledButton component, introduce additional conditions based on the 'color' prop
 */

type StyledButtonProps<C extends React.ElementType> = LoadingButtonProps<
  C,
  { component?: C }
> & {
  inputsEndAdornment?: boolean
}

const StyledButton = styled(LoadingButton, {
  shouldForwardProp: prop => prop !== 'inputsEndAdornment',
})(
  <C extends React.ElementType>({
    theme,

    inputsEndAdornment = false,
  }: StyledButtonProps<C>) => ({
    ...theme.typography.body1,

    minWidth: 'auto',
    padding: theme.spacing(1.125, 2),

    borderRadius: '62px',

    whiteSpace: 'nowrap',
    textTransform: 'none',

    ...(inputsEndAdornment && {
      height: 'calc(100%)',
      borderTopRightRadius: '1px',
      borderBottomRightRadius: '1px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    }),
  }),
)

type ClickEvent = MouseEvent<HTMLButtonElement>

export type ButtonProps<C extends React.ElementType = 'button'> = Omit<
  StyledButtonProps<C>,
  'onClick'
> & {
  onClick?: (event: ClickEvent) => void
  /**
   * Adds confirmation dialog that will be opened after clicking this button.
   * onClick will be invoked only after confirming inside dialog
   */
  confirm?: ConfirmDialogProps
}

export const Button = forwardRef(
  <C extends React.ElementType>(
    { variant, size, onClick, confirm, ...rest }: ButtonProps<C>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const { openDialog, renderConfirmDialog } = useConfirmationDialog(onClick)

    return (
      <>
        <StyledButton
          {...rest}
          ref={ref}
          variant={variant ?? 'contained'}
          size={size ?? 'large'}
          onClick={(event: ClickEvent) => {
            if (confirm) {
              openDialog(event)
            } else if (onClick) {
              onClick(event)
            }
          }}
        />

        {confirm && renderConfirmDialog(confirm)}
      </>
    )
  },
)
