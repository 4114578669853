import { Box, Stack, Typography } from '@mui/material'
import { IconPencil, IconX } from 'assets/icons'
import { getPeriod } from 'lib/js-utils'
import { useBoolean } from 'lib/react-utils'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'ui/inputs/checkbox'
import { IconButton } from 'ui/inputs/icon-button'

import {
  ContactEducationValues,
  EducationFormDialog,
} from './education-form-dialog'

type Props = Readonly<{
  education: ContactEducationValues
  onEdit: (educationValues: ContactEducationValues) => void
  onDelete: () => void
}>

export const EducationRow = ({ education, onEdit, onDelete }: Props) => {
  const { t } = useTranslation()

  const showEditEducationDialog = useBoolean()

  const educationPeriod = getPeriod(
    monthYearToDate(education.startDate),
    monthYearToDate(education.endDate),
  )

  return (
    <Box sx={{ display: 'flex', py: '8px', px: '16px' }}>
      <Stack flex={1} spacing={1}>
        <Typography fontWeight={500} color="mineShaft.main">
          {education.name}
        </Typography>

        <Stack
          direction="row"
          spacing={1}
          divider={<Typography color="textSecondary">•</Typography>}
        >
          {education.fieldOfStudy && (
            <Typography color="textSecondary">
              {education.fieldOfStudy}
            </Typography>
          )}

          {education.degree && (
            <Typography color="textSecondary">{education.degree}</Typography>
          )}
        </Stack>

        <Stack direction="row" spacing={1}>
          <Checkbox checked={!!education.relatedToPosition} disabled />
          <Typography color="mineShaft.main">
            {t('common.relevant_to_position')}
          </Typography>
        </Stack>
      </Stack>

      {educationPeriod && (
        <Typography color="textSecondary">{educationPeriod}</Typography>
      )}

      <Box sx={{ mt: '-6px', ml: '8px' }}>
        <IconButton onClick={() => onDelete()}>
          <IconX
            sx={{
              fontSize: '20px',
              color: 'error.main',
            }}
          />
        </IconButton>
      </Box>

      <Box sx={{ mt: '-6px', ml: '8px' }}>
        <IconButton onClick={showEditEducationDialog.setTrue}>
          <IconPencil
            sx={{
              fontSize: '16px',
              color: 'greyBlue.main',
            }}
          />
        </IconButton>

        <EducationFormDialog
          isOpen={showEditEducationDialog.isTrue}
          onClose={showEditEducationDialog.setFalse}
          onConfirm={educationValues => onEdit(educationValues)}
          defaultValues={education}
          confirmButtonText={t('common.save')}
        />
      </Box>
    </Box>
  )
}

type MonthYear = {
  month?: string
  year?: string
}

const monthYearToDate = ({ month, year }: MonthYear): Date | null => {
  if (!year) return null // If no year is provided, return null

  const monthIndex = month ? Number.parseInt(month, 10) - 1 : 0 // Default to January if no month provided
  return new Date(Number(year), monthIndex)
}
