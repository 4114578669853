import { SvgIconProps } from '@mui/material'
import { ActivityChannel, ActivityStatus, ActivityType } from 'api'
import { ThemeColor } from 'app/mui-theme'
import {
  IconActivity,
  IconActivityUpcoming,
  IconCallOutline,
  IconChannel,
  IconCheckCircleOutlineLarge,
  IconMail,
  IconPlusCircleOutlineWhite,
  IconWarningCircleOutline,
} from 'assets/icons'

type ActivityRecord<Value extends string> = Record<
  Value,
  {
    color: ThemeColor
    background: ThemeColor
    Icon: (props: SvgIconProps<'svg', {}>) => JSX.Element
  }
>

export const activityTypes: ActivityRecord<ActivityType> = {
  FOLLOW_UP: {
    color: 'blue.main',
    background: '#E6ECFD',
    Icon: IconActivity,
  },
  INTERVIEW: {
    color: '#F4343B',
    background: '#FEE6E7',
    Icon: IconActivity,
  },
}

export const activityChannels: ActivityRecord<ActivityChannel> = {
  CALL: {
    color: 'flushOrange.main',
    background: 'flushOrange[100]',
    Icon: IconCallOutline,
  },
  EMAIL: {
    color: '#F1010A',
    background: '#FEE6E7',
    Icon: IconMail,
  },
  SOCIAL_NETWORK: {
    color: '#FEA800',
    background: '#FFF6E6',
    Icon: IconChannel,
  },
}

export const activityStatuses: Record<
  ActivityStatus,
  {
    color: ThemeColor
    background: ThemeColor
    Icon: (props: SvgIconProps<'svg', {}>) => JSX.Element
    order: number
  }
> = {
  OPEN: {
    color: 'primary.main',
    background: '#E6F4F1',
    Icon: IconPlusCircleOutlineWhite,
    order: 1,
  },
  UPCOMING: {
    color: 'flushOrange.main',
    background: 'flushOrange[100]',
    Icon: IconActivityUpcoming,
    order: 2,
  },
  DONE: {
    color: 'greyBlue.main',
    background: 'divider',
    Icon: IconCheckCircleOutlineLarge,
    order: 3,
  },
  EXPIRED: {
    color: '#F1010A',
    background: '#FEE6E7',
    Icon: IconWarningCircleOutline,
    order: 4,
  },
}
