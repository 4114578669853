import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { renderTextField } from 'ui/inputs/text-field'
import { z } from 'zod'

type Props = {
  message: string
  onSubmit: (values: { message: string }) => void
  isAdding?: boolean
  onCancel?: () => void
}

const schema = z.object({ message: z.string().trim().min(1).max(300) })

export const CommentForm = ({ message, onSubmit, isAdding }: Props) => {
  const { t } = useTranslation()

  const { handleSubmit, control, reset } = useForm<z.infer<typeof schema>>({
    defaultValues: {
      message,
    },
    resolver: zodResolver(schema),
  })

  return (
    <form
      onSubmit={handleSubmit(values => {
        if (isAdding) reset()
        onSubmit(values)
      })}
    >
      <Controller
        control={control}
        name="message"
        render={renderTextField({
          minRows: 3,
          maxRows: 3,
          placeholder: t('common.comment_and_enter'),
          resizable: false,
          multiline: true,
          onKeyPress: event => {
            if (event.key === 'Enter' && !event.shiftKey) {
              event.preventDefault()
              handleSubmit(values => {
                if (isAdding) reset()
                onSubmit(values)
              })()
            }
          },
          sx: {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            borderLeft: 0,
            borderRight: 0,
            borderBottom: 0,
          },
        })}
      />
    </form>
  )
}
