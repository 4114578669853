import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconChatDotsOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      d="M6.25 10C6.25 10.6904 5.69036 11.25 5 11.25C4.30964 11.25 3.75 10.6904 3.75 10C3.75 9.30964 4.30964 8.75 5 8.75C5.69036 8.75 6.25 9.30964 6.25 10Z"
      fill="currentColor"
    />
    <path
      d="M11.25 10C11.25 10.6904 10.6904 11.25 10 11.25C9.30964 11.25 8.75 10.6904 8.75 10C8.75 9.30964 9.30964 8.75 10 8.75C10.6904 8.75 11.25 9.30964 11.25 10Z"
      fill="currentColor"
    />
    <path
      d="M15 11.25C15.6904 11.25 16.25 10.6904 16.25 10C16.25 9.30964 15.6904 8.75 15 8.75C14.3096 8.75 13.75 9.30964 13.75 10C13.75 10.6904 14.3096 11.25 15 11.25Z"
      fill="currentColor"
    />
    <path
      d="M2.70583 19.7537L2.73112 19.7487C5.01792 19.2954 6.41671 18.6964 7.06605 18.3674C7.99385 18.6161 8.97906 18.75 10 18.75C15.5228 18.75 20 14.8325 20 10C20 5.16751 15.5228 1.25 10 1.25C4.47715 1.25 0 5.16751 0 10C0 12.2011 0.928827 14.2124 2.46223 15.7501C2.36996 16.7139 2.11778 17.748 1.8081 18.6476L1.80323 18.6618C1.76556 18.7708 1.72705 18.8779 1.68788 18.9826C1.62621 19.1474 1.56292 19.3064 1.49872 19.458C1.40046 19.6902 1.59088 19.9504 1.83965 19.9097C2.0158 19.8808 2.1875 19.851 2.35477 19.8204C2.47403 19.7986 2.59104 19.7763 2.70583 19.7537ZM3.70654 15.8692C3.74204 15.4985 3.61035 15.1312 3.34736 14.8674C2.02163 13.538 1.25 11.8351 1.25 10C1.25 6.00943 5.0055 2.5 10 2.5C14.9945 2.5 18.75 6.00943 18.75 10C18.75 13.9906 14.9945 17.5 10 17.5C9.08845 17.5 8.21208 17.3805 7.38978 17.16C7.09227 17.0802 6.77578 17.1131 6.50102 17.2523C6.01697 17.4976 4.95208 17.9651 3.20846 18.3681C3.44377 17.5648 3.62699 16.7001 3.70654 15.8692Z"
      fill="currentColor"
    />
  </SvgIcon>
)
