import { useTheme } from '@mui/material'
import { useCallback, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'

import { calculateDomainTicks } from '../utils'
import { CustomXTick } from './custom-x-tick'

type Props = Readonly<{
  data: Array<{ name: string; value: number }>
  barColor?: string
  xAxisLabel?: string
  yAxisLabel?: string
}>

export const VerticalBarChart = ({
  data,
  barColor,
  xAxisLabel,
  yAxisLabel,
}: Props) => {
  const theme = useTheme()

  const [rotate, setRotate] = useState(false)
  const [xTicks, setXTicks] = useState<{
    [key: string]: SVGTextElement | null
  }>({})

  const visibleXTicks = Object.values(xTicks).filter(
    (element): element is SVGTextElement => element !== null,
  )

  // Determine if all labels are visible by default. If not - rotate them.
  // Using useUpdateEffect to check it after refs have been set
  // Note: if ticks are rotated - they won't get back. It means it doesn't respond to viewport changes
  useUpdateEffect(() => {
    if (
      !rotate &&
      visibleXTicks.length > 0 &&
      visibleXTicks.length < data.length
    ) {
      setRotate(true)
    }
  }, [visibleXTicks, rotate, data.length])

  const setXTickElement = useCallback(
    (value: string, element: SVGTextElement) => {
      setXTicks(prev => ({ ...prev, [value]: element }))
    },
    [],
  )

  // After rotation, change the height of x-axis to include the highest tick
  const xTicksHeight = rotate
    ? Math.max(...visibleXTicks.map(el => el.getBoundingClientRect().height))
    : 16

  const xAxisHeight = xTicksHeight + 32

  return (
    <ResponsiveContainer width="100%" height={200 + xAxisHeight}>
      <BarChart data={data} maxBarSize={40}>
        <XAxis
          dataKey="name"
          tickLine={false}
          stroke={theme.palette.mischka}
          height={xAxisHeight}
          tickMargin={12}
          {...(rotate && {
            interval: 0,
            textAnchor: 'start',
            padding: { right: 24 },
          })}
          tick={({
            payload: { value },
            verticalAnchor,
            visibleTicksCount,
            tickFormatter,
            ...rest
          }) => {
            return (
              <CustomXTick
                {...rest}
                value={value}
                rotate={rotate}
                onGetRefElement={setXTickElement}
              />
            )
          }}
        >
          {xAxisLabel && (
            <Label
              value={xAxisLabel}
              position="insideBottom"
              style={{
                fontSize: '12px',
                fill: theme.palette.greyBlue.light,
                textAnchor: 'middle',
              }}
            />
          )}
        </XAxis>

        <YAxis
          width={48}
          tickLine={false}
          stroke={theme.palette.mischka}
          tick={{
            fill: theme.palette.text.primary,
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.typography.caption.fontWeight,
          }}
          {...calculateDomainTicks(data, 4)}
        >
          {yAxisLabel && (
            <Label
              value={yAxisLabel}
              angle={-90}
              position="insideLeft"
              style={{
                fontSize: '12px',
                fill: theme.palette.greyBlue.light,
                textAnchor: 'middle',
              }}
            />
          )}
        </YAxis>

        <CartesianGrid
          vertical={false}
          stroke={theme.palette.mischka}
          strokeDasharray="5"
        />

        <Bar
          background={{ fill: 'transparent' }}
          dataKey="value"
          fill={barColor || theme.palette.jade[600]}
          radius={[2, 2, 0, 0]}
          isAnimationActive={false}
          label={{
            position: 'top',
            style: {
              fill: theme.palette.text.primary,
              fontSize: '14px',
              fontWeight: 500,
            },
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}
