import { DeepMap, FieldError, FieldValues } from 'react-hook-form'
import { TFunction } from 'react-i18next'

export type ValidationErrors = {
  required: Array<string>
  patterns: Array<{ field: string; message: string }>
}

/**
 * Recursive function that transforms react-hook-form errors (DeepMap object)
 * into array of required fields and array of other errors with custom messages
 */
export const getValidationErrors = (
  errors: DeepMap<FieldValues, FieldError>,
  t: TFunction,
  fieldName?: string,
): ValidationErrors => {
  const result: ValidationErrors = {
    required: [],
    patterns: [],
  }

  Object.entries(errors).map(([field, error]) => {
    if (!error) return

    const name = fieldName ?? field

    if ('type' in error) {
      // Deepest FieldError object is found
      if (
        error.type === 'required' ||
        error.message === t('validations.required')
      ) {
        // Group required fields in special array
        result.required.push(name)
      } else {
        // Others are grouped as custom patterns with messages
        result.patterns.push({
          field: name,
          message: error.message,
        })
      }
    } else {
      let deepErrors: ValidationErrors | null = null

      if (Array.isArray(error)) {
        // When using useFieldArray -> FieldError is the array of errors
        // In this case retrieve first found error as an example
        const arrayError = error.find(Boolean)
        // Recursively get nested errors
        deepErrors = getValidationErrors(arrayError, t, field)
      } else if (typeof error === 'object') {
        // When FieldError is an object that contains a nested error
        // Recursively get nested errors
        deepErrors = getValidationErrors(error, t)
      }

      if (deepErrors) {
        // Combine all nested results with main one
        result.required.push(...deepErrors.required)
        result.patterns.push(...deepErrors.patterns)
      }
    }
  })

  return result
}
