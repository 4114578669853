import { Box } from '@mui/material'
import { Scope, useScopes } from 'app/scopes'
import catInBoxImage from 'assets/images/cat-in-box.png'
import { NoDataTemplate } from 'components/templates'
import { useIndexPage } from 'lib/app-helpers'
import { useTranslation } from 'react-i18next'
import { LinkButton } from 'ui/navigation/link'

type Props = Readonly<{
  scopes: Array<Scope>
  children: JSX.Element
}>

export const ProtectedRoute = ({ scopes, children }: Props) => {
  const { t } = useTranslation()
  const userScopes = useScopes()
  const { indexPage } = useIndexPage()

  if (scopes.length === 0) {
    return children
  }

  if (!scopes.every(scope => userScopes[scope])) {
    return (
      <Box p={9}>
        <NoDataTemplate
          title={t('common.protected_route.access_denied')}
          subtitle={t('common.protected_route.access_denied_description')}
          imgSrc={catInBoxImage}
          button={
            <LinkButton to={indexPage}>{t('common.back_to_home')}</LinkButton>
          }
        />
      </Box>
    )
  }

  return children
}
