import { useMutation } from '@tanstack/react-query'
import { editContactComment } from 'api'
import { showToast } from 'lib/toast'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { CommentForm } from './comment-form'

type Props = {
  commentId: string
  message: string
  onCancel: () => void
  onEdit: () => void
  refetch: () => void
}

export const EditCommentForm = ({
  commentId,
  message,
  onCancel,
  onEdit,
  refetch,
}: Props) => {
  const { t } = useTranslation()

  const $editContactComment = useMutation(editContactComment)

  const editComment = useCallback(
    ({ message }: { message: string }) => {
      $editContactComment.mutate(
        {
          commentId,
          message,
        },
        {
          onSuccess: () => {
            showToast({
              title: t('toasts.updated_comment'),
            })
            onEdit()
            refetch()
          },
        },
      )
    },
    [$editContactComment, commentId, t, onEdit, refetch],
  )

  return (
    <CommentForm message={message} onSubmit={editComment} onCancel={onCancel} />
  )
}
