import { EmailTemplate } from 'api/email-templates'
import { IconFile } from 'assets/icons'
import { ResizableWindowIconButton } from 'components/templates'
import { useBoolean } from 'lib/react-utils'
import { useTranslation } from 'react-i18next'

import { AddTemplateDialog } from './add-template-dialog'

type Props = Readonly<{
  onSelect: (template: EmailTemplate) => void
}>

export const AddTemplateButton = ({ onSelect }: Props) => {
  const { t } = useTranslation()
  const addTemplateDialogOpen = useBoolean()

  return (
    <>
      <ResizableWindowIconButton
        tooltip={t('common.add_template')}
        onClick={addTemplateDialogOpen.setTrue}
      >
        <IconFile />
      </ResizableWindowIconButton>

      {addTemplateDialogOpen.isTrue && (
        <AddTemplateDialog
          isOpen={addTemplateDialogOpen.isTrue}
          onClose={addTemplateDialogOpen.setFalse}
          onSelect={onSelect}
        />
      )}
    </>
  )
}
