import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconDash = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <rect
      x="3"
      y="19.5"
      width="18"
      height="1.5"
      rx="0.75"
      fill="currentColor"
    />
  </SvgIcon>
)
