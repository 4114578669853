import { Box, Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getContactTalentsPools, qk } from 'api'
import { paths } from 'app/paths'
import { IconStart07 } from 'assets/icons'
import { DetailsItemContainer } from 'components/templates/details-template'
import { renderQueryResult } from 'lib/react-query-utils'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { Link } from 'ui/navigation'

type Props = Readonly<{
  contactId: string
}>

export const ContactTalentPoolsSection = ({ contactId }: Props) => {
  const { t } = useTranslation()

  const $contactTalentsPools = useQuery(
    qk.contacts.talentsPools.toKeyWithArgs({ contactId }),
    () => getContactTalentsPools({ contactId }),
  )

  return renderQueryResult($contactTalentsPools, {
    success: talentPools => {
      if (talentPools.length === 0) return null

      return (
        <DetailsItemContainer title={t('common.talent_pools')}>
          <Stack spacing={1}>
            {talentPools.map(talentPool => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                key={talentPool.talentPoolId}
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  <IconStart07 sx={{ width: 24, height: 24 }} />

                  <Link
                    to={generatePath(paths.talentsPoolContacts, {
                      talentPoolId: talentPool.talentPoolId,
                    })}
                  >
                    <Typography variant="body2" fontWeight={500} noWrap>
                      {talentPool.name}
                    </Typography>
                  </Link>
                </Stack>

                <Typography variant="body2" color="text.secondary">
                  {talentPool.quantity}{' '}
                  {t('common.candidates', { count: talentPool.quantity })}
                </Typography>
              </Box>
            ))}
          </Stack>
        </DetailsItemContainer>
      )
    },
    loading: () => null,
    error: () => null,
  })
}
