import { useTheme } from '@mui/material'
import { Tooltip, Typography } from '@mui/material'
import { memo } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'

import { calculateDomainTicks } from './utils'

type Props = Readonly<{
  data: Array<{ name: string; value: number }>
  barColor: string
  minHeight: number
  ticksCount?: number
  xAxisLabel?: string
  yAxisLabel?: string
}>

export const HorizontalBarChart = memo(
  ({
    data,
    barColor,
    minHeight,
    ticksCount = 6,
    xAxisLabel,
    yAxisLabel,
  }: Props) => {
    const theme = useTheme()

    const count = data.length
    const BAR_SIZE = 12 // px
    const CATEGORY_GAP = 20 // px
    const X_AXIS_HEIGHT = 48 // px

    // We calculate and set the actual height of the chart with the fixed bar size,
    // When there are a lot of categories and there is not enough space for them withing min height.
    const requiredHeight =
      count * BAR_SIZE + count * CATEGORY_GAP + X_AXIS_HEIGHT

    return (
      <ResponsiveContainer
        width="100%"
        height="100%"
        minHeight={requiredHeight > minHeight ? requiredHeight : minHeight}
      >
        <BarChart
          data={data}
          layout="vertical"
          maxBarSize={BAR_SIZE * 2}
          margin={{ top: 0, right: 16, bottom: 0, left: 0 }}
          {...(requiredHeight > minHeight && {
            barSize: BAR_SIZE,
          })}
        >
          <XAxis
            type="number"
            tickLine={false}
            axisLine={false}
            stroke={theme.palette.mischka}
            height={X_AXIS_HEIGHT}
            tickMargin={4}
            tick={{
              fill: theme.palette.text.secondary,
              fontSize: '12px',
            }}
            {...calculateDomainTicks(data, ticksCount)}
          >
            {xAxisLabel && (
              <Label
                value={xAxisLabel}
                position="insideBottom"
                style={{
                  fontSize: '12px',
                  fill: theme.palette.greyBlue.light,
                  textAnchor: 'middle',
                }}
              />
            )}
          </XAxis>

          <YAxis
            type="category"
            dataKey="name"
            width={150}
            tickLine={false}
            stroke={theme.palette.mischka}
            tick={({
              payload: { value },
              verticalAnchor,
              visibleTicksCount,
              tickFormatter,
              ...rest
            }) => {
              const formattedValue =
                value.length > 15 ? `${value.slice(0, 15)}...` : value

              return (
                <Tooltip
                  arrow
                  placement="top"
                  title={formattedValue !== value ? value : ''}
                >
                  <Typography
                    {...rest}
                    component="text"
                    fontSize="12px"
                    fill={theme.palette.text.secondary}
                  >
                    {formattedValue}
                  </Typography>
                </Tooltip>
              )
            }}
            interval={0}
          >
            {yAxisLabel && (
              <Label
                value={yAxisLabel}
                angle={-90}
                position="insideLeft"
                style={{
                  fontSize: '12px',
                  fill: theme.palette.greyBlue.light,
                  textAnchor: 'middle',
                }}
              />
            )}
          </YAxis>

          <CartesianGrid
            horizontal={false}
            stroke={theme.palette.mischka}
            strokeDasharray="5"
          />

          <Bar
            background={{ fill: 'transparent' }}
            dataKey="value"
            fill={barColor}
            radius={[0, 2, 2, 0]}
            // https://github.com/recharts/recharts/issues/829
            // Unfortunately, labels are not displayed from time to time
            // Disabling animation fixes it
            isAnimationActive={false}
            label={{
              position: 'right',
              style: { fill: theme.palette.text.primary, fontSize: '14px' },
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    )
  },
)
