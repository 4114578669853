import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconLevelsMedium = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 14"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.65 5C3.29101 5 3 5.29101 3 5.65C3 6.00899 3.29101 6.3 3.65 6.3H10.527C10.8859 6.3 11.177 6.00899 11.177 5.65C11.177 5.29101 10.8859 5 10.527 5H3.65ZM3.65 8.57031C3.29101 8.57031 3 8.86133 3 9.22031C3 9.5793 3.29101 9.87031 3.65 9.87031L10.527 9.87031C10.8859 9.87031 11.177 9.5793 11.177 9.22031C11.177 8.86133 10.8859 8.57031 10.527 8.57031H3.65Z"
      fill="currentColor"
    />
  </SvgIcon>
)
