import { Button } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  onClick: () => void
  children: ReactNode
}

export const CommentActionButton = ({ onClick, children }: Props) => {
  return (
    <Button
      variant="text"
      color="greyBlue"
      sx={{
        typography: 'caption',
        textTransform: 'none',
        color: 'greyBlue.light',
        p: 0,
        minWidth: 0,
        '&:hover': { background: 'none' },
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}
