import * as t from 'io-ts'

export const TJobPipelineStage = t.strict({
  stageId: t.string,
  name: t.string,
  order: t.number,
  canHire: t.boolean,
})

export type JobPipelineStage = t.TypeOf<typeof TJobPipelineStage>

export const TJobPipelineTemplate = t.strict({
  pipelineId: t.string,
  name: t.string,
  defaultPipeline: t.boolean,
  stages: t.array(TJobPipelineStage),
})

export type JobPipelineTemplate = t.TypeOf<typeof TJobPipelineTemplate>
