import { ListOrder } from 'api'
import { LocalStorageKey } from 'app/enums'
import { isEmpty } from 'fp-ts/Array'
import { usePagination } from 'lib/app-helpers'
import { ArrayParam, DateParam, StringParam } from 'lib/query-params'
import { useApplicableQueryState } from 'lib/react-utils'
import { useCallback, useMemo } from 'react'

import { ContactsUrlFilters } from './create-contacts-url-filter'

export const useContactsFilters = () => {
  const pagination = usePagination(LocalStorageKey.ContactsRowsPerPage)
  const { onChangePage } = pagination

  const levels = useApplicableQueryState(ContactsUrlFilters.Levels, ArrayParam)
  const positions = useApplicableQueryState(
    ContactsUrlFilters.Positions,
    ArrayParam,
  )
  const skills = useApplicableQueryState(ContactsUrlFilters.Skills, ArrayParam)
  const sources = useApplicableQueryState(
    ContactsUrlFilters.Sources,
    ArrayParam,
  )
  const country = useApplicableQueryState(
    ContactsUrlFilters.Country,
    StringParam,
  )
  const city = useApplicableQueryState(ContactsUrlFilters.City, StringParam)
  const talentPools = useApplicableQueryState(
    ContactsUrlFilters.TalentPools,
    ArrayParam,
  )
  const dateFrom = useApplicableQueryState(
    ContactsUrlFilters.DateFrom,
    DateParam,
  )
  const dateTo = useApplicableQueryState(ContactsUrlFilters.DateTo, DateParam)

  const filters = useMemo(
    () => ({
      levels,
      positions,
      skills,
      sources,
      country,
      city,
      talentPools,
      dateFrom,
      dateTo,
    }),
    [
      city,
      country,
      dateFrom,
      dateTo,
      levels,
      positions,
      skills,
      sources,
      talentPools,
    ],
  )

  const isAppliedFiltersEmpty =
    isEmpty(levels.appliedState) &&
    isEmpty(positions.appliedState) &&
    isEmpty(skills.appliedState) &&
    isEmpty(sources.appliedState) &&
    isEmpty(talentPools.appliedState) &&
    country.appliedState === '' &&
    city.appliedState === '' &&
    dateFrom.appliedState === null &&
    dateTo.appliedState === null

  const isSelectedFiltersEmpty =
    isEmpty(levels.state) &&
    isEmpty(positions.state) &&
    isEmpty(skills.state) &&
    isEmpty(sources.state) &&
    isEmpty(talentPools.state) &&
    country.state === '' &&
    city.state === '' &&
    dateFrom.state === null &&
    dateTo.state === null

  const applyFilters = useCallback(() => {
    onChangePage(0)
    for (const filter of Object.values(filters)) filter.applyState()
  }, [onChangePage, filters])

  const resetFilters = useCallback(() => {
    onChangePage(0)
    for (const filter of Object.values(filters)) filter.clearState()
  }, [onChangePage, filters])

  const column = useApplicableQueryState(
    ContactsUrlFilters.column,
    StringParam,
  ).state
  const direction = useApplicableQueryState(
    ContactsUrlFilters.direction,
    StringParam,
  ).state as 'asc' | 'desc'
  const order: ListOrder = {
    column,
    direction,
  }

  return {
    filters,
    isAppliedFiltersEmpty,
    isSelectedFiltersEmpty,
    applyFilters,
    resetFilters,
    pagination,
    order,
  }
}
