import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Stack } from '@mui/material'
import { useDictionaries } from 'lib/app-helpers'
import { getInputError, phoneRegex } from 'lib/form-utils'
import {
  contactInformationLabels,
  contactInformationTypes,
  recordToOptions,
} from 'lib/records'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { Dialog, DialogContent } from 'ui/feedback'
import { renderAutocompleteFreeSolo } from 'ui/inputs/autocomplete'
import { PhoneInput } from 'ui/inputs/phone-input'
import { renderSelect } from 'ui/inputs/select'
import { TextField } from 'ui/inputs/text-field'
import { z } from 'zod'

export const getContactInfoByTypeSchema = (type: string, t: TFunction) => {
  switch (type) {
    case 'PHONE': {
      return z.object({
        label: z.string().trim().min(2).max(30),
        value: z
          .string()
          .trim()
          .min(1)
          .refine(
            value => phoneRegex.test(value),
            t('validations.invalid_international_phone'),
          ),
      })
    }
    case 'MESSENGER': {
      return z.object({
        label: z.string().trim().min(1),
        value: z.string().trim().min(2).max(50),
      })
    }
    case 'EMAIL': {
      return z.object({
        label: z.string().trim().min(2).max(30),
        value: z.string().trim().email(),
      })
    }
    default: {
      return z.object({ label: z.string(), value: z.string() })
    }
  }
}

export const buildContactInfoSchema = (type: string, t: TFunction) => {
  return z.intersection(
    z.object({ type: z.string().min(1) }),
    getContactInfoByTypeSchema(type, t),
  )
}

type FormValues = z.infer<ReturnType<typeof buildContactInfoSchema>>

type Props = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (values: FormValues) => void
  defaultCountry: string
}

export const CreateContactInformationDialog = (props: Props) => {
  const { t } = useTranslation()

  const { dictionaries, isDictionariesError } = useDictionaries()

  const { control, handleSubmit, watch, unregister, reset } =
    useForm<FormValues>({
      defaultValues: {
        type: '',
        label: '',
        value: '',
      },
      resolver: (values, ...rest) =>
        zodResolver(buildContactInfoSchema(values.type, t))(values, ...rest),
    })

  const type = watch('type')

  // Reset validations when type changes
  useEffect(() => {
    unregister(['value', 'label'])
  }, [type, unregister])

  const closeDialog = () => {
    reset()
    props.onClose()
  }

  return (
    <Dialog open={props.isOpen} onClose={closeDialog}>
      <DialogContent
        title={t('common.add_new_contact')}
        onConfirm={handleSubmit(values => {
          props.onSubmit(values)
          closeDialog()
        })}
        onDeny={closeDialog}
        confirmText={t('common.add')}
        wrapWithForm
      >
        <Stack spacing={3}>
          <Stack spacing={2} direction="row">
            <Controller
              control={control}
              name="type"
              render={renderSelect({
                placeholder: t('common.select_option'),
                disableEmptyOption: true,
                label: t('contacts.contact_type'),
                disableAlphabeticalOrder: true,
                options: recordToOptions(contactInformationTypes, value =>
                  t(`contacts.contact_information_types.${value}`),
                ),
                dataCy: 'selectContactTypeInput',
              })}
            />

            <Box width="100%" />
          </Stack>

          <Stack spacing={2} direction="row">
            {type === 'MESSENGER' ? (
              <Controller
                control={control}
                name="label"
                render={renderSelect({
                  placeholder: t('common.enter_label'),
                  disableEmptyOption: true,
                  label: t('common.label'),
                  disableAlphabeticalOrder: true,
                  loadingError: isDictionariesError,
                  options: dictionaries.messengerTypes.options,
                  dataCy: 'selectMessengerLabelInput',
                })}
              />
            ) : (
              <Controller
                control={control}
                name="label"
                render={renderAutocompleteFreeSolo({
                  placeholder: t('common.enter_label'),
                  label: t('common.label'),
                  options: contactInformationLabels.map(value => {
                    const label = t(
                      `contacts.contact_information_block.labels.${value}`,
                    )
                    return { value: label, label }
                  }),
                  dataCy: 'selectContactLabelInput',
                })}
              />
            )}

            <Controller
              control={control}
              name="value"
              render={({ field, fieldState }) => {
                const valueProps = {
                  value: field.value,
                  onChange: field.onChange,
                  label: t('common.value'),
                  ...getInputError(fieldState.error),
                }

                return type === 'PHONE' ? (
                  <PhoneInput
                    defaultCountry={props.defaultCountry}
                    {...valueProps}
                    dataCy="contactPhoneValueInput"
                  />
                ) : (
                  <TextField
                    placeholder={t('common.enter_value')}
                    {...valueProps}
                    inputProps={{ 'data-cy': 'contactValueInput' }}
                  />
                )
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
