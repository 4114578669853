import { Stack } from '@mui/material'
import serverErrorImage from 'assets/images/server-error.png'
import { LogoutButton } from 'components/global'
import { NoDataTemplate } from 'components/templates/no-data-template'
import { getRequestErrorMessage, isRequestError } from 'lib/request'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'

export const ErrorTemplate = ({
  error,
  hideButtons,
}: {
  error: unknown
  hideButtons?: boolean
}) => {
  const { t } = useTranslation()
  const { title, message } = getRequestErrorMessage(error, t)

  const isNetworkError = isRequestError(error) && error.type === 'network'

  return (
    <NoDataTemplate
      imgSrc={serverErrorImage}
      title={title}
      imgWidth={260}
      subtitle={message}
      withImage={!isNetworkError}
      button={
        !hideButtons && (
          <Stack display="inline-flex" direction="row" spacing={2}>
            <Button onClick={() => window.location.reload()}>
              {t('common.refresh_page')}
            </Button>

            <LogoutButton />
          </Stack>
        )
      }
    />
  )
}
