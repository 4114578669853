import {
  Autocomplete as MuiAutocomplete,
  autocompleteClasses,
  AutocompleteRenderInputParams,
} from '@mui/material'
import { IconArrowDropdown } from 'assets/icons'
import { checkScrollToBottom } from 'lib/js-utils'
import { useBoolean } from 'lib/react-utils'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FormControl,
  FormControlWrapper,
  InputBase,
  LoadingMoreOption,
  MenuOption,
} from 'ui/inputs/common'

import { AutocompletePopper } from './autocomplete-popper'

type Option = {
  value: string
  label: string
  disabled?: boolean
  content?: React.ReactNode
}

export type AutocompleteFreeSoloProps = FormControlWrapper & {
  value: string
  onChange: (value: string, option: Option | null) => void
  options: Array<Option>

  disabled?: boolean
  loading?: boolean
  loadingError?: boolean
  placeholder?: string
  noOptionsText?: string
  disableInternalFilter?: boolean
  closedWhileInputEmpty?: boolean
  disableAlphabeticalOrder?: boolean
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  hasMoreOptions?: boolean
  onLoadMore?: () => void
  isLoadingMore?: boolean
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode
  dataCy?: string
}

export const AutocompleteFreeSolo = forwardRef(
  (props: AutocompleteFreeSoloProps, ref: React.Ref<HTMLInputElement>) => {
    const { t } = useTranslation()
    const isOpen = useBoolean()

    const mainOptions = [...props.options].sort((a, b) =>
      props.disableAlphabeticalOrder ? 0 : a.label.localeCompare(b.label),
    )

    return (
      <FormControl
        label={props.label}
        labelAdditionalElement={props.labelAdditionalElement}
        error={props.error}
        helperText={props.helperText}
        onRemove={props.onRemove}
        required={props.required}
        name={props.name}
      >
        <MuiAutocomplete
          freeSolo
          ref={ref}
          value={props.value}
          onChange={(_event, newOption, reason) => {
            if (reason === 'selectOption') {
              props.onChange((newOption as Option).value, newOption as Option)
            }
          }}
          options={
            props.isLoadingMore
              ? [
                  ...mainOptions,
                  {
                    value: 'LOADING_MORE',
                    label: t('common.loading_more'),
                    disabled: true,
                    content: <LoadingMoreOption />,
                  },
                ]
              : mainOptions
          }
          inputValue={
            props.options.find(option => option.value === props.value)?.label ??
            props.value
          }
          onInputChange={(_event, newInputValue, reason) => {
            if (reason !== 'reset') {
              props.onChange(newInputValue, null)
            }
          }}
          open={
            isOpen.isTrue &&
            (props.closedWhileInputEmpty ? props.value !== '' : true)
          }
          onOpen={() => {
            isOpen.setTrue()
          }}
          onClose={isOpen.setFalse}
          popupIcon={<IconArrowDropdown />}
          loading={props.loading}
          disabled={props.disabled}
          getOptionLabel={option =>
            typeof option === 'string' ? option : option.label
          }
          getOptionDisabled={option => Boolean(option.disabled)}
          isOptionEqualToValue={(option, value) => {
            // TS is wrong about `value` type here. When autocomplete is free solo, the second argument is string
            return option.value === (value as unknown as string)
          }}
          noOptionsText={
            props.loadingError
              ? t('common.failed_to_load_options')
              : props.noOptionsText
          }
          filterOptions={
            props.disableInternalFilter ? options => options : undefined
          }
          disableClearable={!props.value}
          renderInput={
            props.renderInput ??
            (params => {
              return (
                <InputBase
                  {...params.InputProps}
                  endAdornment={
                    props.endAdornment ?? params.InputProps.endAdornment
                  }
                  startAdornment={
                    props.startAdornment ?? params.InputProps.startAdornment
                  }
                  inputProps={{
                    ...params.inputProps,
                    'data-cy': props.dataCy,
                    id: props.label,
                    type: 'search',
                    autoComplete: 'off',
                  }}
                  error={props.error}
                  disabled={props.disabled}
                  placeholder={props.placeholder}
                  required={props.required}
                />
              )
            })
          }
          renderOption={({ className, ...optionProps }, option) => (
            <MenuOption
              {...optionProps}
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              disableRipple
            >
              {option.content ?? option.label}
            </MenuOption>
          )}
          PopperComponent={AutocompletePopper}
          ListboxProps={{
            role: 'listbox', // See https://github.com/mui/material-ui/issues/30249#issuecomment-1026131743
            onScroll: event => {
              if (
                props.onLoadMore &&
                props.hasMoreOptions &&
                !props.isLoadingMore
              ) {
                checkScrollToBottom(event, props.onLoadMore)
              }
            },
          }}
          sx={{
            flex: 'auto',

            [`& .${autocompleteClasses.inputRoot}`]: {
              width: '100%',
            },

            ...(props.endAdornment && {
              // &&& is used to overwrite mui styles by increasing priority
              [`&&& .${autocompleteClasses.inputRoot}`]: {
                pr: 0,
              },
            }),
          }}
        />
      </FormControl>
    )
  },
)
