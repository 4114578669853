import { getUsersList, qk, RoleId, UserStatus } from 'api'
import { useInfinitePaginatedQuery } from 'lib/react-query-utils'
import { Select, SelectProps } from 'ui/inputs/select'

type Props = Omit<SelectProps<string>, 'options'> & {
  roles?: Array<RoleId>
  statuses?: Array<UserStatus>
  excludedUsers?: Array<string>
}

export const UsersSelect = ({
  roles,
  statuses = ['ACTIVE'],
  excludedUsers,
  ...selectProps
}: Props) => {
  const $users = useInfinitePaginatedQuery(qk.auth.users.list, getUsersList, {
    pagination: { page: 0, pageSize: 25 },
    roles,
    statuses,
  })

  return (
    <Select
      {...selectProps}
      options={
        $users.data?.pages
          .flatMap(page => page.rows)
          .filter(
            user => !excludedUsers || !excludedUsers.includes(user.userId),
          )
          .map(user => ({
            value: user.userId,
            label: user.fullName,
          })) ?? []
      }
      loading={$users.isLoading}
      disableAlphabeticalOrder
      hasMoreOptions={$users.hasNextPage}
      onLoadMore={$users.fetchNextPage}
      isLoadingMore={$users.isFetchingNextPage}
    />
  )
}
