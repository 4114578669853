import * as t from 'io-ts'

export const TDonorCompany = t.intersection([
  t.strict({
    name: t.string,
    donorId: t.string,
    contactAmount: t.number,
  }),
  t.partial({
    website: t.string,
    address: t.partial({
      country: t.strict({ code: t.string }),
      city: t.string,
    }),
  }),
])

export type DonorCompany = t.TypeOf<typeof TDonorCompany>

export const TDonorCompaniesList = t.array(TDonorCompany)
