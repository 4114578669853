import { pipe } from 'fp-ts/function'
import { toServerDate } from 'lib/js-utils'
import { decodeJson, get, post } from 'lib/request'

import {
  TCalendarFreeTimeSlots,
  TCreateCalendarEvent,
  TimeSlot,
} from './book-meeting.codecs'

export type InvitationId = {
  invitationId: string
}

export type GetCalendarFreeTimeSlotsInput = InvitationId & {
  from: Date
  to: Date
}

export const getCalendarFreeTimeSlots = async ({
  invitationId,
  from,
  to,
}: GetCalendarFreeTimeSlotsInput) => {
  const params = new URLSearchParams({
    invitationId,
    from: toServerDate(from),
    to: toServerDate(to),
  })

  return pipe(
    await get('api/users/schedules/events/timeslots', {
      query: params,
    }),
    decodeJson(TCalendarFreeTimeSlots),
  )
}

export type CreateCalendarEventInput = InvitationId & {
  timeSlot: TimeSlot
}

export const createCalendarEvent = async ({
  invitationId,
  timeSlot,
}: CreateCalendarEventInput) => {
  return pipe(
    await post('api/users/schedules/events', {
      query: new URLSearchParams({ invitationId }),
      body: {
        start: timeSlot.start,
        end: timeSlot.end,
      },
    }),
    decodeJson(TCreateCalendarEvent),
  )
}
