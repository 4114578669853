import { Box } from '@mui/material'
import { WorkPermit } from 'api'
import { IconPlusCircle } from 'assets/icons'
import { useBoolean } from 'lib/react-utils'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'ui/inputs/icon-button'

import { WorkPermitFormDialog } from './work-permits-form-dialog'

type Props = Readonly<{
  onAdd: (workPermit: WorkPermit) => void
}>

export const AddWorkPermitButton = ({ onAdd }: Props) => {
  const { t } = useTranslation()
  const showAddWorkPermitsDialog = useBoolean()

  return (
    <Box>
      <IconButton onClick={showAddWorkPermitsDialog.setTrue}>
        <IconPlusCircle sx={{ fontSize: '20px', color: 'mediumPurple.main' }} />
      </IconButton>

      <WorkPermitFormDialog
        isOpen={showAddWorkPermitsDialog.isTrue}
        onClose={showAddWorkPermitsDialog.setFalse}
        dialogContentProps={{
          title: t('common.add_work_permit'),
          confirmText: t('common.add'),
        }}
        initialValues={{
          country: '',
          startDate: null,
          expiredDate: null,
        }}
        onSubmit={onAdd}
        isResetOnSubmit
      />
    </Box>
  )
}
