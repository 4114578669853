import { DaysOfWeek } from 'api'
import { daysOfWeek } from 'lib/records'

export const getDaysOfWeekStartingFrom = (
  startDay: DaysOfWeek,
): DaysOfWeek[] => {
  const startIdx = daysOfWeek.indexOf(startDay)

  return [...daysOfWeek.slice(startIdx), ...daysOfWeek.slice(0, startIdx)]
}
