import { ChangeEvent } from 'react'
import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form'

/**
 * Transforms a number field for proper rendering and handling in the form.
 *
 * @template V - The type of form values.
 * @template P - The path to the field in the form values.
 * @param {ControllerRenderProps<V, P>} field - The field properties provided by the React Hook Form controller.
 * @returns {object} - Transformed field properties for a number field.
 * @property {string} type - The type of the transformed field, set to 'text'.
 * @property {string} value - The transformed value of the number field, converting NaN or null to an empty string.
 * @property {Function} onChange - The transformed onChange handler for the number field, parsing the input string into a number.
 */
export const transformNumberField = <V extends FieldValues, P extends Path<V>>(
  field: ControllerRenderProps<V, P>,
) => {
  return {
    ...field,
    type: 'text',
    value:
      Number.isNaN(field.value) || field.value === null
        ? ''
        : (field.value as number).toString(),
    onChange: (
      arg: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const newValue = typeof arg === 'string' ? arg : arg.target.value
      const output = Number.parseFloat(newValue)
      field.onChange(Number.isNaN(output) ? null : output)
    },
  }
}
