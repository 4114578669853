import {
  ContactDetails,
  ContactWorkExperience,
  CustomFieldsList,
  UpdateContactInput,
} from 'api'
import { endOfYesterday } from 'date-fns'
import {
  adjustCustomFieldsFromServer,
  adjustCustomFieldsToServer,
  createCustomFieldsSchema,
} from 'lib/app-helpers'
import { linkRegex, nameRegex } from 'lib/form-utils'
import { toServerDate } from 'lib/js-utils'
import { TFunction } from 'react-i18next'
import { z, ZodError } from 'zod'

import { educationSchema } from './education-fieldset/education-form-dialog'
import { buildWorkExperienceSchema } from './work-experience/work-experience-form'

export const buildSchema = (t: TFunction, customFields: CustomFieldsList) =>
  z.object({
    name: z
      .string()
      .trim()
      .min(1) // To display default required error
      .min(
        2,
        t('validations.enter_value_between_symbols', {
          min: 2,
          max: 125,
        }),
      )
      .max(
        125,
        t('validations.enter_value_between_symbols', {
          min: 2,
          max: 125,
        }),
      )
      .regex(nameRegex, t('validations.invalid_name')),
    birthDate: z
      .date()
      .max(endOfYesterday(), t('common.birthday_past_date'))
      .nullable(),
    educations: z.array(educationSchema),
    image: z.string(),
    contactInfos: z
      .array(
        z.object({
          type: z.string(),
          label: z.string(),
          value: z.string(),
        }),
      )
      .refine(array => {
        const seen: { [key: string]: boolean } = {}
        const duplicates: string[] = []

        for (const item of array) {
          type ContactType = 'EMAIL' | 'MESSENGER' | 'PHONE'
          const label = `${t(
            `contacts.contact_information_types.${item.type as ContactType}`,
          )} - ${item.value}`
          const key = `${item.type as ContactType}+${item.value}+${item.label}`
          if (seen[key]) {
            duplicates.push(label)
          }
          seen[key] = true
        }

        if (duplicates.length > 0) {
          const errors = [
            {
              message: `${t(
                'validations.contact_duplications',
              )}  ${duplicates.map(key => '<br/>' + key)}`,
              path: [],
              code: 'custom',
            },
          ]
          throw new ZodError(errors as z.ZodIssue[])
        }

        return true
      }),
    country: z.string(),
    city: z.string(),
    position: z.string().min(1),
    qualificationLevel: z.string().min(1),
    workPermits: z.array(
      z.object({
        country: z.string(),
        startDate: z.date(),
        expiredDate: z.date(),
      }),
    ),
    note: z.string().trim().min(3).max(2600).or(z.literal('')),
    workExperience: z.array(buildWorkExperienceSchema(t)),
    skills: z.array(
      z.object({
        id: z.string(),
        name: z.string(),
        level: z.string().min(1),
        order: z.number(),
      }),
    ),
    languages: z.array(
      z.object({
        languageId: z.string(),
        name: z.string(),
        level: z.string().min(1),
        order: z.number(),
      }),
    ),
    links: z.array(
      z.object({
        value: z.string().regex(linkRegex, t('validations.invalid_link')),
      }),
    ),
    sourceId: z.string(),
    documents: z
      .array(
        z.object({
          fileId: z.string(),
          name: z.string(),
        }),
      )
      .nullable(),
    customFields: createCustomFieldsSchema(customFields),
  })

export type FormValues = z.infer<ReturnType<typeof buildSchema>>

export const defaultValues: FormValues = {
  name: '',
  position: '',
  contactInfos: [],
  image: '',
  birthDate: null,
  note: '',
  documents: null,
  educations: [],
  sourceId: '',
  country: '',
  city: '',
  qualificationLevel: '',
  languages: [],
  skills: [],
  links: [],
  workPermits: [],
  workExperience: [],
  customFields: {},
}

const nonEmptyString = (value: string) => (value !== '' ? value : undefined)
const emptyHtmlStringToNull = (value: string) =>
  value.replace(/<(.|\n)*?>/g, '').trim().length === 0 ? null : value

export const adjustValuesToServer = (
  values: FormValues,
  customFields: CustomFieldsList,
): UpdateContactInput => {
  return {
    name: values.name,
    positionId: values.position,
    contactInfos: values.contactInfos,
    birthDate: values.birthDate ? toServerDate(values.birthDate) : undefined,
    note: emptyHtmlStringToNull(values.note),
    educations: values.educations.map(education => ({
      ...education,
      degree: education.degree === '' ? undefined : education.degree,
      fieldOfStudy:
        education.fieldOfStudy === '' ? undefined : education.fieldOfStudy,
      startDate: education.startDate.year
        ? toServerDate(
            new Date(
              Number(education.startDate.year),
              Number(education.startDate.month),
            ),
          )
        : undefined,
      endDate: education.endDate.year
        ? toServerDate(
            new Date(
              Number(education.endDate.year),
              Number(education.endDate.month),
            ),
          )
        : undefined,
    })),
    sourceId: nonEmptyString(values.sourceId),
    languages:
      values.languages.length > 0
        ? values.languages.map((language, index) => ({
            languageId: language.languageId,
            levelCode: language.level,
            order: index,
          }))
        : undefined,
    address:
      values.country || values.city
        ? {
            countryCode: nonEmptyString(values.country),
            city: nonEmptyString(values.city),
          }
        : undefined,
    skills:
      values.skills.length > 0
        ? values.skills.map((skill, index) => ({
            skillId: skill.id,
            skillLevelCode: skill.level,
            lastUsed: 2021, // temporary overcoming to pass server validation
            order: index,
          }))
        : undefined,
    links:
      values.links.length > 0
        ? values.links.map(link => link.value)
        : undefined,
    qualificationLevelCode: nonEmptyString(values.qualificationLevel),
    imageId: nonEmptyString(values.image),
    documents: values.documents
      ? values.documents.map(document => document.fileId)
      : undefined,
    workPermits: values.workPermits.length > 0 ? values.workPermits : undefined,
    jobExperiences: values.workExperience.map(experience => ({
      ...(experience.position.id
        ? { positionId: experience.position.id }
        : { positionName: experience.position.name }),
      ...(experience.company.id
        ? { donorId: experience.company.id }
        : { donorName: experience.company.name }),
      startDate: toServerDate(
        new Date(
          Number(experience.startDate.year),
          Number(experience.startDate.month),
        ),
      ),
      endDate:
        experience.endDate.year && experience.endDate.month
          ? toServerDate(
              new Date(
                Number(experience.endDate.year),
                Number(experience.endDate.month),
              ),
            )
          : undefined,
      description: nonEmptyString(experience.description),
      jobTypeId: nonEmptyString(experience.jobTypeId),
      ...((experience.country || experience.city) && {
        address: {
          countryCode: nonEmptyString(experience.country),
          city: nonEmptyString(experience.city),
        },
      }),
      level: nonEmptyString(experience.level),
      currentEmployment: experience.currentEmployment,
    })),
    customFields: adjustCustomFieldsToServer(customFields, values),
  }
}

export const adjustValuesFromServer = ({
  details,
  workExperience,
  customFields,
}: {
  details: ContactDetails
  workExperience?: ContactWorkExperience
  customFields: CustomFieldsList
}): FormValues => {
  return {
    name: details.name,
    position: details.position.positionId,
    image: details.imageId ?? '',
    contactInfos: details.contactInfos,
    birthDate: details.birthDate ?? null,
    note: details.note ?? '',
    documents: details.documents ?? null,
    educations: (details.educations ?? []).map(education => ({
      ...education,
      degree: education.degree ?? '',
      fieldOfStudy: education.fieldOfStudy ?? '',
      startDate: {
        month: education.startDate?.getMonth().toString() ?? '',
        year: education.startDate?.getFullYear().toString() ?? '',
      },
      endDate: {
        month: education.endDate?.getMonth().toString() ?? '',
        year: education.endDate?.getFullYear().toString() ?? '',
      },
    })),
    sourceId: details.source?.id ?? '',
    languages:
      details.languages?.map(language => ({
        languageId: language.languageId,
        name: language.language,
        level: language.levelCode,
        order: language.order,
      })) ?? [],
    country: details.address?.country?.code ?? '',
    city: details.address?.city ?? '',
    skills:
      details.skills?.map(skill => ({
        id: skill.skillId,
        name: skill.skillName,
        level: skill.skillLevelCode,
        order: skill.order,
      })) ?? [],
    links: details.linkResponses?.map(({ link }) => ({ value: link })) ?? [],
    qualificationLevel: details.qualificationLevel?.code ?? '',
    workPermits: details.workPermits
      ? details.workPermits.map(workPermit => ({
          ...workPermit,
          country: workPermit.country.code,
        }))
      : [],
    workExperience: workExperience
      ? workExperience.flatMap(work =>
          work.jobExperience.map(experience => ({
            positionValue:
              experience.position.positionId ?? experience.position.name,
            position: {
              id: experience.position.positionId,
              name: experience.position.name,
            },
            companyValue:
              experience.donorCompany.donorId ?? experience.donorCompany.name,
            company: {
              id: experience.donorCompany.donorId,
              name: experience.donorCompany.name,
            },
            level: experience.level ?? '',
            jobTypeId: experience.jobType?.jobTypeId ?? '',
            jobTypeName: experience.jobType?.name ?? '',
            country: experience.address?.country?.code ?? '',
            city: experience.address?.city ?? '',
            startDate: {
              month: experience.startDate.getMonth().toString(),
              year: experience.startDate.getFullYear().toString(),
            },
            endDate: {
              month: experience.endDate?.getMonth().toString() ?? '',
              year: experience.endDate?.getFullYear().toString() ?? '',
            },
            currentEmployment: experience.currentEmployment,
            description: experience.description ?? '',
          })),
        )
      : [],
    customFields: adjustCustomFieldsFromServer(customFields, details),
  }
}
