import { IconCrossCircle } from 'assets/icons'
import { Draggable } from 'react-beautiful-dnd'
import { Control, Controller } from 'react-hook-form'
import { TableCell, TableRow } from 'ui/data'
import { Checkbox } from 'ui/inputs/checkbox'
import { IconButton } from 'ui/inputs/icon-button'
import { TextField } from 'ui/inputs/text-field'

import { FormValues } from './pipeline-template-form'

type Props = {
  index: number
  id: string
  isLast: boolean
  control: Control<FormValues, any>
  onRemove: () => void
}

export const TemplateStageRow = ({
  index,
  id,
  isLast,
  control,
  onRemove,
}: Props) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => {
        return (
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={{
              bgcolor: '#FFFFFF',
              ...(snapshot.isDragging && {
                // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/patterns/tables.md
                display: 'table',
                borderRadius: '4px',
                boxShadow:
                  '0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 5px rgba(50, 50, 105, 0.25)',
              }),
            }}
          >
            <TableCell align="center" width="16%">
              {index + 1}
            </TableCell>
            <TableCell width="52%">
              <Controller
                control={control}
                name={`templateStages.${index}.name` as const}
                render={({ field, fieldState }) => (
                  <TextField {...field} error={Boolean(fieldState.error)} />
                )}
              />
            </TableCell>
            <TableCell align="center" width="16%">
              <Controller
                control={control}
                name={`templateStages.${index}.canHire` as const}
                render={({ field }) => (
                  <Checkbox
                    disabled={isLast}
                    checked={isLast || field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </TableCell>
            <TableCell align="center" width="16%">
              <IconButton color="error" onClick={onRemove}>
                <IconCrossCircle sx={{ fontSize: '16px' }} />
              </IconButton>
            </TableCell>
          </TableRow>
        )
      }}
    </Draggable>
  )
}
