import SvgIcon from '@mui/material/SvgIcon'

import { FilledIcon } from '../icon-props'

export const IconAlt = ({ filled, ...props }: FilledIcon) =>
  filled ? (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <path
        d="M13.5 1.125C13.2016 1.125 12.9155 1.24353 12.7045 1.4545C12.4935 1.66548 12.375 1.95163 12.375 2.25V15.75C12.375 16.0484 12.4935 16.3345 12.7045 16.5455C12.9155 16.7565 13.2016 16.875 13.5 16.875H15.75C16.0484 16.875 16.3345 16.7565 16.5455 16.5455C16.7565 16.3345 16.875 16.0484 16.875 15.75V2.25C16.875 1.95163 16.7565 1.66548 16.5455 1.4545C16.3345 1.24353 16.0484 1.125 15.75 1.125H13.5ZM6.75 7.875C6.75 7.57663 6.86853 7.29048 7.0795 7.0795C7.29048 6.86853 7.57663 6.75 7.875 6.75H10.125C10.4234 6.75 10.7095 6.86853 10.9205 7.0795C11.1315 7.29048 11.25 7.57663 11.25 7.875V15.75C11.25 16.0484 11.1315 16.3345 10.9205 16.5455C10.7095 16.7565 10.4234 16.875 10.125 16.875H7.875C7.57663 16.875 7.29048 16.7565 7.0795 16.5455C6.86853 16.3345 6.75 16.0484 6.75 15.75V7.875ZM1.125 12.375C1.125 12.0766 1.24353 11.7905 1.4545 11.5795C1.66548 11.3685 1.95163 11.25 2.25 11.25H4.5C4.79837 11.25 5.08452 11.3685 5.2955 11.5795C5.50647 11.7905 5.625 12.0766 5.625 12.375V15.75C5.625 16.0484 5.50647 16.3345 5.2955 16.5455C5.08452 16.7565 4.79837 16.875 4.5 16.875H2.25C1.95163 16.875 1.66548 16.7565 1.4545 16.5455C1.24353 16.3345 1.125 16.0484 1.125 15.75V12.375Z"
        fill="currentColor"
      />
    </SvgIcon>
  ) : (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M4 11H2V14H4V11ZM9 7H7V14H9V7ZM14 2V14H12V2H14ZM12 1C11.7348 1 11.4804 1.10536 11.2929 1.29289C11.1054 1.48043 11 1.73478 11 2V14C11 14.2652 11.1054 14.5196 11.2929 14.7071C11.4804 14.8946 11.7348 15 12 15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14V2C15 1.73478 14.8946 1.48043 14.7071 1.29289C14.5196 1.10536 14.2652 1 14 1H12ZM6 7C6 6.73478 6.10536 6.48043 6.29289 6.29289C6.48043 6.10536 6.73478 6 7 6H9C9.26522 6 9.51957 6.10536 9.70711 6.29289C9.89464 6.48043 10 6.73478 10 7V14C10 14.2652 9.89464 14.5196 9.70711 14.7071C9.51957 14.8946 9.26522 15 9 15H7C6.73478 15 6.48043 14.8946 6.29289 14.7071C6.10536 14.5196 6 14.2652 6 14V7ZM1 11C1 10.7348 1.10536 10.4804 1.29289 10.2929C1.48043 10.1054 1.73478 10 2 10H4C4.26522 10 4.51957 10.1054 4.70711 10.2929C4.89464 10.4804 5 10.7348 5 11V14C5 14.2652 4.89464 14.5196 4.70711 14.7071C4.51957 14.8946 4.26522 15 4 15H2C1.73478 15 1.48043 14.8946 1.29289 14.7071C1.10536 14.5196 1 14.2652 1 14V11Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
