import { Box, Stack, Typography } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getJobDetails, qk, searchJobs } from 'api'
import { useFormatInTimeZone } from 'lib/app-helpers'
import { useDebounceState } from 'lib/react-utils'
import { showToast } from 'lib/toast'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'react-use'
import { Autocomplete, AutocompleteProps } from 'ui/inputs/autocomplete'

import { JobStatus } from './job-status'

type Props = Omit<AutocompleteProps, 'options'>

export const JobsSearch = (props: Props) => {
  const { t } = useTranslation()
  const { formatDate } = useFormatInTimeZone()

  const [searchValue, setSearchValue] = useDebounceState('', 500)

  const { data, isLoading, isError } = useQuery(
    qk.jobs.search.toKeyWithArgs({ name: searchValue }),
    () => searchJobs({ name: searchValue }),
    {
      keepPreviousData: true,
      onError: _ => {
        setSearchValue('')
        props.onChange('', null)
        showToast({ title: t('errors.something_wrong'), type: 'error' })
      },
    },
  )

  const $getJobDetails = useMutation(getJobDetails)

  // If initial value is passed - it's job id.
  // But in this case there are no options yet to display input value as job name.
  // So on first load we need to retrieve job details to set initial input value correctly.
  useEffectOnce(() => {
    if (props.value) {
      $getJobDetails.mutate(
        { jobId: props.value },
        {
          onSuccess: data => {
            setSearchValue(data.position.name)
          },
        },
      )
    }
  })

  return (
    <Autocomplete
      {...props}
      disableInternalFilter
      loading={isLoading}
      loadingError={isError}
      placeholder={t('common.search')}
      onInputChange={setSearchValue}
      options={
        data?.map(job => {
          const recruiter = job.recruiters[0]

          return {
            value: job.jobId,
            label: job.positionName,
            content: (
              <Box width="100%">
                <Stack direction="row" spacing={1} mb={0.25}>
                  <JobStatus jobStatusCode={job.jobStatus} />
                  <Typography>{job.positionName}</Typography>
                  {job.projectName && (
                    <Typography variant="caption">
                      ({job.projectName})
                    </Typography>
                  )}
                </Stack>

                <Typography
                  variant="caption"
                  color="text.secondary"
                  display="block"
                >
                  {formatDate(job.targetDate, { timeZone: 'UTC' })}
                  {recruiter ? ` / ${recruiter.fullName}` : ''}
                </Typography>
              </Box>
            ),
          }
        }) ?? []
      }
    />
  )
}
