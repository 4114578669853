export const labels = {
  name: 'common.full_name',
  position: 'common.position',
  contactInfos: 'contacts.contact_information',
  country: 'common.country',
  city: 'common.city',
  sourceId: 'common.source',
  qualificationLevel: 'common.qualification_level',
  educationName: 'common.education',
  links: 'common.links',
  languages: 'common.languages',
  birthDate: 'common.birthday',
  skills: 'common.skills',
  document: 'common.documents',
  note: 'common.notes',
  image: 'common.profile_image',
  workExperience: 'common.work_experience',
  customFields: 'common.custom_fields',
} as const
