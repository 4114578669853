import {
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
  styled,
} from '@mui/material'

interface MenuItemProps extends MuiMenuItemProps {
  small?: boolean
  fontSize?: string
}

export const MenuOption = styled(MuiMenuItem, {
  shouldForwardProp: prop => prop !== 'small' && prop !== 'fontSize',
})<MenuItemProps>(({ theme, small, fontSize }) => ({
  boxSizing: 'border-box',
  overflowX: 'hidden',
  [theme.breakpoints.up('xs')]: {
    minHeight: small ? theme.spacing(3) : theme.spacing(4),
  },
  padding: theme.spacing(0.875, 1.5),
  borderBottom: '1px solid',
  borderColor: theme.palette.mineShaft[100],
  fontSize: fontSize ?? (small ? '0.75rem' : '0.875rem'),
  whiteSpace: 'normal',

  '&:hover': {
    background: theme.palette.mediumPurple[100],
  },

  '&.Mui-focusVisible': {
    background: theme.palette.mediumPurple[100],
  },

  '&.Mui-selected, &[aria-selected="true"]': {
    background: theme.palette.mediumPurple[200],
  },

  '&.Mui-disabled': {
    opacity: 1,
    background: theme.palette.alabaster,
    color: theme.palette.text.secondary,
  },
}))
