import SvgIcon from '@mui/material/SvgIcon'

import { FilledIcon } from '../icon-props'

export const IconStar = ({ filled, ...props }: FilledIcon) =>
  filled ? (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_3173_23773)">
        <path
          d="M3.61212 15.4425C3.22612 15.6405 2.78812 15.2935 2.86612 14.8505L3.69612 10.1205L0.173118 6.76452C-0.155882 6.45052 0.0151183 5.87652 0.456118 5.81452L5.35412 5.11852L7.53812 0.791523C7.73512 0.401523 8.26812 0.401523 8.46512 0.791523L10.6491 5.11852L15.5471 5.81452C15.9881 5.87652 16.1591 6.45052 15.8291 6.76452L12.3071 10.1205L13.1371 14.8505C13.2151 15.2935 12.7771 15.6405 12.3911 15.4425L8.00012 13.1865L3.61112 15.4425H3.61212Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3173_23773">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  ) : (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_2816_14821)">
        <path
          d="M2.866 14.85C2.788 15.294 3.226 15.641 3.612 15.443L8.002 13.187L12.391 15.443C12.777 15.641 13.215 15.294 13.137 14.851L12.307 10.121L15.829 6.76505C16.159 6.45105 15.989 5.87705 15.547 5.81505L10.649 5.11905L8.465 0.792045C8.42339 0.704334 8.35775 0.630228 8.27571 0.578338C8.19366 0.526447 8.09857 0.498901 8.0015 0.498901C7.90442 0.498901 7.80933 0.526447 7.72729 0.578338C7.64524 0.630228 7.5796 0.704334 7.538 0.792045L5.354 5.12005L0.455996 5.81605C0.0149962 5.87805 -0.156004 6.45204 0.172996 6.76605L3.696 10.122L2.866 14.852V14.85ZM7.771 12.083L4.085 13.977L4.779 10.02C4.79525 9.92896 4.78889 9.83527 4.76047 9.74722C4.73205 9.65916 4.68244 9.57944 4.616 9.51505L1.71 6.74505L5.762 6.16905C5.8459 6.15637 5.92548 6.12355 5.99393 6.0734C6.06237 6.02324 6.11764 5.95724 6.155 5.88105L8 2.22305L9.847 5.88105C9.88435 5.95724 9.93962 6.02324 10.0081 6.0734C10.0765 6.12355 10.1561 6.15637 10.24 6.16905L14.292 6.74405L11.386 9.51404C11.3194 9.57854 11.2697 9.65843 11.2413 9.74668C11.2128 9.83492 11.2066 9.9288 11.223 10.02L11.917 13.977L8.231 12.083C8.15972 12.0463 8.08069 12.0271 8.0005 12.0271C7.9203 12.0271 7.84127 12.0463 7.77 12.083H7.771Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2816_14821">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
