import { useMutation } from '@tanstack/react-query'
import { revertImport } from 'api'
import { showPromiseToast, showToast } from 'lib/toast'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * Hook for reverting an import session with mutation handling and toast notifications.
 *
 * @param {Object} options - Options for the hook.
 * @param {Function} options.successCallback - Callback function to be called on successful import session revert.
 * @returns {Object} - Object containing the revert function.
 */
export const useRevertImport = ({
  successCallback,
}: {
  successCallback?: () => void
}) => {
  const { t } = useTranslation()
  const { mutateAsync } = useMutation(revertImport)

  /**
   * Callback function for reverting an import session.
   *
   * @param {string} fileName - Name of the file associated with the import session.
   * @param {string} importSessionId - ID of the import session to be reverted.
   */
  const onRevert = useCallback(
    (fileName: string, importSessionId: string) => () => {
      const promise = mutateAsync(
        { importSessionId },
        {
          onSuccess: () => {
            showToast({
              title: t('toasts.import_revert_success_message', {
                fileName,
              }),
            })

            if (successCallback) {
              successCallback()
            }
          },
        },
      )

      showPromiseToast(
        promise,
        t('toasts.import_revert_progress_message', { fileName }),
      )
    },
    [mutateAsync, successCallback, t],
  )

  return { onRevert }
}
