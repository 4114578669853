import {
  createListSearchParams,
  ListOrder,
  ListPagination,
} from 'api/list-params'
import { pipe } from 'fp-ts/lib/function'
import { decodeJsonWithTotal, get, put } from 'lib/request'

import { TCompanyCharge } from './billing.codecs'

export type UpdateBillingEmailForCompanyInput = {
  billingEmail: string | null
}

export const updateBillingEmailForCompany = async ({
  billingEmail,
}: UpdateBillingEmailForCompanyInput) => {
  return await put('subscription/subscriptions/companies/me/emails', {
    body: { billingEmail },
  })
}

export type GetCompanyChargesListInput = {
  pagination: ListPagination
  order: ListOrder
}

export const getCompanyChargesList = async ({
  order,
  pagination,
}: GetCompanyChargesListInput) => {
  const params = createListSearchParams({
    pagination,
    order,
  })

  return pipe(
    await get('subscription/companies/me/charges/transactions', {
      query: params,
    }),
    decodeJsonWithTotal(TCompanyCharge, pagination),
  )
}
