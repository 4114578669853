import { getInputError } from 'lib/form-utils'
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
} from 'react-hook-form'

import { Autocomplete, AutocompleteProps } from './autocomplete'

export const renderAutocomplete = <
  FormValues extends FieldValues,
  FormPath extends Path<FormValues>,
>(
  props: Omit<AutocompleteProps, 'value' | 'onChange'>,
) => {
  return function AutocompleteController({
    field,
    fieldState,
  }: {
    field: ControllerRenderProps<FormValues, FormPath>
    fieldState: ControllerFieldState
  }) {
    return (
      <Autocomplete
        {...props}
        {...field}
        {...getInputError(fieldState.error)}
      />
    )
  }
}
