import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCalendarFriday = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      d="M5.26904 12.9639V10.3755H7.81348V9.68994H5.26904V7.65088H8.04199V6.96533H4.5V12.9639H5.26904Z"
      fill="currentColor"
    />
    <path
      d="M8.97803 12.9639H9.73828V10.103C9.73828 9.479 9.99316 8.96924 10.8105 8.96924C10.9512 8.96924 11.1006 8.97363 11.1797 8.98682V8.29688C11.0874 8.28369 10.9907 8.2749 10.894 8.2749C10.2393 8.2749 9.89209 8.63525 9.75586 8.91211H9.73389V8.34521H8.97803V12.9639Z"
      fill="currentColor"
    />
    <path
      d="M12.1333 7.2334C12.1333 7.50146 12.3486 7.7124 12.6167 7.7124C12.8848 7.7124 13.1045 7.50146 13.1045 7.2334C13.1045 6.96094 12.8848 6.75 12.6167 6.75C12.3486 6.75 12.1333 6.96094 12.1333 7.2334ZM12.2388 12.9639H12.9946V8.34521H12.2388V12.9639Z"
      fill="currentColor"
    />
    <path
      d="M3.9375 0C4.24816 0 4.5 0.25184 4.5 0.5625V1.125H13.5V0.5625C13.5 0.25184 13.7518 0 14.0625 0C14.3732 0 14.625 0.25184 14.625 0.5625V1.125H15.75C16.9926 1.125 18 2.13236 18 3.375V15.75C18 16.9926 16.9926 18 15.75 18H2.25C1.00736 18 0 16.9926 0 15.75V3.375C0 2.13236 1.00736 1.125 2.25 1.125H3.375V0.5625C3.375 0.25184 3.62684 0 3.9375 0ZM1.125 4.5V15.75C1.125 16.3713 1.62868 16.875 2.25 16.875H15.75C16.3713 16.875 16.875 16.3713 16.875 15.75V4.5H1.125Z"
      fill="currentColor"
    />
  </SvgIcon>
)
