import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '@mui/material'
import { IconX } from 'assets/icons'
import { Fieldset } from 'components/common'
import { linkRegex } from 'lib/form-utils'
import { useMemo } from 'react'
import { Control, Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'react-i18next'
import { Table } from 'ui/data'
import { IconButton } from 'ui/inputs/icon-button'
import { renderTextField } from 'ui/inputs/text-field'
import { z } from 'zod'

import { labels } from './_labels'
import { FormValues } from './_values'

const buildSchema = (t: TFunction) =>
  z.object({
    link: z
      .string()
      .trim()
      .regex(linkRegex, t('validations.invalid_link'))
      .or(z.literal('')),
  })

type Props = {
  control: Control<FormValues>
}

export const Links = ({ control }: Props) => {
  const { t } = useTranslation()

  const schema = useMemo(() => buildSchema(t), [t])

  const linkForm = useForm<z.infer<typeof schema>>({
    defaultValues: { link: '' },
    resolver: zodResolver(schema),
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'links',
  })

  return (
    <Fieldset
      legend={t(labels.links).toString()}
      hint={t('hints.links_description')}
    >
      <Box>
        <Controller
          control={linkForm.control}
          name="link"
          render={renderTextField({
            placeholder: t('common.enter_link'),
            inputProps: { 'data-cy': 'linkTextFieldInput' },
            onKeyDown: event => {
              if (event.key === 'Enter') {
                linkForm.handleSubmit(({ link }) => {
                  if (link) {
                    append({ value: link })
                    linkForm.reset()
                  }
                })()
              }
            },
          })}
        />

        {fields.length > 0 && (
          <Box mt={2}>
            <Table
              fixed
              columns={[
                { key: 'link', name: t('common.link') },
                {
                  key: 'action',
                  name: t('common.action'),
                  align: 'center',
                  width: '80px',
                },
              ]}
              rows={fields.map((item, index) => ({
                key: item.id,
                cells: [
                  item.value,
                  <IconButton
                    key="action"
                    onClick={() => {
                      remove(index)
                    }}
                    data-cy={`deleteLinkBtn${index}`}
                  >
                    <IconX sx={{ fontSize: '20px', color: 'error.main' }} />
                  </IconButton>,
                ],
              }))}
            />
          </Box>
        )}
      </Box>
    </Fieldset>
  )
}
