import i18n, { i18n as I18n } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

type Config = {
  // Logs info level to console output. Helps finding issues with loading not working.
  debug: boolean
}

export const initI18n = async ({ debug }: Config): Promise<I18n> => {
  await i18n
    // Load translation using http -> see /public/locales
    // Learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // Detect user language
    // Learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // Pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // Init i18next
    // For all options read: https://www.i18next.com/overview/configuration-options
    .init({
      debug,
      fallbackLng: 'en',
      load: 'currentOnly',
      interpolation: {
        // Not needed for react as it escapes by default
        escapeValue: false,
      },
      backend: {
        loadPath: '/locales/{{lng}}.json',
      },
    })

  return i18n
}
