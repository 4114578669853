import { Box, Stack, Typography } from '@mui/material'
import { Children } from 'react'

import { InfoHint } from '../info-hint'

type Props = {
  children: React.ReactNode

  icon?: React.ReactNode
  legend?: string
  hint?: string
  action?: React.ReactNode
}

export const Fieldset = (props: Props) => {
  return (
    <Box
      component="fieldset"
      sx={{
        all: 'unset',
        boxSizing: 'border-box',
        width: '100%',
      }}
    >
      {props.legend && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={Children.count(props.children) > 0 ? 3 : 0}
        >
          <Box display="flex" alignItems="center">
            {props.icon && (
              <Box mr={0.75} display="flex">
                {props.icon}
              </Box>
            )}

            <Typography variant="h3">{props.legend}</Typography>
            {props.hint && <InfoHint hint={props.hint} />}
          </Box>

          {props.action && (
            <Box sx={{ flex: 'none', ml: 3, alignSelf: 'flex-start' }}>
              {props.action}
            </Box>
          )}
        </Box>
      )}

      <Stack spacing={3}>{props.children}</Stack>
    </Box>
  )
}
