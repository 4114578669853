import SvgIcon from '@mui/material/SvgIcon'

import { FilledIcon } from '../icon-props'

export const IconPencil = ({ filled, ...props }: FilledIcon) =>
  filled ? (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_2657_2394)">
        <path
          d="M12.8538 0.146393C12.76 0.0526577 12.6329 0 12.5003 0C12.3677 0 12.2406 0.0526577 12.1468 0.146393L10.4998 1.79339L14.2068 5.50039L15.8538 3.85439C15.9004 3.80795 15.9373 3.75277 15.9625 3.69203C15.9877 3.63128 16.0007 3.56616 16.0007 3.50039C16.0007 3.43463 15.9877 3.3695 15.9625 3.30876C15.9373 3.24801 15.9004 3.19284 15.8538 3.14639L12.8538 0.146393ZM13.4998 6.20739L9.79281 2.50039L3.29281 9.00039H3.49981C3.63241 9.00039 3.75959 9.05307 3.85336 9.14684C3.94713 9.24061 3.99981 9.36778 3.99981 9.50039V10.0004H4.49981C4.63241 10.0004 4.75959 10.0531 4.85336 10.1468C4.94713 10.2406 4.99981 10.3678 4.99981 10.5004V11.0004H5.49981C5.63241 11.0004 5.75959 11.0531 5.85336 11.1468C5.94713 11.2406 5.99981 11.3678 5.99981 11.5004V12.0004H6.49981C6.63241 12.0004 6.75959 12.0531 6.85336 12.1468C6.94713 12.2406 6.99981 12.3678 6.99981 12.5004V12.7074L13.4998 6.20739ZM6.03181 13.6754C6.01076 13.6194 5.99993 13.5602 5.99981 13.5004V13.0004H5.49981C5.3672 13.0004 5.24002 12.9477 5.14625 12.8539C5.05248 12.7602 4.99981 12.633 4.99981 12.5004V12.0004H4.49981C4.3672 12.0004 4.24002 11.9477 4.14625 11.8539C4.05248 11.7602 3.99981 11.633 3.99981 11.5004V11.0004H3.49981C3.3672 11.0004 3.24002 10.9477 3.14625 10.8539C3.05248 10.7602 2.99981 10.633 2.99981 10.5004V10.0004H2.49981C2.44003 10.0003 2.38075 9.98946 2.32481 9.96839L2.14581 10.1464C2.09816 10.1944 2.06073 10.2515 2.03581 10.3144L0.0358061 15.3144C-0.000564594 15.4053 -0.0094681 15.5048 0.0101994 15.6007C0.0298668 15.6966 0.0772403 15.7845 0.146447 15.8538C0.215653 15.923 0.303649 15.9703 0.399526 15.99C0.495402 16.0097 0.594942 16.0008 0.685806 15.9644L5.68581 13.9644C5.74867 13.9395 5.80582 13.902 5.85381 13.8544L6.03181 13.6764V13.6754Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2657_2394">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  ) : (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_4829_10839)">
        <path
          d="M12.1461 0.145917C12.1925 0.099354 12.2477 0.0624111 12.3084 0.0372047C12.3692 0.0119982 12.4343 -0.000976563 12.5001 -0.000976562C12.5658 -0.000976562 12.6309 0.0119982 12.6917 0.0372047C12.7524 0.0624111 12.8076 0.099354 12.8541 0.145917L15.8541 3.14592C15.9006 3.19236 15.9376 3.24754 15.9628 3.30828C15.988 3.36903 16.0009 3.43415 16.0009 3.49992C16.0009 3.56568 15.988 3.63081 15.9628 3.69155C15.9376 3.7523 15.9006 3.80747 15.8541 3.85392L5.85405 13.8539C5.80607 13.9016 5.74891 13.939 5.68605 13.9639L0.68605 15.9639C0.595186 16.0003 0.495646 16.0092 0.39977 15.9895C0.303893 15.9699 0.215897 15.9225 0.146691 15.8533C0.0774845 15.7841 0.030111 15.6961 0.0104435 15.6002C-0.00922396 15.5043 -0.000320453 15.4048 0.0360502 15.3139L2.03605 10.3139C2.06098 10.2511 2.0984 10.1939 2.14605 10.1459L12.1461 0.145917ZM11.2071 2.49992L13.5001 4.79292L14.7931 3.49992L12.5001 1.20692L11.2071 2.49992ZM12.7931 5.49992L10.5001 3.20692L4.00005 9.70692V9.99992H4.50005C4.63266 9.99992 4.75984 10.0526 4.8536 10.1464C4.94737 10.2401 5.00005 10.3673 5.00005 10.4999V10.9999H5.50005C5.63266 10.9999 5.75984 11.0526 5.8536 11.1464C5.94737 11.2401 6.00005 11.3673 6.00005 11.4999V11.9999H6.29305L12.7931 5.49992ZM3.03205 10.6749L2.92605 10.7809L1.39805 14.6019L5.21905 13.0739L5.32505 12.9679C5.22967 12.9323 5.14744 12.8684 5.08937 12.7847C5.03129 12.7011 5.00013 12.6017 5.00005 12.4999V11.9999H4.50005C4.36744 11.9999 4.24026 11.9472 4.1465 11.8535C4.05273 11.7597 4.00005 11.6325 4.00005 11.4999V10.9999H3.50005C3.39823 10.9998 3.29886 10.9687 3.21523 10.9106C3.1316 10.8525 3.06769 10.7703 3.03205 10.6749Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4829_10839">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
