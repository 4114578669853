import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconUserListFilled = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      d="M6 8C6.79565 8 7.55871 7.68393 8.12132 7.12132C8.68393 6.55871 9 5.79565 9 5C9 4.20435 8.68393 3.44129 8.12132 2.87868C7.55871 2.31607 6.79565 2 6 2C5.20435 2 4.44129 2.31607 3.87868 2.87868C3.31607 3.44129 3 4.20435 3 5C3 5.79565 3.31607 6.55871 3.87868 7.12132C4.44129 7.68393 5.20435 8 6 8ZM1 14C1 14 0 14 0 13C0 12 1 9 6 9C11 9 12 12 12 13C12 14 11 14 11 14H1ZM11 3.5C11 3.36739 11.0527 3.24021 11.1464 3.14645C11.2402 3.05268 11.3674 3 11.5 3H15.5C15.6326 3 15.7598 3.05268 15.8536 3.14645C15.9473 3.24021 16 3.36739 16 3.5C16 3.63261 15.9473 3.75979 15.8536 3.85355C15.7598 3.94732 15.6326 4 15.5 4H11.5C11.3674 4 11.2402 3.94732 11.1464 3.85355C11.0527 3.75979 11 3.63261 11 3.5ZM11.5 6C11.3674 6 11.2402 6.05268 11.1464 6.14645C11.0527 6.24021 11 6.36739 11 6.5C11 6.63261 11.0527 6.75979 11.1464 6.85355C11.2402 6.94732 11.3674 7 11.5 7H15.5C15.6326 7 15.7598 6.94732 15.8536 6.85355C15.9473 6.75979 16 6.63261 16 6.5C16 6.36739 15.9473 6.24021 15.8536 6.14645C15.7598 6.05268 15.6326 6 15.5 6H11.5ZM13.5 9C13.3674 9 13.2402 9.05268 13.1464 9.14645C13.0527 9.24021 13 9.36739 13 9.5C13 9.63261 13.0527 9.75979 13.1464 9.85355C13.2402 9.94732 13.3674 10 13.5 10H15.5C15.6326 10 15.7598 9.94732 15.8536 9.85355C15.9473 9.75979 16 9.63261 16 9.5C16 9.36739 15.9473 9.24021 15.8536 9.14645C15.7598 9.05268 15.6326 9 15.5 9H13.5ZM13.5 12C13.3674 12 13.2402 12.0527 13.1464 12.1464C13.0527 12.2402 13 12.3674 13 12.5C13 12.6326 13.0527 12.7598 13.1464 12.8536C13.2402 12.9473 13.3674 13 13.5 13H15.5C15.6326 13 15.7598 12.9473 15.8536 12.8536C15.9473 12.7598 16 12.6326 16 12.5C16 12.3674 15.9473 12.2402 15.8536 12.1464C15.7598 12.0527 15.6326 12 15.5 12H13.5Z"
      fill="currentColor"
    />
  </SvgIcon>
)
