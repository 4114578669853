import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconPaperclip = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 21">
    <path
      d="M11.3259 2.98705C12.5463 1.76666 14.5249 1.76666 15.7453 2.98705C16.9657 4.20744 16.9657 6.18608 15.7453 7.40647L7.79034 15.3614C7.05811 16.0937 5.87092 16.0937 5.13869 15.3614C4.40646 14.6292 4.40646 13.442 5.13869 12.7098L11.3259 6.52259C11.57 6.27851 11.9657 6.27851 12.2098 6.52259C12.4538 6.76666 12.4538 7.16239 12.2098 7.40647L6.02257 13.5937C5.7785 13.8377 5.7785 14.2335 6.02257 14.4775C6.26665 14.7216 6.66238 14.7216 6.90646 14.4775L14.8614 6.52259C15.5936 5.79035 15.5936 4.60317 14.8614 3.87094C14.1292 3.1387 12.942 3.1387 12.2098 3.87094L4.25481 11.8259C3.03442 13.0463 3.03442 15.0249 4.25481 16.2453C5.4752 17.4657 7.45383 17.4657 8.67422 16.2453L14.8614 10.0581C15.1055 9.81404 15.5012 9.81404 15.7453 10.0581C15.9894 10.3022 15.9894 10.6979 15.7453 10.942L9.55811 17.1292C7.84956 18.8377 5.07947 18.8377 3.37092 17.1292C1.66238 15.4206 1.66238 12.6505 3.37092 10.942L11.3259 2.98705Z"
      fill="currentColor"
    />
  </SvgIcon>
)
