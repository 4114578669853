import { createListSearchParams, ListOrder } from 'api/list-params'
import { pipe } from 'fp-ts/lib/function'
import { decodeJson, del, get, post, put } from 'lib/request'

import { TDonorCompaniesList } from './donor-companies.codecs'

export type DonorId = {
  donorId: string
}

type DonorCompanyInput = {
  name: string
  website?: string
  address: {
    countryCode?: string
    city?: string
  }
}

export const createDonorCompany = async (input: DonorCompanyInput) => {
  return await post('api/companies/me/donors', {
    body: input,
  })
}

export const updateDonorCompany = async ({
  donorId,
  ...input
}: DonorId & DonorCompanyInput) => {
  return await put('api/companies/me/donors/:donorId', {
    params: { donorId },
    body: input,
  })
}

export type GetDonorCompaniesInput = {
  order: ListOrder
}

export const getDonorCompanies = async ({ order }: GetDonorCompaniesInput) => {
  const params = createListSearchParams({ order })

  return pipe(
    await get('api/companies/me/donors', { query: params }),
    decodeJson(TDonorCompaniesList),
  )
}

export const deleteDonorCompany = async ({ donorId }: DonorId) => {
  return await del('api/companies/me/donors/:donorId', {
    params: { donorId },
  })
}
