import { SvgIconProps } from '@mui/material'
import { JobRequestStatus, QualificationLevelCode } from 'api'
import { ThemeColor } from 'app/mui-theme'
import {
  IconCheckCircleOutlineDashed,
  IconCheckCircleOutlineLarge,
  IconCircleOutlineLarge,
  IconClockOutlineDashed,
  IconCloseCircleOutlineLarge,
  IconPencilCircleOutline,
  IconPlusCircleOutline,
  IconQualificationLevelHigh,
  IconQualificationLevelLow,
  IconQualificationLevelMedium,
} from 'assets/icons'
import { ReactNode } from 'react'

export const jobRequestStatuses: Record<
  JobRequestStatus,
  {
    Icon: (props: SvgIconProps) => JSX.Element
    badgeContent?: ReactNode
    color: ThemeColor
  }
> = {
  DRAFT: {
    Icon: IconCircleOutlineLarge,
    color: '#FEA800',
  },
  PENDING_APPROVAL: {
    Icon: IconClockOutlineDashed,
    color: 'flushOrange.main',
    badgeContent: 'A',
  },
  PENDING_RECRUITER: {
    Icon: IconClockOutlineDashed,
    color: '#FEA800',
    badgeContent: 'R',
  },
  TO_AMEND: {
    Icon: IconPencilCircleOutline,
    color: 'flushOrange.main',
  },
  REJECTED: {
    Icon: IconCloseCircleOutlineLarge,
    color: 'error.main',
  },
  DELETED: {
    Icon: IconCloseCircleOutlineLarge,
    color: 'error.main',
  },
  APPROVED: {
    Icon: IconCheckCircleOutlineLarge,
    color: 'success.main',
  },
  JOB_OPEN: {
    Icon: IconCheckCircleOutlineDashed,
    color: 'success.main',
  },
  JOB_CLOSED: {
    Icon: IconCheckCircleOutlineLarge,
    color: 'blue.main',
  },
  JOB_CREATED: {
    Icon: IconPlusCircleOutline,
    color: '#FEA800',
  },
}

export const jobRequestLevelIcons: Record<
  QualificationLevelCode,
  (props: SvgIconProps) => JSX.Element
> = {
  SEN: IconQualificationLevelHigh,
  MID: IconQualificationLevelMedium,
  JUN: IconQualificationLevelLow,
  TRA: IconQualificationLevelLow,
  NONE: IconQualificationLevelLow,
}
