import { getInputError, transformNumberField } from 'lib/form-utils'
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
} from 'react-hook-form'

import { TextField, TextFieldProps } from './text-field'

// When using useFieldArray with Controller there are some TS limitations
// https://github.com/react-hook-form/react-hook-form/discussions/5585
// To prevent provide controller name like this:
// `name.${index as 0}.value` as const
export const renderTextField = <
  FormValues extends FieldValues,
  FormPath extends Path<FormValues>,
>(
  props: Omit<TextFieldProps, 'value' | 'onChange'> = {},
) => {
  return function TextFieldController({
    field,
    fieldState,
  }: {
    field: ControllerRenderProps<FormValues, FormPath>
    fieldState: ControllerFieldState
  }) {
    return (
      <TextField
        {...props}
        {...(props.type === 'number' ? transformNumberField(field) : field)}
        {...getInputError(fieldState.error)}
      />
    )
  }
}
