import SvgIcon from '@mui/material/SvgIcon'

import { FilledIcon } from '../icon-props'

export const IconCloseCircle = ({ filled, ...props }: FilledIcon) =>
  filled ? (
    <SvgIcon {...props} viewBox="0 0 17 17">
      <path
        d="M16.5 8.5C16.5 12.9183 12.9183 16.5 8.5 16.5C4.08172 16.5 0.5 12.9183 0.5 8.5C0.5 4.08172 4.08172 0.5 8.5 0.5C12.9183 0.5 16.5 4.08172 16.5 8.5ZM5.85355 5.14645C5.65829 4.95118 5.34171 4.95118 5.14645 5.14645C4.95118 5.34171 4.95118 5.65829 5.14645 5.85355L7.79289 8.5L5.14645 11.1464C4.95118 11.3417 4.95118 11.6583 5.14645 11.8536C5.34171 12.0488 5.65829 12.0488 5.85355 11.8536L8.5 9.20711L11.1464 11.8536C11.3417 12.0488 11.6583 12.0488 11.8536 11.8536C12.0488 11.6583 12.0488 11.3417 11.8536 11.1464L9.20711 8.5L11.8536 5.85355C12.0488 5.65829 12.0488 5.34171 11.8536 5.14645C11.6583 4.95118 11.3417 4.95118 11.1464 5.14645L8.5 7.79289L5.85355 5.14645Z"
        fill="currentColor"
      />
    </SvgIcon>
  ) : (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_3013_5008)">
        <path
          d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
          fill="currentColor"
        />
        <path
          d="M4.64592 4.64592C4.69236 4.59935 4.74754 4.56241 4.80828 4.5372C4.86903 4.512 4.93415 4.49902 4.99992 4.49902C5.06568 4.49902 5.13081 4.512 5.19155 4.5372C5.2523 4.56241 5.30747 4.59935 5.35392 4.64592L7.99992 7.29292L10.6459 4.64592C10.6924 4.59943 10.7476 4.56255 10.8083 4.53739C10.8691 4.51223 10.9342 4.49929 10.9999 4.49929C11.0657 4.49929 11.1308 4.51223 11.1915 4.53739C11.2522 4.56255 11.3074 4.59943 11.3539 4.64592C11.4004 4.6924 11.4373 4.74759 11.4624 4.80833C11.4876 4.86907 11.5005 4.93417 11.5005 4.99992C11.5005 5.06566 11.4876 5.13076 11.4624 5.1915C11.4373 5.25224 11.4004 5.30743 11.3539 5.35392L8.70692 7.99992L11.3539 10.6459C11.4004 10.6924 11.4373 10.7476 11.4624 10.8083C11.4876 10.8691 11.5005 10.9342 11.5005 10.9999C11.5005 11.0657 11.4876 11.1308 11.4624 11.1915C11.4373 11.2522 11.4004 11.3074 11.3539 11.3539C11.3074 11.4004 11.2522 11.4373 11.1915 11.4624C11.1308 11.4876 11.0657 11.5005 10.9999 11.5005C10.9342 11.5005 10.8691 11.4876 10.8083 11.4624C10.7476 11.4373 10.6924 11.4004 10.6459 11.3539L7.99992 8.70692L5.35392 11.3539C5.30743 11.4004 5.25224 11.4373 5.1915 11.4624C5.13076 11.4876 5.06566 11.5005 4.99992 11.5005C4.93417 11.5005 4.86907 11.4876 4.80833 11.4624C4.74759 11.4373 4.6924 11.4004 4.64592 11.3539C4.59943 11.3074 4.56255 11.2522 4.53739 11.1915C4.51223 11.1308 4.49929 11.0657 4.49929 10.9999C4.49929 10.9342 4.51223 10.8691 4.53739 10.8083C4.56255 10.7476 4.59943 10.6924 4.64592 10.6459L7.29292 7.99992L4.64592 5.35392C4.59935 5.30747 4.56241 5.2523 4.5372 5.19155C4.512 5.13081 4.49902 5.06568 4.49902 4.99992C4.49902 4.93415 4.512 4.86903 4.5372 4.80828C4.56241 4.74754 4.59935 4.69236 4.64592 4.64592Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3013_5008">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
