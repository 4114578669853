import { isValid } from 'date-fns'

export const copyTimeToDate = (date: Date, time: Date) => {
  if (!isValid(date) || !isValid(time)) {
    return null
  }
  const dateWithTime = new Date(date.getTime())
  dateWithTime.setHours(time.getHours())
  dateWithTime.setMinutes(time.getMinutes())
  dateWithTime.setSeconds(time.getSeconds())
  return dateWithTime
}
