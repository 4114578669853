import {
  createListSearchParams,
  ListOrder,
  ListPagination,
} from 'api/list-params'
import { pipe } from 'fp-ts/function'
import {
  decodeJson,
  decodeJsonWithTotal,
  del,
  get,
  patch,
  post,
  put,
} from 'lib/request'

import { InvitationId } from './book-meeting'
import {
  TContactAppliedJobs,
  TContactCommentsItem,
  TContactCompleteness,
  TContactDetails,
  TContactDetailsFromCv,
  TContactHiringExperience,
  TContactPlaceholder,
  TContactProfileLog,
  TContactRow,
  TContactWorkExperience,
  TCreateContact,
  TGenerateInvitationLink,
  TUpdateInvitationTitle,
} from './contacts.codecs'
import { TTalentsPoolsList } from './talent-pools/talent-pools.codecs'

export type ContactId = {
  contactId: string
}

export type ProfileId = {
  profileId: string
}

const CONTACT_PATH = 'api/candidates/:contactId'

export type GetContactDetailsInput = ContactId

export const getContactDetails = async ({
  contactId,
}: GetContactDetailsInput) => {
  return pipe(
    await get(CONTACT_PATH, { params: { contactId } }),
    decodeJson(TContactDetails),
  )
}

export type GetContactTalentsPoolsInput = ContactId

export const getContactTalentsPools = async ({
  contactId,
}: GetContactTalentsPoolsInput) => {
  return pipe(
    await get('api/talent-pools/contacts/:contactId', {
      params: { contactId },
    }),
    decodeJson(TTalentsPoolsList),
  )
}

export type GetContactAppliedJobsInput = ContactId

export const getContactAppliedJobs = async ({
  contactId,
}: GetContactAppliedJobsInput) => {
  return pipe(
    await get('api/jobs/applied-contacts/:contactId/apply-info', {
      params: { contactId },
    }),
    decodeJson(TContactAppliedJobs),
  )
}

export type GetContactHiringExperienceInput = ContactId

export const getContactHiringExperience = async ({
  contactId,
}: GetContactHiringExperienceInput) => {
  return pipe(
    await get('api/jobs/applied-contacts/:contactId/hiring-experience', {
      params: { contactId },
    }),
    decodeJson(TContactHiringExperience),
  )
}

export type WorkPermit = {
  country: string
  startDate: Date
  expiredDate: Date
}

export type WorkPermits = Array<WorkPermit>

export type UpdateContactInput = {
  name: string
  positionId: string
  contactInfos: Array<{
    type: string
    label: string
    value: string
  }>
  birthDate?: string
  note?: string | null
  educations?: Array<
    {
      name: string
    } & Partial<{
      startDate: string
      endDate: string
      relatedToPosition: boolean
      degree: string
      fieldOfStudy: string
    }>
  >
  sourceId?: string
  languages?: Array<{
    languageId: string
    levelCode: string
    order: number
  }>
  address?: {
    countryCode?: string
    city?: string
  }
  skills?: Array<{
    skillId: string
    skillLevelCode: string
    lastUsed: number
    order: number
  }>
  links?: Array<string>
  qualificationLevelCode?: string
  imageId?: string
  documents?: Array<string>
  workPermits?: WorkPermits
  jobExperiences: Array<{
    positionId?: string
    positionName?: string
    donorId?: string
    donorName?: string
    startDate: string
    endDate?: string
    description?: string
    jobTypeId?: string
    address?: {
      countryCode?: string
      city?: string
    }
    level?: string
    currentEmployment: boolean
  }>
  customFields: Array<{
    fieldId: string
    fieldValue: string | number | Date
  }>
}

export const createContact = async (input: UpdateContactInput) => {
  return pipe(
    await post('api/candidates', { body: input }),
    decodeJson(TCreateContact),
  )
}

export type GetContactDetailsFromCvInput = { file: File }

export const getContactDetailsFromCv = async ({
  file,
}: GetContactDetailsFromCvInput) => {
  return pipe(
    await post('api/candidates/cv', {
      body: { file },
      type: 'file',
    }),
    decodeJson(TContactDetailsFromCv),
  )
}

export const updateContact = async ({
  contactId,
  ...values
}: UpdateContactInput & ContactId) => {
  return await put(CONTACT_PATH, {
    params: { contactId },
    body: values,
  })
}

export const deleteContact = async ({ contactId }: ContactId) => {
  return await del(CONTACT_PATH, { params: { contactId } })
}

export type ContactsListStatuses = 'EMPLOYEE' | 'CANDIDATE'

type Filters = {
  level: Array<string>
  position: Array<string>
  skill: Array<string>
  country: string
  city: string
  talentPools: Array<string>
  from: Date | null
  to: Date | null
  statuses?: Array<ContactsListStatuses>
  sources?: Array<string>
}

export type GetContactsListInput = {
  pagination: ListPagination
  order?: ListOrder
  filters?: Filters
  search?: string
  jobExclude?: string
  talentPoolExclude?: string
  matchingJobId?: string
}

const generateFilters = (filters: Filters) => {
  const result = []

  if (filters.level.length > 0) {
    result.push({
      filterType: 'levelsFilter',
      levels: filters.level,
    })
  }

  if (filters.position.length > 0) {
    result.push({
      filterType: 'positionsFilter',
      positions: filters.position,
    })
  }

  if (filters.skill.length > 0) {
    result.push({
      filterType: 'skillsFilter',
      skills: filters.skill,
    })
  }

  if (filters.country || filters.city) {
    result.push({
      filterType: 'locationFilter',
      countryCode: filters.country || undefined,
      city: filters.city || undefined,
    })
  }

  if (filters.talentPools.length > 0) {
    result.push({
      filterType: 'talentPoolsFilter',
      talentPools: filters.talentPools,
    })
  }

  if (filters.statuses) {
    result.push({
      filterType: 'statusFilter',
      statuses: filters.statuses,
    })
  }

  if (filters.from || filters.to) {
    result.push({
      filterType: 'createdAtFilter',
      from: filters.from ?? undefined,
      to: filters.to ?? undefined,
    })
  }

  if (filters.sources) {
    result.push({
      filterType: 'sourcesFilter',
      sources: filters.sources,
    })
  }

  return result
}

export const getContactsList = async ({
  pagination,
  filters,
  order,
  search,
  jobExclude,
  talentPoolExclude,
  matchingJobId,
}: GetContactsListInput) => {
  const params = createListSearchParams({ pagination, order })

  if (jobExclude) {
    params.set('jobExclude', jobExclude)
  }

  if (talentPoolExclude) {
    params.set('talentPoolExclude', talentPoolExclude)
  }

  if (matchingJobId) {
    params.set('matchingJobId', matchingJobId)
  }

  const generatedFilters = filters ? generateFilters(filters) : []

  return pipe(
    await post('api/candidates/list', {
      query: params,
      body: {
        search: search !== '' ? search : undefined,
        filter: {
          filters: generatedFilters,
        },
      },
    }),
    decodeJsonWithTotal(TContactRow, pagination),
  )
}

export type SearchContactsInput = {
  fullName?: string
  pagination: ListPagination
}

export const searchContacts = async ({
  fullName,
  pagination,
}: SearchContactsInput) => {
  const params = createListSearchParams({ pagination })

  if (fullName !== undefined) {
    params.set('name', fullName)
  }

  return pipe(
    await get('api/candidates/search', { query: params }),
    decodeJsonWithTotal(TContactRow, pagination),
  )
}

export type GetContactCompletenessInfoInput = ContactId

export const getContactCompletenessInfo = async ({
  contactId,
}: GetContactCompletenessInfoInput) => {
  return pipe(
    await get('api/candidates/:contactId/completeness', {
      params: { contactId },
    }),
    decodeJson(TContactCompleteness),
  )
}

export type GetContactHistoryInput = ContactId & {
  pagination: ListPagination
}

export const getContactHistory = async ({
  contactId,
  pagination,
}: GetContactHistoryInput) => {
  const params = createListSearchParams({ pagination })

  return pipe(
    await get('api/candidates/:contactId/history', {
      query: params,
      params: { contactId },
    }),
    decodeJsonWithTotal(TContactProfileLog, pagination),
  )
}

export type GetContactWorkExperienceInput = ContactId

export const getContactWorkExperience = async ({
  contactId,
}: GetContactWorkExperienceInput) => {
  return pipe(
    await get('api/candidates/:contactId/work-experience', {
      params: { contactId },
    }),
    decodeJson(TContactWorkExperience),
  )
}

export type GetContactCommentsInput = ProfileId & {
  pagination: ListPagination
  order: ListOrder
}

export const getContactComments = async ({
  profileId,
  pagination,
  order,
}: GetContactCommentsInput) => {
  const params = createListSearchParams({ pagination, order })
  params.set('profileId', profileId)
  return pipe(
    await get('api/comments', {
      query: params,
    }),
    decodeJsonWithTotal(TContactCommentsItem, pagination),
  )
}

export const addContactComment = async ({
  profileId,
  message,
}: ProfileId & { message: string }) => {
  return await post('api/comments', {
    query: new URLSearchParams({ profileId }),
    body: { message },
  })
}

type CommentId = {
  commentId: string
}

export const editContactComment = async ({
  commentId,
  message,
}: CommentId & { message: string }) => {
  return pipe(
    await put('api/comments/:commentId', {
      params: { commentId },
      body: { message },
    }),
    decodeJson(TContactCommentsItem),
  )
}

export const deleteContactComment = async ({ commentId }: CommentId) =>
  await del('api/comments/:commentId', {
    params: { commentId },
  })

export type GenerateInvitationLinkInput = ContactId & { jobId?: string }

export const generateInvitationLink = async ({
  contactId,
  jobId,
}: GenerateInvitationLinkInput) => {
  return pipe(
    await post(`api/contacts/:contactId/invitations`, {
      params: { contactId },
      query: new URLSearchParams({
        eventType: 'INTERVIEW',
        ...(jobId && { jobId }),
      }),
    }),
    decodeJson(TGenerateInvitationLink),
  )
}

export const renewInvitationLink = async ({
  contactId,
  invitationId,
}: ContactId & InvitationId) => {
  return await put(`api/contacts/:contactId/invitations/:invitationId/renew`, {
    params: { contactId, invitationId },
  })
}

export type UpdateInvitationTitleInput = ContactId &
  InvitationId & {
    title: string
  }

export const updateInvitationTitle = async ({
  contactId,
  invitationId,
  title,
}: UpdateInvitationTitleInput) => {
  return pipe(
    await patch(`api/contacts/:contactId/invitations/:invitationId/title`, {
      params: { contactId, invitationId },
      body: { title },
    }),
    decodeJson(TUpdateInvitationTitle),
  )
}

export type GetContactPlaceholderInput = ContactId

export const getContactPlaceholder = async ({
  contactId,
}: GetContactPlaceholderInput) => {
  return pipe(
    await get('api/candidates/:contactId/placeholder', {
      params: { contactId },
    }),
    decodeJson(TContactPlaceholder),
  )
}
