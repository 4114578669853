import { InputAdornment } from '@mui/material'
import { IconClose } from 'assets/icons'
import { IconSearchLg } from 'assets/icons'
import { InfoHint } from 'components/common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'ui/inputs/icon-button'
import { TextField } from 'ui/inputs/text-field'

type Props = {
  onInputChange: (value: string) => void
}

/**
 * Component for searching contacts with a TextField input.
 *
 * @param {Props} props - Component props.
 * @param {Function} props.onInputChange - Callback function to handle input change.
 * @returns {JSX.Element} - React component for contacts search.
 */
export const ContactsSearch = ({ onInputChange }: Props) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<string>('')
  return (
    <TextField
      placeholder={t('common.search_contacts')}
      inputProps={{ maxLength: 250 }}
      startAdornment={
        <InputAdornment position="start">
          <IconSearchLg sx={{ color: 'mischka', fontSize: '16px' }} />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          {value.trim() ? (
            <IconButton
              sizePx={24}
              onClick={() => {
                setValue('')
                onInputChange('')
              }}
            >
              <IconClose sx={{ color: 'greyBlue.light', fontSize: '20px' }} />
            </IconButton>
          ) : (
            <InfoHint
              hint={t('hints.contacts_search_info')}
              placement="right"
              alignSelf="center"
            />
          )}
        </InputAdornment>
      }
      value={value}
      onChange={newValue => {
        setValue(newValue)
        onInputChange(newValue)
      }}
    />
  )
}
