import { createListSearchParams, ListPagination } from 'api/list-params'
import { pipe } from 'fp-ts/lib/function'
import { decodeJson, decodeJsonWithTotal, del, get, post } from 'lib/request'

import { TGlobalSearchHistoryItem, TGlobalSearchResult } from './search.codecs'

export type GlobalSearchInput = {
  search: string
}

export const searchGlobally = async ({ search }: GlobalSearchInput) => {
  return pipe(
    await post('api/global-search', {
      body: {
        search: search.trim(),
      },
    }),
    decodeJson(TGlobalSearchResult),
  )
}

export type GlobalSearchHistoryInput = { pagination: ListPagination }

export const getGlobalSearchHistory = async ({
  pagination,
}: GlobalSearchHistoryInput) => {
  const params = createListSearchParams({ pagination })
  return pipe(
    await get('api/global-search/history', {
      query: params,
    }),
    decodeJsonWithTotal(TGlobalSearchHistoryItem, pagination),
  )
}

export const deleteGlobalSearchHistory = async () => {
  return await del('api/global-search/history', {})
}
