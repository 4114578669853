import { pipe } from 'fp-ts/function'
import { Format24Hours } from 'lib/js-utils'
import { decodeJson, get, put } from 'lib/request'

import {
  Availabilities,
  TAvailabilities,
  TSchedulingLinksSettings,
  TSpecificAvailabilities,
} from './scheduling-links.codecs'

export const getSchedulingLinksMeetingDetails = async () => {
  const params = new URLSearchParams({ eventType: 'INTERVIEW' })

  return pipe(
    await get('api/users/me/settings/schedules/basic', { query: params }),
    decodeJson(TSchedulingLinksSettings),
  )
}

export const getSchedulingLinksRegularAvailabilities = async () => {
  const params = new URLSearchParams({ eventType: 'INTERVIEW' })

  return pipe(
    await get('api/users/me/settings/schedules/availabilities/regular', {
      query: params,
    }),
    decodeJson(TAvailabilities),
  )
}

export const getSchedulingLinksSpecificAvailabilities = async () => {
  const params = new URLSearchParams({ eventType: 'INTERVIEW' })

  return pipe(
    await get('api/users/me/settings/schedules/availabilities/specific', {
      query: params,
    }),
    decodeJson(TSpecificAvailabilities),
  )
}

type UpdateSchedulingLinksMeetingDetailsInput = {
  body: {
    eventType: string
    meetingDurationMin: number
    bufferTimeMin: number | null
    meetingsPerDay: number | null
    displayRangeDay: number | null
    linkLifetimeMin: number | null
  }
}

export const updateSchedulingLinksMeetingDetails = async ({
  body,
}: UpdateSchedulingLinksMeetingDetailsInput) => {
  return await put('api/users/me/settings/schedules/basic', { body })
}

type UpdateSchedulingLinksRegularAvailability = {
  body: {
    availabilities: Availabilities<Format24Hours>
    eventType: string
  }
}

export const updateSchedulingLinksRegularAvailability = async ({
  body,
}: UpdateSchedulingLinksRegularAvailability) => {
  return await put('api/users/me/settings/schedules/availabilities/regular', {
    body,
  })
}

type UpdateSchedulingLinksSpecificAvailability = {
  body: {
    availabilities: Array<{
      date: string
      active: boolean
      period: {
        timeFrom: string
        timeTo: string
      }
    }>
    eventType: string
  }
}

export const updateSchedulingLinksSpecificAvailability = async ({
  body,
}: UpdateSchedulingLinksSpecificAvailability) => {
  return await put('api/users/me/settings/schedules/availabilities/specific', {
    body,
  })
}
