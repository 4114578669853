import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCloseLarge = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.781 3.21888C20.8508 3.28854 20.9062 3.37131 20.944 3.46243C20.9818 3.55354 21.0013 3.65122 21.0013 3.74988C21.0013 3.84853 20.9818 3.94621 20.944 4.03733C20.9062 4.12844 20.8508 4.21121 20.781 4.28088L4.28097 20.7809C4.14014 20.9217 3.94913 21.0008 3.74997 21.0008C3.55081 21.0008 3.3598 20.9217 3.21897 20.7809C3.07814 20.64 2.99902 20.449 2.99902 20.2499C2.99902 20.0507 3.07814 19.8597 3.21897 19.7189L19.719 3.21888C19.7886 3.14903 19.8714 3.09362 19.9625 3.05581C20.0536 3.018 20.1513 2.99854 20.25 2.99854C20.3486 2.99854 20.4463 3.018 20.5374 3.05581C20.6285 3.09362 20.7113 3.14903 20.781 3.21888Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.21912 3.21888C3.14928 3.28854 3.09386 3.37131 3.05605 3.46243C3.01824 3.55354 2.99878 3.65122 2.99878 3.74988C2.99878 3.84853 3.01824 3.94621 3.05605 4.03733C3.09386 4.12844 3.14928 4.21121 3.21912 4.28088L19.7191 20.7809C19.8599 20.9217 20.051 21.0008 20.2501 21.0008C20.4493 21.0008 20.6403 20.9217 20.7811 20.7809C20.9219 20.64 21.0011 20.449 21.0011 20.2499C21.0011 20.0507 20.9219 19.8597 20.7811 19.7189L4.28112 3.21888C4.21145 3.14903 4.12869 3.09362 4.03757 3.05581C3.94645 3.018 3.84877 2.99854 3.75012 2.99854C3.65147 2.99854 3.55379 3.018 3.46267 3.05581C3.37155 3.09362 3.28879 3.14903 3.21912 3.21888Z"
      fill="currentColor"
    />
  </SvgIcon>
)
