import { Box, Typography } from '@mui/material'

type Props = {
  status: string
  backgroundColor: string
  cornerColor: string
}

export const StatusBadge = (props: Props) => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        px: 1.5,
        boxShadow:
          '0px 1px 2px rgba(50, 50, 93, 0.12), 0px 1px 3px rgba(50, 50, 93, 0.08)',
        borderRadius: 0.5,
        borderBottomLeftRadius: 0,
        bgcolor: props.backgroundColor,

        '&::before': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          transform: 'translateY(100%)',
          width: '6px',
          height: '6px',
          clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%)',
          bgcolor: props.cornerColor,
        },
      }}
    >
      <Typography variant="body2">{props.status}</Typography>
    </Box>
  )
}
