import { useQuery } from '@tanstack/react-query'
import { DictionaryType, getDictionaries, qk } from 'api'
import { useCallback } from 'react'

/**
 * Custom hook to manage dictionaries and their data fetching.
 *
 * @returns {Object} - Object containing dictionaries, error status, and utility functions.
 */
export const useDictionaries = () => {
  const $dictionaries = useQuery(qk.dictionaries.toKey(), getDictionaries, {
    staleTime: Number.POSITIVE_INFINITY,
  })

  /**
   * Function to find the label of a dictionary item based on its code and type.
   *
   * @param {string} code - Code of the dictionary item.
   * @param {DictionaryType} type - Type of the dictionary.
   * @returns {string | undefined} - Label of the dictionary item if found; otherwise, undefined.
   */
  const findDictionaryLabel = useCallback(
    (code: string, type: DictionaryType) => {
      return (
        $dictionaries.data &&
        $dictionaries.data[type].find(dictionary => dictionary.code === code)
          ?.name
      )
    },
    [$dictionaries.data],
  )

  /**
   * Function to retrieve dictionary values and options based on the dictionary type.
   *
   * @param {DictionaryType} type - Type of the dictionary.
   * @returns {Object} - Object containing data and options arrays for the specified dictionary type.
   */
  const values = (type: DictionaryType) => {
    return $dictionaries.data
      ? {
          data: $dictionaries.data[type],
          options: $dictionaries.data[type].map(entity => ({
            value: entity.code,
            label: entity.name,
          })),
        }
      : { data: [], options: [] }
  }

  return {
    dictionaries: {
      countries: values('COUNTRY'),
      qualificationLevels: values('QUALIFICATION_LEVEL'),
      skillLevels: values('SKILL_LEVEL'),
      languageLevels: values('LANGUAGE_LEVELS'),
      jobStatuses: values('JOB_STATUS'),
      jobModels: values('JOB_MODELS'),
      messengerTypes: values('MESSENGER_TYPES'),
      companyIndustries: values('INDUSTRY'),
      companySizes: values('TEAM_MEMBERS'),
      companyLanguages: values('COMPANY_LANGUAGES'),
    },
    isDictionariesError: $dictionaries.isError,
    findDictionaryLabel,
  }
}
