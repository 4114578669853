import { SvgIconProps } from '@mui/material'
import { UserStatus } from 'api'
import { ThemeColor } from 'app/mui-theme'
import {
  IconCheckCircleOutlineLarge,
  IconDenyOutline,
  IconPlusCircleOutline,
} from 'assets/icons'

export const userStatuses: Record<
  UserStatus,
  {
    label: string
    Icon: (props: SvgIconProps<'svg', {}>) => JSX.Element
    color: ThemeColor
  }
> = {
  INVITED: {
    label: 'Invited',
    Icon: IconPlusCircleOutline,
    color: 'flushOrange.main',
  },
  ACTIVE: {
    label: 'Active',
    Icon: IconCheckCircleOutlineLarge,
    color: 'success.main',
  },
  INACTIVE: {
    label: 'Inactive',
    Icon: IconDenyOutline,
    color: 'error.main',
  },
}
