import { Box, Stack, Typography } from '@mui/material'
import { ContactsTalentsPoolDetails } from 'api'
import { useScopes } from 'app/scopes'
import { IconTalentsPool } from 'assets/icons'
import { IconPlusCircle } from 'assets/icons'
import { useBoolean } from 'lib/react-utils'
import { ApplyToJobDialog } from 'pages/contacts/apply-to-job'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'

import { AddContactsToTalentsPool } from './add-to-talents-pool'
import { CreateTalentsPoolButton } from './create-talents-pool'
import { DeleteFromTalentsPool } from './delete-from-talents-pool'

type Props = {
  selectedIds: Array<string>
  onSuccessfulChange?: () => void
  talentsPool?: ContactsTalentsPoolDetails
}

export const ContactsBulkActions = ({
  selectedIds,
  talentsPool,
  onSuccessfulChange,
}: Props) => {
  const { t } = useTranslation()
  const scopes = useScopes()
  const isApplyToJobOpen = useBoolean()

  return (
    <Stack direction="row" spacing={4} alignItems="center">
      <Typography variant="caption" noWrap>
        <Trans
          i18nKey={'common.selected_count_one'}
          values={{ count: selectedIds.length }}
          components={{
            countBox: (
              <Box
                display="inline-block"
                bgcolor="mediumPurple.main"
                color="white"
                width="16px"
                height="16px"
                borderRadius="4px"
                textAlign="center"
                mr="2px"
              />
            ),
          }}
        />
      </Typography>

      <Stack direction="row" spacing={1} alignItems="center">
        {selectedIds[0] && selectedIds.length <= 1 && (
          <>
            <Button
              startIcon={<IconPlusCircle />}
              iconSize="14px"
              variant="outlined"
              size="medium"
              onClick={isApplyToJobOpen.setTrue}
            >
              {t('common.apply_for_the_job')}
            </Button>

            <ApplyToJobDialog
              isOpen={isApplyToJobOpen.isTrue}
              onClose={isApplyToJobOpen.setFalse}
              contactId={selectedIds[0]}
            />
          </>
        )}

        {scopes.canManageTalentsPool && (
          <CreateTalentsPoolButton
            actionText={t('common.create_talent_pool')}
            buttonProps={{
              variant: 'outlined',
              size: 'medium',
            }}
            contactsIds={selectedIds}
            icon={<IconTalentsPool />}
          />
        )}

        {scopes.canManageTalentsPool && (
          <AddContactsToTalentsPool
            contactsIds={selectedIds}
            buttonProps={{
              variant: 'outlined',
              size: 'medium',
            }}
          />
        )}

        {scopes.canManageTalentsPool && talentsPool && talentsPool.modifiable && (
          <DeleteFromTalentsPool
            talentPoolId={talentsPool.talentPoolId}
            contacts={selectedIds}
            onSuccessfulChange={onSuccessfulChange}
            buttonProps={{
              variant: 'outlined',
              size: 'medium',
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}
