import { TProfile } from 'api/common'
import { TCompanyPosition } from 'api/company'
import { TJobStatusCode } from 'api/jobs'
import * as t from 'io-ts'

const TSearchedContact = t.intersection([
  t.strict({
    candidateProfileId: t.string,
    name: t.string,
    position: TCompanyPosition,
  }),
  t.partial({
    imageUri: t.string,
  }),
])

const TSearchedJob = t.strict({
  jobId: t.string,
  position: TCompanyPosition,
  status: TJobStatusCode,
  recruiters: t.array(TProfile),
})

export const TGlobalSearchResult = t.strict({
  contactSearchResult: t.strict({
    content: t.array(TSearchedContact),
    count: t.number,
  }),
  jobSearchResult: t.strict({
    content: t.array(TSearchedJob),
    count: t.number,
  }),
})

export const TGlobalSearchHistoryItem = t.string
