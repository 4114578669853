import { pipe } from 'fp-ts/lib/function'
import { decodeJson, del, get, patch, post } from 'lib/request'

import { TCreatedPaymentMethod, TPaymentMethods } from './finance.codecs'

export type PaymentMethodId = {
  paymentMethodId: string
}

export const createPaymentMethod = async () => {
  return pipe(
    await post('finance/me/sources', {}),
    decodeJson(TCreatedPaymentMethod),
  )
}

export const challengePaymentMethod = async (
  input: PaymentMethodId & {
    payload: string
  },
) => {
  return await post('finance/me/sources/challenge', { body: input })
}

export const getPaymentMethods = async () => {
  return pipe(await get('finance/me/sources', {}), decodeJson(TPaymentMethods))
}

type SourceId = {
  sourceId: string
}

export const deletePaymentMethod = async ({ sourceId }: SourceId) => {
  return await del('finance/me/sources/:sourceId', { params: { sourceId } })
}

export type UpdatePaymentMethodInput = SourceId &
  (
    | { default: boolean }
    | {
        expiryMonth: number
        expiryYear: number
      }
  )

export const updatePaymentMethod = async ({
  sourceId,
  ...body
}: UpdatePaymentMethodInput) => {
  return await patch('finance/me/sources/:sourceId', {
    params: { sourceId },
    body,
  })
}
