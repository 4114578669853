import {
  styled,
  TableRow as MuiTableRow,
  tableRowClasses,
  TableRowProps as MuiTableRowProps,
} from '@mui/material'

export interface TableRowProps extends MuiTableRowProps {
  highlighted?: boolean
  oddHighlighted?: boolean
}

const hover = `&.${tableRowClasses.hover}:hover`

export const TableRow = styled(MuiTableRow, {
  shouldForwardProp: prop =>
    prop !== 'highlighted' && prop !== 'oddHighlighted',
})<TableRowProps>(({ theme, highlighted, oddHighlighted }) => ({
  [hover]: {
    backgroundColor: theme.palette.background.default,
  },

  ...(highlighted && {
    backgroundColor: theme.palette.alabaster,
  }),

  ...(oddHighlighted && {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  }),
}))
