import { Box, Collapse, Stack, Typography } from '@mui/material'
import {
  IconCloseFullscreen,
  IconCloseLarge,
  IconDash,
  IconOpenFullscreen,
} from 'assets/icons'
import { useBoolean } from 'lib/react-utils'
import { ReactNode } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'

import { ResizableWindowContainer } from './resizable-window-container'
import { ResizableWindowIconButton } from './resizable-window-icon-button'

type Props = Readonly<{
  title: string
  children: ReactNode
  isOpen: boolean
  onClose: () => void
  position?: 'fullscreen' | 'minimized'
}>

export const ResizableWindowTemplate = ({
  title,
  onClose,
  children,
  isOpen,
  position,
}: Props) => {
  const { t } = useTranslation()

  const isMinimized = useBoolean()
  const isFullScreen = useBoolean(position === 'fullscreen')

  if (!isOpen) return null

  return ReactDOM.createPortal(
    <ResizableWindowContainer
      isFullScreen={isFullScreen.isTrue && isMinimized.isFalse}
    >
      <Box
        component="header"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pt: 2,
          px: 3,
          pb: 2.5,
        }}
      >
        <Typography variant="h2">{title}</Typography>

        <Stack direction="row" spacing={1}>
          <ResizableWindowIconButton
            tooltip={
              isMinimized.isTrue ? t('common.maximize') : t('common.minimize')
            }
            onClick={isMinimized.toggle}
          >
            <IconDash />
          </ResizableWindowIconButton>

          <ResizableWindowIconButton
            tooltip={
              isFullScreen.isTrue
                ? t('common.exit_full_screen')
                : t('common.full_screen')
            }
            onClick={() => {
              isFullScreen.toggle()
              isMinimized.setFalse()
            }}
          >
            {isFullScreen.isTrue ? (
              <IconCloseFullscreen />
            ) : (
              <IconOpenFullscreen />
            )}
          </ResizableWindowIconButton>

          <ResizableWindowIconButton
            tooltip={t('common.close')}
            onClick={onClose}
          >
            <IconCloseLarge />
          </ResizableWindowIconButton>
        </Stack>
      </Box>

      <Collapse in={isMinimized.isFalse}>{children}</Collapse>
    </ResizableWindowContainer>,
    document.querySelector('#portal-root')!,
  )
}
