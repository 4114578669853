import { LinearProgress } from '@mui/material'
import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import { qk } from 'api'

/**
 * Displays a global loading indicator while:
 * - Any query is fetching (hard loading / background update).
 * - Any mutation is running.
 */
export const GlobalLoadingIndicator = () => {
  const queries = useIsFetching({
    // ? Very weird bug happens without filtering current subscription query here
    // SubscriptionChecker component (which calls current subscription query) gets stuck in an infinite loop
    // It appeared after updating react-query version from 3 to 4
    predicate: query => {
      const isCurrentSubscriptionQuery =
        JSON.stringify(query.queryKey) ===
        JSON.stringify(qk.subscriptions.currentSubscription.toKey())

      return !isCurrentSubscriptionQuery
    },
  })

  const mutations = useIsMutating()

  if (queries === 0 && mutations === 0) {
    return null
  }

  return (
    <LinearProgress
      sx={{
        position: 'absolute',
        zIndex: 9999,
        top: 0,
        left: 0,
        right: 0,
        height: '2px',
      }}
    />
  )
}
