import { LanguageLevel } from 'api'
import {
  IconLevelsCritical,
  IconLevelsHigh,
  IconLevelsHighest,
  IconLevelsMedium,
} from 'assets/icons'
import { IconLevelsLow } from 'assets/icons'
import { IconLevelsLowest } from 'assets/icons'
import { IconLevelsMinor } from 'assets/icons'
import { useTranslation } from 'react-i18next'
import { Badge } from 'ui/data'

const indicators: Record<LanguageLevel, { icon: React.ReactNode }> = {
  BEGINNER: {
    icon: <IconLevelsMinor sx={{ color: '#F1010A', fontSize: '14px' }} />,
  },
  ELEMENTARY: {
    icon: (
      <IconLevelsLowest sx={{ color: 'flushOrange.main', fontSize: '14px' }} />
    ),
  },
  PRE_INTERMEDIATE: {
    icon: <IconLevelsLow sx={{ color: '#FEA800', fontSize: '14px' }} />,
  },
  INTERMEDIATE: {
    icon: <IconLevelsMedium sx={{ color: 'primary.main', fontSize: '14px' }} />,
  },
  UPPER_INTERMEDIATE: {
    icon: <IconLevelsHigh sx={{ color: 'blue.main', fontSize: '14px' }} />,
  },
  ADVANCED: {
    icon: <IconLevelsHighest sx={{ color: '#4D00F0', fontSize: '14px' }} />,
  },
  NATIVE: {
    icon: <IconLevelsCritical sx={{ color: '#A300F0', fontSize: '14px' }} />,
  },
}

type Props = {
  levelCode: LanguageLevel
  name: string
}

export const LanguageBadge = (props: Props) => {
  const indicator = indicators[props.levelCode]
  const { t } = useTranslation()
  const language = t(`common.language_level.${props.levelCode}`)
  return (
    <Badge background="white" icon={indicator.icon}>
      {`${props.name}: ${language}`}
    </Badge>
  )
}
