import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconBell03 = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 22"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M12.9997 18C12.9997 19.6569 11.6566 21 9.99972 21C8.34287 21 6.99972 19.6569 6.99972 18M11.7962 5.23856C12.2317 4.78864 12.4997 4.17562 12.4997 3.5C12.4997 2.11929 11.3804 1 9.99972 1C8.61901 1 7.49972 2.11929 7.49972 3.5C7.49972 4.17562 7.76772 4.78864 8.20322 5.23856M15.9997 10.2C15.9997 8.82087 15.3676 7.49823 14.2424 6.52304C13.1171 5.54786 11.591 5 9.99972 5C8.40842 5 6.8823 5.54786 5.75708 6.52304C4.63186 7.49823 3.99972 8.82087 3.99972 10.2C3.99972 12.4818 3.43385 14.1506 2.72778 15.3447C1.92306 16.7056 1.5207 17.3861 1.53659 17.5486C1.55476 17.7346 1.58824 17.7933 1.73906 17.9036C1.87089 18 2.53323 18 3.85791 18H16.1415C17.4662 18 18.1286 18 18.2604 17.9036C18.4112 17.7933 18.4447 17.7346 18.4629 17.5486C18.4787 17.3861 18.0764 16.7056 17.2717 15.3447C16.5656 14.1506 15.9997 12.4818 15.9997 10.2Z"
      stroke="currentColor"
      strokeWidth={props.stroke ?? '1.4'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
