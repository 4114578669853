import { Box, Stack, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { ContactCommentsItem } from 'api'
import { deleteContactComment } from 'api'
import { differenceInHours, formatDistanceToNow } from 'date-fns'
import { useConfirmationDialog } from 'lib/app-helpers'
import { formatDate } from 'lib/js-utils'
import { useBoolean } from 'lib/react-utils'
import { showToast } from 'lib/toast'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { Avatar } from 'ui/data'

import { CommentActionButton } from './comment-action-button'
import { EditCommentForm } from './edit-comment-form'

const getCommentDate = (date: Date) => {
  return differenceInHours(date, new Date()) < 24
    ? formatDistanceToNow(date, { addSuffix: true })
    : formatDate(date)
}

type Props = {
  comment: ContactCommentsItem
  isLast: boolean
  refetch: () => void
  onLastVisible?: () => void
}

export const Comment = ({ comment, isLast, onLastVisible, refetch }: Props) => {
  const { t } = useTranslation()

  const isEditing = useBoolean(false)
  const { ref, inView } = useInView({
    skip: !isLast,
    triggerOnce: true,
    fallbackInView: true,
  })

  const $deleteContactComment = useMutation(deleteContactComment)

  const deleteDialog = useConfirmationDialog<string>(commentId => {
    $deleteContactComment.mutate(
      {
        commentId,
      },
      {
        onSuccess: () => {
          showToast({
            title: t('toasts.deleted_comment'),
          })
          refetch()
        },
      },
    )
  })

  useEffect(() => {
    if (inView && onLastVisible && isLast) {
      onLastVisible()
    }
  }, [inView, onLastVisible, isLast])

  return (
    <Box width={1} p={2} borderBottom={1} borderColor="divider">
      {isEditing.isTrue ? (
        <EditCommentForm
          message={comment.message}
          commentId={comment.id}
          onCancel={isEditing.setFalse}
          refetch={refetch}
          onEdit={() => isEditing.setFalse()}
        />
      ) : (
        <>
          <Typography mb={1}>{comment.message}</Typography>
          <Stack direction="row" spacing={1.5} mb={1}>
            {comment.editable && (
              <CommentActionButton onClick={isEditing.setTrue}>
                {t('common.edit')}
              </CommentActionButton>
            )}
            {comment.deletable && (
              <CommentActionButton
                onClick={() => deleteDialog.openDialog(comment.id)}
              >
                {t('common.delete')}
              </CommentActionButton>
            )}
          </Stack>
        </>
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        width={1}
        mb={0.25}
        alignItems="center"
        ref={ref}
      >
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Avatar src={comment.creatorProfile.avatar} size={18} />
          <Typography variant="caption" color="text.secondary">
            {comment.creatorProfile.fullName}
          </Typography>
        </Stack>

        <Typography variant="caption" color="text.secondary">
          {getCommentDate(comment.createdAt)}
        </Typography>
      </Stack>

      {deleteDialog.renderConfirmDialog({
        size: 'small',
        variant: 'danger',
        title: t('common.delete'),
        children: t('confirms.contacts_confirm_delete_comment'),
      })}
    </Box>
  )
}
