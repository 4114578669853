import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconArrowRevertOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      d="M10 18.75C5.16751 18.75 1.25 14.8325 1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10C18.75 14.8325 14.8325 18.75 10 18.75ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="currentColor"
    />
    <path
      d="M13.7501 10.625C13.7501 8.89913 12.351 7.50002 10.6251 7.50002H8.75014V5.66722C8.75014 5.40227 8.44112 5.25754 8.23758 5.42715L5.28822 7.88495C5.1383 8.00989 5.1383 8.24016 5.28822 8.36509L8.23758 10.8229C8.44112 10.9925 8.75014 10.8478 8.75014 10.5828V8.75002H10.6251C11.6607 8.75002 12.5001 9.58949 12.5001 10.625V13.75H13.7501V10.625Z"
      fill="currentColor"
    />
  </SvgIcon>
)
