import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconPencilCircleOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g clipPath="url(#clip0_6359_56118)">
      <path
        d="M12 22.5C9.21523 22.5 6.54451 21.3938 4.57538 19.4246C2.60625 17.4555 1.5 14.7848 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
        fill="currentColor"
      />
      <path
        d="M17.8152 7.5065C17.9109 7.60249 17.9646 7.7325 17.9646 7.86804C17.9646 8.00358 17.9109 8.1336 17.8152 8.22959L16.747 9.29885L14.6986 7.25045L15.7668 6.18119C15.8629 6.08518 15.9931 6.03125 16.1289 6.03125C16.2647 6.03125 16.3949 6.08518 16.4909 6.18119L17.8152 7.50548V7.5065ZM16.0229 10.0219L13.9745 7.97354L6.99659 14.9524C6.94022 15.0088 6.89778 15.0775 6.87266 15.1532L6.04818 17.6256C6.03323 17.6707 6.0311 17.719 6.04205 17.7652C6.05299 17.8114 6.07657 17.8537 6.11015 17.8873C6.14373 17.9209 6.18599 17.9444 6.2322 17.9554C6.27841 17.9663 6.32675 17.9642 6.37183 17.9493L8.84425 17.1248C8.9198 17.0999 8.98853 17.0579 9.04499 17.0019L16.0229 10.023V10.0219Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6359_56118">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
