import { Stack } from '@mui/material'
import {
  PipelineTemplateForm,
  PipelineTemplatePaper,
} from 'components/settings'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'
import { v4 as uuid } from 'uuid'

import { JobPipelineTemplateDraft } from '../manual-settings-setup'

type Props = Readonly<{
  onClose: () => void
  onCreate: (newTemplate: JobPipelineTemplateDraft) => void
}>

export const CreatePipelineTemplate = ({ onClose, onCreate }: Props) => {
  const { t } = useTranslation()
  const FORM_ID = 'create-pipeline-template-form'

  return (
    <PipelineTemplatePaper name={t('jobs.new_pipeline_template')}>
      <PipelineTemplateForm
        formId={FORM_ID}
        onSubmit={values => {
          // Generating fake ids, so that we are able to find and update this pipeline later.
          // Why? Because in manual setup wizard we have to keep and manage the whole list of pipeline templates in state
          // Then a single request with all configured settings (including pipeline templates list) will be sent at the end of the setup
          onCreate({
            isNewTemplate: true,
            pipelineId: uuid(),
            name: values.templateName,
            stages: values.templateStages.map((stage, index) => ({
              name: stage.name,
              order: index + 1,
              canHire: stage.canHire,
            })),
            defaultPipeline: values.default,
          })
          onClose()
        }}
      />

      <Stack direction="row" spacing={2} mt={3}>
        <Button variant="outlined" color="greyBlue" onClick={onClose}>
          {t('common.cancel')}
        </Button>

        <Button type="submit" form={FORM_ID}>
          {t('common.create')}
        </Button>
      </Stack>
    </PipelineTemplatePaper>
  )
}
