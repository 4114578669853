import { addMinutes, isValid, parse, startOfDay } from 'date-fns'
import { format } from 'date-fns/fp'
import {
  format24HoursTo12HoursTime,
  formatDateTo24HoursTime,
} from 'lib/js-utils'
import { useEffect, useMemo, useRef, useState } from 'react'
import InputMask, { InputState } from 'react-input-mask'

import { AutocompleteFreeSolo, AutocompleteFreeSoloProps } from './autocomplete'
import { InputBase } from './common'

type Props = Omit<
  AutocompleteFreeSoloProps,
  'value' | 'onChange' | 'options' | 'renderInput'
> & {
  value: Date | null
  onChange: (date: Date | null) => void
  minutesStep: number
  startAfter?: Date
  date?: Date | null
}

const formatValue = format('hh:mm aaa')

/** Allows to choose predefined time values or to enter it manually */
export const TimePicker = ({
  value,
  minutesStep,
  startAfter,
  onChange,
  date,
  ...rest
}: Props) => {
  const [inputValue, setInputValue] = useState(() => {
    return value !== null && isValid(value) ? formatValue(value) : ''
  })
  const formatChars = useRef({
    '1': '[0-9]',
    '2': '[0-9]',
    '3': '[0-5]',
    '4': '[0-9]',
    '*': '[A-Za-z0-9]',
  })

  // In case value change is caused externally,
  // We need to update input value accordingly
  useEffect(() => {
    if (value !== null && !isValid(value)) {
      return
    }

    setInputValue(value !== null ? formatValue(value) : '')
  }, [value])

  const options = useMemo(() => {
    const minutesInDay = 24 * 60
    const count = minutesInDay / minutesStep
    const today = startOfDay(date ?? new Date())

    const times: Array<{ value: string; label: string }> = []

    for (let index = 0; index < count; index++) {
      const time = addMinutes(today, index * minutesStep)
      times.push({
        value: formatValue(time),
        label: formatValue(time),
      })
    }

    if (startAfter) {
      const startAfterValue = format24HoursTo12HoursTime(
        formatDateTo24HoursTime(startAfter),
      )
      const startAfterIndex =
        times.findIndex(time => time.value === startAfterValue) + 1
      times.splice(0, startAfterIndex)
    }

    return times
  }, [date, minutesStep, startAfter])

  return (
    <AutocompleteFreeSolo
      value={inputValue}
      onChange={newValue => {
        setInputValue(newValue)
        if (newValue === '') {
          onChange(null)
        } else {
          onChange(parse(newValue, 'hh:mm aaa', date ?? new Date()))
        }
      }}
      options={options}
      disableInternalFilter
      disableAlphabeticalOrder
      renderInput={renderParams => {
        // https://www.npmjs.com/package/react-input-mask#children--function
        const {
          onChange,
          onPaste,
          onMouseDown,
          onFocus,
          onBlur,
          value,
          disabled,
          readOnly,
          ...inputPropsRest
        } = renderParams.inputProps

        return (
          <InputMask
            onChange={onChange}
            onPaste={onPaste}
            onMouseDown={onMouseDown}
            onFocus={onFocus}
            onBlur={onBlur}
            value={value}
            disabled={disabled}
            readOnly={readOnly}
            mask="12:34 *m"
            formatChars={formatChars.current}
            maskChar={null}
            alwaysShowMask={false}
            placeholder="00:00 am"
            beforeMaskedValueChange={(newState: InputState) => {
              const { value } = newState
              const firstChar = value.charAt(0)

              // if the first digit is more than 1, prepend 0 automatically
              if (firstChar && firstChar !== '0' && firstChar !== '1') {
                return {
                  selection: { start: 2, end: 2 },
                  value: '0' + firstChar,
                }
              }

              // if the first digit is 1, allow only [0-2] as the second character
              formatChars.current['2'] = firstChar === '1' ? '[0-2]' : '[0-9]'

              // when user enters a last symbol and it is not 'a' or 'p', set 'am' as default
              if (value.length === 8) {
                return {
                  selection: { start: value.length, end: value.length },
                  value:
                    !value.endsWith('am') && !value.endsWith('pm')
                      ? value.slice(0, -2) + 'am'
                      : value,
                }
              }

              return newState
            }}
          >
            {
              ((maskInputParams: any) => {
                return (
                  <InputBase
                    {...maskInputParams}
                    {...renderParams.InputProps}
                    inputProps={inputPropsRest}
                    error={rest.error}
                    disabled={rest.disabled}
                  />
                )
              }) as any
            }
          </InputMask>
        )
      }}
      {...rest}
    />
  )
}
