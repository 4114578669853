import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconGoogleCalendar = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 17">
    <g clipPath="url(#clip0_14361_134285)">
      <path
        d="M5.51683 10.7325C5.20208 10.5198 4.9842 10.2093 4.86523 9.79877L5.59577 9.49771C5.66208 9.75034 5.77786 9.94612 5.94314 10.0851C6.10736 10.224 6.30736 10.2925 6.54105 10.2925C6.77998 10.2925 6.98527 10.2198 7.15683 10.0746C7.32842 9.9293 7.41473 9.74402 7.41473 9.5198C7.41473 9.29034 7.3242 9.10296 7.14314 8.95771C6.96208 8.81246 6.73473 8.7398 6.46314 8.7398H6.04105V8.01665H6.41998C6.65367 8.01665 6.85052 7.95349 7.01052 7.82718C7.17052 7.70087 7.25052 7.52824 7.25052 7.30824C7.25052 7.11246 7.17892 6.95665 7.03577 6.83983C6.89261 6.72299 6.71155 6.66405 6.49155 6.66405C6.2768 6.66405 6.1063 6.7209 5.97995 6.83565C5.85364 6.9504 5.76205 7.09143 5.70417 7.25774L4.98102 6.95668C5.0768 6.68508 5.25261 6.44508 5.51048 6.23774C5.76839 6.03037 6.09786 5.92615 6.49786 5.92615C6.79364 5.92615 7.05995 5.98299 7.29577 6.09774C7.53155 6.21249 7.71683 6.37143 7.85052 6.57352C7.9842 6.77668 8.05052 7.00405 8.05052 7.25668C8.05052 7.51458 7.98842 7.73246 7.8642 7.91143C7.73998 8.09037 7.58736 8.22721 7.4063 8.32302V8.36618C7.64523 8.46618 7.83998 8.6188 7.99367 8.82409C8.1463 9.02934 8.22314 9.27462 8.22314 9.56093C8.22314 9.84724 8.15052 10.103 8.00523 10.3272C7.85998 10.5515 7.65892 10.7283 7.40417 10.8567C7.14839 10.9851 6.86102 11.0504 6.54208 11.0504C6.17261 11.0514 5.83158 10.9451 5.51683 10.7325Z"
        fill="#0085F7"
      />
      <path
        d="M10.0042 7.10721L9.20206 7.68721L8.80103 7.07877L10.24 6.04089H10.7916V10.9367H10.0042V7.10721Z"
        fill="#0085F7"
      />
      <path
        d="M12.2105 12.621H3.78942L2.58398 14.3971L3.78942 16.4104H12.2105L13.2064 14.2684L12.2105 12.621Z"
        fill="#00A94B"
      />
      <path
        d="M12.2105 0.4104H1.26316C0.56525 0.4104 0 0.97565 0 1.67356V12.6209L1.89475 13.9679L3.7895 12.6209V4.19987H12.2106L13.5069 2.30512L12.2105 0.4104Z"
        fill="#0085F7"
      />
      <path
        d="M0 12.621V15.1473C0 15.8452 0.56525 16.4104 1.26316 16.4104H3.78947V12.621H0Z"
        fill="#00802E"
      />
      <path
        d="M16 4.1999L14.1053 2.98071L12.2106 4.1999V12.621L13.9146 13.5104L16 12.621V4.1999Z"
        fill="#FFBC00"
      />
      <path
        d="M16 4.19987V1.67356C16 0.97565 15.4348 0.4104 14.7369 0.4104H12.2106V4.19987H16Z"
        fill="#0067D5"
      />
      <path d="M12.2106 16.4104L16 12.621H12.2106V16.4104Z" fill="#FF4131" />
    </g>
    <defs>
      <clipPath id="clip0_14361_134285">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.4104)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
)
