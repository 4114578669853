import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconSearch = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.23496C0 2.78797 2.69485 0 6.02672 0C9.35859 0 12.0534 2.78797 12.0534 6.23496C12.0534 7.65759 11.5854 8.95702 10.8126 10.0057L15.1111 14.4413L13.6044 16L9.25057 11.4957C8.3172 12.1089 7.21628 12.4699 6.02672 12.4699C2.69485 12.4699 0 9.68195 0 6.23496ZM10.6354 6.23496C10.6354 3.59456 8.57893 1.46705 6.02672 1.46705C3.4745 1.46705 1.41805 3.59456 1.41805 6.23496C1.41805 8.87536 3.4745 11.0029 6.02672 11.0029C8.57893 11.0029 10.6354 8.87536 10.6354 6.23496Z"
      fill="currentColor"
    />
  </SvgIcon>
)
