import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconArrowReverted = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g clipPath="url(#clip0_15032_78820)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.58075 10.7659C2.32982 12.6812 2.66871 14.6275 3.55223 16.3452C4.43576 18.063 5.82193 19.4706 7.52592 20.3803C9.22992 21.2901 11.1708 21.6588 13.0897 21.4373C15.0086 21.2158 16.8144 20.4146 18.2663 19.1405C19.7182 17.8664 20.7472 16.18 21.2161 14.3061C21.685 12.4323 21.5715 10.46 20.8908 8.65222C20.2101 6.84448 18.9945 5.2872 17.4061 4.18802C15.8177 3.08884 13.9319 2.49999 12.0003 2.49999C11.586 2.49999 11.2503 2.1642 11.2503 1.74999C11.2503 1.33578 11.586 0.99999 12.0003 0.99999C14.2369 0.99999 16.4204 1.68181 18.2596 2.95455C20.0989 4.22729 21.5064 6.03045 22.2946 8.12363C23.0828 10.2168 23.2142 12.5005 22.6712 14.6703C22.1283 16.84 20.9368 18.7927 19.2556 20.268C17.5745 21.7432 15.4836 22.6709 13.2617 22.9274C11.0398 23.1839 8.7925 22.757 6.81945 21.7036C4.8464 20.6501 3.24136 19.0203 2.21834 17.0313C1.19531 15.0423 0.802911 12.7887 1.09346 10.571C1.14727 10.1603 1.52383 9.87101 1.93454 9.92482C2.34524 9.97863 2.63456 10.3552 2.58075 10.7659Z"
        fill="currentColor"
      />
      <path
        d="M3.77401 12H0.22599C0.0347528 12 -0.0697163 11.7406 0.0527107 11.5698L1.82672 9.09437C1.9169 8.96854 2.0831 8.96854 2.17328 9.09437L3.94729 11.5698C4.06972 11.7406 3.96525 12 3.77401 12Z"
        fill="currentColor"
      />
      <path
        d="M10.5024 16.5C10.5024 15.6716 11.174 15 12.0024 15C12.8309 15 13.5024 15.6716 13.5024 16.5C13.5024 17.3284 12.8309 18 12.0024 18C11.174 18 10.5024 17.3284 10.5024 16.5Z"
        fill="currentColor"
      />
      <path
        d="M10.6494 7.49256C10.5695 6.69343 11.197 6 12.0001 6C12.8032 6 13.4308 6.69343 13.3509 7.49256L12.8248 12.7537C12.7824 13.1774 12.4259 13.5 12.0001 13.5C11.5744 13.5 11.2179 13.1774 11.1755 12.7537L10.6494 7.49256Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_15032_78820">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
