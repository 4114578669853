import { Box, Tooltip, Typography } from '@mui/material'
import { dash } from 'lib/js-utils'
export const SlicedTooltipItems = ({
  items,
  visibleCount = 3,
}: {
  items: Array<string>
  visibleCount?: number
}) => (
  <Typography variant="inherit" color="inherit">
    {items.length === 0 ? (
      <>{dash}</>
    ) : (
      <>
        {items.slice(0, visibleCount).join(', ')}{' '}
        {visibleCount < items.length && (
          <Tooltip arrow placement="top" title={items.join(', ')}>
            <Box component="span" fontWeight={500}>
              + {items.length - visibleCount} more
            </Box>
          </Tooltip>
        )}
      </>
    )}
  </Typography>
)
