import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconGmail = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 17">
    <path
      d="M15.1555 4.33789L14.1341 14.5944H1.86612L1.04321 4.53639L8.00012 8.43145L15.1555 4.33789Z"
      fill="#F2F2F2"
    />
    <path
      d="M14.809 2.40576L8.0001 8.80189L1.19116 2.40576H8.0001H14.809Z"
      fill="#F2F2F2"
    />
    <path
      d="M1.866 5.35921V14.5943H0.754344C0.337875 14.5943 0 14.2565 0 13.84V3.99902L1.21875 4.03224L1.866 5.35921Z"
      fill="#F14336"
    />
    <path
      d="M16 3.99899V13.84C16 14.2565 15.6622 14.5943 15.2453 14.5943H14.134V5.35918L14.7494 3.90527L16 3.99899Z"
      fill="#D32E2A"
    />
    <path
      d="M16 3.16011V3.99901L14.134 5.3592L8 9.83007L1.866 5.3592L0 3.99901V3.16011C0 2.74364 0.337875 2.40576 0.754344 2.40576H1.19106L8 7.36854L14.8089 2.40576H15.2452C15.6621 2.40576 16 2.74361 16 3.16011Z"
      fill="#F14336"
    />
    <path d="M1.866 5.35921L0 5.0699V3.99902L1.866 5.35921Z" fill="#D32E2A" />
  </SvgIcon>
)
