import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconActivityUpcoming = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g clipPath="url(#clip0_6359_56201)">
      <path
        d="M12 5.25C12 5.05109 11.921 4.86032 11.7803 4.71967C11.6397 4.57902 11.4489 4.5 11.25 4.5C11.0511 4.5 10.8603 4.57902 10.7197 4.71967C10.579 4.86032 10.5 5.05109 10.5 5.25V13.5C10.5 13.6322 10.535 13.762 10.6014 13.8764C10.6678 13.9907 10.7632 14.0854 10.878 14.151L16.128 17.151C16.3003 17.2441 16.5022 17.2661 16.6905 17.2124C16.8788 17.1586 17.0386 17.0333 17.1358 16.8633C17.2329 16.6933 17.2597 16.492 17.2104 16.3024C17.1612 16.1129 17.0397 15.9502 16.872 15.849L12 13.065V5.25Z"
        fill="currentColor"
      />
      <path
        d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5C9.21523 22.5 6.54451 21.3938 4.57538 19.4246C2.60625 17.4555 1.5 14.7848 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6359_56201">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
