import { ActivityChannel, ActivityStatus, ActivityType } from 'api'
import { isFuture, startOfToday } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { TFuncKey, TFunction } from 'react-i18next'
import { z } from 'zod'

export const buildSchema = (t: TFunction, timeZone: string) =>
  z
    .object({
      type: z.union([z.literal('FOLLOW_UP'), z.literal('INTERVIEW')]),
      channel: z.string().optional(),
      date: z.date().min(startOfToday(), t('validations.not_past_date')),
      startTime: z
        .date({
          errorMap: (issue, ctx) => {
            return {
              message:
                issue.code === 'invalid_date'
                  ? t('validations.invalid_time')
                  : ctx.defaultError,
            }
          },
        })
        .min(
          utcToZonedTime(new Date(), timeZone),
          t('validations.future_date'),
        ),
      jobId: z.string(),
      contact: z.string().min(1),
      notes: z.string().trim().max(200).optional(),
      status: z.string().min(1),
      link: z.string().optional(),
      endTime: z
        .date()
        .min(utcToZonedTime(new Date(), timeZone), t('validations.future_date'))
        .optional(),
    })
    .refine(
      data => {
        if (data.type !== 'FOLLOW_UP') {
          return true
        }
        return (
          typeof data.channel === 'string' && data.channel.trim().length > 0
        )
      },
      {
        message: t('validations.required'),
        path: ['channel'],
      },
    )
    .refine(
      data => {
        if (data.type !== 'INTERVIEW') {
          return true
        }
        return data.endTime !== undefined
      },
      {
        message: t('validations.required'),
        path: ['endTime'],
      },
    )
    .refine(
      data => {
        if (data.type !== 'INTERVIEW') {
          return true
        }
        return data.endTime && isFuture(data.endTime)
      },
      {
        message: t('validations.future_date'),
        path: ['endTime'],
      },
    )
    .refine(
      data => {
        if (data.type !== 'INTERVIEW') {
          return true
        }
        return data.endTime && data.endTime > data.startTime
      },
      {
        message: t('validations.from_time_less_than_to_time'),
        path: ['endTime'],
      },
    )

export type ValidValues = {
  type: ActivityType
  channel: ActivityChannel
  date: Date
  startTime: Date
  endTime: Date
  jobId: string
  contact: string
  notes: string
  status: ActivityStatus
  link: string
}

export type FormValues = {
  type: ActivityType
  channel?: string
  date: Date | null
  startTime: Date | null
  endTime?: Date | null
  jobId: string
  contact: string
  notes: string
  status: ActivityStatus
  link?: string
}

export const defaultValues: FormValues = {
  type: 'FOLLOW_UP',
  channel: '',
  date: null,
  startTime: null,
  endTime: undefined,
  jobId: '',
  contact: '',
  notes: '',
  status: 'OPEN',
  link: '',
}

export const labels: Record<keyof FormValues, TFuncKey> = {
  type: 'common.activity_type',
  channel: 'common.channel',
  date: 'common.date',
  startTime: 'common.start_time',
  endTime: 'common.end_time',
  jobId: 'common.job',
  contact: 'common.contact',
  notes: 'common.notes',
  status: 'common.status',
  link: 'common.link',
} as const

export const requiredValues = (
  type: ActivityType | undefined,
): Array<string> => {
  const baseArray: Array<keyof FormValues> = [
    'type',
    'date',
    'startTime',
    'contact',
    'channel',
  ]
  const FOLLOW_UP: Array<keyof FormValues> = ['channel']
  const INTERVIEW: Array<keyof FormValues> = ['endTime']

  if (type === 'FOLLOW_UP') return [...baseArray, ...FOLLOW_UP]
  if (type === 'INTERVIEW') return [...baseArray, ...INTERVIEW]
  return baseArray
}
