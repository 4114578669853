import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCircleOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <circle
      cx="9"
      cy="9"
      r="7.25"
      stroke="currentColor"
      strokeWidth="1.5"
      fill="none"
    />
  </SvgIcon>
)
