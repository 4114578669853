import { TAmountOfAddedContactsStatistic } from 'api/contacts/contacts.codecs'
import { format } from 'date-fns'
import { pipe } from 'fp-ts/function'
import { toServerDate } from 'lib/js-utils'
import { decodeJson, get, post } from 'lib/request'

import {
  GetGenericReportInput,
  GetReportInput,
  TReportQualifierAmounts,
} from '../common'
import {
  TContactsAddedToTalentsPoolReport,
  TContactsByQualificationLevelReport,
  TRecruiterContactPerformanceReport,
} from './contact-reports.codecs'

export const getContactsByQualificationLevelReport = async ({
  from,
  to,
}: GetReportInput) => {
  return pipe(
    await get('api/reports/contact-reports/contacts-by-qualification-level', {
      query: new URLSearchParams({
        from: toServerDate(from),
        to: toServerDate(to),
      }),
    }),
    decodeJson(TContactsByQualificationLevelReport),
  )
}

export const getContactsAddedByQuarterReport = async ({
  from,
  to,
}: GetReportInput) => {
  return pipe(
    await get('api/reports/contact-reports/contacts-added-by-quarter', {
      query: new URLSearchParams({
        from: toServerDate(from),
        to: toServerDate(to),
      }),
    }),
    decodeJson(TReportQualifierAmounts),
  )
}

export type GetContactsHiredBySourceInput = GetGenericReportInput & {
  me?: true
}

// Backend will refactor these requests soon
export const getContactsBySourceReport = async ({
  from,
  to,
  me,
  recruiterId,
}: GetContactsHiredBySourceInput) => {
  const params = new URLSearchParams({
    from: toServerDate(from),
    to: toServerDate(to),
  })

  if (recruiterId) {
    params.set('recruiterId', recruiterId)
  }

  return pipe(
    await get(
      `api/reports/contact-reports/${me ? 'recruiters/me/' : ''}${
        !me && !recruiterId
          ? 'contacts-added-by-source'
          : 'contacts-hired-by-source'
      }`,
      {
        query: params,
      },
    ),
    decodeJson(TReportQualifierAmounts),
  )
}

export const getContactsHiredByLevelReport = async ({
  from,
  to,
  recruiterId,
}: GetGenericReportInput) => {
  const params = new URLSearchParams({
    from: toServerDate(from),
    to: toServerDate(to),
  })

  if (recruiterId) {
    params.set('recruiterId', recruiterId)
  }

  return pipe(
    await get(
      'api/reports/contact-reports/contacts-hired-by-qualification-level',
      { query: params },
    ),
    decodeJson(TReportQualifierAmounts),
  )
}

export type GetContactsAddedByPositionReportInput = {
  from: Date
  to: Date
  positionIds: Array<string>
}

export const getContactsAddedByPositionReport = async ({
  from,
  to,
  positionIds,
}: GetContactsAddedByPositionReportInput) => {
  const params = new URLSearchParams({
    from: toServerDate(from),
    to: toServerDate(to),
  })

  return pipe(
    await post('api/reports/contact-reports/contacts-added-by-position', {
      query: params,
      body: { positionIds },
    }),
    decodeJson(TReportQualifierAmounts),
  )
}

export const getContactsAddedToTalentsPoolReport = async ({
  from,
  to,
}: GetReportInput) => {
  const params = new URLSearchParams({
    from: toServerDate(from),
    to: toServerDate(to),
  })

  return pipe(
    await get(
      'api/reports/contact-reports/contacts-added-to-talent-pool-by-position',
      { query: params },
    ),
    decodeJson(TContactsAddedToTalentsPoolReport),
  )
}

export const getRecruiterContactPerformanceReport = async ({
  from,
  to,
  recruiterId,
}: GetGenericReportInput) => {
  return pipe(
    await get(
      `api/reports/contact-reports/recruiters/${
        recruiterId ?? 'me'
      }/contact-performance`,
      {
        query: new URLSearchParams({
          from: toServerDate(from),
          to: toServerDate(to),
        }),
      },
    ),
    decodeJson(TRecruiterContactPerformanceReport),
  )
}

export const getRecruiterContactsHiredByPositionReport = async ({
  from,
  to,
  recruiterId,
}: GetGenericReportInput) => {
  return pipe(
    await get(
      `api/reports/contact-reports/recruiters/${
        recruiterId ?? 'me'
      }/contacts-hired-by-position`,
      {
        query: new URLSearchParams({
          from: toServerDate(from),
          to: toServerDate(to),
        }),
      },
    ),
    decodeJson(TReportQualifierAmounts),
  )
}

export const getAmountOfAddedContactsStatistic = async () => {
  return pipe(
    await get('api/reports/contact-reports/contact-influx', {
      headers: new Headers({
        Timezone: format(new Date(), 'z'),
      }),
    }),

    decodeJson(TAmountOfAddedContactsStatistic),
  )
}
