import { ContactWorkPermits } from 'api'
import { DetailsItemContainer } from 'components/templates/details-template'
import { WorkPermitsList } from 'pages/contacts/form'
import { useTranslation } from 'react-i18next'

type Props = Readonly<{
  workPermits: ContactWorkPermits
}>

export const ContactWorkPermitsSection = ({ workPermits }: Props) => {
  const { t } = useTranslation()

  if (workPermits.length === 0) return null

  return (
    <DetailsItemContainer title={t('common.work_permits')}>
      <WorkPermitsList
        workPermits={workPermits.map(workPermit => ({
          ...workPermit,
          country: workPermit.country.code,
        }))}
      />
    </DetailsItemContainer>
  )
}
