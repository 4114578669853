import { Box, Skeleton, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { TruncateWithTooltip } from 'ui/utils'

type Props = Readonly<{
  icon: React.ReactNode
  label: string
  text?: React.ReactNode
  width?: number | string
  minWidth?: number | string
  disableTextSkeleton?: boolean
  isTextTruncateWithTooltip?: boolean
  noWrap?: boolean
}>

export const IconBlock = ({
  icon,
  label,
  text,
  noWrap,
  width,
  minWidth,
  disableTextSkeleton,
  isTextTruncateWithTooltip = false,
}: Props) => {
  return (
    <Box display="flex" alignItems="center" width={width} minWidth={minWidth}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          width: '40px',
          height: '40px',

          flexShrink: 0,

          borderRadius: '50%',
          border: '1px solid',

          borderColor: 'divider',
          color: 'mineShaft.main',
        }}
      >
        {icon}
      </Box>

      <Box ml={1} minWidth={0}>
        <TruncateWithTooltip variant="body2" color="text.secondary" mb="4px">
          {label}
        </TruncateWithTooltip>

        <IconBlockText
          isTextTruncateWithTooltip={isTextTruncateWithTooltip}
          noWrap={noWrap}
        >
          {text ?? (!disableTextSkeleton && <Skeleton width={100} />)}
        </IconBlockText>
      </Box>
    </Box>
  )
}

type IconBlockTextProps = Readonly<{
  isTextTruncateWithTooltip: boolean
  noWrap?: boolean
  children: ReactNode
}>

const IconBlockText = ({
  isTextTruncateWithTooltip,
  noWrap,
  children,
}: IconBlockTextProps) => {
  return isTextTruncateWithTooltip ? (
    <TruncateWithTooltip variant="body1">{children}</TruncateWithTooltip>
  ) : (
    <Typography variant="body1" component="div" noWrap={noWrap}>
      {children}
    </Typography>
  )
}
