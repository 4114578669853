import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCheckCircleOutlineLarge = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g clipPath="url(#clip0_6359_56128)">
      <path
        d="M12 22.5C9.21523 22.5 6.54451 21.3938 4.57538 19.4246C2.60625 17.4555 1.5 14.7848 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
        fill="currentColor"
      />
      <path
        d="M16.4545 7.45884C16.4438 7.46919 16.4338 7.48022 16.4245 7.49184L11.215 14.1293L8.07553 10.9883C7.86227 10.7896 7.5802 10.6814 7.28875 10.6866C6.9973 10.6917 6.71922 10.8098 6.5131 11.0159C6.30698 11.222 6.18891 11.5001 6.18377 11.7916C6.17863 12.083 6.28681 12.3651 6.48553 12.5783L10.4545 16.5488C10.5615 16.6556 10.6888 16.7397 10.8289 16.7961C10.969 16.8526 11.1191 16.8802 11.2702 16.8774C11.4212 16.8746 11.5701 16.8414 11.7081 16.7798C11.846 16.7182 11.9701 16.6295 12.073 16.5188L18.061 9.03384C18.2649 8.81984 18.3764 8.53418 18.3714 8.23864C18.3663 7.9431 18.2452 7.66141 18.0341 7.45448C17.823 7.24755 17.539 7.132 17.2434 7.13282C16.9478 7.13363 16.6645 7.25075 16.4545 7.45884Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6359_56128">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
