import { Radio as MuiRadio, SxProps } from '@mui/material'
import { FormControlLabel } from 'ui/inputs/common/form-control-label'

import { RadioIcon } from './radio-icon'

export type Props = Readonly<{
  name: string
  value: string
  onChange: (value: string) => void
  selectedValue: string

  label?: React.ReactNode
  disabled?: boolean
  small?: boolean
  sx?: SxProps
}>

export const Radio = ({
  name,
  value,
  onChange,
  selectedValue,
  label,
  disabled,
  small,
  sx,
}: Props) => {
  const control = (
    <MuiRadio
      sx={sx}
      disableRipple
      name={name}
      value={value}
      checked={value === selectedValue}
      onChange={event => onChange(event.target.value)}
      disabled={disabled}
      icon={<RadioIcon disabled={disabled} small={small} />}
      checkedIcon={<RadioIcon checked disabled={disabled} small={small} />}
    />
  )

  if (label) {
    return <FormControlLabel label={label} control={control} small={small} />
  }

  return control
}
