import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconLevelLower = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 14">
    <path
      d="M10.0884 7.37979C10.0345 7.32571 9.97038 7.28281 9.89981 7.25354C9.82924 7.22427 9.75359 7.2092 9.6772 7.2092C9.6008 7.2092 9.52515 7.22427 9.45458 7.25354C9.38401 7.28281 9.31991 7.32571 9.26595 7.37979L7.00261 9.63729L4.73345 7.37396C4.67944 7.31995 4.61533 7.27711 4.54476 7.24788C4.4742 7.21866 4.39857 7.20361 4.3222 7.20361C4.24582 7.20361 4.17019 7.21866 4.09963 7.24788C4.02907 7.27711 3.96495 7.31995 3.91095 7.37396C3.85694 7.42796 3.8141 7.49208 3.78487 7.56264C3.75564 7.6332 3.7406 7.70883 3.7406 7.78521C3.7406 7.86158 3.75564 7.93721 3.78487 8.00778C3.8141 8.07834 3.85694 8.14245 3.91095 8.19646L6.58845 10.874C6.81595 11.1015 7.18345 11.1015 7.41095 10.874L10.0884 8.19646C10.1425 8.14307 10.1854 8.07949 10.2147 8.00939C10.244 7.9393 10.259 7.86409 10.259 7.78813C10.259 7.71216 10.244 7.63695 10.2147 7.56686C10.1854 7.49676 10.1425 7.43318 10.0884 7.37979Z"
      fill="currentColor"
    />
    <path
      d="M10.0884 4.17764C10.0345 4.12357 9.97038 4.08066 9.89981 4.05139C9.82924 4.02212 9.75359 4.00705 9.6772 4.00705C9.6008 4.00705 9.52515 4.02212 9.45458 4.05139C9.38401 4.08066 9.31991 4.12357 9.26595 4.17764L7.00261 6.43514L4.73345 4.17181C4.67944 4.1178 4.61533 4.07496 4.54476 4.04574C4.4742 4.01651 4.39857 4.00146 4.3222 4.00146C4.24582 4.00146 4.17019 4.01651 4.09963 4.04574C4.02907 4.07496 3.96495 4.1178 3.91095 4.17181C3.85694 4.22582 3.8141 4.28993 3.78487 4.36049C3.75564 4.43106 3.7406 4.50668 3.7406 4.58306C3.7406 4.65944 3.75564 4.73506 3.78487 4.80563C3.8141 4.87619 3.85694 4.9403 3.91095 4.99431L6.58845 7.67181C6.81595 7.89931 7.18345 7.89931 7.41095 7.67181L10.0884 4.99431C10.1425 4.94092 10.1854 4.87734 10.2147 4.80724C10.244 4.73715 10.259 4.66194 10.259 4.58598C10.259 4.51001 10.244 4.4348 10.2147 4.36471C10.1854 4.29462 10.1425 4.23103 10.0884 4.17764Z"
      fill="currentColor"
    />
  </SvgIcon>
)
