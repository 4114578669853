import debounce from 'lodash.debounce'
import { ComponentState, useEffect, useMemo, useState } from 'react'

export const useDebounceState = <State extends ComponentState>(
  initialState: State,
  debounceTime: number,
) => {
  const [state, setState] = useState(initialState)

  const debounceSetState = useMemo(
    () => debounce(setState, debounceTime),
    [debounceTime],
  )

  useEffect(() => {
    return () => {
      debounceSetState.cancel()
    }
  }, [debounceSetState])

  return [state, debounceSetState, setState] as const
}
