import { SvgIconProps } from '@mui/material'
import { PoolPermissionType, TalentsPoolStatus } from 'api'
import { ThemeColor } from 'app/mui-theme'
import { IconLock, IconShare } from 'assets/icons'

export const talentsPoolStatuses: Record<
  TalentsPoolStatus,
  {
    color: ThemeColor
    background: ThemeColor
    Icon: (props: SvgIconProps<'svg', {}>) => JSX.Element
  }
> = {
  PRIVATE: {
    color: 'flushOrange.main',
    background: 'flushOrange.200',
    Icon: IconLock,
  },
  SHARED: {
    color: 'jade.main',
    background: 'jade.200',
    Icon: IconShare,
  },
}

export const talentPoolPermissions: Array<PoolPermissionType> = [
  'VIEWER',
  'COLLABORATOR',
  'CONTRIBUTOR',
]
