import { useMutation, useQueryClient } from '@tanstack/react-query'
import { JobPipelineStage } from 'api'
import { qk } from 'api/query-keys'

import {
  moveContactOnJobPipeline,
  MoveContactOnJobPipelineInput,
} from './job-pipeline.api'
import { JobContact } from './job-pipeline.codecs'
import { orderJobPipelineContactsList } from './job-pipeline.utils'

const listKey = (jobId: string) =>
  qk.jobs.pipeline.contactsList.toKeyWithArgs({ jobId })

export const useMoveContactOnJobPipelineOptimistically = (
  stages: Array<JobPipelineStage>,
) => {
  const queryClient = useQueryClient()

  return useMutation<
    Response,
    unknown,
    MoveContactOnJobPipelineInput,
    { previousList: Array<JobContact> } | undefined
  >(moveContactOnJobPipeline, {
    onMutate: async ({ contactId, jobId, stageId }) => {
      const newStage = stages.find(stage => stage.stageId === stageId)
      const list = queryClient.getQueryData<Array<JobContact> | undefined>(
        listKey(jobId),
      )

      if (!newStage || !list) return

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(listKey(jobId))

      // Optimistically update the list
      queryClient.setQueryData(
        listKey(jobId),
        orderJobPipelineContactsList(
          list.map(contact => {
            if (contact.candidateId === contactId) {
              return {
                ...contact,
                jobPipelineStage: newStage,
              }
            }

            return contact
          }),
        ),
      )

      return { previousList: list }
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_error, { jobId }, context) => {
      if (context && context.previousList) {
        queryClient.setQueryData(listKey(jobId), context.previousList)
      }
    },
    onSettled: (_data, _error, { jobId }) => {
      queryClient.invalidateQueries(listKey(jobId))
    },
  })
}
