import { Box, Skeleton, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { TruncateWithTooltip } from 'ui/utils'

type Props = Readonly<{
  label: string
  text?: React.ReactNode
  width?: number | string
  minWidth?: number | string
  disableTextSkeleton?: boolean
  isTextTruncateWithTooltip?: boolean
  noWrap?: boolean
}>

export const InformationItem = ({
  label,
  text,
  noWrap,
  width,
  minWidth,
  disableTextSkeleton,
  isTextTruncateWithTooltip = false,
}: Props) => {
  return (
    <Box display="flex" alignItems="center" width={width} minWidth={minWidth}>
      <Box minWidth={0}>
        <TruncateWithTooltip variant="body2" color="mineShaft.800" mb="4px">
          {label}
        </TruncateWithTooltip>

        <InformationItemText
          isTextTruncateWithTooltip={isTextTruncateWithTooltip}
          noWrap={noWrap}
        >
          {text ?? (!disableTextSkeleton && <Skeleton width={100} />)}
        </InformationItemText>
      </Box>
    </Box>
  )
}

type IconBlockTextProps = Readonly<{
  isTextTruncateWithTooltip: boolean
  noWrap?: boolean
  children: ReactNode
}>

const InformationItemText = ({
  isTextTruncateWithTooltip,
  noWrap,
  children,
}: IconBlockTextProps) => {
  return isTextTruncateWithTooltip ? (
    <TruncateWithTooltip variant="h5" color="mineShaft.main">
      {children}
    </TruncateWithTooltip>
  ) : (
    <Typography
      variant="h5"
      color="mineShaft.main"
      component="div"
      noWrap={noWrap}
    >
      {children}
    </Typography>
  )
}
