import { IconPlus } from 'assets/icons'
import { useBoolean } from 'lib/react-utils'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'ui/feedback'
import { Button, ButtonProps } from 'ui/inputs/button'

import { AddContactsToTalentsPoolForm } from './add-to-talents-pool-form'

type Props = {
  contactsIds: Array<string>
  buttonProps?: ButtonProps
  onOpenDialog?: () => void
  onCloseDialog?: () => void
}

export const AddContactsToTalentsPool = ({
  contactsIds,
  buttonProps,
  onOpenDialog,
  onCloseDialog,
}: Props) => {
  const { t } = useTranslation()
  const dialogOpen = useBoolean()

  const closeDialog = () => {
    dialogOpen.setFalse()
    if (onCloseDialog) onCloseDialog()
  }

  return (
    <>
      <Button
        {...buttonProps}
        onClick={() => {
          dialogOpen.setTrue()
          if (onOpenDialog) onOpenDialog()
        }}
        startIcon={<IconPlus />}
        iconSize="16px"
      >
        {t('common.add_to_talent_pool')}
      </Button>

      <Dialog size="small" open={dialogOpen.isTrue} onClose={closeDialog}>
        <AddContactsToTalentsPoolForm
          contactsIds={contactsIds}
          onClose={closeDialog}
        />
      </Dialog>
    </>
  )
}
