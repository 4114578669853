import { alpha, Box, Stack, Tooltip } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getContactTalentsPools, qk } from 'api'
import { IconTarget04 } from 'assets/icons'
import { renderQueryResult } from 'lib/react-query-utils'
import { useTranslation } from 'react-i18next'

type Props = Readonly<{
  contactId: string
}>

export const TalentsPullsBadge = ({ contactId }: Props) => {
  const { t } = useTranslation()

  const $contactTalentsPools = useQuery(
    qk.contacts.talentsPools.toKeyWithArgs({ contactId }),
    () => getContactTalentsPools({ contactId }),
  )

  return renderQueryResult($contactTalentsPools, {
    loading: () => <Box height="25px" />,
    error: () => null,
    success: contactTalentsPools => {
      const isMemberOfTalentsPool = contactTalentsPools.length > 0

      if (!isMemberOfTalentsPool) return null

      return (
        <Tooltip placement="top" title={t('common.talent_pools')}>
          <Stack
            justifyContent="center"
            sx={{
              height: 25,
              px: 1.5,
              bgcolor: 'primary.main',
              borderRadius: 50,
              outline: theme =>
                `1px solid ${alpha(theme.palette.mineShaft[300], 0.4)}`,
            }}
          >
            <IconTarget04
              sx={{
                fontSize: 16,
                color: '#FFFFFF',
              }}
            />
          </Stack>
        </Tooltip>
      )
    },
  })
}
