import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconPlus = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M8 1V15M1 8H15"
      stroke="currentColor"
      strokeWidth={props.stroke ?? '1.4'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
