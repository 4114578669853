import { SxProps, Typography } from '@mui/material'
import { useMatch } from 'react-router'
import { NavLinkButton } from 'ui/navigation'

import { NavigationItem } from './menu-navigation'

export const MenuNavigationItem = ({
  navigation,
  toggle,
  sx,
}: {
  navigation: NavigationItem
  toggle?: () => void
  sx?: SxProps
}) => {
  const isActive = useMatch({ path: navigation.path, end: false })

  return (
    <NavLinkButton
      color="mineShaft"
      to={navigation.path}
      onClick={toggle}
      variant={isActive ? 'contained' : 'text'}
      sx={{
        outline: 'none',
        border: 'none',
        ':hover': {
          border: 'none',
        },
        ...sx,
      }}
    >
      <navigation.Icon sx={{ fontSize: 14 }} />
      <Typography ml={1} fontSize="14px">
        {navigation.label}
      </Typography>
    </NavLinkButton>
  )
}
