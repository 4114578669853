import { Profile } from 'api'
import { DetailsItemContainer } from 'components/templates/details-template'
import { UserProfile } from 'components/users'
import { useTranslation } from 'react-i18next'

type Props = Readonly<{
  createdBy: Profile
}>

export const ContactCreatorSection = ({ createdBy }: Props) => {
  const { t } = useTranslation()

  return (
    <DetailsItemContainer title={t('common.creator')}>
      <UserProfile
        avatar={createdBy.avatar}
        fullName={createdBy.fullName}
        role={createdBy.role}
      />
    </DetailsItemContainer>
  )
}
