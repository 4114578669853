import { Box } from '@mui/material'

export const WidgetEmptyContent = ({
  children,
  fullHeight,
}: {
  children: React.ReactNode
  fullHeight?: boolean
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: fullHeight ? '100%' : 250,
    }}
  >
    {children}
  </Box>
)
