import { getInputError } from 'lib/form-utils'
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form'

import { Select, SelectProps } from './select'

export const renderSelect = <
  FormValues extends FieldValues,
  FormPath extends Path<FormValues>,
>(
  props: Omit<
    SelectProps<PathValue<FormValues, FormPath>>,
    'value' | 'onChange'
  >,
) => {
  return function SelectController({
    field,
    fieldState,
  }: {
    field: ControllerRenderProps<FormValues, FormPath>
    fieldState: ControllerFieldState
  }) {
    return <Select {...props} {...field} {...getInputError(fieldState.error)} />
  }
}
