import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconLanguageFill = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      d="M4.545 6.714L4.11 8H3L4.862 3H6.146L8 8H6.833L6.398 6.714H4.545ZM6.179 5.978L5.5 3.956H5.451L4.772 5.978H6.18H6.179Z"
      fill="currentColor"
    />
    <path
      d="M0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0L9 0C9.53043 0 10.0391 0.210714 10.4142 0.585786C10.7893 0.960859 11 1.46957 11 2V5H14C14.5304 5 15.0391 5.21071 15.4142 5.58579C15.7893 5.96086 16 6.46957 16 7V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H7C6.46957 16 5.96086 15.7893 5.58579 15.4142C5.21071 15.0391 5 14.5304 5 14V11H2C1.46957 11 0.960859 10.7893 0.585786 10.4142C0.210714 10.0391 0 9.53043 0 9V2ZM2 1C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V9C1 9.26522 1.10536 9.51957 1.29289 9.70711C1.48043 9.89464 1.73478 10 2 10H9C9.26522 10 9.51957 9.89464 9.70711 9.70711C9.89464 9.51957 10 9.26522 10 9V2C10 1.73478 9.89464 1.48043 9.70711 1.29289C9.51957 1.10536 9.26522 1 9 1H2ZM9.138 10.995C9.331 11.296 9.54 11.578 9.768 11.841C9.02 12.416 8.095 12.842 7 13.133C7.178 13.35 7.451 13.768 7.555 14C8.68 13.641 9.635 13.156 10.441 12.506C11.218 13.171 12.18 13.671 13.371 13.978C13.504 13.724 13.785 13.305 14 13.088C12.875 12.835 11.943 12.394 11.18 11.804C11.861 11.057 12.402 10.153 12.801 9.047H14V8H11V9.047H11.765C11.447 9.891 11.025 10.593 10.493 11.177C10.3461 11.0205 10.2075 10.8562 10.078 10.685C9.79581 10.8659 9.47246 10.9726 9.138 10.995Z"
      fill="currentColor"
    />
  </SvgIcon>
)
