import {
  Box,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  stepLabelClasses,
  Stepper,
  styled,
  Typography,
} from '@mui/material'
import puppyDemoImage from 'assets/images/puppy-demo.png'
import { useConfirmationDialog } from 'lib/app-helpers'
import { useIntroductionTour } from 'lib/context'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogContent } from 'ui/feedback'

const STEP_ICON_SIZE = 28

const StepIcon = (props: StepIconProps) => {
  return (
    <Box
      sx={{
        width: STEP_ICON_SIZE,
        height: STEP_ICON_SIZE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 1,
        borderColor:
          props.completed || props.active ? 'primary.main' : 'mischka',
        borderRadius: '50%',
        bgcolor: props.completed ? 'primary.main' : 'transparent',
      }}
    >
      <Typography
        variant="body2"
        color={theme => {
          if (props.completed) {
            return '#FFFFFF'
          } else if (props.active) {
            return theme.palette.primary.main
          } else {
            return theme.palette.greyBlue.light
          }
        }}
        fontWeight={500}
      >
        {props.icon}
      </Typography>
    </Box>
  )
}

const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
  // The equivalent to the standard mui StepConnector styles but for custom icon size
  top: STEP_ICON_SIZE / 2,
  left: `calc(-50% + ${STEP_ICON_SIZE / 2}px)`,
  right: `calc(50% + ${STEP_ICON_SIZE / 2}px)`,

  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mischka,
  },

  [`&.Mui-completed .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.primary.main,
  },
}))

export const IntroductionTourSteps = () => {
  const { t } = useTranslation()

  const {
    steps,
    stepIndex,
    activeHighlight,
    moveForward,
    isActive,
    finishTour,
  } = useIntroductionTour()

  const activeStep = steps[stepIndex]
  const isLastStep = stepIndex === steps.length - 1

  const confirmFinishTourDialog = useConfirmationDialog<void>(() => {
    mixpanel.track('Skip introduction tour', {
      'Steps completed': steps.slice(0, stepIndex).map(step => step.title),
    })

    finishTour()
  })

  return (
    <>
      <Dialog open={isActive && activeHighlight === null} width="520px">
        {activeStep && (
          <DialogContent
            title={activeStep.title}
            onConfirm={moveForward}
            onDeny={isLastStep ? undefined : confirmFinishTourDialog.openDialog}
            confirmText={
              isLastStep
                ? t('introduction_tour.steps.lets_start')
                : t('common.continue')
            }
            denyText={t('introduction_tour.steps.skip_demo')}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              whiteSpace="pre-line"
              mb={3.5}
            >
              {activeStep.message}
            </Typography>

            {!isLastStep && (
              <Box display="flex" justifyContent="center" mb={2}>
                <Box component="img" src={puppyDemoImage} width={225} />
              </Box>
            )}

            <Stepper
              activeStep={stepIndex - 1}
              alternativeLabel
              connector={<StyledStepConnector />}
            >
              {steps
                .filter(step => step.stepName)
                .map(step => (
                  <Step key={step.stepName}>
                    <StepLabel
                      StepIconComponent={StepIcon}
                      sx={{
                        [`& .${stepLabelClasses.label}.${stepLabelClasses.alternativeLabel}`]:
                          {
                            typography: 'caption',
                            display: 'block',
                            color: 'text.primary',
                            mt: 0.5,
                          },
                      }}
                    >
                      {step.stepName}
                    </StepLabel>
                  </Step>
                ))}
            </Stepper>
          </DialogContent>
        )}
      </Dialog>

      {confirmFinishTourDialog.renderConfirmDialog({
        size: 'small',
        variant: 'danger',
        title: t('introduction_tour.steps.skip_demo'),
        children: t('confirms.confirm_skip_demo'),
      })}
    </>
  )
}
