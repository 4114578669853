import { Typography } from '@mui/material'
import { paths } from 'app/routes'
import { useScopes } from 'app/scopes'
import { useTranslation } from 'react-i18next'
import { DialogContent } from 'ui/feedback'

export type EmptyTemplatesDialogContentProps = {
  onDeny?: () => void
}

export const EmptyTemplatesDialogContent = ({
  onDeny,
}: EmptyTemplatesDialogContentProps) => {
  const { t } = useTranslation()
  const scopes = useScopes()

  return (
    <DialogContent
      title={t('common.add_template')}
      onDeny={onDeny}
      linkButton={
        scopes.canManageTalentsPool
          ? {
              linkTitle: t('common.create_template'),
              linkToPath: paths.settingsEmailTemplates,
              linkTarget: '_blank',
            }
          : undefined
      }
    >
      <Typography variant="body2" color="text.secondary" mb={3}>
        {scopes.canManageTalentsPool
          ? t('common.no_mailbox_templates.hr_or_owner')
          : t('common.no_mailbox_templates.other_roles')}
      </Typography>
    </DialogContent>
  )
}
