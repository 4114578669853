import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconArrowLeftShort = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M15.4546 19L8.95459 12L15.4546 5"
      stroke="currentColor"
      strokeWidth={props.strokeWidth ?? 1}
      strokeLinecap="round"
      fill="none"
    />
  </SvgIcon>
)
