import { Box } from '@mui/material'
import { IconX } from 'assets/icons'

import { IconButton } from './icon-button'

type Props = Readonly<{
  onClose?: () => void
}>

export const CloseIconButton = ({ onClose }: Props) => {
  return (
    <Box
      borderRadius="50%"
      sx={{
        bgcolor: theme => theme.palette.mineShaft[100],
      }}
    >
      <IconButton sizePx="24px" size="small" icon={true} onClick={onClose}>
        <IconX
          sx={{
            fontSize: 10,
            color: theme => theme.palette.mineShaft.main,
          }}
        />
      </IconButton>
    </Box>
  )
}
