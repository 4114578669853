import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconArrowRevertedWithCross = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g clipPath="url(#clip0_15323_368)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.58075 13.2341C2.32982 11.3188 2.66871 9.37254 3.55223 7.65479C4.43576 5.93703 5.82193 4.52943 7.52592 3.61965C9.22992 2.70988 11.1708 2.34117 13.0897 2.56268C15.0086 2.7842 16.8144 3.58541 18.2663 4.85949C19.7182 6.13358 20.7472 7.81999 21.2161 9.69386C21.685 11.5677 21.5715 13.54 20.8908 15.3478C20.2101 17.1555 18.9945 18.7128 17.4061 19.812C15.8177 20.9112 13.9319 21.5 12.0003 21.5C11.586 21.5 11.2503 21.8358 11.2503 22.25C11.2503 22.6642 11.586 23 12.0003 23C14.2369 23 16.4204 22.3182 18.2596 21.0455C20.0989 19.7727 21.5064 17.9695 22.2946 15.8764C23.0828 13.7832 23.2142 11.4995 22.6712 9.32974C22.1283 7.15998 20.9368 5.2073 19.2556 3.73205C17.5745 2.25679 15.4836 1.32907 13.2617 1.07258C11.0398 0.816087 8.7925 1.24302 6.81945 2.29644C4.8464 3.34986 3.24136 4.97972 2.21834 6.9687C1.19531 8.95768 0.802911 11.2113 1.09346 13.429C1.14727 13.8397 1.52383 14.129 1.93454 14.0752C2.34524 14.0214 2.63456 13.6448 2.58075 13.2341Z"
        fill="currentColor"
      />
      <path
        d="M3.77401 12H0.22599C0.0347528 12 -0.0697163 12.2594 0.0527107 12.4302L1.82672 14.9056C1.9169 15.0315 2.0831 15.0315 2.17328 14.9056L3.94729 12.4302C4.06972 12.2594 3.96525 12 3.77401 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.96967 7.96967C8.26256 7.67678 8.73744 7.67678 9.03033 7.96967L16.0303 14.9697C16.3232 15.2626 16.3232 15.7374 16.0303 16.0303C15.7374 16.3232 15.2626 16.3232 14.9697 16.0303L7.96967 9.03033C7.67678 8.73744 7.67678 8.26256 7.96967 7.96967Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.96967 16.0303C8.26256 16.3232 8.73744 16.3232 9.03033 16.0303L16.0303 9.03033C16.3232 8.73744 16.3232 8.26256 16.0303 7.96967C15.7374 7.67678 15.2626 7.67678 14.9697 7.96967L7.96967 14.9697C7.67678 15.2626 7.67678 15.7374 7.96967 16.0303Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_15323_368">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
