export const toCamelCase = (str: string): string => {
  if (isSingleWord(str)) {
    return convertSingleWordToCamelCase(str)
  }

  return convertMultiWordToCamelCase(str)
}

export const isSingleWord = (str: string): boolean => {
  if (str.length === 0) return false
  return str.split(' ').length === 1
}

export const convertSingleWordToCamelCase = (str: string): string => {
  if (str.length === 0) return ''
  if (isUpperCase(str)) {
    return str.toLowerCase()
  }

  const [firstSymbol, ...restSymbols] = [...str]

  return firstSymbol!.toLowerCase() + restSymbols.join('')
}

export const isUpperCase = (str: string): boolean => {
  if (str.length === 0) return false

  return [...str].every(char => char === char.toUpperCase())
}

export const convertMultiWordToCamelCase = (str: string): string => {
  return str
    .toLowerCase()
    .replace(/[^\d A-Za-z]/g, '')
    .replace(/^\w|[A-Z]|\b\w/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}
