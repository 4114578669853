import { alpha, Box } from '@mui/material'
import { ReactNode } from 'react'

type Props = Readonly<{
  children?: ReactNode
}>

export const PurpleFadingGradientContainer = ({ children }: Props) => {
  return (
    <Box
      sx={{
        background: theme =>
          `linear-gradient(358.24deg, ${
            theme.palette.mediumPurple[100]
          } 1.7%, ${alpha(theme.palette.mediumPurple[100], 0)} 82.46%)`,
      }}
    >
      {children}
    </Box>
  )
}
