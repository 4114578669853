import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

import { TContactInformationType } from '../contacts.codecs'

export const TContactMappingField = t.strict({
  code: t.string,
  field: t.string,
  required: t.boolean,
})

export type ContactMappingField = t.TypeOf<typeof TContactMappingField>

export const TContactMappingFields = t.array(TContactMappingField)

const TImportStatistic = t.strict({
  failedToImportNumber: t.number,
  successfullyImportedNumber: t.number,
})

export type ImportStatistic = t.TypeOf<typeof TImportStatistic>

export const TImportSessionId = t.strict({ importSessionId: t.string })

export const TImportSessionDetails = t.intersection([
  TImportSessionId,
  TImportStatistic,
])

const TImportHistoryStatus = t.union([
  t.literal('FINISHED'),
  t.literal('IN_PROGRESS'),
  t.literal('REVERTED'),
  t.literal('FAILED'),
  t.literal('CANCELED'),
])

export type ImportHistoryStatus = t.TypeOf<typeof TImportHistoryStatus>

export const TImportHistoryRow = t.strict({
  importSessionId: t.string,
  name: t.string,
  status: TImportHistoryStatus,
  createdAt: DateFromISOString,
})

export type ImportHistoryRow = t.TypeOf<typeof TImportHistoryRow>

export type Columns = Array<{
  /** Column name from CSV file */
  key: string
  /** Selected Axterior contact field */
  value: null | (ContactMappingField & { label?: string })
}>

export type SubmittedColumns = Array<{
  index: number
  key: string
  label?: string
}>

export enum DEFAULT_FIELDS {
  name = 'NAME',
  birthDate = 'DATE_OF_BIRTH',
  education = 'EDUCATION',
  position = 'POSITION',
  level = 'LEVEL',
  notes = 'NOTES',
  country = 'COUNTRY',
  city = 'CITY',
  phone = 'PHONE',
  email = 'EMAIL',
  messenger = 'MESSENGER',
  link = 'LINK',
  source = 'SOURCE',
}

export enum CONTACT_FIELDS {
  workPhone = 'PHONE WORK',
  personalPhone = 'PHONE PERSONAL',
  workEmail = 'EMAIL WORK',
  personalEmail = 'EMAIL PERSONAL',
  whatsapp = 'MESSENGER WHATSAPP',
  viber = 'MESSENGER VIBER',
  telegram = 'MESSENGER TELEGRAM',
  skype = 'MESSENGER SKYPE',
  signal = 'MESSENGER SIGNAL',
}

export type TableColumn = {
  name: string
  key: string
}

export type ContactInfo = {
  type: 'PHONE' | 'EMAIL' | 'MESSENGER'
  label: string
  value: string
}

export type CustomFieldImport = {
  fieldId: string
  fieldValue: string
}

export type TableKeys = {
  [K in TableColumn['key']]?: string
}

export type Contacts = {
  contactInfos: ContactInfo[]
  customFields: CustomFieldImport[]
} & TableKeys

const TImportContactInfos = t.array(
  t.strict({
    type: TContactInformationType,
    label: t.string,
    value: t.string,
  }),
)

const TImportContact = t.intersection([
  t.strict({
    name: t.string,
    position: t.string,
  }),
  t.partial({
    birthDate: t.string,
    education: t.string,
    level: t.string,
    notes: t.string,
    country: t.string,
    city: t.string,
    contactInfos: TImportContactInfos,
    link: t.string,
    source: t.string,
    customFields: t.array(
      t.strict({ fieldName: t.string, fieldValue: t.string }),
    ),
  }),
])

export type ImportContact = t.TypeOf<typeof TImportContact>

const TImportContactsFile = t.strict({
  fileName: t.string,
  contacts: t.array(TImportContact),
})

export type ImportContactsFile = t.TypeOf<typeof TImportContactsFile>

const TImportStatus = t.union([t.literal('IMPORTED'), t.literal('FAILED')])

export type ImportStatus = t.TypeOf<typeof TImportStatus>

export const TImportContractDetails = t.strict({
  status: TImportStatus,
  importData: TImportContact,
  errors: t.array(
    t.strict({
      fieldName: t.string,
      message: t.string,
    }),
  ),
})

export type ImportContractDetails = t.TypeOf<typeof TImportContractDetails>
