import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconArrowClockwise = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3.00001C5.23858 3.00001 3 5.23859 3 8.00001C3 10.7614 5.23858 13 8 13C10.7614 13 13 10.7614 13 8.00001C13 7.25458 12.8372 6.54854 12.5457 5.91435C12.4304 5.66344 12.5403 5.36655 12.7912 5.25122C13.0421 5.13589 13.339 5.2458 13.4543 5.49671C13.8048 6.25924 14 7.10751 14 8.00001C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8.00001C2 4.6863 4.68629 2.00001 8 2.00001V3.00001Z"
      fill="#4D5678"
    />
    <path
      d="M8 4.46625V0.533773C8 0.321813 8.24721 0.206024 8.41005 0.341717L10.7695 2.30796C10.8895 2.40791 10.8895 2.59212 10.7695 2.69207L8.41005 4.65831C8.24721 4.794 8 4.67821 8 4.46625Z"
      fill="#4D5678"
    />
  </SvgIcon>
)
