import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCurve = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 34 28">
    <path
      fill="none"
      opacity="0.5"
      d="M0.5 27.5C0.5 27.5 3.11091 14.8968 9.5 13C14.3126 11.5712 17.1125 17.1463 22 16C29.0722 14.3412 33 1 33 1"
      stroke="currentColor"
    />
  </SvgIcon>
)
