import SvgIcon from '@mui/material/SvgIcon'

import { FilledIcon } from '../icon-props'

export const IconReport = ({ filled, ...props }: FilledIcon) =>
  filled ? (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <path
        d="M7.3125 0C6.38052 0 5.625 0.755519 5.625 1.6875V2.8125C5.625 3.74448 6.38052 4.5 7.3125 4.5H10.6875C11.6195 4.5 12.375 3.74448 12.375 2.8125V1.6875C12.375 0.755519 11.6195 0 10.6875 0H7.3125ZM10.6875 1.125C10.9982 1.125 11.25 1.37684 11.25 1.6875V2.8125C11.25 3.12316 10.9982 3.375 10.6875 3.375H7.3125C7.00184 3.375 6.75 3.12316 6.75 2.8125V1.6875C6.75 1.37684 7.00184 1.125 7.3125 1.125H10.6875Z"
        fill="currentColor"
      />
      <path
        d="M4.5 1.6875H3.375C2.13236 1.6875 1.125 2.69486 1.125 3.9375V15.75C1.125 16.9926 2.13236 18 3.375 18H14.625C15.8676 18 16.875 16.9926 16.875 15.75V3.9375C16.875 2.69486 15.8676 1.6875 14.625 1.6875H13.5V2.8125C13.5 4.3658 12.2408 5.625 10.6875 5.625H7.3125C5.7592 5.625 4.5 4.3658 4.5 2.8125V1.6875ZM11.25 9C11.25 8.37868 11.7537 7.875 12.375 7.875C12.9963 7.875 13.5 8.37868 13.5 9V14.625C13.5 15.2463 12.9963 15.75 12.375 15.75C11.7537 15.75 11.25 15.2463 11.25 14.625V9ZM4.5 13.5C4.5 12.8787 5.00368 12.375 5.625 12.375C6.24632 12.375 6.75 12.8787 6.75 13.5V14.625C6.75 15.2463 6.24632 15.75 5.625 15.75C5.00368 15.75 4.5 15.2463 4.5 14.625V13.5ZM9 10.125C9.62132 10.125 10.125 10.6287 10.125 11.25V14.625C10.125 15.2463 9.62132 15.75 9 15.75C8.37868 15.75 7.875 15.2463 7.875 14.625V11.25C7.875 10.6287 8.37868 10.125 9 10.125Z"
        fill="currentColor"
      />
    </SvgIcon>
  ) : (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <path
        d="M4.5 12.375C4.5 12.0766 4.61853 11.7905 4.82951 11.5795C5.04048 11.3685 5.32663 11.25 5.625 11.25C5.92337 11.25 6.20952 11.3685 6.42049 11.5795C6.63147 11.7905 6.75 12.0766 6.75 12.375V13.5C6.75 13.7984 6.63147 14.0845 6.42049 14.2955C6.20952 14.5065 5.92337 14.625 5.625 14.625C5.32663 14.625 5.04048 14.5065 4.82951 14.2955C4.61853 14.0845 4.5 13.7984 4.5 13.5V12.375ZM11.25 7.875C11.25 7.57663 11.3685 7.29048 11.5795 7.07951C11.7905 6.86853 12.0766 6.75 12.375 6.75C12.6734 6.75 12.9595 6.86853 13.1705 7.07951C13.3815 7.29048 13.5 7.57663 13.5 7.875V13.5C13.5 13.7984 13.3815 14.0845 13.1705 14.2955C12.9595 14.5065 12.6734 14.625 12.375 14.625C12.0766 14.625 11.7905 14.5065 11.5795 14.2955C11.3685 14.0845 11.25 13.7984 11.25 13.5V7.875ZM7.875 10.125C7.875 9.82663 7.99353 9.54048 8.2045 9.3295C8.41548 9.11853 8.70163 9 9 9C9.29837 9 9.58452 9.11853 9.7955 9.3295C10.0065 9.54048 10.125 9.82663 10.125 10.125V13.5C10.125 13.7984 10.0065 14.0845 9.7955 14.2955C9.58452 14.5065 9.29837 14.625 9 14.625C8.70163 14.625 8.41548 14.5065 8.2045 14.2955C7.99353 14.0845 7.875 13.7984 7.875 13.5V10.125Z"
        fill="currentColor"
      />
      <path
        d="M4.5 1.6875H3.375C2.77826 1.6875 2.20597 1.92455 1.78401 2.34651C1.36205 2.76847 1.125 3.34076 1.125 3.9375V15.75C1.125 16.3467 1.36205 16.919 1.78401 17.341C2.20597 17.7629 2.77826 18 3.375 18H14.625C15.2217 18 15.794 17.7629 16.216 17.341C16.6379 16.919 16.875 16.3467 16.875 15.75V3.9375C16.875 3.34076 16.6379 2.76847 16.216 2.34651C15.794 1.92455 15.2217 1.6875 14.625 1.6875H13.5V2.8125H14.625C14.9234 2.8125 15.2095 2.93103 15.4205 3.142C15.6315 3.35298 15.75 3.63913 15.75 3.9375V15.75C15.75 16.0484 15.6315 16.3345 15.4205 16.5455C15.2095 16.7565 14.9234 16.875 14.625 16.875H3.375C3.07663 16.875 2.79048 16.7565 2.5795 16.5455C2.36853 16.3345 2.25 16.0484 2.25 15.75V3.9375C2.25 3.63913 2.36853 3.35298 2.5795 3.142C2.79048 2.93103 3.07663 2.8125 3.375 2.8125H4.5V1.6875Z"
        fill="currentColor"
      />
      <path
        d="M10.6875 1.125C10.8367 1.125 10.9798 1.18426 11.0852 1.28975C11.1907 1.39524 11.25 1.53832 11.25 1.6875V2.8125C11.25 2.96168 11.1907 3.10476 11.0852 3.21025C10.9798 3.31574 10.8367 3.375 10.6875 3.375H7.3125C7.16332 3.375 7.02024 3.31574 6.91475 3.21025C6.80926 3.10476 6.75 2.96168 6.75 2.8125V1.6875C6.75 1.53832 6.80926 1.39524 6.91475 1.28975C7.02024 1.18426 7.16332 1.125 7.3125 1.125H10.6875ZM7.3125 0C6.86495 0 6.43572 0.17779 6.11926 0.494257C5.80279 0.810725 5.625 1.23995 5.625 1.6875V2.8125C5.625 3.26005 5.80279 3.68928 6.11926 4.00574C6.43572 4.32221 6.86495 4.5 7.3125 4.5H10.6875C11.1351 4.5 11.5643 4.32221 11.8807 4.00574C12.1972 3.68928 12.375 3.26005 12.375 2.8125V1.6875C12.375 1.23995 12.1972 0.810725 11.8807 0.494257C11.5643 0.17779 11.1351 0 10.6875 0L7.3125 0Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
