import { Box, Stack } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getContactsList, qk } from 'api'
import { paths } from 'app/paths'
import { useScopes } from 'app/scopes'
import { previewCloseExceptionClass, PreviewDrawer } from 'components/common'
import { ContactsSearch } from 'lib/app-helpers/search/contacts-search'
import { renderQueryResult } from 'lib/react-query-utils'
import { useBoolean, useDebounceState } from 'lib/react-utils'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { Table, TableSelected } from 'ui/data'
import { CircularProgress } from 'ui/feedback'

import { ContactPreview } from './contact-preview'
import { ContactProfile } from './contact-profile'

type Props = Readonly<{
  talentPoolId?: string
  selected: TableSelected
  setSelected: (selected: TableSelected) => void
}>

export const ContactsSelectList = ({
  talentPoolId,
  selected,
  setSelected,
}: Props) => {
  const { t } = useTranslation()

  const [previewId, setPreviewId] = useState<string | null>(null)
  const [debouncedSearch, setDebouncedSearch] = useDebounceState('', 500)

  const previewClickAwayDisabled = useBoolean()

  const [page, setPage] = useState(0)

  const handleChangePage = useCallback(
    (newPage: number) => {
      setPage(newPage)
    },
    [setPage],
  )

  const pagination = {
    page: page,
    pageSize: 5,
    onChangePage: handleChangePage,
    onChangePageSize: () => {},
  }

  const { canViewContacts } = useScopes()

  const args = {
    pagination,
    talentPoolExclude: talentPoolId,
    search: debouncedSearch,
  }

  const $contacts = useQuery(
    qk.contacts.list.toKeyWithArgs(args),
    () => getContactsList(args),
    { keepPreviousData: true },
  )

  return renderQueryResult($contacts, {
    loading: () => (
      <Box position="relative" height="10px">
        <CircularProgress centered />
      </Box>
    ),
    success: contactsData => {
      const contacts = contactsData.rows

      return (
        <>
          <Stack spacing={2}>
            <Box>
              <ContactsSearch onInputChange={setDebouncedSearch} />
            </Box>

            <Box>
              <Table
                columns={[{ key: 'contact', name: t('common.contact') }]}
                rows={contacts.map(contact => ({
                  key: contact.candidateProfileId,
                  onClick: () => {
                    setPreviewId(contact.candidateProfileId)
                  },
                  cells: [
                    <ContactProfile
                      key="contact"
                      avatar={contact.imageUri}
                      name={contact.name}
                      position={contact.position.name}
                      linkTo={
                        canViewContacts
                          ? {
                              pathname: generatePath(paths.contactDetails, {
                                contactId: contact.candidateProfileId,
                              }),
                              search: talentPoolId
                                ? `?talentPoolId=${talentPoolId}`
                                : '',
                            }
                          : undefined
                      }
                      linkStopPropagation
                    />,
                  ],
                }))}
                disableHeader
                selected={selected}
                setSelected={setSelected}
                pagination={pagination}
                totalCount={contactsData.count}
                rowsClassName={previewCloseExceptionClass}
                withRowsByPage={false}
                withPaginationDivider={false}
                tableMaxHeight="260px"
                fullHeight
                noBorder
              />
            </Box>
          </Stack>

          <PreviewDrawer
            onClose={() => setPreviewId(null)}
            isOpened={previewId !== null}
            clickAwayDisabled={previewClickAwayDisabled.isTrue}
          >
            {previewId && (
              <ContactPreview
                contactId={previewId}
                onOpenDialog={previewClickAwayDisabled.setTrue}
                onCloseDialog={previewClickAwayDisabled.setFalse}
                onClose={() => setPreviewId(null)}
                contactIds={contacts.map(contact => contact.candidateProfileId)}
                setPreviewId={setPreviewId}
              />
            )}
          </PreviewDrawer>
        </>
      )
    },
  })
}
