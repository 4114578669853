import { Backdrop, Box, Fade, Paper, Popper, Typography } from '@mui/material'
import { IntroductionTourHighlightId, useIntroductionTour } from 'lib/context'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'

type Props = Readonly<{
  highlightId: IntroductionTourHighlightId
  children: React.ReactNode
}>

export const IntroductionTourHighlight = ({ highlightId, children }: Props) => {
  const { t } = useTranslation()
  const tour = useIntroductionTour()
  const popoverRef = useRef<HTMLDivElement | null>(null)

  const isActive =
    tour.isActive &&
    tour.activeHighlight !== null &&
    tour.activeHighlight.highlightId === highlightId

  const zIndexBackdrop = 100_000 // overlap everything possible

  useEffect(() => {
    if (isActive && popoverRef.current) {
      popoverRef.current.scrollIntoView({
        block: 'center',
      })
    }
  }, [isActive])

  return (
    <Box width="100%">
      <Box
        ref={popoverRef}
        sx={{
          ...(isActive && {
            position: 'relative',
            zIndex: zIndexBackdrop + 1,
            borderRadius: '2px',
          }),
        }}
      >
        {children}
      </Box>

      <Backdrop open={isActive} sx={{ zIndex: zIndexBackdrop }}>
        <Popper
          open={isActive}
          anchorEl={popoverRef.current}
          placement="bottom-start"
          sx={{ zIndex: zIndexBackdrop + 1 }}
          transition
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 4],
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Paper
                sx={{
                  width: 300,
                  borderRadius: '4px',
                  p: 3,
                }}
              >
                {tour.activeHighlight && (
                  <Typography variant="body1" mb={3.5}>
                    {tour.activeHighlight.message}
                  </Typography>
                )}

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Button
                    variant="outlined"
                    color="greyBlue"
                    onClick={tour.finishTour}
                  >
                    {t('common.skip')}
                  </Button>
                  <Button onClick={tour.moveForward}>{t('common.next')}</Button>
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
      </Backdrop>
    </Box>
  )
}
