import { zodResolver } from '@hookform/resolvers/zod'
import { ControlsLine } from 'components/common'
import { CityAutocomplete } from 'components/location/city-autocomplete'
import { CountryAutocomplete } from 'components/location/country-autocomplete'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogContent } from 'ui/feedback'
import { z } from 'zod'

import { labels } from '../_labels'

const schema = z.object({ country: z.string(), city: z.string() })

export type FormValues = z.TypeOf<typeof schema>

type Props = Readonly<{
  isOpen: boolean
  onClose: () => void
  onConfirm: (values: FormValues) => void
  values: FormValues
}>

export const AddLocationDialog = ({
  isOpen,
  onClose,
  onConfirm,
  values,
}: Props) => {
  const { t } = useTranslation()

  const { control, handleSubmit, setValue, watch } = useForm<FormValues>({
    defaultValues: values,
    resolver: zodResolver(schema),
  })

  const country = watch('country')

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent
        title={t('common.location')}
        onDeny={onClose}
        onConfirm={() => {
          handleSubmit(values => onConfirm(values))()
          onClose()
        }}
        wrapWithForm
        confirmText={t('common.add')}
      >
        <ControlsLine>
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <CountryAutocomplete
                label={t(labels.country).toString()}
                placeholder={t('common.select_option')}
                value={field.value}
                dataCy="locationCountryInput"
                onChange={value => {
                  field.onChange(value)
                  setValue('city', '')
                }}
              />
            )}
          />

          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <CityAutocomplete
                label={t(labels.city).toString()}
                value={field.value}
                onChange={field.onChange}
                country={country}
                placeholder={t('common.start_typing')}
                dataCy="locationCityInput"
              />
            )}
          />
        </ControlsLine>
      </DialogContent>
    </Dialog>
  )
}
