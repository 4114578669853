import { Timeline as MuiTimeline } from '@mui/lab'
import { styled } from '@mui/material'

export const Timeline = styled(MuiTimeline)({
  padding: 0,
  margin: 0,
  b: {
    fontWeight: 500,
  },
})
