import { pipe } from 'fp-ts/lib/function'
import { decodeJson, get, post } from 'lib/request'

import { TCompanyPromotion, TPromotionStatus } from './promotion.codec'

export type GetPromotionStatusInput = {
  slug: string
}

export const getPromotionStatus = async ({ slug }: GetPromotionStatusInput) => {
  return pipe(
    await get('subscription/subscriptions/promotions/check', {
      query: new URLSearchParams({ slug }),
    }),
    decodeJson(TPromotionStatus),
  )
}

export const getCompanyPromotion = async () => {
  return pipe(
    await get('subscription/subscriptions/promotions/companies/me', {}),
    decodeJson(TCompanyPromotion),
  )
}

type StartPromotionSubscriptionInput = {
  paymentMethodId: string | null
}

export const startPromotionSubscription = async ({
  paymentMethodId,
}: StartPromotionSubscriptionInput) => {
  const params = new URLSearchParams()

  if (paymentMethodId) {
    params.set('paymentMethodId', paymentMethodId)
  }

  return await post('subscription/subscriptions/promotions/companies/me', {
    query: params,
  })
}
