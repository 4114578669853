import { TCompanyPosition } from 'api/company'
import {
  TContactDocument,
  TContactInformation,
  TContactLink,
} from 'api/contacts'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

const TJobHiredContactInfo = t.strict({
  hiredAt: DateFromISOString,
  contactInfos: t.array(TContactInformation),
})

const TJobContact = t.intersection([
  t.strict({
    candidateId: t.string,
    name: t.string,
    position: TCompanyPosition,
    jobPipelineStage: t.strict({
      stageId: t.string,
      order: t.number,
      canHire: t.boolean,
    }),
    appliedAt: DateFromISOString,
    documents: t.array(TContactDocument),
    links: t.array(TContactLink),
    selfRequested: t.boolean,
  }),
  t.partial({
    rejectedAt: DateFromISOString,
    hireInfo: TJobHiredContactInfo,
    imageUri: t.string,
    lastComment: t.string,
    contactInfos: t.array(TContactInformation),
  }),
])

export type JobContact = t.TypeOf<typeof TJobContact>

export const TJobContactsList = t.array(TJobContact)
