import { useTheme } from '@mui/material'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconPriorityLow = (props: SvgIconProps) => {
  const theme = useTheme()
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M9 7.2C9 7.08954 8.91046 7 8.8 7H7.2C7.08954 7 7 7.08954 7 7.2V13.8C7 13.9105 7.08954 14 7.2 14H8.8C8.91046 14 9 13.9105 9 13.8V7.2ZM13.8 2C13.9105 2 14 2.08954 14 2.2V13.8C14 13.9105 13.9105 14 13.8 14H12.2C12.0895 14 12 13.9105 12 13.8V2.2C12 2.08954 12.0895 2 12.2 2H13.8ZM12 1C11.7348 1 11.4804 1.10536 11.2929 1.29289C11.1054 1.48043 11 1.73478 11 2V14C11 14.2652 11.1054 14.5196 11.2929 14.7071C11.4804 14.8946 11.7348 15 12 15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14V2C15 1.73478 14.8946 1.48043 14.7071 1.29289C14.5196 1.10536 14.2652 1 14 1H12ZM6 7C6 6.73478 6.10536 6.48043 6.29289 6.29289C6.48043 6.10536 6.73478 6 7 6H9C9.26522 6 9.51957 6.10536 9.70711 6.29289C9.89464 6.48043 10 6.73478 10 7V14C10 14.2652 9.89464 14.5196 9.70711 14.7071C9.51957 14.8946 9.26522 15 9 15H7C6.73478 15 6.48043 14.8946 6.29289 14.7071C6.10536 14.5196 6 14.2652 6 14V7ZM1 11C1 10.7348 1.10536 10.4804 1.29289 10.2929C1.48043 10.1054 1.73478 10 2 10H4C4.26522 10 4.51957 10.1054 4.70711 10.2929C4.89464 10.4804 5 10.7348 5 11V14C5 14.2652 4.89464 14.5196 4.70711 14.7071C4.51957 14.8946 4.26522 15 4 15H2C1.73478 15 1.48043 14.8946 1.29289 14.7071C1.10536 14.5196 1 14.2652 1 14V11Z"
        fill={theme.palette.blue.main}
      />
    </SvgIcon>
  )
}
