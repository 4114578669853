import { Box } from '@mui/material'
import {
  APP_LAYOUT_X_PADDING,
  MAX_FORM_CONTENT_WIDTH,
} from 'app/mui-theme/theme'
import { FormEvent } from 'react'

type Props = {
  onSubmit: () => void
  children: React.ReactNode
  toolbar: React.ReactNode
  leftSide?: React.ReactNode
  withLargeLeftPadding?: boolean
}

export const FormLayout = ({
  onSubmit,
  children,
  toolbar,
  leftSide,
  withLargeLeftPadding = false,
}: Props) => {
  return (
    <Box
      component="form"
      noValidate
      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      onSubmit={(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        onSubmit()
      }}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          event.preventDefault()
        }
      }}
    >
      {toolbar}

      {leftSide ? (
        <Box display="flex" overflow="auto">
          <Box
            flex="0 0 50%"
            overflow="auto"
            borderRight={theme => `1px solid ${theme.palette.divider}`}
          >
            {leftSide}
          </Box>

          <Box flex="0 0 50%" overflow="auto" bgcolor="white.primary">
            <Box maxWidth={MAX_FORM_CONTENT_WIDTH} padding={4}>
              {children}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            bgcolor: '#FFFFFF',
            overflow: 'auto',
            boxSizing: 'border-box',

            py: 4,
            pr: APP_LAYOUT_X_PADDING.xs,
            pl: withLargeLeftPadding
              ? {
                  xs: APP_LAYOUT_X_PADDING.xs,
                  sm: '64px',
                  md: '128px',
                  lg: '200px',
                }
              : APP_LAYOUT_X_PADDING.xs,
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  )
}
