import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconArrowDownload = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 17">
    <path
      d="M0.5 10.4C0.776142 10.4 1 10.6239 1 10.9V13.4C1 13.9523 1.44772 14.4 2 14.4H14C14.5523 14.4 15 13.9523 15 13.4V10.9C15 10.6239 15.2239 10.4 15.5 10.4C15.7761 10.4 16 10.6239 16 10.9V13.4C16 14.5046 15.1046 15.4 14 15.4H2C0.895431 15.4 0 14.5046 0 13.4V10.9C0 10.6239 0.223858 10.4 0.5 10.4Z"
      fill="currentColor"
    />
    <path
      d="M7.64645 12.3536C7.84171 12.5488 8.15829 12.5488 8.35355 12.3536L11.3536 9.35355C11.5488 9.15829 11.5488 8.84171 11.3536 8.64645C11.1583 8.45118 10.8417 8.45118 10.6464 8.64645L8.5 10.7929V2C8.5 1.72386 8.27614 1.5 8 1.5C7.72386 1.5 7.5 1.72386 7.5 2V10.7929L5.35355 8.64645C5.15829 8.45118 4.84171 8.45118 4.64645 8.64645C4.45118 8.84171 4.45118 9.15829 4.64645 9.35355L7.64645 12.3536Z"
      fill="currentColor"
    />
  </SvgIcon>
)
