import { TRoleId } from 'api'
import * as t from 'io-ts'
import jwtDecode from 'jwt-decode'

export let globalAccessToken: string | null = null
export const setGlobalAccessToken = (accessToken: string | null) => {
  globalAccessToken = accessToken
}

const TAccessTokenPayload = t.strict({
  userId: t.string,
  scopes: t.array(t.string),
  exp: t.number,
  role: TRoleId,
})

export type AccessTokenPayload = t.TypeOf<typeof TAccessTokenPayload>

export const decodeAccessToken = (accessToken: string) => {
  return TAccessTokenPayload.decode(jwtDecode(accessToken))
}
