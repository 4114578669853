import {
  Autocomplete as MuiAutocomplete,
  autocompleteClasses,
  Box,
  Chip,
  chipClasses,
  inputBaseClasses,
  Typography,
} from '@mui/material'
import { IconChevronDown, IconX } from 'assets/icons'
import { useBoolean } from 'lib/react-utils'
import { forwardRef, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'ui/inputs/checkbox'
import { FormControl, FormControlWrapper, InputBase } from 'ui/inputs/common'

import { MenuOption } from '../common'
import { AutocompletePopper } from './autocomplete-popper'

type Option = {
  value: string
  label: string
  disabled?: boolean
  content?: React.ReactNode
  avatar?: React.ReactElement
}

export type MultipleAutocompleteProps = FormControlWrapper & {
  options: Array<Option>
  value: Array<string>
  onChange: (value: Array<string>) => void

  placeholder?: string
  disabled?: boolean
  loading?: boolean
  limitTags?: number
  loadingError?: boolean
  sharpEnd?: boolean
  showCheckboxes?: boolean
  renderTags?: (value: Array<string>) => React.ReactNode
  disableInternalFilter?: boolean
  closedWhileInputEmpty?: boolean
  onInputChange?: (value: string) => void
}

export const MultipleAutocomplete = forwardRef(
  (props: MultipleAutocompleteProps, ref) => {
    const { t } = useTranslation()

    const isOpen = useBoolean()
    const [inputValue, setInputValue] = useState('')
    const selectedOptions = useRef(
      props.options.filter(option => props.value.includes(option.value)),
    )

    const hasChips = props.value && props.value.length > 0

    const findOptionByValue = (value: string) =>
      props.options.find(option => option.value === value)

    return (
      <FormControl
        label={props.label}
        error={props.error}
        helperText={props.helperText}
        onRemove={props.onRemove}
        required={props.required}
        name={props.name}
      >
        <MuiAutocomplete
          ref={ref}
          multiple
          disableClearable
          onOpen={isOpen.setTrue}
          onClose={isOpen.setFalse}
          options={props.options.map(option => option.value)}
          value={props.value}
          onChange={(_, newValue) => {
            props.onChange(newValue)
            selectedOptions.current = props.options.filter(option =>
              newValue.includes(option.value),
            )
          }}
          noOptionsText={
            props.loadingError ? t('common.failed_to_load_options') : undefined
          }
          disabled={props.disabled}
          getOptionLabel={optionValue =>
            findOptionByValue(optionValue)?.label ?? ''
          }
          loading={props.loading}
          open={
            isOpen.isTrue &&
            (props.closedWhileInputEmpty ? inputValue !== '' : true)
          }
          popupIcon={
            <IconChevronDown
              sx={{
                fontSize: '20px',
                color: theme => theme.palette.mineShaft[800],
              }}
            />
          }
          filterOptions={
            props.disableInternalFilter ? options => options : undefined
          }
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue)
            props.onInputChange?.(newInputValue)
          }}
          renderTags={values => {
            if (props.renderTags) {
              return props.renderTags(values)
            }

            const tags = values.map(optionValue => {
              const option = selectedOptions.current.find(
                option => option.value === optionValue,
              )

              return (
                <Chip
                  key={optionValue}
                  size="small"
                  label={option?.label + '' ?? ''}
                  avatar={option?.avatar}
                  sx={{
                    height: '100%',
                    backgroundColor: 'mineShaft.100',
                    borderRadius: 1,
                    mr: 0.5,
                    maxWidth: props.value.length === 1 ? '200px' : '100px',

                    [`& .${chipClasses.deleteIcon}`]: {
                      mr: 1,
                      ml: 0.25,
                      color: 'text.secondary',
                      fontSize: '8px',
                    },
                  }}
                  deleteIcon={<IconX stroke="2" />}
                  onDelete={() => {
                    props.onChange(props.value.filter(v => v !== optionValue))
                  }}
                />
              )
            })

            if (props.limitTags) {
              tags.length = props.limitTags
            }

            return (
              <>
                {tags}
                {props.limitTags && props.value.length > props.limitTags && (
                  <Typography variant="caption">
                    +{props.value.length - props.limitTags}
                  </Typography>
                )}
              </>
            )
          }}
          renderInput={params => (
            <InputBase
              {...params.InputProps}
              inputProps={{
                ...params.inputProps,
                type: 'search',
                autoComplete: 'off',
              }}
              placeholder={!hasChips ? props.placeholder : undefined}
              error={props.error}
              disabled={props.disabled}
              required={props.required}
            />
          )}
          disableCloseOnSelect
          renderOption={(
            { className, ...optionProps },
            optionValue,
            { selected },
          ) => {
            const option = findOptionByValue(optionValue)
            return option ? (
              <MenuOption {...optionProps} disabled={option.disabled}>
                {props.showCheckboxes && (
                  <Box mr={1.25}>
                    <Checkbox small checked={selected} />
                  </Box>
                )}
                {option.content ?? option.label}
              </MenuOption>
            ) : null
          }}
          PopperComponent={AutocompletePopper}
          sx={{
            flex: 'auto',

            [`& .${autocompleteClasses.inputRoot}`]: {
              ...(hasChips && {
                [`&.${inputBaseClasses.root}`]: {
                  display: 'flex',
                  alignItems: 'center',
                },
              }),

              [`&.${inputBaseClasses.adornedStart}`]: {
                pl: 1,
              },

              [`&.${inputBaseClasses.adornedEnd}`]: {
                pr: 4,
              },

              [`& .${autocompleteClasses.input}`]: {
                minWidth: theme => (hasChips ? 0 : theme.spacing(10)),
                padding: hasChips ? 0 : undefined,
              },

              [`& .${autocompleteClasses.endAdornment}`]: {
                top: '50%',
                transform: 'translate(0, -50%)',
                mr: 1,
              },

              ...(props.sharpEnd && {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }),
            },
          }}
        />
      </FormControl>
    )
  },
)
