import { Box, Typography } from '@mui/material'

type AbsoluteErrorMessageProps = Readonly<{
  errorMessage?: string
  left?: string
  right?: string
  top?: string
  bottom?: string
}>

// The relative container element should have this padding to ensure proper spacing.
export const PADDING_FOR_ABSOLUTE_ERROR = '8px'

export const AbsoluteErrorMessage = ({
  errorMessage,
  left,
  right,
  top,
  bottom,
}: AbsoluteErrorMessageProps) => {
  if (!errorMessage) return null

  return (
    <Box
      position="absolute"
      bottom={bottom ?? '-4px'}
      left={left}
      right={right}
      top={top}
    >
      <Typography color="error" fontSize="12px">
        {errorMessage}
      </Typography>
    </Box>
  )
}
