import * as t from 'io-ts'

export const TProfile = t.intersection([
  t.strict({
    profileId: t.string,
    fullName: t.string,
    role: t.string,
  }),
  t.partial({
    avatar: t.string,
  }),
])

export type Profile = t.TypeOf<typeof TProfile>

export const TProfiles = t.array(TProfile)
