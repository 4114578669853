import { useDictionaries } from 'lib/app-helpers'
import { useCallback } from 'react'

/**
 * Custom hook to format location information based on country code and city.
 *
 * @returns {Function} - Function for formatting location information.
 */
export const useFormatLocation = () => {
  const { dictionaries } = useDictionaries()

  /**
   * Function to format location information based on country code and city.
   *
   * @param {string | undefined} countryCode - Country code for the location.
   * @param {string | undefined} city - City for the location.
   * @returns {string} - Formatted location information.
   */
  return useCallback(
    (countryCode?: string, city?: string): string => {
      if (!countryCode && !city) return ''

      const countryName = dictionaries.countries.data.find(
        ({ code }) => code === countryCode,
      )?.name

      return [countryName, city].filter(Boolean).join(', ')
    },
    [dictionaries.countries.data],
  )
}
