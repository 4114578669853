import { alpha, Box } from '@mui/material'
import { ToastContainerId } from 'app/enums'
import { IconX } from 'assets/icons'
import { cssTransition, ToastContainer } from 'react-toastify'
import { IconButton } from 'ui/inputs/icon-button'

const Transition = cssTransition({
  enter: 'notification-toast-enter-active',
  exit: 'notification-toast-exit-active',
})

export const NotificationsFeedContainer = () => {
  return (
    <ToastContainer
      enableMultiContainer
      containerId={ToastContainerId.NotificationsFeed}
      limit={3}
      autoClose={false}
      hideProgressBar={false}
      position="top-right"
      newestOnTop={false}
      transition={Transition}
      closeOnClick={false}
      rtl={false}
      draggable={false}
      pauseOnHover={false}
      pauseOnFocusLoss={false}
      closeButton={({ closeToast }) => (
        <Box sx={{ position: 'absolute', top: '4px', right: '4px' }}>
          <IconButton onClick={closeToast}>
            <IconX sx={{ color: 'greyBlue.light', fontSize: 12 }} />
          </IconButton>
        </Box>
      )}
      style={{
        position: 'static',
        width: '300px',
        padding: 0,
      }}
      bodyStyle={{
        padding: 0,
        margin: 0,
      }}
      toastStyle={{
        padding: 0,
        marginTop: '4px',
        marginBottom: 0,
        boxShadow: `0px 1px 1px ${alpha('#000000', 0.05)}, 0px 1px 4px ${alpha(
          '#0043F0',
          0.12,
        )}`,
      }}
      progressStyle={{
        width: '2px',
        height: 'calc(100% - 4px)',
        bottom: '2px',
        left: '3px',
        background: '#FEA800',
        animation: 'Toastify__trackProgress--vertical linear 1 forwards',
        transformOrigin: 'bottom',
      }}
    />
  )
}
