import { useMutation } from '@tanstack/react-query'
import {
  getMyNotifications,
  Notification,
  TNotification,
  useInvalidateNotifications,
} from 'api'
import { globalAccessToken } from 'app/auth'
import { ToastContainerId } from 'app/enums'
import { NotificationFeedToast } from 'components/notifications'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { isLeft } from 'fp-ts/lib/Either'
import { formatValidationErrors } from 'io-ts-reporters'
import { useDismissToasts } from 'lib/toast'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

/**
 * Hook for managing real-time notifications feed.
 *
 * @returns {object} - Object with functions and state related to the notifications feed.
 * @property {function} subscribeToNotificationsFeed - Function to subscribe to the notifications feed.
 * @property {function} showCurrentUnreadNotifications - Function to show current unread notifications.
 * @property {function} clearNotificationsFeed - Function to clear the notifications feed.
 * @property {number} notificationsFeedCount - Current count of notifications in the feed.
 */
export const useNotificationsFeed = () => {
  const { dismissToastsByContainerId } = useDismissToasts()
  const invalidateNotifications = useInvalidateNotifications()
  const [notificationsFeedCount, setNotificationsFeedCount] = useState(0)
  const { mutate: getNotifications } = useMutation(getMyNotifications)

  // Notifications feed toast container has limit for 3 notifications
  // If more notifications are shown by this `showNotification` function,
  // they are added to the waiting queue automatically
  const showNotification = useCallback((notification: Notification) => {
    const autoClose = notification.criticality === 'MEDIUM' ? 5000 : false
    toast(<NotificationFeedToast notification={notification} />, {
      containerId: ToastContainerId.NotificationsFeed,
      autoClose,
      onClose: () => {
        setNotificationsFeedCount(prevCount => prevCount - 1)
      },
    })
  }, [])

  const subscribeToNotificationsFeed = useCallback(() => {
    const eventSource = new EventSourcePolyfill(
      '/messaging/notifications/feed',
      {
        headers: {
          Authorization: `Bearer ${globalAccessToken}`,
          Locale: 'EN',
        },
      },
    )

    // eslint-disable-next-line unicorn/prefer-add-event-listener
    eventSource.onmessage = event => {
      invalidateNotifications()

      try {
        const json = JSON.parse(event.data)
        const decodedNotification = TNotification.decode(json)

        if (isLeft(decodedNotification)) {
          throw formatValidationErrors(decodedNotification.left)
        }

        const notification = decodedNotification.right
        showNotification(notification)
        setNotificationsFeedCount(prevCount => prevCount + 1)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }

    return () => {
      eventSource.close()
    }
  }, [showNotification, invalidateNotifications])

  const showCurrentUnreadNotifications = useCallback(() => {
    getNotifications(
      {
        read: false,
        pagination: {
          page: 0,
          pageSize: 50,
        },
        criticalities: ['HIGH', 'MEDIUM'],
        order: {
          column: 'criticality',
          direction: 'asc',
        },
      },
      {
        onSuccess: notifications => {
          for (const notification of notifications.rows) {
            showNotification(notification)
          }

          setNotificationsFeedCount(
            prevCount => prevCount + notifications.rows.length,
          )
        },
      },
    )
  }, [showNotification, getNotifications])

  const clearNotificationsFeed = useCallback(() => {
    setNotificationsFeedCount(0)
    dismissToastsByContainerId(ToastContainerId.NotificationsFeed)
    toast.clearWaitingQueue({
      containerId: ToastContainerId.NotificationsFeed,
    })
  }, [dismissToastsByContainerId])

  return {
    subscribeToNotificationsFeed,
    showCurrentUnreadNotifications,
    clearNotificationsFeed,
    notificationsFeedCount,
  }
}
