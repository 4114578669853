import { LinkProps, NavLinkProps } from 'react-router-dom'
import { Button, ButtonProps } from 'ui/inputs/button'

import { NavRouteLink, RouteLink } from './route-link'

export const LinkButton = (props: LinkProps & ButtonProps) => {
  return <Button component={RouteLink} {...props} />
}

export const NavLinkButton = (props: NavLinkProps & ButtonProps) => {
  return <Button component={NavRouteLink} {...props} />
}
