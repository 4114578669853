import { Stack } from '@mui/material'
import { ContactLink as ContactLinkType } from 'api'
import { ContactLink } from 'components/contacts'
import { DetailsItemContainer } from 'components/templates'
import { useTranslation } from 'react-i18next'

type Props = Readonly<{
  links: Array<ContactLinkType>
}>

export const ContactLinksSection = ({ links }: Props) => {
  const { t } = useTranslation()

  if (links.length === 0) return null

  return (
    <DetailsItemContainer title={t('common.links')}>
      <Stack spacing={2}>
        {links.map(({ link, favicon }) => (
          <ContactLink key={link} linkHref={link} faviconBase64Data={favicon} />
        ))}
      </Stack>
    </DetailsItemContainer>
  )
}
