import { Stack, Typography, TypographyProps } from '@mui/material'
import { Avatar, AvatarProps } from 'ui/data'

type Props = Readonly<{
  avatarProps?: AvatarProps
  typographyProps?: TypographyProps
  children: string
}>

export const ContactNameWithAvatar = ({
  avatarProps,
  typographyProps,
  children,
}: Props) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Avatar size={24} {...avatarProps} />

      <Typography variant={'body2'} {...typographyProps}>
        {children}
      </Typography>
    </Stack>
  )
}
