import {
  createListSearchParams,
  ListOrder,
  ListPagination,
} from 'api/list-params'
import { pipe } from 'fp-ts/function'
import {
  decodeJson,
  decodeJsonWithTotal,
  del,
  get,
  patch,
  post,
  put,
} from 'lib/request'

import { TCompanySkill, TCompanySkillGroups } from './skills.codecs'

export type SkillId = {
  skillId: string
}

const SKILL_PATH = 'api/skills/:skillId'

type Filters = {
  name?: string
  active?: boolean
  group?: string
  positionId?: string
}

export type GetCompanySkillsListInput = {
  pagination: ListPagination
  filters: Filters
  order?: ListOrder
}

const generateFilters = (filters: Filters) => {
  const result = []

  if (filters.name !== undefined) {
    result.push({
      filterType: 'nameFilter',
      name: filters.name,
    })
  }

  if (filters.active !== undefined) {
    result.push({
      filterType: 'activeFilter',
      active: filters.active,
    })
  }

  if (filters.group !== undefined) {
    result.push({
      filterType: 'groupFilter',
      group: filters.group,
    })
  }

  if (filters.positionId !== undefined) {
    result.push({
      filterType: 'positionFilter',
      positionId: filters.positionId,
    })
  }

  return result
}

export const getCompanySkillsList = async ({
  pagination,
  filters,
  order,
}: GetCompanySkillsListInput) => {
  const params = createListSearchParams({ pagination, order })

  const generatedFilters = generateFilters(filters)

  return pipe(
    await post('api/skills/list', {
      query: params,
      body:
        generatedFilters.length > 0 ? { filters: generatedFilters } : undefined,
    }),
    decodeJsonWithTotal(TCompanySkill, pagination),
  )
}

export const getCompanySkillGroups = async () => {
  return pipe(
    await get('api/skills/groups', {}),
    decodeJson(TCompanySkillGroups),
  )
}

export type GetCompanySkillDetailsInput = SkillId

export const getCompanySkillDetails = async ({
  skillId,
}: GetCompanySkillDetailsInput) => {
  return pipe(
    await get(SKILL_PATH, { params: { skillId } }),
    decodeJson(TCompanySkill),
  )
}

type UpdateCompanySkillValues = {
  name: string
  group?: string
  active: boolean
  positionIds: Array<string>
}

export const createCompanySkill = async (input: UpdateCompanySkillValues) => {
  return await post('api/skills', { body: input })
}

export const updateCompanySkill = async ({
  skillId,
  ...values
}: SkillId & UpdateCompanySkillValues) => {
  return await put(SKILL_PATH, {
    params: { skillId },
    body: values,
  })
}

export const updateCompanySkillStatus = async ({
  skillId,
  active,
}: SkillId & {
  active: boolean
}) => {
  return await patch('api/skills/:skillId/active', {
    params: { skillId },
    query: new URLSearchParams({ active: active.toString() }),
  })
}

export const deleteCompanySkill = async ({ skillId }: SkillId) => {
  return await del(SKILL_PATH, { params: { skillId } })
}
