import { Stack } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { uploadPicture } from 'api'
import { useState } from 'react'
import { CircularProgress } from 'ui/feedback'
import { CropImageDialog } from 'ui/utils'

import { AvatarDropZone } from './avatar-drop-zone'

type Props = {
  withCrop?: boolean
  withSrc: boolean
  onUpload: (imageId: string, src: string) => void
  onDelete: () => void
}

export const AvatarUploader = (props: Props) => {
  const $upload = useMutation(uploadPicture)
  const [imageToCrop, setImageToCrop] = useState<Blob | null>(null)

  const upload = (image: Blob) => {
    $upload.mutate(
      { image },
      {
        onSuccess: ({ fileId }) => {
          const reader = new FileReader()

          reader.addEventListener('load', () => {
            const result = reader.result
            props.onUpload(fileId, result as string)
          })

          reader.readAsDataURL(image)
        },
      },
    )
  }

  const onFileReceived = (image: Blob) => {
    if (props.withCrop) {
      setImageToCrop(image)
    } else {
      upload(image)
    }
  }

  return (
    <>
      <Stack
        spacing={2}
        flex={1}
        minHeight={{ sx: 'auto', sm: '120px' }}
        position="relative"
      >
        {$upload.isLoading ? (
          <CircularProgress centered />
        ) : (
          <>
            <AvatarDropZone
              onDrop={files => {
                const image = files[0]

                if (image) {
                  onFileReceived(image)
                }
              }}
              onDelete={props.withSrc ? props.onDelete : undefined}
            />

            {/* TODO: NOT PRESENT IN NEW DESIGN */}
            {/*<UploadByUrl onFileReceived={onFileReceived} /> */}
          </>
        )}
      </Stack>

      {props.withCrop && (
        <CropImageDialog
          open={imageToCrop !== null}
          image={imageToCrop}
          onCrop={croppedImage => {
            setImageToCrop(null)
            upload(croppedImage)
          }}
          onClose={() => setImageToCrop(null)}
        />
      )}
    </>
  )
}
