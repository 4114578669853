import { StatusLogs } from 'api'
import { findLast } from 'fp-ts/lib/Array'
import { pipe } from 'fp-ts/lib/function'
import { fold } from 'fp-ts/Option'

export const findLastApproverLog = (
  statusLogs: StatusLogs,
  profileId: string,
) => {
  return pipe(
    statusLogs,
    findLast(log => log.changedBy?.profileId === profileId),
    fold(
      () => null,
      log => log,
    ),
  )
}
