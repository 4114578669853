import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconLanguageOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 17">
    <g clipPath="url(#clip0_6582_57736)">
      <path
        d="M4.545 7.214L4.11 8.5H3L4.862 3.5H6.146L8 8.5H6.833L6.398 7.214H4.545ZM6.179 6.478L5.5 4.456H5.451L4.772 6.478H6.18H6.179Z"
        fill="currentColor"
      />
      <path
        d="M0 2.5C0 1.96957 0.210714 1.46086 0.585786 1.08579C0.960859 0.710714 1.46957 0.5 2 0.5L9 0.5C9.53043 0.5 10.0391 0.710714 10.4142 1.08579C10.7893 1.46086 11 1.96957 11 2.5V5.5H14C14.5304 5.5 15.0391 5.71071 15.4142 6.08579C15.7893 6.46086 16 6.96957 16 7.5V14.5C16 15.0304 15.7893 15.5391 15.4142 15.9142C15.0391 16.2893 14.5304 16.5 14 16.5H7C6.46957 16.5 5.96086 16.2893 5.58579 15.9142C5.21071 15.5391 5 15.0304 5 14.5V11.5H2C1.46957 11.5 0.960859 11.2893 0.585786 10.9142C0.210714 10.5391 0 10.0304 0 9.5V2.5ZM2 1.5C1.73478 1.5 1.48043 1.60536 1.29289 1.79289C1.10536 1.98043 1 2.23478 1 2.5V9.5C1 9.76522 1.10536 10.0196 1.29289 10.2071C1.48043 10.3946 1.73478 10.5 2 10.5H9C9.26522 10.5 9.51957 10.3946 9.70711 10.2071C9.89464 10.0196 10 9.76522 10 9.5V2.5C10 2.23478 9.89464 1.98043 9.70711 1.79289C9.51957 1.60536 9.26522 1.5 9 1.5H2ZM9.138 11.495C9.331 11.796 9.54 12.078 9.768 12.341C9.02 12.916 8.095 13.342 7 13.633C7.178 13.85 7.451 14.268 7.555 14.5C8.68 14.141 9.635 13.656 10.441 13.006C11.218 13.671 12.18 14.171 13.371 14.478C13.504 14.224 13.785 13.805 14 13.588C12.875 13.335 11.943 12.894 11.18 12.304C11.861 11.557 12.402 10.653 12.801 9.547H14V8.5H11V9.547H11.765C11.447 10.391 11.025 11.093 10.493 11.677C10.3461 11.5205 10.2075 11.3562 10.078 11.185C9.79581 11.3659 9.47246 11.4726 9.138 11.495Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6582_57736">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
)
