import { Tooltip } from '@mui/material'
import { IconSearchLg, IconXCircle } from 'assets/icons'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'ui/inputs/icon-button'

export const Search = ({
  onClick,
  isOpen,
}: {
  onClick: () => void
  isOpen: boolean
}) => {
  const { t } = useTranslation()

  return (
    <Tooltip
      title={isOpen ? t('common.close') : t('common.search')}
      placement="bottom"
    >
      <IconButton onClick={onClick}>
        {isOpen ? (
          <IconXCircle
            sx={{
              fontSize: '20px',
              color: theme => theme.palette.mineShaft.main,
            }}
          />
        ) : (
          <IconSearchLg
            sx={{
              fontSize: '20px',
              color: theme => theme.palette.mineShaft.main,
            }}
          />
        )}
      </IconButton>
    </Tooltip>
  )
}
