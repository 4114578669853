import * as t from 'io-ts'

export const TSkillLevelCode = t.union([
  t.literal('GOOD'),
  t.literal('EXPERIENCED'),
  t.literal('VERY_EXPERIENCED'),
  t.literal('EXPERT'),
])

export type SkillLevelCode = t.TypeOf<typeof TSkillLevelCode>

export const TSkill = t.strict({
  skillId: t.string,
  skillLevelCode: TSkillLevelCode,
  skillName: t.string,
  order: t.number,
})
