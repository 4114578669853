import * as t from 'io-ts'
import { optionFromNullable } from 'io-ts-types'

import { TCompanyPosition } from '../company.codecs'

export const TCompanySkill = t.intersection([
  t.strict({
    skillId: t.string,
    group: optionFromNullable(t.string),
    name: t.string,
    active: t.boolean,
  }),
  t.partial({
    positions: t.array(TCompanyPosition),
  }),
])

export type CompanySkill = t.TypeOf<typeof TCompanySkill>

export const TCompanySkillGroups = t.array(t.string)
