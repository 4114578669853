import { sort } from 'fp-ts/Array'
import { pipe } from 'fp-ts/lib/function'
import { contramap } from 'fp-ts/lib/Ord'
import { Ord as NumberOrd } from 'fp-ts/number'
import { toArray } from 'fp-ts/Record'

/** Same as `toArray` fp-ts function, but sorts key/value pairs using `order` property of value, instead of default alphabetical order */
export const recordToArraySaveOrder = <
  K extends string,
  T extends { order: number },
>(
  record: Record<K, T>,
) => {
  return pipe(
    toArray(record),
    sort(
      pipe(
        NumberOrd,
        contramap(([_key, item]: [K, T]) => item.order),
      ),
    ),
  )
}
