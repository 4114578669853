import { Box, List, ListItem, Stack, Typography } from '@mui/material'
import { IconCheck, IconClose } from 'assets/icons'
import { getValidationErrors } from 'lib/form-utils'
import { DeepMap, FieldError, FieldValues } from 'react-hook-form'
import { TFuncKey, useTranslation } from 'react-i18next'

export const ValidationErrors = ({
  errors,
  translations,
  required,
}: {
  errors: DeepMap<FieldValues, FieldError>
  translations: Record<string, TFuncKey>
  /** Fields that are required by default and should be visible even in case they pass the validation */
  required?: Array<string>
}) => {
  const { t } = useTranslation()
  const validationErrors = getValidationErrors(errors, t)

  const allRequired = required
    ? [...new Set([...required, ...validationErrors.required])]
    : validationErrors.required

  return (
    <Stack spacing={1}>
      {validationErrors.required.length > 0 && (
        <Box>
          <Typography variant="body2" mb={1}>
            {t('validations.form_validation.fields_required')}
          </Typography>
          <List disablePadding>
            {allRequired.map(field => {
              const translation = translations[field]
              return (
                <ListItem key={field} sx={{ padding: '3px 0' }}>
                  <Box
                    width={20}
                    height={20}
                    mr={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {validationErrors.required.includes(field) ? (
                      <IconClose
                        sx={{
                          fontSize: '20px',
                          color: 'error.main',
                        }}
                      />
                    ) : (
                      <IconCheck
                        sx={{
                          fontSize: '12px',
                          color: 'primary.main',
                        }}
                      />
                    )}
                  </Box>
                  {translation ? t(translation).toString() : field}
                </ListItem>
              )
            })}
          </List>
        </Box>
      )}
      {validationErrors.patterns.length > 0 && (
        <Box>
          <Typography variant="body2" mb={1}>
            {t('validations.form_validation.validations_missing')}:
          </Typography>
          {validationErrors.patterns.map(pattern => {
            const translation = translations[pattern.field]
            return (
              <Typography key={pattern.field} variant="body2" sx={{ mb: 1 }}>
                <b>{translation ? t(translation).toString() : pattern.field}</b>
                <p dangerouslySetInnerHTML={{ __html: pattern.message }} />
              </Typography>
            )
          })}
        </Box>
      )}
    </Stack>
  )
}
