import { Box, styled } from '@mui/material'

export const AbsolutelyCentered = styled(Box)({
  position: 'absolute',
  top: '50%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  transform: 'translate(0, -50%)',
})
