import { RoleId } from 'api'
import { useAuthContext } from 'app/auth'
import { paths } from 'app/routes'
import { useCallback } from 'react'
import useLocalStorageState from 'use-local-storage-state'

/**
 * Custom hook to determine the index page based on the user's role and permissions.
 *
 * @returns {Object} - Object containing functions and data related to the index page.
 */

export const showLastPage = (role: RoleId) =>
  !!(role === 'HIRING_MANAGER' || role === 'INTERVIEWER')

export type RedirectLastPage = Record<string, string>
export const useIndexPage = () => {
  const { user } = useAuthContext()

  const defaultIndexPage = paths.contacts
  const [lastPage] = useLocalStorageState<RedirectLastPage>('lastPage', {
    defaultValue: {},
  })

  /**
   * Function to get the index page path based on the user's role.
   *
   * @param {RoleId} role - User's role.
   * @returns {string} - Path to the corresponding index page.
   */
  const getIndexPageByUserRole = useCallback(
    (role: RoleId, userId: string) => {
      if (role === 'OWNER') {
        return paths.teamsDashboard
      }

      if (role === 'RECRUITER') {
        return paths.recruiterDashboard
      }

      if (role === 'HR_MANAGER') {
        return paths.teamsDashboard
      }

      if (userId && showLastPage(role) && lastPage[userId]) {
        return lastPage[userId]!
      }

      return paths.contacts
    },
    [lastPage],
  )

  return {
    getIndexPageByUserRole,
    indexPage:
      user.state === 'authenticated'
        ? getIndexPageByUserRole(user.role, user.userId)
        : defaultIndexPage,
  }
}
