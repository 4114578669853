import { Cell, Pie, PieChart as Chart } from 'recharts'

type Props = {
  size: number
  innerRadius: number
  data: Array<{ value: number; color: string }>
  paddingAngle: number
  cornerRadius: number
  blendStroke?: boolean
}

export const PieChart = ({
  size,
  innerRadius,
  data,
  paddingAngle,
  cornerRadius,
  blendStroke,
}: Props) => {
  return (
    <Chart
      width={blendStroke ? size + 1 : size}
      height={blendStroke ? size + 1 : size}
    >
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        innerRadius={innerRadius}
        outerRadius={size / 2}
        paddingAngle={paddingAngle}
        cornerRadius={cornerRadius}
        dataKey="value"
        blendStroke={blendStroke}
      >
        {data.map((item, index) => (
          <Cell key={`cell-${index}`} fill={item.color} />
        ))}
      </Pie>
    </Chart>
  )
}
