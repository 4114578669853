import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

const TTimeSlot = t.strict({
  start: DateFromISOString,
  end: DateFromISOString,
})

export type TimeSlot = t.TypeOf<typeof TTimeSlot>

export const TCalendarFreeTimeSlots = t.strict({
  username: t.string,
  meetingTitle: t.string,
  freeTimeslots: t.array(TTimeSlot),
  meetingDurationMin: t.number,
})

export const TCreateCalendarEvent = t.strict({
  username: t.string,
  interviewEvent: t.strict({
    title: t.string,
    timeslot: TTimeSlot,
  }),
})
