import { TFunction } from 'i18next'
import { getRequestErrorMessage } from 'lib/request'
import { CopyText } from 'ui/utils'

import { showToast } from './show-toast'

/**
 * Generates a default error toast based on the provided error and translation function.
 *
 * @param {unknown} error - The error object.
 * @param {TFunction} t - The translation function from i18next.
 * @returns {void} - The toast message displayed.
 */
export const defaultErrorToast = (error: unknown, t: TFunction) => {
  // Retrieve title and message for the error
  const { title, message } = getRequestErrorMessage(error, t, 'mutation')

  // Display an error toast with the extracted title and message
  return showToast(
    {
      type: 'error',
      title,
      body:
        typeof message === 'string' ? (
          // If the message is a string, display it with a copyable text component
          <CopyText text={message} tooltipPlacement="top-end">
            {message}
          </CopyText>
        ) : (
          // If the message is not a string, display it as is
          message
        ),
    },
    // Disable auto-closing of the toast
    { autoClose: false },
  )
}
