import * as t from 'io-ts'

const TDictionary = t.strict({
  code: t.string,
  name: t.string,
})

const TDictionaryType = t.union([
  t.literal('QUALIFICATION_LEVEL'),
  t.literal('SKILL_LEVEL'),
  t.literal('LANGUAGE_LEVELS'),
  t.literal('JOB_STATUS'),
  t.literal('MESSENGER_TYPES'),
  t.literal('INDUSTRY'),
  t.literal('TEAM_MEMBERS'),
  t.literal('COMPANY_LANGUAGES'),
  t.literal('JOB_MODELS'),
  t.literal('COUNTRY'),
])

export type DictionaryType = t.TypeOf<typeof TDictionaryType>

export const TDictionariesRecord = t.record(
  TDictionaryType,
  t.array(TDictionary),
)
