import { Box, Typography } from '@mui/material'
import { Notification } from 'api'
import { previewCloseExceptionClass } from 'components/common/preview-drawer'
import { Link } from 'ui/navigation'

type Props = {
  notification: Notification
}

export const NotificationFeedToast = ({ notification }: Props) => {
  const content = (
    <Box sx={{ p: theme => theme.spacing(1.25, 2, 1.75, 2.5) }}>
      {notification.criticality === 'HIGH' && (
        <Box
          sx={{
            position: 'absolute',
            height: 'calc(100% - 4px)',
            bottom: '2px',
            left: '3px',
            width: '2px',
            bgcolor: 'error.main',
          }}
        />
      )}

      <Typography color="text.primary" mr={4} mb={0.75}>
        {notification.subject}
      </Typography>

      <Typography variant="caption" color="text.secondary">
        {notification.message}
      </Typography>
    </Box>
  )

  if (notification.onclick) {
    const { origin } = new URL(notification.onclick)
    const path = notification.onclick.replace(origin, '')

    return (
      <Link
        to={path}
        display="block"
        // Activity notification link leads to activities page
        // with corresponding activity preview opened.
        // But when it happens from activities page directly, the preview is closed immediately
        // That is why we need to add this class to prevent closing a preview
        className={previewCloseExceptionClass}
      >
        {content}
      </Link>
    )
  }

  return content
}
