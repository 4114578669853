import { SvgIconProps } from '@mui/material'
import { NotificationCriticality } from 'api'
import {
  IconPriorityHigh,
  IconPriorityLow,
  IconPriorityMedium,
} from 'assets/icons'

export const notificationCriticalities: Record<
  NotificationCriticality,
  { Icon: (props: SvgIconProps) => JSX.Element }
> = {
  LOW: {
    Icon: IconPriorityLow,
  },
  MEDIUM: {
    Icon: IconPriorityMedium,
  },
  HIGH: {
    Icon: IconPriorityHigh,
  },
}
