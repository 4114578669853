import './text-editor-styles.css'

import { Box } from '@mui/material'
import ReactQuill from 'react-quill'
import { FormControl, FormControlWrapper } from 'ui/inputs/common/form-control'

const TOOLBAR_OPTIONS = [
  [{ header: [1, 2, 3, 4, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ align: [] }],
  ['link'],
  ['clean'],
]

type Props = FormControlWrapper & {
  value: string
  readOnly?: boolean
  onChange?: (value: string) => void
  dataCy?: string
}

export const RichTextEditor = ({
  value,
  onChange,
  readOnly,
  dataCy,
  ...formControlProps
}: Props) => {
  return (
    <Box data-cy={dataCy}>
      <FormControl {...formControlProps}>
        <ReactQuill
          theme="snow"
          value={value}
          onChange={onChange}
          className={`${
            formControlProps.error ? 'error' : undefined
          } rich-text-editor`}
          style={{
            width: '100%',
            minHeight: readOnly ? 'auto' : '200px',
            display: 'flex',
            flexDirection: 'column',
          }}
          modules={{
            toolbar: readOnly ? false : TOOLBAR_OPTIONS,
            clipboard: {
              matchVisual: false,
            },
          }}
          readOnly={readOnly}
        />
      </FormControl>
    </Box>
  )
}
