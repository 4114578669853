import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconSave = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 25"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <g clipPath="url(#clip0_577_3297)">
      <path
        d="M14.8 8.76628H8.82664C8.30393 8.76628 8.04257 8.76628 7.84292 8.66455C7.6673 8.57507 7.52452 8.43229 7.43504 8.25667C7.33331 8.05702 7.33331 7.79566 7.33331 7.27294V4.09961M16.6666 20.8996V14.9263C16.6666 14.4036 16.6666 14.1422 16.5649 13.9426C16.4754 13.7669 16.3327 13.6242 16.157 13.5347C15.9574 13.4329 15.696 13.4329 15.1733 13.4329H8.82664C8.30393 13.4329 8.04257 13.4329 7.84292 13.5347C7.6673 13.6242 7.52452 13.7669 7.43504 13.9426C7.33331 14.1422 7.33331 14.4036 7.33331 14.9263V20.8996M20.4 10.0034V16.4196C20.4 17.9878 20.4 18.7718 20.0948 19.3708C19.8263 19.8976 19.398 20.326 18.8711 20.5944C18.2722 20.8996 17.4881 20.8996 15.92 20.8996H8.07998C6.51183 20.8996 5.72775 20.8996 5.1288 20.5944C4.60195 20.326 4.1736 19.8976 3.90516 19.3708C3.59998 18.7718 3.59998 17.9878 3.59998 16.4196V8.57961C3.59998 7.01146 3.59998 6.22739 3.90516 5.62844C4.1736 5.10158 4.60195 4.67324 5.1288 4.40479C5.72775 4.09961 6.51183 4.09961 8.07998 4.09961H14.4962C14.9528 4.09961 15.181 4.09961 15.3959 4.15119C15.5863 4.19691 15.7684 4.27233 15.9354 4.37468C16.1238 4.49012 16.2852 4.65154 16.6081 4.97438L19.5252 7.8915C19.848 8.21434 20.0095 8.37577 20.1249 8.56414C20.2272 8.73116 20.3027 8.91324 20.3484 9.10371C20.4 9.31854 20.4 9.54682 20.4 10.0034Z"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_577_3297">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
)
