import { Scope } from 'app/scopes'
import { ComponentType } from 'react'
import lazyWithPreload from 'react-lazy-with-preload'
import { IndexRouteObject, NonIndexRouteObject } from 'react-router-dom'

import { importWithRetry } from './import-with-retry'
import { ProtectedRoute } from './protected-route'

/** Extending RouteObject with the function to preload lazy component in advance */
export type Route =
  | (Omit<IndexRouteObject, 'children'> & {
      children?: undefined
      preload?: () => Promise<ComponentType<any>>
    })
  | (Omit<NonIndexRouteObject, 'children'> & {
      children?: Array<Route>
      preload?: () => Promise<ComponentType<any>>
    })

export const buildRoute = ({
  path,
  factory,
  children,
  scopes = [],
}: {
  path: string
  factory: () => Promise<{ default: ComponentType<any> }>
  children?: Array<Route>
  scopes?: Array<Scope>
}): Route => {
  const Component = lazyWithPreload(() => importWithRetry(factory))

  return {
    path,
    children,
    preload: Component.preload,
    element: (
      <ProtectedRoute scopes={scopes}>
        <Component />
      </ProtectedRoute>
    ),
  }
}
