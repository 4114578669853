import { pipe } from 'fp-ts/function'
import { toServerDate } from 'lib/js-utils'
import { decodeJson, get } from 'lib/request'

import {
  GetGenericReportInput,
  GetRecruiterReportInput,
  GetReportInput,
  TReportQualifierAmounts,
} from '../common'
import {
  TJobsAmountByStatusReport,
  TRecruiterJobPerformanceReport,
  TRecruiterJobsClosureInfos,
  TRecruiterJobsClosureReport,
} from './job-reports.codecs'

export const getJobsAmountByStatusReport = async ({
  from,
  to,
  recruiterId,
}: GetGenericReportInput) => {
  return pipe(
    await get(
      `api/reports/job-reports/${
        recruiterId ? 'recruiters/:recruiterId/' : ''
      }jobs-amount-by-status`,
      {
        params: recruiterId ? { recruiterId } : undefined,
        query: new URLSearchParams({
          from: toServerDate(from),
          to: toServerDate(to),
        }),
      },
    ),
    decodeJson(TJobsAmountByStatusReport),
  )
}

export const getJobsClosedByUserReport = async ({
  from,
  to,
}: GetReportInput) => {
  return pipe(
    await get('api/reports/job-reports/jobs-closed-by-user', {
      query: new URLSearchParams({
        from: toServerDate(from),
        to: toServerDate(to),
      }),
    }),
    decodeJson(TReportQualifierAmounts),
  )
}

export const getJobsByLevelAndClosureStatusReport = async ({
  from,
  to,
}: GetReportInput) => {
  return pipe(
    await get(
      'api/reports/job-reports/jobs-amount-by-qualification-level-and-job-closure-status',
      {
        query: new URLSearchParams({
          from: toServerDate(from),
          to: toServerDate(to),
        }),
      },
    ),
    decodeJson(TRecruiterJobsClosureInfos),
  )
}

export const getJobsAverageOverdueReport = async ({
  from,
  to,
}: GetReportInput) => {
  return pipe(
    await get('api/reports/job-reports/recruiters/job-average-overdue', {
      query: new URLSearchParams({
        from: toServerDate(from),
        to: toServerDate(to),
      }),
    }),
    decodeJson(TReportQualifierAmounts),
  )
}

export const getJobsClosedPerQuarterReport = async ({
  from,
  to,
}: GetReportInput) => {
  return pipe(
    await get('api/reports/job-reports/jobs-closed-per-quarter', {
      query: new URLSearchParams({
        from: toServerDate(from),
        to: toServerDate(to),
      }),
    }),
    decodeJson(TReportQualifierAmounts),
  )
}

export const getJobsClosedByLevelReport = async ({
  from,
  to,
}: GetReportInput) => {
  return pipe(
    await get('api/reports/job-reports/jobs-closed-by-qualification-level', {
      query: new URLSearchParams({
        from: toServerDate(from),
        to: toServerDate(to),
      }),
    }),
    decodeJson(TReportQualifierAmounts),
  )
}

export const getJobsClosedBySourceReport = async ({
  from,
  to,
}: GetReportInput) => {
  return pipe(
    await get('api/reports/job-reports/jobs-closed-by-source', {
      query: new URLSearchParams({
        from: toServerDate(from),
        to: toServerDate(to),
      }),
    }),
    decodeJson(TReportQualifierAmounts),
  )
}

export const getJobsClosedByDepartmentReport = async ({
  from,
  to,
}: GetReportInput) => {
  return pipe(
    await get('api/reports/job-reports/jobs-closed-by-department', {
      query: new URLSearchParams({
        from: toServerDate(from),
        to: toServerDate(to),
      }),
    }),
    decodeJson(TReportQualifierAmounts),
  )
}

export const getMyRecruiterJobsClosureReport = async ({
  from,
  to,
}: GetReportInput) => {
  return pipe(
    await get('api/reports/job-reports/recruiters/me/job-closure-report', {
      query: new URLSearchParams({
        from: toServerDate(from),
        to: toServerDate(to),
      }),
    }),
    decodeJson(TRecruiterJobsClosureReport),
  )
}

export const getRecruiterJobsClosureReport = async ({
  from,
  to,
  recruiterId,
}: GetRecruiterReportInput) => {
  return pipe(
    await get(
      'api/reports/job-reports/recruiters/:recruiterId/job-closure-report',
      {
        params: { recruiterId },
        query: new URLSearchParams({
          from: toServerDate(from),
          to: toServerDate(to),
        }),
      },
    ),
    decodeJson(TRecruiterJobsClosureInfos),
  )
}

export const getRecruiterJobPerformanceReport = async ({
  from,
  to,
  recruiterId,
}: GetRecruiterReportInput) => {
  return pipe(
    await get(
      'api/reports/job-reports/recruiters/:recruiterId/job-performance',
      {
        params: { recruiterId },
        query: new URLSearchParams({
          from: toServerDate(from),
          to: toServerDate(to),
        }),
      },
    ),
    decodeJson(TRecruiterJobPerformanceReport),
  )
}
