import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCallOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 15 15">
    <g clipPath="url(#clip0_4991_11820)">
      <path
        d="M10.125 1.375C10.3571 1.375 10.5796 1.46719 10.7437 1.63128C10.9078 1.79538 11 2.01794 11 2.25V12.75C11 12.9821 10.9078 13.2046 10.7437 13.3687C10.5796 13.5328 10.3571 13.625 10.125 13.625H4.875C4.64294 13.625 4.42038 13.5328 4.25628 13.3687C4.09219 13.2046 4 12.9821 4 12.75V2.25C4 2.01794 4.09219 1.79538 4.25628 1.63128C4.42038 1.46719 4.64294 1.375 4.875 1.375H10.125ZM4.875 0.5C4.41087 0.5 3.96575 0.684374 3.63756 1.01256C3.30937 1.34075 3.125 1.78587 3.125 2.25V12.75C3.125 13.2141 3.30937 13.6592 3.63756 13.9874C3.96575 14.3156 4.41087 14.5 4.875 14.5H10.125C10.5891 14.5 11.0342 14.3156 11.3624 13.9874C11.6906 13.6592 11.875 13.2141 11.875 12.75V2.25C11.875 1.78587 11.6906 1.34075 11.3624 1.01256C11.0342 0.684374 10.5891 0.5 10.125 0.5H4.875Z"
        fill="currentColor"
      />
      <path
        d="M7.5 12.75C7.73206 12.75 7.95462 12.6578 8.11872 12.4937C8.28281 12.3296 8.375 12.1071 8.375 11.875C8.375 11.6429 8.28281 11.4204 8.11872 11.2563C7.95462 11.0922 7.73206 11 7.5 11C7.26794 11 7.04538 11.0922 6.88128 11.2563C6.71719 11.4204 6.625 11.6429 6.625 11.875C6.625 12.1071 6.71719 12.3296 6.88128 12.4937C7.04538 12.6578 7.26794 12.75 7.5 12.75Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4991_11820">
        <rect
          width="14"
          height="14"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
)
