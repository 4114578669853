import { Box, SvgIconProps, Tooltip, useTheme } from '@mui/material'
import { Button } from 'ui/inputs/button'

type Props = Readonly<{
  title: string
  onClick: () => void
  iconComponent: React.ElementType<SvgIconProps>
  disabled?: boolean
}>

export const FiltersIconButton = ({
  title,
  onClick,
  iconComponent: IconComponent,
  disabled = false,
}: Props) => {
  const theme = useTheme()

  return (
    <Tooltip title={title} arrow placement="top">
      <Box flex="none">
        <Button
          icon
          variant="outlined"
          color="greyBlue"
          onClick={onClick}
          disabled={disabled}
          size="large"
          sx={{
            background: theme.palette.mineShaft[100],
            px: '24px',
            border: 'none',
            color: theme.palette.mineShaft.main,
            width: '74px',
            boxSizing: 'border-box',

            '&:hover': {
              border: 'none',
            },

            '&:disabled': {
              background: theme.palette.mineShaft.contrastText,
            },
          }}
        >
          <IconComponent sx={{ fontSize: '24px' }} />
        </Button>
      </Box>
    </Tooltip>
  )
}
