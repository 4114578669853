import { pipe } from 'fp-ts/function'
import { decodeJson, get, put } from 'lib/request'

import { TCompanySources } from './sources.codecs'

export const getCompanySources = async () => {
  return pipe(
    await get('api/companies/me/sources', {}),
    decodeJson(TCompanySources),
  )
}

export type UpdatedCompanySources = Array<{
  id?: string
  name: string
}>

export const updateCompanySources = async (input: {
  sources: UpdatedCompanySources
}) => {
  return pipe(
    await put('api/companies/me/sources', {
      body: { sources: input.sources },
    }),
    decodeJson(TCompanySources),
  )
}
