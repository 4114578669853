import { formatISO, isValid, parseISO } from 'date-fns'
import {
  ArrayParam as ArrayParamConfig,
  BooleanParam as BooleanParamConfig,
  DateParam as DateParamConfig,
  NumberParam as NumberParamConfig,
  QueryParamConfig,
  StringParam as StringParamConfig,
  withDefault,
} from 'use-query-params'

// https://github.com/pbeshai/use-query-params/issues/165
// Instead of complicating things with null and undefined to handle empty values,
// We always use `withDefault` utility to keep the type of state consistent.
// An empty value for string is an empty string '', number - 0, boolean - false, array - []
// The only exception is Date - it's empty value is null

/**
 * Custom query parameter configuration for string type with a default value of an empty string ('').
 */
export const StringParam = withDefault(
  StringParamConfig,
  '',
) as QueryParamConfig<string>

/**
 * Custom query parameter configuration for number type with a default value of 0.
 */
export const NumberParam = withDefault(
  NumberParamConfig,
  0,
) as QueryParamConfig<number>

/**
 * Custom query parameter configuration for boolean type with a default value of false.
 */
export const BooleanParam = withDefault(
  BooleanParamConfig,
  false,
) as QueryParamConfig<boolean>

/**
 * Custom query parameter configuration for array of strings with a default value of an empty array ([]).
 */
export const ArrayParam = withDefault(ArrayParamConfig, []) as QueryParamConfig<
  Array<string>
>

/**
 * Custom query parameter configuration for Date type with a default value of null.
 * Empty value for Date is null.
 */
export const DateParam = withDefault(
  DateParamConfig,
  null,
) as QueryParamConfig<Date | null>

/**
 * Custom query parameter configuration for Date type with ISO format encoding and decoding,
 * and a default value of null.
 * Empty value for Date is null.
 */
export const DateParamISO: QueryParamConfig<Date | null> = {
  encode: value => {
    return value && isValid(value) ? formatISO(value) : ''
  },
  decode: value => {
    const dateString = Array.isArray(value) ? value[0] : value
    if (!dateString) return null
    const date = parseISO(dateString)
    return isValid(date) ? date : null
  },
  default: null,
}
