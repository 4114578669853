import { Box, Link, Paper, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { deleteGlobalSearchHistory } from 'api'
import { IconClock } from 'assets/icons'
import { checkScrollToBottom } from 'lib/js-utils'
import { useTranslation } from 'react-i18next'

type Props = Readonly<{
  history: Array<string>
  onSelectQuery: (query: string) => void
  onScrollToBottom: () => void
  onClear: () => void
}>

export const GlobalSearchHistory = ({
  history,
  onSelectQuery,
  onScrollToBottom,
  onClear,
}: Props) => {
  const { t } = useTranslation()

  const $deleteHistory = useMutation(deleteGlobalSearchHistory)

  return (
    <Paper
      sx={{
        borderColor: 'primary.main',
        borderRadius: '5px',
        pb: 1.5,
        maxHeight: 500,
        overflow: 'auto',
      }}
      onScroll={event => {
        checkScrollToBottom(event, onScrollToBottom)
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={1.5}
      >
        <Typography variant="body2" color="text.secondary" fontWeight={500}>
          {t('common.recent')}
        </Typography>

        <Link
          component="button"
          color="blue.main"
          variant="body2"
          underline="none"
          disabled={$deleteHistory.isLoading}
          onClick={() => {
            $deleteHistory.mutate(undefined, {
              onSuccess: onClear,
            })
          }}
        >
          {t('common.clear')}
        </Link>
      </Box>

      <Box component="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
        {history.map((query, index) => (
          <Box
            component="li"
            key={index}
            sx={{
              display: 'flex',
              py: '10px',
              cursor: 'pointer',
              px: 1.5,
              '&:hover': {
                bgcolor: '#E6F4F1',
              },
            }}
            onClick={() => onSelectQuery(query)}
          >
            <IconClock sx={{ fontSize: '20px', color: 'greyBlue.light' }} />
            <Typography variant="body2" ml={1.75} pt={0.15}>
              {query}
            </Typography>
          </Box>
        ))}
      </Box>
    </Paper>
  )
}
