import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCircleOutlineDashed = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g clipPath="url(#clip0_6577_56559)">
      <path
        d="M12.7725 1.5285C12.5154 1.50952 12.2578 1.50001 12 1.5V0C12.2948 0.000143741 12.5895 0.0111512 12.8835 0.033L12.7725 1.5285ZM15.7785 2.2035C15.2977 2.01796 14.8038 1.86805 14.301 1.755L14.6295 0.291C15.204 0.42 15.7695 0.591 16.3185 0.804L15.7785 2.2035ZM17.8335 3.2685C17.619 3.12547 17.3994 2.99038 17.175 2.8635L17.9145 1.5585C18.4274 1.8491 18.9182 2.17699 19.383 2.5395L18.4605 3.723C18.2571 3.56435 18.048 3.41324 17.8335 3.27V3.2685ZM20.5845 5.9535C20.2878 5.53196 19.9604 5.13288 19.605 4.7595L20.691 3.7245C21.096 4.152 21.471 4.6095 21.8115 5.0895L20.5845 5.9535ZM21.7005 7.9815C21.602 7.74369 21.495 7.50953 21.3795 7.2795L22.719 6.6045C22.9839 7.13113 23.2095 7.67661 23.394 8.2365L21.969 8.706C21.8884 8.4613 21.7988 8.21964 21.7005 7.9815ZM22.4955 11.742C22.4834 11.2265 22.4332 10.7126 22.3455 10.2045L23.823 9.9495C23.9235 10.5285 23.982 11.1165 23.997 11.7045L22.497 11.742H22.4955ZM22.299 14.049C22.3485 13.794 22.389 13.5405 22.4205 13.284L23.91 13.4685C23.838 14.0537 23.7226 14.6328 23.565 15.201L22.119 14.8005C22.188 14.553 22.248 14.3025 22.299 14.049ZM20.871 17.6175C21.147 17.1825 21.39 16.7265 21.6 16.2555L22.971 16.863C22.731 17.403 22.4535 17.922 22.1385 18.42L20.871 17.6175ZM19.425 19.425C19.608 19.242 19.7835 19.053 19.95 18.858L21.087 19.8375C20.8945 20.0608 20.6938 20.2769 20.4855 20.4855L19.425 19.425Z"
        fill="currentColor"
      />
      <path
        d="M12 1.5C10.2733 1.50012 8.5733 1.92607 7.05055 2.74011C5.5278 3.55415 4.2293 4.73115 3.27007 6.16687C2.31084 7.6026 1.72048 9.25272 1.55128 10.9711C1.38208 12.6895 1.63926 14.423 2.30005 16.0183C2.96084 17.6135 4.00484 19.0212 5.33957 20.1166C6.67431 21.212 8.25859 21.9613 9.95209 22.2982C11.6456 22.6351 13.396 22.5492 15.0484 22.048C16.7007 21.5468 18.204 20.6459 19.425 19.425L20.4855 20.4855C19.0901 21.8817 17.3719 22.9121 15.483 23.4855C13.5942 24.0589 11.5931 24.1575 9.65708 23.7727C7.72102 23.3879 5.90977 22.5315 4.38381 21.2793C2.85785 20.0272 1.66429 18.418 0.908888 16.5943C0.153486 14.7707 -0.140435 12.7888 0.0531671 10.8244C0.246769 8.85999 0.921915 6.97365 2.01879 5.33253C3.11566 3.6914 4.60039 2.34617 6.34143 1.416C8.08246 0.485843 10.0261 -0.000523098 12 4.22187e-07V1.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6577_56559">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
