import { Box, List, ListItem, Stack } from '@mui/material'
import { getContactComments, qk } from 'api'
import { DetailsItemContainer } from 'components/templates/details-template'
import { useInfinitePaginatedQuery } from 'lib/react-query-utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AddCommentForm } from './add-comment-form'
import { Comment } from './comment'
// import { CommentsSort } from './comments-sort'

type Props = {
  contactId: string
}

export const ContactCommentsSection = ({ contactId }: Props) => {
  const [order] = useState<'desc' | 'asc'>('desc')
  const { t } = useTranslation()

  const $comments = useInfinitePaginatedQuery(
    qk.contacts.comments,
    getContactComments,
    {
      pagination: { page: 0, pageSize: 5 },
      order: {
        column: 'percentage',
        direction: order,
      },
      profileId: contactId,
    },
  )

  const comments = $comments.data?.pages.flatMap(page => page.rows)

  return (
    <DetailsItemContainer title={t('common.comments')}>
      <Box borderRadius="5px" border={1} borderColor="divider">
        {comments && (
          <Box sx={{ position: 'relative' }}>
            {/* <Box
            sx={{
              position: 'absolute',
              right: 0,
              zIndex: 1,
            }}
          >
            <CommentsSort
              order={order}
              onSort={() => {
                setOrder(prev => (prev === 'desc' ? 'asc' : 'desc'))
              }}
            />
          </Box> */}

            <Stack
              component={List}
              disablePadding
              sx={{
                maxHeight: 200,
                overflow: 'auto',
              }}
            >
              {comments.map((comment, index) => {
                return (
                  <ListItem key={comment.id} disablePadding>
                    <Comment
                      comment={comment}
                      isLast={comments.length === index + 1}
                      refetch={$comments.refetch}
                      onLastVisible={
                        $comments.hasNextPage
                          ? $comments.fetchNextPage
                          : undefined
                      }
                    />
                  </ListItem>
                )
              })}
            </Stack>
          </Box>
        )}
        <Box>
          <AddCommentForm contactId={contactId} refetch={$comments.refetch} />
        </Box>
      </Box>
    </DetailsItemContainer>
  )
}
