import { Stack } from '@mui/material'
import {
  PipelineTemplateForm,
  PipelineTemplatePaper,
} from 'components/settings'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'

import { JobPipelineTemplateDraft } from '../manual-settings-setup'

type Props = {
  template: JobPipelineTemplateDraft
  onClose: () => void
  onUpdate: (updatedTemplate: JobPipelineTemplateDraft) => void
}

export const EditPipelineTemplate = ({
  template,
  onClose,
  onUpdate,
}: Props) => {
  const { t } = useTranslation()
  const FORM_ID = 'edit-pipeline-template-form'

  return (
    <PipelineTemplatePaper
      name={template.name}
      isDefault={template.defaultPipeline}
    >
      <PipelineTemplateForm
        formId={FORM_ID}
        initialValues={{
          templateName: template.name,
          templateStages: template.stages.sort(
            (stageA, stageB) => stageA.order - stageB.order,
          ),
          default: template.defaultPipeline,
        }}
        onSubmit={values => {
          onUpdate({
            isNewTemplate: template.isNewTemplate,
            pipelineId: template.pipelineId,
            name: values.templateName,
            stages: values.templateStages.map((stage, index) => ({
              name: stage.name,
              order: index + 1,
              canHire: stage.canHire,
            })),
            defaultPipeline: values.default,
          })
          onClose()
        }}
      />

      <Stack direction="row" spacing={2} mt={3}>
        <Button variant="outlined" color="greyBlue" onClick={onClose}>
          {t('common.cancel')}
        </Button>

        <Button type="submit" form={FORM_ID}>
          {t('common.save')}
        </Button>
      </Stack>
    </PipelineTemplatePaper>
  )
}
