import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconLogo = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 35 32"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8877 3.10795L34.1939 22.8506C35.8802 25.7952 34.8349 29.5349 31.8591 31.2035C30.9279 31.7256 29.876 32 28.8057 32H6.19318C2.77278 32 0 29.2563 0 25.8719C0 24.8129 0.27734 23.772 0.804975 22.8506L12.1113 3.10795C13.7976 0.163387 17.577 -0.870996 20.5528 0.797589C21.528 1.34443 22.335 2.14293 22.8877 3.10795Z"
      fill="#332B24"
    />
    <mask
      id="mask0_1844_14396"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="35"
      height="32"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8877 3.10795L34.1939 22.8506C35.8802 25.7952 34.8349 29.5349 31.8591 31.2035C30.9279 31.7256 29.876 32 28.8057 32H6.19318C2.77278 32 0 29.2563 0 25.8719C0 24.8129 0.27734 23.772 0.804975 22.8506L12.1113 3.10795C13.7976 0.163387 17.577 -0.870996 20.5528 0.797589C21.528 1.34443 22.335 2.14293 22.8877 3.10795Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1844_14396)">
      <g filter="url(#filter0_d_1844_14396)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9713 1.57983C11.9205 3.65854 11.9809 6.11778 13.1325 8.14354L21.629 23.0899C23.3059 26.0397 22.2486 29.7761 19.2675 31.4354C18.3405 31.9513 17.2948 32.2224 16.2312 32.2224H-4.71484L12.9713 1.57983Z"
          fill="url(#paint0_linear_1844_14396)"
        />
      </g>
      <g filter="url(#filter1_d_1844_14396)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.20918 11.3977C6.24738 13.1811 6.27144 15.3268 7.273 17.0886L10.7817 23.2607C12.4343 26.1678 11.3923 29.8501 8.4543 31.4854C7.54072 31.9939 6.51019 32.261 5.462 32.261H-4.89014L7.20918 11.3977Z"
          fill="url(#paint1_linear_1844_14396)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_1844_14396"
        x="-10.7148"
        y="-4.42017"
        width="47.1404"
        height="50.6426"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4" dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.52992 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1844_14396"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1844_14396"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_1844_14396"
        x="-10.8901"
        y="5.39771"
        width="36.4568"
        height="40.8633"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4" dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.52992 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1844_14396"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1844_14396"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1844_14396"
        x1="-4.93584"
        y1="1.35795"
        x2="1.36423"
        y2="51.7773"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E6E6E" />
        <stop offset="1" stopColor="#403B36" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1844_14396"
        x1="-5.02414"
        y1="11.2466"
        x2="-0.226853"
        y2="45.438"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E6E6E" />
        <stop offset="1" stopColor="#403B36" />
      </linearGradient>
    </defs>
  </SvgIcon>
)
