import { Box } from '@mui/material'
import notFoundImage from 'assets/images/404.png'
import { NoDataTemplate } from 'components/templates'
import { useIndexPage } from 'lib/app-helpers'
import { useTranslation } from 'react-i18next'
import { LinkButton } from 'ui/navigation/link'

export const NotFound = () => {
  const { t } = useTranslation()
  const { indexPage } = useIndexPage()

  return (
    <Box py={9} px={3}>
      <NoDataTemplate
        imgSrc={notFoundImage}
        imgWidth={340}
        title={t('common.not_found_page.title')}
        subtitle={t('common.not_found_page.subtitle')}
        button={
          <LinkButton to={indexPage}>{t('common.back_to_home')}</LinkButton>
        }
      />
    </Box>
  )
}
