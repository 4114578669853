import {
  alpha,
  Box,
  BoxProps,
  ClickAwayListener,
  Grow,
  MenuList,
  Popper,
  PopperPlacementType,
  useTheme,
} from '@mui/material'
import { useEffect, useRef } from 'react'

type Props = {
  isOpen: boolean
  onClose: () => void
  anchorEl: HTMLElement | null
  children: React.ReactNode

  placement?: PopperPlacementType
  keepMounted?: boolean
  minWidth?: number
  disablePortal?: boolean
  ContainerProps?: BoxProps
}

// The Menu component uses the Popover component internally.
// However, we want to use a different positioning strategy to save scrolling.
// For answering those needs, we expose a MenuList component composed with Popper.
// The primary responsibility of the MenuList component is to handle the focus.

export const Menu = ({
  isOpen,
  onClose,
  anchorEl,
  placement,
  children,
  keepMounted,
  minWidth,
  disablePortal = true,
  ContainerProps,
}: Props) => {
  const theme = useTheme()

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      onClose()
    } else if (event.key === 'Escape') {
      onClose()
    }
  }

  // return focus to the button when we transitioned from closed to open
  const prevOpen = useRef(isOpen)
  useEffect(() => {
    if (prevOpen.current === true && !isOpen && anchorEl) {
      anchorEl.focus()
    }

    prevOpen.current = isOpen
  }, [isOpen, anchorEl])

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorEl}
      placement={placement}
      transition
      disablePortal={disablePortal}
      keepMounted={keepMounted}
      style={{
        zIndex: theme.zIndex.modal,
      }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Box
            {...ContainerProps}
            sx={{
              minWidth,
              overflow: 'hidden',
              mx: 0.25,
              mt: 1,
              bgcolor: '#FFFFFF',
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: '5px',
              boxShadow: `0px 1px 1px ${alpha(
                '#000000',
                0.05,
              )}, 0px 1px 4px ${alpha('#322D51', 0.16)}`,
              ...ContainerProps?.sx,
            }}
          >
            <ClickAwayListener onClickAway={onClose}>
              <MenuList onKeyDown={handleListKeyDown} sx={{ p: 0 }}>
                {children}
              </MenuList>
            </ClickAwayListener>
          </Box>
        </Grow>
      )}
    </Popper>
  )
}
