import * as Arr from 'fp-ts/Array'
import { pipe } from 'fp-ts/lib/function'
import { Eq as EqString } from 'fp-ts/string'

export const splitCommaSeparatedString = (text: string): Array<string> => {
  if (!text) {
    return []
  }

  return pipe(
    text.split(','),
    Arr.map(value => value.trim()),
    Arr.filter(value => value !== ''),
    Arr.uniq(EqString),
  )
}
