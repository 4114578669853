import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconTreeDiagram = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      d="M17.4375 10.8125H16.3125V8C16.3125 7.6895 16.0605 7.4375 15.75 7.4375H9.5625V5.1875H10.6875C10.998 5.1875 11.25 4.9355 11.25 4.625V1.25C11.25 0.9395 10.998 0.6875 10.6875 0.6875H7.3125C7.002 0.6875 6.75 0.9395 6.75 1.25V4.625C6.75 4.9355 7.002 5.1875 7.3125 5.1875H8.4375V7.4375H2.25C1.9395 7.4375 1.6875 7.6895 1.6875 8V10.8125H0.5625C0.252 10.8125 0 11.0645 0 11.375V14.75C0 15.0605 0.252 15.3125 0.5625 15.3125H3.9375C4.248 15.3125 4.5 15.0605 4.5 14.75V11.375C4.5 11.0645 4.248 10.8125 3.9375 10.8125H2.8125V8.5625H8.4375V10.8125H7.3125C7.002 10.8125 6.75 11.0645 6.75 11.375V14.75C6.75 15.0605 7.002 15.3125 7.3125 15.3125H10.6875C10.998 15.3125 11.25 15.0605 11.25 14.75V11.375C11.25 11.0645 10.998 10.8125 10.6875 10.8125H9.5625V8.5625H15.1875V10.8125H14.0625C13.752 10.8125 13.5 11.0645 13.5 11.375V14.75C13.5 15.0605 13.752 15.3125 14.0625 15.3125H17.4375C17.748 15.3125 18 15.0605 18 14.75V11.375C18 11.0645 17.748 10.8125 17.4375 10.8125Z"
      fill="#4D5678"
    />
  </SvgIcon>
)
