import { TLanguage, TQualificationLevelCode, TSkill } from 'api/common'
import {
  TCompanyDepartment,
  TCompanyJobType,
  TCompanyOffice,
  TCompanyPosition,
  TCompanyProject,
  TCompanyWorkExperience,
} from 'api/company'
import { TJobStatusCode } from 'api/jobs'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export const TJobRequestStatus = t.union([
  t.literal('DRAFT'),
  t.literal('PENDING_APPROVAL'),
  t.literal('PENDING_RECRUITER'),
  t.literal('TO_AMEND'),
  t.literal('REJECTED'),
  t.literal('DELETED'),
  t.literal('APPROVED'),
  t.literal('JOB_OPEN'),
  t.literal('JOB_CLOSED'),
  t.literal('JOB_CREATED'),
])

export type JobRequestStatus = t.TypeOf<typeof TJobRequestStatus>

export const TJobRequestProfile = t.intersection([
  t.strict({
    profileId: t.string,
    fullName: t.string,
  }),
  t.partial({
    avatar: t.string,
  }),
])

export const TStatusLogs = t.array(
  t.intersection([
    t.strict({
      statusCode: TJobRequestStatus,
      time: DateFromISOString,
    }),
    t.partial({ changedBy: TJobRequestProfile }),
  ]),
)

export type StatusLogs = t.TypeOf<typeof TStatusLogs>

export const TApprover = t.strict({
  approver: TJobRequestProfile,
  approved: t.boolean,
})

export const TJobRequest = t.intersection([
  t.strict({
    jobRequestId: t.string,
    position: TCompanyPosition,
    office: TCompanyOffice,
    status: TJobRequestStatus,
    targetDate: DateFromISOString,
    jobDescription: t.string,
    createdAt: DateFromISOString,
    createdBy: TJobRequestProfile,
    approvers: t.array(TApprover),
    qualificationLevel: t.strict({
      code: TQualificationLevelCode,
      name: t.string,
    }),
    jobType: TCompanyJobType,
    statusLogs: TStatusLogs,
    department: TCompanyDepartment,
    model: t.strict({
      code: t.string,
      name: t.string,
    }),
  }),
  t.partial({
    project: TCompanyProject,
    workExperience: TCompanyWorkExperience,
    education: t.string,
    reasonForHiring: t.string,
    minSalary: t.number,
    maxSalary: t.number,
    address: t.partial({
      country: t.strict({ code: t.string }),
      city: t.string,
    }),
    skills: t.array(TSkill),
    languages: t.array(TLanguage),
    recruiter: TJobRequestProfile,
    statusDetails: t.string,
  }),
])

export type JobRequest = t.TypeOf<typeof TJobRequest>

export const TJobRequestRow = t.intersection([
  t.strict({
    jobRequestId: t.string,
    position: TCompanyPosition,
    targetDate: DateFromISOString,
    office: TCompanyOffice,
    createdBy: TJobRequestProfile,
    status: TJobRequestStatus,
    createdAt: DateFromISOString,
    department: TCompanyDepartment,
  }),
  t.partial({
    statusDetails: t.string,
    project: TCompanyProject,
    address: t.partial({
      country: t.strict({ code: t.string }),
      city: t.string,
    }),
  }),
])

export const TJobsCreatedFromJobRequest = t.array(
  t.strict({
    jobId: t.string,
    status: TJobStatusCode,
    position: TCompanyPosition,
    department: TCompanyDepartment,
  }),
)

export type JobsCreatedFromJobRequest = t.TypeOf<
  typeof TJobsCreatedFromJobRequest
>
