import {
  Breadcrumbs as MuiBreadcrumbs,
  breadcrumbsClasses,
} from '@mui/material'
import { IconChevronRight } from 'assets/icons'
import { Link } from 'ui/navigation/link'
import { TruncateWithTooltip } from 'ui/utils'

export type BreadcrumbsLocations = Array<{
  name: string
  to?: string
}>

type Props = {
  locations: BreadcrumbsLocations
}

export const Breadcrumbs = (props: Props) => {
  return (
    <MuiBreadcrumbs
      sx={{
        [`& .${breadcrumbsClasses.li}`]: {
          lineHeight: 1,
        },

        [`& .${breadcrumbsClasses.separator}`]: {
          mx: '2px',
        },
      }}
      separator={
        <IconChevronRight sx={{ fontSize: '8px', color: 'greyBlue.light' }} />
      }
    >
      {props.locations.map(location =>
        location.to ? (
          <Link
            key={location.name}
            to={location.to}
            typography="caption"
            color="text.secondary"
          >
            <TruncateWithTooltip variant="inherit" maxWidth="250px">
              {location.name}
            </TruncateWithTooltip>
          </Link>
        ) : (
          <TruncateWithTooltip
            key={location.name}
            variant="caption"
            color="greyBlue.light"
            maxWidth="250px"
          >
            {location.name}
          </TruncateWithTooltip>
        ),
      )}
    </MuiBreadcrumbs>
  )
}
