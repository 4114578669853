import {
  InputBase as MuiInputBase,
  InputBaseProps as MuiInputBaseProps,
  useTheme,
} from '@mui/material'
import { forwardRef } from 'react'

export type InputBaseProps = MuiInputBaseProps & {
  small?: boolean
  resizable?: boolean
  fullWidth?: boolean
}

export const InputBase = forwardRef<HTMLInputElement, InputBaseProps>(
  (
    {
      small,
      resizable = true,
      fullWidth = true,
      sx,

      ...inputBaseProps
    },
    ref,
  ) => {
    const theme = useTheme()

    return (
      <MuiInputBase
        ref={ref}
        sx={{
          ...(fullWidth && { width: '100%' }),
          '&.MuiInputBase-multiline': {
            '& .MuiInputBase-input': {
              ...(resizable && {
                resize: 'vertical',
                minHeight: theme.spacing(3),
                maxHeight: theme.spacing(50),
              }),
            },
          },
          ...(small && {
            height: theme.spacing(3),
            fontSize: theme.typography.pxToRem(14),
          }),
          ...sx,
        }}
        {...inputBaseProps}
      />
    )
  },
)
