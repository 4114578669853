import { TProfiles } from 'api/common'
import {
  createListSearchParams,
  ListOrder,
  ListPagination,
} from 'api/list-params'
import { pipe } from 'fp-ts/function'
import {
  decodeJson,
  decodeJsonWithTotal,
  del,
  get,
  patch,
  post,
} from 'lib/request'

import {
  PoolPermissionType,
  TContactsTalentsPoolDetails,
  TContactsTalentsPoolRow,
  TTalentsPool,
  TTalentsPoolCreated,
} from './talent-pools.codecs'

export type TalentPoolId = {
  talentPoolId: string
}

const TALENT_POOL_PATH = 'api/talent-pools/:talentPoolId'

export type GetContactsTalentsPoolsList = {
  pagination: ListPagination
  hasContacts?: boolean
  excludeCandidateProfileId?: string
  sort?: string
  order?: ListOrder
}

export const getContactsTalentsPoolsList = async ({
  pagination,
  hasContacts,
  excludeCandidateProfileId,
  sort,
  order,
}: GetContactsTalentsPoolsList) => {
  const params = createListSearchParams({ pagination, order })

  if (hasContacts !== undefined) {
    params.set('hasContacts', hasContacts.toString())
  }

  if (sort !== undefined) {
    params.set('sort', sort)
  }

  if (excludeCandidateProfileId) {
    params.set('excludeCandidateProfileId', excludeCandidateProfileId)
  }

  return pipe(
    await get('api/talent-pools', { query: params }),
    decodeJsonWithTotal(TContactsTalentsPoolRow, pagination),
  )
}

export const createContactsTalentsPool = async ({
  name,
  contactsIds,
}: {
  name: string
  contactsIds: Array<string>
}) => {
  return pipe(
    await post('api/talent-pools', {
      body: {
        name,
        contacts: contactsIds,
      },
    }),
    decodeJson(TTalentsPoolCreated),
  )
}

export const editTalentsPoolName = async ({
  talentPoolId,
  name,
}: TalentPoolId & { name: string }) => {
  return await patch(TALENT_POOL_PATH + '/name', {
    params: { talentPoolId },
    body: { name },
  })
}

export const editTalentsPoolOwner = async ({
  talentPoolId,
  newOwnerId,
}: TalentPoolId & { newOwnerId: string }) => {
  return await patch(TALENT_POOL_PATH + '/owner', {
    params: { talentPoolId },
    body: { newOwnerId },
  })
}

export const deleteContactsTalentsPool = async ({
  talentPoolId,
}: TalentPoolId) => {
  return await del(TALENT_POOL_PATH, {
    params: { talentPoolId },
  })
}

export const addContactsToTalentsPool = async ({
  talentPoolId,
  contactsIds,
}: TalentPoolId & { contactsIds: Array<string> }) => {
  return pipe(
    await post(`${TALENT_POOL_PATH}/contacts`, {
      params: { talentPoolId },
      body: {
        contacts: contactsIds,
      },
    }),
    decodeJson(TTalentsPool),
  )
}

export type GetContactsTalentsPoolDetailsInput = TalentPoolId

export const getContactsTalentsPoolDetails = async ({
  talentPoolId,
}: GetContactsTalentsPoolDetailsInput) => {
  return pipe(
    await get(TALENT_POOL_PATH, {
      params: { talentPoolId },
    }),
    decodeJson(TContactsTalentsPoolDetails),
  )
}

export const shareContactsTalentsPool = async ({
  talentPoolId,
  sharedWith,
  globalRole,
}: TalentPoolId & {
  sharedWith: Record<string, PoolPermissionType>
  globalRole: PoolPermissionType | null
}) => {
  return await patch('api/talent-pools/:talentPoolId/permissions', {
    params: { talentPoolId },
    body: {
      sharedWith,
      globalRole,
    },
  })
}

export const getTalentsPoolSharedUsers = async ({
  talentPoolId,
}: TalentPoolId) => {
  return pipe(
    await get(`${TALENT_POOL_PATH}/share/users`, {
      params: { talentPoolId },
    }),
    decodeJson(TProfiles),
  )
}

export const deleteContactsFromTalentsPool = async ({
  talentPoolId,
  contacts,
}: TalentPoolId & { contacts: Array<string> }) => {
  return await del(`${TALENT_POOL_PATH}/contacts`, {
    params: { talentPoolId },
    body: { contacts },
  })
}
