import * as Sentry from '@sentry/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { logout } from 'api'
import { useAuthContext } from 'app/auth'
import { paths } from 'app/routes'
import {
  RedirectLastPage,
  showLastPage,
  useNotificationsFeed,
} from 'lib/app-helpers'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button } from 'ui/inputs/button'
import useLocalStorageState from 'use-local-storage-state'

export type EditFormType = 'general' | 'details'

export const LogoutButton = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { unauthorize, user } = useAuthContext()
  const userId = user.state === 'authenticated' && user.userId
  const userRole = user.state === 'authenticated' && user.role
  const saveLastPage =
    userRole && showLastPage(userRole) && typeof userId === 'string'

  const { clearNotificationsFeed } = useNotificationsFeed()

  const $logout = useMutation(logout)
  const [, setLastPage] = useLocalStorageState('lastPage')

  return (
    <Button
      variant="outlined"
      color="greyBlue"
      onClick={() =>
        $logout.mutate(undefined, {
          onSuccess: () => {
            saveLastPage &&
              setLastPage((prev: RedirectLastPage) => ({
                ...prev,
                [userId]: location.pathname,
              }))
            unauthorize()
            queryClient.removeQueries()
            clearNotificationsFeed()
            Sentry.setUser(null)
            mixpanel.reset()
            navigate(paths.login)
          },
        })
      }
    >
      {t('common.log_out')}
    </Button>
  )
}
