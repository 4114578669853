import { pipe } from 'fp-ts/lib/function'
import { decodeJson, get } from 'lib/request'

import { TDataByIP } from './external.codecs'

export const getDataByIP = async () => {
  return pipe(
    await get('https://ipapi.co/json/', {
      externalUrl: true,
    }),
    decodeJson(TDataByIP),
  )
}
