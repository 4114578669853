import { pipe } from 'fp-ts/function'
import { decodeBlob, decodeJson, get, post } from 'lib/request'

import { TUploadFileResponse } from './files.codecs'

export const uploadFile = async ({ file }: { file: File }) => {
  return pipe(
    await post('file/files/upload', {
      body: { file },
      type: 'file',
    }),
    decodeJson(TUploadFileResponse),
  )
}

export type GetFileInput = { fileId: string }

export const getFile = async ({ fileId }: GetFileInput) => {
  return pipe(
    await get('file/files/:fileId', { params: { fileId } }),
    decodeBlob,
  )
}

export const uploadPicture = async ({ image }: { image: Blob }) => {
  return pipe(
    await post('file/pictures/upload', {
      body: { image },
      type: 'file',
    }),
    decodeJson(TUploadFileResponse),
  )
}
