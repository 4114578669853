import { styled, Tabs as MuiTabs, TabsProps } from '@mui/material'

export interface StyledTabsProps extends TabsProps {}

export const Tabs = styled(MuiTabs, {
  shouldForwardProp: prop => prop !== 'hasIcon',
})<StyledTabsProps>(({ theme }) => ({
  minHeight: 'auto',
  [`& .MuiTabs-indicator`]: {
    backgroundColor: theme.palette.mineShaft.main,
    bottom: 0,
    borderRadius: 50,
    height: 30,
  },
}))
