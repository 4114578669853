import { Box, Typography } from '@mui/material'
import computerImage from 'assets/images/computer.png'

type Props = {
  title: string
  imgSrc?: string
  button?: React.ReactNode
}

export const NoDataTemplateSmall = ({
  title,
  imgSrc = computerImage,
  button,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pb: 3,
      }}
    >
      <Box component="img" src={imgSrc} alt={title} width="auto" height={150} />
      <Typography variant="h4" mt={2}>
        {title}
      </Typography>
      {button && <Box mt={2}>{button}</Box>}
    </Box>
  )
}
