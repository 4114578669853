import { startWeekDays } from 'lib/records'
import { useTranslation } from 'react-i18next'
import { Select, SelectProps } from 'ui/inputs/select'

export const StartWeekDaySelect = (
  props: Omit<SelectProps<string>, 'options'>,
) => {
  const { t } = useTranslation()

  return (
    <Select
      {...props}
      label={t('common.start_week_day')}
      options={startWeekDays.map(day => ({
        value: day,
        label: t(`start_week_day.${day}` as const),
      }))}
    />
  )
}
