import { Box, Paper, Stack, Typography } from '@mui/material'

import { InfoHint } from '../info-hint'

export type WidgetProps = Readonly<{
  title: string
  children: React.ReactNode

  hint?: string
  icon?: React.ReactNode
  additionalElement?: React.ReactNode
  minHeight?: number
  fullHeight?: boolean
  autoOverflow?: boolean
}>

export const Widget = ({
  icon,
  title,
  children,
  hint,
  additionalElement,
  minHeight,
  fullHeight,
  autoOverflow,
}: WidgetProps) => {
  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: fullHeight ? '100%' : 'auto',
        minHeight: minHeight ?? 'auto',
      }}
    >
      <Stack
        direction="row"
        gap={1}
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        minHeight="50px"
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          color="text.primary"
        >
          {icon}
          <Box display="flex">
            <Typography variant="h2">{title}</Typography>
            {hint && <InfoHint hint={hint} />}
          </Box>
        </Stack>

        {additionalElement}
      </Stack>
      <Box flex={1} overflow={autoOverflow ? 'auto' : 'visible'}>
        {children}
      </Box>
    </Paper>
  )
}
