import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material'
import { forwardRef } from 'react'
import { LinkProps, NavLinkProps } from 'react-router-dom'
import { boldOnHover as boldOnHoverStyles } from 'styles/utils'

import { NavRouteLink, RouteLink } from './route-link'

export const Link = forwardRef<
  HTMLElement,
  LinkProps &
    Omit<MuiLinkProps, 'href'> & {
      boldOnHover?: boolean
    }
>(({ boldOnHover, ...rest }, ref) => {
  return (
    <MuiLink
      ref={ref}
      component={RouteLink}
      underline="none"
      color="inherit"
      {...rest}
      sx={{
        ...(boldOnHover && boldOnHoverStyles),
        ...rest.sx,
      }}
    />
  )
})

export const NavLink = forwardRef<
  HTMLElement,
  NavLinkProps &
    Omit<MuiLinkProps, 'href'> & {
      boldOnHover?: boolean
    }
>(({ boldOnHover, ...rest }, ref) => {
  return (
    <MuiLink
      ref={ref}
      component={NavRouteLink}
      underline="none"
      color="inherit"
      {...rest}
      sx={{
        ...(boldOnHover && boldOnHoverStyles),
        ...rest.sx,
      }}
    />
  )
})
