import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconArrowDownShort = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      d="M14.0966 6.56298L8.84656 11.438L3.59656 6.56298"
      stroke="currentColor"
      strokeLinecap="round"
      fill="none"
    />
  </SvgIcon>
)
