import { TProfiles } from 'api/common'
import { JobRequestId } from 'api/job-requests'
import {
  createListSearchParams,
  ListOrder,
  ListPagination,
} from 'api/list-params'
import { pipe } from 'fp-ts/function'
import {
  decodeJson,
  decodeJsonWithTotal,
  del,
  get,
  patch,
  post,
  put,
} from 'lib/request'

import {
  JobPermissionType,
  JobPriority,
  JobStatusCode,
  TJobDescription,
  TJobDetails,
  TJobFromJobRequest,
  TJobHistoryLog,
  TJobRow,
  TJobsSearchResult,
  TPublishJob,
  WorkModel,
} from './jobs.codecs'

export type JobId = {
  jobId: string
}

const JOB_PATH = 'api/jobs/:jobId'

export type GetJobDetailsInput = JobId

export const getJobDetails = async ({ jobId }: GetJobDetailsInput) => {
  return pipe(
    await get(JOB_PATH, { params: { jobId } }),
    decodeJson(TJobDetails),
  )
}

type JobsFilters = {
  statuses?: Array<string>
  departments?: Array<string>
  projects?: Array<string>
  skills?: Array<string>
  recruiters?: Array<string>
  from?: Date | null
  to?: Date | null
  performingStatus?: string
  jobRequestId?: string
}

const generateFilters = (filters: JobsFilters) => {
  const result = []

  if (filters.departments && filters.departments.length > 0) {
    result.push({
      filterType: 'departmentsFilter',
      departmentIds: filters.departments,
    })
  }

  if (filters.projects && filters.projects.length > 0) {
    result.push({
      filterType: 'projectIdsFilter',
      projectIds: filters.projects,
    })
  }

  if (filters.skills && filters.skills.length > 0) {
    result.push({
      filterType: 'skillsFilter',
      skills: filters.skills,
    })
  }

  if (filters.recruiters && filters.recruiters.length > 0) {
    result.push({
      filterType: 'recruitersFilter',
      recruiters: filters.recruiters,
    })
  }

  if (filters.from || filters.to) {
    result.push({
      filterType: 'createdAtFilter',
      from: filters.from ?? undefined,
      to: filters.to ?? undefined,
    })
  }

  return result
}

export type GetJobsListInput = {
  pagination: ListPagination
  order?: ListOrder
  filters?: JobsFilters
  search?: string
  candidateExclude?: string
}

export const getJobsList = async ({
  filters,
  candidateExclude,
  order,
  pagination,
  search,
}: GetJobsListInput) => {
  const params = createListSearchParams({ pagination, order })

  if (filters && filters.statuses && filters.statuses.length > 0) {
    for (const status of filters.statuses) {
      params.append('statuses', status)
    }
  }

  if (filters && filters.performingStatus) {
    params.append('performing', filters.performingStatus)
  }
  if (candidateExclude) {
    params.append('candidateExclude', candidateExclude)
  }

  const generatedFilters = generateFilters(filters ?? {})

  return pipe(
    await post('api/jobs/list', {
      query: params,
      body: {
        search,
        filter: {
          filters: generatedFilters,
        },
      },
    }),
    decodeJsonWithTotal(TJobRow, pagination),
  )
}

export type JobInput = {
  positionId: string
  jobTypeId: string
  jobPipelineId?: string
  salaryMin?: number
  salaryMax?: number
  qualificationLevelCode?: string
  address?: {
    countryCode?: string
    city?: string
  }
  skills: Array<{
    skillId: string
    skillLevelCode: string
  }>
  officeId: string
  projectId?: string
  recruiters: Array<string>
  hiringManager?: string
  targetDate: string
  description?: string
  jobRequestId?: string
  languages?: Array<{
    languageId: string
    levelCode: string
    order: number
  }>
  priority: JobPriority
  modelCode: WorkModel
  departmentId: string
  customFields: Array<{
    fieldId: string
    fieldValue: string | number | Date
  }>
}

export const createJob = async (input: JobInput) => {
  return pipe(await post('api/jobs', { body: input }), decodeJson(TJobDetails))
}

export const updateJob = async ({ jobId, ...values }: JobId & JobInput) => {
  return pipe(
    await put(JOB_PATH, {
      params: { jobId },
      body: values,
    }),
    decodeJson(TJobDetails),
  )
}

export const deleteJob = async ({ jobId }: JobId) => {
  return await del(JOB_PATH, { params: { jobId } })
}

export const updateJobStatus = async ({
  jobId,
  statusCode,
}: JobId & {
  statusCode: JobStatusCode
}) => {
  return await put('api/jobs/:jobId/statuses', {
    params: { jobId },
    query: new URLSearchParams({ statusCode }),
  })
}

export type GetJobFromJobRequestInput = JobRequestId

export const getJobFromJobRequest = async ({
  jobRequestId,
}: GetJobFromJobRequestInput) => {
  return pipe(
    await get('api/jobs/job_request/:jobRequestId', {
      params: { jobRequestId },
    }),
    decodeJson(TJobFromJobRequest),
  )
}

export type GetJobHistoryInput = JobId & {
  pagination: ListPagination
}

export const getJobHistory = async ({
  jobId,
  pagination,
}: GetJobHistoryInput) => {
  const params = createListSearchParams({ pagination })

  return pipe(
    await get('api/jobs/:jobId/history', {
      params: { jobId },
      query: params,
    }),
    decodeJsonWithTotal(TJobHistoryLog, pagination),
  )
}

export type SearchJobsInput = { name: string }

export const searchJobs = async (input: SearchJobsInput) => {
  const params = new URLSearchParams()

  if (input.name !== '') {
    params.set('name', input.name)
  }

  return pipe(
    await get('api/jobs/search', {
      query: params,
    }),
    decodeJson(TJobsSearchResult),
  )
}

export type GetUsersForJobShareInput = JobId

export const getUsersForJobShare = async ({
  jobId,
}: GetUsersForJobShareInput) => {
  return pipe(
    await get('api/jobs/:jobId/users-for-sharing', {
      params: { jobId },
    }),
    decodeJson(TProfiles),
  )
}

export const shareJobToUsers = async ({
  jobId,
  sharedWith,
}: JobId & {
  sharedWith: Record<string, JobPermissionType>
}) => {
  return await patch('api/jobs/:jobId/shared-with', {
    params: { jobId },
    body: {
      sharedWith,
    },
  })
}

export const publishJob = async ({ jobId }: JobId) => {
  return pipe(
    await patch('api/jobs/:jobId/publish', {
      params: { jobId },
    }),
    decodeJson(TPublishJob),
  )
}

export const unpublishJob = async ({ jobId }: JobId) => {
  return await patch('api/jobs/:jobId/unpublish', {
    params: { jobId },
  })
}

type GenerateJobDescriptionInput = {
  body: {
    positionId: string
    countryCode?: string
    qualificationLevel?: string
  }
}

export const generateJobDescription = async ({
  body,
}: GenerateJobDescriptionInput) => {
  return pipe(
    await post('api/jobs/generate-descriptions', {
      body,
    }),
    decodeJson(TJobDescription),
  )
}
