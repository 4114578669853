import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconList = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 17.25C3.75 16.8358 4.08579 16.5 4.5 16.5H19.5C19.9142 16.5 20.25 16.8358 20.25 17.25C20.25 17.6642 19.9142 18 19.5 18H4.5C4.08579 18 3.75 17.6642 3.75 17.25Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 11.25C3.75 10.8358 4.08579 10.5 4.5 10.5H19.5C19.9142 10.5 20.25 10.8358 20.25 11.25C20.25 11.6642 19.9142 12 19.5 12H4.5C4.08579 12 3.75 11.6642 3.75 11.25Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 5.25C3.75 4.83579 4.08579 4.5 4.5 4.5H19.5C19.9142 4.5 20.25 4.83579 20.25 5.25C20.25 5.66421 19.9142 6 19.5 6H4.5C4.08579 6 3.75 5.66421 3.75 5.25Z"
      fill="currentColor"
    />
  </SvgIcon>
)
