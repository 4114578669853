import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconLevelsHigh = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 14"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M10.0884 8.56479C10.0345 8.61887 9.97038 8.66177 9.89981 8.69104C9.82924 8.72031 9.75359 8.73538 9.6772 8.73538C9.6008 8.73538 9.52515 8.72031 9.45458 8.69104C9.38401 8.66177 9.31991 8.61887 9.26595 8.56479L7.00261 6.30729L4.73345 8.57062C4.67944 8.62463 4.61533 8.66747 4.54476 8.6967C4.4742 8.72592 4.39857 8.74097 4.3222 8.74097C4.24582 8.74097 4.17019 8.72592 4.09963 8.6967C4.02907 8.66747 3.96495 8.62463 3.91095 8.57062C3.85694 8.51662 3.8141 8.4525 3.78487 8.38194C3.75564 8.31138 3.7406 8.23575 3.7406 8.15937C3.7406 8.083 3.75564 8.00737 3.78487 7.9368C3.8141 7.86624 3.85694 7.80213 3.91095 7.74812L6.58845 5.07062C6.81595 4.84312 7.18345 4.84312 7.41095 5.07062L10.0884 7.74812C10.1425 7.80151 10.1854 7.86509 10.2147 7.93519C10.244 8.00528 10.259 8.08049 10.259 8.15645C10.259 8.23242 10.244 8.30763 10.2147 8.37772C10.1854 8.44782 10.1425 8.5114 10.0884 8.56479Z"
      fill="currentColor"
    />
  </SvgIcon>
)
