import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconPieChartFill = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      d="M17.9827 9.56279H9.233L3.04615 15.7496C4.63279 17.1503 6.71714 18.0003 9 18.0003C13.7816 18.0003 17.6923 14.2713 17.9827 9.56279Z"
      fill="currentColor"
    />
    <path
      d="M2.25066 14.9541C0.849948 13.3675 0 11.2831 0 9.00029C0 4.21864 3.72896 0.307939 8.4375 0.0175781V8.76729L2.25066 14.9541Z"
      fill="currentColor"
    />
    <path
      d="M9.5625 0.0175781V8.43779H17.9827C17.7038 3.91528 14.085 0.296467 9.5625 0.0175781Z"
      fill="currentColor"
    />
  </SvgIcon>
)
