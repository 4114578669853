import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createActivity, qk } from 'api'
import { useMyTimeZone } from 'lib/app-helpers'
import { showToast } from 'lib/toast'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'

import { ActivityDialogForm } from '../form'

type Props = {
  isOpen: boolean
  onClose: () => void
  contactId?: string
}

export const CreateActivityDialog = (props: Props) => {
  const { t } = useTranslation()
  const timeZone = useMyTimeZone()

  const queryClient = useQueryClient()
  const $createActivity = useMutation(createActivity)

  return (
    <ActivityDialogForm
      title={t('common.create_activity')}
      hint={t('hints.toolbar.activities_creation_form')}
      isOpen={props.isOpen}
      onClose={props.onClose}
      initialValues={{
        contact: props.contactId ?? '',
      }}
      isActionLoading={$createActivity.isLoading}
      onSubmit={({ status, ...values }) => {
        $createActivity.mutate(
          {
            ...values,
            timeZone,
          },
          {
            onSuccess: () => {
              mixpanel.track('Create activity')
              props.onClose()
              showToast({ title: t('toasts.activity_created') })
              queryClient.invalidateQueries(qk.activities.list.toKey())
              queryClient.invalidateQueries(qk.contacts.logs.toKey())
              if (props.contactId) {
                queryClient.invalidateQueries(
                  qk.activities.contact.toKeyWithArgs({
                    contactId: props.contactId,
                  }),
                )
              }
            },
          },
        )
      }}
    />
  )
}
