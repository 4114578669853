import { Box, Typography } from '@mui/material'
import { getRequestErrorMessage } from 'lib/request'
import { useTranslation } from 'react-i18next'

export const ErrorTemplateSmall = ({ error }: { error: unknown }) => {
  const { t } = useTranslation()
  const { title, message } = getRequestErrorMessage(error, t)

  return (
    <Box p={3} textAlign="center">
      <Typography variant="h3">{title}</Typography>
      <Typography variant="body2" color="text.secondary" mt={1}>
        {message}
      </Typography>
    </Box>
  )
}
