import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconUserCircleFill = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      d="M10.0003 1.66699C5.48283 1.66699 1.66699 5.48283 1.66699 10.0003C1.66699 14.5178 5.48283 18.3337 10.0003 18.3337C14.5178 18.3337 18.3337 14.5178 18.3337 10.0003C18.3337 5.48283 14.5178 1.66699 10.0003 1.66699ZM10.0003 5.83366C11.4395 5.83366 12.5003 6.89366 12.5003 8.33366C12.5003 9.77366 11.4395 10.8337 10.0003 10.8337C8.56199 10.8337 7.50033 9.77366 7.50033 8.33366C7.50033 6.89366 8.56199 5.83366 10.0003 5.83366ZM5.74533 13.977C6.49283 12.877 7.73949 12.1437 9.16699 12.1437H10.8337C12.262 12.1437 13.5078 12.877 14.2553 13.977C13.1903 15.117 11.6795 15.8337 10.0003 15.8337C8.32116 15.8337 6.81033 15.117 5.74533 13.977Z"
      fill="currentColor"
    />
  </SvgIcon>
)
