import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconMinusLarge = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 26 24">
    <path
      d="M6.90918 12L18.9092 12"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </SvgIcon>
)
