import { Box } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { applyContactToJobs, qk } from 'api'
import { LocalStorageKey } from 'app/enums'
import { paths } from 'app/paths'
import programmerImage from 'assets/images/programmer.png'
import { NoDataTemplate } from 'components/templates'
import { usePagination } from 'lib/app-helpers'
import { showToast } from 'lib/toast'
import mixpanel from 'mixpanel-browser'
import { JobsTable } from 'pages/jobs/table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TableSelected } from 'ui/data'
import { Dialog, DialogContent } from 'ui/feedback'
import { LinkButton } from 'ui/navigation'

type Props = {
  isOpen: boolean
  onClose: () => void
  contactId: string
}

export const ApplyToJobDialog = ({ contactId, onClose, isOpen }: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const pagination = usePagination(LocalStorageKey.JobsRowsPerPage)
  const [selected, setSelected] = useState<TableSelected>([])

  const $apply = useMutation(applyContactToJobs)

  const closeDialog = () => {
    pagination.onChangePage(0)
    setSelected([])
    onClose()
  }

  return (
    <Dialog open={isOpen} width="1200px" onClose={closeDialog}>
      <DialogContent
        onDeny={closeDialog}
        confirmText={t('common.apply')}
        disableContentPadding
        isConfirmDisabled={selected.length === 0}
        isActionLoading={$apply.isLoading}
        title={t('common.select_jobs_to_apply')}
        onConfirm={() => {
          $apply.mutate(
            {
              jobIds: selected,
              contactId,
            },
            {
              onSuccess: () => {
                mixpanel.track('Apply contact to job')
                closeDialog()
                showToast({
                  title: t('toasts.contact_applied'),
                  body: t('toasts.contact_applied_description'),
                })
                setSelected([])
                queryClient.invalidateQueries(qk.contacts.logs.toKey())
                queryClient.invalidateQueries(
                  qk.contacts.appliedJobs.toKeyWithArgs({ contactId }),
                )
              },
            },
          )
        }}
      >
        <Box
          sx={{
            maxHeight: '70vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <JobsTable
            selected={selected}
            setSelected={setSelected}
            contactId={contactId}
            pagination={pagination}
            statusesFilter={['OPEN']}
            noDataTemplate={
              <NoDataTemplate
                imgSrc={programmerImage}
                noBorder
                title={t('common.no_open_jobs')}
                subtitle={t('common.create_job_to_apply_candidates')}
                button={
                  <LinkButton to={paths.createJob}>
                    {t('common.create_a_job')}
                  </LinkButton>
                }
              />
            }
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
