import { useMutation } from '@tanstack/react-query'
import { getContactDetails, getContactsList, qk } from 'api'
import { ContactProfile } from 'components/contacts'
import { useInfinitePaginatedQuery } from 'lib/react-query-utils'
import { useDebounceState } from 'lib/react-utils'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'react-use'
import { Autocomplete, AutocompleteProps } from 'ui/inputs/autocomplete'

type Props = Omit<AutocompleteProps, 'options'>

export const ContactAutocomplete = forwardRef<HTMLInputElement, Props>(
  (props: Props, ref) => {
    const { t } = useTranslation()
    const [searchValue, setSearchValue] = useDebounceState('', 500)

    const $contacts = useInfinitePaginatedQuery(
      qk.contacts.list,
      getContactsList,
      {
        search: searchValue,
        order: {
          column: 'RELEVANCE',
          direction: 'desc',
        },
        pagination: { page: 0, pageSize: 25 },
      },
      {
        keepPreviousData: false,
        enabled: searchValue.length > 0,
      },
    )

    const $getContactDetails = useMutation(getContactDetails)

    // If initial value is passed - it's contact id.
    // But in this case there are no options yet to display input value as contact name.
    // So on first load we need to retrieve contact details to set initial input value correctly.
    useEffectOnce(() => {
      if (props.value) {
        $getContactDetails.mutate(
          { contactId: props.value },
          {
            onSuccess: data => {
              setSearchValue(data.name)
            },
          },
        )
      }
    })

    return (
      <Autocomplete
        {...props}
        ref={ref}
        loading={$contacts.isLoading}
        loadingError={$contacts.isError}
        disableInternalFilter
        onInputChange={setSearchValue}
        closedWhileInputEmpty
        hasMoreOptions={$contacts.hasNextPage}
        onLoadMore={$contacts.fetchNextPage}
        isLoadingMore={$contacts.isFetchingNextPage}
        placeholder={
          $contacts.isLoading ? t('common.loading') : t('common.search')
        }
        options={
          $contacts.data?.pages
            .flatMap(page => page.rows)
            .map(contact => ({
              value: contact.candidateProfileId,
              label: contact.name,
              content: (
                <ContactProfile
                  avatar={contact.imageUri}
                  name={contact.name}
                  position={contact.position.name}
                />
              ),
            })) ?? []
        }
      />
    )
  },
)
