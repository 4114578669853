import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconChevronDown = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M6 9L12 15L18 9"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
