type Env = {
  REACT_APP_NAME: string
  REACT_APP_VERSION: string
  REACT_APP_ENV: 'development' | 'staging' | 'production'
  REACT_APP_MSW: 'on' | 'off' | 'quiet'
  REACT_APP_CLIENT_ID: string
  REACT_APP_GOOGLE_API_KEY: string
  REACT_APP_DEBUG_I18N: 'on' | 'off'
  REACT_APP_STRIPE_PRIVATE_KEY: string
  REACT_APP_MIXPANEL_TOKEN: string
}

export const env = {
  REACT_APP_NAME: process.env['REACT_APP_NAME'],
  REACT_APP_VERSION: process.env['REACT_APP_VERSION'],
  REACT_APP_ENV: process.env['REACT_APP_ENV'],
  REACT_APP_MSW: process.env['REACT_APP_MSW'],
  REACT_APP_CLIENT_ID: process.env['REACT_APP_CLIENT_ID'],
  REACT_APP_GOOGLE_API_KEY: process.env['REACT_APP_GOOGLE_API_KEY'],
  REACT_APP_DEBUG_I18N: process.env['REACT_APP_DEBUG_I18N'],
  REACT_APP_STRIPE_PRIVATE_KEY: process.env['REACT_APP_STRIPE_PRIVATE_KEY'],
  REACT_APP_MIXPANEL_TOKEN: process.env['REACT_APP_MIXPANEL_TOKEN'],
} as Env
