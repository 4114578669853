import { useCallback, useLayoutEffect, useRef, useState } from 'react'

/**
 * Custom React hook to detect element overflow in a specified direction.
 *
 * @param {'horizontal' | 'vertical'} direction - The overflow direction to check.
 * @returns {{
 *   ref: React.MutableRefObject<HTMLElement | null>,
 *   isOverflow: boolean
 * }} - Object containing a ref to the target element and a boolean indicating overflow status.
 */
export const useIsElementOverflow = (direction: 'horizontal' | 'vertical') => {
  const ref = useRef<HTMLElement | null>(null)
  const [isOverflow, setIsOverflow] = useState(false)

  /**
   * Check if the element overflows in the horizontal direction.
   *
   * @param {HTMLElement} element - The target element to check.
   * @returns {boolean} - True if the element overflows horizontally, false otherwise.
   */
  const checkHorizontalOverflow = useCallback(
    (element: HTMLElement) => {
      return direction === 'horizontal'
        ? element.scrollWidth > element.offsetWidth
        : element.scrollHeight > element.offsetHeight
    },
    [direction],
  )

  useLayoutEffect(() => {
    const element = ref.current
    if (!element) return

    /**
     * Trigger the overflow check and update the state.
     */
    const triggerCheck = () => {
      setIsOverflow(checkHorizontalOverflow(element))
    }

    // Track element size change using ResizeObserver
    const resizeObserver = new ResizeObserver(triggerCheck)
    resizeObserver.observe(element)

    // Initial check
    triggerCheck()

    /**
     * Cleanup: Disconnect the ResizeObserver.
     */
    return () => {
      resizeObserver.disconnect()
    }
  }, [setIsOverflow, checkHorizontalOverflow])

  return { ref, isOverflow }
}
