import { Box, LinearProgress, Stack, Typography } from '@mui/material'
import { ContactDocument } from 'api'
import { IconFileAttachment04, IconX } from 'assets/icons'
import { Accept } from 'react-dropzone'

import { IconButton } from '../icon-button'
import { DropZone } from './drop-zone'

type Props = Readonly<{
  files: Array<ContactDocument> | null
  onUpload: (files: Array<File>) => void
  onDelete: (fileId: string) => void
  accept?: Accept
  subtitle?: string
  maxFiles?: number
  maxSize?: number
  isLoading?: boolean
  acceptedFileFormats?: string
}>

export const SeveralFilesUploader = ({
  files,
  onUpload,
  onDelete,
  accept,
  subtitle,
  maxFiles = 10,
  maxSize = 2 * Math.pow(1024, 2),
  isLoading,
  acceptedFileFormats,
}: Props) => {
  return (
    <Stack spacing={1}>
      {maxFiles > 0 && (
        <DropZone
          onDrop={onUpload}
          maxFiles={maxFiles}
          maxSize={maxSize}
          accept={accept}
          subtitle={subtitle}
          disabled={maxFiles <= 0}
          acceptedFileFormats={acceptedFileFormats}
          multiple
        />
      )}

      {files && (
        <Stack spacing={1}>
          {files.map(file => (
            <Box
              key={file.fileId}
              bgcolor={theme => theme.palette.mediumPurple[100]}
              borderRadius="4px"
              display="flex"
              alignItems="center"
              p={1}
            >
              <IconFileAttachment04
                sx={{ color: theme => theme.palette.mediumPurple.main }}
              />

              <Typography
                variant="body1"
                pl={1}
                maxWidth="75%"
                ml={1}
                sx={{
                  wordWrap: 'break-word',
                  color: theme => theme.palette.mineShaft.main,
                }}
              >
                {file.name}
              </Typography>

              <IconButton
                sx={{ ml: 'auto' }}
                onClick={() => {
                  onDelete(file.fileId)
                }}
              >
                <IconX
                  sx={{
                    fontSize: '20px',
                    color: theme => theme.palette.mineShaft[800],
                  }}
                />
              </IconButton>
            </Box>
          ))}
        </Stack>
      )}

      {isLoading && <LinearProgress />}
    </Stack>
  )
}
