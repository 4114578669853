import * as t from 'io-ts'
// import { DateFromISOString } from 'io-ts-types' - temporary disabled

export const TEmailIntegration = t.strict({
  active: t.boolean,
  integrationId: t.string,
  type: t.union([t.literal('EMAIL'), t.literal('OUTLOOK')]),
  email: t.string,
  provider: t.string,
  expired: t.boolean,
  // createdAt: DateFromISOString, - temporary disabled
})

export const TEmailIntegrationsList = t.array(TEmailIntegration)

export const TOAuth2Url = t.strict({
  url: t.string,
})
