/** Creates a perfect distribution of integer ticks on the axis */
export const calculateDomainTicks = (
  data: Array<{ name: string; value: number }>,
  ticksCount: number,
) => {
  const maxValue = Math.max(...data.map(item => item.value))
  const orientedMax = maxValue + Math.ceil(maxValue / 10)
  const stepSize = Math.ceil(orientedMax / (ticksCount - 1))
  const ticks = [...Array(ticksCount).keys()].map(index => index * stepSize)

  return {
    ticks,
    domain: [0, ticks[ticks.length - 1]!],
  }
}
