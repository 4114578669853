import { Box, Typography } from '@mui/material'
import { useIndexPage } from 'lib/app-helpers'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Dialog, DialogContent } from 'ui/feedback'

type Props = Readonly<{
  error: Error
  componentStack: string | null
  resetError(): void
}>

export const RuntimeErrorDialog = ({
  error,
  componentStack,
  resetError,
}: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { indexPage } = useIndexPage()

  return (
    <Dialog open>
      <DialogContent
        title={t('errors.something_wrong')}
        denyText={t('common.refresh_page')}
        confirmText={t('common.go_to_main_page')}
        onDeny={() => {
          resetError()
          navigate(0)
        }}
        onConfirm={() => {
          resetError()
          navigate(indexPage)
        }}
      >
        <Box width="100%" overflow="auto">
          <Typography variant="body2" color="error">
            {error.toString()}
          </Typography>

          {componentStack && (
            <Typography variant="caption" color="error" mt={2}>
              {componentStack}
            </Typography>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}
