import { Box, Link, Stack, Tooltip } from '@mui/material'
import { ContactInformation } from 'api'
import { findContactMessengerIcon } from 'components/contacts'
import { DetailsItemContainer } from 'components/templates'
import { toArray } from 'fp-ts/Record'
import { contactInformationTypes } from 'lib/records'
import { useTranslation } from 'react-i18next'
import { TruncateAndCopy } from 'ui/utils'

type Props = {
  contactInfos: Array<ContactInformation>
}

export const ContactContactsSection = ({ contactInfos }: Props) => {
  const { t } = useTranslation()

  if (contactInfos.length === 0) return null

  return (
    <DetailsItemContainer title={t('common.contacts')}>
      <Stack spacing={1.5}>
        <SocialContactItems contactInfos={contactInfos} />
      </Stack>
    </DetailsItemContainer>
  )
}

const InfoBlock = ({
  info,
  InfoTypeIcon,
}: {
  info: ContactInformation
  InfoTypeIcon: JSX.Element
}) => {
  if (info.type === 'MESSENGER') {
    const Icon = findContactMessengerIcon(info.label)

    return (
      <Box display="flex" alignItems="center">
        {Icon && <Icon />}
        <TruncateAndCopy
          variant="body2"
          color="blue.main"
          ml={0.75}
          minWidth={0}
          text={info.value}
        />
      </Box>
    )
  }

  return (
    <Tooltip title={info.label} placement="left">
      <Box display="flex" alignItems="center" gap={0.75}>
        <Box
          bgcolor="cerulean.main"
          borderRadius={50}
          width={24}
          height={24}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {InfoTypeIcon}
        </Box>
        <Link
          variant="body2"
          color="blue.main"
          underline="none"
          href={`${info.type === 'EMAIL' ? 'mailto:' : 'tel:'}${info.value}`}
          minWidth={0}
        >
          <TruncateAndCopy text={info.value} />
        </Link>
      </Box>
    </Tooltip>
  )
}

export const SocialContactItems = ({ contactInfos }: Props) => {
  return (
    <>
      {toArray(contactInformationTypes).map(([value, infoType]) => {
        const typeInfos = contactInfos.filter(info => info.type === value)

        if (typeInfos.length === 0) return null

        const InfoTypeIcon = infoType.Icon

        return (
          <Box key={value}>
            <Stack spacing={1.5}>
              {typeInfos.map((info, index) => (
                <InfoBlock
                  key={index}
                  info={info}
                  InfoTypeIcon={
                    <InfoTypeIcon sx={{ fontSize: 14, color: '#FFFFFF' }} />
                  }
                />
              ))}
            </Stack>
          </Box>
        )
      })}
    </>
  )
}
