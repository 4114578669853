import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Stack } from '@mui/material'
import { CustomFieldsList } from 'api'
import { ToastContainerId } from 'app/enums'
import { MAX_FORM_CONTENT_WIDTH } from 'app/mui-theme/theme'
import { FormLayout, ValidationErrors } from 'components/common'
import { RouteLeavingGuard } from 'components/global'
import { LanguagesWithLevels } from 'components/languages'
import { SkillsWithLevels } from 'components/skills'
import { showToast, useDismissToasts } from 'lib/toast'
import { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PdfPreview } from 'ui/data'
import { Button } from 'ui/inputs/button'
import { LinkButton } from 'ui/navigation'
import { Toolbar } from 'ui/surfaces'

import { labels } from './_labels'
import { buildSchema, defaultValues, FormValues } from './_values'
import { AvatarFieldset } from './avatar-fieldset'
import { ContactInformation } from './contact-information'
import { ContactCustomFields } from './custom-fields'
import { DocumentUploader } from './document-uploader'
import { EducationFieldset } from './education-fieldset/education-fieldset'
import { Links } from './links'
import { LocationFieldset } from './location-fieldset'
import { PersonalInformation } from './personal-information'
import { PossibleDuplicates } from './possible-duplicates'
import { ProfileCompleteness } from './profile-completeness'
import { WorkExperience } from './work-experience'
import { WorkInformation } from './work-information'
import { WorkPermitsFieldset } from './work-permits-fieldset'

type Props = {
  title: string
  customFields: CustomFieldsList
  actionText?: string
  onSave: (values: FormValues, isDirty: boolean) => void
  initialValues?: Partial<FormValues>
  initialAvatarSrc?: string
  isLoading?: boolean
  cancelRoute: string
  cvFile?: File
}

export const ContactForm = ({
  title,
  customFields,
  actionText,
  onSave,
  initialValues,
  initialAvatarSrc,
  cvFile,
  isLoading = false,
  cancelRoute,
}: Props) => {
  const { t } = useTranslation()
  const schema = useMemo(() => buildSchema(t, customFields), [t, customFields])
  const { dismissToastsByContainerId } = useDismissToasts()

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitSuccessful },
  } = useForm<FormValues>({
    defaultValues: { ...defaultValues, ...initialValues },
    resolver: zodResolver(schema),
  })

  const [languages, skills, country, city, name, position] = watch([
    'languages',
    'skills',
    'country',
    'city',
    'name',
    'position',
  ])

  const watchedFields = watch()

  return (
    <>
      <Helmet title={title} />

      <FormLayout
        withLargeLeftPadding
        toolbar={
          <Toolbar withGradient>
            <Stack spacing={2} direction="row" ml="auto">
              <LinkButton
                to={cancelRoute}
                variant="outlined"
                color="greyBlue"
                data-cy="cancelBtn"
              >
                {t('common.cancel')}
              </LinkButton>

              <Button
                type="submit"
                loading={isLoading}
                data-cy="submitContactBtn"
              >
                {actionText ?? t('common.create')}
              </Button>
            </Stack>
          </Toolbar>
        }
        leftSide={cvFile ? <PdfPreview file={cvFile} /> : null}
        onSubmit={() => {
          dismissToastsByContainerId(ToastContainerId.System)
          handleSubmit(
            values => onSave(values, isDirty),
            errors => {
              const required = [
                'name',
                'position',
                ...customFields
                  .filter(field => field.required)
                  .map(field => field.fieldName),
              ]

              showToast(
                {
                  type: 'error',
                  title: t('toasts.form_not_completed'),
                  body: (
                    <ValidationErrors
                      errors={errors}
                      translations={labels}
                      required={required}
                    />
                  ),
                },
                { autoClose: false },
              )
            },
          )()
        }}
      >
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="space-between"
          gap={4}
        >
          <Box
            display="flex"
            flex={1}
            justifyContent="center"
            minWidth={{ xs: 240, sm: 480 }}
          >
            <Stack gap={5} maxWidth={MAX_FORM_CONTENT_WIDTH} width="100%">
              <Controller
                control={control}
                name="image"
                render={({ field }) => (
                  <AvatarFieldset
                    withCrop
                    value={field.value}
                    onChange={field.onChange}
                    initialSrc={initialAvatarSrc}
                  />
                )}
              />

              <PersonalInformation control={control} />

              <LocationFieldset
                values={{ country, city }}
                changeLocationValues={({ country, city }) => {
                  setValue('country', country)
                  setValue('city', city)
                }}
              />

              <SkillsWithLevels
                control={control}
                legend={t(labels.skills).toString()}
                hint={t('hints.select_skills_of_contact')}
                selectedSkills={skills}
                positionId={position === '' ? undefined : position}
              />

              <LanguagesWithLevels
                control={control}
                legend={t(labels.languages).toString()}
                hint={t('hints.select_languages_of_contact')}
                selectedLanguages={languages}
              />

              <WorkExperience control={control} />

              <EducationFieldset control={control} />

              <ContactInformation control={control} country={country} />

              <WorkInformation control={control} />

              <WorkPermitsFieldset control={control} />

              <Links control={control} />

              {customFields.length > 0 && (
                <ContactCustomFields
                  control={control}
                  customFields={customFields}
                />
              )}
            </Stack>
          </Box>

          <Box
            minWidth={240}
            maxWidth={{ sx: 'auto', sm: 316 }}
            width="100%"
            position="relative"
          >
            <Stack gap={4} position="sticky" top={0}>
              <ProfileCompleteness watchedFields={watchedFields} />

              <DocumentUploader control={control} />
            </Stack>
          </Box>
        </Stack>
      </FormLayout>

      <PossibleDuplicates name={name} initialName={initialValues?.name} />

      <RouteLeavingGuard when={isDirty && !isSubmitSuccessful} />
    </>
  )
}

export * from './_values'
export * from './work-permits-fieldset'
