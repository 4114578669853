import { Box, Divider, Link, Stack, Tooltip, Typography } from '@mui/material'
import { useScopes } from 'app/scopes'
import { IconFileDownloadOutline, IconMinusLarge, IconPlus } from 'assets/icons'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'
import { IconButton } from 'ui/inputs/icon-button'
import { TextField } from 'ui/inputs/text-field'

type Props = {
  title: string
  url: string
  fileName: string
  children: React.ReactNode
  onDeny: () => void
  pageNumber: number
  numPages: number
  goToPrevPage: () => void
  goToNextPage: () => void
  scale: number
  setScale: Dispatch<SetStateAction<number>>
}

export const PreviewDialogContent = ({
  title,
  url,
  fileName,
  children,
  onDeny,
  pageNumber,
  numPages,
  goToPrevPage,
  goToNextPage,
  scale,
  setScale,
}: Props) => {
  const { t } = useTranslation()
  const [inputScale, setInputScale] = useState<string>(scale.toString())
  const { canViewFile } = useScopes()

  const onZoomOut = () => {
    setScale(prevScale => {
      const newScale = prevScale - 10
      const value = newScale < 25 ? 25 : newScale
      setInputScale(value.toString())
      return value
    })
  }

  const onZoomIn = () => {
    setScale(prevScale => {
      const newScale = prevScale + 10
      const value = newScale > 500 ? 500 : newScale
      setInputScale(value.toString())
      return value
    })
  }

  const onScaleChange = () => {
    const parsed = Number.parseInt(inputScale, 10)

    if (!Number.isNaN(parsed)) {
      if (parsed < 25) {
        setScale(25)
        setInputScale('25')
      } else if (parsed > 500) {
        setScale(500)
        setInputScale('500')
      } else setScale(parsed)
      return
    }

    setScale(100)
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={3}
        py={2.5}
      >
        <Typography variant="h2">{title}</Typography>
        {canViewFile && (
          <Tooltip title={t('common.download')} arrow placement="left">
            <IconButton component={Link} href={url} download={fileName}>
              <IconFileDownloadOutline sx={{ color: 'greyBlue.light' }} />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Box minHeight={600} display="flex" overflow="auto" p={3} pt={0}>
        <Box m="auto">{children}</Box>
      </Box>

      <Box
        sx={{
          bgcolor: '#FFFFFF',
          px: 3,
          py: 2.5,
          display: 'flex',
          borderTop: 1,
          borderColor: 'divider',
        }}
      >
        <Button variant="outlined" color="greyBlue" onClick={onDeny}>
          {t('common.cancel')}
        </Button>

        <Box display="flex" alignItems="center" ml="auto">
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            divider={<Divider flexItem orientation="vertical" />}
          >
            <Box display="flex" alignItems="center">
              <IconButton disabled={scale <= 25} onClick={onZoomOut}>
                <IconMinusLarge />
              </IconButton>
              <Box width={70} mx={1}>
                <TextField
                  value={`${inputScale}%`}
                  onChange={value => setInputScale(value.slice(0, -1))}
                  onKeyPress={event => {
                    if (event.key === 'Enter') onScaleChange()
                  }}
                />
              </Box>
              <IconButton disabled={scale >= 500} onClick={onZoomIn}>
                <IconPlus />
              </IconButton>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body2" color="text.primary" mr={2}>
                {t('common.pages', {
                  page: pageNumber || (numPages ? 1 : '--'),
                  pages: numPages || '--',
                })}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Button disabled={pageNumber <= 1} onClick={goToPrevPage}>
                  {t('common.previous')}
                </Button>
                <Button
                  disabled={pageNumber >= numPages}
                  onClick={goToNextPage}
                >
                  {t('common.next')}
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  )
}
