import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconSaveOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      d="M5 3.75C4.66848 3.75 4.35054 3.8817 4.11612 4.11612C3.8817 4.35054 3.75 4.66848 3.75 5V15C3.75 15.3315 3.8817 15.6495 4.11612 15.8839C4.35054 16.1183 4.66848 16.25 5 16.25V11.875C5 11.3777 5.19754 10.9008 5.54917 10.5492C5.90081 10.1975 6.37772 10 6.875 10H13.125C13.6223 10 14.0992 10.1975 14.4508 10.5492C14.8025 10.9008 15 11.3777 15 11.875V16.25C15.3315 16.25 15.6495 16.1183 15.8839 15.8839C16.1183 15.6495 16.25 15.3315 16.25 15V7.025C16.2499 6.69351 16.1182 6.37562 15.8837 6.14125L13.8575 4.115C13.6229 3.88102 13.3051 3.74974 12.9737 3.75H12.5V5.625C12.5 6.12228 12.3025 6.59919 11.9508 6.95083C11.5992 7.30246 11.1223 7.5 10.625 7.5H8.125C7.62772 7.5 7.15081 7.30246 6.79917 6.95083C6.44754 6.59919 6.25 6.12228 6.25 5.625V3.75H5ZM7.5 3.75V5.625C7.5 5.79076 7.56585 5.94973 7.68306 6.06694C7.80027 6.18415 7.95924 6.25 8.125 6.25H10.625C10.7908 6.25 10.9497 6.18415 11.0669 6.06694C11.1842 5.94973 11.25 5.79076 11.25 5.625V3.75H7.5ZM13.75 16.25V11.875C13.75 11.7092 13.6842 11.5503 13.5669 11.4331C13.4497 11.3158 13.2908 11.25 13.125 11.25H6.875C6.70924 11.25 6.55027 11.3158 6.43306 11.4331C6.31585 11.5503 6.25 11.7092 6.25 11.875V16.25H13.75ZM2.5 5C2.5 4.33696 2.76339 3.70107 3.23223 3.23223C3.70107 2.76339 4.33696 2.5 5 2.5H12.9737C13.6367 2.50014 14.2725 2.76363 14.7413 3.2325L16.7675 5.25875C17.2364 5.72748 17.4999 6.36326 17.5 7.02625V15C17.5 15.663 17.2366 16.2989 16.7678 16.7678C16.2989 17.2366 15.663 17.5 15 17.5H5C4.33696 17.5 3.70107 17.2366 3.23223 16.7678C2.76339 16.2989 2.5 15.663 2.5 15V5Z"
      fill="currentColor"
    />
  </SvgIcon>
)
