import { paths } from '../paths'
import { buildRoute, Route } from './build-route'

export const publicRoutes: Array<Route> = [
  buildRoute({
    path: paths.support,
    factory: () => import('pages/support'),
  }),
  buildRoute({
    path: paths.bookMeeting,
    factory: () => import('pages/book-meeting'),
  }),
  buildRoute({
    path: paths.publishedJob,
    factory: () => import('pages/published-job'),
  }),
  buildRoute({
    path: paths.publishedJobs,
    factory: () => import('pages/published-jobs'),
  }),
]
