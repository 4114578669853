import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconStars03 = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 22 22"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M17.5 7V2M4.5 20V15M15 4.5H20M2 17.5H7M5.5 1L4.71554 2.56892C4.45005 3.09989 4.31731 3.36538 4.13997 3.59545C3.98261 3.79959 3.79959 3.98261 3.59545 4.13997C3.36538 4.31731 3.0999 4.45005 2.56892 4.71554L1 5.5L2.56892 6.28446C3.0999 6.54995 3.36538 6.68269 3.59545 6.86003C3.79959 7.01739 3.98261 7.20041 4.13997 7.40455C4.31731 7.63462 4.45005 7.9001 4.71554 8.43108L5.5 10L6.28446 8.43108C6.54995 7.9001 6.68269 7.63462 6.86003 7.40455C7.01739 7.20041 7.20041 7.01739 7.40455 6.86003C7.63462 6.68269 7.9001 6.54995 8.43108 6.28446L10 5.5L8.43108 4.71554C7.9001 4.45005 7.63462 4.31731 7.40455 4.13997C7.20041 3.98261 7.01739 3.79959 6.86003 3.59545C6.68269 3.36538 6.54995 3.0999 6.28446 2.56892L5.5 1ZM16 11L15.0489 12.9022C14.7834 13.4332 14.6506 13.6987 14.4733 13.9288C14.3159 14.1329 14.1329 14.3159 13.9288 14.4733C13.6987 14.6506 13.4332 14.7834 12.9023 15.0489L11 16L12.9023 16.9511C13.4332 17.2166 13.6987 17.3494 13.9288 17.5267C14.1329 17.6841 14.3159 17.8671 14.4733 18.0712C14.6506 18.3013 14.7834 18.5668 15.0489 19.0977L16 21L16.9511 19.0978C17.2166 18.5668 17.3494 18.3013 17.5267 18.0712C17.6841 17.8671 17.8671 17.6841 18.0712 17.5267C18.3013 17.3494 18.5668 17.2166 19.0977 16.9511L21 16L19.0977 15.0489C18.5668 14.7834 18.3013 14.6506 18.0712 14.4733C17.8671 14.3159 17.6841 14.1329 17.5267 13.9288C17.3494 13.6987 17.2166 13.4332 16.9511 12.9023L16 11Z"
      stroke="currentColor"
      strokeWidth={props.stroke ?? '1.4'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
