import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  styled,
} from '@mui/material'
import { ConfirmDialogProps, useConfirmationDialog } from 'lib/app-helpers'
import { ForwardedRef, forwardRef, MouseEvent } from 'react'

export type IconButtonProps<C extends React.ElementType> = MuiIconButtonProps<
  C,
  { component?: C }
> & {
  disablePadding?: boolean
  disableHover?: boolean
  variant?: 'outlined'
  sizePx?: number
  /**
   * Adds confirmation dialog that will be opened after clicking this button.
   * onClick will be invoked only after confirming inside dialog
   */
  confirm?: ConfirmDialogProps
}

const StyledButton = styled(MuiIconButton, {
  shouldForwardProp: prop =>
    prop !== 'disablePadding' &&
    prop !== 'disableHover' &&
    prop !== 'sizePx' &&
    prop !== 'variant',
})(
  <C extends React.ElementType>({
    disablePadding,
    disableHover,
    sizePx = 32,
    variant,
    theme,
  }: IconButtonProps<C>) => ({
    width: sizePx,
    height: sizePx,
    padding: theme.spacing(0.5),

    ...(disablePadding && {
      padding: 0,
      width: 'auto',
      height: 'auto',
    }),

    ...(variant === 'outlined' && {
      color: theme.palette.mineShaft.main,
      background: '#FFFFFF',
      border: `1px solid ${theme.palette.mineShaft[200]}`,
      boxShadow: '0px 1px 2px 0px #0000000D',
      transition: '0.25s',

      '&:hover': {
        borderColor: theme.palette.primary.main,
        background: theme.palette.alabaster,
        transition: '0.25s',
      },
    }),

    ...((disableHover || disablePadding) && {
      '&:hover': {
        background: 'none',
      },
    }),
  }),
)

export const IconButton = forwardRef(
  <C extends React.ElementType>(
    { onClick, confirm, ...props }: IconButtonProps<C>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const { openDialog, renderConfirmDialog } = useConfirmationDialog(onClick)

    return (
      <>
        <StyledButton
          ref={ref}
          size="small"
          disableRipple={props['disableHover'] || props['disablePadding']}
          onClick={(event: MouseEvent<HTMLButtonElement>) => {
            if (confirm) {
              openDialog(event)
            } else if (onClick) {
              onClick(event)
            }
          }}
          {...props}
        />

        {confirm && renderConfirmDialog(confirm)}
      </>
    )
  },
)
