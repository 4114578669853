import { Badge, badgeClasses, Tooltip } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { qk } from 'api'
import { getMailboxUnreadEmailsCount } from 'api/mailbox'
import { paths } from 'app/routes'
import { useScopes } from 'app/scopes'
import { IconMail01 } from 'assets/icons'
import { useCheckEmailIntegration } from 'lib/app-helpers/use-check-email-integration'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router'
import { IconButton } from 'ui/inputs/icon-button'
import { NavLink } from 'ui/navigation'

export const MailLink = () => {
  const isActive = useMatch({ path: paths.mailbox, end: false })

  const { t } = useTranslation()

  const { canReadMailbox } = useScopes()

  const { isEmailIntegrated } = useCheckEmailIntegration({})

  const $unreadEmailsCount = useQuery(
    qk.mailbox.unreadEmailsCount.toKey(),
    getMailboxUnreadEmailsCount,
    {
      enabled: canReadMailbox && isEmailIntegrated,
      refetchInterval: 60 * 1000, // 1 minute
    },
  )

  return (
    <Tooltip title={t('common.mailbox')} placement="bottom">
      <IconButton component={NavLink} to={paths.mailbox}>
        <Badge
          badgeContent={$unreadEmailsCount.data?.count ?? 0}
          color="primary"
          sx={{
            [`& .${badgeClasses.badge}`]: {
              minWidth: 16,
              height: 16,
              border: 1,
              borderColor: '#FFF',
              py: 0,
              px: '2px',
              fontSize: '11px',
            },
          }}
        >
          <IconMail01
            sx={{
              fontSize: '20px',
              color: theme =>
                isActive ? 'primary.main' : theme.palette.mineShaft.main,
            }}
          />
        </Badge>
      </IconButton>
    </Tooltip>
  )
}
