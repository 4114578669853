import { Typography } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteContactsFromTalentsPool, qk } from 'api'
import { IconCrossCircle } from 'assets/icons'
import { showToast } from 'lib/toast'
import { useTranslation } from 'react-i18next'
import { Button, ButtonProps } from 'ui/inputs/button'

type Props = {
  talentPoolId: string
  contacts: Array<string>
  onSuccessfulChange?: () => void
  buttonProps?: ButtonProps
}

export const DeleteFromTalentsPool = ({
  talentPoolId,
  contacts,
  onSuccessfulChange,
  buttonProps,
}: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const $delete = useMutation(deleteContactsFromTalentsPool)

  return (
    <Button
      {...buttonProps}
      startIcon={<IconCrossCircle />}
      iconSize="14px"
      confirm={{
        title: t('common.delete_from_talents_pool'),
        children: (
          <Typography>
            {t('confirms.confirm_delete_from_talents_pool')}
          </Typography>
        ),
        variant: 'danger',
        confirmText: t('common.delete'),
        size: 'small',
      }}
      onClick={() => {
        $delete.mutate(
          { talentPoolId, contacts },
          {
            onSuccess: () => {
              showToast({
                title: t('toasts.actions_deleted_from_talents_pool'),
              })

              queryClient.invalidateQueries(
                qk.contacts.talentPools.details.toKeyWithArgs({
                  talentPoolId,
                }),
              )

              if (onSuccessfulChange) {
                onSuccessfulChange()
              }
            },
          },
        )
      }}
    >
      {t('common.delete_from_talents_pool')}
    </Button>
  )
}
