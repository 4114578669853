import { useQuery } from '@tanstack/react-query'
import { getMyProfile, qk } from 'api'

/**
 * Custom hook to retrieve the time zone of the current user.
 *
 * @returns {string} - User's time zone.
 */
export const useMyTimeZone = () => {
  const $myProfile = useQuery(qk.auth.users.myProfile.toKey(), getMyProfile, {
    staleTime: Number.POSITIVE_INFINITY,
  })

  // Retrieve the current time zone from the system.
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  // Return the user's time zone if available, otherwise fallback to the current system time zone.
  return $myProfile.data?.timeZone ?? currentTimeZone
}
