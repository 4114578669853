import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconPencilSquareOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox=" 0 0 24 24">
    <path
      d="M23.2524 2.90901C23.5453 3.2019 23.5453 3.67678 23.2524 3.96967L21.6881 5.53399L18.6881 2.53399L20.2524 0.96967C20.5453 0.676777 21.0202 0.676777 21.3131 0.96967L23.2524 2.90901Z"
      fill="currentColor"
    />
    <path
      d="M20.6274 6.59465L17.6274 3.59465L7.40792 13.8141C7.32558 13.8965 7.26355 13.9968 7.22673 14.1073L6.01989 17.7278C5.92217 18.021 6.20107 18.2999 6.49423 18.2022L10.1148 16.9953C10.2252 16.9585 10.3256 16.8965 10.4079 16.8141L20.6274 6.59465Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 20.25C1.5 21.4926 2.50736 22.5 3.75 22.5H20.25C21.4926 22.5 22.5 21.4926 22.5 20.25V11.25C22.5 10.8358 22.1642 10.5 21.75 10.5C21.3358 10.5 21 10.8358 21 11.25V20.25C21 20.6642 20.6642 21 20.25 21H3.75C3.33579 21 3 20.6642 3 20.25V3.75C3 3.33579 3.33579 3 3.75 3H13.5C13.9142 3 14.25 2.66421 14.25 2.25C14.25 1.83579 13.9142 1.5 13.5 1.5H3.75C2.50736 1.5 1.5 2.50736 1.5 3.75V20.25Z"
      fill="currentColor"
    />
  </SvgIcon>
)
