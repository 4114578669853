import { addWeeks } from 'date-fns'

export const getRangeFromTodayByWeeks = (
  weeks: number,
): { from: Date; to: Date } => {
  const currentDate = new Date()
  const endDate = addWeeks(currentDate, weeks)

  return {
    from: currentDate,
    to: endDate,
  }
}
