import { useQuery } from '@tanstack/react-query'
import { AuthorizationResponse, getDataByIP, qk, refreshToken } from 'api'
import { paths, publicRoutes, unauthRoutes } from 'app/routes'
import constate from 'constate'
import { isRight } from 'fp-ts/lib/Either'
import mixpanel from 'mixpanel-browser'
import { useCallback, useEffect, useRef, useState } from 'react'
import { matchRoutes, useLocation, useNavigate } from 'react-router'

import {
  AccessTokenPayload,
  decodeAccessToken,
  setGlobalAccessToken,
} from './access-token'
import { useLogoutAcrossTabs } from './use-logout-across-tabs'

type AuthCause = 'login' | 'refresh' | 'signup'

type NotAuthUser = {
  state: 'initial' | 'unauthenticated'
}

type AuthUser = {
  state: 'authenticated'
  accessToken: string
} & AccessTokenPayload

type User = NotAuthUser | AuthUser

const useAuth = () => {
  const [user, setUser] = useState<User>({ state: 'initial' })
  const refreshTimeoutRef = useRef<number | null>(null)
  const [refetchInterval, setRefetchInterval] = useState<number | undefined>()

  const [authCause, setAuthCause] = useState<AuthCause | null>(null)

  const location = useLocation()
  const isPublicPage = !!matchRoutes(publicRoutes, location)
  const isUnauthPage = !!matchRoutes(unauthRoutes, location)

  const unauthorize = useLogoutAcrossTabs(
    useCallback(() => {
      setGlobalAccessToken(null)
      setUser({ state: 'unauthenticated' })
      setAuthCause(null)
      setRefetchInterval(-1)

      if (refreshTimeoutRef.current !== null) {
        window.clearTimeout(refreshTimeoutRef.current)
      }
    }, []),
  )

  useEffect(() => {
    if (isPublicPage) setUser({ state: 'initial' })
    if (isUnauthPage) setUser({ state: 'unauthenticated' })
  }, [isPublicPage, isUnauthPage])

  const $dataByIP = useQuery(qk.auth.token.refreshInfo.toKey(), getDataByIP)

  const navigate = useNavigate()

  const { refetch: refetchRefreshToken } = useQuery(
    qk.auth.token.refresh.toKey(),
    () => refreshToken({ countryCode: $dataByIP.data?.country }),
    {
      staleTime: Number.POSITIVE_INFINITY,
      refetchIntervalInBackground: true,
      refetchInterval,
      enabled: !isPublicPage && !isUnauthPage && !$dataByIP.isLoading, // Enable the query for auth pages
      onSuccess: ({ accessToken, existCompany }) => {
        authorize({ accessToken }, 'refresh')
        !existCompany && navigate(paths.companyName)
      },
      onError: () => {
        unauthorize()

        // https://developer.mixpanel.com/docs/javascript#call-reset-at-logout
        const superPropertyExist = mixpanel.get_property('Subscription name')
        if (superPropertyExist) {
          mixpanel.reset()
        }
      },
    },
  )

  const authorize = (
    { accessToken }: AuthorizationResponse,
    authCause: AuthCause,
    callback?: (user: AuthUser) => void,
  ) => {
    const payload = decodeAccessToken(accessToken)

    if (isRight(payload)) {
      setGlobalAccessToken(accessToken)

      const authorizedUser: AuthUser = {
        state: 'authenticated',
        accessToken,
        ...payload.right,
      }

      setUser(authorizedUser)
      setAuthCause(authCause)

      // !We have -30 to send request before token will be expired
      const expiresIn = (payload.right.exp - 30) * 1000 - Date.now()
      setRefetchInterval(expiresIn)

      mixpanel.identify(authorizedUser.userId)
      mixpanel.people.set('Role', authorizedUser.role)

      callback?.(authorizedUser)
    } else {
      setUser({ state: 'unauthenticated' })
    }
  }

  return {
    user,
    authorize,
    unauthorize,
    refetchRefreshToken,
    authCause,
  } as const
}

export const [AuthProvider, useAuthContext] = constate(useAuth)

/**
 * Use in components that are definitely protected from unauthorized users.
 */
export const useAuthUser = () => {
  const { user } = useAuthContext()
  return user as AuthUser
}
