import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

const TemplateCreator = t.intersection([
  t.strict({
    profileId: t.string,
    fullName: t.string,
    role: t.string,
  }),
  t.partial({
    avatar: t.string,
  }),
])

export const TEmailTemplate = t.strict({
  id: t.string,
  name: t.string,
  emailSubject: t.string,
  emailBody: t.string,
  createdAt: DateFromISOString,
  creator: TemplateCreator,
})

export type EmailTemplate = t.TypeOf<typeof TEmailTemplate>
