import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * Custom hook to provide validations for various settings collections.
 *
 * @returns {Object} - Validations for different settings collections.
 */
export const useSettingsCollectionValidations = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      ({
        offices: {
          minValueLength: 1,
          maxValueLength: 50,
          minAmount: {
            value: 1,
            errorMessage: t('errors.min_offices_error', {
              minValue: 1,
            }),
          },
          maxAmount: {
            value: 100,
            errorMessage: t('errors.max_offices_error', {
              maxValue: 100,
            }),
          },
        },
        positions: {
          minValueLength: 2,
          maxValueLength: 220,
          minAmount: {
            value: 1,
            errorMessage: t('errors.min_positions_error', {
              minValue: 1,
            }),
          },
          maxAmount: {
            value: 1000,
            errorMessage: t('errors.max_positions_error', {
              maxValue: 1000,
            }),
          },
          isPosition: true,
        },
        departments: {
          minValueLength: 3,
          maxValueLength: 50,
          minAmount: {
            value: 1,
            errorMessage: t('errors.min_departments_error', {
              minValue: 1,
            }),
          },
          maxAmount: {
            value: 30,
            errorMessage: t('errors.max_departments_error', {
              maxValue: 30,
            }),
          },
        },
        jobTypes: {
          minValueLength: 1,
          maxValueLength: 50,
          minAmount: {
            value: 1,
            errorMessage: t('errors.min_types_of_employment_error', {
              minValue: 1,
            }),
          },
          maxAmount: {
            value: 100,
            errorMessage: t('errors.max_types_of_employment_error', {
              maxValue: 100,
            }),
          },
        },
        languages: {
          minValueLength: 1,
          maxValueLength: 50,
          minAmount: {
            value: 1,
            errorMessage: t('errors.min_languages_error', {
              minValue: 1,
            }),
          },
          maxAmount: {
            value: 300,
            errorMessage: t('errors.max_languages_error', {
              maxValue: 300,
            }),
          },
        },
        rejectReasons: {
          minValueLength: 3,
          maxValueLength: 100,
          minAmount: {
            value: 1,
            errorMessage: t('errors.min_reject_reasons_error', {
              minValue: 1,
            }),
          },
          maxAmount: {
            value: 100,
            errorMessage: t('errors.max_reject_reasons_error', {
              maxValue: 100,
            }),
          },
        },
        workExperiences: {
          minValueLength: 1,
          maxValueLength: 50,
          minAmount: {
            value: 1,
            errorMessage: t('errors.min_work_experiences_error', {
              minValue: 1,
            }),
          },
          maxAmount: {
            value: 100,
            errorMessage: t('errors.max_work_experiences_error', {
              maxValue: 100,
            }),
          },
        },
        projects: {
          minValueLength: 1,
          maxValueLength: 50,
          minAmount: {
            value: 1,
            errorMessage: t('errors.min_projects_error', {
              minValue: 1,
            }),
          },
          maxAmount: {
            value: 1000,
            errorMessage: t('errors.max_projects_error', {
              maxValue: 1000,
            }),
          },
        },
        sources: {
          minValueLength: 1,
          maxValueLength: 50,
          minAmount: {
            value: 1,
            errorMessage: t('errors.min_sources_error', {
              minValue: 1,
            }),
          },
          maxAmount: {
            value: 50,
            errorMessage: t('errors.max_sources_error', {
              maxValue: 50,
            }),
          },
        },
      } as const),
    [t],
  )
}
