/**
 * Takes the array of elements and returns duplicates found using strict equality
 *
 * @param arr [1, 2, 3, 1, 4, 2]
 * @returns [1, 2]
 */
export const findDuplicates = <T extends any>(arr: Array<T>) => {
  return arr.reduce<Array<T>>(
    (duplicates, value, index) =>
      arr.indexOf(value) !== index && !duplicates.includes(value)
        ? [...duplicates, value]
        : duplicates,
    [],
  )
}
