import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

/**
 * Hook to set a custom error map for Zod validation errors with translated messages.
 * @returns {void}
 */
export const useSetZodErrorMap = () => {
  const { t } = useTranslation()

  /**
   * Custom error map function for Zod validation errors.
   * @type {z.ZodErrorMap}
   * @param {z.ZodError} issue - The Zod error object.
   * @param {z.ZodErrorMapParams} ctx - The Zod error map context.
   * @returns {object} - An object with a message property representing the translated error message.
   */
  const errorMap: z.ZodErrorMap = useCallback(
    (issue, ctx) => {
      if (issue.code === 'invalid_type' && issue.received === 'null') {
        return { message: t('validations.required') }
      }

      if (
        issue.code === 'too_small' &&
        issue.type === 'string' &&
        issue.minimum === 1
      ) {
        return { message: t('validations.required') }
      }

      if (issue.code === 'invalid_string' && issue.validation === 'email') {
        return { message: t('validations.invalid_email') }
      }

      if (issue.code === 'invalid_string' && issue.validation === 'url') {
        return { message: t('validations.invalid_url') }
      }

      if (issue.code === 'too_small' && issue.type === 'string') {
        return {
          message: t('validations.min_length', { minLength: issue.minimum }),
        }
      }

      if (issue.code === 'too_big' && issue.type === 'string') {
        return {
          message: t('validations.max_length', { maxLength: issue.maximum }),
        }
      }

      if (issue.code === 'too_small' && issue.type === 'number') {
        return {
          message: issue.inclusive
            ? t('validations.min_number_inclusive', { minValue: issue.minimum })
            : t('validations.min_number', { minValue: issue.minimum }),
        }
      }

      if (issue.code === 'too_big' && issue.type === 'number') {
        return {
          message: issue.inclusive
            ? t('validations.max_number_inclusive', { maxValue: issue.maximum })
            : t('validations.max_number', { maxValue: issue.maximum }),
        }
      }

      return { message: ctx.defaultError }
    },
    [t],
  )

  z.setErrorMap(errorMap)
}
