import {
  Box,
  CircularProgress,
  Dialog as MuiDialog,
  dialogClasses,
  Grow,
} from '@mui/material'

export type DialogSize = 'small' | 'medium' | 'large'

const getPaperSize = (size: DialogSize) => {
  switch (size) {
    case 'large': {
      return '900px'
    }
    case 'medium': {
      return '582px'
    }
    case 'small': {
      return '400px'
    }
  }
}

export type DialogProps = {
  /** Whether the dialog is open or closed */
  open: boolean
  /** The content to be displayed inside the dialog */
  children: React.ReactNode
  /** Callback function to handle the dialog close event */
  onClose?: () => void
  /** The size of the dialog */
  size?: DialogSize
  /** To add custom paper width that differs from predefined size options */
  width?: string
  /** Adds circular progress indicator in place of content */
  isContentLoading?: boolean
}

export const Dialog = ({
  open,
  children,
  onClose,
  width,
  size = 'medium',
  isContentLoading = false,
}: DialogProps) => {
  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      BackdropProps={{
        timeout: 300,
        easing: 'ease-out',
        sx: {
          background: 'rgba(0, 0, 0, 0.6)',
        },
      }}
      TransitionProps={{
        timeout: 300,
        easing: 'ease-in-out',
      }}
      TransitionComponent={Grow}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          maxWidth: 'none',
          width: width ?? getPaperSize(size),
          border: 'none',
          minHeight: 240,
        },
      }}
    >
      {isContentLoading ? (
        <Box
          minHeight={250}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </MuiDialog>
  )
}
