import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCloseFullscreen = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.257451 23.7427C0.550344 24.0356 1.02522 24.0356 1.31811 23.7427L7.4624 17.5984L7.4624 21.7501C7.4624 22.1643 7.79819 22.5001 8.2124 22.5001C8.62661 22.5001 8.9624 22.1643 8.9624 21.7501L8.9624 15.7878C8.9624 15.3736 8.62661 15.0378 8.2124 15.0378L2.25015 15.0378C1.83594 15.0378 1.50015 15.3736 1.50015 15.7878C1.50015 16.202 1.83594 16.5378 2.25015 16.5378L6.40174 16.5378L0.257451 22.6821C-0.0354424 22.975 -0.0354424 23.4498 0.257451 23.7427Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7425 0.257268C23.4497 -0.0356256 22.9748 -0.0356257 22.6819 0.257268L16.5376 6.40156L16.5376 2.2499C16.5376 1.83568 16.2018 1.4999 15.7876 1.4999C15.3734 1.4999 15.0376 1.83568 15.0376 2.2499L15.0376 8.21222C15.0376 8.62643 15.3734 8.96222 15.7876 8.96222L21.7498 8.96221C22.1641 8.96221 22.4999 8.62643 22.4999 8.21221C22.4999 7.798 22.1641 7.46221 21.7498 7.46221L17.5983 7.46222L23.7425 1.31793C24.0354 1.02503 24.0354 0.550161 23.7425 0.257268Z"
      fill="currentColor"
    />
  </SvgIcon>
)
