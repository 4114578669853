import { useMutation } from '@tanstack/react-query'
import { uploadFile } from 'api'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SeveralFilesUploader } from 'ui/inputs/drop-zone'

import { FormValues } from './_values'

type Props = {
  control: Control<FormValues>
}

export const DocumentUploader = ({ control }: Props) => {
  const { t } = useTranslation()
  const $uploadFile = useMutation(uploadFile)

  return (
    <Controller
      control={control}
      name="documents"
      render={({ field }) => {
        const filesCount = field.value ? field.value.length : 0

        return (
          <SeveralFilesUploader
            files={field.value}
            accept={{
              'application/pdf': ['.pdf'],
              'text/plain': ['.txt'],
              'application/msword': ['.doc'],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                ['.docx'],
            }}
            subtitle={t('common.documents_uploader_subtitle')}
            maxFiles={10 - filesCount}
            isLoading={$uploadFile.isLoading}
            onUpload={files => {
              const uploadPromises = files.map(file =>
                $uploadFile.mutateAsync({ file }).then(({ fileId }) => ({
                  fileId,
                  name: file.name,
                })),
              )

              Promise.all(uploadPromises).then(uploadedFiles => {
                field.onChange([...(field.value || []), ...uploadedFiles])
              })
            }}
            onDelete={fileId => {
              const updatedFiles = field.value!.filter(
                file => file.fileId !== fileId,
              )

              field.onChange(updatedFiles)
            }}
            acceptedFileFormats={t(
              'common.document_uploader_supported_formats_with_mb',
              {
                size: '2 MB',
              },
            )}
          />
        )
      }}
    />
  )
}
