import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconUploadCloudOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 36 36">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2045 11.5795C17.6438 11.1402 18.3562 11.1402 18.7955 11.5795L23.2955 16.0795C23.7348 16.5188 23.7348 17.2312 23.2955 17.6705C22.8562 18.1098 22.1438 18.1098 21.7045 17.6705L19.125 15.091V23.625C19.125 24.2463 18.6213 24.75 18 24.75C17.3787 24.75 16.875 24.2463 16.875 23.625V15.091L14.2955 17.6705C13.8562 18.1098 13.1438 18.1098 12.7045 17.6705C12.2652 17.2312 12.2652 16.5188 12.7045 16.0795L17.2045 11.5795Z"
      fill="currentColor"
    />
    <path
      d="M9.91297 7.51909C11.9515 5.76103 14.7906 4.5 18 4.5C24.053 4.5 29.0774 8.99874 29.6228 14.8027C33.2057 15.3092 36 18.3073 36 21.9886C36 26.0314 32.6302 29.25 28.5469 29.25H8.50781C3.84196 29.25 0 25.5735 0 20.9659C0 16.999 2.84819 13.714 6.62001 12.8812C6.94079 10.9388 8.18966 9.00532 9.91297 7.51909ZM11.3824 9.22296C9.68019 10.691 8.78906 12.4612 8.78906 13.8477V14.8558L7.78698 14.966C4.64335 15.3115 2.25 17.8935 2.25 20.9659C2.25 24.2661 5.01884 27 8.50781 27H28.5469C31.4534 27 33.75 24.724 33.75 21.9886C33.75 19.2533 31.4534 16.9773 28.5469 16.9773H27.4219V15.8523C27.4219 10.8576 23.2364 6.75 18 6.75C15.3846 6.75 13.0557 7.77988 11.3824 9.22296Z"
      fill="currentColor"
    />
  </SvgIcon>
)
