import { TProfile } from 'api/common'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

import { TContactRow } from '../contacts.codecs'

const TTalentsPoolStatus = t.union([t.literal('PRIVATE'), t.literal('SHARED')])

export type TalentsPoolStatus = t.TypeOf<typeof TTalentsPoolStatus>

export const TPoolPermissionType = t.union([
  t.literal('VIEWER'), // can view
  t.literal('CONTRIBUTOR'), // can add only
  t.literal('COLLABORATOR'), // can edit
])
export type PoolPermissionType = t.TypeOf<typeof TPoolPermissionType>

export const TTalentsPoolSharedUsersList = t.array(
  t.strict({
    user: t.intersection([
      t.strict({
        profileId: t.string,
      }),
      t.partial({
        avatar: t.string,
      }),
    ]),
    role: TPoolPermissionType,
  }),
)

export const TTalentsPoolCreated = t.strict({
  talentPoolId: t.string,
})

export const TTalentsPool = t.intersection([
  t.strict({
    name: t.string,
    quantity: t.number,
    status: TTalentsPoolStatus,
    talentPoolId: t.string,
    createdAt: DateFromISOString,
    modifiable: t.boolean,
    enrichable: t.boolean,
    viewable: t.boolean,
    sharedUserRoles: TTalentsPoolSharedUsersList,
  }),
  t.partial({ globalPermissionRole: TPoolPermissionType }),
])

export type TalentsPool = t.TypeOf<typeof TTalentsPool>

export const TTalentsPoolsList = t.array(TTalentsPool)

export const TContactsTalentsPoolRow = t.intersection([
  TTalentsPool,
  t.partial({
    owner: TProfile,
  }),
])

export type ContactsTalentsPoolRow = t.TypeOf<typeof TContactsTalentsPoolRow>

export const TContactsTalentsPoolDetails = t.intersection([
  TTalentsPool,
  t.strict({
    contacts: t.array(TContactRow),
  }),
])

export type ContactsTalentsPoolDetails = t.TypeOf<
  typeof TContactsTalentsPoolDetails
>
