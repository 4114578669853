export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 bytes'
  if (bytes < 0 || decimals < 0) return null
  const marker = 1024
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const power = Math.floor(Math.log(bytes) / Math.log(marker))

  return `${Number.parseFloat(
    (bytes / Math.pow(marker, power)).toFixed(decimals),
  )} ${sizes[power]}`
}
