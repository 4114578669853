import { useEffect, useState } from 'react'
import {
  Droppable as DroppableOriginal,
  DroppableProps,
} from 'react-beautiful-dnd'

/**
 * https://github.com/atlassian/react-beautiful-dnd/issues/2399
 * react-beautiful-dnd doesn't work inside StrictMode of React 18
 */
export const Droppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true))

    return () => {
      cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])

  if (!enabled) {
    return null
  }

  return <DroppableOriginal {...props}>{children}</DroppableOriginal>
}
