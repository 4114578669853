import { TContactCompletenessStatuses } from 'api/contacts'
import * as t from 'io-ts'

import { TReportQualifierAmounts } from '../common'

export const TContactsByQualificationLevelReport = t.strict({
  contactsAmountByQualificationLevel: TReportQualifierAmounts,
  totalContactRequired: t.number,
  totalContactAdded: t.number,
})

export const TContactsAddedToTalentsPoolReport = t.strict({
  totalContactsAdded: t.number,
  position: TReportQualifierAmounts,
})

export const TRecruiterContactPerformanceReport = t.strict({
  totalContactsAdded: t.number,
  totalContactsRequired: t.number,
  contactsQuality: t.array(
    t.strict({
      qualifier: TContactCompletenessStatuses,
      amount: t.number,
      percentage: t.number,
    }),
  ),
})
