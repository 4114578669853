import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCheck = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 18 13"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M17 1L6 12L1 7"
      stroke="currentColor"
      strokeWidth={props.stroke ?? '1.4'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
