import { ListOrder } from 'api'
import { LocalStorageKey } from 'app/enums'
import { isEmpty } from 'fp-ts/Array'
import { usePagination } from 'lib/app-helpers'
import { ArrayParam, DateParam, StringParam } from 'lib/query-params'
import { useApplicableQueryState } from 'lib/react-utils'
import { useCallback, useMemo } from 'react'
import {
  ArrayParam as ArrayParamConfig,
  QueryParamConfig,
  withDefault,
} from 'use-query-params'

const StatusesParam = withDefault(ArrayParamConfig, [
  'OPEN',
]) as QueryParamConfig<Array<string>>

const useJobsFilters = () => {
  const pagination = usePagination(LocalStorageKey.JobsRowsPerPage)
  const { onChangePage } = pagination

  const statuses = useApplicableQueryState('statuses', StatusesParam, [])
  const departments = useApplicableQueryState('departments', ArrayParam)
  const projects = useApplicableQueryState('projects', ArrayParam)
  const recruiters = useApplicableQueryState('recruiters', ArrayParam)
  const skills = useApplicableQueryState('skills', ArrayParam)
  const dateFrom = useApplicableQueryState('from', DateParam)
  const dateTo = useApplicableQueryState('to', DateParam)

  const filters = useMemo(
    () => ({
      statuses,
      departments,
      projects,
      recruiters,
      skills,
      dateFrom,
      dateTo,
    }),
    [statuses, departments, projects, recruiters, skills, dateFrom, dateTo],
  )

  const isAppliedFiltersEmpty =
    isEmpty(statuses.appliedState) &&
    isEmpty(departments.appliedState) &&
    isEmpty(projects.appliedState) &&
    isEmpty(recruiters.appliedState) &&
    isEmpty(skills.appliedState) &&
    dateFrom.appliedState === null &&
    dateTo.appliedState === null

  const isSelectedFiltersEmpty =
    isEmpty(statuses.state) &&
    isEmpty(departments.state) &&
    isEmpty(projects.state) &&
    isEmpty(recruiters.state) &&
    isEmpty(skills.state) &&
    dateFrom.state === null &&
    dateTo.state === null

  const applyFilters = useCallback(() => {
    onChangePage(0)
    for (const filter of Object.values(filters)) filter.applyState()
  }, [onChangePage, filters])

  const resetFilters = useCallback(() => {
    onChangePage(0)
    for (const filter of Object.values(filters)) filter.clearState()
  }, [onChangePage, filters])

  const column = useApplicableQueryState('column', StringParam).state
  const direction = useApplicableQueryState('direction', StringParam).state as
    | 'asc'
    | 'desc'
  const order: ListOrder = { column, direction }

  return {
    filters,
    isAppliedFiltersEmpty,
    isSelectedFiltersEmpty,
    applyFilters,
    resetFilters,
    pagination,
    order,
  }
}

export { useJobsFilters }
