import { ListOrder } from 'api'
import { LocalStorageKey } from 'app/enums'
import { isEmpty } from 'fp-ts/Array'
import { usePagination } from 'lib/app-helpers'
import { ArrayParam, DateParam, StringParam } from 'lib/query-params'
import { useApplicableQueryState } from 'lib/react-utils'
import { useCallback, useMemo } from 'react'

const useJobRequestsFilters = () => {
  const pagination = usePagination(LocalStorageKey.JobsRowsPerPage)
  const { onChangePage } = pagination

  const statuses = useApplicableQueryState('statuses', ArrayParam)
  const positions = useApplicableQueryState('positions', ArrayParam)
  const offices = useApplicableQueryState('offices', ArrayParam)
  const departments = useApplicableQueryState('departments', ArrayParam)
  const projects = useApplicableQueryState('projects', ArrayParam)
  const createdBy = useApplicableQueryState('createdBy', ArrayParam)
  const dateFrom = useApplicableQueryState('from', DateParam)
  const dateTo = useApplicableQueryState('to', DateParam)

  const filters = useMemo(
    () => ({
      statuses,
      positions,
      offices,
      departments,
      projects,
      createdBy,
      dateFrom,
      dateTo,
    }),
    [
      statuses,
      positions,
      offices,
      departments,
      projects,
      createdBy,
      dateFrom,
      dateTo,
    ],
  )

  const isAppliedFiltersEmpty =
    isEmpty(statuses.appliedState) &&
    isEmpty(positions.appliedState) &&
    isEmpty(offices.appliedState) &&
    isEmpty(departments.appliedState) &&
    isEmpty(projects.appliedState) &&
    isEmpty(createdBy.appliedState) &&
    dateFrom.appliedState === null &&
    dateTo.appliedState === null

  const isSelectedFiltersEmpty =
    isEmpty(statuses.state) &&
    isEmpty(positions.state) &&
    isEmpty(offices.state) &&
    isEmpty(departments.state) &&
    isEmpty(projects.state) &&
    isEmpty(createdBy.state) &&
    dateFrom.state === null &&
    dateTo.state === null

  const applyFilters = useCallback(() => {
    onChangePage(0)
    for (const filter of Object.values(filters)) filter.applyState()
  }, [onChangePage, filters])

  const resetFilters = useCallback(() => {
    onChangePage(0)
    for (const filter of Object.values(filters)) filter.clearState()
  }, [onChangePage, filters])

  const column = useApplicableQueryState('column', StringParam).state
  const direction = useApplicableQueryState('direction', StringParam).state as
    | 'asc'
    | 'desc'
  const order: ListOrder = { column, direction }

  return {
    filters,
    isAppliedFiltersEmpty,
    isSelectedFiltersEmpty,
    applyFilters,
    resetFilters,
    pagination,
    order,
  }
}

export { useJobRequestsFilters }
