import {
  createListSearchParams,
  ListOrder,
  ListPagination,
} from 'api/list-params'
import { pipe } from 'fp-ts/lib/function'
import { getDateFromString, toServerDate } from 'lib/js-utils'
import {
  decodeBlob,
  decodeJson,
  decodeJsonWithTotal,
  get,
  post,
  put,
} from 'lib/request'

import {
  ImportContact,
  TContactMappingFields,
  TImportContractDetails,
  TImportHistoryRow,
  TImportSessionDetails,
  TImportSessionId,
} from './import.codecs'

export type ImportSessionId = {
  importSessionId: string
}

export type ImportContactsInput = {
  fileName: string
  contacts: ImportContact[]
}

/**
 * There are 2 cases in case of 200 status:
 * 1. Response array is empty => completely successful import
 * 2. Response array is not empty => import failed, the array contains all errors by line
 */
export const importContacts = async ({
  fileName,
  contacts,
}: ImportContactsInput) => {
  return pipe(
    await post('api/imports/contacts', {
      body: {
        fileName,
        contacts: contacts.map(contact => {
          const dateValue = getDateFromString(
            contact.birthDate?.toString() || '',
          )

          return {
            ...contact,
            birthDate: dateValue ? toServerDate(dateValue) : undefined,
          }
        }),
      },
    }),
    decodeJson(TImportSessionId),
  )
}

export type GetImportContractsInput = ImportSessionId & {
  pagination: ListPagination
  order?: ListOrder
}

export const getImportContracts = async ({
  importSessionId,
  pagination,
  order,
}: GetImportContractsInput) => {
  const params = createListSearchParams({ pagination, order })

  params.append('importSessionId', importSessionId)

  return pipe(
    await get(`api/imports/contacts`, {
      query: params,
    }),
    decodeJsonWithTotal(TImportContractDetails, pagination),
  )
}

export const getContactMappingFields = async () => {
  return pipe(
    await get('api/imports/fields', {
      query: new URLSearchParams({ type: 'CONTACT' }),
    }),
    decodeJson(TContactMappingFields),
  )
}

export const getContactImportTemplate = async () => {
  return pipe(
    await get('api/imports/templates', {
      query: new URLSearchParams({ type: 'CONTACT' }),
      type: 'file',
      headers: new Headers({
        'Content-Type': 'text/csv',
      }),
    }),
    decodeBlob,
  )
}

export type GetImportHistoryInput = {
  pagination: ListPagination
}

export const getImportHistory = async ({
  pagination,
}: GetImportHistoryInput) => {
  const params = createListSearchParams({ pagination })

  return pipe(
    await get('api/imports/sessions', {
      query: params,
    }),
    decodeJsonWithTotal(TImportHistoryRow, pagination),
  )
}

export type GetImportSessionInput = ImportSessionId

export const getImportSession = async ({
  importSessionId,
}: GetImportSessionInput) => {
  return pipe(
    await get(`api/imports/sessions/:importSessionId`, {
      params: { importSessionId },
    }),
    decodeJson(TImportSessionDetails),
  )
}

export const revertImport = async ({ importSessionId }: ImportSessionId) => {
  return await put(`api/imports/sessions/:importSessionId/revert`, {
    params: { importSessionId },
  })
}

export const cancelImport = async ({ importSessionId }: ImportSessionId) => {
  return await put(`api/imports/sessions/:importSessionId/cancel`, {
    params: { importSessionId },
  })
}
