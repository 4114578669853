import { SvgIconProps } from '@mui/material'
import {
  JobHistoryLogType,
  JobPerformingStatuses,
  JobPermissionType,
  JobPriority,
} from 'api'
import { ThemeColor } from 'app/mui-theme'
import {
  IconFreeze,
  IconPriorityHigh,
  IconPriorityLow,
  IconPriorityMedium,
} from 'assets/icons'

export const jobPriorities: Record<
  JobPriority,
  {
    Icon: (props: SvgIconProps) => JSX.Element
  }
> = {
  LOW: { Icon: IconPriorityLow },
  MEDIUM: { Icon: IconPriorityMedium },
  HIGH: { Icon: IconPriorityHigh },
  FREEZE: { Icon: IconFreeze },
}

export const jobPermissionTypes: Array<JobPermissionType> = [
  'COLLABORATOR',
  'VIEWER',
]

export const jobPermissionTypesForHiringManager: Array<JobPermissionType> = [
  'VIEWER',
]

export const jobPerformingStatuses: Array<JobPerformingStatuses> = [
  'TIMELY',
  'UPCOMING',
  'APPROACHING',
  'OVERDUE',
]

export const jobStagesColors: Array<{
  color: ThemeColor
  bgcolor: ThemeColor
}> = [
  { color: '#F1010A', bgcolor: '#FEE6E7' },
  { color: 'flushOrange.main', bgcolor: 'flushOrange[100]' },
  { color: '#FEA800', bgcolor: '#FFF6E6' },
  { color: 'success.main', bgcolor: '#E6F4F1' },
  { color: 'blue.main', bgcolor: '#E6F3FE' },
  { color: '#0043F0', bgcolor: '#E6ECFD' },
  { color: '#4D00F0', bgcolor: '#F1EBFD' },
]

export const jobStagesBackgroundLine: Array<{
  background: string
}> = [
  { background: 'linear-gradient(270deg, #BEBEBE 0%, #F4F4F4 100%)' },
  { background: 'linear-gradient(270deg, #FFE7A4 0%, #FFD7AE 100%)' },
  { background: 'linear-gradient(270deg, #E4F5FF 0%, #95D9FF 100%)' },
  { background: 'linear-gradient(270deg, #D08EF3 0%, #FFD7AE 100%)' },
  { background: 'linear-gradient(270deg, #DDE8E3 0%, #81E1B3 100%)' },
]

export const jobHistoryLogColors: Record<JobHistoryLogType, ThemeColor> = {
  JOB_CONTACT_APPLIED: '#E6F4F1',
  JOB_CONTACT_MOVED: '#E6F3FE',
  JOB_CONTACT_REJECTED: '#FEE6E7',
  JOB_CONTACT_HIRED: '#F1EBFD',
  JOB_SHARED: '#E6F4F1',
  JOB_UNSHARED: '#FEE6E7',
  JOB_STATUS_CHANGED: 'flushOrange[100]',
  JOB_CONTACT_RESTORED: '#E6ECFD',
  JOB_CREATED: '#FFF6E6',
  JOB_UPDATED: '#FFF6E6',
  JOB_PUBLISHED_BY_USER: '#E6ECFD',
  JOB_UNPUBLISHED_BY_USER: '#FFF6E6',
  JOB_UNPUBLISHED_BY_SYSTEM: '#F9FAFC',
}
