import { useMutation } from '@tanstack/react-query'
import { EmailTemplate, updateEmailTemplate } from 'api/email-templates'
import { IconPencil } from 'assets/icons'
import { useBoolean } from 'lib/react-utils'
import { showToast } from 'lib/toast'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'ui/inputs/icon-button'

import { EmailTemplateFormDialog } from './email-template-form-dialog'

type Props = Readonly<{
  emailTemplate: EmailTemplate
  onUpdate: () => void
}>

export const UpdateEmailTemplateIconButton = ({
  emailTemplate,

  onUpdate,
}: Props) => {
  const { t } = useTranslation()

  const showTemplateCreatingDialog = useBoolean()

  const $updateEmailTemplate = useMutation(updateEmailTemplate)

  return (
    <>
      <IconButton
        onClick={(e: Event) => {
          e.stopPropagation()
          showTemplateCreatingDialog.setTrue()
        }}
        sx={{
          color: 'greyBlue.main',
        }}
      >
        <IconPencil sx={{ fontSize: '16px' }} />
      </IconButton>

      <EmailTemplateFormDialog
        title={t('common.edit_email_template')}
        defaultValues={{
          name: emailTemplate.name,
          emailSubject: emailTemplate.emailSubject,
          emailBody: emailTemplate.emailBody,
        }}
        isOpen={showTemplateCreatingDialog.isTrue}
        onClose={showTemplateCreatingDialog.setFalse}
        onConfirm={({ values }) =>
          $updateEmailTemplate.mutate(
            { emailTemplateId: emailTemplate.id, ...values },
            {
              onSuccess: () => {
                showToast({ title: t('toasts.email_template_saved') })
                showTemplateCreatingDialog.setFalse()
                onUpdate()
              },
            },
          )
        }
        confirmCancelingText={t(
          'confirms.confirm_cancel_editing_email_template',
        )}
      />
    </>
  )
}
