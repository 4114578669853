import * as t from 'io-ts'

const TLanguageLevel = t.union([
  t.literal('BEGINNER'),
  t.literal('ELEMENTARY'),
  t.literal('PRE_INTERMEDIATE'),
  t.literal('INTERMEDIATE'),
  t.literal('UPPER_INTERMEDIATE'),
  t.literal('ADVANCED'),
  t.literal('NATIVE'),
])

export type LanguageLevel = t.TypeOf<typeof TLanguageLevel>

export const TLanguage = t.strict({
  language: t.string,
  languageId: t.string,
  levelCode: TLanguageLevel,
  order: t.number,
})

export type Language = t.TypeOf<typeof TLanguage>
