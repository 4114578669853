import { zodResolver } from '@hookform/resolvers/zod'
import { Stack } from '@mui/material'
import { ControlsLine } from 'components/common'
import { RouteLeavingGuard } from 'components/global'
import { getInputError } from 'lib/form-utils'
import { useBoolean } from 'lib/react-utils'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogContent } from 'ui/feedback'
import { Checkbox } from 'ui/inputs/checkbox'
import { FormControl } from 'ui/inputs/common'
import { MonthPicker } from 'ui/inputs/month-picker'
import { renderTextField } from 'ui/inputs/text-field'
import { YearPicker } from 'ui/inputs/year-picker'
import { z } from 'zod'

export const educationSchema = z
  .object({
    name: z.string().trim().min(1).min(2).max(300),
    startDate: z.object({
      month: z.optional(z.string().optional().or(z.literal(''))),
      year: z.optional(z.string().optional().or(z.literal(''))),
    }),
    endDate: z.object({
      month: z.optional(z.string().optional().or(z.literal(''))),
      year: z.optional(z.string().optional().or(z.literal(''))),
    }),
    relatedToPosition: z.optional(z.boolean()),
    degree: z.string().trim().min(2).max(300).optional().or(z.literal('')),
    fieldOfStudy: z
      .string()
      .trim()
      .min(2)
      .max(300)
      .optional()
      .or(z.literal('')),
  })
  .refine(
    ({ startDate, endDate }) => {
      if (!startDate.year || !endDate.year) return true
      return Number(endDate.year) >= Number(startDate.year)
    },
    {
      message: 'End year can’t be earlier than start year',
      path: ['endDate.year'],
    },
  )
  .refine(
    ({ startDate, endDate }) => {
      const wrongEndMonth =
        startDate.year &&
        startDate.month &&
        endDate.year &&
        endDate.month &&
        startDate.year === endDate.year &&
        Number(endDate.month) < Number(startDate.month)

      return !wrongEndMonth
    },
    {
      message: 'Select end month after start month',
      path: ['endDate.month'],
    },
  )

type FormValues = z.TypeOf<typeof educationSchema>
export type ContactEducationValues = FormValues

type Props = Readonly<{
  isOpen: boolean
  onClose: () => void
  onConfirm: (values: FormValues) => void
  defaultValues?: FormValues
  confirmButtonText: string
}>

export const EducationFormDialog = ({
  isOpen,
  onClose,
  onConfirm,
  defaultValues,
  confirmButtonText,
}: Props) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitSuccessful, dirtyFields },
  } = useForm<FormValues>({
    defaultValues: defaultValues ?? {
      name: '',
      startDate: {
        month: '',
        year: '',
      },
      endDate: {
        month: '',
        year: '',
      },
      relatedToPosition: false,
    },
    resolver: zodResolver(educationSchema),
  })

  const showCloseConfirmationDialog = useBoolean()

  const handleCloseDialog = () => {
    if (dirtyFields && isDirty) {
      showCloseConfirmationDialog.setTrue()
    } else {
      onClose()
    }
  }

  return (
    <>
      <Dialog open={isOpen} onClose={handleCloseDialog}>
        <DialogContent
          title={t('common.education')}
          onDeny={handleCloseDialog}
          onConfirm={() => {
            handleSubmit(values => {
              onConfirm(values)
              reset()
              onClose()
            })()
          }}
          wrapWithForm
          confirmText={confirmButtonText}
        >
          <Stack spacing={2}>
            <ControlsLine>
              <Controller
                name="name"
                control={control}
                render={renderTextField({
                  required: true,
                  label: t('common.name'),
                })}
              />
            </ControlsLine>

            <ControlsLine>
              <Controller
                name="degree"
                control={control}
                render={renderTextField({
                  label: t('common.degree'),
                })}
              />
            </ControlsLine>

            <ControlsLine>
              <Controller
                name="fieldOfStudy"
                control={control}
                render={renderTextField({
                  label: t('common.field_of_study'),
                })}
              />
            </ControlsLine>

            <FormControl label={t('common.start_date')}>
              <Stack spacing={3} direction="row" width="100%">
                <Controller
                  name={`startDate.month`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <MonthPicker
                      name={`startDate.month`}
                      value={field.value ?? ''}
                      onChange={field.onChange}
                      {...getInputError(fieldState.error)}
                      placeholder={t('common.select_month')}
                    />
                  )}
                />

                <Controller
                  name={`startDate.year`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <YearPicker
                      name={`startDate.year`}
                      value={field.value ?? ''}
                      startYear={new Date().getFullYear() - 100}
                      endYear={new Date().getFullYear()}
                      onChange={field.onChange}
                      {...getInputError(fieldState.error)}
                      placeholder={t('common.select_year')}
                    />
                  )}
                />
              </Stack>
            </FormControl>

            <FormControl label={t('common.end_date')}>
              <Stack spacing={3} direction="row" width="100%">
                <Controller
                  name={`endDate.month`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <MonthPicker
                      name={`endDate.month`}
                      value={field.value ?? ''}
                      onChange={field.onChange}
                      {...getInputError(fieldState.error)}
                      placeholder={t('common.select_month')}
                    />
                  )}
                />

                <Controller
                  name={`endDate.year`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <YearPicker
                      name={`endDate.year`}
                      startYear={new Date().getFullYear() - 100}
                      endYear={new Date().getFullYear()}
                      value={field.value ?? ''}
                      onChange={field.onChange}
                      {...getInputError(fieldState.error)}
                      placeholder={t('common.select_year')}
                    />
                  )}
                />
              </Stack>
            </FormControl>

            <ControlsLine>
              <Controller
                name="relatedToPosition"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    label={t('common.relevant_to_position')}
                    checked={field.value ?? false}
                    onChange={field.onChange}
                  />
                )}
              />
            </ControlsLine>
          </Stack>

          <RouteLeavingGuard
            when={isDirty && dirtyFields && !isSubmitSuccessful}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={showCloseConfirmationDialog.isTrue}
        onClose={showCloseConfirmationDialog.setFalse}
        size="small"
      >
        <DialogContent
          title={t('common.changes_wont_be_saved')}
          variant="danger"
          onDeny={showCloseConfirmationDialog.setFalse}
          onConfirm={() => {
            onClose()
            reset()
            showCloseConfirmationDialog.setFalse()
          }}
        >
          {t('confirms.cancel_education_editing')}
        </DialogContent>
      </Dialog>
    </>
  )
}
