import { UIEvent } from 'react'

export const checkScrollToBottom = (event: UIEvent, callback: () => void) => {
  const element = event.target as HTMLElement
  const distanceToBottom = Math.abs(
    element.scrollHeight - element.clientHeight - element.scrollTop,
  )

  if (distanceToBottom < 8) {
    callback()
  }
}
