import { paths } from '../paths'
import { buildRoute, Route } from './build-route'

export const chooseSubscriptionRoutes: Array<Route> = [
  /**
   * Temporarily removing the "Start 1st subscription" step from the Company Registration process.
   * Task: https://axondevgroup.atlassian.net/browse/AXT-4661
   *
   * According to the task, after entering the Company name, the user should be logged into the account,
   * and the default subscription should start, eliminating the need for the "Start 1st subscription" step.
   *
   * @comment The code below is commented out to reflect the temporary removal of the step.
   * Uncomment the code if the "Start 1st subscription" step needs to be reintroduced in the future.
   */
  // buildRoute({
  //   path: paths.startFirstSubscription,
  //   factory: () => import('pages/change-subscription/start-first-subscription'),
  // }),
  buildRoute({
    path: paths.startSubscription,
    factory: () => import('pages/change-subscription/start-subscription'),
  }),
  buildRoute({
    path: paths.chooseSubscription,
    factory: () => import('pages/change-subscription/choose-subscription'),
  }),
  buildRoute({
    path: paths.startPromotionSubscription,
    factory: () =>
      import('pages/change-subscription/start-promotion-subscription'),
  }),
]
