import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconOfficeOutline = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.93 0.101091C20.0278 0.161564 20.1085 0.246008 20.1645 0.346418C20.2205 0.446827 20.2499 0.559875 20.25 0.674841V20.9248C20.25 21.1039 20.1789 21.2756 20.0523 21.4021C19.9257 21.5287 19.754 21.5998 19.575 21.5998H15.525C15.346 21.5998 15.1743 21.5287 15.0477 21.4021C14.9211 21.2756 14.85 21.1039 14.85 20.9248V18.8998H13.5V20.9248C13.5 21.1039 13.4289 21.2756 13.3023 21.4021C13.1757 21.5287 13.004 21.5998 12.825 21.5998H0.675C0.495979 21.5998 0.32429 21.5287 0.197703 21.4021C0.0711159 21.2756 0 21.1039 0 20.9248V13.4998C0.000107523 13.3582 0.0447499 13.2202 0.127609 13.1054C0.210467 12.9906 0.327345 12.9047 0.4617 12.8599L8.1 10.3138V6.07484C8.1 5.94959 8.13486 5.82681 8.20066 5.72024C8.26646 5.61367 8.36062 5.5275 8.4726 5.47139L19.2726 0.0713913C19.3756 0.0198292 19.4901 -0.0045109 19.6052 0.000687798C19.7203 0.00588649 19.8321 0.0404509 19.93 0.101091ZM8.1 11.7367L1.35 13.9858V20.2498H8.1V11.7367ZM9.45 20.2498H12.15V18.2248C12.15 18.0458 12.2211 17.8741 12.3477 17.7475C12.4743 17.621 12.646 17.5498 12.825 17.5498H15.525C15.704 17.5498 15.8757 17.621 16.0023 17.7475C16.1289 17.8741 16.2 18.0458 16.2 18.2248V20.2498H18.9V1.76699L9.45 6.49199V20.2498Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8599 8.41865C10.8599 8.04586 11.1621 7.74365 11.5349 7.74365H16.8877C17.2605 7.74365 17.5627 8.04586 17.5627 8.41865C17.5627 8.79144 17.2605 9.09365 16.8877 9.09365H11.5349C11.1621 9.09365 10.8599 8.79144 10.8599 8.41865Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8599 11.5676C10.8599 11.1948 11.1621 10.8926 11.5349 10.8926H16.8877C17.2605 10.8926 17.5627 11.1948 17.5627 11.5676C17.5627 11.9404 17.2605 12.2426 16.8877 12.2426H11.5349C11.1621 12.2426 10.8599 11.9404 10.8599 11.5676Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.75049 15.2675C2.75049 14.8947 3.0527 14.5925 3.42549 14.5925L6.20005 14.5925C6.57284 14.5925 6.87505 14.8947 6.87505 15.2675C6.87505 15.6403 6.57284 15.9425 6.20005 15.9425L3.42549 15.9425C3.0527 15.9425 2.75049 15.6403 2.75049 15.2675Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8599 14.6289C10.8599 14.2561 11.1621 13.9539 11.5349 13.9539H16.8877C17.2605 13.9539 17.5627 14.2561 17.5627 14.6289C17.5627 15.0017 17.2605 15.3039 16.8877 15.3039H11.5349C11.1621 15.3039 10.8599 15.0017 10.8599 14.6289Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.75049 18.0087C2.75049 17.6359 3.0527 17.3337 3.42549 17.3337L6.20004 17.3337C6.57283 17.3337 6.87504 17.6359 6.87504 18.0087C6.87504 18.3815 6.57283 18.6837 6.20004 18.6837L3.42549 18.6837C3.0527 18.6837 2.75049 18.3815 2.75049 18.0087Z"
      fill="currentColor"
    />
  </SvgIcon>
)
