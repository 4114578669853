import { pipe } from 'fp-ts/function'
import { decodeJson, del, get, put } from 'lib/request'

import { TCompanyDepartmentsList } from '../company.codecs'
import { TDepartmentApprovers } from './approvers.codecs'

export type DepartmentId = {
  departmentId: string
}

export const updateDepartmentApprovers = async ({
  departmentId,
  approverIds,
}: DepartmentId & {
  approverIds: Array<string>
}) => {
  return await put('api/companies/me/departments/:departmentId/approvers', {
    params: { departmentId },
    body: { approverIds },
  })
}

export const getCompanyDepartmentsForApprover = async () => {
  return pipe(
    await get('api/companies/me/departments', {
      query: new URLSearchParams({ availableForApprovers: 'true' }),
    }),
    decodeJson(TCompanyDepartmentsList),
  )
}

export const getDepartmentApprovers = async () => {
  return pipe(
    await get('api/companies/me/departments/approvers', {}),
    decodeJson(TDepartmentApprovers),
  )
}

export const deleteDepartmentApprover = async ({
  departmentId,
}: DepartmentId) => {
  return await del('api/companies/me/departments/:departmentId/approvers', {
    params: { departmentId },
  })
}
