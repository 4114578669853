import XRegExp from 'xregexp'

export const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*([^\s\w]|_)).{8,90}$/

export const nameRegex = XRegExp("^[\\p{L}-., ']{2,125}$")

export const linkRegex =
  // eslint-disable-next-line security/detect-unsafe-regex
  /^(https?:\/\/)?([\da-z-]{1,64}\.){0,1}[\da-z-]{2,64}\.[\da-z-]{2,10}(\/[\w-]*)*\/?/i

export const phoneRegex = /^\+\d{7,15}$/

export const atLeastOneSymbolRegex = /\p{L}+/gu
