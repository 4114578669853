import { TAuthorizationResponse } from 'api/common'
import { env } from 'app/env'
import { pipe } from 'fp-ts/function'
import { decodeJson, get, patch, post } from 'lib/request'

import { UserId } from '../users'
import { TInvitationUser } from './confirmations.codec'

export const changePassword = async () => {
  return await post('auth/confirmations/passwords/change', {})
}

export const sendPasswordRecoveryEmail = async ({
  email,
}: {
  email: string
}) => {
  return await post('auth/confirmations/passwords/recovery', {
    query: new URLSearchParams({ email }),
  })
}

export const validateConfirmationRequest = async ({
  id,
  hash,
}: {
  id: string
  hash: string
}) => {
  return await get('auth/confirmations/:id/validate', {
    params: { id },
    query: new URLSearchParams({ hash }),
    withoutAuthHeader: true,
  })
}

export type GetUserByConfirmationRequestInput = {
  id: string
  hash: string
}

export const getUserByConfirmationRequest = async ({
  id,
  hash,
}: GetUserByConfirmationRequestInput) => {
  return pipe(
    await get('auth/confirmations/:id/user', {
      params: { id },
      query: new URLSearchParams({ hash }),
      withoutAuthHeader: true,
    }),
    decodeJson(TInvitationUser),
  )
}

export type ConfirmRegistrationInput = {
  id: string
  hash: string
  countryCode: string
  languageCode: string
  timeZone: string
  startWeekDay: string
}

export const confirmRegistration = async (input: ConfirmRegistrationInput) => {
  return pipe(
    await patch('auth/confirmations/:id/registration', {
      params: { id: input.id },
      query: new URLSearchParams({ hash: input.hash }),
      withoutAuthHeader: true,
      body: {
        clientId: env.REACT_APP_CLIENT_ID,
        countryCode: input.countryCode,
        languageCode: input.languageCode,
        zoneId: input.timeZone,
        startWeekDay: input.startWeekDay,
      },
    }),
    decodeJson(TAuthorizationResponse),
  )
}

export const finishPasswordRecovery = async (input: {
  id: string
  hash: string
  newPassword: string
}) => {
  return await patch('auth/confirmations/:id/password', {
    params: { id: input.id },
    query: new URLSearchParams({ hash: input.hash }),
    body: { newPassword: input.newPassword },
  })
}

export type ConfirmUserInvitationInput = {
  id: string
  hash: string
  fullName: string
  languageCode: string
  countryCode: string
  zoneId: string
  password: string
}

export const confirmUserInvitation = async ({
  id,
  hash,
  ...rest
}: ConfirmUserInvitationInput) => {
  return pipe(
    await patch('auth/confirmations/:id/invitation', {
      params: { id },
      query: new URLSearchParams({ hash }),
      body: {
        clientId: env.REACT_APP_CLIENT_ID,
        ...rest,
      },
    }),
    decodeJson(TAuthorizationResponse),
  )
}

export const resendUserInvitation = async ({ userId }: UserId) => {
  return await post(`auth/confirmations/users/${userId}/invitations`, {})
}
