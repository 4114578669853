import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconXCircle = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 22 22"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M14 8L8 14M8 8L14 14M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
      stroke="currentColor"
      strokeWidth={props.stroke ?? '1.4'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
