import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export const TCreatedPaymentMethod = t.strict({
  clientSecret: t.string,
  payload: t.string,
})

export const TPaymentMethods = t.array(
  t.strict({
    userSourceId: t.string,
    details: t.strict({
      brand: t.string,
      lastFour: t.string,
      expiryMonth: t.string,
      expiryYear: t.string,
    }),
    default: t.boolean,
    createdAt: DateFromISOString,
  }),
)

export type PaymentMethods = t.TypeOf<typeof TPaymentMethods>

export type PaymentMethod = PaymentMethods[0]
