import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconArrowRestore = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.624998 10C0.279821 10 -0.00202885 10.2806 0.0192206 10.6251C0.341898 15.8568 4.6871 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C7.23964 0 4.74051 1.11842 2.93103 2.92684L3.81465 3.81098C5.39794 2.22862 7.58469 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10C18.75 14.8325 14.8325 18.75 10 18.75C5.37769 18.75 1.59249 15.1658 1.27198 10.6251C1.24768 10.2808 0.970177 10 0.624998 10Z"
      fill="currentColor"
    />
    <path
      d="M1.74906 1.72242L4.93355 4.90719C5.16977 5.14343 5.00245 5.54734 4.66837 5.54734H1.48502C1.27797 5.54734 1.1101 5.37953 1.11002 5.17247L1.10889 1.9877C1.10877 1.65354 1.51279 1.48612 1.74906 1.72242Z"
      fill="currentColor"
    />
    <path
      d="M11.25 10C11.25 10.6904 10.6904 11.25 10 11.25C9.30964 11.25 8.75 10.6904 8.75 10C8.75 9.30964 9.30964 8.75 10 8.75C10.6904 8.75 11.25 9.30964 11.25 10Z"
      fill="currentColor"
    />
  </SvgIcon>
)
