import { CustomField, CustomFieldsList } from 'api'
import { FieldValues, Path } from 'react-hook-form'
import { renderAutocomplete } from 'ui/inputs/autocomplete'
import { renderDatePicker } from 'ui/inputs/date-picker/render-date-picker'
import { renderTextField } from 'ui/inputs/text-field'
import { z, ZodDate, ZodNullable, ZodNumber, ZodString } from 'zod'

/**
 * Renders a UI component based on the provided CustomField.
 *
 * @template FormValues - Type for form values.
 * @template FormPath - Type for form path.
 *
 * @param {CustomField} customField - Object representing a customizable field.
 * @returns {React.ReactElement} - UI component for displaying the corresponding form field.
 */
export const renderCustomField = <
  FormValues extends FieldValues,
  FormPath extends Path<FormValues>,
>(
  customField: CustomField,
) => {
  switch (customField.fieldType) {
    case 'TEXT': {
      return renderTextField<FormValues, FormPath>({
        label: customField.fieldName,
        required: customField.required,
      })
    }
    case 'NUMBER': {
      return renderTextField<FormValues, FormPath>({
        type: 'number',
        label: customField.fieldName,
        required: customField.required,
      })
    }
    case 'DATE': {
      return renderDatePicker<FormValues, FormPath>({
        label: customField.fieldName,
        required: customField.required,
        name: customField.fieldName,
      })
    }
    case 'SINGLE_CHOICE': {
      return renderAutocomplete<FormValues, FormPath>({
        label: customField.fieldName,
        required: customField.required,
        options:
          customField.valueDetails?.map(option => ({
            value: option,
            label: option,
          })) ?? [],
      })
    }
  }
}

type CustomFieldValueSchema =
  | ZodString
  | ZodNumber
  | ZodDate
  | ZodNullable<ZodNumber>
  | ZodNullable<ZodDate>

/**
 * Generates a Zod schema for validating customizable field values.
 *
 * @param {CustomFieldsList} customFields - List of customizable fields.
 * @returns {ZodObject} - Zod schema for validating customizable field values.
 */
export const createCustomFieldsSchema = (customFields: CustomFieldsList) => {
  return z.object(
    Object.fromEntries(
      customFields.map(field => {
        let schema: CustomFieldValueSchema = z.string()

        switch (field.fieldType) {
          case 'TEXT': {
            schema = z
              .string()
              .trim()
              .min(field.required ? 1 : 0)
              .max(250)
            break
          }
          case 'NUMBER': {
            const limit = Math.pow(10, 32)
            const number = z.number().gt(-limit).lt(limit)
            schema = field.required ? number : number.nullable()
            break
          }
          case 'DATE': {
            const date = z.date()
            schema = field.required ? date : date.nullable()
            break
          }
          case 'SINGLE_CHOICE': {
            schema = z.string().min(field.required ? 1 : 0)
          }
        }

        return [field.fieldName, schema]
      }),
    ),
  )
}
