import { Language } from 'api'
import { LanguageBadge } from 'components/languages'
import { DetailsItemContainer } from 'components/templates/details-template'
import { useTranslation } from 'react-i18next'
import { BadgeCollection } from 'ui/data'

type Props = Readonly<{
  languages: Array<Language>
}>

export const ContactLanguagesSection = ({ languages }: Props) => {
  const { t } = useTranslation()

  if (languages.length === 0) return null

  return (
    <DetailsItemContainer title={t('common.languages')}>
      <BadgeCollection>
        {languages.map(language => (
          <LanguageBadge
            key={language.languageId}
            name={language.language}
            levelCode={language.levelCode}
          />
        ))}
      </BadgeCollection>
    </DetailsItemContainer>
  )
}
