import { paths } from 'app/routes'
import { useScopes } from 'app/scopes'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export type IntroductionTourHighlightId =
  | 'GENERAL_SETTINGS'
  | 'CONFIGURATION'
  | 'PIPELINE_TEMPLATES'
  | 'SUBSCRIPTIONS'
  | 'CONTACTS_ADDITION'
  | 'WORKLOAD'
  | 'JOB_CONFIGURATION'
  | 'SET_OF_SKILLS'
  | 'CONTACTS'
  | 'JOBS'
  | 'ACTIVITIES'
  | 'REPORTS'
  | 'TEAM_DASHBOARD'

export type IntroductionTourHighlight = {
  highlightId: IntroductionTourHighlightId
  message: string
  route: string
}

export type IntroductionTourStep = {
  title: string
  message: React.ReactNode
  route: string
  /** If stepName is undefined, exclude this step from stepper */
  stepName?: string
  highlights: Array<IntroductionTourHighlight>
}

export const useIntroductionTourSteps = ({
  skipSettings,
}: {
  skipSettings?: boolean
} = {}) => {
  const { t } = useTranslation()
  const scopes = useScopes()

  return useMemo(() => {
    const scopedSteps: Array<IntroductionTourStep> = [
      {
        title: t('common.welcome_to_axterior'),
        message: t('introduction_tour.welcome_message'),
        route: paths.settingsGeneral,
        highlights: [],
      },
    ]

    if (!skipSettings) {
      const settingsStep: IntroductionTourStep = {
        title: t('introduction_tour.settings.title'),
        message: t('introduction_tour.settings.message'),
        route: paths.settings,
        stepName: t('introduction_tour.settings.step_name'),
        highlights: [
          {
            highlightId: 'GENERAL_SETTINGS',
            message: t('introduction_tour.settings.company_settings'),
            route: paths.settingsGeneral,
          },
        ],
      }

      if (scopes.canViewCompanyConfiguration) {
        settingsStep.highlights.push({
          highlightId: 'PIPELINE_TEMPLATES',
          message: t('introduction_tour.settings.pipeline_templates'),
          route: paths.settingsPipelineTemplates,
        })
      }

      if (scopes.canViewSubscriptions) {
        settingsStep.highlights.push({
          highlightId: 'SUBSCRIPTIONS',
          message: t('introduction_tour.settings.subscriptions'),
          route: paths.settingsSubscriptions,
        })
      }

      if (scopes.canViewCompanyConfiguration) {
        settingsStep.highlights.push({
          highlightId: 'CONTACTS_ADDITION',
          message: t('introduction_tour.settings.kpi_settings'),
          route: paths.settingsContactsAddition,
        })
      }

      settingsStep.highlights.push({
        highlightId: 'JOB_CONFIGURATION',
        message: t('introduction_tour.settings.job_settings'),
        route: paths.settingsJob,
      })

      if (scopes.canViewCompanyConfiguration) {
        settingsStep.highlights.push({
          highlightId: 'SET_OF_SKILLS',
          message: t('introduction_tour.settings.set_of_skills'),
          route: paths.settingsSetOfSkills,
        })
      }

      scopedSteps.push(settingsStep)
    }

    scopedSteps.push({
      title: t('introduction_tour.team_members.title'),
      message: t('introduction_tour.team_members.message'),
      route: paths.settingsUsersAndPermissions,
      highlights: [],
      stepName: t('introduction_tour.team_members.title'),
    })

    const systemStep: IntroductionTourStep = {
      title: t('introduction_tour.system_overview.title'),
      message: t('introduction_tour.system_overview.message'),
      route: paths.contacts,
      stepName: t('introduction_tour.system_overview.title'),
      highlights: [],
    }

    if (scopes.canViewContacts) {
      systemStep.highlights.push({
        highlightId: 'CONTACTS',
        message: t('introduction_tour.system_overview.contacts'),
        route: paths.contacts,
      })
    }

    if (scopes.canViewJobs) {
      systemStep.highlights.push({
        highlightId: 'JOBS',
        message: t('introduction_tour.system_overview.jobs'),
        route: paths.jobs,
      })
    }

    if (scopes.canViewActivities) {
      systemStep.highlights.push({
        highlightId: 'ACTIVITIES',
        message: t('introduction_tour.system_overview.activities'),
        route: paths.activities,
      })
    }

    if (scopes.canViewJobRequests) {
      systemStep.highlights.push({
        highlightId: 'REPORTS',
        message: t('introduction_tour.system_overview.reports'),
        route: paths.recruiterPerformanceReport,
      })
    }

    if (scopes.canViewDashboardTeam) {
      systemStep.highlights.push({
        highlightId: 'TEAM_DASHBOARD',
        message: t('introduction_tour.system_overview.team_dashboard'),
        route: paths.teamsDashboard,
      })
    }

    if (systemStep.highlights.length > 0) {
      scopedSteps.push(systemStep)
    }

    scopedSteps.push({
      title: t('introduction_tour.conclusion.title'),
      message: t('introduction_tour.conclusion.message'),
      route: paths.contacts,
      highlights: [],
    })

    return scopedSteps
  }, [t, scopes, skipSettings])
}
