import {
  createListSearchParams,
  ListOrder,
  ListPagination,
} from 'api/list-params'
import { pipe } from 'fp-ts/lib/function'
import {
  decodeJson,
  decodeJsonWithTotal,
  del,
  get,
  post,
  put,
} from 'lib/request'

import { TEmailTemplate } from './email-templates.codecs'

export type EmailTemplatesListInput = {
  order: ListOrder
  pagination: ListPagination
}

export const getEmailTemplateListRequest = async ({
  order,
  pagination,
}: EmailTemplatesListInput) => {
  const params = createListSearchParams({ pagination, order })

  return pipe(
    await get('api/companies/me/email-templates', {
      query: params,
    }),
    decodeJsonWithTotal(TEmailTemplate, pagination),
  )
}

export type EmailTemplateInput = {
  templateId: string
}

export const getEmailTemplateRequest = async ({
  templateId,
}: EmailTemplateInput) => {
  return pipe(
    await get(`api/companies/me/email-templates/:templateId`, {
      params: { templateId },
    }),
    decodeJson(TEmailTemplate),
  )
}

type EmailTemplateBodyInput = {
  name: string
  emailSubject: string
  emailBody: string
}

export const createEmailTemplate = async (
  emailTemplateBody: EmailTemplateBodyInput,
) => {
  return await post(`api/companies/me/email-templates`, {
    body: emailTemplateBody,
  })
}

type EmailTemplateId = {
  emailTemplateId: string
}

export const updateEmailTemplate = async ({
  emailTemplateId,
  ...emailTemplateBody
}: EmailTemplateBodyInput & EmailTemplateId) => {
  return await put(`api/companies/me/email-templates/:emailTemplateId`, {
    params: { emailTemplateId },
    body: emailTemplateBody,
  })
}

export const deleteEmailTemplate = async ({
  emailTemplateId,
}: EmailTemplateId) => {
  return await del(`api/companies/me/email-templates/:emailTemplateId`, {
    params: { emailTemplateId },
  })
}
