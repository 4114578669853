import {
  createListSearchParams,
  ListOrder,
  ListPagination,
} from 'api/list-params'
import { pipe } from 'fp-ts/function'
import { decodeJson, decodeJsonWithTotal, get, patch } from 'lib/request'

import {
  NotificationCriticality,
  TNotification,
  TNotificationsCount,
} from './notifications.codecs'

export type GetMyNotificationsInput = {
  read?: boolean
  criticalities?: Array<NotificationCriticality>
  pagination: ListPagination
  order: ListOrder
}

export const getMyNotifications = async ({
  read,
  criticalities,
  pagination,
  order,
}: GetMyNotificationsInput) => {
  const params = createListSearchParams({ pagination })

  if (read !== undefined) {
    params.set('read', read.toString())
  }

  if (order) {
    params.set('sort', order.column.toUpperCase())
    params.set('order', order.direction.toUpperCase())
  }

  if (criticalities !== undefined) {
    for (const criticality of criticalities) {
      params.append('criticality', criticality)
    }
  }

  return pipe(
    await get('messaging/notifications/me', {
      query: params,
      headers: new Headers({
        Locale: 'EN',
      }),
    }),
    decodeJsonWithTotal(TNotification, pagination),
  )
}

export const getNotificationsCount = async () => {
  return pipe(
    await get('messaging/notifications/me/count', {}),
    decodeJson(TNotificationsCount),
  )
}

export const readAllNotifications = async () => {
  return await patch('messaging/notifications/read/all', {})
}

export type ReadNotificationsInput = {
  notificationIds: Array<string>
}

export const readNotifications = async ({
  notificationIds,
}: ReadNotificationsInput) => {
  return await patch('messaging/notifications/read', {
    body: {
      notificationIds,
    },
  })
}

export type UnreadNotificationsInput = {
  notificationIds: Array<string>
}

export const unreadNotifications = async ({
  notificationIds,
}: UnreadNotificationsInput) => {
  return await patch('messaging/notifications/unread', {
    body: {
      notificationIds,
    },
  })
}
