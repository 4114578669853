import { UseQueryResult } from '@tanstack/react-query'
import { ErrorTemplateSmall } from 'components/templates'
import { TableSkeleton } from 'ui/data'
import { CircularProgress } from 'ui/feedback'

import { Widget, WidgetProps } from './widget'
import { WidgetEmptyContent } from './widget-empty-content'

type Props<QueryData extends any> = Readonly<
  Omit<WidgetProps, 'children'> & {
    query: UseQueryResult<QueryData>
    children: (data: QueryData) => React.ReactNode
    loadingTableSkeleton?: boolean
  }
>

export const WidgetQuery = <QueryData extends any>({
  query,
  children,
  loadingTableSkeleton,
  ...widgetProps
}: Props<QueryData>) => {
  const renderContent = () => {
    if (query.isLoading) {
      return loadingTableSkeleton ? (
        <TableSkeleton noBorder columnsCount={4} rowsCount={5} />
      ) : (
        <WidgetEmptyContent fullHeight={widgetProps.minHeight !== undefined}>
          <CircularProgress />
        </WidgetEmptyContent>
      )
    }

    if (query.isError) {
      return (
        <WidgetEmptyContent fullHeight={widgetProps.minHeight !== undefined}>
          <ErrorTemplateSmall error={query.error} />
        </WidgetEmptyContent>
      )
    }

    if (query.data) {
      return children(query.data)
    }

    return null
  }

  return <Widget {...widgetProps}>{renderContent()}</Widget>
}
