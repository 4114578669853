import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconLevelsLowest = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 14"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.09982 6.75205C4.02925 6.78132 3.96515 6.82422 3.91119 6.8783C3.85715 6.93169 3.81424 6.99527 3.78496 7.06537C3.75568 7.13546 3.7406 7.21067 3.7406 7.28663C3.7406 7.3626 3.75568 7.43781 3.78496 7.5079C3.81424 7.57799 3.85715 7.64158 3.91119 7.69497L6.58869 10.3725C6.81619 10.6 7.18369 10.6 7.41119 10.3725L10.0887 7.69497C10.1427 7.64096 10.1855 7.57685 10.2148 7.50628C10.244 7.43572 10.259 7.36009 10.259 7.28372C10.259 7.20734 10.244 7.13171 10.2148 7.06115C10.1855 6.99059 10.1427 6.92647 10.0887 6.87247C10.0347 6.81846 9.97057 6.77562 9.9 6.74639C9.82944 6.71716 9.75381 6.70212 9.67744 6.70212C9.60106 6.70212 9.52543 6.71716 9.45487 6.74639C9.38431 6.77562 9.32019 6.81846 9.26619 6.87247L6.99702 9.1358L4.73369 6.8783C4.67972 6.82422 4.61562 6.78132 4.54505 6.75205C4.47448 6.72277 4.39884 6.70771 4.32244 6.70771C4.24604 6.70771 4.17039 6.72277 4.09982 6.75205ZM4.09982 3.54999C4.02925 3.57927 3.96515 3.62217 3.91119 3.67625C3.85715 3.72963 3.81424 3.79322 3.78496 3.86331C3.75568 3.93341 3.7406 4.00862 3.7406 4.08458C3.7406 4.16054 3.75568 4.23575 3.78496 4.30585C3.81424 4.37594 3.85715 4.43952 3.91119 4.49291L6.58869 7.17041C6.81619 7.39791 7.18369 7.39791 7.41119 7.17041L10.0887 4.49291C10.1427 4.43891 10.1855 4.37479 10.2148 4.30423C10.244 4.23367 10.259 4.15804 10.259 4.08166C10.259 4.00529 10.244 3.92966 10.2148 3.8591C10.1855 3.78853 10.1427 3.72442 10.0887 3.67041C10.0347 3.61641 9.97057 3.57357 9.9 3.54434C9.82944 3.51511 9.75381 3.50007 9.67744 3.50007C9.60106 3.50007 9.52543 3.51511 9.45487 3.54434C9.38431 3.57357 9.32019 3.61641 9.26619 3.67041L6.99702 5.93375L4.73369 3.67625C4.67972 3.62217 4.61562 3.57927 4.54505 3.54999C4.47448 3.52072 4.39884 3.50565 4.32244 3.50565C4.24604 3.50565 4.17039 3.52072 4.09982 3.54999Z"
      fill="currentColor"
    />
  </SvgIcon>
)
