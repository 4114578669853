import { Drawer, drawerClasses, styled } from '@mui/material'

export const StyledDrawer = styled(Drawer)(({ theme, open = false }) => {
  const drawerStyles = {
    width: open ? 232 : 58,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: open
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen,
    }),
  }

  return {
    flex: 'none',
    ...drawerStyles,
    [`& .${drawerClasses.paper}`]: {
      ...drawerStyles,
      borderTop: 0,
      borderBottom: 0,
      overflow: 'initial',
    },
  }
})
