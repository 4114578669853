import { Box } from '@mui/material'
import { UseQueryResult } from '@tanstack/react-query'
import { ErrorTemplate } from 'components/templates'
import { CircularProgress } from 'ui/feedback'

/**
 * Renders a default loading component with a centered circular progress indicator.
 * @returns {JSX.Element} - The default loading component.
 */
export const renderDefaultLoading = (): JSX.Element => (
  <Box height={200} position="relative">
    <CircularProgress centered />
  </Box>
)

/**
 * Renders a default error component with the given error information.
 * @param {unknown} error - The error information.
 * @returns {JSX.Element} - The default error component.
 */
export const renderDefaultError = (error: unknown): JSX.Element => (
  <Box p={5}>
    <ErrorTemplate error={error} />
  </Box>
)

/**
 * An utility function that is often used to render JSX for different query result states.
 * @template T - The type of the query result data.
 * @param {UseQueryResult<T, unknown>} query - The query result returned from the `useQuery` call.
 * @param {{
 *   success: (data: T) => JSX.Element | null;
 *   loading?: () => JSX.Element | null;
 *   error?: (error: unknown) => JSX.Element | null;
 *   fetching?: () => JSX.Element | null;
 * }} options - Render props functions to render common query states.
 * @returns {JSX.Element | null} - An appropriate query state or `null` otherwise.
 */
export const renderQueryResult = <T extends any>(
  query: UseQueryResult<T, unknown>,
  {
    success,
    loading = renderDefaultLoading,
    error = renderDefaultError,
    fetching,
  }: {
    success: (data: T) => JSX.Element | null
    loading?: () => JSX.Element | null
    error?: (error: unknown) => JSX.Element | null
    fetching?: () => JSX.Element | null
  },
) => {
  if (query.isLoading && loading) {
    return loading()
  }

  if (query.isFetching && fetching) {
    return fetching()
  }

  if (query.isError && error) {
    return error(query.error)
  }

  if (query.isSuccess) {
    return success(query.data)
  }

  return null
}

export const renderAllQueriesResult = <
  Queries extends Array<UseQueryResult<any>>,
>(
  queries: Queries & { readonly 0: UseQueryResult<any> },
  {
    success,
    loading = renderDefaultLoading,
    error = renderDefaultError,
    fetching,
  }: {
    success: (data: {
      [Index in keyof Queries]: Queries[Index] extends UseQueryResult<
        infer Data,
        unknown
      >
        ? Data
        : never
    }) => JSX.Element | null
    loading?: () => JSX.Element | null
    error?: (error: unknown) => JSX.Element | null
    fetching?: () => JSX.Element | null
  },
) => {
  if (queries.some(query => query.isLoading) && loading) {
    return loading()
  }

  if (queries.some(query => query.isFetching) && fetching) {
    return fetching()
  }

  const queryWithError = queries.find(query => query.isError)

  if (queryWithError && error) {
    return error(queryWithError.error)
  }

  if (queries.every(query => query.isSuccess)) {
    return success(queries.map(query => query.data) as any)
  }

  return null
}
