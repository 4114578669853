import { globalAccessToken } from 'app/auth'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { ReactElement, useEffect, useState } from 'react'
import { flushSync } from 'react-dom'

/**
 * Custom hook to track the progress of an import session.
 *
 * This hook opens an EventSource connection to get real-time updates on the progress of
 * an import session and returns a function to render appropriate components based on the session's state.
 *
 * @param {string} importSessionId - ID of the import session to track.
 * @returns {Function} A function that accepts a set of renderers and returns a JSX element
 *                     based on the import session's state.
 *
 * @example
 * const renderImportProgress = useRenderImportSession('someImportSessionId');
 *
 * return renderImportProgress({
 *   loading: (percentage) => <div>Progress: {percentage}%</div>,
 *   error: () => <div>Error occurred</div>,
 *   success: () => <div>Import Successful!</div>
 * });
 *
 */
export const useRenderImportSession = (importSessionId: string) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [percentage, setPercentage] = useState<number>(0)

  useEffect(() => {
    // Establish the EventSource connection.
    const eventSource = new EventSourcePolyfill(
      `/api/imports/sessions/${importSessionId}/progress`,
      {
        headers: {
          Authorization: `Bearer ${globalAccessToken}`,
          Locale: 'EN',
        },
      },
    )

    // Handle incoming messages from the EventSource.
    // eslint-disable-next-line unicorn/prefer-add-event-listener
    eventSource.onmessage = event => {
      try {
        const data = JSON.parse(event.data)

        // Update the progress percentage if the session is in progress.
        if (data.importSessionStatus === 'IN_PROGRESS') {
          flushSync(() => {
            setPercentage(data.importProgressPercentage)
          })
        } else if (
          data.importSessionStatus === 'FINISHED' ||
          data.importSessionStatus === 'FAILED'
        ) {
          // Set the loading state to false and close the connection if the session is finished.
          setIsLoading(false)
          eventSource.close()
        }
      } catch {
        // Handle any parsing errors or unexpected message formats.
        setIsError(true)
        eventSource.close()
      }
    }

    // Add an error handler to the EventSource instance.
    // eslint-disable-next-line unicorn/prefer-add-event-listener
    eventSource.onerror = () => {
      setIsError(true)
      eventSource.close()
    }

    return () => {
      // Cleanup: Close the EventSource connection when the component unmounts.
      eventSource.close()
    }
  }, [importSessionId])

  return (renderers: {
    loading: (percentage: number) => ReactElement
    error: () => ReactElement
    success: () => ReactElement
  }) => {
    if (isError) {
      return renderers.error()
    }
    if (isLoading) {
      return renderers.loading(percentage)
    }
    if (!isLoading && !isError) {
      return renderers.success()
    }

    return null
  }
}
