import { PaymentMethodId } from 'api/finance'
import { createListSearchParams } from 'api/list-params'
import { pipe } from 'fp-ts/lib/function'
import {
  decodeJson,
  decodeJsonWithTotal,
  del,
  get,
  patch,
  post,
} from 'lib/request'

import {
  TCurrentSubscription,
  TSubscription,
  TSubscriptionChangePrice,
  TSubscriptionWithTrial,
} from './subscriptions.codecs'

export const getCurrentCompanySubscription = async () => {
  return pipe(
    await get('subscription/subscriptions/companies/me', {}),
    response => {
      // When response is 204 No Content it means that company has no active subscription
      // Owner can not use the system in this case, but choose the subscription first
      // When response is empty we should omit decoding json, the response should remain successful
      if (response.status === 204) return null
      return decodeJson(TCurrentSubscription)(response)
    },
  )
}

export const activateFirstCompanySubscription = async () => {
  return await post('subscription/subscriptions/defaults/companies/me', {})
}

export const getAvailableSubscriptions = async () => {
  const pagination = { page: 0, pageSize: 50 }
  const params = createListSearchParams({ pagination })

  return pipe(
    await get('subscription/subscriptions/me', {
      query: params,
    }),
    decodeJsonWithTotal(TSubscription, pagination),
  )
}

export const getDefaultSubscription = async () => {
  return pipe(
    await get('subscription/subscriptions/startup', {}),
    decodeJson(TSubscriptionWithTrial),
  )
}

export type SubscriptionId = {
  subscriptionId: string
}

export const updateCompanySubscription = async ({
  subscriptionId,
}: SubscriptionId) => {
  return await patch(
    'subscription/subscriptions/:subscriptionId/companies/me',
    {
      params: {
        subscriptionId,
      },
    },
  )
}

export const cancelSubscription = async () => {
  return await del('subscription/subscriptions/companies/me', {})
}

export const reactivateSubscription = async () => {
  return await patch('subscription/subscriptions/companies/me', {})
}

export const inviteNewUser = async (input: {
  email: string
  fullName: string
  role: string
}) => {
  return await post('subscription/companies/me/seats', {
    body: input,
  })
}

/** Used when the company is frozen and Owner must select new subscription and immediately pay for it */
export const activateSubscriptionToUnfreezeCompany = async ({
  subscriptionId,
  paymentMethodId,
}: SubscriptionId & PaymentMethodId) => {
  return pipe(
    await post(
      'subscription/subscriptions/:subscriptionId/companies/me/payment',
      {
        params: { subscriptionId },
        body: { paymentMethodId },
      },
    ),
    // Successful response is 204 No Content
    response => response.status === 204,
  )
}

export type SubscriptionChangePriceInput = SubscriptionId

export const calculateSubscriptionChangePrice = async ({
  subscriptionId,
}: SubscriptionChangePriceInput) => {
  return pipe(
    await get('subscription/subscriptions/:subscriptionId/companies/me/price', {
      params: { subscriptionId },
    }),
    decodeJson(TSubscriptionChangePrice),
  )
}
