import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCloseCircleOutlineLarge = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g clipPath="url(#clip0_6359_56086)">
      <path
        d="M12 22.5C9.21523 22.5 6.54451 21.3938 4.57538 19.4246C2.60625 17.4555 1.5 14.7848 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
        fill="currentColor"
      />
      <path
        d="M6.97034 6.97034C7.04001 6.9005 7.12277 6.84508 7.21389 6.80727C7.30501 6.76946 7.40269 6.75 7.50134 6.75C7.59999 6.75 7.69767 6.76946 7.78879 6.80727C7.87991 6.84508 7.96267 6.9005 8.03234 6.97034L12.0013 10.9408L15.9703 6.97034C16.0401 6.90061 16.1229 6.84529 16.214 6.80756C16.3051 6.76982 16.4027 6.75039 16.5013 6.75039C16.6 6.75039 16.6976 6.76982 16.7887 6.80756C16.8798 6.84529 16.9626 6.90061 17.0323 6.97034C17.1021 7.04007 17.1574 7.12286 17.1951 7.21396C17.2329 7.30507 17.2523 7.40272 17.2523 7.50134C17.2523 7.59996 17.2329 7.69761 17.1951 7.78872C17.1574 7.87982 17.1021 7.96261 17.0323 8.03234L13.0618 12.0013L17.0323 15.9703C17.1021 16.0401 17.1574 16.1229 17.1951 16.214C17.2329 16.3051 17.2523 16.4027 17.2523 16.5013C17.2523 16.6 17.2329 16.6976 17.1951 16.7887C17.1574 16.8798 17.1021 16.9626 17.0323 17.0323C16.9626 17.1021 16.8798 17.1574 16.7887 17.1951C16.6976 17.2329 16.6 17.2523 16.5013 17.2523C16.4027 17.2523 16.3051 17.2329 16.214 17.1951C16.1229 17.1574 16.0401 17.1021 15.9703 17.0323L12.0013 13.0618L8.03234 17.0323C7.96261 17.1021 7.87982 17.1574 7.78872 17.1951C7.69761 17.2329 7.59996 17.2523 7.50134 17.2523C7.40272 17.2523 7.30507 17.2329 7.21396 17.1951C7.12286 17.1574 7.04007 17.1021 6.97034 17.0323C6.90061 16.9626 6.84529 16.8798 6.80756 16.7887C6.76982 16.6976 6.75039 16.6 6.75039 16.5013C6.75039 16.4027 6.76982 16.3051 6.80756 16.214C6.84529 16.1229 6.90061 16.0401 6.97034 15.9703L10.9408 12.0013L6.97034 8.03234C6.9005 7.96267 6.84508 7.87991 6.80727 7.78879C6.76946 7.69767 6.75 7.59999 6.75 7.50134C6.75 7.40269 6.76946 7.30501 6.80727 7.21389C6.84508 7.12277 6.9005 7.04001 6.97034 6.97034Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6359_56086">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
