import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import { InfiniteQueryKey, ListPagination } from 'api'
import { JsonArrayWithTotal } from 'lib/request'

/**
 * A custom hook for using infinite paginated queries with react-query.
 *
 * @template TData - The type of the data in the paginated list.
 * @template TResult - The type of the result, including the paginated list.
 * @template TInput - The type of the input parameters for the query.
 * @param {object} queryKey - An object with a `toKeyWithArgs` function that generates a unique query key.
 * @param {function} queryFunction - The function to fetch data for the query.
 * @param {TInput} input - The input parameters for the query, including pagination.
 * @param {UseInfiniteQueryOptions<TResult>} [queryOptions] - Additional options for the query.
 * @returns {UseInfiniteQueryResult<TResult>} - The result of the infinite query.
 */
export const useInfinitePaginatedQuery = <
  TData extends unknown,
  TResult extends JsonArrayWithTotal<TData>,
  TInput extends { pagination: ListPagination },
>(
  queryKey: {
    toKeyWithArgs: (options: TInput & InfiniteQueryKey) => QueryKey
  },
  queryFunction: (input: TInput) => Promise<TResult>,
  input: TInput,
  queryOptions?: UseInfiniteQueryOptions<TResult>,
) => {
  return useInfiniteQuery(
    queryKey.toKeyWithArgs({ infinite: true, ...input }),
    ({ pageParam = 0 }) =>
      queryFunction({
        ...input,
        pagination: {
          pageSize: input.pagination.pageSize,
          page: pageParam,
        },
      }),
    {
      keepPreviousData: true,
      ...queryOptions,
      getNextPageParam: lastPage => lastPage.nextPage,
    },
  )
}
