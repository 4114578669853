import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconProjectOutline = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip0_2778_27228)">
      <path
        d="M12.3526 2.33849C12.2441 2.28071 12.123 2.25049 12.0001 2.25049C11.8771 2.25049 11.7561 2.28071 11.6476 2.33849L0.397575 8.33849C0.277766 8.4025 0.177592 8.49785 0.107747 8.61436C0.0379033 8.73086 0.00101172 8.86415 0.00101172 8.99999C0.00101172 9.13583 0.0379033 9.26911 0.107747 9.38562C0.177592 9.50213 0.277766 9.59748 0.397575 9.66149L4.78207 12L0.396075 14.3385C0.276266 14.4025 0.176092 14.4979 0.106247 14.6144C0.0364033 14.7309 -0.000488281 14.8642 -0.000488281 15C-0.000488281 15.1358 0.0364033 15.2691 0.106247 15.3856C0.176092 15.5021 0.276266 15.5975 0.396075 15.6615L11.6461 21.6615C11.7546 21.7193 11.8756 21.7495 11.9986 21.7495C12.1215 21.7495 12.2426 21.7193 12.3511 21.6615L23.6011 15.6615C23.7209 15.5975 23.8211 15.5021 23.8909 15.3856C23.9607 15.2691 23.9976 15.1358 23.9976 15C23.9976 14.8642 23.9607 14.7309 23.8909 14.6144C23.8211 14.4979 23.7209 14.4025 23.6011 14.3385L19.2196 12L23.6026 9.66149C23.7224 9.59748 23.8226 9.50213 23.8924 9.38562C23.9622 9.26911 23.9991 9.13583 23.9991 8.99999C23.9991 8.86415 23.9622 8.73086 23.8924 8.61436C23.8226 8.49785 23.7224 8.4025 23.6026 8.33849L12.3526 2.33849ZM17.6251 12.8505L21.6571 15L12.0001 20.1495L2.34307 15L6.37507 12.8505L11.6476 15.6615C11.7561 15.7193 11.8771 15.7495 12.0001 15.7495C12.123 15.7495 12.2441 15.7193 12.3526 15.6615L17.6251 12.8505ZM12.0001 14.1495L2.34307 8.99999L12.0001 3.85049L21.6571 8.99999L12.0001 14.1495Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2778_27228">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
