import { useQueryClient } from '@tanstack/react-query'
import { qk } from 'api/query-keys'
import { useCallback } from 'react'

export const useInvalidateNotifications = () => {
  const queryClient = useQueryClient()

  return useCallback(() => {
    queryClient.invalidateQueries(qk.notifications.count.toKey())
    queryClient.invalidateQueries(qk.notifications.list.toKey(), {
      exact: false,
    })
  }, [queryClient])
}
