import { useMutation } from '@tanstack/react-query'
import { validateConfirmationRequest } from 'api'
import { LocalStorageKey } from 'app/enums'
import { paths } from 'app/routes'
import { useBoolean } from 'lib/react-utils'
import { showToast } from 'lib/toast'
import { useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import useLocalStorageState from 'use-local-storage-state'

/**
 * Hook for validating a confirmation link and managing related state.
 *
 * @param {string} expiredMessage - The message to display when the confirmation link is expired.
 * @returns {object} - Object with properties related to the confirmation link validation.
 * @property {string | null} id - The invitation ID.
 * @property {string | null} hash - The invitation hash.
 * @property {boolean} isValid - Boolean indicating if the confirmation link is valid.
 * @property {boolean} isValidating - Boolean indicating if the validation is in progress.
 */
export const useValidateConfirmationLink = (expiredMessage: string) => {
  // React hooks and utility functions
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  // Extracting and managing invitation ID and hash from URL and local storage
  const paramId = searchParams.get('id')
  const [localStorageId, setLocalStorageId] = useLocalStorageState(
    LocalStorageKey.InvitationId,
    {
      defaultValue: paramId,
    },
  )
  const invitationId = useMemo(
    () => paramId ?? localStorageId,
    [paramId, localStorageId],
  )

  const paramHash = searchParams.get('hash')
  const [localStorageHash, setLocalStorageHash] = useLocalStorageState(
    LocalStorageKey.InvitationHash,
    {
      defaultValue: paramHash,
    },
  )
  const invitationHash = useMemo(
    () => paramHash ?? localStorageHash,
    [paramHash, localStorageHash],
  )

  // State management for validation result and mutation
  const valid = useBoolean(false)
  const { mutate: validate, isLoading } = useMutation(
    validateConfirmationRequest,
    {
      onError: () => {
        showToast(
          {
            type: 'error',
            title: 'This link is expired',
            body: expiredMessage,
          },
          {
            autoClose: false,
          },
        )
      },
    },
  )

  // Synchronize URL parameters with local storage
  useEffect(() => {
    if (paramId !== null) setLocalStorageId(paramId)

    if (paramHash !== null) setLocalStorageHash(paramHash)
  }, [setLocalStorageId, setLocalStorageHash, paramId, paramHash])

  // Perform validation on component mount
  useEffectOnce(() => {
    if (invitationId === null || invitationHash === null) {
      // Redirect to login page if necessary parameters are missing
      navigate(paths.login)
    } else {
      // Initiate validation
      validate(
        { id: invitationId, hash: invitationHash },
        {
          onSuccess: () => {
            // Set validation result to true on success
            valid.setTrue()
          },
          onError: () => {
            // Redirect to login page with appropriate state and show error toast
            navigate(paths.login, {
              state: { saveToasts: true },
            })
          },
        },
      )
    }
  })

  // Return relevant properties for component consumption
  return {
    id: invitationId,
    hash: invitationHash,
    isValid: valid.isTrue,
    isValidating: isLoading,
  }
}
