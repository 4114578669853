import {
  Autocomplete as MuiAutocomplete,
  autocompleteClasses,
  FilterOptionsState,
} from '@mui/material'
import { IconChevronDown } from 'assets/icons'
import { checkScrollToBottom } from 'lib/js-utils'
import { useBoolean } from 'lib/react-utils'
import { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FormControl,
  FormControlWrapper,
  InputBase,
  LoadingMoreOption,
  MenuOption,
} from 'ui/inputs/common'

import { AutocompletePopper } from './autocomplete-popper'

type Option = {
  value: string
  label: string
  disabled?: boolean
  content?: React.ReactNode
}

export type AutocompleteProps = FormControlWrapper & {
  value: string
  onChange: (value: string, option: Option | null) => void
  options: Array<Option>

  onInputChange?: (value: string) => void
  onEnter?: () => void
  disabled?: boolean
  loading?: boolean
  loadingError?: boolean
  placeholder?: string
  noOptionsText?: string
  disableInternalFilter?: boolean
  closedWhileInputEmpty?: boolean
  disableAlphabeticalOrder?: boolean
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  hasMoreOptions?: boolean
  onLoadMore?: () => void
  isLoadingMore?: boolean
  dataCy?: string
  doStartWithFilter?: boolean
}

export const Autocomplete = forwardRef(
  (props: AutocompleteProps, ref: React.Ref<HTMLInputElement>) => {
    const { t } = useTranslation()
    const isOpen = useBoolean()

    const [inputValue, setInputValue] = useState('')

    const mainOptions = [...props.options].sort((a, b) =>
      props.disableAlphabeticalOrder ? 0 : a.label.localeCompare(b.label),
    )

    const options = props.isLoadingMore
      ? [
          ...mainOptions,
          {
            value: 'LOADING_MORE',
            label: t('common.loading_more'),
            disabled: true,
            content: <LoadingMoreOption />,
          },
        ]
      : mainOptions

    const findOptionByValue = (value: string) =>
      options.find(option => option.value === value) ?? null

    const optionsFilter = (
      options: Array<string>,
      state: FilterOptionsState<string>,
    ) => {
      if (props.disableInternalFilter) return options
      if (props.doStartWithFilter) {
        return options.filter(option =>
          findOptionByValue(option)
            ?.label.toLowerCase()
            .startsWith(state.inputValue.toLowerCase()),
        )
      }
      return options.filter(option =>
        findOptionByValue(option)
          ?.label.toLowerCase()
          .includes(state.inputValue.toLowerCase()),
      )
    }

    return (
      <FormControl
        label={props.label}
        labelAdditionalElement={props.labelAdditionalElement}
        hint={props.hint}
        error={props.error}
        helperText={props.helperText}
        onRemove={props.onRemove}
        required={props.required}
        name={props.name}
      >
        <MuiAutocomplete
          ref={ref}
          value={props.value || null}
          freeSolo={props.loading}
          onKeyDown={event => {
            if (event.key === 'Enter' && props.onEnter) {
              event.preventDefault()
              props.onEnter()
            }
          }}
          onChange={(_event, newValue) => {
            if (newValue) {
              props.onChange(newValue, findOptionByValue(newValue))
            } else {
              props.onChange('', null)
            }
          }}
          options={options.map(option => option.value)}
          inputValue={inputValue}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue)
            if (props.onInputChange) {
              props.onInputChange(newInputValue)
            }
          }}
          open={
            isOpen.isTrue &&
            (props.closedWhileInputEmpty ? inputValue !== '' : true)
          }
          onOpen={isOpen.setTrue}
          onClose={isOpen.setFalse}
          popupIcon={
            <IconChevronDown
              sx={{
                fontSize: '20px',
                color: theme => theme.palette.mineShaft[800],
              }}
            />
          }
          loading={props.loading}
          disabled={props.disabled}
          getOptionLabel={optionValue =>
            findOptionByValue(optionValue)?.label ?? ''
          }
          getOptionDisabled={optionValue =>
            Boolean(findOptionByValue(optionValue)?.disabled ?? false)
          }
          noOptionsText={
            props.loadingError
              ? t('common.failed_to_load_options')
              : props.noOptionsText
          }
          filterOptions={optionsFilter}
          renderInput={params => (
            <InputBase
              {...params.InputProps}
              endAdornment={
                props.endAdornment ?? params.InputProps.endAdornment
              }
              startAdornment={
                props.startAdornment ?? params.InputProps.startAdornment
              }
              inputProps={{
                ...params.inputProps,
                'data-cy': props.dataCy,
                type: 'search',
                autoComplete: 'off',
              }}
              error={props.error}
              disabled={props.disabled}
              placeholder={props.placeholder}
              required={props.required}
            />
          )}
          renderOption={({ className, ...optionProps }, optionValue) => {
            const option = findOptionByValue(optionValue)
            return option ? (
              <MenuOption
                {...optionProps}
                key={option.value}
                value={option.value}
                disabled={option.disabled}
                data-cy={`${props.dataCy}Item${options.findIndex(
                  option => option.value === optionValue,
                )}`}
                disableRipple
              >
                {option.content ?? option.label}
              </MenuOption>
            ) : null
          }}
          PopperComponent={AutocompletePopper}
          ListboxProps={{
            role: 'listbox', // See https://github.com/mui/material-ui/issues/30249#issuecomment-1026131743
            onScroll: event => {
              if (
                props.onLoadMore &&
                props.hasMoreOptions &&
                !props.isLoadingMore
              ) {
                checkScrollToBottom(event, props.onLoadMore)
              }
            },
          }}
          sx={{
            flex: 'auto',

            [`& .${autocompleteClasses.inputRoot}`]: {
              width: '100%',
            },
            [`& .${autocompleteClasses.endAdornment}`]: {
              top: '50%',
              transform: 'translate(0, -50%)',
              mr: 1,
            },

            ...(props.required && {
              [`& .${autocompleteClasses.clearIndicator}`]: {
                display: 'none',
              },
            }),

            ...(props.endAdornment && {
              // &&& is used to overwrite mui styles by increasing priority
              [`&&& .${autocompleteClasses.inputRoot}`]: {
                pr: 0,
              },
            }),
          }}
        />
      </FormControl>
    )
  },
)
