import {
  IconSignal,
  IconSkype,
  IconTelegram,
  IconViber,
  IconWhatsapp,
} from 'assets/icons'

export const findContactMessengerIcon = (messenger: string) => {
  switch (messenger) {
    case 'TELEGRAM': {
      return IconTelegram
    }
    case 'VIBER': {
      return IconViber
    }
    case 'WHATSAPP': {
      return IconWhatsapp
    }
    case 'SIGNAL': {
      return IconSignal
    }
    case 'SKYPE': {
      return IconSkype
    }
    default: {
      return null
    }
  }
}
