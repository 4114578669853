import { createListSearchParams } from 'api/list-params'
import { pipe } from 'fp-ts/lib/function'
import { decodeJsonWithTotal, del, get, post, put } from 'lib/request'

import { TJobPipelineTemplate } from './pipeline-templates.codecs'

export type PipelineId = {
  pipelineId: string
}

const PIPELINE_PATH = 'api/pipelines/:pipelineId'

export type JobPipelineTemplateInput = {
  name: string
  stages: Array<{
    name: string
    order: number
    canHire: boolean
  }>
  defaultPipeline: boolean
}

export const createJobPipelineTemplate = async (
  input: JobPipelineTemplateInput,
) => {
  return await post('api/pipelines', {
    body: input,
  })
}

export const PIPELINE_TEMPLATES_MAXIMUM = 20

export const getJobPipelineTemplates = async () => {
  const pagination = {
    page: 0,
    pageSize: PIPELINE_TEMPLATES_MAXIMUM,
  }

  const params = createListSearchParams({
    pagination,
    order: {
      column: 'name',
      direction: 'asc',
    },
  })

  return pipe(
    await get('api/pipelines', { query: params }),
    decodeJsonWithTotal(TJobPipelineTemplate, pagination),
  )
}

export const deleteJobPipelineTemplate = async ({ pipelineId }: PipelineId) => {
  return await del(PIPELINE_PATH, {
    params: { pipelineId },
  })
}

export const updateJobPipelineTemplate = async ({
  pipelineId,
  ...input
}: JobPipelineTemplateInput & PipelineId) => {
  return pipe(
    await put(PIPELINE_PATH, {
      params: { pipelineId },
      body: input,
    }),
  )
}
