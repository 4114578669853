import { FieldError } from 'react-hook-form'

/**
 * Transforms react-hook-form field error to appropriate MUI input props
 * @param error field state error
 * @returns input props to determine whether an error should be displayed
 */
export const getInputError = (error?: FieldError) => {
  return error
    ? {
        error: true,
        ...(error.message && { helperText: error.message }),
      }
    : {}
}
