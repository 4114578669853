import * as t from 'io-ts'

export const TPromotionStatus = t.strict({
  exists: t.boolean,
})

export const TCompanyPromotion = t.intersection([
  t.strict({
    subscription: t.intersection([
      t.strict({
        subscriptionId: t.string,
        trialAvailable: t.boolean,
        name: t.string,
        price: t.number,
      }),
      t.partial({
        discount: t.number,
        features: t.array(
          t.strict({
            code: t.string,
            name: t.string,
          }),
        ),
      }),
    ]),
  }),
  t.partial({
    totalPrice: t.number,
    totalDiscountedPrice: t.number,
    activeUsersCount: t.number,
  }),
])
