import { pipe } from 'fp-ts/lib/function'
import { decodeJson, del, get, post, put } from 'lib/request'

import {
  CustomFieldsCategory,
  CustomFieldType,
  TCustomFieldsList,
} from './custom-fields.codecs'

export type CustomFieldId = {
  customFieldId: string
}

export type CreateCustomFieldInput = {
  categoryType: CustomFieldsCategory
  fieldName: string
  fieldType: CustomFieldType
  required: boolean
  /** Array of options when field type is SINGLE_CHOICE */
  valueDetails?: Array<string>
}

export const createCustomField = async (input: CreateCustomFieldInput) => {
  return await post('api/companies/me/custom-fields', {
    body: input,
  })
}

export type GetCustomFieldsInput = {
  categoryType: CustomFieldsCategory
}

export const getCustomFields = async ({
  categoryType,
}: GetCustomFieldsInput) => {
  return pipe(
    await get('api/companies/me/custom-fields', {
      query: new URLSearchParams({ categoryType }),
    }),
    decodeJson(TCustomFieldsList),
  )
}

export type UpdateCustomFieldInput = CustomFieldId & {
  fieldName: string
  required: boolean
  valueDetails?: Array<string>
}

export const updateCustomField = async ({
  customFieldId,
  ...body
}: UpdateCustomFieldInput) => {
  return await put('api/companies/me/custom-fields/:customFieldId', {
    params: { customFieldId },
    body,
  })
}

export type ChangeCustomFieldOrderInput = CustomFieldId & {
  order: number
}

export const changeCustomFieldOrder = async ({
  customFieldId,
  order,
}: ChangeCustomFieldOrderInput) => {
  return await put('api/companies/me/custom-fields/:customFieldId/order', {
    params: { customFieldId },
    body: { order },
  })
}

export const deleteCustomField = async ({ customFieldId }: CustomFieldId) => {
  return await del('api/companies/me/custom-fields/:customFieldId', {
    params: { customFieldId },
  })
}
