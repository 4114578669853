import { Stack } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getCompanyPositions, qk } from 'api'
import { ControlsLine, Fieldset } from 'components/common'
import { RichTextEditor } from 'components/jobs'
import { useDictionaries } from 'lib/app-helpers'
import { getInputError } from 'lib/form-utils'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { renderAutocomplete } from 'ui/inputs/autocomplete'
import { renderSelect } from 'ui/inputs/select'

import { labels } from './_labels'
import { FormValues } from './_values'

type Props = {
  control: Control<FormValues>
}

export const WorkInformation = ({ control }: Props) => {
  const { t } = useTranslation()

  const { dictionaries, isDictionariesError } = useDictionaries()
  const $positions = useQuery(qk.company.positions.toKey(), getCompanyPositions)

  return (
    <Fieldset legend={t('common.work_information')}>
      <ControlsLine>
        <Controller
          name="position"
          control={control}
          render={renderAutocomplete({
            required: true,
            label: t(labels.position).toString(),
            loadingError: $positions.isError,
            options:
              $positions.data?.map(position => ({
                value: position.positionId,
                label: position.name,
              })) ?? [],
            dataCy: 'positionAutocompleteInput',
          })}
        />

        <Controller
          name="qualificationLevel"
          control={control}
          render={renderSelect({
            disableAlphabeticalOrder: true,
            disableEmptyOption: true,
            required: true,
            label: t(labels.qualificationLevel).toString(),
            loadingError: isDictionariesError,
            options: dictionaries.qualificationLevels.options,
            dataCy: 'qualificationLevelSelectInput',
          })}
        />
      </ControlsLine>

      <Stack data-cy="noteTextInput">
        <Controller
          name="note"
          control={control}
          render={({ field, fieldState }) => (
            <RichTextEditor
              name="note"
              label={t(labels.note).toString()}
              value={field.value}
              dataCy="jobDescription"
              onChange={field.onChange}
              {...getInputError(fieldState.error)}
            />
          )}
        />
      </Stack>
    </Fieldset>
  )
}
