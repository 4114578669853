import { UseMutationResult } from '@tanstack/react-query'

/**
 * Renders the result of a mutation query with different components based on its state.
 * @template T - The type of the mutation result data.
 * @template E - The type of the mutation error.
 * @template V - The type of the mutation variables.
 * @param {UseMutationResult<T, E, V>} query - The mutation query result.
 * @param {{
 *   success: (data: T) => JSX.Element | null;
 *   loading?: () => JSX.Element | null;
 *   error?: (error: E) => JSX.Element | null;
 *   default?: () => JSX.Element | null;
 * }} options - Options for rendering different components based on the query state.
 * @returns {JSX.Element | null} - The rendered component based on the query state.
 */
export const renderMutationResult = <T extends any, E = unknown, V = unknown>(
  query: UseMutationResult<T, E, V>,
  {
    success,
    loading,
    error,
    default: defaultRender,
  }: {
    success: (data: T) => JSX.Element | null
    loading?: () => JSX.Element | null
    error?: (error: E) => JSX.Element | null
    default?: () => JSX.Element | null
  },
) => {
  if (query.isLoading && loading) {
    return loading()
  }

  if (query.isError && error) {
    return error(query.error)
  }

  if (query.isSuccess) {
    return success(query.data)
  }

  return defaultRender ? defaultRender() : null
}
