import { Divider, Stack } from '@mui/material'
import { IconPlusCircle } from 'assets/icons'
import { Fieldset } from 'components/common'
import { useBoolean } from 'lib/react-utils'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'ui/inputs/icon-button'

import { FormValues } from '../_values'
import { EducationFormDialog } from './education-form-dialog'
import { EducationRow } from './education-row'

type Props = {
  control: Control<FormValues>
}

export const EducationFieldset = ({ control }: Props) => {
  const { t } = useTranslation()

  const showAddEducationDialog = useBoolean()

  return (
    <Fieldset
      legend={t('common.education')}
      action={
        <IconButton onClick={showAddEducationDialog.setTrue}>
          <IconPlusCircle
            sx={{ fontSize: '20px', color: 'mediumPurple.main' }}
          />
        </IconButton>
      }
    >
      <Controller
        name="educations"
        control={control}
        render={({ field }) => {
          return (
            <>
              {field.value.length > 0 && (
                <Stack
                  divider={<Divider />}
                  sx={{
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: '6px',
                  }}
                >
                  {field.value.map((education, educationIndex) => {
                    return (
                      <EducationRow
                        key={education.name + educationIndex}
                        education={education}
                        onDelete={() =>
                          field.onChange(
                            field.value.filter(
                              (_, index) => index !== educationIndex,
                            ),
                          )
                        }
                        onEdit={educationValues => {
                          field.onChange(
                            field.value.map((education, index) => {
                              if (index === educationIndex) {
                                return educationValues
                              }
                              return education
                            }),
                          )
                        }}
                      />
                    )
                  })}
                </Stack>
              )}

              <EducationFormDialog
                isOpen={showAddEducationDialog.isTrue}
                onClose={showAddEducationDialog.setFalse}
                onConfirm={newEducation =>
                  field.onChange([...field.value, newEducation])
                }
                confirmButtonText={t('common.add')}
              />
            </>
          )
        }}
      ></Controller>
    </Fieldset>
  )
}
