import { Typography, TypographyProps } from '@mui/material'
import { useBoolean, useIsElementOverflow } from 'lib/react-utils'
import { useTranslation } from 'react-i18next'
import { NoStylesButton } from 'ui/inputs/no-styles-button'

/** Pass max height equal to line height multiplied by the number of lines you need to show */
export const SeeMoreText = <C extends React.ElementType>(
  props: TypographyProps<C, { component?: C }>,
) => {
  const { t } = useTranslation()
  const { ref, isOverflow } = useIsElementOverflow('vertical')
  const isMoreVisible = useBoolean()

  return (
    <Typography
      {...props}
      ref={ref}
      sx={{
        ...props.sx,
        overflowY: 'hidden',
        position: 'relative',
        ...(isMoreVisible.isTrue && {
          maxHeight: 'none',
        }),
      }}
    >
      {props.children}
      {isOverflow && isMoreVisible.isFalse && (
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            pl: 3,
            background: 'linear-gradient(to right, transparent, #FFF 12px)',
          }}
        >
          <NoStylesButton onClick={isMoreVisible.setTrue}>
            {t('common.see_more')}
          </NoStylesButton>
        </Typography>
      )}
    </Typography>
  )
}
