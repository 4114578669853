import { authRoutes } from 'app/routes'
import constate from 'constate'
import { useBoolean } from 'lib/react-utils'
import mixpanel from 'mixpanel-browser'
import { useCallback, useState } from 'react'
import { matchRoutes, useNavigate } from 'react-router-dom'

import {
  IntroductionTourHighlight,
  IntroductionTourStep,
  useIntroductionTourSteps,
} from './introduction-tour-steps'

/**
 * Base hook for managing the introduction tour state and logic.
 *
 * @returns {object} - Object with properties and functions related to the introduction tour.
 * @property {boolean} isActive - Boolean indicating whether the introduction tour is currently active.
 * @property {Function} startTour - Function to start the introduction tour.
 * @property {Function} finishTour - Function to finish or cancel the introduction tour.
 * @property {Array} steps - Array of introduction tour steps.
 * @property {number} stepIndex - The current index of the introduction tour step.
 * @property {object | null} activeHighlight - The currently active highlight of the tour step.
 * @property {Function} moveForward - Function to move forward in the introduction tour.
 * @property {Function} skipSettingsSteps - Function to skip steps related to settings in the introduction tour.
 */
const useIntroductionTourBase = () => {
  const navigate = useNavigate()

  const isSkippingSettingsSteps = useBoolean()
  const steps = useIntroductionTourSteps({
    skipSettings: isSkippingSettingsSteps.isTrue,
  })

  const isTourActive = useBoolean()
  const [stepIndex, setStepIndex] = useState(0)
  const [activeHighlight, setActiveHighlight] =
    useState<IntroductionTourHighlight | null>(null)

  const startTour = isTourActive.setTrue
  const finishTour = isTourActive.setFalse

  const navigateToNextRoute = useCallback(
    async (route: string) => {
      const matches = matchRoutes(authRoutes, route)

      if (matches) {
        for (const match of matches) {
          await match.route.preload?.()
        }
      }

      navigate(route)
    },
    [navigate],
  )

  const moveToNextStep = useCallback(() => {
    if (stepIndex === steps.length - 1) {
      mixpanel.track('Complete introduction tour')
      finishTour()
    } else {
      const nextIndex = stepIndex + 1
      setStepIndex(nextIndex)
      navigateToNextRoute((steps[nextIndex] as IntroductionTourStep).route)
      setActiveHighlight(null)
    }
  }, [stepIndex, steps, navigateToNextRoute, finishTour])

  /** Activates next highlight or moves to the next step */
  const moveForward = useCallback(() => {
    const activeStep = steps[stepIndex]
    if (!activeStep) return

    const stepHighlights = activeStep.highlights

    /**
     * When some highlight is curretly active, try to move to the next one or proceed to the next step
     * When highlight is not active, try to activate the first one or proceed to the next step
     */
    if (activeHighlight) {
      const highlightIndex = stepHighlights.findIndex(
        highlight => highlight.highlightId === activeHighlight.highlightId,
      )

      if (highlightIndex === stepHighlights.length - 1) {
        moveToNextStep()
      } else {
        const nextHighlight = stepHighlights[
          highlightIndex + 1
        ] as IntroductionTourHighlight
        setActiveHighlight(nextHighlight)
        navigateToNextRoute(nextHighlight.route)
      }
    } else {
      const nextHighlight = stepHighlights[0]
      if (nextHighlight) {
        setActiveHighlight(nextHighlight)
        navigateToNextRoute(nextHighlight.route)
      } else {
        moveToNextStep()
      }
    }
  }, [stepIndex, activeHighlight, steps, moveToNextStep, navigateToNextRoute])

  return {
    isActive: isTourActive.isTrue,
    startTour,
    finishTour,
    steps,
    stepIndex,
    activeHighlight,
    moveForward,
    skipSettingsSteps: isSkippingSettingsSteps.setTrue,
  }
}

export const [IntroductionTourProvider, useIntroductionTour] = constate(
  useIntroductionTourBase,
)
