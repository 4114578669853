export const formatStringTimeToNumberMinutes = (timeString: string): number => {
  const hoursRegex = /(\d+)h/
  const minutesRegex = /(\d+)m/

  // eslint-disable-next-line security/detect-unsafe-regex
  const validTimeRegex = /^(\d+h\s*)*(\d+m)?$/
  if (!validTimeRegex.test(timeString)) {
    return 0
  }

  const hoursMatch = timeString.match(hoursRegex)
  const minutesMatch = timeString.match(minutesRegex)

  const hours = hoursMatch ? Number.parseInt(hoursMatch[1]!, 10) : 0
  const minutes = minutesMatch ? Number.parseInt(minutesMatch[1]!, 10) : 0

  return hours * 60 + minutes
}
