import { useCallback } from 'react'
import {
  createSearchParams,
  To,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

/**
 * Custom hook for handling authentication redirection.
 *
 * @returns {{
 *   rememberAuthUrl: (loginPath: string) => string,
 *   redirectToRememberedAuthUrl: (alternativeUrl: To) => void,
 * }}
 */
export const useRedirectAuth = () => {
  const location = useLocation()
  const navigate = useNavigate()

  // Constants for redirect parameters
  const REDIRECT_PATH = 'redirect-path'
  const REDIRECT_QUERY = 'redirect-query'

  // Retrieve search parameters from the current URL
  const [searchParams] = useSearchParams()
  const redirectPath = searchParams.get(REDIRECT_PATH)
  const redirectQuery = searchParams.get(REDIRECT_QUERY)

  /**
   * Generate a URL to remember the current authentication state.
   *
   * @param {string} loginPath - The path to the login page.
   * @returns {string} - The URL with redirect parameters.
   */
  const rememberAuthUrl = useCallback(
    (loginPath: string) => {
      // If already on the homepage, return the login path
      if (location.pathname === '/') {
        return loginPath
      }

      // Create redirect parameters based on the current location
      const redirectParams = createSearchParams({
        [REDIRECT_PATH]: location.pathname,
        ...(location.search && {
          [REDIRECT_QUERY]: location.search.slice(1),
        }),
      })

      // Return the login path with redirect parameters
      return `${loginPath}?${redirectParams.toString()}`
    },
    [location.pathname, location.search],
  )

  /**
   * Redirect to the previously remembered authentication URL or an alternative URL.
   *
   * @param {To} alternativeUrl - The alternative URL to redirect to if no remembered URL exists.
   */
  const redirectToRememberedAuthUrl = useCallback(
    (alternativeUrl: To) => {
      // Redirect to the remembered URL if available, otherwise, redirect to the alternative URL
      navigate(
        redirectPath
          ? {
              pathname: redirectPath,
              search: redirectQuery ? `?${redirectQuery}` : '',
            }
          : alternativeUrl,
      )
    },
    [navigate, redirectPath, redirectQuery],
  )

  return {
    rememberAuthUrl,
    redirectToRememberedAuthUrl,
  }
}
