import { Box, Stack, Typography } from '@mui/material'
import { IconOfficeOutline, IconPencil, IconX } from 'assets/icons'
import { useDictionaries, useFormatLocation } from 'lib/app-helpers'
import { IconButton } from 'ui/inputs/icon-button'
import { SeeMoreText } from 'ui/utils'

import { CircleDivider } from './circle-divider'
import { ExperienceDurationWithDates } from './experience-duration'

type Props = Readonly<{
  companyName: string
  positionName: string
  startDate: Date
  endDate?: Date
  jobTypeName?: string
  levelCode?: string
  description?: string
  country?: string
  city?: string
  onEdit?: () => void
  onDelete?: () => void
}>

export const WorkExperienceSinglePosition = ({
  companyName,
  positionName,
  startDate,
  endDate,
  jobTypeName,
  levelCode,
  description,
  country,
  city,
  onEdit,
  onDelete,
}: Props) => {
  const { dictionaries } = useDictionaries()
  const formatLocation = useFormatLocation()

  const experienceLevel = dictionaries.qualificationLevels.data.find(
    ({ code }) => code === levelCode,
  )?.name

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        '&:hover .actions': {
          opacity: 1,
        },
      }}
    >
      <IconOfficeOutline sx={{ color: 'greyBlue.light' }} />
      <Box ml={1.5} flex={1}>
        <Box display="flex" justifyContent="space-between">
          <Stack spacing={0.75}>
            <Typography variant="body2" fontWeight={500}>
              {experienceLevel && levelCode !== 'NONE'
                ? `${experienceLevel} `
                : ''}
              {positionName}
            </Typography>

            <CircleDivider>
              <Typography variant="caption">{companyName}</Typography>
              {jobTypeName && (
                <Typography variant="caption">{jobTypeName}</Typography>
              )}
            </CircleDivider>

            <ExperienceDurationWithDates
              startDate={startDate}
              endDate={endDate}
            />

            {(country || city) && (
              <Typography variant="caption" color="text.secondary">
                {formatLocation(country, city)}
              </Typography>
            )}
          </Stack>

          {(onEdit || onDelete) && (
            <Stack
              direction="row"
              spacing={0.5}
              className="actions"
              sx={{
                ml: 1,
                opacity: 0,
                transition: 'opacity 150ms',
              }}
            >
              {onDelete && (
                <IconButton color="error" onClick={onDelete}>
                  <IconX sx={{ fontSize: '20px' }} />
                </IconButton>
              )}

              {onEdit && (
                <IconButton onClick={onEdit}>
                  <IconPencil
                    sx={{ color: 'greyBlue.main', fontSize: '16px' }}
                  />
                </IconButton>
              )}
            </Stack>
          )}
        </Box>

        {description && (
          <SeeMoreText
            variant="caption"
            component="p"
            sx={{
              mt: 1.25,
              maxHeight: theme =>
                `calc(${theme.typography.caption.lineHeight} * 2)`,
            }}
          >
            {description}
          </SeeMoreText>
        )}
      </Box>
    </Box>
  )
}
