import { ToastContainerId } from 'app/enums'
import { useCallback } from 'react'
import { toast } from 'react-toastify'
import { useNotificationCenter } from 'react-toastify/addons/use-notification-center'

/**
 * Custom React Hook that provides a function to dismiss toasts by container id.
 * It utilizes the `useNotificationCenter` addon from react-toastify to keep track of all shown toasts.
 */
export const useDismissToasts = () => {
  // Get the notifications object from the useNotificationCenter addon
  const { notifications } = useNotificationCenter()

  // Function to dismiss toasts by container id
  const dismissToastsByContainerId = useCallback(
    (containerId: ToastContainerId) => {
      // Iterate through notifications and dismiss toasts with the specified container id
      for (const notification of notifications.filter(
        notification => notification.containerId === containerId,
      )) {
        toast.dismiss(notification.id)
      }
    },
    [notifications],
  )

  // Return the function to be used externally
  return {
    dismissToastsByContainerId,
  }
}
