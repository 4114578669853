import { useMutation } from '@tanstack/react-query'
import { deleteEmailTemplate } from 'api/email-templates'
import { IconCrossCircle } from 'assets/icons'
import { showToast } from 'lib/toast'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'ui/inputs/icon-button'

type Props = Readonly<{
  emailTemplateId: string
  emailTemplateName: string
  onDelete: () => void
}>

export const DeleteEmailTemplateIconButton = ({
  emailTemplateId,
  emailTemplateName,
  onDelete,
}: Props) => {
  const { t } = useTranslation()

  const $deleteEmailTemplate = useMutation(deleteEmailTemplate)

  return (
    <IconButton
      onClick={(e: Event) => {
        e.stopPropagation()
        $deleteEmailTemplate.mutate(
          { emailTemplateId },
          {
            onSuccess: () => {
              onDelete()
              showToast({ title: t('toasts.email_template_deleted') })
            },
          },
        )
      }}
      sx={{ color: 'error.main' }}
      confirm={{
        size: 'small',
        title: t('common.delete'),
        children: t('confirms.email_template_deleting_confirm', {
          emailTemplateName,
        }),
      }}
    >
      <IconCrossCircle sx={{ fontSize: '16px' }} />
    </IconButton>
  )
}
