import { useBoolean } from 'lib/react-utils'
import { Placeholder } from 'lib/records'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'

import { AddPlaceholderDialog } from './add-placeholder-dialog'

type Props = Readonly<{
  onSelect: (placeholder: Placeholder) => void
}>

export const AddPlaceholderButton = ({ onSelect }: Props) => {
  const { t } = useTranslation()

  const addPlaceholderDialogOpen = useBoolean()

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={addPlaceholderDialogOpen.setTrue}
      >
        {t('common.add_placeholder')}
      </Button>

      {addPlaceholderDialogOpen.isTrue && (
        <AddPlaceholderDialog
          isOpen={addPlaceholderDialogOpen.isTrue}
          onClose={addPlaceholderDialogOpen.setFalse}
          onSelect={onSelect}
        />
      )}
    </>
  )
}
