import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCheckCircleOutlineDashed = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g clipPath="url(#clip0_6445_82350)">
      <path
        d="M11.2206 1.5285C11.4777 1.50952 11.7353 1.50001 11.9931 1.5V0C11.6983 0.000143741 11.4036 0.0111512 11.1096 0.033L11.2206 1.5285ZM8.2146 2.2035C8.69543 2.01796 9.18927 1.86805 9.6921 1.755L9.3636 0.291C8.7891 0.42 8.2236 0.591 7.6746 0.804L8.2146 2.2035ZM6.1596 3.2685C6.37406 3.12547 6.59371 2.99038 6.8181 2.8635L6.0786 1.5585C5.56574 1.8491 5.07492 2.17699 4.6101 2.5395L5.5326 3.723C5.73597 3.56435 5.94511 3.41324 6.1596 3.27V3.2685ZM3.4086 5.9535C3.70531 5.53196 4.0327 5.13288 4.3881 4.7595L3.3021 3.7245C2.8971 4.152 2.5221 4.6095 2.1816 5.0895L3.4086 5.9535ZM2.2926 7.9815C2.39106 7.74369 2.49813 7.50953 2.6136 7.2795L1.2741 6.6045C1.0092 7.13113 0.78359 7.67661 0.599104 8.2365L2.0241 8.706C2.10471 8.4613 2.19427 8.21964 2.2926 7.9815ZM1.4976 11.742C1.50974 11.2265 1.55987 10.7126 1.6476 10.2045L0.170104 9.9495C0.0696039 10.5285 0.0111036 11.1165 -0.00389671 11.7045L1.4961 11.742H1.4976ZM1.6941 14.049C1.6446 13.794 1.6041 13.5405 1.5726 13.284L0.0831041 13.4685C0.155151 14.0537 0.270467 14.6328 0.428103 15.201L1.8741 14.8005C1.8051 14.553 1.7451 14.3025 1.6941 14.049ZM3.1221 17.6175C2.8461 17.1825 2.6031 16.7265 2.3931 16.2555L1.0221 16.863C1.2621 17.403 1.5396 17.922 1.8546 18.42L3.1221 17.6175ZM4.5681 19.425C4.3851 19.242 4.2096 19.053 4.0431 18.858L2.9061 19.8375C3.09865 20.0608 3.29928 20.2769 3.5076 20.4855L4.5681 19.425Z"
        fill="currentColor"
      />
      <path
        d="M11.9923 1.5C13.719 1.50012 15.419 1.92607 16.9418 2.74011C18.4645 3.55415 19.763 4.73115 20.7222 6.16687C21.6815 7.6026 22.2718 9.25272 22.441 10.9711C22.6102 12.6895 22.353 14.423 21.6923 16.0183C21.0315 17.6135 19.9875 19.0212 18.6527 20.1166C17.318 21.212 15.7337 21.9613 14.0402 22.2982C12.3467 22.6351 10.5963 22.5492 8.94394 22.048C7.29159 21.5468 5.78833 20.6459 4.56733 19.425L3.50682 20.4855C4.90222 21.8817 6.62045 22.9121 8.50926 23.4855C10.3981 24.0589 12.3992 24.1575 14.3352 23.7727C16.2713 23.3879 18.0825 22.5315 19.6085 21.2793C21.1345 20.0272 22.328 18.418 23.0834 16.5943C23.8388 14.7707 24.1327 12.7888 23.9391 10.8244C23.7455 8.85999 23.0704 6.97365 21.9735 5.33253C20.8767 3.6914 19.3919 2.34617 17.6509 1.416C15.9098 0.485843 13.9663 -0.000523098 11.9923 4.22187e-07V1.5Z"
        fill="currentColor"
      />
      <path
        d="M16.5722 7.45493C16.5615 7.46529 16.5515 7.47631 16.5422 7.48793L11.3327 14.1254L8.19321 10.9844C7.97994 10.7857 7.69788 10.6775 7.40642 10.6827C7.11497 10.6878 6.83689 10.8059 6.63077 11.012C6.42465 11.2181 6.30659 11.4962 6.30144 11.7876C6.2963 12.0791 6.40449 12.3612 6.60321 12.5744L10.5722 16.5449C10.6791 16.6517 10.8065 16.7358 10.9466 16.7922C11.0867 16.8487 11.2368 16.8763 11.3878 16.8735C11.5389 16.8707 11.6878 16.8375 11.8258 16.7759C11.9637 16.7143 12.0878 16.6256 12.1907 16.5149L18.1787 9.02993C18.3826 8.81593 18.4941 8.53028 18.489 8.23473C18.484 7.93919 18.3629 7.6575 18.1518 7.45057C17.9407 7.24364 17.6567 7.1281 17.3611 7.12891C17.0655 7.12973 16.7821 7.24684 16.5722 7.45493Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6445_82350">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
