import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconQualificationLevelMedium = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      d="M14 2.2C14 2.08954 13.9105 2 13.8 2L2.2 2C2.08954 2 2 2.08954 2 2.2V3.8C2 3.91046 2.08954 4 2.2 4L13.8 4C13.9105 4 14 3.91046 14 3.8V2.2ZM15 4C15 4.26522 14.8946 4.51957 14.7071 4.70711C14.5196 4.89464 14.2652 5 14 5L2 5C1.73478 5 1.48043 4.89464 1.29289 4.70711C1.10536 4.51957 1 4.26522 1 4V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1L14 1C14.2652 1 14.5196 1.10536 14.7071 1.29289C14.8946 1.48043 15 1.73478 15 2V4ZM9 10C9.26522 10 9.51957 9.89464 9.70711 9.70711C9.89464 9.51957 10 9.26522 10 9L10 7C10 6.73478 9.89464 6.48043 9.70711 6.29289C9.51957 6.10536 9.26522 6 9 6H2C1.73478 6 1.48043 6.10536 1.29289 6.29289C1.10536 6.48043 1 6.73478 1 7V9C1 9.26522 1.10536 9.51957 1.29289 9.70711C1.48043 9.89464 1.73478 10 2 10L9 10ZM5 15C5.26522 15 5.51957 14.8946 5.70711 14.7071C5.89464 14.5196 6 14.2652 6 14V12C6 11.7348 5.89464 11.4804 5.70711 11.2929C5.51957 11.1054 5.26522 11 5 11L2 11C1.73478 11 1.48043 11.1054 1.29289 11.2929C1.10536 11.4804 1 11.7348 1 12V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H5Z"
      fill="currentColor"
    />
  </SvgIcon>
)
