import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconLink = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M7.07242 9.813L5.01442 11.871C4.17043 12.715 3.69629 13.8597 3.69629 15.0532C3.69629 16.2468 4.17043 17.3915 5.01442 18.2355C5.85841 19.0795 7.00309 19.5536 8.19667 19.5536C9.39025 19.5536 10.5349 19.0795 11.3789 18.2355L14.1209 15.492C14.6299 14.983 15.0093 14.3593 15.2273 13.6733C15.4453 12.9873 15.4956 12.259 15.374 11.5496C15.2523 10.8402 14.9622 10.1702 14.5281 9.59611C14.0939 9.02197 13.5284 8.56035 12.8789 8.25L11.9999 9.129C11.9107 9.2184 11.8331 9.31869 11.7689 9.4275C12.2707 9.57175 12.7262 9.84447 13.0903 10.2187C13.4544 10.5929 13.7145 11.0557 13.8449 11.5612C13.9754 12.0668 13.9716 12.5976 13.834 13.1013C13.6964 13.6049 13.4298 14.064 13.0604 14.433L10.3199 17.175C9.757 17.7379 8.99351 18.0542 8.19742 18.0542C7.40133 18.0542 6.63784 17.7379 6.07492 17.175C5.512 16.6121 5.19575 15.8486 5.19575 15.0525C5.19575 14.2564 5.512 13.4929 6.07492 12.93L7.26442 11.742C7.09659 11.1131 7.03175 10.4611 7.07242 9.8115V9.813Z"
      fill="currentColor"
    />
    <path
      d="M9.87883 7.00788C9.36989 7.5169 8.99048 8.14058 8.77244 8.82656C8.55441 9.51255 8.50412 10.2408 8.62579 10.9503C8.74746 11.6597 9.03754 12.3296 9.47168 12.9038C9.90583 13.4779 10.4714 13.9395 11.1208 14.2499L12.2833 13.0859C11.7748 12.9495 11.311 12.6816 10.9388 12.3092C10.5665 11.9368 10.2988 11.473 10.1626 10.9643C10.0264 10.4557 10.0265 9.92016 10.1628 9.41156C10.2992 8.90296 10.567 8.4392 10.9393 8.06688L13.6798 5.32489C14.2428 4.76196 15.0062 4.44572 15.8023 4.44572C16.5984 4.44572 17.3619 4.76196 17.9248 5.32489C18.4878 5.88781 18.804 6.65129 18.804 7.44739C18.804 8.24348 18.4878 9.00696 17.9248 9.56989L16.7353 10.7579C16.9033 11.3879 16.9678 12.0404 16.9273 12.6884L18.9853 10.6304C19.8293 9.7864 20.3035 8.64171 20.3035 7.44814C20.3035 6.25456 19.8293 5.10987 18.9853 4.26589C18.1413 3.4219 16.9967 2.94775 15.8031 2.94775C14.6095 2.94775 13.4648 3.4219 12.6208 4.26589L9.87883 7.00788Z"
      fill="currentColor"
    />
  </SvgIcon>
)
