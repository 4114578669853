import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCopyOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      d="M13.5 14.625C13.5 15.2217 13.2629 15.794 12.841 16.216C12.419 16.6379 11.8467 16.875 11.25 16.875H3.375C2.77826 16.875 2.20597 16.6379 1.78401 16.216C1.36205 15.794 1.125 15.2217 1.125 14.625V6.75C1.125 6.15326 1.36205 5.58097 1.78401 5.15901C2.20597 4.73705 2.77826 4.5 3.375 4.5V5.625C3.07663 5.625 2.79048 5.74353 2.5795 5.95451C2.36853 6.16548 2.25 6.45163 2.25 6.75V14.625C2.25 14.9234 2.36853 15.2095 2.5795 15.4205C2.79048 15.6315 3.07663 15.75 3.375 15.75H11.25C11.5484 15.75 11.8345 15.6315 12.0455 15.4205C12.2565 15.2095 12.375 14.9234 12.375 14.625H13.5Z"
      fill="currentColor"
    />
    <path
      d="M6.75 2.25C6.45163 2.25 6.16548 2.36853 5.95451 2.5795C5.74353 2.79048 5.625 3.07663 5.625 3.375V11.25C5.625 11.5484 5.74353 11.8345 5.95451 12.0455C6.16548 12.2565 6.45163 12.375 6.75 12.375H14.625C14.9234 12.375 15.2095 12.2565 15.4205 12.0455C15.6315 11.8345 15.75 11.5484 15.75 11.25V3.375C15.75 3.07663 15.6315 2.79048 15.4205 2.5795C15.2095 2.36853 14.9234 2.25 14.625 2.25H6.75ZM6.75 1.125H14.625C15.2217 1.125 15.794 1.36205 16.216 1.78401C16.6379 2.20597 16.875 2.77826 16.875 3.375V11.25C16.875 11.8467 16.6379 12.419 16.216 12.841C15.794 13.2629 15.2217 13.5 14.625 13.5H6.75C6.15326 13.5 5.58097 13.2629 5.15901 12.841C4.73705 12.419 4.5 11.8467 4.5 11.25V3.375C4.5 2.77826 4.73705 2.20597 5.15901 1.78401C5.58097 1.36205 6.15326 1.125 6.75 1.125Z"
      fill="currentColor"
    />
  </SvgIcon>
)
