import { useTheme } from '@mui/material'
import MuiPhoneNumber from 'material-ui-phone-number'
import { allCountries } from 'material-ui-phone-number/src/country_data'

import { FormControl } from './common'

const DEFAULT_COUNTRY = 'ua'

type Props = {
  value: string
  onChange: (value: string) => void
  label?: string
  helperText?: React.ReactNode
  error?: boolean
  onRemove?: () => void
  defaultCountry?: string
  dataCy?: string
  required?: boolean
}

export const PhoneInput = ({
  value,
  onChange,
  label,
  helperText,
  error,
  onRemove,
  required,
  defaultCountry = DEFAULT_COUNTRY,
  dataCy,
}: Props) => {
  const theme = useTheme()
  const countryCode = defaultCountry.toLowerCase()

  return (
    <FormControl
      label={label}
      required={required}
      error={error}
      helperText={helperText}
      onRemove={onRemove}
      name="phone"
    >
      <MuiPhoneNumber
        fullWidth
        defaultCountry={
          // There is a chance that a default country from our list
          // doesn't exist in all countries array from material-ui-phone-number library
          // For example, {code: "AX", name: "Åland Islands"}
          // In this case it throws an error and app crashes
          // So we need to import allCountries data and check if country code exists first
          allCountries.some(country => country.iso2 === countryCode)
            ? countryCode
            : DEFAULT_COUNTRY
        }
        inputProps={{
          'data-cy': dataCy,
        }}
        value={value}
        margin="none"
        disableAreaCodes
        error={error}
        InputProps={{
          id: label,
          disableUnderline: true,
          sx: {
            borderRadius: '4px',
            height: '32px',
            borderColor: theme.palette.mineShaft[200],

            '&.Mui-focused': {
              borderColor: 'primary.main',
            },

            '&.Mui-error': {
              background: theme.palette.red['100'],
              borderColor: 'error.main',
            },

            '& .MuiInputAdornment-positionStart': {
              ml: 0.5,

              '& svg': {
                width: '24px',
              },
            },
          },
        }}
        countryCodeEditable={false}
        onChange={phone => {
          if (typeof phone === 'string') {
            onChange(phone.replace(/[\s()-]/g, ''))
          }
        }}
      />
    </FormControl>
  )
}
