import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconResendOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 25">
    <path
      d="M14.6644 6.26245L18.6367 9.79337C20.2053 11.1877 20.9896 11.8849 20.9896 12.783C20.9896 13.6811 20.2053 14.3783 18.6367 15.7726L14.6644 19.3036C13.9484 19.94 13.5903 20.2583 13.2952 20.1257C13 19.9932 13 19.5142 13 18.5562V16.2116C9.4 16.2116 5.5 17.9259 4 20.783C4 11.6402 9.33333 9.35444 13 9.35444V7.00986C13 6.05186 13 5.57286 13.2952 5.44031C13.5903 5.30776 13.9484 5.62599 14.6644 6.26245Z"
      fill="transparent"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
