import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconUserCheckLarge = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4396 10.4996C13.6443 10.4996 15.4316 8.71235 15.4316 6.50763C15.4316 4.30291 13.6443 2.51562 11.4396 2.51562C9.23488 2.51562 7.4476 4.30291 7.4476 6.50763C7.4476 8.71235 9.23488 10.4996 11.4396 10.4996ZM11.4396 11.9996C14.4728 11.9996 16.9316 9.54078 16.9316 6.50763C16.9316 3.47448 14.4728 1.01562 11.4396 1.01562C8.40646 1.01562 5.9476 3.47448 5.9476 6.50763C5.9476 9.54078 8.40646 11.9996 11.4396 11.9996Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.8971 21.9415C1.91872 21.8451 1.94374 21.7417 1.97268 21.6323C2.15595 20.9394 2.4924 20.0196 3.10242 19.1042C4.29653 17.3121 6.60201 15.4351 11.1778 15.4351C14.1024 15.4351 16.0856 16.2024 17.4403 17.1975C17.7741 17.4428 18.2435 17.3709 18.4887 17.0371C18.7339 16.7033 18.6621 16.2339 18.3283 15.9887C16.6846 14.7812 14.3803 13.9351 11.1778 13.9351C6.10226 13.9351 3.32749 16.0613 1.85415 18.2724C1.13044 19.3585 0.736372 20.4404 0.522553 21.2487C0.415352 21.654 0.352535 21.994 0.31624 22.2364C0.298075 22.3578 0.286501 22.455 0.279323 22.5242C0.275733 22.5587 0.273239 22.5863 0.271569 22.6065L0.269646 22.631L0.269084 22.6389L0.268891 22.6418C0.268858 22.6423 0.268753 22.6439 1.01724 22.6915L0.268753 22.6439L0.218018 23.4415H13.3163C13.7305 23.4415 14.0663 23.1057 14.0663 22.6915C14.0663 22.2773 13.7305 21.9415 13.3163 21.9415H1.8971Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7402 17.4056C24.0482 17.6825 24.0735 18.1567 23.7966 18.4648L19.2798 23.4895C19.1399 23.6451 18.9413 23.7353 18.732 23.738C18.5227 23.7408 18.3217 23.656 18.1777 23.5041L15.0324 20.1861C14.7474 19.8855 14.7601 19.4108 15.0607 19.1259C15.3613 18.8409 15.836 18.8536 16.121 19.1542L18.7074 21.8825L22.681 17.462C22.9579 17.1539 23.4321 17.1287 23.7402 17.4056Z"
      fill="currentColor"
    />
  </SvgIcon>
)
