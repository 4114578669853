import { Box, Divider, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { ListOrder, ListPagination, qk } from 'api'
import { EmailTemplate, getEmailTemplateListRequest } from 'api/email-templates'
import { IconArrowDropdown } from 'assets/icons'
import DOMPurify from 'dompurify'
import * as Arr from 'fp-ts/Array'
import { renderQueryResult } from 'lib/react-query-utils'
import { EMAIL_TEMPLATE_LIMIT } from 'pages/settings/email-templates/email-templates'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from 'ui/feedback'
import { Dialog, DialogContent } from 'ui/feedback'
import { Radio } from 'ui/inputs/radio'
import { Accordion } from 'ui/surfaces'

import { EmptyTemplatesDialogContent } from './empty-templates-dialog-content'
type Props = {
  isOpen: boolean
  onClose: () => void
  onSelect: (template: EmailTemplate) => void
}
const order: ListOrder = {
  column: 'name',
  direction: 'asc',
}
const pagination: ListPagination = {
  page: 0,
  pageSize: EMAIL_TEMPLATE_LIMIT, //DISPLAY ALL
}
export const AddTemplateDialog = ({ onClose, onSelect, isOpen }: Props) => {
  const { t } = useTranslation()
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null,
  )
  const $emailTemplates = useQuery(
    qk.emailTemplates.list.toKeyWithArgs({ order, pagination }),
    () => getEmailTemplateListRequest({ order, pagination }),
    {
      onSuccess: data => {
        if (data.rows.length > 0) {
          const firstItem = data.rows.at(0)
          if (firstItem) {
            setSelectedTemplateId(firstItem.id)
          }
        }
      },
      refetchOnWindowFocus: true,
    },
  )
  return (
    <Dialog open={isOpen} onClose={onClose}>
      {renderQueryResult($emailTemplates, {
        loading: () => (
          <DialogContent title={t('common.add_template')} onDeny={onClose}>
            <CircularProgress centered />
          </DialogContent>
        ),
        success: templates => {
          if (Arr.isEmpty(templates.rows)) {
            return <EmptyTemplatesDialogContent onDeny={onClose} />
          }

          return (
            <DialogContent
              title={t('common.add_template')}
              onDeny={onClose}
              confirmText={t('common.select')}
              onConfirm={() => {
                const selectedTemplate = templates.rows.find(
                  template => template.id === selectedTemplateId,
                )
                if (selectedTemplate) {
                  onSelect(selectedTemplate)
                  onClose()
                }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ flex: '1 0 auto' }}>
                  {templates.rows.map((template, index) => {
                    const isNotLast = templates.rows.length - 1 !== index
                    return (
                      <Box
                        key={template.id}
                        sx={{ display: 'flex', alignItems: 'flex-start' }}
                      >
                        <Radio
                          name="email-template"
                          value={template.id}
                          onChange={(templateId: string) => {
                            setSelectedTemplateId(templateId)
                          }}
                          selectedValue={selectedTemplateId || ''}
                          sx={{ marginRight: '4px' }}
                        />
                        <Accordion
                          title={
                            <>
                              <Typography
                                variant="h4"
                                sx={{ padding: '12px 0', flex: '1 0 auto' }}
                              >
                                {template.name}
                              </Typography>
                              <Typography
                                variant="button"
                                sx={{
                                  color: 'primary.main',
                                  textDecoration: 'uppercase',
                                }}
                              >
                                {t('common.preview')}
                              </Typography>
                            </>
                          }
                          expanded={selectedTemplateId === template.id}
                          onChange={(isExpanded: boolean) => {
                            setSelectedTemplateId(
                              isExpanded ? template.id : null,
                            )
                          }}
                          expandIcon={
                            <IconArrowDropdown
                              sx={{ color: 'primary.main', fontSize: '20px' }}
                            />
                          }
                          sx={{ width: 'calc(100% - 46px)' }}
                        >
                          <Box sx={{ marginBottom: '20px' }}>
                            <Typography
                              variant="body2"
                              sx={{ marginBottom: '12px' }}
                            >
                              {t('common.subject')}: {template.emailSubject}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                color: 'text.secondary',
                                wordWrap: 'break-word',
                              }}
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(template.emailBody),
                              }}
                            />
                          </Box>
                        </Accordion>
                        {isNotLast && <Divider />}
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            </DialogContent>
          )
        },
      })}
    </Dialog>
  )
}
