import { alpha, Box, Typography } from '@mui/material'
import { IconFileAttachment04 } from 'assets/icons'
import { showToast } from 'lib/toast'
import { Accept, useDropzone } from 'react-dropzone'
import { Trans, useTranslation } from 'react-i18next'

import { RejectedFiles } from './rejected-files'

type Props = {
  onDrop: (files: Array<File>) => void
  children?: React.ReactNode
  /** Maximum accepted number of files */
  maxFiles?: number
  /** Object with a common MIME type as keys and an array of file extensions as values */
  accept?: Accept
  acceptedFileFormats?: string
  toastTitle?: string
  subtitle?: string
  /** Maximum file size (in bytes) */
  maxSize?: number
  /** Allow drag 'n' drop (or selection from the file dialog) of multiple files */
  multiple?: boolean
  disabled?: boolean
  dataCy?: string
}

export const DropZone = ({
  onDrop,
  children,
  maxFiles,
  accept,
  subtitle,
  acceptedFileFormats,
  toastTitle,
  maxSize,
  multiple,
  disabled,
  dataCy,
}: Props) => {
  const { t } = useTranslation()

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    onDropAccepted: onDrop,
    maxSize,
    onDropRejected: rejections => {
      showToast(
        {
          type: 'error',
          title: toastTitle ?? t('toasts.dropzone_files_rejected'),
          body: (
            <RejectedFiles
              rejections={rejections}
              maxSize={maxSize}
              acceptedFileFormats={acceptedFileFormats}
            />
          ),
        },
        { autoClose: false },
      )
    },
    accept,
    maxFiles,
    multiple,
    disabled,
  })

  return (
    <Box
      {...getRootProps()}
      sx={{
        px: 2.5,
        bgcolor: theme => alpha(theme.palette.mediumPurple[100], 0.4),
        border: '1px dashed',
        borderColor: 'mediumPurple.main',
        borderRadius: '6px',
      }}
    >
      <input {...getInputProps()} />

      {children || (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
          py="32px"
        >
          <IconFileAttachment04
            stroke="1"
            sx={{ fontSize: '60px', color: 'mediumPurple.main' }}
          />

          <Typography variant="caption" color="mineShaft.main" mt={2}>
            <Trans
              i18nKey="common.click_to_upload_or_drag"
              components={{
                button: (
                  <Typography
                    onClick={open}
                    data-cy={dataCy}
                    variant="inherit"
                    display="inline"
                    sx={{
                      cursor: 'pointer',
                      color: 'mediumPurple.main',
                      ':hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  />
                ),
              }}
            />
          </Typography>
          {subtitle && (
            <Typography
              variant="caption"
              color={theme => theme.palette.mineShaft[600]}
              textAlign="center"
              mt={1}
              maxWidth="240px"
            >
              {subtitle}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}
