import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconLevelMedium = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 14">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.42261 5.93789C3.42261 5.62378 3.67725 5.36914 3.99136 5.36914L10.0087 5.36914C10.3228 5.36914 10.5774 5.62378 10.5774 5.93789C10.5774 6.252 10.3228 6.50664 10.0087 6.50664L3.99136 6.50664C3.67725 6.50664 3.42261 6.252 3.42261 5.93789Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.42261 9.06191C3.42261 8.7478 3.67725 8.49316 3.99136 8.49316H10.0087C10.3228 8.49316 10.5774 8.7478 10.5774 9.06191C10.5774 9.37603 10.3228 9.63066 10.0087 9.63066H3.99136C3.67725 9.63066 3.42261 9.37603 3.42261 9.06191Z"
      fill="currentColor"
    />
  </SvgIcon>
)
