import { wrapUseRoutes } from '@sentry/react'
import { paths, publicRoutes, unauthRoutes } from 'app/routes'
import { useRedirectAuth } from 'lib/react-utils'
import { Navigate, useRoutes } from 'react-router-dom'

const useSentryRoutes = wrapUseRoutes(useRoutes)

export const UnauthApp = () => {
  const { rememberAuthUrl } = useRedirectAuth()

  return useSentryRoutes([
    ...unauthRoutes,
    ...publicRoutes,
    {
      path: '*',
      element: <Navigate replace to={rememberAuthUrl(paths.login)} />,
    },
  ])
}
