import { Box, Typography } from '@mui/material'

export const CollectionWrapper = ({
  title,
  subtitle,
  children,
}: Readonly<{
  title: string
  subtitle: string
  children: React.ReactNode
}>) => {
  return (
    <Box>
      <Typography variant="h2" mb={2}>
        {title}
      </Typography>

      <Typography variant="body2" color="text.secondary" mb={2.5}>
        {subtitle}
      </Typography>

      {children}
    </Box>
  )
}
