import { Box, Divider, Stack, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { searchContacts } from 'api'
import { paths } from 'app/routes'
import { showToast } from 'lib/toast'
import debounce from 'lodash.debounce'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { Id as ToastId, toast } from 'react-toastify'
import { Avatar } from 'ui/data'
import { ToastContent, ToastContentProps } from 'ui/feedback'
import { Link } from 'ui/navigation/link'

type Props = {
  name: string
  initialName?: string
}

export const PossibleDuplicates = ({ name, initialName }: Props) => {
  const { t } = useTranslation()
  const toastId = useRef<ToastId | null>(null)

  const $search = useMutation(searchContacts)

  const debounceSearch = useMemo(
    () => debounce($search.mutate, 500),
    [$search.mutate],
  )

  useEffect(() => () => debounceSearch.cancel(), [debounceSearch])

  const closeToast = useCallback(() => {
    if (toastId.current) {
      toast.dismiss(toastId.current)
      toastId.current = null
    }
  }, [])

  useEffect(() => {
    if (name.length > 2) {
      debounceSearch(
        {
          pagination: { page: 0, pageSize: 10 },
          fullName: name,
        },
        {
          onSuccess: data => {
            const duplicates = data.rows.filter(
              contact => contact.name !== initialName,
            )

            if (duplicates.length > 0) {
              const contentProps: ToastContentProps = {
                title: t('common.possible_duplicates_found'),
                type: 'warning',
                colorBackground: true,
                body: (
                  <Stack spacing={1} divider={<Divider flexItem />}>
                    {duplicates.map(contact => (
                      <Link
                        key={contact.candidateProfileId}
                        to={generatePath(paths.contactDetails, {
                          contactId: contact.candidateProfileId,
                        })}
                        target="_blank"
                        display="flex"
                        alignItems="center"
                      >
                        <Avatar size={32} src={contact.imageUri} />
                        <Box ml={1}>
                          <Typography variant="body2" color="text.primary">
                            {contact.name}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {contact.address && contact.address.city
                              ? `${contact.address.city}, `
                              : ''}
                            {contact.position.name}
                          </Typography>
                        </Box>
                      </Link>
                    ))}
                  </Stack>
                ),
              }

              if (toastId.current) {
                toast.update(toastId.current, {
                  render: <ToastContent {...contentProps} />,
                })
              } else {
                toastId.current = showToast(contentProps, {
                  autoClose: false,
                })
              }
            } else {
              closeToast()
            }
          },
        },
      )
    } else {
      debounceSearch.cancel()
      closeToast()
    }
  }, [t, name, initialName, debounceSearch, closeToast])

  return null
}
