import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconChannel = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 14">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.83789 7.72266C6.41283 7.72266 6.87891 7.25658 6.87891 6.68164C6.87891 6.1067 6.41283 5.64062 5.83789 5.64062C5.26295 5.64062 4.79688 6.1067 4.79688 6.68164C4.79688 7.25658 5.26295 7.72266 5.83789 7.72266ZM5.83789 8.72266C6.96511 8.72266 7.87891 7.80886 7.87891 6.68164C7.87891 5.55442 6.96511 4.64062 5.83789 4.64062C4.71067 4.64062 3.79688 5.55442 3.79688 6.68164C3.79688 7.80886 4.71067 8.72266 5.83789 8.72266Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.459 7.72266C13.0339 7.72266 13.5 7.25658 13.5 6.68164C13.5 6.1067 13.0339 5.64062 12.459 5.64062C11.884 5.64062 11.418 6.1067 11.418 6.68164C11.418 7.25658 11.884 7.72266 12.459 7.72266ZM12.459 8.72266C13.5862 8.72266 14.5 7.80886 14.5 6.68164C14.5 5.55442 13.5862 4.64062 12.459 4.64062C11.3318 4.64062 10.418 5.55442 10.418 6.68164C10.418 7.80886 11.3318 8.72266 12.459 8.72266Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.54102 11.8008C3.11595 11.8008 3.58203 11.3347 3.58203 10.7598C3.58203 10.1848 3.11595 9.71875 2.54102 9.71875C1.96608 9.71875 1.5 10.1848 1.5 10.7598C1.5 11.3347 1.96608 11.8008 2.54102 11.8008ZM2.54102 12.8008C3.66824 12.8008 4.58203 11.887 4.58203 10.7598C4.58203 9.63254 3.66824 8.71875 2.54102 8.71875C1.41379 8.71875 0.5 9.63254 0.5 10.7598C0.5 11.887 1.41379 12.8008 2.54102 12.8008Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.10044 12.8462C9.33325 12.8462 9.52198 12.6575 9.52198 12.4247C9.52198 12.1919 9.33325 12.0031 9.10044 12.0031C8.86764 12.0031 8.67891 12.1919 8.67891 12.4247C8.67891 12.6575 8.86764 12.8462 9.10044 12.8462ZM9.10044 13.6462C9.77508 13.6462 10.322 13.0993 10.322 12.4247C10.322 11.75 9.77508 11.2031 9.10044 11.2031C8.42581 11.2031 7.87891 11.75 7.87891 12.4247C7.87891 13.0993 8.42581 13.6462 9.10044 13.6462Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.10044 2.98682C9.33325 2.98682 9.52198 2.7981 9.52198 2.56529C9.52198 2.33248 9.33325 2.14375 9.10044 2.14375C8.86764 2.14375 8.67891 2.33248 8.67891 2.56529C8.67891 2.7981 8.86764 2.98682 9.10044 2.98682ZM9.10044 3.78682C9.77508 3.78682 10.322 3.23992 10.322 2.56529C10.322 1.89065 9.77508 1.34375 9.10044 1.34375C8.42581 1.34375 7.87891 1.89065 7.87891 2.56529C7.87891 3.23992 8.42581 3.78682 9.10044 3.78682Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.54185 2.98682C2.77466 2.98682 2.96339 2.7981 2.96339 2.56529C2.96339 2.33248 2.77466 2.14375 2.54185 2.14375C2.30904 2.14375 2.12031 2.33248 2.12031 2.56529C2.12031 2.7981 2.30904 2.98682 2.54185 2.98682ZM2.54185 3.78682C3.21649 3.78682 3.76339 3.23992 3.76339 2.56529C3.76339 1.89065 3.21649 1.34375 2.54185 1.34375C1.86721 1.34375 1.32031 1.89065 1.32031 2.56529C1.32031 3.23992 1.86721 3.78682 2.54185 3.78682Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.29909 5.40145L2.86702 3.59339L3.49414 3.09668L4.92621 4.90474L4.29909 5.40145Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00989 8.3473L4.02705 9.54702L3.4082 9.04004L4.39104 7.84033L5.00989 8.3473Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.2564 11.7L6.35563 8.34343L7.05176 7.94922L8.95253 11.3058L8.2564 11.7Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7546 7.08501L7.75004 7.0705L7.75391 6.27051L10.7585 6.28502L10.7546 7.08501Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.77841 3.62759L7.37144 5.39811L6.74512 4.90039L8.15208 3.12987L8.77841 3.62759Z"
      fill="currentColor"
    />
  </SvgIcon>
)
