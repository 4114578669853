import * as t from 'io-ts'

export const TStartWeekDay = t.union([t.literal('SUNDAY'), t.literal('MONDAY')])

export type StartWeekDay = t.TypeOf<typeof TStartWeekDay>

export const TDaysOfWeek = t.union([
  t.literal('MONDAY'),
  t.literal('TUESDAY'),
  t.literal('WEDNESDAY'),
  t.literal('THURSDAY'),
  t.literal('FRIDAY'),
  t.literal('SATURDAY'),
  t.literal('SUNDAY'),
])

export type DaysOfWeek = t.TypeOf<typeof TDaysOfWeek>
