import { Box, Stack, Typography } from '@mui/material'
import { Placeholder, placeholders } from 'lib/records'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogContent } from 'ui/feedback'
import { Radio } from 'ui/inputs/radio'

type Props = Readonly<{
  isOpen: boolean
  onClose: () => void
  onSelect: (placeholder: Placeholder) => void
}>

export const AddPlaceholderDialog = ({ isOpen, onClose, onSelect }: Props) => {
  const { t } = useTranslation()

  const [selectedPlaceholderIndex, setSelectedPlaceholderIndex] =
    useState<string>('0')

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent
        title={t('common.choose_placeholder')}
        hint={t('hints.email_settings')}
        onDeny={onClose}
        onConfirm={() => {
          const selectedPlaceholder =
            placeholders[Number(selectedPlaceholderIndex)]

          if (selectedPlaceholder) {
            onSelect(selectedPlaceholder)
            onClose()
          }
        }}
        confirmText={t('common.select')}
      >
        <Stack direction="column">
          <Box sx={{ flex: '1 0 auto' }}>
            {placeholders.map(({ key, description }, index) => {
              return (
                <Box
                  key={key}
                  onClick={() => setSelectedPlaceholderIndex(index.toString())}
                  sx={{
                    cursor: 'pointer',
                    padding: '14px 0 12px',
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  <Radio
                    name="select-placeholder"
                    value={index.toString()}
                    selectedValue={selectedPlaceholderIndex}
                    sx={{ marginRight: '12px' }}
                    onChange={setSelectedPlaceholderIndex}
                  />
                  <Stack gap={1} direction="column">
                    <Typography variant="h3" fontWeight="400">
                      {key}
                    </Typography>
                    <Typography variant="body2">{description}</Typography>
                  </Stack>
                </Box>
              )
            })}
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
