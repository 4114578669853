import { Box } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import {
  getCompanyDepartments,
  getCompanyOffices,
  getCompanyPositions,
  getCompanyProjects,
  getJobPipelineTemplates,
  qk,
} from 'api'
import { CircularProgress, Dialog } from 'ui/feedback'

import { ManualSettingsSetup } from './manual-settings-setup'

const unifyCollection = <IdKey extends string>(
  idKey: IdKey,
  collection?: Array<
    { name: string } & { [key in IdKey]: string } & { isDisabled?: boolean }
  >,
) =>
  collection
    ? collection.map(item => ({
        name: item.name,
        id: item[idKey],
        isDisabled: item.isDisabled,
      }))
    : []

type Props = Readonly<{
  onCancel: () => void
  onSuccess: () => void
}>

export const ManualSettingsDialog = ({ onCancel, onSuccess }: Props) => {
  const $offices = useQuery(qk.company.offices.toKey(), getCompanyOffices)
  const $departments = useQuery(
    qk.company.departments.toKey(),
    getCompanyDepartments,
  )
  const $positions = useQuery(qk.company.positions.toKey(), getCompanyPositions)
  const $projects = useQuery(qk.company.projects.toKey(), getCompanyProjects)

  const $pipelineTemplates = useQuery(
    qk.company.pipelineTemplates.list.toKey(),
    getJobPipelineTemplates,
  )

  return (
    <Dialog open width="932px">
      {$offices.isLoading ||
      $departments.isLoading ||
      $positions.isLoading ||
      $projects.isLoading ||
      $pipelineTemplates.isLoading ? (
        <Box position="relative" height={720}>
          <CircularProgress centered />
        </Box>
      ) : (
        <ManualSettingsSetup
          initialOffices={unifyCollection('officeId', $offices.data)}
          initialDepartments={unifyCollection(
            'departmentId',
            $departments.data,
          )}
          initialPositions={unifyCollection('positionId', $positions.data)}
          initialProjects={unifyCollection('projectId', $projects.data)}
          initialPipelineTemplates={
            $pipelineTemplates.data
              ? $pipelineTemplates.data.rows.map(template => ({
                  ...template,
                  stages: template.stages.map(({ stageId, ...stage }) => stage),
                }))
              : []
          }
          onCancel={onCancel}
          onSuccess={onSuccess}
        />
      )}
    </Dialog>
  )
}
