import { Paper, Stack, Typography } from '@mui/material'
import { IconX } from 'assets/icons'
import { IconPlusCircle } from 'assets/icons'
import { Fieldset } from 'components/common'
import { CountryFlag } from 'components/languages'
import { useDictionaries } from 'lib/app-helpers'
import { useBoolean } from 'lib/react-utils'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'ui/inputs/icon-button'

import { AddLocationDialog, FormValues } from './add-location-dialog'

type Props = {
  values: FormValues
  changeLocationValues: (values: FormValues) => void
}

export const LocationFieldset = ({ values, changeLocationValues }: Props) => {
  const { t } = useTranslation()

  const showAddLocationDialog = useBoolean()

  const { dictionaries } = useDictionaries()

  const countryName = dictionaries.countries.data.find(
    ({ code }) => code === values.country,
  )?.name

  const hasLocation = values.country || values.city

  return (
    <>
      <Fieldset
        legend={t('common.location')}
        action={
          !hasLocation && (
            <IconButton onClick={showAddLocationDialog.setTrue}>
              <IconPlusCircle
                sx={{ fontSize: '20px', color: 'mediumPurple.main' }}
              />
            </IconButton>
          )
        }
      >
        {hasLocation ? (
          <Paper
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',

              py: '28px',
              px: '16px',
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              {values.country && (
                <>
                  <CountryFlag countryCode={values.country} size="lg" />
                  <Typography color="mineShaft.main" fontWeight={500}>
                    {countryName}
                  </Typography>
                </>
              )}

              {values.city && (
                <Typography color="mineShaft.main" fontWeight={400}>
                  {values.city}
                </Typography>
              )}
            </Stack>

            <IconButton
              onClick={() => changeLocationValues({ country: '', city: '' })}
            >
              <IconX sx={{ fontSize: '20px', color: 'error.main' }} />
            </IconButton>
          </Paper>
        ) : null}
      </Fieldset>

      {!hasLocation && (
        <AddLocationDialog
          isOpen={showAddLocationDialog.isTrue}
          onClose={showAddLocationDialog.setFalse}
          onConfirm={changeLocationValues}
          values={values}
        />
      )}
    </>
  )
}
