import { Box, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Badge } from 'ui/data'

type Props = Readonly<{
  name: string
  children: React.ReactNode
  actions?: React.ReactNode
  isDefault?: boolean
}>

export const PipelineTemplatePaper = ({
  name,
  children,
  actions,
  isDefault,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Paper sx={{ padding: 2 }}>
      <Box display="flex" alignItems="center" mb={3}>
        <Typography variant="h4" mr={2}>
          {name}
        </Typography>
        {isDefault && <Badge>{t('common.default')}</Badge>}
        <Box ml="auto">{actions}</Box>
      </Box>

      <Box>{children}</Box>
    </Paper>
  )
}
