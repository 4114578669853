import { Box } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { addContactComment } from 'api'
import { showToast } from 'lib/toast'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { CommentForm } from './comment-form'
type Props = {
  contactId: string
  refetch: () => void
}

export const AddCommentForm = ({ contactId, refetch }: Props) => {
  const { t } = useTranslation()

  const $addContactComment = useMutation(addContactComment)

  const addComment = useCallback(
    ({ message }: { message: string }) => {
      $addContactComment.mutate(
        {
          profileId: contactId,
          message,
        },
        {
          onSuccess: () => {
            showToast({
              title: t('toasts.added_comment'),
            })
            refetch()
          },
        },
      )
    },
    [$addContactComment, contactId, refetch, t],
  )

  return (
    <Box>
      <CommentForm message="" onSubmit={addComment} isAdding />
    </Box>
  )
}
