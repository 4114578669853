import { RecruiterId } from 'api/auth'
import { WorkModel } from 'api/jobs'
import {
  createListSearchParams,
  ListOrder,
  ListPagination,
} from 'api/list-params'
import { pipe } from 'fp-ts/function'
import {
  decodeJson,
  decodeJsonWithTotal,
  del,
  get,
  patch,
  post,
  put,
} from 'lib/request'

import {
  JobRequestStatus,
  TJobRequest,
  TJobRequestRow,
  TJobsCreatedFromJobRequest,
} from './job-requests.codecs'

export type JobRequestId = {
  jobRequestId: string
}

const JOB_REQUEST_PATH = 'api/job-requests/:jobRequestId'

export type GetJobRequestInput = JobRequestId

export const getJobRequestDetails = async ({
  jobRequestId,
}: GetJobRequestInput) => {
  return pipe(
    await get(JOB_REQUEST_PATH, { params: { jobRequestId } }),
    decodeJson(TJobRequest),
  )
}

type Filters = {
  statuses?: Array<string>
  positions?: Array<string>
  offices?: Array<string>
  departments?: Array<string>
  projects?: Array<string>
  createdBy?: Array<string>
  dateFrom?: Date | null
  dateTo?: Date | null
}

export type GetJobRequestsListInput = {
  pagination: ListPagination
  order: ListOrder
  filters: Filters
}

const generateFilters = (filters: Filters) => {
  const result = []

  if (filters.statuses && filters.statuses.length > 0) {
    result.push({
      filterType: 'statusFilter',
      statuses: filters.statuses,
    })
  }

  if (filters.positions && filters.positions.length > 0) {
    result.push({
      filterType: 'positionFilter',
      positionIds: filters.positions,
    })
  }

  if (filters.projects && filters.projects.length > 0) {
    result.push({
      filterType: 'projectFilter',
      projectIds: filters.projects,
    })
  }

  if (filters.offices && filters.offices.length > 0) {
    result.push({
      filterType: 'officeFilter',
      officeIds: filters.offices,
    })
  }

  if (filters.departments && filters.departments.length > 0) {
    result.push({
      filterType: 'departmentFilter',
      departmentIds: filters.departments,
    })
  }

  if (filters.createdBy && filters.createdBy.length > 0) {
    result.push({
      filterType: 'createdByFilter',
      creatorIds: filters.createdBy,
    })
  }

  if (filters.dateFrom || filters.dateTo) {
    result.push({
      filterType: 'createdAtFilter',
      from: filters.dateFrom ?? undefined,
      to: filters.dateTo ?? undefined,
    })
  }

  return result
}

export const getJobRequestsList = async ({
  pagination,
  order,
  filters,
}: GetJobRequestsListInput) => {
  const params = createListSearchParams({ pagination, order })
  const generatedFilters = generateFilters(filters)

  return pipe(
    await post('api/job-requests/list', {
      query: params,
      body:
        generatedFilters.length > 0 ? { filters: generatedFilters } : undefined,
    }),
    decodeJsonWithTotal(TJobRequestRow, pagination),
  )
}

export type UpdateJobRequestStatus = 'PENDING_APPROVAL' | 'DRAFT'

export type UpdateJobRequestInput = {
  status: UpdateJobRequestStatus
  office: string
  project?: string
  education?: string
  skills?: Array<{
    skillId: string
    skillLevelCode: string
  }>
  languages?: Array<{
    languageId: string
    levelCode: string
    order: number
  }>
  position: string
  qualificationLevel: string
  address?: {
    countryCode?: string
    city?: string
  }
  jobType: string
  targetDate: string
  reasonForHiring?: string
  modelCode: WorkModel
  minSalary?: number
  maxSalary?: number
  workExperience?: string
  jobDescription: string
  department: string
}

export const createJobRequest = async (input: UpdateJobRequestInput) => {
  return pipe(
    await post('api/job-requests', { body: input }),
    decodeJson(TJobRequest),
  )
}

export const updateJobRequest = async ({
  jobRequestId,
  ...values
}: JobRequestId & UpdateJobRequestInput) => {
  return await put(JOB_REQUEST_PATH, {
    params: { jobRequestId },
    body: values,
  })
}

export const deleteJobRequest = async ({ jobRequestId }: JobRequestId) => {
  return await del(JOB_REQUEST_PATH, { params: { jobRequestId } })
}

export type UpdateJobRequestStatusInput = JobRequestId &
  Partial<RecruiterId> & {
    status: JobRequestStatus
    comment?: string
  }

export const updateJobRequestStatus = async ({
  jobRequestId,
  status,
  recruiterId,
  comment,
}: UpdateJobRequestStatusInput) => {
  return await patch(JOB_REQUEST_PATH, {
    params: { jobRequestId },
    body: {
      status,
      comment,
      recruiter: recruiterId,
    },
  })
}

export const approveJobRequest = async ({ jobRequestId }: JobRequestId) => {
  return await post(`${JOB_REQUEST_PATH}/approvals`, {
    params: { jobRequestId },
  })
}

export const assignRecruiterToJobRequest = async ({
  jobRequestId,
  recruiterId,
}: JobRequestId & RecruiterId) => {
  return await post(`${JOB_REQUEST_PATH}/recruiter`, {
    params: { jobRequestId },
    body: { recruiterId },
  })
}

export const getJobsCreatedFromJobRequest = async ({
  jobRequestId,
}: JobRequestId) => {
  return pipe(
    await get(`${JOB_REQUEST_PATH}/jobs`, { params: { jobRequestId } }),
    decodeJson(TJobsCreatedFromJobRequest),
  )
}
