import { alpha, Stack, Tooltip, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getContactCompletenessInfo, qk } from 'api'
import { completenessStatuses } from 'lib/records'
import { useTranslation } from 'react-i18next'

type Props = {
  contactId: string
}

export const ContactCompleteness = ({ contactId }: Props) => {
  const { t } = useTranslation()

  const { data } = useQuery(
    qk.contacts.completeness.toKeyWithArgs({
      contactId,
    }),
    () => getContactCompletenessInfo({ contactId }),
  )

  if (!data) {
    return null
  }

  const status = completenessStatuses[data.completenessStatus]

  return (
    <Stack direction="column" spacing={0.5} alignItems="center">
      <Tooltip
        title={
          data.completenessStatus === 'ACCEPTABLE'
            ? t(status.tooltipText, {
                fields: data.notFilledFields.join(', '),
              }).toString()
            : t(status.tooltipText).toString()
        }
        placement="top"
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={1}
          sx={{
            height: 25,
            px: 1.5,
            backgroundColor: status.background,
            color: status.color,
            borderRadius: 50,
            outline: theme =>
              `1px solid ${alpha(theme.palette.mineShaft[300], 0.4)}`,
          }}
        >
          <status.Icon sx={{ fontSize: 18 }} />
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              color: status.color,
            }}
          >
            {t(`contacts.contacts_completeness.${data.completenessStatus}`)}
          </Typography>
        </Stack>
      </Tooltip>
    </Stack>
  )
}
