import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconViber = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#6F3FAA"
    />
    <path
      d="M18.3482 8.43395L18.3442 8.4181C18.0236 7.12187 16.5781 5.731 15.2506 5.44164L15.2356 5.43854C13.0885 5.02895 10.9113 5.02895 8.76466 5.43854L8.74924 5.44164C7.4222 5.731 5.97663 7.12196 5.65558 8.4181L5.65202 8.43395C5.25564 10.2441 5.25564 12.0799 5.65202 13.8901L5.65558 13.9059C5.96299 15.1468 7.30085 16.4739 8.5788 16.8388V18.2857C8.5788 18.8094 9.217 19.0667 9.57991 18.6887L11.0459 17.1648C11.3639 17.1826 11.6821 17.1925 12.0001 17.1925C13.0809 17.1925 14.1622 17.0903 15.2356 16.8856L15.2505 16.8825C16.578 16.5931 18.0235 15.2021 18.3442 13.906L18.3481 13.8902C18.7445 12.0799 18.7445 10.2442 18.3482 8.43395ZM17.188 13.6277C16.9739 14.4733 15.8763 15.5246 15.0043 15.7189C13.8626 15.936 12.7119 16.0288 11.5623 15.9969C11.5395 15.9963 11.5175 16.0052 11.5016 16.0216C11.3384 16.189 10.4312 17.1204 10.4312 17.1204L9.29271 18.2889C9.20946 18.3758 9.06321 18.3167 9.06321 18.1969V15.7999C9.06321 15.7603 9.03494 15.7267 8.99603 15.719C8.9958 15.7189 8.99561 15.7189 8.99538 15.7188C8.12331 15.5246 7.02616 14.4733 6.8117 13.6276C6.45494 11.9914 6.45494 10.3327 6.8117 8.69645C7.02616 7.85078 8.12331 6.79946 8.99538 6.60521C10.9893 6.226 13.0109 6.226 15.0043 6.60521C15.8768 6.79946 16.9739 7.85078 17.188 8.69645C17.5451 10.3327 17.5451 11.9914 17.188 13.6277Z"
      fill="white"
    />
    <path
      d="M13.8969 14.7342C13.7629 14.6934 13.6351 14.6661 13.5164 14.6169C12.2868 14.1068 11.1552 13.4487 10.2589 12.4399C9.74923 11.8662 9.35027 11.2186 9.01305 10.5331C8.85312 10.2081 8.71835 9.87038 8.58096 9.53476C8.45571 9.22876 8.64021 8.91263 8.83446 8.68201C9.01676 8.46558 9.25137 8.30002 9.50543 8.17791C9.70371 8.08266 9.89927 8.1376 10.0441 8.30565C10.3571 8.66902 10.6448 9.05096 10.8776 9.47218C11.0208 9.73126 10.9815 10.0479 10.7219 10.2242C10.6588 10.2671 10.6014 10.3174 10.5426 10.3658C10.4911 10.4082 10.4426 10.4511 10.4073 10.5085C10.3427 10.6135 10.3396 10.7375 10.3811 10.8518C10.7012 11.7312 11.2406 12.4151 12.1258 12.7835C12.2674 12.8424 12.4097 12.911 12.5729 12.892C12.8462 12.8601 12.9347 12.5603 13.1262 12.4036C13.3134 12.2506 13.5526 12.2486 13.7542 12.3762C13.9559 12.5039 14.1514 12.6408 14.3457 12.7793C14.5365 12.9153 14.7264 13.0482 14.9023 13.2032C15.0716 13.3522 15.1298 13.5477 15.0345 13.7498C14.8601 14.1202 14.6063 14.4282 14.2403 14.6249C14.1369 14.6803 14.0134 14.6983 13.8969 14.7342C13.7629 14.6934 14.0134 14.6983 13.8969 14.7342Z"
      fill="white"
    />
    <path
      d="M12.0035 7.75454C13.6117 7.79964 14.9327 8.86693 15.2158 10.4569C15.264 10.7279 15.2812 11.0048 15.3026 11.2798C15.3117 11.3955 15.2461 11.5053 15.1214 11.5069C14.9924 11.5084 14.9345 11.4005 14.926 11.285C14.9095 11.0561 14.898 10.8262 14.8664 10.5993C14.7 9.40126 13.7447 8.41009 12.5522 8.19742C12.3727 8.1654 12.1891 8.15701 12.0073 8.13793C11.8924 8.12589 11.7419 8.11895 11.7165 7.97607C11.6952 7.85631 11.7962 7.76096 11.9103 7.75482C11.9412 7.753 11.9723 7.75445 12.0035 7.75454C13.6118 7.79964 11.9723 7.75445 12.0035 7.75454Z"
      fill="white"
    />
    <path
      d="M14.4474 10.9229C14.4447 10.943 14.4434 10.9902 14.4316 11.0346C14.3889 11.1961 14.144 11.2163 14.0877 11.0534C14.071 11.0051 14.0685 10.95 14.0684 10.898C14.0679 10.5574 13.9939 10.2171 13.8221 9.92069C13.6455 9.61605 13.3757 9.36007 13.0593 9.20505C12.868 9.1114 12.6611 9.05313 12.4514 9.01849C12.3597 9.0033 12.2671 8.99416 12.175 8.98132C12.0634 8.9658 12.0038 8.89469 12.0091 8.78472C12.014 8.68169 12.0894 8.60749 12.2017 8.61391C12.5707 8.63482 12.9273 8.71469 13.2554 8.88841C13.9227 9.2418 14.3038 9.79952 14.4151 10.5443C14.4201 10.5781 14.4282 10.6115 14.4308 10.6453C14.4371 10.7289 14.441 10.8125 14.4474 10.9229C14.4447 10.9429 14.441 10.8125 14.4474 10.9229Z"
      fill="white"
    />
    <path
      d="M13.4472 10.8838C13.3126 10.8862 13.2406 10.8117 13.2267 10.6884C13.2171 10.6025 13.2094 10.5153 13.1889 10.4315C13.1485 10.2667 13.0609 10.114 12.9222 10.013C12.8568 9.96528 12.7826 9.93055 12.7049 9.90805C12.6062 9.8795 12.5036 9.88738 12.4053 9.86328C12.2983 9.83708 12.2392 9.75046 12.256 9.65019C12.2713 9.55888 12.3601 9.48763 12.4599 9.49489C13.0834 9.53989 13.529 9.86225 13.5927 10.5963C13.5972 10.6481 13.6024 10.7028 13.591 10.7525C13.5712 10.8372 13.5084 10.8797 13.4472 10.8838C13.3126 10.8862 13.5084 10.8797 13.4472 10.8838Z"
      fill="white"
    />
    <path
      d="M13.8971 14.7338C14.0136 14.6979 13.763 14.6931 13.8971 14.7338V14.7338Z"
      fill="white"
    />
    <path
      d="M12.0076 7.75457C12.0063 7.75457 12.005 7.75443 12.0036 7.75443C12.0023 7.75434 12.004 7.75443 12.0076 7.75457Z"
      fill="white"
    />
    <path
      d="M12.0037 7.75439C12.005 7.75439 12.0063 7.75454 12.0077 7.75454C12.0969 7.75772 13.5486 7.7977 12.0037 7.75439Z"
      fill="white"
    />
    <path
      d="M14.4474 10.9226C14.441 10.8122 14.4447 10.9426 14.4474 10.9226V10.9226Z"
      fill="white"
    />
    <path
      d="M13.4471 10.8835C13.4492 10.8834 13.4513 10.8823 13.4533 10.8821C13.4476 10.8821 13.4389 10.8822 13.4299 10.8826C13.4357 10.8827 13.441 10.8836 13.4471 10.8835Z"
      fill="white"
    />
    <path
      d="M13.447 10.8838C13.4409 10.8839 13.4356 10.883 13.4298 10.8828C13.4007 10.8836 13.3706 10.8852 13.447 10.8838Z"
      fill="white"
    />
    <path
      d="M13.4532 10.8821C13.451 10.8823 13.449 10.8834 13.447 10.8835C13.4628 10.8825 13.4614 10.8821 13.4532 10.8821Z"
      fill="white"
    />
  </SvgIcon>
)
