import { paths } from 'app/paths'
import { useScopes } from 'app/scopes'
import { useCheckEmailIntegration } from 'lib/app-helpers/use-check-email-integration'
import { useBoolean } from 'lib/react-utils'
import { ComposeEmailWindow } from 'pages/mailbox/compose-email-window'
import { ReactNode } from 'react'
import { Trans } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { Link } from 'ui/navigation'

type Props = Readonly<{
  candidateId: string
  isContactHasEmail: boolean
  renderButton: ({
    isDisabled,
    disabledTooltipTitle,
    onClick,
  }: {
    isDisabled: boolean
    disabledTooltipTitle: ReactNode
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  }) => ReactNode
}>

export const SendEmailButton = ({
  candidateId,
  isContactHasEmail,
  renderButton,
}: Props) => {
  const { t } = useTranslation()

  const { canManageCompanyIntegration } = useScopes()

  const { isEmailIntegrated, isLoadingIntegration } = useCheckEmailIntegration(
    {},
  )

  const isComposing = useBoolean()

  let disabledTooltipTitle: ReactNode = ''

  if (!isContactHasEmail) {
    disabledTooltipTitle = t('hints.add_email_for_messages')
  }

  if (!isEmailIntegrated) {
    disabledTooltipTitle = canManageCompanyIntegration ? (
      <Trans
        i18nKey="hints.integrate_email"
        components={{
          integrateLink: (
            <Link
              to={paths.settingsCompanyIntegrations}
              underline="always"
              sx={{ fontWeight: '500' }}
            />
          ),
        }}
      />
    ) : (
      t('hints.contact_owner_for_email_integration')
    )
  }

  return (
    <>
      {renderButton({
        isDisabled:
          !isContactHasEmail || !isEmailIntegrated || isLoadingIntegration,
        disabledTooltipTitle,
        onClick: event => {
          event.stopPropagation()
          isComposing.setTrue()
        },
      })}

      {isComposing.isTrue && (
        <ComposeEmailWindow
          isOpen={isComposing.isTrue}
          onClose={isComposing.setFalse}
          onSuccess={() => {
            isComposing.setFalse()
          }}
          candidateId={candidateId}
          isToFieldDisabled
        />
      )}
    </>
  )
}
