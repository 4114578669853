import { Stack } from '@mui/material'

type Props = {
  children: React.ReactNode
}

export const ControlsLine = (props: Props) => {
  return (
    <Stack spacing={3} direction="row">
      {props.children}
    </Stack>
  )
}
