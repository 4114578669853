import { useMutation } from '@tanstack/react-query'
import { generateJobDescription } from 'api'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'

type Props = Readonly<{
  positionId: string
  countryCode?: string
  qualificationLevel?: string
  onGenerated: (description: string) => void
}>

export const GenerateDescriptionButton = ({
  positionId,
  countryCode,
  qualificationLevel,
  onGenerated,
}: Props) => {
  const { t } = useTranslation()

  const $generateJobDescription = useMutation(generateJobDescription)

  return (
    <Button
      loading={$generateJobDescription.isLoading}
      onClick={() =>
        $generateJobDescription.mutate(
          {
            body: { positionId, countryCode, qualificationLevel },
          },
          { onSuccess: ({ description }) => onGenerated(description) },
        )
      }
    >
      {t('common.ai_generate')}
    </Button>
  )
}
