import { range } from 'fp-ts/NonEmptyArray'

import { Autocomplete, AutocompleteProps } from './autocomplete'

type Props = Omit<AutocompleteProps, 'options'> & {
  startYear: number
  endYear: number
}

export const YearPicker = ({ startYear, endYear, ...rest }: Props) => {
  const years = range(startYear, endYear).reverse()
  return (
    <Autocomplete
      {...rest}
      options={years.map(year => ({
        value: year.toString(),
        label: year.toString(),
      }))}
    />
  )
}
