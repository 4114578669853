import { Box, Drawer, Stack } from '@mui/material'
import { IconX } from 'assets/icons'
import logoImage from 'assets/images/logo.svg'
import { IconButton } from 'ui/inputs/icon-button'

import { NavigationItem } from './menu-navigation'
import { MenuNavigationItem } from './menu-navigation-item'

export const MenuDrawer = ({
  isOpen,
  toggle,
  navigationItems,
}: {
  isOpen: boolean
  toggle: () => void
  navigationItems: Array<NavigationItem>
}) => {
  return (
    <Drawer
      open={isOpen}
      onClose={toggle}
      PaperProps={{
        sx: {
          border: 'none',
          background: `linear-gradient(300deg, #D08EF3 0%, #FFD7AE 100%)`,
        },
      }}
      sx={{
        border: 'none',
      }}
    >
      <Stack width={250} p={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={2}
          mb={1}
        >
          <Box
            component="img"
            src={logoImage}
            alt="logo"
            display="block"
            width={32}
          />

          <IconButton onClick={toggle}>
            <IconX
              sx={{
                fontSize: '20px',
              }}
            />
          </IconButton>
        </Stack>

        <Stack gap={1}>
          {navigationItems.map(navigation => (
            <Stack key={`drawer-${navigation.path}`} direction="row">
              <MenuNavigationItem
                navigation={navigation}
                toggle={toggle}
                sx={{
                  alignItems: 'left',
                  justifyContent: 'left',
                }}
              />
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Drawer>
  )
}
