import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import {
  getCompanyJobTypes,
  getCompanyPositions,
  getDonorCompanies,
  qk,
} from 'api'
import { ControlsLine } from 'components/common'
import { CityAutocomplete } from 'components/location/city-autocomplete'
import { CountryAutocomplete } from 'components/location/country-autocomplete'
import { useDictionaries, useFormatLocation } from 'lib/app-helpers'
import { getInputError } from 'lib/form-utils'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { DialogContent } from 'ui/feedback'
import { AutocompleteFreeSolo } from 'ui/inputs/autocomplete'
import { Checkbox } from 'ui/inputs/checkbox'
import { FormControl } from 'ui/inputs/common'
import { MonthPicker } from 'ui/inputs/month-picker'
import { renderSelect, Select } from 'ui/inputs/select'
import { renderTextField } from 'ui/inputs/text-field'
import { YearPicker } from 'ui/inputs/year-picker'
import { z } from 'zod'

export const buildWorkExperienceSchema = (t: TFunction) =>
  z
    .object({
      positionValue: z.string().trim().min(1).max(220),
      position: z.object({
        id: z.optional(z.string()),
        name: z.string(),
      }),
      level: z.string().min(1),
      jobTypeId: z.string(),
      jobTypeName: z.string(),
      companyValue: z.string().trim().min(1).max(100),
      company: z.object({
        id: z.optional(z.string()),
        name: z.string().min(1).max(100),
      }),
      country: z.string(),
      city: z.string(),
      startDate: z.object({
        month: z.string().min(1),
        year: z.string().min(1),
      }),
      endDate: z.object({
        month: z.string(),
        year: z.string(),
      }),
      currentEmployment: z.boolean(),
      description: z.string().trim().min(3).max(500).or(z.literal('')),
    })
    .refine(
      ({ endDate, currentEmployment }) => endDate.year || currentEmployment,
      { message: t('validations.required'), path: ['endDate.year'] },
    )
    .refine(
      ({ endDate, currentEmployment }) => endDate.month || currentEmployment,
      { message: t('validations.required'), path: ['endDate.month'] },
    )
    .refine(
      ({ startDate, endDate }) => {
        if (!startDate.year || !endDate.year) return true
        return Number(endDate.year) >= Number(startDate.year)
      },
      {
        message: t('validations.work_experience.end_year_validation'),
        path: ['endDate.year'],
      },
    )
    .refine(
      ({ startDate, endDate }) => {
        const wrongEndMonth =
          startDate.year &&
          startDate.month &&
          endDate.year &&
          endDate.month &&
          startDate.year === endDate.year &&
          Number(endDate.month) <= Number(startDate.month)

        return !wrongEndMonth
      },
      {
        message: t('validations.work_experience.end_month_validation'),
        path: ['endDate.month'],
      },
    )

type FormValues = z.infer<ReturnType<typeof buildWorkExperienceSchema>>

type Props = Readonly<{
  title: string
  confirmText: string
  onCancel: () => void
  onSubmit: (values: FormValues) => void
  initialValues?: FormValues
}>

const defaultValues: FormValues = {
  positionValue: '',
  position: { name: '' },
  companyValue: '',
  company: { name: '' },
  jobTypeId: '',
  jobTypeName: '',
  level: '',
  country: '',
  city: '',
  startDate: {
    month: '',
    year: '',
  },
  endDate: {
    month: '',
    year: '',
  },
  currentEmployment: false,
  description: '',
}

export const WorkExperienceForm = ({
  title,
  confirmText,
  onCancel,
  onSubmit,
  initialValues,
}: Props) => {
  const { t } = useTranslation()
  const schema = useMemo(() => buildWorkExperienceSchema(t), [t])
  const formatLocation = useFormatLocation()

  const { handleSubmit, control, watch, setValue } = useForm<FormValues>({
    defaultValues: initialValues ?? defaultValues,
    resolver: zodResolver(schema),
  })

  const { dictionaries, isDictionariesError } = useDictionaries()
  const $positions = useQuery(qk.company.positions.toKey(), getCompanyPositions)
  const $jobTypes = useQuery(qk.company.jobTypes.toKey(), getCompanyJobTypes)

  const $donors = useQuery(
    qk.company.donorCompanies.toKeyWithArgs({ order: null }),
    () => getDonorCompanies({ order: null }),
  )

  const [country, currentEmployment] = watch(['country', 'currentEmployment'])

  const startDateYear = watch('startDate.year')
  const currentYear = new Date().getFullYear()

  const endDateYearRange = useMemo(() => {
    const startYear = startDateYear ? Number(startDateYear) : currentYear - 100
    const endYear = currentYear
    return { startYear, endYear }
  }, [startDateYear, currentYear])

  return (
    <DialogContent
      wrapWithForm
      title={title}
      onConfirm={handleSubmit(onSubmit)}
      onDeny={onCancel}
      confirmText={confirmText}
      isConfirmDisabled={!$donors.data}
    >
      <Stack spacing={3}>
        <ControlsLine>
          <Controller
            control={control}
            name="positionValue"
            render={({ field, fieldState }) => (
              <AutocompleteFreeSolo
                required
                value={field.value}
                onChange={(value, option) => {
                  field.onChange(value)
                  setValue(
                    'position',
                    option
                      ? { id: option.value, name: option.label }
                      : { name: value },
                  )
                }}
                {...getInputError(fieldState.error)}
                label={t('common.position')}
                dataCy="workExperiencePositionInput"
                placeholder={t('common.position_placeholder')}
                loadingError={$positions.isError}
                options={
                  $positions.data?.map(position => ({
                    value: position.positionId,
                    label: position.name,
                  })) ?? []
                }
              />
            )}
          />

          <Controller
            control={control}
            name="level"
            render={renderSelect({
              label: t('common.qualification_level'),
              loadingError: isDictionariesError,
              disableAlphabeticalOrder: true,
              disableEmptyOption: true,
              required: true,
              options: dictionaries.qualificationLevels.options,
              dataCy: 'workExperienceLevelSelect',
            })}
          />
        </ControlsLine>

        <ControlsLine>
          <Controller
            control={control}
            name="jobTypeId"
            render={({ field, fieldState }) => (
              <Select
                value={field.value}
                onChange={(value, option) => {
                  field.onChange(value)
                  setValue('jobTypeName', option?.label ?? '')
                }}
                {...getInputError(fieldState.error)}
                label={t('common.type_of_employment')}
                loadingError={$jobTypes.isError}
                options={
                  $jobTypes.data?.map(jobType => ({
                    value: jobType.jobTypeId,
                    label: jobType.name,
                  })) ?? []
                }
                dataCy="workExperienceJobTypeSelect"
              />
            )}
          />

          {$donors.data && (
            <Controller
              control={control}
              name="companyValue"
              render={({ field, fieldState }) => (
                <AutocompleteFreeSolo
                  required
                  value={field.value}
                  dataCy="workExperienceCompanyInput"
                  onChange={value => {
                    field.onChange(value)
                    const selectedDonor = $donors.data.find(
                      donor => donor.donorId === value,
                    )

                    setValue(
                      'company',
                      selectedDonor
                        ? {
                            id: selectedDonor.donorId,
                            name: selectedDonor.name,
                          }
                        : { name: value },
                    )

                    if (selectedDonor && selectedDonor.address) {
                      setValue(
                        'country',
                        selectedDonor.address.country?.code ?? '',
                      )
                      setValue('city', selectedDonor.address.city ?? '')
                    }
                  }}
                  {...getInputError(fieldState.error)}
                  label={t('common.company')}
                  placeholder={t('common.company_placeholder')}
                  loadingError={$donors.isError}
                  options={$donors.data.map(donor => {
                    const location = formatLocation(
                      donor.address?.country?.code,
                      donor.address?.city,
                    )

                    return {
                      value: donor.donorId,
                      label: donor.name,
                      content: (
                        <Box>
                          <Typography variant="inherit">
                            {donor.name}
                          </Typography>
                          {location && (
                            <Typography
                              variant="caption"
                              color="text.secondary"
                              mt={0.5}
                            >
                              {location}
                            </Typography>
                          )}
                        </Box>
                      ),
                    }
                  })}
                />
              )}
            />
          )}
        </ControlsLine>

        <ControlsLine>
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <CountryAutocomplete
                label={t('common.country')}
                placeholder={t('common.select_option')}
                dataCy="workExperienceCountryAutocomplete"
                value={field.value}
                onChange={value => {
                  field.onChange(value)
                  setValue('city', '')
                }}
              />
            )}
          />

          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <CityAutocomplete
                label={t('common.city')}
                value={field.value}
                onChange={field.onChange}
                country={country}
                dataCy="workExperienceCityAutocomplete"
                placeholder={t('common.start_typing')}
              />
            )}
          />
        </ControlsLine>

        <FormControl required label={t('common.start_date')}>
          <Stack spacing={3} direction="row" width="100%">
            <Controller
              control={control}
              name="startDate.month"
              render={({ field, fieldState }) => (
                <MonthPicker
                  value={field.value}
                  onChange={field.onChange}
                  {...getInputError(fieldState.error)}
                  placeholder={t('common.select_month')}
                  dataCy="workExperienceStartDateMonthPicker"
                />
              )}
            />

            <Controller
              control={control}
              name="startDate.year"
              render={({ field, fieldState }) => (
                <YearPicker
                  value={field.value}
                  onChange={field.onChange}
                  {...getInputError(fieldState.error)}
                  startYear={new Date().getFullYear() - 100}
                  endYear={new Date().getFullYear()}
                  placeholder={t('common.select_year')}
                  dataCy="workExperienceStartDateYearPicker"
                />
              )}
            />
          </Stack>
        </FormControl>

        <FormControl required={!currentEmployment} label={t('common.end_date')}>
          <Stack spacing={3} direction="row" width="100%">
            <Controller
              control={control}
              name="endDate.month"
              render={({ field, fieldState }) => (
                <MonthPicker
                  value={field.value}
                  onChange={field.onChange}
                  {...getInputError(fieldState.error)}
                  disabled={currentEmployment}
                  placeholder={t('common.select_month')}
                  dataCy="workExperienceEndDateMonthPicker"
                />
              )}
            />

            <Controller
              control={control}
              name="endDate.year"
              render={({ field, fieldState }) => (
                <YearPicker
                  value={field.value}
                  onChange={field.onChange}
                  {...getInputError(fieldState.error)}
                  startYear={endDateYearRange.startYear}
                  endYear={endDateYearRange.endYear}
                  disabled={currentEmployment}
                  placeholder={t('common.select_year')}
                  dataCy="workExperienceEndDateYearPicker"
                />
              )}
            />
          </Stack>
        </FormControl>

        <Box>
          <Controller
            control={control}
            name="currentEmployment"
            render={({ field }) => (
              <Checkbox
                checked={field.value}
                onChange={checked => {
                  field.onChange(checked)
                  if (checked) {
                    setValue('endDate.month', '', { shouldValidate: true })
                    setValue('endDate.year', '', { shouldValidate: true })
                  }
                }}
                label={t('common.current_employment')}
                dataCy="workExperienceCurrentEmploymentCheckbox"
              />
            )}
          />
        </Box>

        <Box>
          <Controller
            control={control}
            name="description"
            render={renderTextField({
              label: t('common.description'),
              multiline: true,
              resizable: false,
              minRows: 3,
              maxRows: 7,
              inputProps: { 'data-cy': 'workExperienceDescriptionInput' },
            })}
          />
        </Box>
      </Stack>
    </DialogContent>
  )
}
