import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCardOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      d="M0 5C0 3.61929 1.11929 2.5 2.5 2.5H17.5C18.8807 2.5 20 3.61929 20 5V15C20 16.3807 18.8807 17.5 17.5 17.5H2.5C1.11929 17.5 0 16.3807 0 15V5ZM2.5 3.75C1.80964 3.75 1.25 4.30964 1.25 5V6.25H18.75V5C18.75 4.30964 18.1904 3.75 17.5 3.75H2.5ZM18.75 8.75H1.25V15C1.25 15.6904 1.80964 16.25 2.5 16.25H17.5C18.1904 16.25 18.75 15.6904 18.75 15V8.75Z"
      fill="currentColor"
    />
    <path
      d="M2.5 12.5C2.5 11.8096 3.05964 11.25 3.75 11.25H5C5.69036 11.25 6.25 11.8096 6.25 12.5V13.75C6.25 14.4404 5.69036 15 5 15H3.75C3.05964 15 2.5 14.4404 2.5 13.75V12.5Z"
      fill="currentColor"
    />
  </SvgIcon>
)
