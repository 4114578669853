import { Box, Divider, Stack, Tooltip } from '@mui/material'
import { IconPlusCircle } from 'assets/icons'
import { Fieldset } from 'components/common'
import { WorkExperienceSinglePosition } from 'components/contacts'
import { useBoolean } from 'lib/react-utils'
import { useEffect, useState } from 'react'
import { Control, FieldArrayWithId, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'ui/feedback'
import { IconButton } from 'ui/inputs/icon-button'

import { labels } from '../_labels'
import { FormValues } from '../_values'
import { WorkExperienceForm } from './work-experience-form'

const MAX_POSITIONS = 20

type Props = Readonly<{
  control: Control<FormValues>
}>

export const WorkExperience = ({ control }: Props) => {
  const { t } = useTranslation()
  const isAddingNewExperience = useBoolean()

  const { fields, remove, replace, prepend, update } = useFieldArray({
    control,
    name: 'workExperience',
  })

  const [experienceToEdit, setExperienceToEdit] = useState<{
    values: FieldArrayWithId<FormValues, 'workExperience', 'id'>
    index: number
  } | null>(null)

  // After user adds or updates the experience, we need to update the order of fields by descending end date
  const isWaitingToSortFields = useBoolean()
  useEffect(() => {
    if (isWaitingToSortFields.isTrue) {
      isWaitingToSortFields.setFalse()
      replace(
        [...fields].sort((expA, expB) => {
          if (expA.currentEmployment && expB.currentEmployment) {
            return expA.position.name.localeCompare(expB.position.name)
          }

          if (expA.currentEmployment) {
            return -1
          }

          if (expB.currentEmployment) {
            return 1
          }

          const expAendDate = new Date(
            Number(expA.endDate.year),
            Number(expA.endDate.month),
          )
          const expBendDate = new Date(
            Number(expB.endDate.year),
            Number(expB.endDate.month),
          )

          return expBendDate.getTime() - expAendDate.getTime()
        }),
      )
    }
  }, [fields, replace, isWaitingToSortFields])

  return (
    <Fieldset
      legend={t(labels.workExperience).toString()}
      action={
        <Tooltip
          placement="top"
          title={
            fields.length === MAX_POSITIONS
              ? t('common.max_positions_reached')
              : ''
          }
        >
          <IconButton
            disabled={fields.length === MAX_POSITIONS}
            onClick={isAddingNewExperience.setTrue}
          >
            <IconPlusCircle
              sx={{ fontSize: '20px', color: 'mediumPurple.main' }}
            />
          </IconButton>
        </Tooltip>
      }
    >
      <Box>
        {fields.length > 0 && (
          <Stack spacing={3} divider={<Divider flexItem />} mb={3}>
            {fields.map((field, index) => (
              <WorkExperienceSinglePosition
                key={field.id}
                companyName={field.company.name}
                positionName={field.position.name}
                startDate={
                  new Date(
                    Number(field.startDate.year),
                    Number(field.startDate.month),
                  )
                }
                endDate={
                  field.endDate.year && field.endDate.month
                    ? new Date(
                        Number(field.endDate.year),
                        Number(field.endDate.month),
                      )
                    : undefined
                }
                jobTypeName={field.jobTypeName}
                levelCode={field.level}
                description={field.description}
                country={field.country}
                city={field.city}
                onEdit={() => {
                  setExperienceToEdit({ index, values: field })
                }}
                onDelete={() => {
                  remove(index)
                }}
              />
            ))}
          </Stack>
        )}

        <Dialog
          open={isAddingNewExperience.isTrue}
          onClose={isAddingNewExperience.setFalse}
        >
          <WorkExperienceForm
            title={t('common.create_position')}
            onCancel={isAddingNewExperience.setFalse}
            confirmText={t('common.create')}
            onSubmit={values => {
              isAddingNewExperience.setFalse()
              prepend(values)
              isWaitingToSortFields.setTrue()
            }}
          />
        </Dialog>

        <Dialog
          open={experienceToEdit !== null}
          onClose={() => setExperienceToEdit(null)}
        >
          {experienceToEdit !== null && (
            <WorkExperienceForm
              title={t('common.edit_position')}
              onCancel={() => setExperienceToEdit(null)}
              confirmText={t('common.save')}
              onSubmit={values => {
                update(experienceToEdit.index, values)
                isWaitingToSortFields.setTrue()
                setExperienceToEdit(null)
              }}
              initialValues={experienceToEdit.values}
            />
          )}
        </Dialog>
      </Box>
    </Fieldset>
  )
}
