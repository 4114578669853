import { Stack } from '@mui/material'
import defaultUserImage from 'assets/images/defaultUser.svg'
import { useState } from 'react'
import { Avatar } from 'ui/data'

import { AvatarUploader } from './avatar-uploader'

type Props = Readonly<{
  value: string
  onChange: (value: string) => void
  withCrop?: boolean
  initialSrc?: string
}>

export const AvatarFieldset = ({ withCrop, initialSrc, onChange }: Props) => {
  const [src, setSrc] = useState(initialSrc)

  return (
    <Stack
      minHeight={120}
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={{ xs: 'center', sm: 'flex-start' }}
    >
      <Avatar src={src || defaultUserImage} size={120} />

      <AvatarUploader
        onUpload={(newImageId, newSrc) => {
          onChange(newImageId)
          setSrc(newSrc)
        }}
        onDelete={() => {
          onChange('')
          setSrc('')
        }}
        withSrc={!!src}
        withCrop={withCrop}
      />
    </Stack>
  )
}
