import { Box, Link, Typography } from '@mui/material'

export const ContactLink = ({
  linkHref,
  faviconBase64Data,
}: {
  linkHref: string
  faviconBase64Data: string
}) => {
  return (
    <Box display="flex" alignItems="center">
      <img
        src={`data:image/png;base64,${faviconBase64Data}`}
        width={16}
        alt=""
      />

      <Typography
        variant="body2"
        component={Link}
        href={linkHref}
        target="_blank"
        noWrap
        underline="none"
        sx={{
          color: 'blue.main',
          ml: 1.25,
          mr: 0.5,
        }}
      >
        {linkHref}
      </Typography>
    </Box>
  )
}
