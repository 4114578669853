import { createListSearchParams, ListPagination } from 'api/list-params'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { decodeJsonWithTotal, post } from 'lib/request'

export type GetCitiesListInput = {
  pagination: ListPagination
  countryCode: string
  search?: string
}

export const getCitiesList = async ({
  pagination,
  countryCode,
  search,
}: GetCitiesListInput) => {
  const params = createListSearchParams({ pagination })

  return pipe(
    await post('api/cities/search', {
      query: params,
      body: {
        search: search !== '' ? search : undefined,
        countryCode,
      },
    }),
    decodeJsonWithTotal(t.string, pagination),
  )
}
