import { TProfile } from 'api/common'
import * as t from 'io-ts'

export const TDepartmentApprover = t.strict({
  department: t.strict({
    departmentId: t.string,
    name: t.string,
  }),
  approvers: t.array(TProfile),
})

export type DepartmentApprover = t.TypeOf<typeof TDepartmentApprover>

export const TDepartmentApprovers = t.array(TDepartmentApprover)
