import SvgIcon from '@mui/material/SvgIcon'

import { FilledIcon } from '../icon-props'

export const IconActivity = ({ filled, ...props }: FilledIcon) =>
  filled ? (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM8.53161 4.83078C8.20024 4.83078 7.93161 5.09941 7.93161 5.43078C7.93161 5.76215 8.20024 6.03078 8.53161 6.03078H11.1211L5.00655 12.1452C4.77223 12.3796 4.77223 12.7595 5.00654 12.9938C5.24085 13.2281 5.62075 13.2281 5.85507 12.9938L11.9697 6.87929V9.44147C11.9697 9.77284 12.2383 10.0415 12.5697 10.0415C12.9011 10.0415 13.1697 9.77284 13.1697 9.44147V5.43078C13.1697 5.09941 12.9011 4.83078 12.5697 4.83078H8.53161Z"
        fill="currentColor"
      />
    </SvgIcon>
  ) : (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.93152 5.43057C7.93152 5.0992 8.20015 4.83057 8.53152 4.83057H12.5696C12.901 4.83057 13.1696 5.0992 13.1696 5.43057V9.44125C13.1696 9.77263 12.901 10.0413 12.5696 10.0413C12.2382 10.0413 11.9696 9.77263 11.9696 9.44125V6.87907L5.85498 12.9936C5.62066 13.2279 5.24076 13.2279 5.00645 12.9936C4.77214 12.7592 4.77214 12.3793 5.00646 12.145L11.1211 6.03057H8.53152C8.20015 6.03057 7.93152 5.76194 7.93152 5.43057Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 16.8C13.3078 16.8 16.8 13.3078 16.8 9C16.8 4.69218 13.3078 1.2 9 1.2C4.69218 1.2 1.2 4.69218 1.2 9C1.2 13.3078 4.69218 16.8 9 16.8ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
