import { Box, Divider, Typography, useTheme } from '@mui/material'
import {
  IconCheckCircle,
  IconInfoCircleOutline,
  IconWarning,
} from 'assets/icons'
import { ReactNode } from 'react'

import { CircularProgress } from '../circular-progress'

export type CommonToastType = 'success' | 'error' | 'warning' | 'info'
export type PromiseToastType = 'promise'

export type CommonToastProps = {
  title: string
  body?: ReactNode
  colorBackground?: boolean
}

export type CommonToastContentProps = CommonToastProps & {
  type?: CommonToastType
}

export type PromiseToastContentProps = CommonToastProps & {
  type: PromiseToastType
}

const getIcon = (type: CommonToastType | PromiseToastType) => {
  switch (type) {
    case 'success': {
      return IconCheckCircle
    }
    case 'error':
    case 'warning': {
      return IconWarning
    }
    case 'info': {
      return IconInfoCircleOutline
    }
    case 'promise': {
      return () => <CircularProgress size={16} />
    }
  }
}

const useColor = (type: CommonToastType | PromiseToastType) => {
  const theme = useTheme()

  switch (type) {
    case 'promise':
    case 'success': {
      return theme.palette.primary.main
    }
    case 'error': {
      return theme.palette.error.main
    }
    case 'info': {
      return theme.palette.info.main
    }
    case 'warning': {
      return theme.palette.warning.main
    }
  }
}

export const ToastContent = ({
  title,
  type = 'success',
  body,
  colorBackground,
}: CommonToastContentProps | PromiseToastContentProps) => {
  const color = useColor(type)
  const Icon = getIcon(type)

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          minHeight: '42px',
          bgcolor: colorBackground ? '#FFEECC' : 'transparent',
          p: 1.5,
          pr: 5,
          position: 'relative',

          '&::before': {
            content: '""',
            position: 'absolute',
            left: '4px',
            top: '4px',
            bottom: '4px',
            width: '2px',
            background: theme =>
              colorBackground ? theme.palette.flushOrange.main : color,
          },
        }}
      >
        <Box
          sx={{
            color: colorBackground ? 'flushOrange' : color,
            mr: 1,
            display: 'flex',
          }}
        >
          <Icon sx={{ fontSize: '16px' }} />
        </Box>

        <Typography variant="body2" color="text.primary">
          {title}
        </Typography>
      </Box>

      {body && (
        <>
          <Divider />
          <Box
            component={typeof body === 'string' ? 'p' : 'div'}
            sx={{
              px: 1.5,
              py: 1,
              m: 0,
              overflow: 'auto',
              typography: 'body2',
              color: 'greyBlue.main',
            }}
          >
            {body}
          </Box>
        </>
      )}
    </Box>
  )
}
