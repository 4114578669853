import { useAuthContext } from 'app/auth'
import { toReadonlyArray } from 'fp-ts/ReadonlyRecord'
import { useMemo } from 'react'

import { scopes } from './scopes'

export type Scope = keyof typeof scopes
export type Scopes = Record<Scope, boolean>

export const useScopes = () => {
  const { user } = useAuthContext()

  return useMemo(
    () =>
      toReadonlyArray(scopes).reduce(
        (result, [key, scope]) => ({
          ...result,
          [key]:
            user.state === 'authenticated'
              ? user.scopes.includes(scope)
              : false,
        }),
        {} as Scopes,
      ),
    [user],
  )
}
