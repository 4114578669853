import { Typography, useTheme } from '@mui/material'
import { useCallback } from 'react'

export const CustomXTick = ({
  x,
  y,
  value,
  rotate,
  onGetRefElement,
  ...rest // Pass through the rest of tick props
}: {
  x: number
  y: number
  value: string
  rotate: boolean
  onGetRefElement: (value: string, element: SVGTextElement) => void
}) => {
  const theme = useTheme()

  const handleRef = useCallback(
    (element: SVGTextElement) => {
      onGetRefElement(value, element)
    },
    [value, onGetRefElement],
  )

  return (
    <Typography
      {...rest}
      ref={handleRef}
      x={x}
      y={y}
      component="text"
      variant="caption"
      fill={theme.palette.text.primary}
      transform={rotate ? `rotate(65, ${x}, ${y})` : ''}
    >
      {value.length > 40 ? value.slice(0, 40) + '...' : value}
    </Typography>
  )
}
