import {
  FormControlLabel as MuiFormControlLabel,
  formControlLabelClasses,
  FormControlLabelProps,
  styled,
} from '@mui/material'

export const FormControlLabel = styled(MuiFormControlLabel, {
  shouldForwardProp: prop => prop !== 'small',
})<FormControlLabelProps & { small?: boolean }>(({ theme, small }) => ({
  margin: 0,

  [`& .${formControlLabelClasses.label}`]: {
    marginLeft: '8px',
    ...(small && {
      fontSize: '0.875rem',
    }),

    '&.Mui-disabled': {
      color: theme.palette.text.primary,
    },
  },
}))
