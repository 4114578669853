import { Link, Stack } from '@mui/material'
import { IconQuestionCircleOutline } from 'assets/icons'
import { AXTERIOR_HELP_CENTER_LINK } from 'lib/records'
import { useTranslation } from 'react-i18next'

export const HelpCenterLink = () => {
  const { t } = useTranslation()

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <IconQuestionCircleOutline
        sx={{
          fontSize: 16,
          color: 'greyBlue.light',
        }}
      />
      <Link
        href={AXTERIOR_HELP_CENTER_LINK}
        sx={{
          fontSize: 14,
          textDecoration: 'none',
          color: 'text.primary',
          ':hover': {
            color: 'primary.main',
            textDecoration: 'underline',
          },
        }}
        target="_blank"
      >
        {t('support.help_center')}
      </Link>
    </Stack>
  )
}
