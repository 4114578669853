import { Box } from '@mui/material'
import { To } from 'react-router-dom'
import { Avatar } from 'ui/data/avatar'
import { Link } from 'ui/navigation/link'
import { TruncateWithTooltip } from 'ui/utils'

type Props = {
  name: string
  position?: string
  avatar?: string
  linkTo?: To
  linkStopPropagation?: boolean
}

export const ContactProfile = ({
  avatar,
  name,
  position,
  linkTo,
  linkStopPropagation,
}: Props) => {
  return (
    <Box display="flex" alignItems="center">
      <Avatar size={32} src={avatar} alt={name} />
      <Box ml={1}>
        {linkTo ? (
          <Link
            to={linkTo}
            boldOnHover
            data-text={name}
            typography="body2"
            maxWidth={200}
            onClick={event => {
              if (linkStopPropagation) {
                event.stopPropagation()
              }
            }}
          >
            <TruncateWithTooltip variant="body2" maxWidth={200}>
              {name}
            </TruncateWithTooltip>
          </Link>
        ) : (
          <TruncateWithTooltip variant="body2" maxWidth={200}>
            {name}
          </TruncateWithTooltip>
        )}
        <TruncateWithTooltip
          variant="caption"
          color="text.secondary"
          display="block"
          maxWidth={200}
          mt={0.25}
        >
          {position}
        </TruncateWithTooltip>
      </Box>
    </Box>
  )
}
