import { useMutation } from '@tanstack/react-query'
import { sendEmail } from 'api/mailbox'
import { EmailForm } from 'components/email'
import { ResizableWindowTemplate } from 'components/templates'
import { showToast } from 'lib/toast'
import { useTranslation } from 'react-i18next'

type Props = Readonly<{
  isOpen: boolean
  onSuccess: () => void
  onClose: () => void
  candidateId?: string
  isToFieldDisabled?: boolean
}>

export const ComposeEmailWindow = ({
  isOpen,
  onSuccess,
  onClose,
  candidateId,
  isToFieldDisabled,
}: Props) => {
  const { t } = useTranslation()

  const $sendNewEmail = useMutation(sendEmail, {
    onSuccess: () => {
      showToast({ title: t('toasts.email_is_sent') })
      onSuccess()
    },
  })

  return (
    <ResizableWindowTemplate
      isOpen={isOpen}
      title={t('common.new_email')}
      onClose={onClose}
    >
      <EmailForm
        defaultValues={{
          to: candidateId ?? '',
          subject: '',
          body: '',
        }}
        onSubmit={values => {
          $sendNewEmail.mutate({ ...values, contactId: values.to })
        }}
        isToFieldDisabled={isToFieldDisabled}
        isLoading={$sendNewEmail.isLoading}
      />
    </ResizableWindowTemplate>
  )
}
