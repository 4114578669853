import {
  Box,
  MenuItem as MuiMenuItem,
  menuItemClasses,
  MenuItemProps,
  styled,
} from '@mui/material'
import { IconArrowRightShort } from 'assets/icons'
import { ConfirmDialogProps, useConfirmationDialog } from 'lib/app-helpers'
import { useBoolean } from 'lib/react-utils'
import { useRef } from 'react'

import { Menu } from './menu'

const StyledMenuItem = styled(MuiMenuItem)(({ theme }) => ({
  width: '100%',

  ...theme.typography.body2,
  color: theme.palette.mineShaft.main,
  padding: theme.spacing(1.25, 1.5),

  '&:hover': {
    background: theme.palette.alabaster,
  },

  '&:focus': {
    background: theme.palette.alabaster,
  },

  [`&.${menuItemClasses.divider}`]: {
    borderColor: theme.palette.divider,
  },

  [`&.${menuItemClasses.selected}`]: {
    background: theme.palette.alabaster,

    '&:hover': {
      background: theme.palette.alabaster,
    },
  },
}))

export const MenuItem = <C extends React.ElementType>({
  confirm,
  onClick,
  children,
  subMenuItems,
  displayArrow,
  ...props
}: // TS composition caveat. Allows to pass custom component.
MenuItemProps<C, { component?: C }> & {
  confirm?: ConfirmDialogProps
  subMenuItems?: React.ReactNode
  displayArrow?: boolean
}) => {
  const { openDialog, renderConfirmDialog } = useConfirmationDialog(onClick)

  const subMenuOpened = useBoolean()
  const subMenuAnchorRef = useRef<HTMLLIElement | null>(null)
  const subMenuContainerRef = useRef<HTMLElement | null>(null)

  return (
    <>
      <StyledMenuItem
        {...props}
        selected={subMenuOpened.isTrue}
        ref={subMenuAnchorRef}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          if (subMenuItems) return

          if (confirm) {
            openDialog(event)
          } else if (onClick) {
            onClick(event)
          }
        }}
        {...(subMenuItems && {
          onMouseEnter: subMenuOpened.setTrue,
          onMouseLeave: event => {
            const toElement = event.relatedTarget

            if (
              toElement instanceof Node &&
              subMenuContainerRef.current &&
              !subMenuContainerRef.current.contains(toElement) &&
              !toElement.contains(subMenuContainerRef.current)
            ) {
              subMenuOpened.setFalse()
            }
          },
        })}
      >
        {children}

        {(subMenuItems || displayArrow) && (
          <Box ml="auto" pl={2} display="flex">
            <IconArrowRightShort
              sx={{ fontSize: '18px', color: ' greyBlue.light' }}
            />
          </Box>
        )}
      </StyledMenuItem>

      {subMenuItems && (
        <Menu
          isOpen={subMenuOpened.isTrue}
          anchorEl={subMenuAnchorRef.current}
          onClose={() => {}}
          placement="right-start"
        >
          <Box ref={subMenuContainerRef} onMouseLeave={subMenuOpened.setFalse}>
            {subMenuItems}
          </Box>
        </Menu>
      )}

      {confirm && renderConfirmDialog(confirm)}
    </>
  )
}
