import { Box } from '@mui/material'
import { Children } from 'react'

export const BadgeCollection = ({
  children,
  noWrap,
}: {
  children: React.ReactNode
  noWrap?: boolean
}) => (
  <Box
    display="flex"
    flexWrap={noWrap ? 'nowrap' : 'wrap'}
    alignItems="center"
    mr={-1}
    mb={-1}
  >
    {Children.map(children, badge => (
      <Box pr={1} pb={1}>
        {badge}
      </Box>
    ))}
  </Box>
)
