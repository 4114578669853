import {
  styled,
  tableBodyClasses,
  TableCell as MuiTableCell,
  TableCellProps as MuiTableCellProps,
  tableRowClasses,
} from '@mui/material'
import { noWrap } from 'styles/utils'

export interface TableCellProps extends MuiTableCellProps {
  noBorder?: boolean
}

export const TableCell = styled(MuiTableCell, {
  shouldForwardProp: prop => prop !== 'noBorder',
})<TableCellProps>(({ theme, noBorder }) => ({
  ...noWrap,
  borderColor: theme.palette.divider,
  padding: theme.spacing(1, 2),
  height: theme.spacing(5),

  [`.${tableBodyClasses.root} .${tableRowClasses.root}:last-child &`]: {
    borderBottom: 0,
  },
  borderBottom: noBorder ? 0 : '1',
}))
