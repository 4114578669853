export type ListPagination = {
  /** Page index starting from 0 */
  page: number
  /** Elements count per page */
  pageSize: number
}

export type ListOrder = {
  column: string
  direction: 'asc' | 'desc'
} | null

/** Creates URLSearchParams with pagination and sorting */
export const createListSearchParams = ({
  pagination,
  order,
}: {
  pagination?: ListPagination
  order?: ListOrder
}) => {
  const params = new URLSearchParams()

  if (pagination) {
    params.set('skip', String(pagination.page * pagination.pageSize))
    params.set('limit', String(pagination.pageSize))
  }

  if (order) {
    params.set('sort', order.column.toUpperCase())
    params.set('order', order.direction.toUpperCase())
  }

  return params
}
