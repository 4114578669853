import { useTheme } from '@mui/material'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCompletenessStatus = (props: SvgIconProps) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} viewBox="0 0 18 17">
      <g opacity="0.8">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.03874 13.3357C8.99504 13.5259 9.98628 13.4283 10.8871 13.0551C11.7879 12.682 12.5579 12.0501 13.0996 11.2394C13.6413 10.4287 13.9304 9.47555 13.9304 8.50051L17.0156 8.50051C17.0156 10.0857 16.5455 11.6354 15.6648 12.9535C14.7841 14.2715 13.5323 15.2989 12.0678 15.9055C10.6032 16.5122 8.99162 16.6709 7.43684 16.3616C5.88206 16.0523 4.4539 15.289 3.33297 14.168C2.21203 13.0471 1.44867 11.619 1.1394 10.0642C0.830138 8.5094 0.988864 6.89782 1.59551 5.43325C2.20216 3.96868 3.22947 2.71689 4.54755 1.83618C5.86563 0.95547 7.41527 0.485393 9.00051 0.485393L9.00051 3.57062C8.02547 3.57062 7.07233 3.85975 6.26161 4.40145C5.4509 4.94316 4.81902 5.7131 4.44589 6.61392C4.07276 7.51474 3.97513 8.50598 4.16535 9.46228C4.35557 10.4186 4.82509 11.297 5.51455 11.9865C6.20401 12.6759 7.08243 13.1454 8.03874 13.3357Z"
          fill={theme.palette.divider}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9304 8.50098C13.9304 7.85357 13.8029 7.21251 13.5551 6.61439C13.3074 6.01627 12.9443 5.4728 12.4865 5.01502C12.0287 4.55724 11.4852 4.1941 10.8871 3.94635C10.289 3.6986 9.64791 3.57109 9.00051 3.57109L9.00051 0.485862C10.0531 0.485862 11.0953 0.693179 12.0678 1.09598C13.0402 1.49877 13.9238 2.08916 14.6681 2.83343C15.4123 3.57771 16.0027 4.46129 16.4055 5.43372C16.8083 6.40616 17.0156 7.44842 17.0156 8.50098L13.9304 8.50098Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  )
}
