import { Box } from '@mui/material'

type Props = {
  countryCode: string
  size?: 'sm' | 'md' | 'lg' | 'xl'
  round?: boolean
}

export const CountryFlag = ({
  countryCode,
  size = 'md',
  round = false,
}: Props) => (
  <Box
    className={`fflag fflag-${countryCode} ff-${size} ${
      round ? 'ff-round' : ''
    }`}
  ></Box>
)
