import { Box, Stack, Typography } from '@mui/material'
import { TFunction } from 'i18next'
import { formatBytes } from 'lib/js-utils'
import { FileError, FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

type Props = {
  rejections: Array<FileRejection>
  maxSize?: number
  acceptedFileFormats?: string
}
const showErrorMessage = ({
  error,
  t,
  maxSize,
  acceptedFileFormats,
}: {
  error: FileError
  t: TFunction
  maxSize?: number
  acceptedFileFormats?: string
}) => {
  if (maxSize && error.code === 'file-too-large') {
    return t('validations.large_file', {
      maxSize: formatBytes(maxSize),
    })
  }
  if (error.code === 'file-invalid-type') {
    return acceptedFileFormats
  }
  return error.message
}

export const RejectedFiles = ({
  rejections,
  maxSize,
  acceptedFileFormats,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Stack component="ul" spacing={1} sx={{ p: 0, m: 0, listStyle: 'none' }}>
      {rejections.map(({ file, errors }, index) => (
        <li key={index}>
          <Typography
            variant="body2"
            sx={{
              mb: 1,
              fontWeight: 500,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {file.name}
          </Typography>

          <Box component="ul" sx={{ p: 0, listStylePosition: 'inside' }}>
            {errors.map((error, index) => (
              <Box component="li" key={index} sx={{ py: 0.5, px: 0.25 }}>
                {showErrorMessage({ error, t, maxSize, acceptedFileFormats })}
              </Box>
            ))}
          </Box>
        </li>
      ))}
    </Stack>
  )
}
