import { IconButton, Tooltip } from '@mui/material'

export const ResizableWindowIconButton = ({
  tooltip,
  children,
  onClick,
  active = false,
}: {
  tooltip: string
  children: React.ReactNode
  onClick?: () => void
  active?: boolean
}) => {
  return (
    <Tooltip disableInteractive placement="top" title={tooltip}>
      <IconButton
        onClick={onClick}
        sx={{
          width: 32,
          height: 32,
          borderRadius: '2px',
          color: 'greyBlue.main',

          ...(active && {
            color: 'primary.main',
            bgcolor: '#E6F4F1',
            '&:hover': {
              bgcolor: '#E6F4F1',
            },
          }),
        }}
      >
        {children}
      </IconButton>
    </Tooltip>
  )
}
