import { alpha, Checkbox as MuiCheckbox } from '@mui/material'
import { IconCheck, IconMinus } from 'assets/icons'
import { FormControlLabel } from 'ui/inputs/common/form-control-label'

import { CheckboxIcon } from './styled-icon'

export type Props = {
  checked: boolean
  onChange?: (checked: boolean) => void
  label?: React.ReactNode
  disabled?: boolean
  small?: boolean
  indeterminate?: boolean
  dataCy?: string
}

export const Checkbox = ({ small = false, ...props }: Props) => {
  const control = (
    <MuiCheckbox
      color="primary"
      disableRipple
      checked={props.checked}
      onChange={event => {
        props.onChange?.(event.target.checked)
      }}
      disabled={props.disabled}
      data-cy={props.dataCy}
      icon={
        <CheckboxIcon
          small={small}
          disabled={props.disabled}
          checked={props.checked}
        />
      }
      indeterminate={props.indeterminate && !props.checked}
      checkedIcon={
        <CheckboxIcon
          small={small}
          disabled={props.disabled}
          checked={props.checked}
        >
          <IconCheck
            stroke="2"
            sx={{
              fontSize: small ? '8px' : '10px',
              color: props.disabled ? 'greyBlue.light' : '#FFFFFF',
            }}
          />
        </CheckboxIcon>
      }
      indeterminateIcon={
        <CheckboxIcon
          small={small}
          disabled={props.disabled}
          checked={props.checked}
        >
          <IconMinus stroke="2" sx={{ fontSize: small ? '10px' : '14px' }} />
        </CheckboxIcon>
      }
      sx={{
        padding: 0,
        transition: 'box-shadow 0.25s',

        '&:hover': {
          background: 'transparent',
          boxShadow: `0px 1px 4px ${alpha('#000000', 0.04)}`,
        },
      }}
    />
  )

  if (props.label) {
    return (
      <FormControlLabel label={props.label} control={control} small={small} />
    )
  }

  return control
}
