import { wrapUseRoutes } from '@sentry/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  activateFirstCompanySubscription,
  getCompanyPromotion,
  getCurrentCompanySubscription,
  qk,
  startPromotionSubscription,
} from 'api'
import { globalAccessToken, useAuthContext, useAuthUser } from 'app/auth'
import { LocalStorageKey } from 'app/enums'
import { chooseSubscriptionRoutes, paths } from 'app/routes'
import { ErrorTemplate } from 'components/templates'
import { renderMutationResult, renderQueryResult } from 'lib/react-query-utils'
import { isClientError } from 'lib/request'
import mixpanel from 'mixpanel-browser'
import { CreateNewCompanyPage } from 'pages/login/create-new-company/create-new-company-page'
import {
  generatePath,
  Navigate,
  useNavigate,
  useRoutes,
} from 'react-router-dom'
import { CircularProgress } from 'ui/feedback'
import { AbsolutelyCentered } from 'ui/utils'
import useLocalStorageState from 'use-local-storage-state'

const useSentryRoutes = wrapUseRoutes(useRoutes)

type Props = Readonly<{
  children: JSX.Element
}>

/**
 * There are two cases when Owner needs to choose the subscription (while other functionality is forbidden)
 * 1. Owner logins for the first time -> choose a subscription plan
 * 2. Owner has a frozen subscription -> choose a subscription plan again, select payment method and pay for it
 */
export const SubscriptionChecker = ({ children }: Props) => {
  const navigate = useNavigate()

  const user = useAuthUser()
  const isOwner = user.role === 'OWNER'

  const queryClient = useQueryClient()
  const { refetchRefreshToken } = useAuthContext()
  const $startFirstSubscription = useMutation(activateFirstCompanySubscription)
  const $startPromotionSubscription = useMutation(startPromotionSubscription)

  const [, setIsPromotionExpired] = useLocalStorageState(
    LocalStorageKey.IsPromotionExpired,
    {
      defaultValue: false,
    },
  )

  const $getCompanyPromotion = useMutation(getCompanyPromotion, {
    onError: undefined,
  })

  const onSuccessStartSubscription = () => {
    // TODO: remove timeout when backend will adjust the request
    // After subscription is activated, token scopes are updated asynchronously on the server
    // It was decided to quickly (dirty) fix it on the frontend by calling refresh after tiny interval of time
    setTimeout(async () => {
      await refetchRefreshToken()

      await queryClient.invalidateQueries(
        qk.subscriptions.availableSubscriptions.toKey(),
      )

      await queryClient.invalidateQueries(
        qk.subscriptions.currentSubscription.toKey(),
      )

      navigate(paths.settingsGeneral, {
        replace: true,
        state: { saveToasts: true },
      })
    }, 1000)
  }

  const startFirstSubscription = () => {
    $startFirstSubscription.mutate(undefined, {
      onSuccess: onSuccessStartSubscription,
    })
  }

  const $currentSubscription = useQuery(
    qk.subscriptions.currentSubscription.toKey(),
    getCurrentCompanySubscription,
    {
      enabled: isOwner,
      onSuccess: subscription => {
        /**
         * Temporarily removing the "Start 1st subscription" step from the Company Registration process.
         * Task: https://axondevgroup.atlassian.net/browse/AXT-4661
         *
         * According to the task, after entering the Company name, the user should be logged into the account,
         * and the default subscription should start, eliminating the need for the "Start 1st subscription" step.
         *
         * If the "Start 1st subscription" step is reintroduced, this code block should be deleted
         */
        if (subscription === null) {
          $getCompanyPromotion.mutate(undefined, {
            onSuccess: ({ subscription }) => {
              if (subscription.trialAvailable) {
                $startPromotionSubscription.mutate(
                  {
                    paymentMethodId: null,
                  },
                  {
                    onSuccess: () => onSuccessStartSubscription(),
                  },
                )
              } else {
                navigate(
                  generatePath(paths.startPromotionSubscription, {
                    subscriptionId: subscription.subscriptionId,
                  }),
                  {
                    replace: true,
                    state: { saveToasts: true },
                  },
                )
              }
            },
            onError: error => {
              const errorCode = isClientError(error) && error.code

              if (errorCode === 'error_promotion_not_found') {
                setIsPromotionExpired(true)
              }

              startFirstSubscription()
            },
          })
        }

        if (subscription) {
          const subscriptionInfo = {
            'Subscription name': subscription.subscription.name,
            'Subscription status': subscription.status,
          }

          mixpanel.people.set(subscriptionInfo)
          mixpanel.register(subscriptionInfo)
        }
      },
    },
  )

  // We need to check subscription plan status only for Owner
  // Because other roles can only login to a fully active company
  if (!isOwner) return children

  return renderQueryResult($currentSubscription, {
    loading: () => <CircularProgress centered />,
    error: error =>
      window.location.pathname === paths.companyName ? (
        <CreateNewCompanyPage createCompanyToken={globalAccessToken!} />
      ) : (
        <AbsolutelyCentered>
          <ErrorTemplate error={error} />
        </AbsolutelyCentered>
      ),
    success: currentSubscription => {
      /**
       * Temporarily removing the "Start 1st subscription" step from the Company Registration process.
       * Task: https://axondevgroup.atlassian.net/browse/AXT-4661
       *
       * According to the task, after entering the Company name, the user should be logged into the account,
       * and the default subscription should start, eliminating the need for the "Start 1st subscription" step.
       *
       * @comment The code below is commented out to reflect the temporary removal of the step.
       * Uncomment the code if the "Start 1st subscription" step needs to be reintroduced in the future.
       */
      // if (currentSubscription === null) {
      //   return (
      //     <ChooseSubscriptionApp navigatePath={paths.startFirstSubscription} />
      //   )
      // }

      if (currentSubscription === null) {
        return renderMutationResult($getCompanyPromotion, {
          success: ({ subscription }) => {
            if (subscription.trialAvailable === false) {
              return (
                <ChooseSubscriptionApp
                  navigatePath={generatePath(paths.startPromotionSubscription, {
                    subscriptionId: subscription.subscriptionId,
                  })}
                />
              )
            }

            return null
          },
        })
      }

      if (currentSubscription.status === 'FROZEN') {
        return <ChooseSubscriptionApp navigatePath={paths.chooseSubscription} />
      }

      return children
    },
  })
}

const ChooseSubscriptionApp = ({ navigatePath }: { navigatePath: string }) => {
  return useSentryRoutes([
    ...chooseSubscriptionRoutes,
    {
      path: '*',
      element: <Navigate to={navigatePath} replace />,
    },
  ])
}
