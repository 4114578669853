import { Box, Stack, Tooltip } from '@mui/material'
import { PIPELINE_TEMPLATES_MAXIMUM } from 'api'
import documentSearchingImage from 'assets/images/document-searching.png'
import { NoDataTemplate } from 'components/templates'
import { useBoolean } from 'lib/react-utils'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'

import { CollectionWrapper } from '../collection-wrapper'
import { JobPipelineTemplateDraft } from '../manual-settings-setup'
import { CreatePipelineTemplate } from './create-pipeline-template'
import { PipelineTemplateItem } from './pipeline-template-item'

const ensureDefaultTemplate = (list: Array<JobPipelineTemplateDraft>) => {
  return list.some(template => template.defaultPipeline) || list.length === 0
    ? list
    : [{ ...list[0]!, defaultPipeline: true }, ...list.slice(1)]
}

type Props = Readonly<{
  templates: Array<JobPipelineTemplateDraft>
  onChange: (templates: Array<JobPipelineTemplateDraft>) => void
}>

// This pipeline list is very similar to the one we have in settings
// But they have absolutely different logic:

// Settings: create, update and delete are made with separate requests for each action

// Manual settings setup: managing the whole list of templates in state,
// so that we can send them all together with a single batch request later

// Instead of trying to mix those two approaches in the same components (would be very confusing),
// we get a bit of repetition, but keep everything clear and straightforward
export const PipelineTemplates = ({ templates, onChange }: Props) => {
  const { t } = useTranslation()
  const isCreatingTemplate = useBoolean()
  const isTemplatesMaximum = templates.length >= PIPELINE_TEMPLATES_MAXIMUM

  return (
    <CollectionWrapper
      title={t('manual_settings_setup.pipeline.title')}
      subtitle={t('manual_settings_setup.pipeline.description')}
    >
      {templates.length > 0 || isCreatingTemplate.isTrue ? (
        <Stack spacing={3}>
          {templates.length > 0 &&
            [
              templates.find(template => template.defaultPipeline)!,
              ...templates.filter(template => !template.defaultPipeline),
            ].map(template => (
              <PipelineTemplateItem
                key={template.pipelineId}
                template={template}
                onUpdate={updatedTemplate => {
                  onChange(
                    ensureDefaultTemplate(
                      templates.map(template => {
                        if (
                          template.pipelineId === updatedTemplate.pipelineId
                        ) {
                          return updatedTemplate
                        }

                        // if the updated template is default, make others not default
                        return updatedTemplate.defaultPipeline
                          ? { ...template, defaultPipeline: false }
                          : template
                      }),
                    ),
                  )
                }}
                onDelete={templateId => {
                  onChange(
                    ensureDefaultTemplate(
                      templates.filter(
                        template => template.pipelineId !== templateId,
                      ),
                    ),
                  )
                }}
              />
            ))}

          {isCreatingTemplate.isTrue ? (
            <CreatePipelineTemplate
              onClose={isCreatingTemplate.setFalse}
              onCreate={newTemplate => {
                // if the new template is default, make others not default
                const currentTemplates = newTemplate.defaultPipeline
                  ? templates.map(template => ({
                      ...template,
                      defaultPipeline: false,
                    }))
                  : templates

                onChange(
                  ensureDefaultTemplate([...currentTemplates, newTemplate]),
                )
              }}
            />
          ) : (
            <Tooltip
              arrow
              title={
                isTemplatesMaximum
                  ? t('jobs.max_templates_count', {
                      max: PIPELINE_TEMPLATES_MAXIMUM,
                    })
                  : ''
              }
            >
              <Box>
                <Button
                  variant="outlined"
                  onClick={isCreatingTemplate.setTrue}
                  disabled={isTemplatesMaximum || isCreatingTemplate.isTrue}
                >
                  {t('common.create_new_template')}
                </Button>
              </Box>
            </Tooltip>
          )}
        </Stack>
      ) : (
        <NoDataTemplate
          title={t('jobs.no_templates_title')}
          subtitle={t('jobs.no_templates_subtitle')}
          button={
            <Button variant="outlined" onClick={isCreatingTemplate.setTrue}>
              {t('common.create_new_template')}
            </Button>
          }
          imgSrc={documentSearchingImage}
          fullWidth
        />
      )}
    </CollectionWrapper>
  )
}
