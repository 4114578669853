import {
  endOfMonth,
  endOfQuarter,
  endOfToday,
  endOfTomorrow,
  endOfWeek,
  endOfYear,
  endOfYesterday,
  startOfMonth,
  startOfQuarter,
  startOfToday,
  startOfTomorrow,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns'
import { TFuncKey } from 'react-i18next'

const now = new Date()
const lastWeek = subWeeks(now, 1)
const lastMonth = subMonths(now, 1)
const lastQuarter = subQuarters(now, 1)
const lastYear = subYears(now, 1)

type DateRangeID =
  | 'TODAY'
  | 'YESTERDAY'
  | 'TOMORROW'
  | 'THIS_WEEK'
  | 'LAST_WEEK'
  | 'THIS_MONTH'
  | 'LAST_MONTH'
  | 'THIS_QUARTER'
  | 'LAST_QUARTER'
  | 'THIS_YEAR'
  | 'LAST_YEAR'

type DateRange = {
  id: DateRangeID
  name: TFuncKey
  from: Date
  to: Date
}

export const commonDateRanges: Array<DateRange> = [
  {
    id: 'TODAY',
    name: 'common.today',
    from: startOfToday(),
    to: endOfToday(),
  },
  {
    id: 'YESTERDAY',
    name: 'common.date_range_periods.yesterday',
    from: startOfYesterday(),
    to: endOfYesterday(),
  },
  {
    id: 'TOMORROW',
    name: 'common.date_range_periods.tomorrow',
    from: startOfTomorrow(),
    to: endOfTomorrow(),
  },
  {
    id: 'THIS_WEEK',
    name: 'common.date_range_periods.this_week',
    from: startOfWeek(now),
    to: endOfWeek(now),
  },
  {
    id: 'LAST_WEEK',
    name: 'common.date_range_periods.last_week',
    from: startOfWeek(lastWeek),
    to: endOfWeek(lastWeek),
  },
  {
    id: 'THIS_MONTH',
    name: 'common.date_range_periods.this_month',
    from: startOfMonth(now),
    to: endOfMonth(now),
  },
  {
    id: 'LAST_MONTH',
    name: 'common.date_range_periods.last_month',
    from: startOfMonth(lastMonth),
    to: endOfMonth(lastMonth),
  },
  {
    id: 'THIS_QUARTER',
    name: 'common.date_range_periods.this_quarter',
    from: startOfQuarter(now),
    to: endOfQuarter(now),
  },
  {
    id: 'LAST_QUARTER',
    name: 'common.date_range_periods.last_quarter',
    from: startOfQuarter(lastQuarter),
    to: endOfQuarter(lastQuarter),
  },
  {
    id: 'THIS_YEAR',
    name: 'common.date_range_periods.this_year',
    from: startOfYear(now),
    to: endOfYear(now),
  },
  {
    id: 'LAST_YEAR',
    name: 'common.date_range_periods.last_year',
    from: startOfYear(lastYear),
    to: endOfYear(lastYear),
  },
]
