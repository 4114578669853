import { StartWeekDay } from 'api'

export const AXTERIOR_HELP_EMAIL = 'help@axterior.com'
export const TERMS_AND_CONDITIONS_LINK = 'https://www.axterior.com/term-of-use'
export const PRIVACY_POLICY_LINK = 'https://www.axterior.com/privacy-policy'
export const AXTERIOR_HELP_CENTER_LINK = 'https://www.axterior.com/help-center'
export const AXTERIOR_BLOG_LINK = 'https://www.axterior.com/blog'

export const startWeekDays: ReadonlyArray<StartWeekDay> = ['SUNDAY', 'MONDAY']

export const shortWeekdayKeys = [
  'SUN',
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
] as const

export const daysOfWeek = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
] as const
