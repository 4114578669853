import { getDefaultOptions } from 'date-fns'
import { formatInTimeZone, OptionsWithTZ } from 'date-fns-tz'

import { useMyTimeZone } from './use-my-time-zone'

/**
 * Custom hook to format dates and times in the user's time zone using date-fns and date-fns-tz libraries.
 *
 * @returns {Object} - Object containing functions for formatting dates and times.
 */
export const useFormatInTimeZone = () => {
  const myTimeZone = useMyTimeZone()

  /**
   * Function to create a date formatter with the specified pattern and time zone.
   *
   * @param {string} pattern - Date and time pattern for formatting.
   * @returns {Function} - Date formatter function.
   */
  const format = (pattern: string) => {
    return (date: Date, options?: OptionsWithTZ) => {
      return formatInTimeZone(date, options?.timeZone ?? myTimeZone, pattern, {
        ...getDefaultOptions(),
        ...options,
      })
    }
  }

  return {
    format,
    // most common format patterns
    formatDate: format('PP'), // Format for displaying date
    formatDateTime: format('PP p'), // Format for displaying date and time
    formatTime: format('p'), // Format for displaying time
  }
}
