import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { QueryParamConfig, useQueryParam } from 'use-query-params'
/**
 * Custom hook for managing and synchronizing query state with React component state.
 *
 * @template S - The type of the query state (string, number, boolean, Array<string>, Date, or null)
 * @param {string} name - The name of the search parameter.
 * @param {QueryParamConfig<S>} queryParamConfig - Configuration for the useQueryParam hook.
 * @param {S | undefined} [emptyState] - Optional empty state, different from the default one.
 * @returns {{ state: S, setState: React.Dispatch<React.SetStateAction<S>>, appliedState: S, applyState: () => void, clearState: () => void, setBothStates: (newState: S) => void }}
 */
export const useApplicableQueryState = <
  S extends string | number | boolean | Array<string> | Date | null,
>(
  name: string,
  queryParamConfig: QueryParamConfig<S>,
  emptyState?: S,
) => {
  const emptyStateRef = useRef(emptyState ?? queryParamConfig.default)

  const [appliedState, setAppliedState] = useQueryParam(name, queryParamConfig)
  const [state, setState] = useState(appliedState)

  /**
   * Workaround for the issue with ArrayParam and non-empty default array.
   * When setting it to an empty array, we need to use [''] to apply properly.
   * All other cases work correctly, so we can safely apply state as it is.
   *
   * @param {S} stateToApply - The state to be applied.
   */
  const applyStateWorkaround = useCallback(
    (stateToApply: S) => {
      const defaultIsNotEmptyArray =
        queryParamConfig.default &&
        Array.isArray(queryParamConfig.default) &&
        queryParamConfig.default.length > 0

      const stateToApplyIsEmptyArray =
        Array.isArray(stateToApply) && stateToApply.length === 0

      if (defaultIsNotEmptyArray && stateToApplyIsEmptyArray) {
        setAppliedState([''] as S)
      } else {
        setAppliedState(stateToApply)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAppliedState],
  )

  /**
   * Apply the current state to the query parameter.
   */
  const applyState = useCallback(() => {
    applyStateWorkaround(state)
  }, [applyStateWorkaround, state])

  /**
   * Clear the state, setting it to the empty state if defined.
   */
  const clearState = useCallback(() => {
    if (emptyStateRef.current !== undefined) {
      setState(emptyStateRef.current)
      applyStateWorkaround(emptyStateRef.current)
    }
  }, [applyStateWorkaround])

  /**
   * Set both component state and query state.
   *
   * @param {S} newState - The new state value.
   */
  const setBothStates = useCallback(
    (newState: S) => {
      setState(newState)
      applyStateWorkaround(newState)
    },

    [applyStateWorkaround],
  )

  /**
   * Effect to handle external changes in URL search params,
   * updating the component state accordingly.
   */
  useEffect(() => {
    setState(appliedState)
  }, [appliedState])

  /**
   * Memoized object with the hook's public API.
   */
  return useMemo(
    () => ({
      state,
      setState,
      appliedState,
      applyState,
      clearState,
      setBothStates,
    }),
    [state, appliedState, setState, applyState, clearState, setBothStates],
  )
}
