import * as t from 'io-ts'

export const TAuthorizationResponse = t.intersection([
  t.strict({
    accessToken: t.string,
  }),
  t.partial({
    existCompany: t.boolean,
  }),
])

export type AuthorizationResponse = t.TypeOf<typeof TAuthorizationResponse>

export const TAuthorizationResponseByLink = t.intersection([
  TAuthorizationResponse,
  t.strict({
    firstLogin: t.boolean,
    existCompany: t.boolean,
  }),
])
