import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconLevelHighest = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 14">
    <path
      d="M10.0884 9.18124C10.0345 9.23532 9.97038 9.27822 9.89981 9.3075C9.82924 9.33677 9.75359 9.35184 9.6772 9.35184C9.6008 9.35184 9.52515 9.33677 9.45458 9.3075C9.38401 9.27822 9.31991 9.23532 9.26595 9.18124L7.00261 6.92374L4.73345 9.18708C4.67944 9.24108 4.61533 9.28392 4.54476 9.31315C4.4742 9.34238 4.39857 9.35742 4.3222 9.35742C4.24582 9.35742 4.17019 9.34238 4.09963 9.31315C4.02907 9.28392 3.96495 9.24108 3.91095 9.18708C3.85694 9.13307 3.8141 9.06896 3.78487 8.99839C3.75564 8.92783 3.7406 8.8522 3.7406 8.77583C3.7406 8.69945 3.75564 8.62382 3.78487 8.55326C3.8141 8.4827 3.85694 8.41858 3.91095 8.36458L6.58845 5.68708C6.81595 5.45958 7.18345 5.45958 7.41095 5.68708L10.0884 8.36458C10.1425 8.41797 10.1854 8.48155 10.2147 8.55164C10.244 8.62174 10.259 8.69695 10.259 8.77291C10.259 8.84887 10.244 8.92408 10.2147 8.99418C10.1854 9.06427 10.1425 9.12785 10.0884 9.18124Z"
      fill="currentColor"
    />
    <path
      d="M10.0884 12.3834C10.0345 12.4375 9.97038 12.4804 9.89981 12.5096C9.82924 12.5389 9.75359 12.554 9.6772 12.554C9.6008 12.554 9.52515 12.5389 9.45458 12.5096C9.38401 12.4804 9.31991 12.4375 9.26595 12.3834L7.00261 10.1259L4.73345 12.3892C4.67944 12.4432 4.61533 12.4861 4.54476 12.5153C4.4742 12.5445 4.39857 12.5596 4.3222 12.5596C4.24582 12.5596 4.17019 12.5445 4.09963 12.5153C4.02907 12.4861 3.96495 12.4432 3.91095 12.3892C3.85694 12.3352 3.8141 12.2711 3.78487 12.2005C3.75564 12.13 3.7406 12.0544 3.7406 11.978C3.7406 11.9016 3.75564 11.826 3.78487 11.7554C3.8141 11.6848 3.85694 11.6207 3.91095 11.5667L6.58845 8.88923C6.81595 8.66173 7.18345 8.66173 7.41095 8.88923L10.0884 11.5667C10.1425 11.6201 10.1854 11.6837 10.2147 11.7538C10.244 11.8239 10.259 11.8991 10.259 11.9751C10.259 12.051 10.244 12.1262 10.2147 12.1963C10.1854 12.2664 10.1425 12.33 10.0884 12.3834Z"
      fill="currentColor"
    />
    <path
      d="M10.0884 6.10556C10.0344 6.15964 9.97028 6.20254 9.89971 6.23181C9.82915 6.26108 9.7535 6.27615 9.6771 6.27615C9.6007 6.27615 9.52505 6.26108 9.45449 6.23181C9.38392 6.20254 9.31982 6.15964 9.26585 6.10556L7.00252 3.84806L4.73918 6.11139C4.63011 6.22046 4.48218 6.28174 4.32793 6.28174C4.17368 6.28174 4.02575 6.22046 3.91668 6.11139C3.80761 6.00232 3.74634 5.85439 3.74634 5.70014C3.74634 5.54589 3.80761 5.39796 3.91668 5.28889L6.59418 2.61139C6.82168 2.38389 7.18918 2.38389 7.41668 2.61139L10.0942 5.28889C10.2013 5.39819 10.2608 5.54542 10.2597 5.69843C10.2586 5.85144 10.197 5.99781 10.0884 6.10556Z"
      fill="currentColor"
    />
  </SvgIcon>
)
