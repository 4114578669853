import { Box } from '@mui/material'
import { MouseEventHandler } from 'react'

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>
  children: React.ReactNode
}

export const NoStylesButton = ({ onClick, children }: Props) => {
  return (
    <Box
      onClick={onClick}
      component="button"
      sx={{
        background: 'none',
        color: 'inherit',
        border: 'none',
        padding: 0,
        font: 'inherit',
        cursor: 'pointer',
      }}
    >
      {children}
    </Box>
  )
}
