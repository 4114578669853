import { StartWeekDay, TAuthorizationResponseByLink } from 'api/common'
import { pipe } from 'fp-ts/function'
import { decodeJson, get, patch, post, put } from 'lib/request'

import { DepartmentId } from './approvers'
import {
  TCompanyDepartmentsList,
  TCompanyDetails,
  TCompanyJobTypesList,
  TCompanyLanguagesList,
  TCompanyName,
  TCompanyOfficesList,
  TCompanyPositionsList,
  TCompanyProjectsList,
  TCompanyRejectReasonsList,
  TCompanyWorkExperiencesList,
} from './company.codecs'

export const getCompanyDetails = async () => {
  return pipe(await get('api/companies/me', {}), decodeJson(TCompanyDetails))
}

export const getCompanyName = async () => {
  return pipe(await get('api/companies/me/name', {}), decodeJson(TCompanyName))
}

type UpdatedCompanyOfficesInput = Array<{ name: string; officeId?: string }>

export type UpdateCompanyDetailsInput = {
  name: string
  industryCode: string
  teamMembersCode: string
  imageId: string | null
  offices: UpdatedCompanyOfficesInput
  countryCode: string
  languageCode: string
  zoneId: string
  startWeekDay: string
}

export const updateCompanyDetails = async ({
  offices,
  ...data
}: UpdateCompanyDetailsInput) => {
  return await put('api/companies/me', {
    body: {
      ...data,
      officeListRequest: {
        offices,
      },
    },
  })
}

export const getCompanyOffices = async () => {
  return pipe(
    await get('api/companies/me/offices', {}),
    decodeJson(TCompanyOfficesList),
  )
}

export const getCompanyProjects = async () => {
  return pipe(
    await get('api/companies/me/projects', {}),
    decodeJson(TCompanyProjectsList),
  )
}

type UpdatedCompanyProjectsInput = Array<{ name: string; projectId?: string }>

export const updateCompanyProjects = async (input: {
  projects: UpdatedCompanyProjectsInput
}) => {
  return await put('api/companies/me/projects', { body: input })
}

export const getCompanyPositions = async () => {
  return pipe(
    await get('api/companies/me/positions', {}),
    decodeJson(TCompanyPositionsList),
  )
}

type UpdatedCompanyPositionsInput = Array<{ name: string; positionId?: string }>

export const updateCompanyPositions = async (input: {
  positions: UpdatedCompanyPositionsInput
}) => {
  return await put('api/companies/me/positions', { body: input })
}

export const getCompanyDepartments = async () => {
  return pipe(
    await get('api/companies/me/departments', {}),
    decodeJson(TCompanyDepartmentsList),
  )
}

type UpdatedCompanyDepartmentsInput = Array<
  Partial<DepartmentId> & {
    name: string
  }
>

export const updateCompanyDepartments = async (input: {
  departments: UpdatedCompanyDepartmentsInput
}) => {
  return await put('api/companies/me/departments', {
    body: input,
  })
}

export const getCompanyLanguages = async () => {
  return pipe(await get('api/languages', {}), decodeJson(TCompanyLanguagesList))
}

type UpdatedCompanyLanguagesInput = Array<{ name: string; id?: string }>

export const updateCompanyLanguages = async (input: {
  languages: UpdatedCompanyLanguagesInput
}) => {
  return await put('api/companies/me/languages', { body: input })
}

export const getCompanyJobTypes = async () => {
  return pipe(
    await get('api/companies/me/job_types', {}),
    decodeJson(TCompanyJobTypesList),
  )
}

type UpdatedCompanyJobTypesInput = Array<{ name: string; jobTypeId?: string }>

export const updateCompanyJobTypes = async ({
  jobTypes,
}: {
  jobTypes: UpdatedCompanyJobTypesInput
}) => {
  return await put('api/companies/me/job_types', { body: { jobTypes } })
}

export const getCompanyWorkExperiences = async () => {
  return pipe(
    await get('api/companies/me/work_experience_types', {}),
    decodeJson(TCompanyWorkExperiencesList),
  )
}

type UpdatedCompanyWorkExperiencesInput = Array<{
  workExperienceId?: string
  name: string
}>

export const updateCompanyWorkExperiences = async ({
  workExperienceTypes,
}: {
  workExperienceTypes: UpdatedCompanyWorkExperiencesInput
}) => {
  return await put('api/companies/me/work_experience_types', {
    body: { workExperienceTypes },
  })
}

export const getCompanyRejectReasons = async () => {
  return pipe(
    await get('api/companies/me/reject_reasons', {}),
    decodeJson(TCompanyRejectReasonsList),
  )
}

type UpdatedCompanyRejectReasonsInput = Array<{
  name: string
  rejectReasonId?: string
}>

export const updateCompanyRejectReasons = async (input: {
  reasons: UpdatedCompanyRejectReasonsInput
}) => {
  return await put('api/companies/me/reject_reasons', {
    body: {
      rejectReasons: input.reasons,
    },
  })
}

export type ConfigureCompanySettingsManuallyInput = {
  offices: UpdatedCompanyOfficesInput
  departments: UpdatedCompanyDepartmentsInput
  positions: UpdatedCompanyPositionsInput
  projects: UpdatedCompanyProjectsInput
  // Can be either a new template or an updated version of the existing one
  jobPipelines: Array<{
    pipelineId?: string
    name: string
    defaultPipeline: boolean
    stages: Array<{
      name: string
      canHire: boolean
      order: number
    }>
  }>
}

export const configureCompanySettingsManually = async (
  input: ConfigureCompanySettingsManuallyInput,
) => {
  return await put('api/companies/me/settings', {
    body: {
      officeListRequest: {
        offices: input.offices,
      },
      departmentListRequest: {
        departments: input.departments,
      },
      positionListRequest: {
        positions: input.positions,
      },
      projectListRequest: {
        projects: input.projects,
      },
      jobPipelines: input.jobPipelines,
    },
  })
}

export const markCompanySettingsAsConfigured = async () => {
  return await patch('api/companies/me/mark-as-configured', {})
}

export type CreateCompanyInput = {
  accessToken: string
  client: string
  companyName: string
  languageCode: string
  countryCode: string
  zoneId: string
  startWeekDay: StartWeekDay
}

//TODO auth folder
export const createCompany = async ({
  accessToken,
  ...body
}: CreateCompanyInput) => {
  return pipe(
    await post('auth/companies', {
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
      }),
      body: body,
    }),
    decodeJson(TAuthorizationResponseByLink),
  )
}
