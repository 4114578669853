import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconFileArrowUpOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M11.6875 15.8125C11.6875 16.1922 11.3797 16.5 11 16.5C10.6203 16.5 10.3125 16.1922 10.3125 15.8125V10.5973L8.73614 12.1736C8.46765 12.4421 8.03235 12.4421 7.76386 12.1736C7.49538 11.9051 7.49538 11.4699 7.76386 11.2014L10.5139 8.45136C10.7824 8.18288 11.2177 8.18288 11.4861 8.45136L14.2361 11.2014C14.5046 11.4699 14.5046 11.9051 14.2361 12.1736C13.9676 12.4421 13.5323 12.4421 13.2639 12.1736L11.6875 10.5973V15.8125Z"
      fill="currentColor"
    />
    <path
      d="M19.25 19.25V6.1875L13.0625 0H5.5C3.98122 0 2.75 1.23122 2.75 2.75V19.25C2.75 20.7688 3.98122 22 5.5 22H16.5C18.0188 22 19.25 20.7688 19.25 19.25ZM13.0625 4.125C13.0625 5.26409 13.9859 6.1875 15.125 6.1875H17.875V19.25C17.875 20.0094 17.2594 20.625 16.5 20.625H5.5C4.74061 20.625 4.125 20.0094 4.125 19.25V2.75C4.125 1.99061 4.74061 1.375 5.5 1.375H13.0625V4.125Z"
      fill="currentColor"
    />
  </SvgIcon>
)
