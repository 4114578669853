import { PaletteColor, useTheme } from '@mui/material'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconChevronSelectorVertical = ({
  sort,
  ...props
}: SvgIconProps & { sort?: 'asc' | 'desc' }) => {
  const theme = useTheme()
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 12 18"
      sx={{
        ...props.sx,
        fill: 'none',
      }}
    >
      <path
        d="M1 12L6 17L11 12"
        stroke={
          sort === 'asc'
            ? theme.palette.primary['500' as keyof PaletteColor]
            : 'currentColor'
        }
        strokeWidth={props.stroke ?? '1.4'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6L6 1L11 6"
        stroke={
          sort === 'desc'
            ? theme.palette.primary['500' as keyof PaletteColor]
            : 'currentColor'
        }
        strokeWidth={props.stroke ?? '1.4'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
