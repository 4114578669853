import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconUserCheck = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.57971 7.87473C10.2333 7.87473 11.5737 6.53427 11.5737 4.88072C11.5737 3.22718 10.2333 1.88672 8.57971 1.88672C6.92617 1.88672 5.58571 3.22718 5.58571 4.88072C5.58571 6.53427 6.92617 7.87473 8.57971 7.87473ZM8.57971 8.99973C10.8546 8.99973 12.6987 7.15559 12.6987 4.88072C12.6987 2.60586 10.8546 0.761719 8.57971 0.761719C6.30485 0.761719 4.46071 2.60586 4.46071 4.88072C4.46071 7.15559 6.30485 8.99973 8.57971 8.99973Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.42281 16.456C1.43902 16.3837 1.45779 16.3061 1.47949 16.2241C1.61695 15.7044 1.86929 15.0146 2.3268 14.328C3.22238 12.9839 4.9515 11.5762 8.3833 11.5762C10.5768 11.5762 12.0642 12.1517 13.0802 12.898C13.3305 13.0819 13.6826 13.0281 13.8665 12.7777C14.0504 12.5273 13.9966 12.1753 13.7462 11.9914C12.5135 11.0858 10.7852 10.4512 8.3833 10.4512C4.57668 10.4512 2.4956 12.0458 1.3906 13.7042C0.847817 14.5188 0.552264 15.3301 0.391899 15.9364C0.311499 16.2404 0.264386 16.4954 0.237165 16.6772C0.223541 16.7682 0.21486 16.8411 0.209477 16.893C0.206785 16.9189 0.211867 16.8706 0.203661 16.9547L0.202219 16.9731L0.201797 16.979L0.201653 16.9812L0.201549 16.9828C0.180937 17.3068 0.438207 17.581 0.762915 17.581H9.98721C10.2979 17.581 10.5497 17.3292 10.5497 17.0185C10.5497 16.7078 10.2979 16.456 9.98721 16.456H1.42281Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8051 13.0543C18.0362 13.262 18.0551 13.6177 17.8474 13.8487L14.4599 17.6172C14.3549 17.734 14.206 17.8016 14.049 17.8036C13.892 17.8057 13.7413 17.7421 13.6333 17.6282L11.2743 15.1397C11.0605 14.9143 11.0701 14.5582 11.2955 14.3445C11.521 14.1308 11.877 14.1403 12.0907 14.3657L14.0305 16.412L17.0108 13.0966C17.2185 12.8656 17.5741 12.8467 17.8051 13.0543Z"
      fill="currentColor"
    />
  </SvgIcon>
)
