import * as t from 'io-ts'

export const TPublishedCompany = t.intersection([
  t.strict({
    companyId: t.string,
    name: t.string,
  }),
  t.partial({
    logoUri: t.string,
  }),
])

export const TPublishedJob = t.intersection([
  t.strict({
    jobId: t.string,
    companyName: t.string,
    title: t.string,
    description: t.string,
    jobType: t.string,
    model: t.strict({
      name: t.string,
    }),
    recruiters: t.array(
      t.intersection([
        t.strict({
          email: t.string,
          fullName: t.string,
          role: t.string,
        }),
        t.partial({
          avatar: t.string,
        }),
      ]),
    ),
  }),
  t.partial({
    companyLogoLink: t.string,
    salaryMin: t.number,
    salaryMax: t.number,
  }),
])

export const TPublishedJobShort = t.intersection([
  t.strict({
    jobId: t.string,
    title: t.string,
    slug: t.string,
  }),
  t.partial({
    salaryMin: t.number,
    salaryMax: t.number,
    jobType: t.string,
    address: t.partial({
      country: t.strict({ code: t.string }),
      city: t.string,
    }),
  }),
])
