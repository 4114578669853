import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconFormatting = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M2.896 20.1484C2.896 19.7342 3.23178 19.3984 3.646 19.3984H20.146C20.5602 19.3984 20.896 19.7342 20.896 20.1484C20.896 20.5627 20.5602 20.8984 20.146 20.8984H3.646C3.23178 20.8984 2.896 20.5627 2.896 20.1484Z"
      fill="currentColor"
    />
    <path
      d="M7.86621 17.0981L9.28125 12.894H14.4902L15.9053 17.0981H17.792L12.8086 3.10156H10.9834L6 17.0981H7.86621ZM11.9165 5.21387L14.0083 11.4482H9.76318L11.8652 5.21387H11.9165Z"
      fill="currentColor"
    />
  </SvgIcon>
)
