import { Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getContactWorkExperience, qk } from 'api'
import { ExperienceDurationWithDates } from 'components/contacts/work-experience/experience-duration'
import { DetailsItemContainer } from 'components/templates/details-template'
import { renderQueryResult } from 'lib/react-query-utils'
import { useTranslation } from 'react-i18next'
import { Timeline, TimelineItem } from 'ui/data'

type Props = {
  contactId: string
}

export const ContactWorkExperienceSection = ({ contactId }: Props) => {
  const { t } = useTranslation()

  const $workExperience = useQuery(
    qk.contacts.workExperience.toKeyWithArgs({
      contactId,
    }),
    () => getContactWorkExperience({ contactId }),
  )

  return renderQueryResult($workExperience, {
    success: workExperience => {
      if (workExperience.length === 0) return null

      return (
        <DetailsItemContainer title={t('common.work_experience')}>
          <Timeline>
            {workExperience.map((item, index) => (
              <TimelineItem
                key={index}
                icon={
                  <Typography fontSize={12} fontWeight={600}>
                    {workExperience.length - index}
                  </Typography>
                }
              >
                <Stack direction="row" alignItems="center" spacing={2} mb={0.5}>
                  <Typography>
                    {item.jobExperience[0]?.position.name}
                  </Typography>
                  <Typography>
                    <ExperienceDurationWithDates
                      startDate={item.startDate}
                      endDate={item.endDate}
                    />
                  </Typography>
                </Stack>

                <Typography variant="caption" color="text.secondary">
                  {item.companyName}
                </Typography>
              </TimelineItem>
            ))}
          </Timeline>
        </DetailsItemContainer>
      )
    },
    loading: () => null,
    error: () => null,
  })
}
