import { Box, Divider, Stack, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { configureCompanySettingsManually } from 'api'
import { IconArrowDownShort, IconArrowRight, IconCheck } from 'assets/icons'
import { EditSettingsCollectionForm } from 'components/settings'
import { SettingsCollection } from 'components/settings/edit-settings-collection-form'
import { useSettingsCollectionValidations } from 'lib/app-helpers'
import { showToast } from 'lib/toast'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'
import { Accordion } from 'ui/surfaces'

import { CollectionWrapper } from './collection-wrapper'
import { PipelineTemplates } from './pipeline-templates'
import { navigation, SectionId } from './sections'
import { SetupSummary } from './setup-summary'

const identifyCollection = (idKey: string, collection: SettingsCollection) =>
  collection.map(item => ({ [idKey]: item.id, name: item.name }))

export type JobPipelineTemplateDraft = {
  /** true when it is a new template with a fake id which should not be sent to the server */
  isNewTemplate?: boolean
  pipelineId: string
  name: string
  defaultPipeline: boolean
  stages: Array<{
    name: string
    order: number
    canHire: boolean
  }>
}

type Props = Readonly<{
  initialOffices: SettingsCollection
  initialDepartments: SettingsCollection
  initialPositions: SettingsCollection
  initialProjects: SettingsCollection
  initialPipelineTemplates: Array<JobPipelineTemplateDraft>
  onCancel: () => void
  onSuccess: () => void
}>

// This component may look quite complicated at first, but it is caused by some tricky requirements:
// The majority of company settings (where each entity is configured on a separate page with a separate request)
// must be placed inside this manual setup dialog, where everything must be kept in state
// and then saved all at once with a single batch request.
// That's why you can find some tricky decisions here like dispatching form submit event externally.
export const ManualSettingsSetup = ({
  initialOffices,
  initialDepartments,
  initialPositions,
  initialProjects,
  initialPipelineTemplates,
  onCancel,
  onSuccess,
}: Props) => {
  const { t } = useTranslation()
  const validations = useSettingsCollectionValidations()

  const [activeSectionId, setActiveSection] = useState<SectionId>(
    navigation[0].sections[0].sectionId,
  )

  const sectionToNavigateAfterSubmit = useRef<SectionId | null>(null)

  const [offices, setOffices] = useState<SettingsCollection>(initialOffices)
  const [departments, setDepartments] =
    useState<SettingsCollection>(initialDepartments)
  const [positions, setPositions] =
    useState<SettingsCollection>(initialPositions)
  const [projects, setProjects] = useState<SettingsCollection>(initialProjects)

  const [pipelineTemplates, setPipelineTemplates] = useState<
    Array<JobPipelineTemplateDraft>
  >(initialPipelineTemplates)

  const $configure = useMutation(configureCompanySettingsManually)

  const saveAndFinish = () => {
    $configure.mutate(
      {
        offices: identifyCollection('officeId', offices),
        departments: identifyCollection('departmentId', departments),
        positions: identifyCollection('positionId', positions),
        projects: identifyCollection('projectId', projects),
        jobPipelines: pipelineTemplates.map(template => ({
          pipelineId: template.isNewTemplate ? undefined : template.pipelineId,
          name: template.name,
          stages: template.stages,
          defaultPipeline: template.defaultPipeline,
        })),
      },
      {
        onSuccess: () => {
          onSuccess()
          showToast({ title: t('toasts.manual_settings_setup_success') })
        },
      },
    )
  }

  const activeSection = navigation
    .flatMap(item => item.sections)
    .find(section => section.sectionId === activeSectionId)!

  const isLastSection = activeSectionId === SectionId.Summary

  const moveToTheNextSection = () => {
    const activeGroupIndex = navigation.findIndex(item =>
      item.sections.some(section => section.sectionId === activeSectionId),
    )

    const activeGroup = navigation[activeGroupIndex]!

    const activeSectionIndex = activeGroup.sections.findIndex(
      section => section.sectionId === activeSectionId,
    )

    if (activeSectionIndex === activeGroup.sections.length - 1) {
      // If it is the last section in the group, move to the first section of the next group
      setActiveSection(navigation[activeGroupIndex + 1]!.sections[0].sectionId)
    } else {
      // Move to the next section inside the current group
      setActiveSection(activeGroup.sections[activeSectionIndex + 1]!.sectionId)
    }
  }

  const handleSuccessfulSubmit = () => {
    if (sectionToNavigateAfterSubmit.current) {
      setActiveSection(sectionToNavigateAfterSubmit.current)
    } else {
      moveToTheNextSection()
    }

    sectionToNavigateAfterSubmit.current = null
  }

  const submitCurrentFormExternally = () => {
    const formElement = document.querySelector(
      `#${activeSectionId}`,
    ) as HTMLFormElement

    formElement.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    )
  }

  const navigateToArbitrarySection = (sectionId: SectionId) => {
    if (activeSection.requiresSubmit) {
      sectionToNavigateAfterSubmit.current = sectionId
      submitCurrentFormExternally()
    } else {
      setActiveSection(sectionId)
    }
  }

  const renderSectionForm = () => {
    switch (activeSectionId) {
      case SectionId.Offices: {
        return (
          <CollectionWrapper
            // setting keys so that EditSettingsCollectionForm is remounted on section change
            key={SectionId.Offices}
            title={t('manual_settings_setup.offices.title')}
            subtitle={t('manual_settings_setup.offices.description')}
          >
            <EditSettingsCollectionForm
              {...validations.offices}
              formId={SectionId.Offices}
              initialValues={offices}
              onSubmit={offices => {
                setOffices(offices)
                handleSuccessfulSubmit()
              }}
            />
          </CollectionWrapper>
        )
      }
      case SectionId.Departments: {
        return (
          <CollectionWrapper
            key={SectionId.Departments}
            title={t('manual_settings_setup.departments.title')}
            subtitle={t('manual_settings_setup.departments.description')}
          >
            <EditSettingsCollectionForm
              {...validations.departments}
              formId={SectionId.Departments}
              initialValues={departments}
              onSubmit={departments => {
                setDepartments(departments)
                handleSuccessfulSubmit()
              }}
            />
          </CollectionWrapper>
        )
      }
      case SectionId.Positions: {
        return (
          <CollectionWrapper
            key={SectionId.Positions}
            title={t('manual_settings_setup.positions.title')}
            subtitle={t('manual_settings_setup.positions.description')}
          >
            <EditSettingsCollectionForm
              {...validations.positions}
              formId={SectionId.Positions}
              initialValues={positions}
              onSubmit={positions => {
                setPositions(positions)
                handleSuccessfulSubmit()
              }}
            />
          </CollectionWrapper>
        )
      }
      case SectionId.Projects: {
        return (
          <CollectionWrapper
            key={SectionId.Projects}
            title={t('manual_settings_setup.projects.title')}
            subtitle={t('manual_settings_setup.projects.description')}
          >
            <EditSettingsCollectionForm
              {...validations.projects}
              formId={SectionId.Projects}
              initialValues={projects}
              onSubmit={projects => {
                setProjects(projects)
                handleSuccessfulSubmit()
              }}
            />
          </CollectionWrapper>
        )
      }
      case SectionId.PipelineTemplates: {
        return (
          <PipelineTemplates
            templates={pipelineTemplates}
            onChange={setPipelineTemplates}
          />
        )
      }

      case SectionId.Summary: {
        return (
          <SetupSummary
            onEdit={setActiveSection}
            offices={offices}
            departments={departments}
            positions={positions}
            projects={projects}
            pipelineTemplates={pipelineTemplates}
          />
        )
      }
      default: {
        return null
      }
    }
  }

  return (
    <Box display="flex" flexDirection="column" height={720}>
      <Box flex={1} display="flex" overflow="auto">
        <Box flex="0 0 30%" borderRight={1} borderColor="divider" p={3}>
          <Stack spacing={0.5}>
            {navigation.map(item => {
              const isExpanded = item.sections.some(
                section => section.sectionId === activeSectionId,
              )

              return (
                <Accordion
                  key={item.name}
                  expanded={isExpanded}
                  onChange={expanded => {
                    if (expanded) {
                      navigateToArbitrarySection(item.sections[0].sectionId)
                    }
                  }}
                  title={
                    <Box display="flex" alignItems="center" height={36} pl={1}>
                      <item.Icon
                        sx={{
                          fontSize: '18px',
                          color: isExpanded ? 'primary.main' : 'greyBlue.light',
                        }}
                      />
                      <Typography
                        variant="h3"
                        color={isExpanded ? 'text.primary' : 'text.secondary'}
                        ml={1}
                      >
                        {t(item.name).toString()}
                      </Typography>
                    </Box>
                  }
                  expandIcon={
                    <IconArrowDownShort
                      sx={{
                        fontSize: '18px',
                        color: 'greyBlue.light',
                        mx: 1,
                      }}
                    />
                  }
                >
                  <Stack spacing={0.5} mt={0.5}>
                    {item.sections.map(section => {
                      const isActive = section.sectionId === activeSectionId

                      return (
                        <Box
                          key={section.name}
                          onClick={() => {
                            navigateToArbitrarySection(section.sectionId)
                          }}
                          sx={{
                            height: 36,
                            px: 1,
                            display: 'flex',
                            alignItems: 'center',
                            bgcolor: isActive ? '#E6F3FE' : 'auto',
                            cursor: 'pointer',
                          }}
                        >
                          <Box
                            width={18}
                            height={18}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Box
                              width={6}
                              height={6}
                              bgcolor={isActive ? 'primary.main' : 'mischka'}
                              borderRadius="50%"
                            />
                          </Box>
                          <Typography
                            color={isActive ? 'primary.main' : 'text.secondary'}
                            ml={1}
                          >
                            {t(section.name).toString()}
                          </Typography>
                        </Box>
                      )
                    })}
                  </Stack>
                </Accordion>
              )
            })}
          </Stack>
        </Box>
        <Box flex="0 0 70%" bgcolor="#F9FAFC" p={3} overflow="auto">
          {renderSectionForm()}
        </Box>
      </Box>

      <Divider />

      <Box
        sx={{
          px: 3,
          py: 2.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant="outlined"
          color="greyBlue"
          onClick={onCancel}
          confirm={{
            size: 'small',
            variant: 'danger',
            title: t('manual_settings_setup.setup_cancelation'),
            children: t('confirms.manual_settings_setup_cancel_confirm'),
          }}
        >
          {t('common.cancel')}
        </Button>

        {isLastSection ? (
          <Button
            loading={$configure.isLoading}
            endIcon={<IconCheck />}
            iconSize="10px"
            onClick={saveAndFinish}
          >
            {t('manual_settings_setup.summary.save_and_finish')}
          </Button>
        ) : (
          <Button
            endIcon={<IconArrowRight />}
            {...(activeSection.requiresSubmit
              ? {
                  type: 'submit',
                  form: activeSectionId,
                  onClick: () => {
                    sectionToNavigateAfterSubmit.current = null
                  },
                }
              : {
                  onClick: moveToTheNextSection,
                })}
          >
            {t('common.continue')}
          </Button>
        )}
      </Box>
    </Box>
  )
}
