import { useBoolean } from 'lib/react-utils'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'ui/feedback'
import { Button, ButtonProps } from 'ui/inputs/button'

import { CreateContactsTalentsPoolForm } from './create-talents-pool-form'

type Props = {
  contactsIds: Array<string>
  actionText?: string
  icon?: ReactNode
  buttonProps?: ButtonProps
}

export const CreateTalentsPoolButton = ({
  contactsIds,
  actionText,
  buttonProps,
  icon,
}: Props) => {
  const { t } = useTranslation()
  const dialogOpen = useBoolean()

  return (
    <>
      <Button
        {...buttonProps}
        onClick={dialogOpen.setTrue}
        startIcon={icon}
        iconSize="14px"
      >
        {actionText ?? t('common.create')}
      </Button>

      <Dialog
        width="440px"
        open={dialogOpen.isTrue}
        onClose={dialogOpen.setFalse}
      >
        <CreateContactsTalentsPoolForm
          contactsIds={contactsIds}
          onClose={dialogOpen.setFalse}
        />
      </Dialog>
    </>
  )
}
