import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCVFill = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <circle cx="8" cy="8" r="8" fill="currentColor" />
      <path
        d="M5.84659 10.7601C5.14437 10.7601 4.56437 10.5267 4.10659 10.0601C3.64881 9.59341 3.41992 9.01785 3.41992 8.33341C3.41992 7.64452 3.64881 7.06896 4.10659 6.60674C4.56437 6.14007 5.14437 5.90674 5.84659 5.90674C6.26881 5.90674 6.6577 6.00674 7.01326 6.20674C7.37326 6.40229 7.65326 6.66896 7.85326 7.00674L7.05992 7.46674C6.94437 7.25785 6.77992 7.09563 6.56659 6.98007C6.35326 6.86007 6.11326 6.80007 5.84659 6.80007C5.39326 6.80007 5.02659 6.94229 4.74659 7.22674C4.47103 7.51118 4.33326 7.88007 4.33326 8.33341C4.33326 8.78229 4.47103 9.14896 4.74659 9.43341C5.02659 9.71785 5.39326 9.86007 5.84659 9.86007C6.11326 9.86007 6.35326 9.80229 6.56659 9.68674C6.78437 9.56674 6.94881 9.40452 7.05992 9.20007L7.85326 9.66007C7.65326 9.99785 7.37548 10.2667 7.01992 10.4667C6.66437 10.6623 6.27326 10.7601 5.84659 10.7601Z"
        fill="white"
      />
      <path
        d="M9.95221 10.6667L8.38555 6.00007H9.38555L10.5255 9.58674L11.6589 6.00007H12.6655L11.0922 10.6667H9.95221Z"
        fill="white"
      />
    </SvgIcon>
  )
}
