import { Box, Stack, Typography } from '@mui/material'
import { IconMinus, IconPlus } from 'assets/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Document, Page, pdfjs } from 'react-pdf'
import { Button } from 'ui/inputs/button'
import { IconButton } from 'ui/inputs/icon-button'
import { TextField } from 'ui/inputs/text-field'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

type Props = Readonly<{
  file: File
}>

export const PdfPreview = ({ file }: Props) => {
  const { t } = useTranslation()
  const [numPages, setNumPages] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [scale, setScale] = useState(100)

  const [inputScale, setInputScale] = useState<string>(scale.toString())

  const onZoomOut = () => {
    setScale(prevScale => {
      const newScale = prevScale - 10
      const value = newScale < 25 ? 25 : newScale
      setInputScale(value.toString())
      return value
    })
  }

  const onZoomIn = () => {
    setScale(prevScale => {
      const newScale = prevScale + 10
      const value = newScale > 500 ? 500 : newScale
      setInputScale(value.toString())
      return value
    })
  }

  const onScaleChange = () => {
    const parsed = Number.parseInt(inputScale, 10)

    if (!Number.isNaN(parsed)) {
      if (parsed < 25) {
        setScale(25)
        setInputScale('25')
      } else if (parsed > 500) {
        setScale(500)
        setInputScale('500')
      } else setScale(parsed)
      return
    }

    setScale(100)
  }

  const changePage = (offset: number) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  const goToPrevPage = () => {
    return changePage(-1)
  }

  const goToNextPage = () => {
    return changePage(1)
  }

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          bgcolor: '#FFFFFF',
          padding: '8px 24px',
          borderBottom: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box display="flex" alignItems="center">
          <IconButton disabled={scale <= 25} onClick={onZoomOut}>
            <IconMinus sx={{ fontSize: 14 }} />
          </IconButton>

          <Box width={70} mx={1}>
            <TextField
              value={`${inputScale}%`}
              onChange={value => setInputScale(value.slice(0, -1))}
              onKeyPress={event => {
                if (event.key === 'Enter') onScaleChange()
              }}
            />
          </Box>

          <IconButton disabled={scale >= 500} onClick={onZoomIn}>
            <IconPlus sx={{ fontSize: 14 }} />
          </IconButton>
        </Box>

        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="text.primary" mr={2}>
            {t('common.pages', {
              page: pageNumber || (numPages ? 1 : '--'),
              pages: numPages || '--',
            })}
          </Typography>

          <Stack direction="row" alignItems="center" spacing={3}>
            <Button disabled={pageNumber <= 1} onClick={goToPrevPage}>
              {t('common.previous')}
            </Button>

            <Button disabled={pageNumber >= numPages} onClick={goToNextPage}>
              {t('common.next')}
            </Button>
          </Stack>
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" p={2} overflow="auto">
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          <Page scale={scale / 100} pageNumber={pageNumber} />
        </Document>
      </Box>
    </Box>
  )
}
