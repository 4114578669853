import { RecruiterId } from 'api/auth'
import {
  createListSearchParams,
  ListOrder,
  ListPagination,
} from 'api/list-params'
import { pipe } from 'fp-ts/function'
import { decodeJson, decodeJsonWithTotal, get, put } from 'lib/request'

import { TKPISettings, TTeamWorkload } from './kpi-settings.codecs'

export const getKPISettings = async () => {
  return pipe(
    await get('api/companies/me/kpi-settings', {}),
    decodeJson(TKPISettings),
  )
}

export type UpdateKPIWorkloadsInput = {
  workloads: Array<
    RecruiterId & {
      jobsAmount: number
    }
  >
}

export const updateKPIWorkloads = async (input: UpdateKPIWorkloadsInput) => {
  return await put('api/companies/me/kpi-settings/workloads', {
    body: input,
  })
}

export type UpdateKPIContactsAdditionGoalsInput = {
  contactAdditionGoals: Array<
    RecruiterId & {
      period: string
      contactsAmount: number
    }
  >
}

export const updateKPIContactsAdditionGoals = async (
  input: UpdateKPIContactsAdditionGoalsInput,
) => {
  return await put('api/companies/me/kpi-settings/contact-addition-goals', {
    body: input,
  })
}

export type GetTeamWorkloadInput = {
  order?: ListOrder
  pagination: ListPagination
}

export const getTeamWorkload = async ({
  pagination,
  order,
}: GetTeamWorkloadInput) => {
  const params = createListSearchParams({ pagination, order })

  return pipe(
    await get('api/companies/me/kpi-settings/workload-dashboard', {
      query: params,
    }),
    decodeJsonWithTotal(TTeamWorkload, pagination),
  )
}
