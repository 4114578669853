import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconMoreVert = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 6C14 4.89583 13.1042 4 12 4C10.8958 4 10 4.89583 10 6C10 7.10417 10.8958 8 12 8C13.1042 8 14 7.10417 14 6ZM14 11.6667C14 10.5625 13.1042 9.66667 12 9.66667C10.8958 9.66667 10 10.5625 10 11.6667C10 12.7708 10.8958 13.6667 12 13.6667C13.1042 13.6667 14 12.7708 14 11.6667ZM12 15.3333C13.1042 15.3333 14 16.2292 14 17.3333C14 18.4375 13.1042 19.3333 12 19.3333C10.8958 19.3333 10 18.4375 10 17.3333C10 16.2292 10.8958 15.3333 12 15.3333Z"
      fill="currentColor"
    />
  </SvgIcon>
)
