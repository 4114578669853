import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconFont = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M18.3867 4.5H5.62055L5.4975 8.19141H6.21527C6.60492 5.87402 7.35346 5.51514 10.2553 5.42285L10.8911 5.40234V17.1431C10.8911 18.1377 10.6757 18.3735 8.94281 18.5273V19.3066H15.0644V18.5273C13.3212 18.3735 13.1059 18.1377 13.1059 17.1431V5.40234L13.7519 5.42285C16.6537 5.51514 17.4023 5.87402 17.7919 8.19141H18.5097L18.3867 4.5Z"
      fill="currentColor"
    />
  </SvgIcon>
)
