import { FilterHistoryValues } from 'api'
import { formatInTimeZone } from 'date-fns-tz'

export enum ContactsUrlFilters {
  Levels = 'levels',
  Positions = 'positions',
  Skills = 'skills',
  Sources = 'sources',
  Country = 'country',
  City = 'city',
  TalentPools = 'talent-pools',
  DateFrom = 'date-from',
  DateTo = 'date-to',
  column = 'column',
  direction = 'direction',
}

export const createContactsUrlFilters = (
  filters: FilterHistoryValues,
  timeZone: string,
): string => {
  const params = new URLSearchParams()

  if (filters.levels) {
    for (const level of filters.levels) {
      params.append(ContactsUrlFilters.Levels, level.code)
    }
  }

  if (filters.positions) {
    for (const position of filters.positions) {
      params.append(ContactsUrlFilters.Positions, position.positionId)
    }
  }

  if (filters.skills) {
    for (const skill of filters.skills) {
      params.append(ContactsUrlFilters.Skills, skill.skillId)
    }
  }

  if (filters.location?.country) {
    params.set(ContactsUrlFilters.Country, filters.location.country)
  }

  if (filters.location?.city) {
    params.set(ContactsUrlFilters.City, filters.location.city)
  }

  if (filters.from) {
    params.set(
      ContactsUrlFilters.DateFrom,
      formatInTimeZone(filters.from, timeZone, 'yyyy-MM-dd'),
    )
  }

  if (filters.to) {
    params.set(
      ContactsUrlFilters.DateTo,
      formatInTimeZone(filters.to, timeZone, 'yyyy-MM-dd'),
    )
  }

  return params.toString()
}
