import { ToastContainerId } from 'app/enums'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useDismissToasts } from './use-dismiss-toasts'

type LocationState = { saveToasts?: boolean } | null

/**
 * Custom React Hook that automatically dismisses system toasts on route change.
 * To prevent dismissing toasts, set `saveToasts` as true in the location state.
 */
export const useCloseToastsOnRouteChange = () => {
  // Get the current location object from the React Router
  const location = useLocation()

  // Access the dismissToastsByContainerId function from the useDismissToasts hook
  const { dismissToastsByContainerId } = useDismissToasts()

  // Extract the location state
  const state = location.state as LocationState

  // Effect to dismiss all system toasts on route change
  useEffect(() => {
    // If the location state does not have `saveToasts` set to true, dismiss system toasts
    if (!(state && state.saveToasts)) {
      // Dismiss toasts from the system container
      dismissToastsByContainerId(ToastContainerId.System)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
}
