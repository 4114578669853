import * as t from 'io-ts'

export const TCompanySource = t.intersection([
  t.strict({
    id: t.string,
    name: t.string,
  }),
  t.partial({ systemType: t.literal('CAREER_SITE') }),
])

export const TCompanySources = t.array(TCompanySource)

export type CompanySources = t.TypeOf<typeof TCompanySources>
