import { Link } from '@mui/material'
import { AXTERIOR_HELP_EMAIL } from 'lib/records'
import { ReactNode } from 'react'
import { TFuncKey, TFunction, Trans } from 'react-i18next'

import { isRequestError } from './error-types'

/**
 * Retrieves error details and messages based on the type of request error.
 *
 * @param {unknown} error - The error object.
 * @param {TFunction} t - The i18next translation function.
 * @param {'query' | 'mutation'} type - The type of request error ('query' for queries, 'mutation' for mutations).
 * @returns {{ title: string; message: string | ReactNode }} - An object containing the error title and message.
 */
export const getRequestErrorMessage = (
  error: unknown,
  t: TFunction,
  type: 'query' | 'mutation' = 'query',
): { title: string; message: string | ReactNode } => {
  const unknownError = {
    title: t('errors.unknown_error'),
    message: t('errors.unknown_error_description'),
  }

  if (isRequestError(error)) {
    switch (error.type) {
      case 'client': {
        const clientErrorTitle = t(
          `errors.client_error_titles.${
            error.code as TFuncKey<'translation', 'errors.client_error_titles'>
          }`,
          { defaultValue: t('errors.mutation') },
        )

        return {
          title: type === 'query' ? t('errors.query') : clientErrorTitle,
          message: t(
            `errors.client_errors.${
              error.code as TFuncKey<'translation', 'errors.client_errors'>
            }`,
            { defaultValue: t('errors.default_error_message') },
          ),
        }
      }
      case 'network': {
        return {
          title: t('errors.network_error'),
          message: t('errors.network_error_description'),
        }
      }
      case 'server':
      case 'decode_body':
      case 'decode_headers':
      case 'parse_blob':
      case 'parse_json': {
        return {
          title: t('errors.something_wrong'),
          message: (
            <Trans
              i18nKey="errors.try_reload_page"
              values={{ email: AXTERIOR_HELP_EMAIL }}
              components={{
                linkElement: <Link href={`mailto:${AXTERIOR_HELP_EMAIL}`} />,
              }}
            />
          ),
        }
      }
      default: {
        return unknownError
      }
    }
  }

  return unknownError
}
