import { Skeleton } from '@mui/material'
import { range } from 'fp-ts/NonEmptyArray'
import { randomInt } from 'fp-ts/Random'
import { memo } from 'react'

import { Table } from './table'

export const CellSkeleton = memo(() => {
  return <Skeleton sx={{ width: randomInt(75, 125)() }} />
})

export const TableSkeleton = memo(
  ({
    columnsCount,
    rowsCount,
    noBorder,
  }: {
    columnsCount: number
    rowsCount?: number
    noBorder?: boolean
  }) => {
    return (
      <Table
        noBorder={noBorder}
        columns={range(0, columnsCount - 1).map(index => ({
          key: index.toString(),
          name: <CellSkeleton />,
        }))}
        rows={range(0, rowsCount ? rowsCount - 1 : 9).map(index => ({
          key: index.toString(),
          cells: range(0, columnsCount - 1).map(cellIndex => (
            <CellSkeleton key={cellIndex} />
          )),
        }))}
      />
    )
  },
)
