import { qk } from 'api'
import { getCitiesList } from 'api/search/cities-search.api'
import { getOptionsFromStringArray } from 'lib/form-utils'
import { useInfinitePaginatedQuery } from 'lib/react-query-utils'
import { useDebounceState } from 'lib/react-utils'
import { forwardRef, Ref } from 'react'
import { useTranslation } from 'react-i18next'

import { Autocomplete, AutocompleteProps } from '../../ui/inputs/autocomplete'

export type Props = Omit<AutocompleteProps, 'options'> & {
  country: string
  dataCy?: string
}

export const CityAutocomplete = forwardRef(
  (
    { value, onChange, country, dataCy, ...rest }: Props,
    ref: Ref<HTMLInputElement>,
  ) => {
    const { t } = useTranslation()

    const [searchValue, setSearchValue] = useDebounceState(value, 500)

    const $cities = useInfinitePaginatedQuery(
      qk.globalSearch.cities,
      getCitiesList,
      {
        search: searchValue || value,
        pagination: { page: 0, pageSize: 25 },
        countryCode: country,
      },
      {
        keepPreviousData: false,
        enabled: searchValue.length > 0 || value.length > 0,
      },
    )

    return (
      <Autocomplete
        {...rest}
        ref={ref}
        options={
          $cities.data
            ? getOptionsFromStringArray(
                $cities.data.pages.flatMap(page => page.rows),
              )
            : []
        }
        value={value}
        onChange={(newValue, option) => {
          setSearchValue(newValue)
          onChange(newValue, option)
        }}
        dataCy={dataCy}
        onInputChange={setSearchValue}
        loading={$cities.isLoading}
        loadingError={$cities.isError}
        hasMoreOptions={$cities.hasNextPage}
        onLoadMore={$cities.fetchNextPage}
        isLoadingMore={$cities.isFetchingNextPage}
        disableInternalFilter
        disableAlphabeticalOrder
        closedWhileInputEmpty
        disabled={!country}
        hint={!country ? t('hints.country_before_city') : undefined}
      />
    )
  },
)
