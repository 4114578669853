import { RecruiterId } from 'api/auth'
import * as t from 'io-ts'

export type GetReportInput = {
  from: Date
  to: Date
}

export type GetRecruiterReportInput = RecruiterId & GetReportInput

export type GetGenericReportInput = Partial<RecruiterId> & GetReportInput

export const TReportRecord = t.record(t.string, t.number)

export const TReportQualifierAmounts = t.array(
  t.intersection([
    t.strict({ qualifier: t.string, amount: t.number }),
    t.partial({ percentage: t.number }),
  ]),
)
