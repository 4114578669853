import { Format12Hours } from './is-format-12-hours'
import { Format24Hours, format24HoursRegex } from './is-format-24-hours'

export const format24HoursTo12HoursTime = (
  timeText: Format24Hours,
): Format12Hours => {
  const match = timeText.match(format24HoursRegex)

  if (!match) {
    throw new Error('Invalid time format')
  }

  let hours: number = Number.parseInt(match[1]!, 10)
  const minutes: number = Number.parseInt(match[2]!, 10)

  const meridiem = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12 || 12

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
    2,
    '0',
  )} ${meridiem}` as Format12Hours
}
