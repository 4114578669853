import { Box, Divider, Paper, Stack, Typography } from '@mui/material'
import { IconPencil } from 'assets/icons'
import { ReadonlyNonEmptyArray } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import { useTranslation } from 'react-i18next'
import { Badge, BadgeCollection } from 'ui/data'
import { IconButton } from 'ui/inputs/icon-button'

type Props = Readonly<{
  groupName: string
  onEdit: () => void
  sections: ReadonlyNonEmptyArray<
    | {
        name: string
        values: Array<string>
      }
    | {
        name: string
        content: React.ReactNode
      }
  >
}>

export const SummaryGroup = ({ groupName, onEdit, sections }: Props) => {
  const { t } = useTranslation()

  return (
    <Paper>
      <Box
        sx={{
          py: 1.5,
          px: 2.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h2">{groupName}</Typography>
        <IconButton onClick={onEdit}>
          <IconPencil filled sx={{ color: 'primary.main', fontSize: '20px' }} />
        </IconButton>
      </Box>
      <Divider />
      <Stack spacing={3} divider={<Divider flexItem />} sx={{ py: 3, px: 2.5 }}>
        {sections.map(section => (
          <Box key={section.name}>
            <Typography variant="h3" mb={2}>
              {section.name}
            </Typography>

            {'values' in section ? (
              section.values.length > 0 ? (
                <BadgeCollection>
                  {section.values.map(value => (
                    <Badge key={value}>{value}</Badge>
                  ))}
                </BadgeCollection>
              ) : (
                <Typography color="text.secondary">
                  {t('manual_settings_setup.have_no_parameters')}
                </Typography>
              )
            ) : (
              section.content
            )}
          </Box>
        ))}
      </Stack>
    </Paper>
  )
}
