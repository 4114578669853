import { Format24Hours } from './is-format-24-hours'

export const formatDateTo24HoursTime = (date: Date): Format24Hours => {
  const hours: number = date.getHours()
  const minutes: number = date.getMinutes()

  const formattedHours: string = hours.toString().padStart(2, '0')
  const formattedMinutes: string = minutes.toString().padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}` as Format24Hours
}
