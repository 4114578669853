import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { createContactsTalentsPool } from 'api'
import { paths } from 'app/routes'
import { ContactsSelectList } from 'components/contacts/contacts-select-list'
import { showToast } from 'lib/toast'
import mixpanel from 'mixpanel-browser'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { DialogContent } from 'ui/feedback'
import { renderTextField } from 'ui/inputs/text-field'
import { z } from 'zod'

const schema = z.object({
  name: z.string().trim().min(2).max(125),
  contactsIds: z.array(z.string()),
})

type Props = {
  contactsIds: Array<string>
  onClose: () => void
}

export const CreateContactsTalentsPoolForm = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const $createTalentsPool = useMutation(createContactsTalentsPool)

  const { handleSubmit, control } = useForm<z.infer<typeof schema>>({
    defaultValues: {
      name: '',
      contactsIds: props.contactsIds,
    },
    resolver: zodResolver(schema),
  })

  return (
    <DialogContent
      title={t('common.create_new_pool')}
      hint={t('hints.toolbar.create_talent_pool')}
      wrapWithForm
      onDeny={props.onClose}
      confirmText={t('common.create')}
      isActionLoading={$createTalentsPool.isLoading}
      onConfirm={handleSubmit(values => {
        $createTalentsPool.mutate(
          {
            name: values.name,
            contactsIds: values.contactsIds,
          },
          {
            onSuccess: data => {
              mixpanel.track('Create talents pool')
              navigate(
                generatePath(paths.talentsPoolContacts, {
                  talentPoolId: data.talentPoolId,
                }),
                { state: { saveToasts: true } },
              )
              showToast({ title: t('toasts.actions_talents_pool_created') })
              props.onClose()
            },
          },
        )
      })}
    >
      <Box mb={3}>
        <Controller
          control={control}
          name="name"
          render={renderTextField({
            label: t('common.pool_name'),
            required: true,
          })}
        />
      </Box>

      <Controller
        control={control}
        name="contactsIds"
        render={({ field }) => (
          <ContactsSelectList
            selected={field.value}
            setSelected={field.onChange}
          />
        )}
      />
    </DialogContent>
  )
}
