import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
export const IconBlogOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 9">
    <path
      d="M1 2.5C1 1.39543 1.89543 0.5 3 0.5H15C16.1046 0.5 17 1.39543 17 2.5V7.5C17 8.60457 16.1046 9.5 15 9.5H3C1.89543 9.5 1 8.60457 1 7.5V2.5ZM2 5.5V7.5C2 8.05228 2.44772 8.5 3 8.5H15C15.5523 8.5 16 8.05228 16 7.5V5.5H2ZM16 4.5V2.5C16 1.94772 15.5523 1.5 15 1.5H3C2.44772 1.5 2 1.94772 2 2.5V4.5H16ZM3 3C3 2.72386 3.22386 2.5 3.5 2.5H12.5C12.7761 2.5 13 2.72386 13 3C13 3.27614 12.7761 3.5 12.5 3.5H3.5C3.22386 3.5 3 3.27614 3 3ZM3 7C3 6.72386 3.22386 6.5 3.5 6.5H9.5C9.77614 6.5 10 6.72386 10 7C10 7.27614 9.77614 7.5 9.5 7.5H3.5C3.22386 7.5 3 7.27614 3 7Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
  </SvgIcon>
)
