import { DaysOfWeek, TDaysOfWeek } from 'api/common'
import * as t from 'io-ts'

const TTimePeriod = t.strict({
  timeFrom: t.string,
  timeTo: t.string,
})

export type TimePeriod<T> = {
  timeFrom: T
  timeTo: T
}

export type SpecificAvailability = {
  period: {
    timeFrom: Date
    timeTo: Date
  }
  date: Date
  active: boolean
}

const TDayOfWeek = t.strict({
  active: t.boolean,
  periods: t.array(TTimePeriod),
})

export const TAvailabilities = t.strict({
  availabilities: t.record(TDaysOfWeek, TDayOfWeek),
  eventType: t.literal('INTERVIEW'),
  timezone: t.strict({ name: t.string, offset: t.string }),
})

export const TSpecificAvailability = t.strict({
  period: t.strict({
    timeFrom: t.string,
    timeTo: t.string,
  }),
  active: t.boolean,
  date: t.string,
})

export type SpecificAvailabilityGet = t.TypeOf<typeof TSpecificAvailability>

export const TSpecificAvailabilities = t.strict({
  availabilities: t.array(TSpecificAvailability),
  eventType: t.literal('INTERVIEW'),
})

export type Availabilities<T> = Record<
  DaysOfWeek,
  {
    periods: Array<TimePeriod<T>>
    active: boolean
  }
>

export const TSchedulingLinksSettings = t.intersection([
  t.strict({
    eventType: t.literal('INTERVIEW'),
    meetingDurationMin: t.number,
    timezone: t.strict({ name: t.string, offset: t.string }),
    displayRangeDay: t.number,
    linkLifetimeMin: t.number,
  }),
  t.partial({ meetingsPerDay: t.number, bufferTimeMin: t.number }),
])
