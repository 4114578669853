import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconChartBreakoutCircle = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 23 22"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M14.5 2.5V1M18.4393 3.56066L19.5 2.5M19.5103 7.5H21.0103M20.9506 12C20.4489 17.0533 16.1853 21 11 21C5.47715 21 1 16.5228 1 11C1 5.81465 4.94668 1.5511 10 1.04938M11 7H15V11M14.6197 7C12.2653 10.3276 8.38636 12.5 4 12.5C2.9971 12.5 2.02072 12.3864 1.08302 12.1715"
      stroke="currentColor"
      strokeWidth={props.stroke ?? '1.4'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
