import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconClear = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 25"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <g clipPath="url(#clip0_577_3294)">
      <path
        d="M9.198 12.063L8.08322 16.5342C7.61787 18.3109 6.64099 18.6244 5.21777 19.511C7.63241 19.4956 12.0005 19.5672 14.4441 19.5708C14.5058 19.5708 14.5366 19.5709 14.5859 19.5661C14.616 19.5631 14.6866 19.5515 14.7161 19.5446C14.7643 19.5333 14.7765 19.5292 14.8007 19.5209C16.6145 18.9024 17.1904 15.4837 17.5814 14.1532M9.198 12.063L17.5814 14.1532M9.198 12.063L9.23283 11.9233C9.52549 10.7495 9.67182 10.1626 10.012 9.77127C10.3113 9.42701 10.7119 9.18633 11.1563 9.08372C11.6616 8.96707 12.2485 9.1134 13.4223 9.40605L15.0989 9.82409C16.2727 10.1167 16.8596 10.2631 17.251 10.6033C17.5952 10.9025 17.8359 11.3031 17.9385 11.7476C18.0552 12.2528 17.9088 12.8397 17.6162 14.0135L17.5814 14.1532M12.5141 9.17961L13.2979 6.03585C13.5384 5.07127 14.5153 4.48428 15.4799 4.72478C16.4445 4.96528 17.0314 5.94219 16.791 6.90677L16.0071 10.0505"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_577_3294">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
)
