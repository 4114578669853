import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconArrowLeft = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 28 28">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 14C21 14.4833 20.6082 14.875 20.125 14.875H9.98744L13.7437 18.6313C14.0854 18.973 14.0854 19.527 13.7437 19.8687C13.402 20.2104 12.848 20.2104 12.5063 19.8687L7.25628 14.6187C6.91457 14.277 6.91457 13.723 7.25628 13.3813L12.5063 8.13128C12.848 7.78957 13.402 7.78957 13.7437 8.13128C14.0854 8.47299 14.0854 9.02701 13.7437 9.36872L9.98744 13.125H20.125C20.6082 13.125 21 13.5168 21 14Z"
      fill="currentColor"
    />
  </SvgIcon>
)
