import { isRight } from 'fp-ts/lib/Either'
import * as t from 'io-ts'

export type RequestErrorClient = {
  type: 'client'
  response: Response
  message: string
  code: string
  data?: unknown
}

export type RequestError =
  | RequestErrorClient
  | {
      type: 'decode_headers'
      headers: Record<string, string>
      message: string
    }
  | { type: 'decode_body'; json: unknown; message: string }
  | { type: 'network'; error: TypeError }
  | { type: 'parse_blob'; response: Response; error: Error }
  | { type: 'parse_json'; response: Response; error: Error }
  | { type: 'server'; response: Response }

const TRequestError = t.type({
  _isRequestError: t.literal(true),
})

/**
 * Checks if the given error object is a request error.
 *
 * @param {unknown} error - The error object to check.
 * @returns {boolean} - `true` if the error is a request error, otherwise `false`.
 */
export const isRequestError = (error: unknown): error is RequestError => {
  return isRight(TRequestError.decode(error))
}

/**
 * Checks if the given error object is a client-side request error.
 *
 * @param {unknown} error - The error object to check.
 * @returns {boolean} - `true` if the error is a client-side request error, otherwise `false`.
 */
export const isClientError = (error: unknown): error is RequestErrorClient => {
  return isRequestError(error) && error.type === 'client'
}

/**
 * Wraps the provided request error in an object with an additional property `_isRequestError`.
 * This function is used to ensure the error object conforms to the runtime type definition.
 *
 * @param {RequestError} error - The request error to wrap.
 * @returns {RequestError & { _isRequestError: true }} - The wrapped request error.
 */
export const requestError = (
  error: RequestError,
): RequestError & { _isRequestError: true } => {
  return {
    _isRequestError: true,
    ...error,
  }
}
