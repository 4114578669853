import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconEducationOutline = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6108 1.75946C11.8494 1.6356 12.1334 1.63551 12.3721 1.75923L23.5536 7.55398C23.8239 7.69406 23.9954 7.97135 23.9999 8.27576C24.0044 8.58016 23.8413 8.86243 23.5753 9.01049L19.6078 11.2188V17.7917C19.6078 18.0939 19.4429 18.372 19.1779 18.517L12.3886 22.2324C12.1355 22.3709 11.8284 22.3674 11.5785 22.2232L5.13948 18.5079C4.88356 18.3602 4.7259 18.0872 4.7259 17.7917V11.406L0.42451 9.01039C0.158649 8.86232 -0.00438474 8.58018 8.97986e-05 8.2759C0.00456429 7.97162 0.175824 7.6944 0.445923 7.55421L11.6108 1.75946ZM6.3795 12.3269V17.3142L12.0024 20.5587L17.9542 17.3017V12.1392L12.3988 15.2314C12.1487 15.3706 11.8444 15.3706 11.5944 15.2313L6.3795 12.3269ZM11.992 3.42469L2.57215 8.31373L11.9968 13.5627L21.4265 8.31406L11.992 3.42469Z"
      fill="currentColor"
    />
  </SvgIcon>
)
