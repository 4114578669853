import { QueryClient } from '@tanstack/react-query'
import { qk } from 'api'
import { setGlobalAccessToken } from 'app/auth'
import { isClientError } from 'lib/request'
import { defaultErrorToast } from 'lib/toast'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

// It is a global configuration. You can change it per-query as well.
// Stale queries are refetched automatically in the background when:
// - New instances of the query mount
// - The network is reconnected.
export const useGlobalQueryClient = () => {
  const { t } = useTranslation()

  const queryClient = useMemo(() => new QueryClient(), [])

  const checkTokenValidity = useCallback(
    (error: unknown) => {
      if (
        isClientError(error) &&
        (error.code === 'error_token_expired' ||
          error.code === 'error_invalid_token' ||
          error.code === 'error_refresh_token_not_found')
      ) {
        setGlobalAccessToken(null)
        queryClient.invalidateQueries(qk.auth.token.refresh.toKey())
      }
    },
    [queryClient],
  )

  const handleMutationError = useCallback(
    (error: unknown) => {
      checkTokenValidity(error)

      defaultErrorToast(error, t)
    },
    [t, checkTokenValidity],
  )

  queryClient.setDefaultOptions({
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      onError: checkTokenValidity,
    },
    mutations: {
      onError: handleMutationError,
    },
  })

  return queryClient
}
