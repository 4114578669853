import { useQuery } from '@tanstack/react-query'
import { getCompanyDetails, getMyProfile, qk, StartWeekDay } from 'api'
import { useScopes } from 'app/scopes'
import { setDefaultOptions } from 'date-fns'
import enLocale from 'date-fns/locale/en-US'
import { changeLanguage } from 'i18next'
import { useCallback, useState } from 'react'

/**
 * key - language code from API
 * value - locale code of i18next resource
 */
const localeByLanguage: Record<string, string> = {
  SPA: 'es',
  FRE: 'fr',
  UKR: 'uk',
  ENG: 'en',
  ITA: 'it',
}

/**
 * Custom hook to manage the application's locale and start week day.
 *
 * @returns {Object} - Object containing locale information.
 */
export const useLocale = () => {
  const [locale, setLocale] = useState(enLocale)
  const [startWeekDay, setStartWeekDay] = useState<StartWeekDay>('SUNDAY')
  const { canManageCompany } = useScopes()

  /**
   * Function to update locale options based on the selected locale and start week day.
   *
   * @param {Locale} newLocale - New locale object.
   * @param {StartWeekDay} newStartWeekDay - New start week day value.
   */
  const updateLocaleOptions = useCallback(
    (newLocale: Locale, newStartWeekDay: StartWeekDay) => {
      const dayIndex = newStartWeekDay === 'SUNDAY' ? 0 : 1

      if (newLocale.options) {
        newLocale.options.weekStartsOn = dayIndex
      } else {
        newLocale.options = { weekStartsOn: dayIndex }
      }

      setLocale(newLocale)
      setStartWeekDay(newStartWeekDay)

      setDefaultOptions({
        locale: newLocale,
        weekStartsOn: dayIndex,
      })
    },
    [],
  )

  // As long as user updates company details, update locale with the new start week day
  useQuery(qk.company.details.toKey(), getCompanyDetails, {
    enabled: canManageCompany,

    onSuccess: data => {
      updateLocaleOptions(locale, data.startWeekDay)
    },
  })

  // As long as user updates own profile, update locale according to the selected language
  useQuery(qk.auth.users.myProfile.toKey(), getMyProfile, {
    onSuccess: async data => {
      const localeCode = localeByLanguage[data.languageCode]
      if (localeCode) {
        changeLanguage(localeCode)

        const dateFnsLocaleCode = localeCode === 'en' ? 'en-US' : localeCode
        const newLocale = await import(
          `date-fns/locale/${dateFnsLocaleCode}/index.js`
        )

        if (newLocale) {
          updateLocaleOptions(newLocale, startWeekDay)
        }
      }
    },
  })

  return { locale }
}
