import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconOpenFullscreen = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.74255 15.2573C8.44966 14.9644 7.97478 14.9644 7.68189 15.2573L1.5376 21.4016L1.5376 17.2499C1.5376 16.8357 1.20181 16.4999 0.787599 16.4999C0.373385 16.4999 0.0375977 16.8357 0.0375982 17.2499L0.0375977 23.2122C0.0375977 23.6264 0.373385 23.9622 0.787598 23.9622L6.74985 23.9622C7.16406 23.9622 7.49985 23.6264 7.49985 23.2122C7.49985 22.798 7.16406 22.4622 6.74985 22.4622L2.59826 22.4622L8.74255 16.3179C9.03544 16.025 9.03544 15.5502 8.74255 15.2573Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2575 8.74273C15.5503 9.03563 16.0252 9.03563 16.3181 8.74273L22.4624 2.59844L22.4624 6.7501C22.4624 7.16432 22.7982 7.5001 23.2124 7.5001C23.6266 7.5001 23.9624 7.16432 23.9624 6.7501L23.9624 0.787782C23.9624 0.373569 23.6266 0.0377808 23.2124 0.0377808L17.2502 0.0377855C16.8359 0.0377855 16.5001 0.373572 16.5001 0.787786C16.5001 1.202 16.8359 1.53779 17.2502 1.53779L21.4017 1.53778L15.2575 7.68207C14.9646 7.97497 14.9646 8.44984 15.2575 8.74273Z"
      fill="currentColor"
    />
  </SvgIcon>
)
