import { Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getTimeZonesList, qk } from 'api'
import { useTranslation } from 'react-i18next'
import { Autocomplete, AutocompleteProps } from 'ui/inputs/autocomplete'

export const TimeZoneAutocomplete = (
  props: Omit<AutocompleteProps, 'options'>,
) => {
  const { t } = useTranslation()
  const $timeZones = useQuery(qk.timeZones.list.toKey(), getTimeZonesList)

  return (
    <Autocomplete
      {...props}
      label={t('common.time_zone')}
      loadingError={$timeZones.isError}
      options={
        $timeZones.data
          ? Object.entries($timeZones.data).map(([zoneId, offset]) => ({
              value: zoneId,
              label: `(UTC${offset}) ${zoneId}`,
              content: (
                <Typography variant="body2">
                  (UTC{offset}) {zoneId}
                </Typography>
              ),
            }))
          : []
      }
    />
  )
}
