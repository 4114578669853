import './transitions.css'

import {
  alpha,
  Box,
  ClickAwayListener,
  Divider,
  Grow,
  Paper,
  Popper,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  getMyNotifications,
  qk,
  readNotifications,
  useInvalidateNotifications,
} from 'api'
import { APP_LAYOUT_X_PADDING } from 'app/mui-theme/theme'
import { paths } from 'app/routes'
import { useScopes } from 'app/scopes'
import { range } from 'fp-ts/NonEmptyArray'
import { renderQueryResult } from 'lib/react-query-utils'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Button } from 'ui/inputs/button'
import { LinkButton } from 'ui/navigation/link'

import { NotificationItem } from './notification-item'

type Props = {
  isOpen: boolean
  onClose: () => void
  anchorElement: HTMLElement | null
}

export const NotificationsWindow = ({
  isOpen,
  onClose,
  anchorElement,
}: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const invalidateNotifications = useInvalidateNotifications()

  const queryArgs = {
    read: false,
    pagination: {
      page: 0,
      pageSize: 5,
    },
    order: {
      column: 'created_at',
      direction: 'desc',
    },
  } as const

  const $notifications = useQuery(
    qk.notifications.list.toKeyWithArgs(queryArgs),
    () => getMyNotifications(queryArgs),
    { keepPreviousData: true },
  )

  const $readNotifications = useMutation(readNotifications)

  const { canManageNotifications } = useScopes()

  // Component open/close state is determined only by `isOpen` boolean, it stays in render tree
  // That is why we need to explicitly refetch notifications here
  useEffect(() => {
    if (isOpen) {
      invalidateNotifications()
    }
  }, [isOpen, invalidateNotifications])

  useEffect(() => {
    // Close window if user reads all notifications, but wait for transition to finish
    if ($notifications.data && $notifications.data.rows.length === 0) {
      setTimeout(() => {
        onClose()
      }, 400)
    }
  }, [onClose, $notifications.data])

  return (
    <Popper
      style={{ zIndex: theme.zIndex.modal }}
      open={isOpen}
      anchorEl={anchorElement}
      disablePortal
      placement="bottom"
      transition
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={onClose}>
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: '100% 0',
            }}
          >
            <Paper
              variant="elevation"
              sx={{
                width: { xs: 280, sm: 440 },
                minHeight: 240,
                display: 'flex',
                marginRight: APP_LAYOUT_X_PADDING,
                flexDirection: 'column',
                border: theme => `1px solid ${theme.palette.primary.main}`,
                borderRadius: '5px',
                boxShadow: `0px 1px 1px ${alpha(
                  '#000000',
                  0.1,
                )}, 0px 2px 6px ${alpha('#0043F0', 0.24)}`,
              }}
            >
              <Box
                sx={{
                  padding: '10px 16px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="h4">
                  {t('common.notifications')}
                </Typography>

                {$notifications.data && canManageNotifications && (
                  <Button
                    variant="text"
                    disabled={$readNotifications.isLoading}
                    sx={{ color: 'primary.main' }}
                    size="small"
                    onClick={() => {
                      $readNotifications.mutate(
                        {
                          notificationIds: $notifications.data.rows.map(
                            notification => notification.notificationId,
                          ),
                        },
                        { onSuccess: invalidateNotifications },
                      )
                    }}
                  >
                    {t('common.mark_all_as_read')}
                  </Button>
                )}
              </Box>

              <Divider />

              <Box
                sx={{
                  maxHeight: 460,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  paddingRight: '6px',
                }}
              >
                {renderQueryResult($notifications, {
                  loading: () => (
                    <Stack spacing={2} divider={<Divider flexItem />}>
                      {range(0, 2).map(index => (
                        <Skeleton
                          key={index}
                          height={90}
                          width="100%"
                          variant="rectangular"
                        />
                      ))}
                    </Stack>
                  ),
                  error: () => (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      align="center"
                      mt={3}
                    >
                      <Trans i18nKey="common.failed_to_load_notifications" />
                    </Typography>
                  ),
                  success: notifications => {
                    return (
                      <TransitionGroup>
                        {notifications.rows.map(notification => (
                          <CSSTransition
                            key={notification.notificationId}
                            timeout={700}
                            classNames="notification-item"
                          >
                            <Box
                              sx={{
                                '&:not(:last-child)': {
                                  borderBottom: 1,
                                  borderColor: 'divider',
                                },
                              }}
                            >
                              <NotificationItem
                                notification={notification}
                                onClickLink={onClose}
                              />
                            </Box>
                          </CSSTransition>
                        ))}
                      </TransitionGroup>
                    )
                  },
                })}
              </Box>

              <Box mt="auto">
                <Divider />

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  padding="10px 12px"
                >
                  <LinkButton
                    variant="text"
                    to={paths.notifications}
                    onClick={onClose}
                    sx={{ color: 'primary.main' }}
                    size="small"
                  >
                    {$notifications.data && $notifications.data.count > 5
                      ? t('common.see_more_notifications_count', {
                          moreCount: `${$notifications.data.count - 5}+`,
                        })
                      : t('common.see_all_notifications')}
                  </LinkButton>
                </Box>
              </Box>
            </Paper>
          </Grow>
        </ClickAwayListener>
      )}
    </Popper>
  )
}
