import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { wrapUseRoutes } from '@sentry/react'
import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { getCompanyDetails, getMyProfile, qk } from 'api'
import { useAuthUser } from 'app/auth'
import {
  authRoutes,
  chooseSubscriptionRoutes,
  paths,
  unauthRoutes,
} from 'app/routes'
import { useScopes } from 'app/scopes'
import { IntroductionTourSteps, NotFound } from 'components/global'
import { useIndexPage, useLocale } from 'lib/app-helpers'
import {
  IntroductionTourProvider,
  RouteLeavingGuardControlProvider,
} from 'lib/context'
import mixpanel from 'mixpanel-browser'
import {
  matchRoutes,
  Navigate,
  useLocation,
  useNavigate,
  useRoutes,
} from 'react-router-dom'

import { AuthLayout } from './auth-layout'

const useSentryRoutes = wrapUseRoutes(useRoutes)

export const AuthApp = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { locale } = useLocale()
  const { indexPage } = useIndexPage()
  const { role } = useAuthUser()

  const { canManageCompany } = useScopes()

  const $companyDetails = useQuery(
    qk.company.details.toKey(),
    getCompanyDetails,
    {
      enabled: canManageCompany,
      onSuccess: company => {
        mixpanel.people.set({
          'Company ID': company.companyId,
          'Company name': company.name,
          'Company size': company.teamMembers?.name ?? '',
          'Company industry': company.industry?.name ?? '',
          'Company users count': company.usersCount,
        })

        // Owner must configure settings (manually or with quick start) before using the system.
        // Settings package dialog is placed inside settings page due to the different nested theme.
        // So if settings are not configured yet, we need to redirect the user to settings page.
        // This is unlikely to happen, because the only way this could happen is
        // if user manually changes URL when prompting to configure settings (still better to handle)
        if (role === 'OWNER' && !company.packageConfigured) {
          navigate(paths.personalData, {
            replace: true,
            state: { saveToasts: true },
          })
        }
      },
    },
  )

  useQuery(qk.auth.users.myProfile.toKey(), getMyProfile, {
    staleTime: Number.POSITIVE_INFINITY,
    onSuccess: user => {
      mixpanel.people.set({
        $name: user.fullName,
        $email: user.email,
        $created: user.createdAt,
      })

      Sentry.setUser({
        id: user.userId,
        email: user.email,
        username: user.fullName,
        role: user.role.name,
      })
    },
  })

  const routes = useSentryRoutes([
    ...authRoutes,
    {
      index: true,
      element: <Navigate to={indexPage} replace />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ])

  if ($companyDetails.isLoading && canManageCompany) {
    return null
  }

  if (matchRoutes(unauthRoutes, location)) {
    return <Navigate to={indexPage} replace />
  }

  if (matchRoutes(chooseSubscriptionRoutes, location)) {
    return <Navigate to={paths.settingsGeneral} replace />
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <RouteLeavingGuardControlProvider>
        <IntroductionTourProvider>
          <AuthLayout>{routes}</AuthLayout>
          <IntroductionTourSteps />
        </IntroductionTourProvider>
      </RouteLeavingGuardControlProvider>
    </LocalizationProvider>
  )
}
