import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'ui/navigation'

type Props = Readonly<{
  name: string
  allResultsLink: string
  totalCount: number
  onClickAllResults: () => void
  children: React.ReactNode
}>

export const CategoryResults = ({
  name,
  allResultsLink,
  totalCount,
  onClickAllResults,
  children,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Box pt={1.5} pb={2}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={1.5}
        mb={0.5}
      >
        <Typography variant="body2" fontWeight={500} color="text.secondary">
          {name}
        </Typography>

        <Link
          to={allResultsLink}
          typography="body2"
          color="blue.main"
          onClick={onClickAllResults}
        >
          {t('common.view_all_results', {
            totalCount,
          })}
        </Link>
      </Box>

      {children}
    </Box>
  )
}
