import { authRoutes, unauthRoutes } from 'app/routes'
import { forwardRef, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { mergeRefs } from 'react-merge-refs'
import {
  Link,
  LinkProps,
  matchRoutes,
  NavLink,
  NavLinkProps,
  To,
} from 'react-router-dom'

/** Matches all routes against a given location (to) and preloads found ones */
const useChunksPreload = (to: To) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  })

  useEffect(() => {
    if (inView) {
      const matchedRoutes = matchRoutes([...authRoutes, ...unauthRoutes], to)
      if (matchedRoutes) {
        for (const route of matchedRoutes.map(({ route }) => route)) {
          route.preload?.()
        }
      }
    }
  }, [inView, to])

  return ref
}

export const RouteLink = forwardRef((props: LinkProps, forwardedRef) => {
  const innerRef = useChunksPreload(props.to)
  return <Link ref={mergeRefs([innerRef, forwardedRef])} {...props} />
})

export const NavRouteLink = forwardRef((props: NavLinkProps, forwardedRef) => {
  const innerRef = useChunksPreload(props.to)
  return <NavLink ref={mergeRefs([innerRef, forwardedRef])} {...props} />
})
