import { paths } from 'app/routes'

import { createConfigurationHint } from './create-configuration-hint'

export const PositionsConfigurationHint = createConfigurationHint({
  linkTo: paths.settingsConfiguration,
  scope: 'canViewCompanySettings',
  textWithAccess: 'hints.configuration.positions.with_access',
  textNoAccess: 'hints.configuration.positions.no_access',
})

export const SkillsConfigurationHint = createConfigurationHint({
  linkTo: paths.settingsSetOfSkills,
  scope: 'canViewCompanyConfiguration',
  textWithAccess: 'hints.configuration.skills.with_access',
  textNoAccess: 'hints.configuration.skills.no_access',
})

export const LanguagesConfigurationHint = createConfigurationHint({
  linkTo: paths.settingsJob,
  scope: 'canViewCompanySettings',
  textWithAccess: 'hints.configuration.languages.with_access',
  textNoAccess: 'hints.configuration.languages.no_access',
})

export const SourcesConfigurationHint = createConfigurationHint({
  linkTo: paths.settingsSources,
  scope: 'canViewCompanySettings',
  textWithAccess: 'hints.configuration.sources.with_access',
  textNoAccess: 'hints.configuration.sources.no_access',
})

export const TypesOfEmploymentConfigurationHint = createConfigurationHint({
  linkTo: paths.settingsJob,
  scope: 'canViewCompanySettings',
  textWithAccess: 'hints.configuration.types_of_employment.with_access',
  textNoAccess: 'hints.configuration.types_of_employment.no_access',
})

export const DepartmentsConfigurationHint = createConfigurationHint({
  linkTo: paths.settingsConfiguration,
  scope: 'canViewCompanySettings',
  textWithAccess: 'hints.configuration.departments.with_access',
  textNoAccess: 'hints.configuration.departments.no_access',
})

export const ProjectsConfigurationHint = createConfigurationHint({
  linkTo: paths.settingsConfiguration,
  scope: 'canViewCompanySettings',
  textWithAccess: 'hints.configuration.projects.with_access',
  textNoAccess: 'hints.configuration.projects.no_access',
})

export const JobPipelinesConfigurationHint = createConfigurationHint({
  linkTo: paths.settingsPipelineTemplates,
  scope: 'canManageCompanyConfiguration',
  textWithAccess: 'hints.configuration.job_pipelines.with_access',
  textNoAccess: 'hints.configuration.job_pipelines.no_access',
})

export const OfficesConfigurationHint = createConfigurationHint({
  linkTo: paths.settingsGeneral,
  scope: 'canManageCompany',
  textWithAccess: 'hints.configuration.offices.with_access',
  textNoAccess: 'hints.configuration.offices.no_access',
})

export const UsersConfigurationHint = createConfigurationHint({
  linkTo: paths.settingsUsersAndPermissions,
  scope: 'canManageUser',
  textWithAccess: 'hints.configuration.users.with_access',
  textNoAccess: 'hints.configuration.users.no_access',
})

export const WorkExperiencesConfigurationHint = createConfigurationHint({
  linkTo: paths.settingsJob,
  scope: 'canViewCompanySettings',
  textWithAccess: 'hints.configuration.work_experiences.with_access',
  textNoAccess: 'hints.configuration.work_experiences.no_access',
})
