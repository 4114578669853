import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

const TCustomFieldsCategory = t.union([t.literal('CONTACT'), t.literal('JOB')])
export type CustomFieldsCategory = t.TypeOf<typeof TCustomFieldsCategory>

const TCustomFieldType = t.union([
  t.literal('TEXT'),
  t.literal('NUMBER'),
  t.literal('DATE'),
  t.literal('SINGLE_CHOICE'),
])

export type CustomFieldType = t.TypeOf<typeof TCustomFieldType>

const TCustomFieldBase = t.intersection([
  t.strict({
    fieldId: t.string,
    fieldOrder: t.number,
    fieldName: t.string,
    categoryType: TCustomFieldsCategory,
    required: t.boolean,
  }),
  t.partial({
    valueDetails: t.array(t.string),
  }),
])

export const TCustomField = t.intersection([
  TCustomFieldBase,
  t.strict({
    fieldType: TCustomFieldType,
  }),
])

export type CustomField = t.TypeOf<typeof TCustomField>

export const TCustomFieldsList = t.array(TCustomField)
export type CustomFieldsList = Array<CustomField>

const buildCustomFieldWithValue = <V extends t.Mixed, T extends t.Mixed>(
  valueCodec: V,
  fieldType: T,
) => {
  return t.strict({
    fieldDetails: t.intersection([TCustomFieldBase, t.strict({ fieldType })]),
    fieldValue: valueCodec,
  })
}

const TStringCustomFieldValue = buildCustomFieldWithValue(
  t.string,
  t.union([t.literal('TEXT'), t.literal('NUMBER'), t.literal('SINGLE_CHOICE')]),
)

const TDateCustomFieldValue = buildCustomFieldWithValue(
  DateFromISOString,
  t.literal('DATE'),
)

export const TCustomFieldWithValue = t.union([
  TStringCustomFieldValue,
  TDateCustomFieldValue,
])

export type CustomFieldWithValue = t.TypeOf<typeof TCustomFieldWithValue>
