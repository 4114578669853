import { months } from 'lib/js-utils'
import { useTranslation } from 'react-i18next'

import { Autocomplete, AutocompleteProps } from './autocomplete'

type Props = Omit<AutocompleteProps, 'options'>

export const MonthPicker = (props: Props) => {
  const { t } = useTranslation()
  return (
    <Autocomplete
      {...props}
      disableAlphabeticalOrder
      options={months.map((month, index) => ({
        value: index.toString(),
        label: t(month).toString(),
      }))}
    />
  )
}
