import { Format12Hours, format12HoursRegex } from './is-format-12-hours'

export const format12HoursTimeToDate = (timeText: Format12Hours): Date => {
  const match = timeText.match(format12HoursRegex)

  if (!match) {
    throw new Error('Invalid time format')
  }

  let hours: number = Number.parseInt(match[1]!, 10)
  const minutes: number = Number.parseInt(match[2]!, 10)
  const meridiem: string = match[3]!.toLowerCase()

  if (hours === 12) {
    hours = meridiem === 'am' ? 0 : 12
  } else {
    hours += meridiem === 'pm' ? 12 : 0
  }

  const date: Date = new Date()
  date.setHours(hours, minutes, 0, 0)

  return date
}
