import { useAuthContext } from 'app/auth'
import { useCloseToastsOnRouteChange } from 'lib/toast'

import { AuthApp } from './auth'
import { PublicApp } from './public-app'
import { SubscriptionChecker } from './subscription-checker'
import { UnauthApp } from './unauth-app'

export const Root = () => {
  const { user } = useAuthContext()

  useCloseToastsOnRouteChange()

  if (user.state === 'initial') {
    return <PublicApp />
  }

  if (
    user.state === 'unauthenticated' ||
    (user.state === 'authenticated' && user.role === 'UNCONFIRMED_USER')
  ) {
    return <UnauthApp />
  }

  return (
    <SubscriptionChecker>
      <AuthApp />
    </SubscriptionChecker>
  )
}
