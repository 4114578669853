import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCoinOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      d="M6.875 11.8888C6.97037 13.0807 7.91137 14.0098 9.60262 14.1198V15H10.3529V14.1137C12.1013 13.9914 13.125 13.0562 13.125 11.6993C13.125 10.4645 12.343 9.82885 10.9442 9.49878L10.3529 9.35819V6.9621C11.1031 7.04768 11.58 7.45721 11.6944 8.02567H13.0106C12.9152 6.87653 11.9297 5.97799 10.3529 5.8802V5H9.60262V5.89853C8.10847 6.04523 7.09117 6.94377 7.09117 8.21516C7.09117 9.33985 7.84779 10.055 9.10669 10.3484L9.60262 10.4707V13.0134C8.83329 12.8973 8.32464 12.4756 8.2102 11.8888H6.875ZM9.59626 9.18093C8.85872 9.00978 8.45816 8.66137 8.45816 8.1357C8.45816 7.5489 8.89051 7.1088 9.60262 6.98044V9.18093H9.59626ZM10.461 10.6724C11.3575 10.8802 11.7707 11.2164 11.7707 11.8093C11.7707 12.4878 11.2557 12.9523 10.3529 13.0379V10.6479L10.461 10.6724Z"
      fill="currentColor"
    />
    <path
      d="M10 18.75C5.16751 18.75 1.25 14.8325 1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10C18.75 14.8325 14.8325 18.75 10 18.75ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="currentColor"
    />
    <path
      d="M10 16.875C6.20304 16.875 3.125 13.797 3.125 10C3.125 6.20304 6.20304 3.125 10 3.125C13.797 3.125 16.875 6.20304 16.875 10C16.875 13.797 13.797 16.875 10 16.875ZM10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
      fill="currentColor"
    />
  </SvgIcon>
)
