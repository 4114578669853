import { Box, styled, Typography } from '@mui/material'

export const FiltersGroup = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  color: theme.palette.text.secondary,
}))

export const FiltersGroupHead = ({
  title,
  icon,
}: {
  title: string
  icon?: React.ReactNode
}) => {
  return (
    <Box display="flex" alignItems="center">
      {icon}
      <Typography variant="h3" ml={1.5}>
        {title}
      </Typography>
    </Box>
  )
}
