import { autocompleteClasses, Popper, styled } from '@mui/material'
import { scrollBarListStyles } from 'app/mui-theme/theme'

export const AutocompletePopper = styled(Popper)(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    borderRadius: '6px',
    borderColor: theme.palette.primary.main,
    marginTop: '4px',
    marginBottom: '4px',

    boxShadow: '0px 0px 4px 0px #00000040 inset, 0px 1px 4px 0px #9A7EF0',

    '&:empty': {
      border: 'none',
    },
  },

  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    maxHeight: theme.spacing(24),
    marginX: '-2px',
    ...scrollBarListStyles,
  },
}))
