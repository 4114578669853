import { pipe } from 'fp-ts/lib/function'
import { decodeJson, del, get, post } from 'lib/request'
import { EmailSyncPeriod } from 'pages/settings/company-integrations/email-sync-dialog/email-sync-dialog'

import { TEmailIntegrationsList, TOAuth2Url } from './integrations.codecs'

export type TIntegrationType = 'EMAIL' | 'OUTLOOK'
export type TIntegrationProvider = 'GOOGLE'
export interface IEmailIntegrations {
  type: TIntegrationType
  provider?: TIntegrationProvider
}

export const getOAuth2Url = async ({
  provider = 'GOOGLE',
  type,
}: IEmailIntegrations) => {
  return pipe(
    await get('messaging/companies/me/integrations/oauth2-url', {
      query: new URLSearchParams({ type, provider }),
    }),
    decodeJson(TOAuth2Url),
  )
}

export const getEmailIntegration = async () => {
  return pipe(
    await get('messaging/companies/me/integrations', {}),
    decodeJson(TEmailIntegrationsList),
  )
}

export const createEmailIntegration = async (input: {
  providerType: TIntegrationProvider
  integrationType: TIntegrationType
  code: string
  syncPeriod?: EmailSyncPeriod
}) => {
  return await post('messaging/companies/me/integrations', {
    body: input,
  })
}

export const removeEmailIntegration = async (integrationId: string) => {
  return await del(`messaging/companies/me/integrations/:integrationId`, {
    params: { integrationId },
  })
}

export const restoreEmailIntegration = async ({
  integrationId,
  code,
}: {
  integrationId: string
  code: string
}) => {
  return await post(
    `messaging/companies/me/integrations/:integrationId/token`,
    {
      params: { integrationId },
      query: new URLSearchParams({ code }),
    },
  )
}
