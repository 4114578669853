import { Box, Stack, Typography } from '@mui/material'
import { SettingsCollection } from 'components/settings/edit-settings-collection-form'
import { useTranslation } from 'react-i18next'
import { Badge, BadgeCollection } from 'ui/data'

import { CollectionWrapper } from '../collection-wrapper'
import { JobPipelineTemplateDraft } from '../manual-settings-setup'
import { SectionId } from '../sections'
import { SummaryGroup } from './summary-group'

type Props = Readonly<{
  onEdit: (sectionId: SectionId) => void
  offices: SettingsCollection
  departments: SettingsCollection
  positions: SettingsCollection
  projects: SettingsCollection
  pipelineTemplates: Array<JobPipelineTemplateDraft>
}>

const retrieveValues = (collection: SettingsCollection) => {
  return collection.map(item => item.name)
}

export const SetupSummary = ({
  onEdit,
  offices,
  departments,
  positions,
  projects,
  pipelineTemplates,
}: Props) => {
  const { t } = useTranslation()

  return (
    <CollectionWrapper
      title={t('manual_settings_setup.summary.title')}
      subtitle={t('manual_settings_setup.summary.description')}
    >
      <Stack spacing={3}>
        <SummaryGroup
          groupName={t('manual_settings_setup.navigation.company')}
          onEdit={() => onEdit(SectionId.Offices)}
          sections={[
            { name: t('common.offices'), values: retrieveValues(offices) },
            {
              name: t('common.departments'),
              values: retrieveValues(departments),
            },
            { name: t('common.positions'), values: retrieveValues(positions) },
            { name: t('common.projects'), values: retrieveValues(projects) },
          ]}
        />

        <SummaryGroup
          groupName={t('manual_settings_setup.navigation.job_settings')}
          onEdit={() => onEdit(SectionId.PipelineTemplates)}
          sections={[
            {
              name: t('jobs.pipeline_templates'),
              content:
                pipelineTemplates.length > 0 ? (
                  <Stack spacing={3}>
                    {pipelineTemplates.map(template => (
                      <Box key={template.pipelineId}>
                        <Box display="flex" alignItems="center" mb={2}>
                          <Typography variant="body2" fontWeight={500} mr={2}>
                            {template.name}
                          </Typography>

                          {template.defaultPipeline && (
                            <Badge>{t('common.default')}</Badge>
                          )}
                        </Box>

                        <BadgeCollection>
                          {template.stages.map(stage => (
                            <Badge key={stage.name}>{stage.name}</Badge>
                          ))}
                        </BadgeCollection>
                      </Box>
                    ))}
                  </Stack>
                ) : (
                  <Typography color="text.secondary">
                    {t('manual_settings_setup.have_no_parameters')}
                  </Typography>
                ),
            },
          ]}
        />
      </Stack>
    </CollectionWrapper>
  )
}
