import { SvgIconProps } from '@mui/material'
import { CustomFieldType } from 'api'
import { IconCalendar, IconFont, IconList, IconNumber } from 'assets/icons'

export const customFieldType: Record<
  CustomFieldType,
  {
    order: number
    Icon: (props: SvgIconProps<'svg', {}>) => JSX.Element
  }
> = {
  TEXT: { order: 1, Icon: IconFont },
  NUMBER: { order: 2, Icon: IconNumber },
  DATE: { order: 3, Icon: IconCalendar },
  SINGLE_CHOICE: { order: 4, Icon: IconList },
}
