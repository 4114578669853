import { getCompanySkillsList, qk } from 'api'
import { useInfinitePaginatedQuery } from 'lib/react-query-utils'
import { useDebounceState } from 'lib/react-utils'
import { useEffect } from 'react'
import { Autocomplete, AutocompleteProps } from 'ui/inputs/autocomplete'

type Props = Omit<AutocompleteProps, 'options'> & {
  excludedSkills?: Array<string>
  positionId?: string
}

/** Autocomplete for active skills with debounce search */
export const SkillsAutocomplete = ({
  excludedSkills,
  positionId,
  ...props
}: Props) => {
  const [searchValue, setSearchValue] = useDebounceState('', 500)

  const $skills = useInfinitePaginatedQuery(
    qk.company.skills.list,
    getCompanySkillsList,
    {
      pagination: { page: 0, pageSize: 25 },
      filters: { active: true, name: searchValue, positionId },
    },
  )

  useEffect(() => {
    if (props.value === '') {
      setSearchValue('')
    }
  }, [props.value, setSearchValue])

  return (
    <Autocomplete
      {...props}
      disableInternalFilter
      onChange={props.onChange}
      loading={$skills.isLoading}
      loadingError={$skills.isError}
      onInputChange={value => {
        setSearchValue(value)
      }}
      hasMoreOptions={$skills.hasNextPage}
      onLoadMore={$skills.fetchNextPage}
      isLoadingMore={$skills.isFetchingNextPage}
      //helperText={<SkillsConfigurationHint variant="full" />}
      options={
        $skills.data?.pages
          .flatMap(page => page.rows)
          .filter(
            skill => !excludedSkills || !excludedSkills.includes(skill.skillId),
          )
          .map(skill => ({
            value: skill.skillId,
            label: skill.name,
          })) ?? []
      }
    />
  )
}
