import SvgIcon from '@mui/material/SvgIcon'

import { FilledIcon } from '../icon-props'

export const IconDashboard = ({ filled, ...props }: FilledIcon) =>
  filled ? (
    <SvgIcon {...props} viewBox="0 0 18 18" fontSize="inherit">
      <path
        d="M1.125 2.8125C1.125 2.36495 1.30279 1.93572 1.61926 1.61926C1.93572 1.30279 2.36495 1.125 2.8125 1.125H6.1875C6.63505 1.125 7.06428 1.30279 7.38074 1.61926C7.69721 1.93572 7.875 2.36495 7.875 2.8125V6.1875C7.875 6.63505 7.69721 7.06428 7.38074 7.38074C7.06428 7.69721 6.63505 7.875 6.1875 7.875H2.8125C2.36495 7.875 1.93572 7.69721 1.61926 7.38074C1.30279 7.06428 1.125 6.63505 1.125 6.1875V2.8125ZM10.125 2.8125C10.125 2.36495 10.3028 1.93572 10.6193 1.61926C10.9357 1.30279 11.3649 1.125 11.8125 1.125H15.1875C15.6351 1.125 16.0643 1.30279 16.3807 1.61926C16.6972 1.93572 16.875 2.36495 16.875 2.8125V6.1875C16.875 6.63505 16.6972 7.06428 16.3807 7.38074C16.0643 7.69721 15.6351 7.875 15.1875 7.875H11.8125C11.3649 7.875 10.9357 7.69721 10.6193 7.38074C10.3028 7.06428 10.125 6.63505 10.125 6.1875V2.8125ZM1.125 11.8125C1.125 11.3649 1.30279 10.9357 1.61926 10.6193C1.93572 10.3028 2.36495 10.125 2.8125 10.125H6.1875C6.63505 10.125 7.06428 10.3028 7.38074 10.6193C7.69721 10.9357 7.875 11.3649 7.875 11.8125V15.1875C7.875 15.6351 7.69721 16.0643 7.38074 16.3807C7.06428 16.6972 6.63505 16.875 6.1875 16.875H2.8125C2.36495 16.875 1.93572 16.6972 1.61926 16.3807C1.30279 16.0643 1.125 15.6351 1.125 15.1875V11.8125ZM10.125 11.8125C10.125 11.3649 10.3028 10.9357 10.6193 10.6193C10.9357 10.3028 11.3649 10.125 11.8125 10.125H15.1875C15.6351 10.125 16.0643 10.3028 16.3807 10.6193C16.6972 10.9357 16.875 11.3649 16.875 11.8125V15.1875C16.875 15.6351 16.6972 16.0643 16.3807 16.3807C16.0643 16.6972 15.6351 16.875 15.1875 16.875H11.8125C11.3649 16.875 10.9357 16.6972 10.6193 16.3807C10.3028 16.0643 10.125 15.6351 10.125 15.1875V11.8125Z"
        fill="currentColor"
      />
    </SvgIcon>
  ) : (
    <SvgIcon {...props} viewBox="0 0 18 18" fontSize="inherit">
      <path
        d="M1.125 2.8125C1.125 1.88052 1.88052 1.125 2.8125 1.125L6.1875 1.125C7.11948 1.125 7.875 1.88052 7.875 2.8125V6.1875C7.875 7.11948 7.11948 7.875 6.1875 7.875H2.8125C1.88052 7.875 1.125 7.11948 1.125 6.1875L1.125 2.8125ZM2.8125 2.25C2.50184 2.25 2.25 2.50184 2.25 2.8125V6.1875C2.25 6.49816 2.50184 6.75 2.8125 6.75H6.1875C6.49816 6.75 6.75 6.49816 6.75 6.1875V2.8125C6.75 2.50184 6.49816 2.25 6.1875 2.25H2.8125ZM10.125 2.8125C10.125 1.88052 10.8805 1.125 11.8125 1.125L15.1875 1.125C16.1195 1.125 16.875 1.88052 16.875 2.8125V6.1875C16.875 7.11948 16.1195 7.875 15.1875 7.875H11.8125C10.8805 7.875 10.125 7.11948 10.125 6.1875V2.8125ZM11.8125 2.25C11.5018 2.25 11.25 2.50184 11.25 2.8125V6.1875C11.25 6.49816 11.5018 6.75 11.8125 6.75H15.1875C15.4982 6.75 15.75 6.49816 15.75 6.1875V2.8125C15.75 2.50184 15.4982 2.25 15.1875 2.25H11.8125ZM1.125 11.8125C1.125 10.8805 1.88052 10.125 2.8125 10.125H6.1875C7.11948 10.125 7.875 10.8805 7.875 11.8125V15.1875C7.875 16.1195 7.11948 16.875 6.1875 16.875H2.8125C1.88052 16.875 1.125 16.1195 1.125 15.1875L1.125 11.8125ZM2.8125 11.25C2.50184 11.25 2.25 11.5018 2.25 11.8125V15.1875C2.25 15.4982 2.50184 15.75 2.8125 15.75H6.1875C6.49816 15.75 6.75 15.4982 6.75 15.1875V11.8125C6.75 11.5018 6.49816 11.25 6.1875 11.25H2.8125ZM10.125 11.8125C10.125 10.8805 10.8805 10.125 11.8125 10.125H15.1875C16.1195 10.125 16.875 10.8805 16.875 11.8125V15.1875C16.875 16.1195 16.1195 16.875 15.1875 16.875H11.8125C10.8805 16.875 10.125 16.1195 10.125 15.1875V11.8125ZM11.8125 11.25C11.5018 11.25 11.25 11.5018 11.25 11.8125V15.1875C11.25 15.4982 11.5018 15.75 11.8125 15.75H15.1875C15.4982 15.75 15.75 15.4982 15.75 15.1875V11.8125C15.75 11.5018 15.4982 11.25 15.1875 11.25H11.8125Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
