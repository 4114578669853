import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

const TPaymentCurrency = t.literal('EUR')

export const TCompanyCharge = t.intersection([
  t.strict({
    subscriptionChargeId: t.string,
    subscriptionName: t.string,
    price: t.number,
    currency: TPaymentCurrency,
    seats: t.number,
    createdAt: DateFromISOString,
    paid: t.boolean,
  }),
  t.partial({
    errorCode: t.string,
    paymentMethodDigits: t.string,
    paymentMethodBrand: t.string,
  }),
])
