import { Box, Paper } from '@mui/material'
import { Toolbar } from 'ui/surfaces'

type Props = {
  left: React.ReactNode
  right: React.ReactNode
}

export const DetailsTemplate = (props: Props) => {
  return (
    <Paper sx={{ border: 0, borderRadius: 0 }}>
      <Toolbar noBorder transparent>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: 10,
          }}
        >
          <Box
            sx={{
              width: '70%',
              py: 5.25,
              position: 'relative',
            }}
          >
            {props.left}
          </Box>

          <Box py={5.25} width="30%">
            {props.right}
          </Box>
        </Box>
      </Toolbar>
    </Paper>
  )
}
