import { Box, Stack, Typography } from '@mui/material'
import logoImage from 'assets/images/logo.svg'
import { useTranslation } from 'react-i18next'

export const AxteriorLogoLight = () => {
  const { t } = useTranslation()

  return (
    <Stack spacing={1.5} direction="row" alignItems="center">
      <Box
        component="img"
        src={logoImage}
        alt="logo"
        display="block"
        width={32}
      />

      <Typography
        sx={{
          fontFamily: 'Gilroy',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '24.5px',
          letterSpacing: '0.52px',
          color: '#FFFFFF',
        }}
      >
        {t('common.axterior')}
      </Typography>
    </Stack>
  )
}

export const AxteriorLogoDark = () => {
  const { t } = useTranslation()

  return (
    <Stack spacing={1.5} direction="row" alignItems="center">
      <Box
        component="img"
        src={logoImage}
        alt="logo"
        display="block"
        width={32}
      />

      <Typography variant="h2">{t('common.axterior')}</Typography>
    </Stack>
  )
}
