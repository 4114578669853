export const formatNumberMinutesToStringTime = (minutes: number): string => {
  if (minutes <= 0) return `0m`

  const hours: number = Math.floor(minutes / 60)

  if (hours === 0) return `${minutes}m`

  const remainingMinutes: number = minutes % 60

  if (remainingMinutes === 0) return `${hours}h`

  return `${hours}h ${remainingMinutes}m`
}
