import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconEyeOpenOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 21">
    <path
      d="M2.01677 11.0944C1.90328 10.9147 1.84654 10.8248 1.81477 10.6863C1.79091 10.5822 1.79091 10.418 1.81477 10.3139C1.84654 10.1753 1.90328 10.0855 2.01677 9.90577C2.95461 8.42078 5.74617 4.66675 10.0003 4.66675C14.2545 4.66675 17.0461 8.42078 17.9839 9.90577C18.0974 10.0855 18.1541 10.1753 18.1859 10.3139C18.2098 10.418 18.2098 10.5822 18.1859 10.6863C18.1541 10.8248 18.0974 10.9147 17.9839 11.0944C17.0461 12.5794 14.2545 16.3334 10.0003 16.3334C5.74617 16.3334 2.95461 12.5794 2.01677 11.0944Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
    <path
      d="M10.0003 13.0001C11.381 13.0001 12.5003 11.8808 12.5003 10.5001C12.5003 9.11937 11.381 8.00008 10.0003 8.00008C8.61962 8.00008 7.50034 9.11937 7.50034 10.5001C7.50034 11.8808 8.61962 13.0001 10.0003 13.0001Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
  </SvgIcon>
)
