export const placeholders = [
  {
    key: '[recipient_name]',
    description: "The recipient's full name",
  },
  {
    key: '[recipient_email]',
    description: "The recipient's email",
  },
  {
    key: '[email_sender_name]',
    description: 'The full name of the team member who is sending the email',
  },
  {
    key: '[email_sender_address]',
    description: 'The email of the team member who is sending the email',
  },
  {
    key: '[company_name]',
    description: 'The company name',
  },
  {
    key: '[position]',
    description: "Recipient's position title",
  },
  {
    key: '[scheduling_link]',
    description: 'Scheduling link of user who opens the Invitation form',
  },
] as const

export type Placeholder = typeof placeholders[number]

export type DataSources = {
  '[recipient_name]': string
  '[recipient_email]': string
  '[position]': string
  '[email_sender_name]': string
  '[email_sender_address]': string
  '[company_name]': string
  '[scheduling_link]': string
}

const replacePlaceholders = (text: string, data: DataSources) => {
  return placeholders.reduce((currentText, placeholder) => {
    const value = data[placeholder.key]
    if (value) {
      return currentText.split(placeholder.key).join(value)
    }
    return currentText
  }, text)
}

export const replaceFieldsWithPlaceholders = (
  fields: string[],
  data: DataSources,
) => {
  return fields.map(text => replacePlaceholders(text, data))
}
