import { Box, Typography } from '@mui/material'
import { IconLocation } from 'assets/icons'
import { DetailsItemContainer } from 'components/templates/details-template'
import { useFormatLocation } from 'lib/app-helpers'
import { useTranslation } from 'react-i18next'

type Props = Readonly<{
  countryCode?: string
  city?: string
}>

export const ContactLocationSection = ({ countryCode, city }: Props) => {
  const { t } = useTranslation()

  const formatLocation = useFormatLocation()
  const location = formatLocation(countryCode, city)

  if (location.length === 0) return null

  return (
    <DetailsItemContainer title={t('common.location')}>
      <Box display="flex" alignItems="center">
        <IconLocation sx={{ color: 'greyBlue.light' }} />
        <Typography variant="body2" ml={1} color="text.secondary">
          {location}
        </Typography>
      </Box>
    </DetailsItemContainer>
  )
}
