import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconWhatsapp = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M12 0C5.3736 0 0 5.3736 0 12C0 18.6264 5.3736 24 12 24C18.6264 24 24 18.6264 24 12C24 5.3736 18.6264 0 12 0ZM12.2543 18.9868C12.2542 18.9868 12.2545 18.9868 12.2543 18.9868H12.2514C11.0493 18.9862 9.8681 18.6848 8.81909 18.1128L5.01178 19.1113L6.03076 15.3906C5.40216 14.3018 5.07147 13.0666 5.07202 11.8011C5.07367 7.84241 8.29559 4.62177 12.2543 4.62177C14.1755 4.6225 15.9789 5.3703 17.3348 6.72729C18.6909 8.08447 19.4372 9.88843 19.4365 11.8068C19.4348 15.7657 16.2125 18.9868 12.2543 18.9868Z"
      fill="#008D6E"
    />
    <path
      d="M12.2568 5.83447C8.96405 5.83447 6.28613 8.51129 6.28467 11.8017C6.2843 12.9293 6.59998 14.0273 7.19745 14.9775L7.33936 15.2032L6.73621 17.4055L8.99555 16.8129L9.21363 16.9422C10.1301 17.486 11.1807 17.7737 12.2519 17.774H12.2543C15.5445 17.774 18.2224 15.097 18.2239 11.8065C18.2244 10.2118 17.6041 8.71252 16.4771 7.58459C15.35 6.45667 13.8511 5.83502 12.2568 5.83447ZM15.7681 14.3674C15.6185 14.7863 14.9016 15.1688 14.5568 15.2205C14.2476 15.2666 13.8565 15.2858 13.4265 15.1494C13.1658 15.0667 12.8316 14.9562 12.4033 14.7715C10.6032 13.9944 9.42749 12.1824 9.33777 12.0626C9.24805 11.9429 8.60498 11.0898 8.60498 10.2067C8.60498 9.32373 9.06861 8.88959 9.23304 8.71014C9.39765 8.53052 9.59211 8.48566 9.71167 8.48566C9.83124 8.48566 9.95099 8.48676 10.0555 8.49188C10.1658 8.49738 10.3137 8.44995 10.4593 8.79987C10.6089 9.15912 10.9678 10.0421 11.0126 10.1318C11.0575 10.2217 11.0873 10.3264 11.0277 10.4462C10.9678 10.5659 10.7689 10.8243 10.579 11.0598C10.4994 11.1584 10.3956 11.2463 10.5003 11.426C10.6049 11.6054 10.9652 12.193 11.4986 12.6687C12.1842 13.2799 12.7624 13.4692 12.9418 13.5591C13.1211 13.6489 13.2258 13.6339 13.3306 13.5143C13.4351 13.3945 13.7792 12.9904 13.8988 12.8108C14.0183 12.6312 14.1381 12.6612 14.3025 12.7211C14.4671 12.7808 15.3493 13.2149 15.5288 13.3046C15.7082 13.3945 15.8278 13.4394 15.8726 13.5143C15.9177 13.5892 15.9177 13.9482 15.7681 14.3674Z"
      fill="#008D6E"
    />
  </SvgIcon>
)
