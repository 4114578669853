import { Box, Collapse, Paper, Typography } from '@mui/material'
import { paths } from 'app/routes'
import { Trans } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import { Link } from 'ui/navigation'

type Props = {
  count: number
  onCheckAllNotifications: () => void
}

export const NotificationsFeedMoreCount = ({
  count,
  onCheckAllNotifications,
}: Props) => {
  return (
    <Collapse
      in={count > 0}
      timeout={{ enter: 0, exit: 600 }}
      style={{ ...(count <= 0 && { transitionDelay: '600ms' }) }}
    >
      <CSSTransition
        in={count > 0}
        timeout={600}
        classNames="notification-toast"
      >
        <Paper
          variant="elevation"
          sx={{ width: 300, p: theme => theme.spacing(1.25, 2, 1.75, 2.5) }}
        >
          <Typography color="text.primary" mb={0.75}>
            <Trans
              i18nKey="common.more_unread_notifications"
              values={{ moreCount: count }}
              components={{
                highlight: <Box component="span" color="primary.main" />,
              }}
            />
          </Typography>

          <Typography variant="caption" color="text.secondary">
            <Trans
              i18nKey="common.check_all_notifications"
              components={{
                linkElement: (
                  <Link
                    to={paths.notifications}
                    color="blue.main"
                    onClick={onCheckAllNotifications}
                  />
                ),
              }}
            />
          </Typography>
        </Paper>
      </CSSTransition>
    </Collapse>
  )
}
