import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconLevelLow = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 14">
    <path
      d="M3.91082 5.57559C3.96479 5.52152 4.02889 5.47861 4.09946 5.44934C4.17002 5.42007 4.24567 5.405 4.32207 5.405C4.39847 5.405 4.47412 5.42007 4.54469 5.44934C4.61525 5.47861 4.67935 5.52152 4.73332 5.57559L6.99665 7.83309L9.26582 5.56976C9.31983 5.51575 9.38394 5.47291 9.4545 5.44369C9.52507 5.41446 9.6007 5.39941 9.67707 5.39941C9.75345 5.39941 9.82908 5.41446 9.89964 5.44369C9.9702 5.47291 10.0343 5.51575 10.0883 5.56976C10.1423 5.62377 10.1852 5.68788 10.2144 5.75844C10.2436 5.829 10.2587 5.90463 10.2587 5.98101C10.2587 6.05739 10.2436 6.13301 10.2144 6.20358C10.1852 6.27414 10.1423 6.33825 10.0883 6.39226L7.41082 9.06976C7.18332 9.29726 6.81582 9.29726 6.58832 9.06976L3.91082 6.39226C3.85678 6.33887 3.81388 6.27529 3.78459 6.20519C3.75531 6.1351 3.74023 6.05989 3.74023 5.98393C3.74023 5.90796 3.75531 5.83275 3.78459 5.76266C3.81388 5.69256 3.85678 5.62898 3.91082 5.57559Z"
      fill="currentColor"
    />
  </SvgIcon>
)
