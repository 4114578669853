import { alpha, ClickAwayListener, Drawer } from '@mui/material'
type Props = {
  isOpened: boolean
  onClose: () => void
  children: React.ReactNode
  /** disable closing preview on click away */
  clickAwayDisabled?: boolean
}

export const previewCloseExceptionClass = 'preview-close-exception'

export const PreviewDrawer = ({
  isOpened,
  onClose,
  children,
  clickAwayDisabled,
}: Props) => {
  return (
    <ClickAwayListener
      onClickAway={event => {
        if (!isOpened || clickAwayDisabled) return

        const eventPath = event.composedPath()

        if (
          !eventPath.some(element => {
            if (element instanceof Element) {
              return element.classList.contains(previewCloseExceptionClass)
            }

            return false
          })
        ) {
          onClose()
        }
      }}
    >
      <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            width: '373px',
            boxShadow: `2px 5px 8px ${alpha('#000000', 0.15)}`,
          },
        }}
        anchor="right"
        variant="persistent"
        ModalProps={{
          BackdropProps: { invisible: true },
          disableScrollLock: true,
        }}
        onClose={onClose}
        open={isOpened}
      >
        {children}
      </Drawer>
    </ClickAwayListener>
  )
}
