import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconFile06 = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 18 22"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M11 1.26953V5.40007C11 5.96012 11 6.24015 11.109 6.45406C11.2049 6.64222 11.3578 6.7952 11.546 6.89108C11.7599 7.00007 12.0399 7.00007 12.6 7.00007H16.7305M13 12H5M13 16H5M7 8H5M11 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21H12.2C13.8802 21 14.7202 21 15.362 20.673C15.9265 20.3854 16.3854 19.9265 16.673 19.362C17 18.7202 17 17.8802 17 16.2V7L11 1Z"
      stroke="currentColor"
      strokeWidth={props.stroke ?? '1.4'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
