import SvgIcon from '@mui/material/SvgIcon'

import { FilledIcon } from '../icon-props'

export const IconWorkExperience = ({ filled, ...props }: FilledIcon) =>
  filled ? (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_3173_23736)">
        <path
          d="M6.5 1C6.10218 1 5.72064 1.15804 5.43934 1.43934C5.15804 1.72064 5 2.10218 5 2.5V3H1.5C1.10218 3 0.720644 3.15804 0.43934 3.43934C0.158035 3.72064 0 4.10218 0 4.5L0 5.884L7.614 7.914C7.86693 7.98135 8.13307 7.98135 8.386 7.914L16 5.884V4.5C16 4.10218 15.842 3.72064 15.5607 3.43934C15.2794 3.15804 14.8978 3 14.5 3H11V2.5C11 2.10218 10.842 1.72064 10.5607 1.43934C10.2794 1.15804 9.89782 1 9.5 1H6.5ZM6.5 2H9.5C9.63261 2 9.75979 2.05268 9.85355 2.14645C9.94732 2.24021 10 2.36739 10 2.5V3H6V2.5C6 2.36739 6.05268 2.24021 6.14645 2.14645C6.24021 2.05268 6.36739 2 6.5 2Z"
          fill="currentColor"
        />
        <path
          d="M0 12.4996C0 12.8974 0.158035 13.279 0.43934 13.5603C0.720644 13.8416 1.10218 13.9996 1.5 13.9996H14.5C14.8978 13.9996 15.2794 13.8416 15.5607 13.5603C15.842 13.279 16 12.8974 16 12.4996V6.84961L8.129 8.94661C8.04448 8.96918 7.95552 8.96918 7.871 8.94661L0 6.84961V12.4996Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3173_23736">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  ) : (
    <SvgIcon {...props}>
      <path
        d="M9.75 1.5C9.15326 1.5 8.58097 1.73705 8.15901 2.15901C7.73705 2.58097 7.5 3.15326 7.5 3.75V4.5H2.25C1.65326 4.5 1.08097 4.73705 0.65901 5.15901C0.237053 5.58097 0 6.15326 0 6.75L0 18.75C0 19.3467 0.237053 19.919 0.65901 20.341C1.08097 20.7629 1.65326 21 2.25 21H21.75C22.3467 21 22.919 20.7629 23.341 20.341C23.7629 19.919 24 19.3467 24 18.75V6.75C24 6.15326 23.7629 5.58097 23.341 5.15901C22.919 4.73705 22.3467 4.5 21.75 4.5H16.5V3.75C16.5 3.15326 16.2629 2.58097 15.841 2.15901C15.419 1.73705 14.8467 1.5 14.25 1.5H9.75ZM9.75 3H14.25C14.4489 3 14.6397 3.07902 14.7803 3.21967C14.921 3.36032 15 3.55109 15 3.75V4.5H9V3.75C9 3.55109 9.07902 3.36032 9.21967 3.21967C9.36032 3.07902 9.55109 3 9.75 3ZM12.579 13.371L22.5 10.7265V18.75C22.5 18.9489 22.421 19.1397 22.2803 19.2803C22.1397 19.421 21.9489 19.5 21.75 19.5H2.25C2.05109 19.5 1.86032 19.421 1.71967 19.2803C1.57902 19.1397 1.5 18.9489 1.5 18.75V10.725L11.421 13.371C11.8004 13.472 12.1996 13.472 12.579 13.371ZM2.25 6H21.75C21.9489 6 22.1397 6.07902 22.2803 6.21967C22.421 6.36032 22.5 6.55109 22.5 6.75V9.174L12.1935 11.922C12.0667 11.9559 11.9333 11.9559 11.8065 11.922L1.5 9.174V6.75C1.5 6.55109 1.57902 6.36032 1.71967 6.21967C1.86032 6.07902 2.05109 6 2.25 6Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
