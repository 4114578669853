import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconArrowRepeat = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      d="M12.9755 7.875H17.3996C17.638 7.875 17.7683 8.15312 17.6156 8.3363L15.4036 10.9907C15.2912 11.1257 15.0839 11.1257 14.9715 10.9907L12.7594 8.3363C12.6068 8.15312 12.7371 7.875 12.9755 7.875Z"
      fill="currentColor"
    />
    <path
      d="M0.600513 10.125H5.02455C5.26301 10.125 5.39327 9.84688 5.24061 9.6637L3.02859 7.00927C2.91615 6.87434 2.70891 6.87434 2.59647 7.00927L0.384451 9.6637C0.231796 9.84688 0.362059 10.125 0.600513 10.125Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00003 3.375C7.25348 3.375 5.69317 4.17034 4.66055 5.42069C4.46273 5.66022 4.10819 5.69404 3.86865 5.49621C3.62912 5.29839 3.5953 4.94385 3.79313 4.70431C5.03029 3.20628 6.90373 2.25 9.00003 2.25C12.3094 2.25 15.0616 4.63107 15.6388 7.77338C15.645 7.80717 15.6509 7.84104 15.6566 7.875H14.5126C13.9917 5.3075 11.721 3.375 9.00003 3.375ZM3.48744 10.125C4.00833 12.6925 6.27908 14.625 9.00003 14.625C10.7466 14.625 12.3069 13.8297 13.3395 12.5793C13.5373 12.3398 13.8919 12.306 14.1314 12.5038C14.3709 12.7016 14.4048 13.0562 14.2069 13.2957C12.9698 14.7937 11.0963 15.75 9.00003 15.75C5.69062 15.75 2.93851 13.3689 2.36131 10.2266C2.35511 10.1928 2.34915 10.159 2.34345 10.125H3.48744Z"
      fill="currentColor"
    />
  </SvgIcon>
)
