import { Box, Link, Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { ContactInformationType, getMyProfile, qk } from 'api'
import { IconPlusCircle } from 'assets/icons'
import { IconPencil } from 'assets/icons'
import { Fieldset } from 'components/common'
import { findContactMessengerIcon } from 'components/contacts'
import { toArray } from 'fp-ts/Record'
import { useBoolean } from 'lib/react-utils'
import { contactInformationTypes } from 'lib/records'
import { useState } from 'react'
import { Control, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'ui/inputs/icon-button'

import { FormValues } from '../_values'
import { CreateContactInformationDialog } from './create-dialog'
import { EditContactInformationDialog } from './edit-dialog'

type Props = {
  control: Control<FormValues>
  country: string
}

export const ContactInformation = ({ control, country }: Props) => {
  const { t } = useTranslation()
  const $profile = useQuery(qk.auth.users.myProfile.toKey(), getMyProfile)

  const { fields, append, replace } = useFieldArray({
    control,
    name: 'contactInfos',
  })

  const isCreateDialogOpen = useBoolean()

  const [typeToEdit, setTypeToEdit] = useState<ContactInformationType | null>(
    null,
  )

  return (
    <Fieldset
      legend={t('contacts.contact_information')}
      hint={t('contacts.contact_information_block.subtitle')}
      action={
        <IconButton onClick={isCreateDialogOpen.setTrue}>
          <IconPlusCircle
            sx={{ fontSize: '20px', color: 'mediumPurple.main' }}
          />
        </IconButton>
      }
    >
      <Box>
        {toArray(contactInformationTypes).map(([value, contactInfo]) => {
          const typeFields = fields.filter(field => field.type === value)

          if (typeFields.length === 0) return null

          const isMessenger = value === 'MESSENGER'
          const InfoIcon = contactInfo.Icon

          return (
            <Box
              key={value}
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'flex-start',
                pb: 2,
                mb: 2.5,
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <Box width={150} display="flex" alignItems="center">
                <InfoIcon sx={{ fontSize: '16px', color: 'greyBlue.light' }} />
                <Typography variant="body2" fontWeight={500} ml={1}>
                  {t(`contacts.contact_information_types.${value}`)}
                </Typography>
              </Box>

              <Stack spacing={isMessenger ? 2 : 1}>
                {typeFields.map(field => {
                  let label: React.ReactNode = field.label

                  if (isMessenger) {
                    const Icon = findContactMessengerIcon(field.label)
                    if (Icon) {
                      label = <Icon />
                    }
                  }

                  return (
                    <Typography
                      key={field.id}
                      variant="body2"
                      color="text.secondary"
                      display="flex"
                      alignItems="center"
                    >
                      {label}
                      {!isMessenger ? ':' : ''}
                      <Box component="span" color="blue.main" ml={0.75}>
                        {field.type === 'EMAIL' || field.type === 'PHONE' ? (
                          <Link
                            color="inherit"
                            underline="none"
                            href={`${
                              field.type === 'EMAIL' ? 'mailto:' : 'tel:'
                            }:${field.value}`}
                          >
                            {field.value}
                          </Link>
                        ) : (
                          field.value
                        )}
                      </Box>
                    </Typography>
                  )
                })}
              </Stack>

              <IconButton
                color="greyBlue"
                sx={{ position: 'absolute', top: -8, right: 0 }}
                onClick={() => {
                  setTypeToEdit(value)
                }}
              >
                <IconPencil sx={{ fontSize: '16px' }} />
              </IconButton>
            </Box>
          )
        })}

        {$profile.data && (
          <CreateContactInformationDialog
            defaultCountry={
              country !== '' ? country : $profile.data.countryCode
            }
            isOpen={isCreateDialogOpen.isTrue}
            onClose={isCreateDialogOpen.setFalse}
            onSubmit={append}
          />
        )}

        {typeToEdit !== null && $profile.data && (
          <EditContactInformationDialog
            defaultCountry={
              country !== '' ? country : $profile.data.countryCode
            }
            isOpen
            onClose={() => setTypeToEdit(null)}
            type={typeToEdit}
            onSubmit={contactInfos => {
              replace([
                ...fields.filter(field => field.type !== typeToEdit),
                ...contactInfos.map(info => ({ ...info, type: typeToEdit })),
              ])
            }}
            initialValues={fields
              .filter(field => field.type === typeToEdit)
              .map(typeField => ({
                value: typeField.value,
                label: typeField.label,
              }))}
          />
        )}
      </Box>
    </Fieldset>
  )
}
