import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { GoBackIconButton, InfoHint } from 'components/common'
import { ReactNode } from 'react'
import { Breadcrumbs, BreadcrumbsLocations } from 'ui/navigation/breadcrumbs'

import { Toolbar } from '.'

export type Props = {
  title?: string
  icon?: React.ReactNode
  breadcrumbs?: BreadcrumbsLocations
  buttons?: React.ReactNode
  isLoading?: boolean
  sticky?: boolean
  transparent?: boolean
  withBackButton?: boolean
  hint?: string
  children?: ReactNode
}

export const CommonToolbar = ({
  title,
  icon,
  breadcrumbs,
  buttons,
  hint,
  isLoading,
  sticky,
  withBackButton = false,
  transparent,
  children,
}: Props) => {
  return (
    <Toolbar sticky={sticky} transparent={transparent}>
      <Box display="flex" flexDirection="column" flex={1}>
        <Box
          width="100%"
          py={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box mr={3}>
            {breadcrumbs && (
              <Box mb={1.25}>
                <Breadcrumbs locations={breadcrumbs} />
              </Box>
            )}

            <Stack direction="row" spacing={1} alignItems="center">
              {withBackButton && (
                <GoBackIconButton buttonProps={{ variant: 'text' }} />
              )}

              {icon}
              <Typography variant="h2">
                {isLoading ? <Skeleton variant="text" width={200} /> : title}
              </Typography>
              {hint && <InfoHint hint={hint} />}
            </Stack>
          </Box>

          {buttons && (
            <Stack direction="row" spacing={2}>
              {buttons}
            </Stack>
          )}
        </Box>

        {children && (
          <Box
            width="100%"
            pb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {children}
          </Box>
        )}
      </Box>
    </Toolbar>
  )
}
