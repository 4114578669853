import { Typography } from '@mui/material'
import { formatDuration, intervalToDuration, startOfDay } from 'date-fns'
import { formatMonthYear } from 'lib/js-utils'
import { useTranslation } from 'react-i18next'

import { CircleDivider } from './circle-divider'

type Props = Readonly<{
  startDate: Date
  endDate?: Date
}>

export const ExperienceDurationWithDates = ({ startDate, endDate }: Props) => {
  const { t } = useTranslation()

  return (
    <CircleDivider>
      <Typography variant="caption" color="text.secondary">
        {formatMonthYear(startDate)}
        {` - `}
        {endDate ? formatMonthYear(endDate) : t('common.present')}
      </Typography>

      <Typography variant="caption" color="text.secondary">
        {formatDuration(
          intervalToDuration({
            start: startOfDay(startDate),
            end: startOfDay(endDate ?? new Date()),
          }),
          { format: ['years', 'months'] },
        )}
      </Typography>
    </CircleDivider>
  )
}
