import { Grid } from '@mui/material'
import { CustomFieldsList } from 'api'
import { Fieldset } from 'components/common'
import { renderCustomField } from 'lib/app-helpers'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { labels } from './_labels'
import { FormValues } from './_values'

type Props = Readonly<{
  control: Control<FormValues>
  customFields: CustomFieldsList
}>

export const ContactCustomFields = ({ control, customFields }: Props) => {
  const { t } = useTranslation()

  return (
    <Fieldset legend={t(labels.customFields)}>
      <Grid container spacing={3}>
        {customFields.map(customField => (
          <Grid key={customField.fieldId} item xs={6}>
            <Controller
              name={`customFields.${customField.fieldName}`}
              control={control}
              render={renderCustomField(customField)}
            />
          </Grid>
        ))}
      </Grid>
    </Fieldset>
  )
}
