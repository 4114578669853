import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconArrowDropdown = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 14L15.5 10H8.5L12 14Z"
      fill="currentColor"
    />
  </SvgIcon>
)
