import { Box, SxProps, Theme, Tooltip } from '@mui/material'
import { JobStatusCode } from 'api'
import {
  IconArrowDownCircleOutline,
  IconCheckCircleOutlineDashed,
  IconCheckCircleOutlineLarge,
  IconCircleOutlineLarge,
} from 'assets/icons'
import { useTranslation } from 'react-i18next'

type JobStatusProps = Readonly<{
  jobStatusCode: JobStatusCode
  iconSx?: SxProps<Theme>
  containerSx?: SxProps<Theme>
}>

export const JobStatus = ({
  jobStatusCode,
  iconSx,
  containerSx,
}: JobStatusProps) => {
  const { t } = useTranslation()

  return (
    <Tooltip arrow title={t(`jobs.statuses.${jobStatusCode}`)} placement="top">
      <Box sx={{ display: 'flex', ...containerSx }}>
        <JobStatusIcon jobStatusCode={jobStatusCode} iconSx={iconSx} />
      </Box>
    </Tooltip>
  )
}

type JobStatusIconProps = Readonly<{
  jobStatusCode: JobStatusCode
  iconSx?: SxProps<Theme>
}>

export const JobStatusIcon = ({
  jobStatusCode,
  iconSx,
}: JobStatusIconProps) => {
  switch (jobStatusCode) {
    case 'OPEN': {
      return (
        <IconCheckCircleOutlineDashed
          sx={{ color: 'success.main', fontSize: '20px', ...iconSx }}
        />
      )
    }
    case 'DRAFT': {
      return (
        <IconCircleOutlineLarge
          sx={{ color: '#FEA800', fontSize: '20px', ...iconSx }}
        />
      )
    }
    case 'CLOSED': {
      return (
        <IconCheckCircleOutlineLarge
          sx={{ color: 'blue.main', fontSize: '20px', ...iconSx }}
        />
      )
    }
    case 'ARCHIVED': {
      return (
        <IconArrowDownCircleOutline
          sx={{ color: 'greyBlue.light', fontSize: '20px', ...iconSx }}
        />
      )
    }
    default: {
      return null
    }
  }
}
