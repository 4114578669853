import { Box } from '@mui/material'
import { WorkPermit } from 'api'
import { IconPencil } from 'assets/icons'
import { useBoolean } from 'lib/react-utils'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'ui/inputs/icon-button'

import { WorkPermitFormDialog } from './work-permits-form-dialog'

type Props = Readonly<{
  workPermit: WorkPermit
  onEdit: (workPermit: WorkPermit) => void
}>

export const EditWorkPermitIconButton = ({ workPermit, onEdit }: Props) => {
  const { t } = useTranslation()
  const showEditWorkPermitsDialog = useBoolean()

  return (
    <Box>
      <IconButton color="primary" onClick={showEditWorkPermitsDialog.setTrue}>
        <IconPencil sx={{ fontSize: '16px' }} />
      </IconButton>

      <WorkPermitFormDialog
        isOpen={showEditWorkPermitsDialog.isTrue}
        onClose={showEditWorkPermitsDialog.setFalse}
        dialogContentProps={{
          title: t('common.edit_work_permit'),
          confirmText: t('common.save'),
        }}
        initialValues={workPermit}
        onSubmit={onEdit}
      />
    </Box>
  )
}
