import { ContactId } from 'api/contacts'
import { pipe } from 'fp-ts/function'
import { decodeJson, patch, post, put } from 'lib/request'

import { JobId } from '../jobs.api'
import { TJobContactsList } from './job-pipeline.codecs'

export type GetJobPipelineContactsListInput = JobId

export const getJobPipelineContactsList = async ({
  jobId,
}: GetJobPipelineContactsListInput) => {
  return pipe(
    await post('api/candidates/jobs/:jobId/list', {
      params: { jobId },
    }),
    decodeJson(TJobContactsList),
  )
}

export const applyContactsToJob = async ({
  jobId,
  contactIds,
}: JobId & {
  contactIds: Array<string>
}) => {
  return await post('api/jobs/:jobId/applied_contacts', {
    params: { jobId },
    body: { contactIds },
  })
}

export const applyContactToJobs = async ({
  jobIds,
  contactId,
}: ContactId & {
  jobIds: Array<string>
}) => {
  return await post('api/jobs/applied_contacts', {
    body: { contactId, jobIds },
  })
}

export type MoveContactOnJobPipelineInput = ContactId &
  JobId & {
    stageId: string
  }

export const moveContactOnJobPipeline = async ({
  contactId,
  jobId,
  stageId,
}: MoveContactOnJobPipelineInput) => {
  return await patch(
    'api/jobs/:jobId/applied_contacts/:contactId/job_pipeline_stage',
    {
      params: { contactId, jobId },
      body: { jobPipelineStageId: stageId },
    },
  )
}

export type RejectContactOnJobPipelineInput = ContactId &
  JobId & {
    message: string
    rejectReasonId: string
  }

export const rejectContactOnJobPipeline = async ({
  contactId,
  jobId,
  message,
  rejectReasonId,
}: RejectContactOnJobPipelineInput) => {
  return await post('api/jobs/:jobId/applied_contacts/:contactId/reject', {
    params: { contactId, jobId },
    body: { message, rejectReasonId },
  })
}

export const restoreContactOnJobPipeline = async ({
  contactId,
  jobId,
}: ContactId & JobId) => {
  return await post('api/jobs/:jobId/applied_contacts/:contactId/restore', {
    params: { contactId, jobId },
  })
}

export const hireContactForJob = async ({
  contactId,
  jobId,
}: ContactId & JobId) => {
  return await put('api/jobs/:jobId/candidates/:contactId/hire', {
    params: { jobId, contactId },
  })
}
