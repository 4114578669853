import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Stack, Typography } from '@mui/material'
import { ValidationErrors } from 'components/common'
import { EmailBodyTextEditor } from 'components/email/email-form/email-body-text-editor'
import { useConfirmationDialog } from 'lib/app-helpers'
import { Placeholder } from 'lib/records'
import { showToast } from 'lib/toast'
import {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useRef,
  useState,
} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import { Dialog } from 'ui/feedback'
import { Button } from 'ui/inputs/button'
import { renderTextField } from 'ui/inputs/text-field'
import { z } from 'zod'

import { AddPlaceholderButton } from './add-placeholder-button'

const buildSchema = (t: TFunction) => {
  return z.object({
    name: z
      .string()
      .min(1)
      .min(
        2,
        t('validations.enter_value_between_symbols', {
          min: 2,
          max: 100,
        }),
      )
      .max(
        100,
        t('validations.enter_value_between_symbols', {
          min: 2,
          max: 100,
        }),
      )
      .refine(data => /^\D*$/.test(data), {
        message: t('validations.please_enter_correct_name'),
      }),
    emailSubject: z.string().min(1),
    emailBody: z.string(),
  })
}

type FormValues = {
  name: string
  emailSubject: string
  emailBody: string
}

type ActiveField = 'emailSubject' | 'emailBody'

type Props = Readonly<{
  isOpen: boolean
  onClose: () => void
  onConfirm: (props: { values: FormValues; resetForm: () => void }) => void
  title: string
  confirmCancelingText: string
  defaultValues: FormValues
}>

export const EmailTemplateFormDialog = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  confirmCancelingText,
  defaultValues,
}: Props) => {
  const { t } = useTranslation()

  // make emailSubject field active by default if user did not focus any field yet
  const [activeField, setActiveField] = useState<ActiveField>('emailSubject')
  const [cursorPosition, setCursorPosition] = useState<number>(0)

  const quillRef = useRef<ReactQuill | null>(null)

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitSuccessful },
    getValues,
    setValue,
  } = useForm<FormValues>({
    resolver: zodResolver(buildSchema(t)),
    defaultValues,
  })

  const confirmClosing = useConfirmationDialog<void>(() => {
    onClose()
    reset()
  })

  const closeTemplateCreatingDialog = useCallback(() => {
    if (isDirty && !isSubmitSuccessful) {
      confirmClosing.openDialog()
    } else {
      onClose()
    }
  }, [confirmClosing, isDirty, isSubmitSuccessful, onClose])

  const onSubmit = () => {
    handleSubmit(
      values => onConfirm({ values, resetForm: reset }),
      errors => {
        const required = ['name', 'emailSubject']

        showToast(
          {
            type: 'error',
            title: t('toasts.form_not_completed'),
            body: (
              <ValidationErrors
                errors={errors}
                translations={{
                  name: 'common.name',
                  emailSubject: 'common.subject',
                }}
                required={required}
              />
            ),
          },
          { autoClose: false },
        )
      },
    )()
  }

  const handleClick = (event: MouseEvent<HTMLInputElement>) => {
    const position = (event.target as HTMLInputElement).selectionStart
    setActiveField('emailSubject')
    setCursorPosition(position ?? 0)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCursorPosition(event.target.selectionStart ?? 0)
  }

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      const position = event.currentTarget.selectionStart
      setCursorPosition(position ?? 0)
    }
  }

  const handleEmailBodyChange = (range: ReactQuill.Range | null) => {
    setActiveField('emailBody')
    if (range !== null) {
      setCursorPosition(range.index)
    }
  }

  const insertPlaceholder = (placeholder: string) => {
    const editor = quillRef.current?.getEditor()

    if (editor && activeField === 'emailBody') {
      editor.insertText(cursorPosition, placeholder)
      const updatedValue = getValues('emailBody')
      setValue(activeField, updatedValue)
    } else if (activeField === 'emailSubject') {
      const currentValue = getValues('emailSubject')

      const updatedValue = `${currentValue.slice(
        0,
        cursorPosition,
      )}${placeholder}${currentValue.slice(cursorPosition)}`
      setValue(activeField, updatedValue)
    }
  }

  return (
    <>
      <Dialog open={isOpen} onClose={closeTemplateCreatingDialog}>
        <Box
          component="form"
          noValidate
          onSubmit={event => {
            event.preventDefault()
            onSubmit()
          }}
          sx={{
            flex: 1,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box flex={1} overflow="auto">
            <Box px={3} py={2.5}>
              <Typography variant="h2">{title}</Typography>
            </Box>

            <Stack p={3} pt={0} gap={3}>
              <Controller
                control={control}
                rules={{ required: true }}
                name="name"
                render={renderTextField({
                  label: t('common.name'),
                  required: true,
                })}
              />

              <Controller
                control={control}
                rules={{ required: true }}
                name="emailSubject"
                render={renderTextField({
                  label: t('common.subject'),
                  required: true,
                  inputProps: {
                    onClick: handleClick,
                    onChange: handleChange,
                    onKeyUp: handleKeyUp,
                  },
                })}
              />

              <Controller
                control={control}
                name="emailBody"
                render={({ field }) => (
                  <EmailBodyTextEditor
                    ref={quillRef}
                    value={field.value}
                    onChange={field.onChange}
                    label={t('common.body')}
                    isToolbarHidden={false}
                    onSelectionChange={handleEmailBodyChange}
                  />
                )}
              />
            </Stack>
          </Box>

          <Box
            sx={{
              bgcolor: '#FFFFFF',
              px: 3,
              py: 2.5,
              display: 'flex',
              borderTop: 1,
              borderColor: 'divider',
            }}
          >
            <Button
              variant="outlined"
              color="greyBlue"
              onClick={closeTemplateCreatingDialog}
            >
              {t('common.cancel')}
            </Button>

            <Stack ml="auto" gap={2} direction="row">
              <AddPlaceholderButton
                onSelect={(placeholder: Placeholder) => {
                  insertPlaceholder(placeholder.key)
                }}
              />

              <Button type="submit" color="primary">
                {t('common.save')}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Dialog>

      {confirmClosing.renderConfirmDialog({
        size: 'small',
        title: t('common.cancel'),
        variant: 'danger',
        children: confirmCancelingText,
      })}
    </>
  )
}
