import { Box, Tooltip, TooltipProps } from '@mui/material'
import { IconInfoCircleOutline } from 'assets/icons'

type Props = Readonly<{
  hint: string
  iconSize?: string
  placement?: TooltipProps['placement']
  alignSelf?: 'center' | 'start' | 'end'
}>

export const InfoHint = ({
  hint,
  iconSize = '12px',
  alignSelf = 'start',
  placement = 'bottom',
}: Props) => {
  return (
    <Tooltip title={hint} placement={placement}>
      <Box display="flex" ml={0.5} alignSelf={alignSelf}>
        <IconInfoCircleOutline
          sx={{ fontSize: iconSize, color: 'greyBlue.light' }}
        />
      </Box>
    </Tooltip>
  )
}
