import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconLevelsHighest = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 14"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.89981 7.29114C9.97038 7.26186 10.0345 7.21896 10.0884 7.16488C10.1425 7.11149 10.1854 7.04791 10.2147 6.97782C10.244 6.90772 10.259 6.83251 10.259 6.75655C10.259 6.68059 10.244 6.60538 10.2147 6.53528C10.1854 6.46519 10.1425 6.4016 10.0884 6.34822L7.41095 3.67072C7.18345 3.44322 6.81595 3.44322 6.58845 3.67072L3.91095 6.34822C3.85694 6.40222 3.8141 6.46634 3.78487 6.5369C3.75564 6.60746 3.7406 6.68309 3.7406 6.75947C3.7406 6.83584 3.75564 6.91147 3.78487 6.98203C3.8141 7.0526 3.85694 7.11671 3.91095 7.17072C3.96495 7.22472 4.02907 7.26756 4.09963 7.29679C4.17019 7.32602 4.24582 7.34106 4.3222 7.34106C4.39857 7.34106 4.4742 7.32602 4.54476 7.29679C4.61533 7.26756 4.67944 7.22472 4.73345 7.17072L7.00261 4.90738L9.26595 7.16488C9.31991 7.21896 9.38401 7.26186 9.45458 7.29114C9.52515 7.32041 9.6008 7.33548 9.6772 7.33548C9.75359 7.33548 9.82924 7.32041 9.89981 7.29114ZM9.89981 10.4932C9.97038 10.4639 10.0345 10.421 10.0884 10.3669C10.1425 10.3135 10.1854 10.25 10.2147 10.1799C10.244 10.1098 10.259 10.0346 10.259 9.9586C10.259 9.88264 10.244 9.80743 10.2147 9.73734C10.1854 9.66724 10.1425 9.60366 10.0884 9.55027L7.41095 6.87277C7.18345 6.64527 6.81595 6.64527 6.58845 6.87277L3.91095 9.55027C3.85694 9.60428 3.8141 9.66839 3.78487 9.73895C3.75564 9.80951 3.7406 9.88514 3.7406 9.96152C3.7406 10.0379 3.75564 10.1135 3.78487 10.1841C3.8141 10.2546 3.85694 10.3188 3.91095 10.3728C3.96495 10.4268 4.02907 10.4696 4.09963 10.4988C4.17019 10.5281 4.24582 10.5431 4.3222 10.5431C4.39857 10.5431 4.4742 10.5281 4.54476 10.4988C4.61533 10.4696 4.67944 10.4268 4.73345 10.3728L7.00261 8.10944L9.26595 10.3669C9.31991 10.421 9.38401 10.4639 9.45458 10.4932C9.52515 10.5225 9.6008 10.5375 9.6772 10.5375C9.75359 10.5375 9.82924 10.5225 9.89981 10.4932Z"
      fill="currentColor"
    />
  </SvgIcon>
)
