import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconPhoneOutline = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip0_2767_19419)">
      <path
        d="M5.48122 1.9922C5.39267 1.87829 5.28089 1.78453 5.15332 1.71714C5.02575 1.64974 4.8853 1.61027 4.7413 1.60133C4.59729 1.59239 4.45304 1.6142 4.31811 1.6653C4.18319 1.71639 4.06067 1.79561 3.95872 1.8977L2.40772 3.4502C1.68322 4.1762 1.41622 5.2037 1.73272 6.1052C3.04633 9.83658 5.18316 13.2243 7.98472 16.0172C10.7776 18.8187 14.1654 20.9555 17.8967 22.2692C18.7982 22.5857 19.8257 22.3187 20.5517 21.5942L22.1027 20.0432C22.2048 19.9412 22.284 19.8187 22.3351 19.6838C22.3862 19.5489 22.408 19.4046 22.3991 19.2606C22.3901 19.1166 22.3507 18.9762 22.2833 18.8486C22.2159 18.721 22.1221 18.6092 22.0082 18.5207L18.5477 15.8297C18.426 15.7353 18.2845 15.6698 18.1338 15.6381C17.9831 15.6064 17.8271 15.6093 17.6777 15.6467L14.3927 16.4672C13.9542 16.5768 13.4949 16.571 13.0593 16.4503C12.6237 16.3297 12.2268 16.0983 11.9072 15.7787L8.22322 12.0932C7.90337 11.7738 7.67171 11.3769 7.55079 10.9413C7.42987 10.5058 7.42381 10.0463 7.53322 9.6077L8.35522 6.3227C8.39258 6.17329 8.39552 6.01735 8.36382 5.86664C8.33212 5.71593 8.2666 5.57439 8.17222 5.4527L5.48122 1.9922ZM2.82622 0.766698C3.08871 0.504123 3.40406 0.300394 3.75133 0.16904C4.09859 0.0376865 4.46983 -0.0182865 4.84039 0.00483791C5.21095 0.0279623 5.57235 0.129655 5.90059 0.303163C6.22883 0.476672 6.51641 0.718026 6.74422 1.0112L9.43522 4.4702C9.92872 5.1047 10.1027 5.9312 9.90772 6.7112L9.08722 9.9962C9.0448 10.1663 9.04709 10.3446 9.09387 10.5136C9.14065 10.6826 9.23034 10.8366 9.35422 10.9607L13.0397 14.6462C13.164 14.7703 13.3183 14.8602 13.4875 14.907C13.6568 14.9537 13.8354 14.9559 14.0057 14.9132L17.2892 14.0927C17.6741 13.9965 18.0759 13.989 18.4641 14.0708C18.8524 14.1527 19.2169 14.3217 19.5302 14.5652L22.9892 17.2562C24.2327 18.2237 24.3467 20.0612 23.2337 21.1727L21.6827 22.7237C20.5727 23.8337 18.9137 24.3212 17.3672 23.7767C13.409 22.384 9.81513 20.118 6.85222 17.1467C3.88115 14.1842 1.61513 10.5909 0.222218 6.6332C-0.320782 5.0882 0.166718 3.4277 1.27672 2.3177L2.82772 0.766698H2.82622Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2767_19419">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
