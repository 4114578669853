import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconLevelLowest = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 14">
    <path
      d="M10.0884 5.81729C10.0345 5.76321 9.97038 5.72031 9.89981 5.69104C9.82924 5.66177 9.75359 5.6467 9.6772 5.6467C9.6008 5.6467 9.52515 5.66177 9.45458 5.69104C9.38401 5.72031 9.31991 5.76321 9.26595 5.81729L7.00261 8.07479L4.73345 5.81146C4.67944 5.75745 4.61533 5.71461 4.54476 5.68538C4.4742 5.65616 4.39857 5.64111 4.3222 5.64111C4.24582 5.64111 4.17019 5.65616 4.09963 5.68538C4.02907 5.71461 3.96495 5.75745 3.91095 5.81146C3.85694 5.86546 3.8141 5.92958 3.78487 6.00014C3.75564 6.0707 3.7406 6.14633 3.7406 6.22271C3.7406 6.29908 3.75564 6.37471 3.78487 6.44528C3.8141 6.51584 3.85694 6.57995 3.91095 6.63396L6.58845 9.31146C6.81595 9.53896 7.18345 9.53896 7.41095 9.31146L10.0884 6.63396C10.1425 6.58057 10.1854 6.51699 10.2147 6.44689C10.244 6.3768 10.259 6.30159 10.259 6.22562C10.259 6.14966 10.244 6.07445 10.2147 6.00436C10.1854 5.93426 10.1425 5.87068 10.0884 5.81729Z"
      fill="currentColor"
    />
    <path
      d="M10.0884 2.61514C10.0345 2.56107 9.97038 2.51816 9.89981 2.48889C9.82924 2.45962 9.75359 2.44455 9.6772 2.44455C9.6008 2.44455 9.52515 2.45962 9.45458 2.48889C9.38401 2.51816 9.31991 2.56107 9.26595 2.61514L7.00261 4.87264L4.73345 2.60931C4.67944 2.5553 4.61533 2.51246 4.54476 2.48324C4.4742 2.45401 4.39857 2.43896 4.3222 2.43896C4.24582 2.43896 4.17019 2.45401 4.09963 2.48324C4.02907 2.51246 3.96495 2.5553 3.91095 2.60931C3.85694 2.66332 3.8141 2.72743 3.78487 2.79799C3.75564 2.86856 3.7406 2.94418 3.7406 3.02056C3.7406 3.09694 3.75564 3.17256 3.78487 3.24313C3.8141 3.31369 3.85694 3.3778 3.91095 3.43181L6.58845 6.10931C6.81595 6.33681 7.18345 6.33681 7.41095 6.10931L10.0884 3.43181C10.1425 3.37842 10.1854 3.31484 10.2147 3.24474C10.244 3.17465 10.259 3.09944 10.259 3.02348C10.259 2.94751 10.244 2.8723 10.2147 2.80221C10.1854 2.73212 10.1425 2.66853 10.0884 2.61514Z"
      fill="currentColor"
    />
    <path
      d="M10.0884 8.89298C10.0344 8.8389 9.97028 8.796 9.89971 8.76672C9.82915 8.73745 9.7535 8.72238 9.6771 8.72238C9.6007 8.72238 9.52505 8.73745 9.45449 8.76672C9.38392 8.796 9.31982 8.8389 9.26585 8.89298L7.00252 11.1505L4.73918 8.88714C4.63011 8.77807 4.48218 8.7168 4.32793 8.7168C4.17368 8.7168 4.02575 8.77807 3.91668 8.88714C3.80761 8.99621 3.74634 9.14414 3.74634 9.29839C3.74634 9.45264 3.80761 9.60057 3.91668 9.70964L6.59418 12.3871C6.82168 12.6146 7.18918 12.6146 7.41668 12.3871L10.0942 9.70964C10.2013 9.60035 10.2608 9.45312 10.2597 9.30011C10.2586 9.14709 10.197 9.00073 10.0884 8.89298Z"
      fill="currentColor"
    />
  </SvgIcon>
)
