import { useBoolean } from 'lib/react-utils'
import { useCallback, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentProps,
  DialogSize,
} from 'ui/feedback'

export type ConfirmDialogProps = Omit<
  DialogContentProps,
  'onConfirm' | 'onDeny'
> & {
  size?: DialogSize
  width?: string
}

/**
 * Custom hook to manage a confirmation dialog with optional callback on confirmation.
 *
 * @template T - The type of parameters to be passed to the callback function.
 * @param {(params: T) => void} callback - Callback function to be executed on confirmation.
 * @returns {Object} - Object containing methods and properties for confirmation dialog management.
 */
export const useConfirmationDialog = <T extends any>(
  callback?: (params: T) => void,
) => {
  const visibility = useBoolean(false) // State for dialog visibility
  const [params, setParams] = useState<T | null>(null) // State to store parameters for callback

  /**
   * Handler function for confirming the dialog. Executes the callback with the stored parameters (if available).
   * Closes the dialog and resets the parameters.
   */
  const handleConfirm = useCallback(() => {
    if (callback && params !== null) {
      callback(params)
    }

    setParams(null)
    visibility.setFalse()
  }, [callback, visibility, params])

  /**
   * Handler function for denying or closing the dialog. Resets the parameters and closes the dialog.
   */
  const handleDeny = useCallback(() => {
    setParams(null)
    visibility.setFalse()
  }, [visibility])

  /**
   * Handler function to open the confirmation dialog with specified parameters.
   * @param {T} params - Parameters to be passed to the callback function.
   */
  const handleOpen = useCallback(
    (params: T) => {
      setParams(params)
      visibility.setTrue()
    },
    [visibility],
  )

  /**
   * Function to render the confirmation dialog.
   * @param {ConfirmDialogProps} contentProps - Properties for customizing the dialog content.
   * @returns {JSX.Element} - JSX element representing the confirmation dialog.
   */
  const renderConfirmDialog = useCallback(
    ({ size, width, ...contentProps }: ConfirmDialogProps) => (
      <Dialog
        open={visibility.isTrue}
        onClose={handleDeny}
        size={size}
        width={width}
      >
        <DialogContent
          {...contentProps}
          onDeny={handleDeny}
          onConfirm={handleConfirm}
        />
      </Dialog>
    ),
    [handleConfirm, handleDeny, visibility.isTrue],
  )

  return {
    params,
    openDialog: handleOpen,
    renderConfirmDialog,
  }
}
