import { ComponentType } from 'react'

/** A function to retry loading a chunk to avoid chunk load error for out of date code */
export const importWithRetry = <C extends { default: ComponentType<any> }>(
  componentImport: () => Promise<C>,
): Promise<C> => {
  return new Promise((resolve, reject) => {
    const refreshedKey = 'page-has-been-force-refreshed'
    const pageHasBeenForceRefreshed =
      window.sessionStorage.getItem(refreshedKey) === 'true'

    componentImport()
      .then(component => {
        window.sessionStorage.setItem(refreshedKey, 'false')
        resolve(component)
      })
      .catch(error => {
        if (!pageHasBeenForceRefreshed) {
          window.sessionStorage.setItem(refreshedKey, 'true')
          return window.location.reload()
        }

        reject(error)
      })
  })
}
