import { Box, Stack } from '@mui/material'
import { WorkPermit } from 'api'
import { Fieldset } from 'components/common'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormValues } from '../_values'
import { AddWorkPermitButton } from './add-work-permit-button'
import { WorkPermitsList } from './work-permits-list'

type Props = Readonly<{
  control: Control<FormValues>
}>

export const WorkPermitsFieldset = ({ control }: Props) => {
  const { t } = useTranslation()

  return (
    <Controller
      name="workPermits"
      control={control}
      render={({ field: workPermitsField }) => {
        const sortedWorkPermits = [...workPermitsField.value].sort(
          (a: WorkPermit, b: WorkPermit) =>
            new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
        )

        return (
          <Fieldset
            legend={t('common.work_permits')}
            hint={t('hints.add_work_permit')}
            action={
              sortedWorkPermits.length < 15 && (
                <AddWorkPermitButton
                  onAdd={newWorkPermit => {
                    workPermitsField.onChange([
                      ...sortedWorkPermits,
                      newWorkPermit,
                    ])
                  }}
                />
              )
            }
          >
            <Box>
              <Stack spacing={2}>
                <WorkPermitsList
                  workPermits={sortedWorkPermits}
                  onEdit={(updatedWorkPermit, index) =>
                    workPermitsField.onChange([
                      ...sortedWorkPermits.slice(0, index),
                      updatedWorkPermit,
                      ...sortedWorkPermits.slice(index + 1),
                    ])
                  }
                  onRemove={index =>
                    workPermitsField.onChange([
                      ...sortedWorkPermits.slice(0, index),
                      ...sortedWorkPermits.slice(index + 1),
                    ])
                  }
                />
              </Stack>
            </Box>
          </Fieldset>
        )
      }}
    />
  )
}
