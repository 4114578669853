import { createListSearchParams, ListPagination } from 'api/list-params'
import { pipe } from 'fp-ts/function'
import { decodeJson, decodeJsonWithTotal, del, get, post } from 'lib/request'

import {
  TFilterHistoryRow,
  TFiltersName,
  TSavedFilterRow,
} from './filters.codecs'

export type GetHistoryFiltersInput = ListPagination

export const getHistoryFilters = async (input: GetHistoryFiltersInput) => {
  const params = createListSearchParams({ pagination: input })

  return pipe(
    await get('api/filter_history', { query: params }),
    decodeJsonWithTotal(TFilterHistoryRow, input),
  )
}

export const deleteHistoryFilters = async () => {
  return await del('api/filter_history', {})
}

export const deleteHistoryFilter = async ({
  historyFilterId,
}: {
  historyFilterId: string
}) => {
  return await del('api/filter_history/:historyFilterId', {
    params: {
      historyFilterId,
    },
  })
}

export type GetSavedFiltersInput = ListPagination

export const getSavedFilters = async (input: GetSavedFiltersInput) => {
  const params = createListSearchParams({ pagination: input })

  return pipe(
    await get('api/filters', { query: params }),
    decodeJsonWithTotal(TSavedFilterRow, input),
  )
}

export const saveFiltersFromHistory = async ({
  name,
  historyId,
}: {
  name: string
  historyId: string
}) => {
  return await post('api/filters/history', {
    body: {
      filterName: name,
      filterHistoryId: historyId,
    },
  })
}

export const deleteSavedFilter = async ({
  savedFilterId,
}: {
  savedFilterId: string
}) => {
  return await del('api/filters/:savedFilterId', {
    params: {
      savedFilterId,
    },
  })
}

export type SaveFiltersValues = {
  levels: Array<string>
  skills: Array<string>
  sources: Array<string>
  talentPools: Array<string>
  positions: Array<string>
  location: {
    country: string
    city: string
  }
  from: Date | null
  to: Date | null
}

const adjustFilterValues = (values: SaveFiltersValues) => ({
  ...values,
  location:
    values.location.country || values.location.city
      ? {
          country: values.location.country || undefined,
          city: values.location.city || undefined,
        }
      : undefined,
})

export const saveFilters = async (
  input: {
    name: string
  } & SaveFiltersValues,
) => {
  return await post('api/filters', { body: adjustFilterValues(input) })
}

export const generateFiltersName = async (input: SaveFiltersValues) => {
  return pipe(
    await post('api/filters/name', { body: adjustFilterValues(input) }),
    decodeJson(TFiltersName),
  )
}
