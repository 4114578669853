import { TLanguage, TProfile, TSkill, TSkillLevelCode } from 'api/common'
import {
  TCompanyPosition,
  TCompanySource,
  TCustomFieldWithValue,
  TJobPipelineStage,
  TPeriodTypes,
} from 'api/company'
import { TJobStatusCode } from 'api/jobs/jobs.codecs'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export const TContactLink = t.strict({
  link: t.string,
  favicon: t.string,
})

export type ContactLink = t.TypeOf<typeof TContactLink>

export const TContactDocument = t.intersection([
  t.strict({
    fileId: t.string,
    name: t.string,
  }),
  t.partial({
    createdAt: DateFromISOString,
  }),
])

export type ContactDocument = t.TypeOf<typeof TContactDocument>

export const TContactHiringExperience = t.array(
  t.intersection([
    t.strict({
      jobId: t.string,
      jobName: t.string,
      jobPipelineStageName: t.string,
      jobStatus: TJobStatusCode,
      applyDate: DateFromISOString,
      applyStatus: t.union([
        t.literal('APPLIED'),
        t.literal('HIRED'),
        t.literal('REJECTED'),
      ]),
    }),
    t.partial({
      // rejected reason and restored can be missing when job becomes archived
      rejectReason: t.string,
      restored: t.boolean,
    }),
  ]),
)

export type ContactHiringExperience = t.TypeOf<typeof TContactHiringExperience>

export const TContactAppliedJob = t.intersection([
  t.strict({
    jobId: t.string,
    jobName: t.string,
    jobPipelineStage: TJobPipelineStage,
    appliedAt: DateFromISOString,
    jobStatus: TJobStatusCode,
  }),
  t.partial({
    hiredAt: DateFromISOString,
  }),
])

export type ContactAppliedJob = t.TypeOf<typeof TContactAppliedJob>

export const TContactAppliedJobs = t.array(TContactAppliedJob)

export type ContactAppliedJobs = t.TypeOf<typeof TContactAppliedJobs>

export const TContactInformationType = t.union([
  t.literal('PHONE'),
  t.literal('EMAIL'),
  t.literal('MESSENGER'),
])

export type ContactInformationType = t.TypeOf<typeof TContactInformationType>

export const TContactInformation = t.strict({
  type: TContactInformationType,
  label: t.string,
  value: t.string,
})

export type ContactInformation = t.TypeOf<typeof TContactInformation>

const TContactEducation = t.intersection([
  t.strict({
    name: t.string,
  }),
  t.partial({
    startDate: DateFromISOString,
    endDate: DateFromISOString,
    relatedToPosition: t.boolean,
    degree: t.string,
    fieldOfStudy: t.string,
  }),
])

export type ContactEducation = t.TypeOf<typeof TContactEducation>

const TWorkPermit = t.strict({
  country: t.strict({
    code: t.string,
  }),
  startDate: DateFromISOString,
  expiredDate: DateFromISOString,
})

const TWorkPermits = t.array(TWorkPermit)

export type ContactWorkPermits = t.TypeOf<typeof TWorkPermits>

export const TContactDetails = t.intersection([
  t.strict({
    name: t.string,
    candidateProfileId: t.string,
    position: TCompanyPosition,
    contactInfos: t.array(TContactInformation),
    totalJobsCount: t.number,
    createdBy: TProfile,
  }),
  t.partial({
    birthDate: DateFromISOString,
    note: t.string,
    documents: t.array(TContactDocument),
    educations: t.array(TContactEducation),
    source: TCompanySource,
    languages: t.array(TLanguage),
    address: t.partial({
      country: t.strict({ code: t.string }),
      city: t.string,
    }),
    skills: t.array(TSkill),
    linkResponses: t.array(TContactLink),
    qualificationLevel: t.strict({
      code: t.string,
      name: t.string,
    }),
    updatedAt: DateFromISOString,
    imageUri: t.string,
    imageId: t.string,
    customFields: t.array(TCustomFieldWithValue),
    workPermits: TWorkPermits,
  }),
])

export type ContactDetails = t.TypeOf<typeof TContactDetails>

export const TCreateContact = t.strict({
  candidateProfileId: t.string,
})

export const TContactRowSkill = t.strict({
  skillId: t.string,
  skillName: t.string,
  levelCode: TSkillLevelCode,
  lastUsed: t.number,
})

export type ContactRowSkill = t.TypeOf<typeof TContactRowSkill>

const TContactMatchingStatus = t.union([
  t.literal('MISMATCH'),
  t.literal('ALMOST_MATCH'),
  t.literal('MATCH'),
  t.literal('BEST_MATCH'),
])

export type ContactMatchingStatus = t.TypeOf<typeof TContactMatchingStatus>

export const TContactRow = t.intersection([
  t.strict({
    candidateProfileId: t.string,
    name: t.string,
    position: TCompanyPosition,
    createdAt: DateFromISOString,
    createdBy: t.strict({
      profileId: t.string,
      fullName: t.string,
    }),
  }),
  t.partial({
    updatedAt: DateFromISOString,
    imageUri: t.string,
    skills: t.array(TContactRowSkill),
    address: t.partial({
      country: t.strict({ code: t.string }),
      city: t.string,
    }),
    contactMatchingRate: t.strict({
      contactMatchingStatus: TContactMatchingStatus,
      contactMatchingScore: t.number,
    }),
  }),
])

export type ContactRow = t.TypeOf<typeof TContactRow>

export const TAmountOfAddedContactsStatistic = t.intersection([
  t.strict({
    contactInfluxPeriods: t.array(
      t.strict({
        periodStart: DateFromISOString,
        periodEnd: DateFromISOString,
        amount: t.number,
      }),
    ),
    periodType: TPeriodTypes,
  }),
  t.partial({
    contactAdditionGoal: t.number,
  }),
])

export type AmountOfAddedContactsStatistic = t.TypeOf<
  typeof TAmountOfAddedContactsStatistic
>

export const TContactCompletenessStatuses = t.union([
  t.literal('INCOMPLETE'),
  t.literal('MINIMAL'),
  t.literal('ACCEPTABLE'),
  t.literal('COMPLETE'),
])

export type ContactCompletenessStatuses = t.TypeOf<
  typeof TContactCompletenessStatuses
>

export const TContactCompleteness = t.strict({
  completenessStatus: TContactCompletenessStatuses,
  completenessPercentage: t.number,
  notFilledFields: t.array(t.string),
})

export const TContactHistoryTypes = t.union([
  t.literal('CONTACT_ACTIVITY_STATUS_CHANGED'),
  t.literal('CONTACT_ACTIVITY_CHANGED'),
  t.literal('CONTACT_ACTIVITY_CREATED'),
  t.literal('CONTACT_CREATED'),
  t.literal('CONTACT_REMOVED'),
  t.literal('CONTACT_UPDATED'),
  t.literal('CONTACT_DOCUMENT_ADDED'),
  t.literal('CONTACT_DOCUMENT_REMOVED'),
  t.literal('CONTACT_ADDED_TALENT_POOL'),
  t.literal('CONTACT_REMOVED_TALENT_POOL'),
  t.literal('CONTACT_APPLIED_JOB'),
  t.literal('CONTACT_MOVED_JOB'),
  t.literal('CONTACT_REJECTED_JOB'),
  t.literal('CONTACT_RESTORED_JOB'),
  t.literal('CONTACT_HIRED_JOB'),
  t.literal('CONTACT_INVITATION_CREATED'),
  t.literal('CONTACT_CREATED_FROM_PUBLIC_JOB'),
  t.literal('CONTACT_DOCUMENT_ADDED_FROM_PUBLIC_JOB'),
  t.literal('CONTACT_APPLIED_PUBLIC_JOB'),
  t.literal('CONTACT_ACTIVITY_CREATED_MANUALLY'),
  t.literal('CONTACT_ACTIVITY_CREATED_AUTOMATICALLY'),
  t.literal('CONTACT_JOB_ARCHIVED'),
])

export type ContactHistoryTypes = t.TypeOf<typeof TContactHistoryTypes>

export const TContactProfileLog = t.strict({
  id: t.string,
  entityId: t.string,
  type: TContactHistoryTypes,
  subject: t.string,
  message: t.string,
  createdAt: DateFromISOString,
})

const TContactWorkExperienceItem = t.intersection([
  t.strict({
    startDate: DateFromISOString,
    companyName: t.string,
    jobExperience: t.array(
      t.intersection([
        t.strict({
          position: t.intersection([
            t.strict({
              name: t.string,
            }),
            t.partial({
              positionId: t.string,
            }),
          ]),
          donorCompany: t.intersection([
            t.strict({
              name: t.string,
            }),
            t.partial({
              donorId: t.string,
            }),
          ]),
          startDate: DateFromISOString,
          currentEmployment: t.boolean,
        }),
        t.partial({
          endDate: DateFromISOString,
          description: t.string,
          level: t.string,
          address: t.partial({
            country: t.strict({ code: t.string }),
            city: t.string,
          }),
          jobType: t.strict({
            jobTypeId: t.string,
            name: t.string,
          }),
        }),
      ]),
    ),
  }),
  t.partial({
    endDate: DateFromISOString,
    jobTypeName: t.string,
  }),
])

export type ContactWorkExperienceItem = t.TypeOf<
  typeof TContactWorkExperienceItem
>

export const TContactWorkExperience = t.array(TContactWorkExperienceItem)

export type ContactWorkExperience = t.TypeOf<typeof TContactWorkExperience>

export const TContactCommentsItem = t.strict({
  id: t.string,
  creatorProfile: TProfile,
  message: t.string,
  createdAt: DateFromISOString,
  deletable: t.boolean,
  editable: t.boolean,
})

export type ContactCommentsItem = t.TypeOf<typeof TContactCommentsItem>

export const TContactComments = t.array(TContactCommentsItem)

export type ContactComments = t.TypeOf<typeof TContactComments>

export const TGenerateInvitationLink = t.strict({
  invitationUrl: t.string,
})

export const TUpdateInvitationTitle = t.strict({
  invitationUrl: t.string,
  invitationId: t.string,
})

export const TContactPlaceholder = t.strict({
  placeholder: t.string,
})

export const TContactDetailsFromCv = t.partial({
  name: t.string,
  birthDate: DateFromISOString,
  contactInfos: t.array(TContactInformation),
  address: t.partial({
    country: t.strict({ code: t.string }),
    city: t.string,
  }),
  position: TCompanyPosition,
  languages: t.array(TLanguage),
  skills: t.array(TSkill),
  educations: t.array(TContactEducation),
  documents: t.array(TContactDocument),
  note: t.string,
  linkResponses: t.array(TContactLink),
})
