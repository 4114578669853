import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconLevelHigher = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 14">
    <path
      d="M10.0884 7.66464C10.0345 7.71872 9.97038 7.76162 9.89981 7.79089C9.82924 7.82017 9.75359 7.83523 9.6772 7.83523C9.6008 7.83523 9.52515 7.82017 9.45458 7.79089C9.38401 7.76162 9.31991 7.71872 9.26595 7.66464L7.00261 5.40714L4.73345 7.67048C4.67944 7.72448 4.61533 7.76732 4.54476 7.79655C4.4742 7.82578 4.39857 7.84082 4.3222 7.84082C4.24582 7.84082 4.17019 7.82578 4.09963 7.79655C4.02907 7.76732 3.96495 7.72448 3.91095 7.67048C3.85694 7.61647 3.8141 7.55235 3.78487 7.48179C3.75564 7.41123 3.7406 7.3356 3.7406 7.25922C3.7406 7.18285 3.75564 7.10722 3.78487 7.03666C3.8141 6.9661 3.85694 6.90198 3.91095 6.84797L6.58845 4.17048C6.81595 3.94298 7.18345 3.94298 7.41095 4.17048L10.0884 6.84797C10.1425 6.90136 10.1854 6.96495 10.2147 7.03504C10.244 7.10513 10.259 7.18034 10.259 7.25631C10.259 7.33227 10.244 7.40748 10.2147 7.47758C10.1854 7.54767 10.1425 7.61125 10.0884 7.66464Z"
      fill="currentColor"
    />
    <path
      d="M10.0884 10.8668C10.0345 10.9209 9.97038 10.9638 9.89981 10.993C9.82924 11.0223 9.75359 11.0374 9.6772 11.0374C9.6008 11.0374 9.52515 11.0223 9.45458 10.993C9.38401 10.9638 9.31991 10.9209 9.26595 10.8668L7.00261 8.60929L4.73345 10.8726C4.67944 10.9266 4.61533 10.9695 4.54476 10.9987C4.4742 11.0279 4.39857 11.043 4.3222 11.043C4.24582 11.043 4.17019 11.0279 4.09963 10.9987C4.02907 10.9695 3.96495 10.9266 3.91095 10.8726C3.85694 10.8186 3.8141 10.7545 3.78487 10.6839C3.75564 10.6134 3.7406 10.5377 3.7406 10.4614C3.7406 10.385 3.75564 10.3094 3.78487 10.2388C3.8141 10.1682 3.85694 10.1041 3.91095 10.0501L6.58845 7.37262C6.81595 7.14512 7.18345 7.14512 7.41095 7.37262L10.0884 10.0501C10.1425 10.1035 10.1854 10.1671 10.2147 10.2372C10.244 10.3073 10.259 10.3825 10.259 10.4585C10.259 10.5344 10.244 10.6096 10.2147 10.6797C10.1854 10.7498 10.1425 10.8134 10.0884 10.8668Z"
      fill="currentColor"
    />
  </SvgIcon>
)
