import { Box } from '@mui/material'
import { CountryFlag } from 'components/languages'
import { useDictionaries } from 'lib/app-helpers'
import { forwardRef, Ref } from 'react'

import { Autocomplete, AutocompleteProps } from '../../ui/inputs/autocomplete'

// TODO: https://axondevgroup.atlassian.net/browse/AXT-2885
// Virtualize the list so that it reacts faster

type Props = Omit<AutocompleteProps, 'options'> & { dataCy?: string }

export const CountryAutocomplete = forwardRef(
  (props: Props, ref: Ref<HTMLInputElement>) => {
    const { dictionaries, isDictionariesError } = useDictionaries()

    return (
      <Autocomplete
        {...props}
        ref={ref}
        doStartWithFilter
        loadingError={isDictionariesError}
        options={dictionaries.countries.data.map(country => ({
          value: country.code,
          label: country.name,
          content: (
            <Box display="flex" alignItems="center">
              <Box flex="none">
                <CountryFlag countryCode={country.code} />
              </Box>

              <Box component="span" ml={1} whiteSpace="normal">
                {country.name}
              </Box>
            </Box>
          ),
        }))}
      />
    )
  },
)
