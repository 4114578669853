import { alpha, styled, Theme } from '@mui/material'

type Props = {
  disabled?: boolean
  small?: boolean
  checked: boolean
}

const checkboxBgColor = ({
  disabled,
  checked,
  theme,
}: Omit<Props, 'small'> & { theme: Theme }) => {
  if (disabled) {
    return theme.palette.alabaster
  } else if (checked) {
    return theme.palette.primary.main
  } else {
    return theme.palette.alabaster
  }
}

export const CheckboxIcon = styled('span', {
  shouldForwardProp: prop => prop === 'children',
})<Props>(({ theme, small, disabled, checked }) => ({
  position: 'relative',
  width: small ? '16px' : '20px',
  height: small ? '16px' : '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid',
  borderColor: theme.palette.mischka,
  background: checkboxBgColor({ disabled, checked, theme }),
  borderRadius: '4px',
  boxShadow: `inset 0px 1px 4px ${alpha('#000000', 0.04)}`,
}))
