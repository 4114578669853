type GradientType =
  | 'VIOLET'
  | 'BLUE'
  | 'LIGHT_BLUE'
  | 'GREEN'
  | 'LIGHT_ORANGE'
  | 'ORANGE'
  | 'RED'
  | 'LIGHT_VIOLET'

type Colors = [string, string, string, string, string]

export const gradientColors = {
  VIOLET: ['#C5B5F7', '#9A7EF0', '#8B6CEE', '#7D59EC', '#6E47EA'],
  BLUE: ['#CCD9FC', '#99B4F9', '#668EF6', '#3369F3', '#0043F0'],
  LIGHT_BLUE: ['#CCE8FC', '#99D0F9', '#66B9F6', '#33A2F3', '#008BF0'],
  GREEN: ['#CCE8E2', '#99D1C5', '#66BBA8', '#33A48B', '#008D6E'],
  LIGHT_ORANGE: ['#FEA800', '#FEA800', '#FECB66', '#FEB933', '#FEA800'],
  ORANGE: ['#FFE6CC', '#FFCD99', '#FFB466', '#FF9B33', '#FF8200'],
  RED: ['#FCCCCE', '#F9999D', '#F7676C', '#F4343B', '#F1010A'],
  LIGHT_VIOLET: ['#EDCCFC', '#DA99F9', '#C866F6', '#B633F3', '#A300F0'],
} as const

export const getGradientColors = (
  colorsCount: number,
  gradientType: GradientType,
  colorsAccumulator: Array<string> = [],
): Array<string> => {
  if (colorsCount === 0) {
    return []
  }
  const neededColorsCount = colorsCount - colorsAccumulator.length

  const neededColors = getNeededColorsForGradient(
    [...gradientColors[gradientType]],
    neededColorsCount,
  )

  const result = [...colorsAccumulator, ...neededColors]

  if (result.length < colorsCount) {
    const gradientTypes = Object.keys(gradientColors)

    const currentGradientTypeIndex = gradientTypes.indexOf(gradientType)
    const nextGradientTypeIndex =
      currentGradientTypeIndex === gradientTypes.length - 1
        ? 0
        : currentGradientTypeIndex + 1

    return getGradientColors(
      colorsCount,
      gradientTypes[nextGradientTypeIndex] as GradientType,
      result,
    )
  }

  return result
}

// need different colors order depending on the count
// https://www.figma.com/file/FgCuHfHRxFZBXRIDJ29ofi/Axterior-components?node-id=450%3A4271&t=1u5Bm1xVF5qXLnX4-0
export const getNeededColorsForGradient = (colors: Colors, count: number) => {
  if (count === 4) return [colors[0], colors[1], colors[2], colors[4]]
  if (count === 3) return [colors[0], colors[2], colors[4]]
  if (count === 2) return [colors[0], colors[2]]
  if (count === 1) return [colors[0]]
  if (count === 0) return []
  return colors
}
