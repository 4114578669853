import { TProfile } from 'api/common'
import * as t from 'io-ts'

export const TWorkload = t.strict({
  jobsAmount: t.number,
  recruiter: TProfile,
})

export const TWorkloads = t.array(TWorkload)

export type Workloads = t.TypeOf<typeof TWorkloads>

export const TPeriodTypes = t.union([
  t.literal('DAY'),
  t.literal('WEEK'),
  t.literal('MONTH'),
])

export type PeriodTypes = t.TypeOf<typeof TPeriodTypes>

export const TContactAdditionGoal = t.strict({
  period: TPeriodTypes,
  contactsAmount: t.number,
  recruiter: TProfile,
})

export const TContactAdditionGoals = t.array(TContactAdditionGoal)

export type ContactAdditionGoals = t.TypeOf<typeof TContactAdditionGoals>

export const TKPISettings = t.strict({
  contactAdditionGoals: TContactAdditionGoals,
  workloads: TWorkloads,
})

export const TTeamWorkload = t.strict({
  recruiter: TProfile,
  workloadPercentage: t.number,
  maxNumberOfJobs: t.number,
  currentNumberOfJobs: t.number,
})
