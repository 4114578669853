import { Box, CircularProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const LoadingMoreOption = () => {
  const { t } = useTranslation()
  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography mr={0.5} color="inherit" variant="inherit">
        {t('common.loading_more')}
      </Typography>
      <CircularProgress size={16} />
    </Box>
  )
}
