import { getInputError } from 'lib/form-utils'
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
} from 'react-hook-form'

import { DatePicker, Props } from './date-picker'

export const renderDatePicker = <
  FormValues extends FieldValues,
  FormPath extends Path<FormValues>,
>(
  props: Omit<Props, 'value' | 'onChange'>,
) => {
  return function SelectController({
    field,
    fieldState,
  }: {
    field: ControllerRenderProps<FormValues, FormPath>
    fieldState: ControllerFieldState
  }) {
    return (
      <DatePicker {...props} {...field} {...getInputError(fieldState.error)} />
    )
  }
}
