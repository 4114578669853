import { Stack } from '@mui/material'
import { ContactDocument } from 'api'
import { Document } from 'components/common'
import { DetailsItemContainer } from 'components/templates/details-template'
import { useTranslation } from 'react-i18next'

type Props = Readonly<{
  documents: Array<ContactDocument>
}>

export const ContactDocumentsSection = ({ documents }: Props) => {
  const { t } = useTranslation()
  if (documents.length === 0) return null

  return (
    <DetailsItemContainer title={t('common.documents')}>
      <Stack spacing={1}>
        {documents.map(document => (
          <Document
            key={document.fileId}
            fileId={document.fileId}
            fileName={document.name}
            fileCreatedAt={document.createdAt}
          />
        ))}
      </Stack>
    </DetailsItemContainer>
  )
}
