import { paths } from '../paths'
import { buildRoute, Route } from './build-route'

export const unauthRoutes: Array<Route> = [
  buildRoute({
    path: paths.signup,
    factory: () => import('pages/signup'),
  }),
  buildRoute({
    path: paths.createCompany,
    factory: () => import('pages/create-company'),
  }),
  buildRoute({
    path: paths.finishInvitation,
    factory: () => import('pages/finish-invitation'),
  }),
  buildRoute({
    path: paths.frozenAccount,
    factory: () => import('pages/login/frozen-account'),
  }),
  buildRoute({
    path: paths.login,
    factory: () => import('pages/login'),
  }),
  buildRoute({
    path: paths.forgotPassword,
    factory: () => import('pages/login/forgot-password-page'),
  }),
  buildRoute({
    path: paths.loginGoogle,
    factory: () => import('pages/login/login-google-page'),
  }),
  buildRoute({
    path: paths.inviteGoogle,
    factory: () => import('pages/login/login-google-page'),
  }),
  buildRoute({
    path: paths.passwordRecovery,
    factory: () => import('pages/password-reset'),
  }),
  buildRoute({
    path: paths.passwordChange,
    factory: () => import('pages/password-reset'),
  }),
]
