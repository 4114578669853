import * as t from 'io-ts'

export const TQualificationLevelCode = t.union([
  t.literal('TRA'),
  t.literal('JUN'),
  t.literal('MID'),
  t.literal('SEN'),
  t.literal('NONE'),
])

export type QualificationLevelCode = t.TypeOf<typeof TQualificationLevelCode>
