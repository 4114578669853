import {
  Tooltip,
  TooltipProps,
  Typography,
  TypographyProps,
} from '@mui/material'
import { useIsElementOverflow } from 'lib/react-utils'

type Props = Readonly<
  TypographyProps & {
    isShowTooltip?: boolean
    tooltipProps?: TooltipProps
  }
>

/** Typography wrapped with Tooltip which is visible only when text is truncated */
export const TruncateWithTooltip = ({
  tooltipProps,
  isShowTooltip = true,
  ...props
}: Props) => {
  const { ref, isOverflow } = useIsElementOverflow('horizontal')

  return (
    <Tooltip
      placement="top"
      {...tooltipProps}
      title={isOverflow && isShowTooltip ? props.children : ''}
    >
      <Typography {...props} ref={ref} noWrap display="block" />
    </Tooltip>
  )
}
