import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconNumber = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M4.30923 16.9462V6.21247H2.54779L0 8.06827V9.77466L2.46129 8.00536H2.54779V16.9462H4.30923Z"
      fill="currentColor"
    />
    <path
      d="M9.12959 9.31071C9.12959 9.29498 9.12959 9.23994 9.12959 9.21635C9.12959 8.28845 9.79013 7.46277 10.9225 7.46277C11.9369 7.46277 12.6839 8.12331 12.6839 9.12199C12.6839 10.0577 12.0548 10.7733 11.4729 11.4103L7.48611 15.8296V16.9462H14.6577V15.46H9.96313V15.3578L12.6131 12.3775C13.5882 11.2923 14.4689 10.3802 14.4689 8.97258C14.4689 7.27405 13.085 6.00015 10.9618 6.00015C8.60273 6.00015 7.4232 7.59645 7.4232 9.22421V9.31071H9.12959Z"
      fill="currentColor"
    />
    <path
      d="M18.9669 12.1337H20.1464C21.381 12.1337 22.2067 12.8572 22.2146 13.903C22.2303 14.9646 21.3889 15.7274 20.0835 15.7195C18.9276 15.7116 18.094 15.0904 18.0154 14.2883H16.3719C16.4348 15.8689 17.7795 17.1664 20.0678 17.1664C22.2853 17.1664 24.031 15.9082 23.9996 13.9502C23.9681 12.236 22.6156 11.4732 21.672 11.3788V11.2845C22.4741 11.1508 23.6929 10.2937 23.6614 8.76813C23.6221 7.18755 22.2617 5.98443 20.1228 6.00015C17.8739 6.00802 16.6472 7.32123 16.6 8.84676H18.2749C18.3221 8.09972 19.0219 7.43132 20.0835 7.43132C21.1372 7.43132 21.8921 8.084 21.8921 9.03549C21.9 9.99484 21.1372 10.6947 20.0914 10.6947H18.9669V12.1337Z"
      fill="currentColor"
    />
  </SvgIcon>
)
