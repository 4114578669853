import { pipe } from 'fp-ts/function'
import { decodeJson, get } from 'lib/request'

import { TDictionariesRecord } from './dictionaries.codecs'

export const getDictionaries = async () => {
  return pipe(
    await get('api/dictionaries', {}),
    decodeJson(TDictionariesRecord),
  )
}
