import constate from 'constate'
import { useCallback, useState } from 'react'

/**
 * A hook to control the behavior of the RouteLeavingGuard.
 *
 * - isGuardActive: Determines whether the RouteLeavingGuard should be active.
 * - activateGuard: Activates the RouteLeavingGuard, making it interrupt route changes.
 * - deactivateGuard: Deactivates the RouteLeavingGuard, allowing route changes without interruption.
 */
const useRouteLeavingGuardControl = () => {
  const [isGuardActive, setIsGuardActive] = useState(true)

  const activateGuard = useCallback(() => {
    setIsGuardActive(true)
  }, [])

  const deactivateGuard = useCallback(() => {
    setIsGuardActive(false)
  }, [])

  return {
    isGuardActive,
    activateGuard,
    deactivateGuard,
  } as const
}

/**
 * Context provider and hook for components to control the RouteLeavingGuard behavior.
 */
export const [
  RouteLeavingGuardControlProvider,
  useRouteLeavingGuardControlContext,
] = constate(useRouteLeavingGuardControl)
