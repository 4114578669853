import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconLock = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 17 17"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M11.8333 7.16667V5.83333C11.8333 3.99238 10.3409 2.5 8.49996 2.5C6.65901 2.5 5.16663 3.99238 5.16663 5.83333V7.16667M8.49996 10.1667V11.5M6.36663 14.5H10.6333C11.7534 14.5 12.3135 14.5 12.7413 14.282C13.1176 14.0903 13.4236 13.7843 13.6153 13.408C13.8333 12.9802 13.8333 12.4201 13.8333 11.3V10.3667C13.8333 9.24656 13.8333 8.68651 13.6153 8.25869C13.4236 7.88236 13.1176 7.5764 12.7413 7.38465C12.3135 7.16667 11.7534 7.16667 10.6333 7.16667H6.36663C5.24652 7.16667 4.68647 7.16667 4.25865 7.38465C3.88232 7.5764 3.57636 7.88236 3.38461 8.25869C3.16663 8.68651 3.16663 9.24656 3.16663 10.3667V11.3C3.16663 12.4201 3.16663 12.9802 3.38461 13.408C3.57636 13.7843 3.88232 14.0903 4.25865 14.282C4.68647 14.5 5.24652 14.5 6.36663 14.5Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
