import { env } from 'app/env'
import { pipe } from 'fp-ts/lib/function'
import { decodeJson, post } from 'lib/request'

import { TUserResponse } from './registration.codecs'

export type SignupInput = {
  companyName: string
  promotionSlug?: string | null
  fullName: string
  email: string
  password: string
  zoneId: string
}

export const signup = async (input: SignupInput) => {
  return pipe(
    await post('auth/register', {
      body: {
        ...input,
        zoneId: input.zoneId,
        clientId: env.REACT_APP_CLIENT_ID,
      },
    }),
    decodeJson(TUserResponse),
  )
}
