import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from '@mui/material'
import { Droppable } from 'components/common/droppable'
import { useCallback } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { Control, UseFieldArrayReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TableCell, TableRow } from 'ui/data'

import { FormValues } from './pipeline-template-form'
import { TemplateStageRow } from './template-stage-row'

type Props = {
  control: Control<FormValues, any>
  stages: UseFieldArrayReturn<FormValues, 'templateStages', 'id'>
}

export const TemplateStagesTable = ({ control, stages }: Props) => {
  const { t } = useTranslation()

  const handleDragEnd = useCallback(
    ({ source, destination }: DropResult) => {
      // dropped outside the list
      if (!destination) {
        return
      }

      stages.move(source.index, destination.index)
    },
    [stages],
  )

  return (
    <TableContainer component={Paper} sx={{ borderRadius: '2px' }}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="stages">
          {provided => (
            <Table
              sx={{ tableLayout: 'fixed' }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center" width="16%">
                    {t('common.number')}
                  </TableCell>
                  <TableCell width="52%">{t('common.stage')}</TableCell>
                  <TableCell align="center" width="16%">
                    {t('common.can_hire')}
                  </TableCell>
                  <TableCell align="center" width="16%">
                    {t('common.actions')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stages.fields.map((stage, index) => {
                  const isLast = index === stages.fields.length - 1
                  return (
                    <TemplateStageRow
                      key={stage.id}
                      control={control}
                      id={stage.id}
                      index={index}
                      isLast={isLast}
                      onRemove={() => stages.remove(index)}
                    />
                  )
                })}
                {provided.placeholder}
              </TableBody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
    </TableContainer>
  )
}
