import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconStopWatch = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M11.75 8.39996C11.75 7.98575 11.4142 7.64996 11 7.64996C10.5858 7.64996 10.25 7.98575 10.25 8.39997L10.25 12.75H5.75C5.33579 12.75 5 13.0858 5 13.5C5 13.9142 5.33579 14.25 5.75 14.25H11C11.1989 14.25 11.3897 14.171 11.5303 14.0303C11.671 13.8897 11.75 13.6989 11.75 13.5L11.75 8.39996Z"
      fill="currentColor"
    />
    <path
      d="M8.75 1.5C8.75 1.08579 9.08579 0.75 9.5 0.75H12.5C12.9142 0.75 13.25 1.08579 13.25 1.5C13.25 1.91421 12.9142 2.25 12.5 2.25V3.10641C14.5398 3.3983 16.3904 4.27624 17.8757 5.56421C17.8818 5.55771 17.888 5.5513 17.8943 5.54497L18.4247 5.01464L17.8943 4.48431C17.6014 4.19142 17.6014 3.71654 17.8943 3.42365C18.1872 3.13076 18.6621 3.13076 18.955 3.42365L21.0763 5.54497C21.3692 5.83786 21.3692 6.31274 21.0763 6.60563C20.7834 6.89852 20.3085 6.89852 20.0156 6.60563L19.4853 6.0753L18.955 6.60563C18.9487 6.61195 18.9423 6.61812 18.9358 6.62417C20.5333 8.46625 21.5 10.8702 21.5 13.5C21.5 19.299 16.799 24 11 24C5.20101 24 0.5 19.299 0.5 13.5C0.5 8.21021 4.41123 3.83459 9.5 3.10641V2.25C9.08579 2.25 8.75 1.91421 8.75 1.5ZM11 4.5C10.7666 4.5 10.5355 4.50886 10.3069 4.52626C5.66057 4.87971 2 8.76272 2 13.5C2 18.4706 6.02944 22.5 11 22.5C15.9706 22.5 20 18.4706 20 13.5C20 8.76272 16.3394 4.87971 11.6931 4.52626C11.4645 4.50886 11.2334 4.5 11 4.5Z"
      fill="currentColor"
    />
  </SvgIcon>
)
