import { Badge, badgeClasses, Box, Tooltip } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getNotificationsCount, qk } from 'api'
import { useAuthContext } from 'app/auth'
import { paths } from 'app/routes'
import { IconBell03 } from 'assets/icons'
import { NotificationsFeedContainer } from 'components/notifications'
import { NotificationsFeedMoreCount } from 'components/notifications/notifications-feed/notifications-feed-more-count'
import { useNotificationsFeed } from 'lib/app-helpers'
import { useBoolean } from 'lib/react-utils'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'react-use'
import { IconButton } from 'ui/inputs/icon-button'
import { NavLink } from 'ui/navigation'

import { NotificationsWindow } from './notifications-window'

export const Notifications = () => {
  const { authCause } = useAuthContext()
  const { t } = useTranslation()

  const $notificationsCount = useQuery(
    qk.notifications.count.toKey(),
    getNotificationsCount,
  )

  const notificationsWindowAnchor = useRef<HTMLElement | null>(null)
  const isNotificationsWindowOpen = useBoolean()

  const {
    subscribeToNotificationsFeed,
    clearNotificationsFeed,
    notificationsFeedCount,
    showCurrentUnreadNotifications,
  } = useNotificationsFeed()

  useEffectOnce(() => {
    // Subscribe to real-time (server-sent events) notifications feed
    return subscribeToNotificationsFeed()
  })

  useEffectOnce(() => {
    // Show high and medium notifications right after user logins (but not after refreshing the page)
    // Note: using authCause approach instead of simply calling it right after successful login mutation,
    // because there are other requests we need to wait for before giving access to the system.
    // For example, checking current subscription status.
    if (authCause === 'login') {
      showCurrentUnreadNotifications()
    }
  })

  return (
    <Box position="relative">
      <Tooltip title={t('common.notifications')} placement="bottom">
        <Box ref={notificationsWindowAnchor}>
          <IconButton
            component={NavLink}
            to={paths.notifications}
            onClick={(event: React.MouseEvent<HTMLLinkElement>) => {
              // When user has unread notifications, open notifications window
              // instead of redirecting him notifications center page
              if (
                !$notificationsCount.data ||
                $notificationsCount.data.count > 0
              ) {
                event.preventDefault()
                isNotificationsWindowOpen.setTrue()
                clearNotificationsFeed()
              }
            }}
          >
            <Badge
              variant="dot"
              invisible={
                !$notificationsCount.data ||
                $notificationsCount.data.count === 0
              }
              color="warning"
              sx={{
                [`& .${badgeClasses.dot}`]: {
                  width: 12,
                  height: 12,
                  borderRadius: '50%',
                  border: `1px solid white`,
                },
              }}
            >
              <IconBell03
                sx={{
                  fontSize: '20px',
                  color: theme => theme.palette.mineShaft.main,

                  '.active &': {
                    color: 'primary.main',
                  },

                  '@keyframes bell': {
                    '0%': { transform: 'rotate(0)' },
                    '25%': { transform: 'rotate(30deg)' },
                    '50%': { transform: 'rotate(0)' },
                    '75%': { transform: 'rotate(-30deg)' },
                    '100%': { transform: 'rotate(0)' },
                  },

                  animation:
                    $notificationsCount.data &&
                    $notificationsCount.data.count > 0
                      ? 'bell 400ms 300ms'
                      : 'none',
                }}
              />
            </Badge>
          </IconButton>
        </Box>
      </Tooltip>

      <NotificationsWindow
        isOpen={isNotificationsWindowOpen.isTrue}
        onClose={isNotificationsWindowOpen.setFalse}
        anchorElement={notificationsWindowAnchor.current}
      />

      <Box
        sx={{
          position: 'absolute',
          zIndex: theme => theme.zIndex.snackbar,
          top: 'calc(100% + 4px)',
          left: 0,
          transform: 'translateX(-75%)',
        }}
      >
        <NotificationsFeedMoreCount
          count={notificationsFeedCount - 3}
          onCheckAllNotifications={clearNotificationsFeed}
        />

        <NotificationsFeedContainer />
      </Box>
    </Box>
  )
}
