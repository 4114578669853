import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconLevelsLow = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 14"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M4.17059 5.33521C4.22455 5.28113 4.28865 5.23823 4.35922 5.20895C4.42979 5.17968 4.50544 5.16461 4.58184 5.16461C4.65824 5.16461 4.73388 5.17968 4.80445 5.20895C4.87502 5.23823 4.93912 5.28113 4.99309 5.33521L7.25642 7.59271L9.52559 5.32937C9.57959 5.27537 9.64371 5.23253 9.71427 5.2033C9.78483 5.17407 9.86046 5.15903 9.93684 5.15903C10.0132 5.15903 10.0888 5.17407 10.1594 5.2033C10.23 5.23253 10.2941 5.27537 10.3481 5.32937C10.4021 5.38338 10.4449 5.44749 10.4742 5.51806C10.5034 5.58862 10.5184 5.66425 10.5184 5.74062C10.5184 5.817 10.5034 5.89263 10.4742 5.96319C10.4449 6.03375 10.4021 6.09787 10.3481 6.15187L7.67059 8.82937C7.44309 9.05687 7.07559 9.05687 6.84809 8.82937L4.17059 6.15187C4.11655 6.09848 4.07364 6.0349 4.04436 5.96481C4.01508 5.89471 4 5.8195 4 5.74354C4 5.66757 4.01508 5.59237 4.04436 5.52227C4.07364 5.45218 4.11655 5.38859 4.17059 5.33521Z"
      fill="currentColor"
    />
  </SvgIcon>
)
