import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconReverseLeft = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 18 18"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M1 5H11C14.3137 5 17 7.68629 17 11C17 14.3137 14.3137 17 11 17H1M1 5L5 1M1 5L5 9"
      stroke="currentColor"
      strokeWidth={props.stroke ?? '1.4'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
