import { JobContact } from './job-pipeline.codecs'

// Server has a contact sorting logic.
// So we also need to perform exactly that sorting during an optimistic update.
// pipeline stages order -> applied before rejected -> date of applying/rejecting -> name
export const orderJobPipelineContactsList = (list: Array<JobContact>) => {
  return list.sort((a, b) => {
    if (a.jobPipelineStage.stageId === b.jobPipelineStage.stageId) {
      if (a.rejectedAt && b.rejectedAt) {
        const aTime = a.rejectedAt.getTime()
        const bTime = b.rejectedAt.getTime()

        if (aTime === bTime) {
          return a.name.localeCompare(b.name)
        }

        return bTime - aTime
      } else if (!a.rejectedAt && !b.rejectedAt) {
        const aTime = a.appliedAt.getTime()
        const bTime = b.appliedAt.getTime()

        if (aTime === bTime) {
          return a.name.localeCompare(b.name)
        }

        return bTime - aTime
      }

      return a.rejectedAt ? -1 : 1
    }

    return a.jobPipelineStage.order - b.jobPipelineStage.order
  })
}
