import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconLevelHigh = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 14">
    <path
      d="M10.0884 9.06454C10.0345 9.11862 9.97038 9.16152 9.89981 9.1908C9.82924 9.22007 9.75359 9.23514 9.6772 9.23514C9.6008 9.23514 9.52515 9.22007 9.45458 9.1908C9.38401 9.16152 9.31991 9.11862 9.26595 9.06454L7.00261 6.80704L4.73345 9.07038C4.67944 9.12438 4.61533 9.16722 4.54476 9.19645C4.4742 9.22568 4.39857 9.24072 4.3222 9.24072C4.24582 9.24072 4.17019 9.22568 4.09963 9.19645C4.02907 9.16722 3.96495 9.12438 3.91095 9.07038C3.85694 9.01637 3.8141 8.95226 3.78487 8.88169C3.75564 8.81113 3.7406 8.7355 3.7406 8.65913C3.7406 8.58275 3.75564 8.50712 3.78487 8.43656C3.8141 8.366 3.85694 8.30188 3.91095 8.24788L6.58845 5.57038C6.81595 5.34288 7.18345 5.34288 7.41095 5.57038L10.0884 8.24788C10.1425 8.30127 10.1854 8.36485 10.2147 8.43494C10.244 8.50504 10.259 8.58025 10.259 8.65621C10.259 8.73218 10.244 8.80738 10.2147 8.87748C10.1854 8.94757 10.1425 9.01116 10.0884 9.06454Z"
      fill="currentColor"
    />
  </SvgIcon>
)
