import { Stack } from '@mui/material'
import { IconCrossCircle, IconPencil } from 'assets/icons'
import { PipelineTemplatePaper } from 'components/settings'
import { useBoolean } from 'lib/react-utils'
import { Badge, BadgeCollection } from 'ui/data'
import { IconButton } from 'ui/inputs/icon-button'

import { JobPipelineTemplateDraft } from '../manual-settings-setup'
import { EditPipelineTemplate } from './edit-pipeline-template'

type Props = {
  template: JobPipelineTemplateDraft
  onUpdate: (template: JobPipelineTemplateDraft) => void
  onDelete: (templateId: string) => void
}

export const PipelineTemplateItem = ({
  template,
  onUpdate,
  onDelete,
}: Props) => {
  const isEditing = useBoolean()

  return isEditing.isFalse ? (
    <PipelineTemplatePaper
      name={template.name}
      isDefault={template.defaultPipeline}
      actions={
        <Stack direction="row" spacing={0.5}>
          <IconButton color="primary" onClick={isEditing.setTrue}>
            <IconPencil sx={{ fontSize: '16px ' }} />
          </IconButton>

          <IconButton
            color="error"
            onClick={() => {
              onDelete(template.pipelineId)
            }}
          >
            <IconCrossCircle sx={{ fontSize: '16px ' }} />
          </IconButton>
        </Stack>
      }
    >
      <BadgeCollection>
        {template.stages.map(stage => (
          <Badge key={stage.name}>{stage.name}</Badge>
        ))}
      </BadgeCollection>
    </PipelineTemplatePaper>
  ) : (
    <EditPipelineTemplate
      template={template}
      onClose={isEditing.setFalse}
      onUpdate={onUpdate}
    />
  )
}
