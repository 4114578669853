import { CustomFieldsList, CustomFieldType, CustomFieldWithValue } from 'api'
import { toServerDate } from 'lib/js-utils'

/**
 * Type representing possible form values for custom fields.
 */
type CustomFieldFormValue = string | number | Date | null

/**
 * Default values for each type of custom field.
 */
const defaultCustomFieldFormValue: Record<
  CustomFieldType,
  CustomFieldFormValue
> = {
  TEXT: '',
  NUMBER: null,
  DATE: null,
  SINGLE_CHOICE: '',
}

/**
 * Generates default form values for a given list of custom fields.
 *
 * @param {CustomFieldsList} customFields - List of custom fields.
 * @returns {Record<string, CustomFieldFormValue>} - Default form values for each custom field.
 */
export const getDefaultCustomFieldsValues = (
  customFields: CustomFieldsList,
) => {
  return Object.fromEntries(
    customFields.map(field => [
      field.fieldName,
      defaultCustomFieldFormValue[field.fieldType],
    ]),
  )
}

/**
 * Adjusts custom field values to a format suitable for server submission.
 *
 * @template FormValues - Type for form values.
 * @param {CustomFieldsList} customFields - List of custom fields.
 * @param {FormValues} formValues - Form values including custom fields.
 * @returns {Array<{ fieldId: string; fieldValue: CustomFieldFormValue }>} - Adjusted custom field values.
 */
export const adjustCustomFieldsToServer = <
  FormValues extends {
    customFields: {
      [key: string]: CustomFieldFormValue
    }
  },
>(
  customFields: CustomFieldsList,
  formValues: FormValues,
) => {
  return customFields
    .filter(field => formValues.customFields[field.fieldName])
    .map(field => {
      const fieldValue = formValues.customFields[field.fieldName]!
      return {
        fieldId: field.fieldId,
        fieldValue:
          field.fieldType === 'DATE' ? toServerDate(fieldValue) : fieldValue,
      }
    })
}

/**
 * Adjusts custom field values from server response to a format suitable for form usage.
 *
 * @template Data - Type for server response data.
 * @param {CustomFieldsList} customFields - List of custom fields.
 * @param {Data} data - Server response data including custom fields.
 * @returns {Record<string, CustomFieldFormValue>} - Adjusted custom field values for form usage.
 */
export const adjustCustomFieldsFromServer = <
  Data extends { customFields?: Array<CustomFieldWithValue> },
>(
  customFields: CustomFieldsList,
  data: Data,
) => {
  return Object.fromEntries(
    customFields.map(field => {
      let contactFieldValue: CustomFieldFormValue | undefined =
        data.customFields?.find(
          contactField => contactField.fieldDetails.fieldId === field.fieldId,
        )?.fieldValue

      // Even if field type is number, server returns field value as a string
      if (contactFieldValue && field.fieldType === 'NUMBER') {
        contactFieldValue = Number(contactFieldValue)
      }

      // Current SINGLE_CHOICE value can be removed from list options in settings
      // In this case, replace removed (not allowed anymore) value with an empty string
      if (
        contactFieldValue &&
        field.fieldType === 'SINGLE_CHOICE' &&
        !field.valueDetails?.includes(contactFieldValue as string)
      ) {
        contactFieldValue = ''
      }

      return [
        field.fieldName,
        contactFieldValue ?? defaultCustomFieldFormValue[field.fieldType],
      ]
    }),
  )
}
