import { SvgIconProps } from '@mui/material'
import { IconFileCheckOutline, IconJobs, IconOfficeOutline } from 'assets/icons'
import { ReadonlyNonEmptyArray } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import { TFuncKey } from 'react-i18next'

export enum SectionId {
  Offices = 'OFFICES',
  Departments = 'DEPARTMENTS',
  Positions = 'POSITIONS',
  Projects = 'PROJECTS',
  PipelineTemplates = 'PIPELINE_TEMPLATES',
  Summary = 'SUMMARY',
}

type Navigation = ReadonlyNonEmptyArray<{
  name: TFuncKey
  Icon: (props: SvgIconProps<'svg', {}>) => JSX.Element
  sections: ReadonlyNonEmptyArray<{
    sectionId: SectionId
    name: TFuncKey
    requiresSubmit: boolean
  }>
}>

export const navigation: Navigation = [
  {
    name: 'manual_settings_setup.navigation.company',
    Icon: IconOfficeOutline,
    sections: [
      {
        sectionId: SectionId.Offices,
        name: 'common.offices',
        requiresSubmit: true,
      },
      {
        sectionId: SectionId.Departments,
        name: 'common.departments',
        requiresSubmit: true,
      },
      {
        sectionId: SectionId.Positions,
        name: 'common.positions',
        requiresSubmit: true,
      },
      {
        sectionId: SectionId.Projects,
        name: 'common.projects',
        requiresSubmit: true,
      },
    ],
  },
  {
    name: 'manual_settings_setup.navigation.job_settings',
    Icon: IconJobs,
    sections: [
      {
        sectionId: SectionId.PipelineTemplates,
        name: 'jobs.pipeline_templates',
        requiresSubmit: false,
      },
    ],
  },
  {
    name: 'manual_settings_setup.navigation.review_and_finish',
    Icon: IconFileCheckOutline,
    sections: [
      {
        sectionId: SectionId.Summary,
        name: 'manual_settings_setup.summary.summary',
        requiresSubmit: false,
      },
    ],
  },
]
