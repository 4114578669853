import { NumberParam } from 'lib/query-params'
import { MutableRefObject, useCallback, useEffect, useMemo } from 'react'
import { Pagination } from 'ui/data'
import useLocalStorageState from 'use-local-storage-state'
import { useQueryParam } from 'use-query-params'

/**
 * Hook for managing pagination state.
 *
 * @param {string} sizeLocalStorageKey - The key for storing page size in local storage.
 * @param {MutableRefObject<HTMLElement | null>} [scrollContainerRef] - Ref object for the scroll container (optional).
 *
 * @returns {Pagination} - Object with pagination state and handlers.
 */
export const usePagination = (
  sizeLocalStorageKey: string,
  scrollContainerRef?: MutableRefObject<HTMLElement | null>,
  defaultValue?: number,
): Pagination => {
  // Retrieve page index from the query parameters.
  const [index, setIndex] = useQueryParam('page', NumberParam)

  // Retrieve and store page size in local storage.
  const [size, setSize] = useLocalStorageState(sizeLocalStorageKey, {
    defaultValue: defaultValue ?? 25,
  })

  useEffect(() => {
    if (defaultValue !== undefined) {
      setSize(defaultValue)
    }
  }, [defaultValue, setSize])

  // Handle changing the current page index.
  const handleChangePage = useCallback(
    (index: number) => {
      // Scroll to the top of the scroll container or window.
      const scrollContainer = scrollContainerRef?.current ?? window
      scrollContainer.scrollTo(0, 0)

      // Set the new page index.
      setIndex(index)
    },
    [setIndex, scrollContainerRef],
  )

  // Handle changing the page size.
  const handleChangeSize = useCallback(
    (size: number) => {
      // Change to the first page when the page size changes.
      handleChangePage(0)

      // Set the new page size.
      setSize(size)
    },
    [handleChangePage, setSize],
  )

  // Memoize the pagination object to avoid unnecessary re-renders.
  return useMemo(() => {
    return {
      page: index,
      pageSize: size,
      onChangePage: handleChangePage,
      onChangePageSize: handleChangeSize,
    }
  }, [index, size, handleChangePage, handleChangeSize])
}
