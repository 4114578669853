import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconShare = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 17"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M5.72667 9.50665L10.28 12.16M10.2733 4.83998L5.72667 7.49331M14 3.83331C14 4.93788 13.1046 5.83331 12 5.83331C10.8954 5.83331 10 4.93788 10 3.83331C10 2.72874 10.8954 1.83331 12 1.83331C13.1046 1.83331 14 2.72874 14 3.83331ZM6 8.49998C6 9.60455 5.10457 10.5 4 10.5C2.89543 10.5 2 9.60455 2 8.49998C2 7.39541 2.89543 6.49998 4 6.49998C5.10457 6.49998 6 7.39541 6 8.49998ZM14 13.1666C14 14.2712 13.1046 15.1666 12 15.1666C10.8954 15.1666 10 14.2712 10 13.1666C10 12.0621 10.8954 11.1666 12 11.1666C13.1046 11.1666 14 12.0621 14 13.1666Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
