import { useRouteLeavingGuardControlContext } from 'lib/context'
import { useBoolean } from 'lib/react-utils'
import { useCallback, useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'
import { Dialog, DialogContent } from 'ui/feedback'

type Props = Readonly<{
  when: boolean
  title?: string
  text?: string
  onConfirm?: () => void
}>

// https://github.com/remix-run/react-router/issues/8139
// Unfortunately, react-router does not support Prompt, usePrompt and useBlocker yet
// So we use custom solution to confirm navigation for now
// It would block if the user closed the tab or tried to click a link to another page
// But wouldn't block on history events (back/forward)
export const RouteLeavingGuard = ({ when, title, text, onConfirm }: Props) => {
  const { t } = useTranslation()
  const { navigator } = useContext(NavigationContext)
  const { isGuardActive } = useRouteLeavingGuardControlContext()

  const dialogOpen = useBoolean()
  const confirmLeaving = useRef<() => void>()

  const openDialog = dialogOpen.setTrue

  useEffect(() => {
    if (!when || !isGuardActive) {
      return
    }

    const push = navigator.push

    navigator.push = (...args: Parameters<typeof push>) => {
      openDialog()
      confirmLeaving.current = () => push(...args)
    }

    return () => {
      navigator.push = push
    }
  }, [navigator, when, openDialog, isGuardActive])

  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      event.preventDefault()
      return (event.returnValue = t('common.changes_wont_be_saved'))
    },
    [t],
  )

  useEffect(() => {
    if (when) {
      window.addEventListener('beforeunload', handleBeforeUnload, {
        capture: true,
      })
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload, {
        capture: true,
      })
    }
  }, [when, handleBeforeUnload])

  return (
    <Dialog size="small" open={dialogOpen.isTrue} onClose={dialogOpen.setFalse}>
      <DialogContent
        variant={'danger'}
        title={title ?? t('common.changes_wont_be_saved')}
        onConfirm={() => {
          if (onConfirm) onConfirm()
          if (confirmLeaving.current) confirmLeaving.current()
        }}
        onDeny={dialogOpen.setFalse}
      >
        {text ?? t('common.cant_save_data')}
      </DialogContent>
    </Dialog>
  )
}
