import { Box, Stack, Typography } from '@mui/material'
import { WorkPermit, WorkPermits } from 'api'
import { IconX } from 'assets/icons'
import { useFormatLocation } from 'lib/app-helpers'
import { formatDate } from 'lib/js-utils'
import { dateRangeWithDash } from 'lib/js-utils/text/date-range-with-dash'
import { IconButton } from 'ui/inputs/icon-button'

import { EditWorkPermitIconButton } from './edit-work-permit-icon-button'

type Props = Readonly<{
  workPermits: WorkPermits
  onEdit?: (updatedWorkPermit: WorkPermit, index: number) => void
  onRemove?: (index: number) => void
}>

export const WorkPermitsList = ({ workPermits, onEdit, onRemove }: Props) => {
  const formatLocation = useFormatLocation()

  const canEdit = onEdit !== undefined
  const canRemove = onRemove !== undefined
  const haveActions = canEdit || canRemove

  if (workPermits.length === 0) return null

  return (
    <Stack spacing={2}>
      {workPermits.map((workPermit, index) => {
        return (
          <Box
            key={workPermit.country + index}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={2} alignItems="center" width={1}>
              <Typography>{formatLocation(workPermit.country)}</Typography>
              <Typography variant="caption" color="text.secondary">
                {dateRangeWithDash(
                  formatDate(workPermit.startDate),
                  formatDate(workPermit.expiredDate),
                )}
              </Typography>
            </Stack>

            {haveActions && (
              <Stack spacing={1} direction="row">
                {canRemove && (
                  <IconButton color="error" onClick={() => onRemove(index)}>
                    <IconX sx={{ fontSize: '20px' }} />
                  </IconButton>
                )}

                {canEdit && (
                  <EditWorkPermitIconButton
                    workPermit={workPermit}
                    onEdit={updatedWorkPermit => {
                      onEdit(updatedWorkPermit, index)
                    }}
                  />
                )}
              </Stack>
            )}
          </Box>
        )
      })}
    </Stack>
  )
}
