import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

const TNotificationCriticality = t.union([
  t.literal('HIGH'),
  t.literal('MEDIUM'),
  t.literal('LOW'),
])

export type NotificationCriticality = t.TypeOf<typeof TNotificationCriticality>

const TNotificationStatus = t.union([t.literal('READ'), t.literal('UNREAD')])

export type NotificationStatus = t.TypeOf<typeof TNotificationStatus>

export const TNotification = t.intersection([
  t.strict({
    notificationId: t.string,
    criticality: TNotificationCriticality,
    subject: t.string,
    message: t.string,
    read: t.boolean,
    type: t.string,
    createdAt: DateFromISOString,
  }),
  t.partial({
    /** Full URL */
    onclick: t.string,
  }),
])

export type Notification = t.TypeOf<typeof TNotification>

export const TNotificationsList = t.array(TNotification)

export type NotificationsList = t.TypeOf<typeof TNotificationsList>

export const TNotificationsCount = t.strict({
  count: t.number,
})
