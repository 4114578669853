import { useMutation } from '@tanstack/react-query'
import { createEmailTemplate } from 'api/email-templates'
import { useBoolean } from 'lib/react-utils'
import { showToast } from 'lib/toast'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'

import { EmailTemplateFormDialog } from './email-template-form-dialog'

type Props = Readonly<{
  onCreate: () => void
}>

export const CreateEmailTemplateButton = ({ onCreate }: Props) => {
  const { t } = useTranslation()

  const showTemplateCreatingDialog = useBoolean()

  const $createEmailTemplate = useMutation(createEmailTemplate)

  return (
    <>
      <Button onClick={showTemplateCreatingDialog.setTrue}>
        {t('common.add_template')}
      </Button>

      <EmailTemplateFormDialog
        title={t('common.create_email_template')}
        defaultValues={{
          name: '',
          emailSubject: '',
          emailBody: '',
        }}
        isOpen={showTemplateCreatingDialog.isTrue}
        onClose={showTemplateCreatingDialog.setFalse}
        onConfirm={({ values, resetForm }) =>
          $createEmailTemplate.mutate(values, {
            onSuccess: () => {
              showToast({ title: t('toasts.email_template_saved') })
              showTemplateCreatingDialog.setFalse()
              resetForm()
              onCreate()
            },
          })
        }
        confirmCancelingText={t(
          'confirms.confirm_cancel_creating_email_template',
        )}
      />
    </>
  )
}
