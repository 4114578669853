import { useRouteLeavingGuardControlContext } from 'lib/context'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { NavigateOptions, To } from 'react-router-dom'

/**
 * A custom hook that provides navigation functionality which temporarily bypasses the RouteLeavingGuard.
 *
 * This is especially useful for scenarios where navigation should be allowed without triggering
 * the guard's confirmation, such as when the user intentionally clicks on a button to navigate away.
 *
 * The RouteLeavingGuard is disabled just before navigation and re-enabled immediately after,
 * ensuring that the guard's functionality remains intact for other navigation scenarios.
 *
 * Example Usage:
 * const navigateWithoutGuard = useNavigateWithoutGuard();
 * navigateWithoutGuard("/target-route");
 */
export const useNavigateWithoutGuard = () => {
  const navigate = useNavigate()

  // Fetching the controls for the RouteLeavingGuard from the context.
  const { deactivateGuard, activateGuard } =
    useRouteLeavingGuardControlContext()

  return useCallback(
    (to: To, options?: NavigateOptions) => {
      // Temporarily deactivate the guard to allow navigation.
      deactivateGuard()

      // Using setTimeout to ensure the deactivation of the guard completes before navigation.
      setTimeout(() => {
        navigate(to, options)

        // Reactivate the guard after navigation.
        activateGuard()
      }, 0)
    },
    [navigate, deactivateGuard, activateGuard],
  )
}
