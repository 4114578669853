import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconX = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <g clipPath="url(#clip0_516_1813)">
      <path
        d="M5.23078 5.23078C5.30375 5.15763 5.39044 5.09959 5.48587 5.05999C5.58131 5.02038 5.68362 5 5.78695 5C5.89027 5 5.99258 5.02038 6.08802 5.05999C6.18346 5.09959 6.27014 5.15763 6.34311 5.23078L10.5002 9.38945L14.6573 5.23078C14.7303 5.15775 14.817 5.09981 14.9125 5.06028C15.0079 5.02076 15.1102 5.00041 15.2135 5.00041C15.3168 5.00041 15.419 5.02076 15.5145 5.06028C15.6099 5.09981 15.6966 5.15775 15.7696 5.23078C15.8427 5.30382 15.9006 5.39053 15.9401 5.48595C15.9797 5.58138 16 5.68366 16 5.78695C16 5.89024 15.9797 5.99251 15.9401 6.08794C15.9006 6.18337 15.8427 6.27008 15.7696 6.34311L11.611 10.5002L15.7696 14.6573C15.8427 14.7303 15.9006 14.817 15.9401 14.9125C15.9797 15.0079 16 15.1102 16 15.2135C16 15.3168 15.9797 15.419 15.9401 15.5145C15.9006 15.6099 15.8427 15.6966 15.7696 15.7696C15.6966 15.8427 15.6099 15.9006 15.5145 15.9401C15.419 15.9797 15.3168 16 15.2135 16C15.1102 16 15.0079 15.9797 14.9125 15.9401C14.817 15.9006 14.7303 15.8427 14.6573 15.7696L10.5002 11.611L6.34311 15.7696C6.27008 15.8427 6.18337 15.9006 6.08794 15.9401C5.99251 15.9797 5.89024 16 5.78695 16C5.68366 16 5.58138 15.9797 5.48595 15.9401C5.39053 15.9006 5.30382 15.8427 5.23078 15.7696C5.15775 15.6966 5.09981 15.6099 5.06028 15.5145C5.02076 15.419 5.00041 15.3168 5.00041 15.2135C5.00041 15.1102 5.02076 15.0079 5.06028 14.9125C5.09981 14.817 5.15775 14.7303 5.23078 14.6573L9.38945 10.5002L5.23078 6.34311C5.15763 6.27014 5.09959 6.18346 5.05999 6.08802C5.02038 5.99258 5 5.89027 5 5.78695C5 5.68362 5.02038 5.58131 5.05999 5.48587C5.09959 5.39044 5.15763 5.30375 5.23078 5.23078Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_516_1813">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
