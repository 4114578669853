import { ListPagination } from 'api'
import * as t from 'io-ts'
import { NumberFromString } from 'io-ts-types'

import { decodeHeaders } from './decode-headers'
import { decodeJson } from './decode-json'

/**
 * Represents a paginated list with total count and optional next page information.
 *
 * @template T - The type of data in the paginated list.
 */
export type JsonArrayWithTotal<T> = {
  rows: Array<T>
  /** Total amount of filtered items (or all of them if no filters applied) */
  count: number
  /** Total amount of all existing items (returned only for a couple of requests) */
  totalCount?: number
  nextPage?: number
}

/**
 * IO-ts type representing headers with count and total-count properties.
 */
export const THeaders = t.intersection([
  t.strict({ count: NumberFromString }),
  t.partial({ ['total-count']: NumberFromString }),
])

/**
 * A utility function to decode JSON responses with total count and pagination information.
 *
 * @template TCodec - The IO-ts codec for decoding the content of the paginated list.
 * @param {TCodec} codec - The codec for decoding the content of the paginated list.
 * @param {ListPagination} pagination - The pagination information.
 * @returns {Promise<JsonArrayWithTotal<t.TypeOf<TCodec>>>} - The decoded paginated list.
 */
export const decodeJsonWithTotal = <TCodec extends t.Mixed>(
  codec: TCodec,
  pagination: ListPagination,
) => {
  return async (
    response: Response,
  ): Promise<JsonArrayWithTotal<t.TypeOf<TCodec>>> => {
    const headers = decodeHeaders(THeaders)(response)
    const rows = await decodeJson(t.array(codec))(response)
    const count = headers.count
    const totalCount = headers['total-count']
    return {
      rows,
      count,
      totalCount,
      nextPage:
        count > pagination.page * pagination.pageSize + rows.length
          ? pagination.page + 1
          : undefined,
    }
  }
}
