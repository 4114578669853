import { toArray } from 'fp-ts/Record'

export const recordToOptions = <T extends string>(
  record: Record<T, any>,
  translate: (key: T) => string,
): Array<{
  value: T
  label: string
}> => {
  return toArray(record).map(([key]) => ({
    value: key,
    label: translate(key),
  }))
}
