import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material'
import { IconChevronDown } from 'assets/icons'
import { CSSProperties, ReactNode } from 'react'

export type Props = {
  children: ReactNode
  title: ReactNode
  expandIcon?: ReactNode
  defaultExpanded?: boolean
  disableGutters?: boolean
  justifySummaryToStart?: boolean
  fullWidth?: boolean
  expanded?: boolean
  isWithPadding?: boolean
  onChange?: (expanded: boolean) => void
  sx?: CSSProperties
}

export const Accordion = ({
  disableGutters = true,
  title,
  children,
  defaultExpanded,
  expandIcon,
  justifySummaryToStart,
  fullWidth,
  isWithPadding = true,
  expanded,
  onChange,
  sx,
}: Props) => {
  const width = sx?.width ?? (fullWidth ? '100%' : 'auto')

  return (
    <MuiAccordion
      disableGutters={disableGutters}
      sx={{
        ...sx,
        background: 'none',
        color: 'inherit',
        border: 'none',
        width,

        '&:before': {
          display: 'none',
        },
      }}
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={(_event, expanded) => onChange && onChange(expanded)}
    >
      <AccordionSummary
        sx={{
          padding: 0,
          py: isWithPadding ? 1 : 0,
          margin: 0,
          minHeight: 'auto',

          ...(justifySummaryToStart && {
            justifyContent: 'flex-start',
          }),

          '& .MuiAccordionSummary-content': {
            margin: 0,
            alignItems: 'center',

            ...(justifySummaryToStart && {
              flexGrow: 'initial',
            }),
          },
        }}
        expandIcon={
          expandIcon ?? (
            <IconChevronDown sx={{ color: 'text.primary', fontSize: '10px' }} />
          )
        }
      >
        {title}
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
    </MuiAccordion>
  )
}
