import * as Sentry from '@sentry/react'
import { env } from 'app/env'
import { isLeft } from 'fp-ts/lib/Either'
import { Mixed, TypeOf } from 'io-ts'
import { formatValidationErrors } from 'io-ts-reporters'

import { isRequestError, requestError } from './error-types'
import { getValidationMessage } from './get-validation-message'

/**
 * Decodes the JSON response using an IO-ts codec and handles decoding errors.
 *
 * @template TCodec - The IO-ts codec for decoding the response body.
 * @param {TCodec} codec - The codec for decoding the response body.
 * @param {Response} response - The HTTP response object.
 * @returns {Promise<TypeOf<TCodec>>} - The decoded response body.
 * @throws {RequestError} - An error that occurs during decoding or parsing the JSON response.
 */
export const decodeJson = <TCodec extends Mixed>(codec: TCodec) => {
  return async (response: Response): Promise<TypeOf<TCodec>> => {
    try {
      // Parse the JSON response
      const json = await response.json()

      // Decode the JSON using the provided codec
      const decodedJson = codec.decode(json)

      // Handle decoding errors
      if (isLeft(decodedJson)) {
        const errors = formatValidationErrors(decodedJson.left)
        const message = getValidationMessage(errors)

        // Log details and capture exception with Sentry
        Sentry.withScope(scope => {
          scope.setExtras({
            json,
            url: response.url,
          })

          const error = new Error(message)
          error.name = 'Failed to decode response body'

          Sentry.captureException(error)
        })
        ;(env.REACT_APP_ENV === 'staging' ||
          env.REACT_APP_ENV === 'development') &&
          console.log(
            'Failed to decode response body',
            requestError({
              type: 'decode_body',
              json,
              message,
            }),
          )
        // Throw a request error with details about the decoding failure
        throw requestError({
          type: 'decode_body',
          json,
          message,
        })
      }

      // Return the decoded JSON body if successful
      return decodedJson.right
    } catch (error) {
      // Handle request errors and parsing errors
      if (isRequestError(error)) {
        throw error
      }

      if (error instanceof Error) {
        // Throw a request error with details about the parsing failure
        throw requestError({
          error,
          response,
          type: 'parse_json',
        })
      }

      // This shouldn't happen, throw the original error
      throw error
    }
  }
}
