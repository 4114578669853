import * as t from 'io-ts'

export const TInvitationUser = t.strict({
  userId: t.string,
  fullName: t.string,
  countryCode: t.string,
  languageCode: t.string,
  timeZone: t.string,
  email: t.string,
})
