import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconArrowLeftBar = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.625 18.75C15.4593 18.75 15.3003 18.6842 15.1831 18.5669C15.0659 18.4497 15 18.2908 15 18.125V1.875C15 1.70924 15.0659 1.55027 15.1831 1.43306C15.3003 1.31585 15.4593 1.25 15.625 1.25C15.7908 1.25 15.9498 1.31585 16.067 1.43306C16.1842 1.55027 16.25 1.70924 16.25 1.875V18.125C16.25 18.2908 16.1842 18.4497 16.067 18.5669C15.9498 18.6842 15.7908 18.75 15.625 18.75ZM12.5 10C12.5 10.1658 12.4342 10.3247 12.317 10.4419C12.1998 10.5592 12.0408 10.625 11.875 10.625H4.63377L7.31752 13.3075C7.37563 13.3656 7.42172 13.4346 7.45317 13.5105C7.48462 13.5864 7.50081 13.6678 7.50081 13.75C7.50081 13.8322 7.48462 13.9136 7.45317 13.9895C7.42172 14.0654 7.37563 14.1344 7.31752 14.1925C7.25941 14.2506 7.19042 14.2967 7.1145 14.3282C7.03857 14.3596 6.9572 14.3758 6.87502 14.3758C6.79284 14.3758 6.71146 14.3596 6.63554 14.3282C6.55962 14.2967 6.49063 14.2506 6.43252 14.1925L2.68252 10.4425C2.62431 10.3844 2.57814 10.3155 2.54663 10.2395C2.51512 10.1636 2.4989 10.0822 2.4989 10C2.4989 9.91779 2.51512 9.83639 2.54663 9.76046C2.57814 9.68453 2.62431 9.61556 2.68252 9.5575L6.43252 5.8075C6.54988 5.69014 6.70905 5.62421 6.87502 5.62421C7.04099 5.62421 7.20016 5.69014 7.31752 5.8075C7.43488 5.92486 7.50081 6.08403 7.50081 6.25C7.50081 6.41597 7.43488 6.57514 7.31752 6.6925L4.63377 9.375H11.875C12.0408 9.375 12.1998 9.44085 12.317 9.55806C12.4342 9.67527 12.5 9.83424 12.5 10Z"
      fill="currentColor"
    />
  </SvgIcon>
)
