export type Format12Hours = `${number}:${number} ${'am' | 'pm'}`

export const format12HoursRegex: RegExp =
  /^(0?[1-9]|1[0-2]):([0-5]\d) (am|pm)$/i

export const isFormat12Hours = (
  timeText: string,
): timeText is Format12Hours => {
  return format12HoursRegex.test(timeText)
}
