import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconFileCheckOutline = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      d="M12.2102 8.83525C12.4299 8.61558 12.4299 8.25942 12.2102 8.03975C11.9906 7.82008 11.6344 7.82008 11.4148 8.03975L8.4375 11.017L7.14775 9.72725C6.92808 9.50758 6.57192 9.50758 6.35225 9.72725C6.13258 9.94692 6.13258 10.3031 6.35225 10.5227L8.03975 12.2102C8.14524 12.3157 8.28832 12.375 8.4375 12.375C8.58668 12.375 8.72976 12.3157 8.83525 12.2102L12.2102 8.83525Z"
      fill="currentColor"
    />
    <path
      d="M15.75 15.75V5.0625L10.6875 0H4.5C3.25736 0 2.25 1.00736 2.25 2.25V15.75C2.25 16.9926 3.25736 18 4.5 18H13.5C14.7426 18 15.75 16.9926 15.75 15.75ZM10.6875 3.375C10.6875 4.30698 11.443 5.0625 12.375 5.0625H14.625V15.75C14.625 16.3713 14.1213 16.875 13.5 16.875H4.5C3.87868 16.875 3.375 16.3713 3.375 15.75V2.25C3.375 1.62868 3.87868 1.125 4.5 1.125H10.6875V3.375Z"
      fill="currentColor"
    />
  </SvgIcon>
)
