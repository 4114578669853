import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconSubscription = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      d="M13.1188 7.83417H17.7013C17.7567 7.83419 17.8109 7.84999 17.8576 7.8797C17.9043 7.90942 17.9416 7.95183 17.9651 8.00197C17.9886 8.05211 17.9973 8.10789 17.9902 8.1628C17.9831 8.21771 17.9605 8.26946 17.9251 8.312L15.6338 11.0624C15.6065 11.0952 15.5723 11.1216 15.5336 11.1397C15.495 11.1578 15.4528 11.1672 15.4101 11.1672C15.3674 11.1672 15.3252 11.1578 15.2865 11.1397C15.2479 11.1216 15.2137 11.0952 15.1863 11.0624L12.8951 8.312C12.8596 8.26946 12.8371 8.21771 12.83 8.1628C12.8229 8.10789 12.8316 8.05211 12.8551 8.00197C12.8786 7.95183 12.9158 7.90942 12.9626 7.8797C13.0093 7.84999 13.0635 7.83419 13.1188 7.83417ZM0.299062 10.165H4.88155C4.93691 10.165 4.99112 10.1492 5.03783 10.1195C5.08454 10.0898 5.12182 10.0474 5.1453 9.99725C5.16878 9.94711 5.17749 9.89132 5.17041 9.83641C5.16332 9.78151 5.14075 9.72975 5.10531 9.68721L2.81407 6.93679C2.78673 6.904 2.75251 6.87762 2.71384 6.85952C2.67517 6.84141 2.633 6.83203 2.59031 6.83203C2.54761 6.83203 2.50544 6.84141 2.46677 6.85952C2.4281 6.87762 2.39389 6.904 2.36654 6.93679L0.0752986 9.68721C0.0398668 9.72975 0.017288 9.78151 0.0102062 9.83641C0.00312445 9.89132 0.0118328 9.94711 0.0353116 9.99725C0.0587905 10.0474 0.0960678 10.0898 0.142779 10.1195C0.189489 10.1492 0.2437 10.165 0.299062 10.165Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99988 3.17263C7.19112 3.17263 5.5735 3.99659 4.5048 5.29139C4.45708 5.35324 4.39741 5.40487 4.32933 5.44319C4.26126 5.48152 4.18617 5.50577 4.10854 5.5145C4.03091 5.52323 3.95231 5.51626 3.87742 5.494C3.80254 5.47175 3.73289 5.43466 3.67263 5.38495C3.61236 5.33523 3.56271 5.27391 3.52662 5.20462C3.49053 5.13534 3.46874 5.0595 3.46255 4.98162C3.45636 4.90375 3.46589 4.82542 3.49057 4.7513C3.51526 4.67718 3.5546 4.60878 3.60625 4.55017C4.45957 3.51704 5.59167 2.75075 6.86791 2.34244C8.14415 1.93412 9.51081 1.90096 10.8053 2.24691C12.0999 2.59285 13.2678 3.30335 14.1702 4.29387C15.0726 5.2844 15.6716 6.51329 15.8958 7.83436H14.7105C14.4414 6.51836 13.7262 5.33566 12.6856 4.48624C11.6451 3.63682 10.3431 3.17279 8.99988 3.17263ZM3.28925 10.1652C3.50988 11.2428 4.03088 12.236 4.79205 13.03C5.55323 13.8241 6.52352 14.3865 7.59083 14.6525C8.65813 14.9184 9.77891 14.877 10.8237 14.5329C11.8684 14.1889 12.7945 13.5563 13.495 12.7082C13.5427 12.6464 13.6024 12.5947 13.6704 12.5564C13.7385 12.5181 13.8136 12.4938 13.8912 12.4851C13.9689 12.4764 14.0474 12.4833 14.1223 12.5056C14.1972 12.5278 14.2669 12.5649 14.3271 12.6146C14.3874 12.6644 14.437 12.7257 14.4731 12.795C14.5092 12.8643 14.531 12.9401 14.5372 13.018C14.5434 13.0958 14.5339 13.1742 14.5092 13.2483C14.4845 13.3224 14.4452 13.3908 14.3935 13.4494C13.5402 14.4826 12.4081 15.2488 11.1319 15.6572C9.85561 16.0655 8.48895 16.0986 7.19442 15.7527C5.89988 15.4067 4.73195 14.6962 3.82953 13.7057C2.92712 12.7152 2.3282 11.4863 2.104 10.1652H3.28925Z"
      fill="currentColor"
    />
    <path
      d="M11.1801 10.1841C11.1801 8.95218 10.3118 8.59896 9.37262 8.40014L8.8748 8.29931C8.13865 8.15459 8.08185 7.94105 8.08185 7.65522C8.08185 7.2843 8.4161 7.06263 8.97602 7.06263C9.63909 7.06263 9.80899 7.39572 9.87948 7.64592L9.88619 7.66671C9.96959 7.88489 10.1634 8.01503 10.4052 8.01503C10.4678 8.01503 10.5241 8.00547 10.5662 7.99695C10.8257 7.94531 11.0064 7.73874 11.0064 7.49409C11.0064 7.43342 10.9954 7.37325 10.9734 7.31477C10.8404 6.88718 10.4668 6.21417 9.43149 6.06054V5.32723C9.43149 4.71644 8.47484 4.71644 8.47484 5.32723V6.06492C7.36274 6.23896 6.95323 6.9955 6.95323 7.65496C6.95323 8.85484 7.78878 9.18935 8.60497 9.3577L9.14346 9.47208C9.91033 9.62804 10.0455 9.82802 10.0455 10.1903C10.0455 10.633 9.67124 10.8969 9.04379 10.8969C8.22928 10.8969 8.06326 10.5301 7.95223 10.1184C7.88729 9.8905 7.68137 9.74371 7.42743 9.74371C7.37204 9.74371 7.32763 9.75107 7.27405 9.76024L7.25791 9.76334C6.99532 9.82569 6.81909 10.0325 6.81909 10.2777C6.81909 10.3243 6.82735 10.3639 6.83407 10.3959L6.84233 10.4326C6.96046 10.8319 7.22125 11.704 8.52196 11.8986V12.6661C8.52196 12.9717 8.76235 13.1316 9.00016 13.1316C9.23797 13.1316 9.47861 12.9717 9.47861 12.6661V11.912C10.5114 11.7775 11.1811 11.1176 11.1811 10.1842"
      fill="currentColor"
    />
  </SvgIcon>
)
