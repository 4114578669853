import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconMinus = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 2"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M1 1H15"
      stroke="currentColor"
      strokeWidth={props.stroke ?? '1.4'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
