import { Box } from '@mui/material'
import { TopMenu } from 'components/global/top-menu'

type Props = Readonly<{
  children: React.ReactNode
}>

export const AuthLayout = ({ children }: Props) => {
  return (
    <Box height="100vh" display="flex">
      <Box
        flex="auto"
        minWidth={0}
        display="flex"
        flexDirection="column"
        position="relative"
      >
        <TopMenu />

        <Box flex="1" minHeight={0} overflow="auto">
          {children}
        </Box>
      </Box>

      <Box id="portal-root" />
    </Box>
  )
}
