import { Box, Typography } from '@mui/material'
import { IconUploadCloudOutline } from 'assets/icons'
import dashedBorderGreenImage from 'assets/images/dashed-border-green.png'
import importFileCheckmarkImage from 'assets/images/import-file-checkmark.png'
import importFromFileImage from 'assets/images/import-from-file.png'
import { showToast } from 'lib/toast'
import { Accept, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'
import { RejectedFiles } from 'ui/inputs/drop-zone/rejected-files'

type Props = Readonly<{
  file: { name: string } | null
  onUpload: (files: File) => void
  onRemove: () => void
  /** Object with a common MIME type as keys and an array of file extensions as values */
  accept: Accept
  /** Maximum file size (in bytes) */
  maxSize: number
}>

export const ContactFileUpload = ({
  file,
  onUpload,
  onRemove,
  accept,
  maxSize,
}: Props) => {
  const { t } = useTranslation()

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    onDropAccepted: files => onUpload(files[0] as File),
    maxSize,
    onDropRejected: rejections => {
      showToast(
        {
          type: 'error',
          title: t('toasts.dropzone_files_rejected'),
          body: <RejectedFiles rejections={rejections} maxSize={maxSize} />,
        },
        { autoClose: false },
      )
    },
    accept,
    /** Maximum accepted number of files */
    maxFiles: 1,
  })

  const fileDetails = (
    <>
      <Typography color="text.secondary" mb={3}>
        {file?.name}
      </Typography>
      <Button
        variant="outlined"
        color="greyBlue"
        disableShadow
        sx={{ background: '#FFFFFF' }}
        onClick={onRemove}
      >
        {t('common.delete')}
      </Button>
    </>
  )

  const filePrompt = (
    <>
      <Typography variant="caption" color="text.secondary" sx={{ mb: 1 }}>
        {t('common.dropzone_accepted_format', {
          maxValue: 2,
        })}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        {isDragActive
          ? t('common.drop_here')
          : t('common.dropzone_drag_and_drop')}
      </Typography>

      {!isDragActive && (
        <Button onClick={open} startIcon={<IconUploadCloudOutline />}>
          {t('common.choose_file')}
        </Button>
      )}
    </>
  )

  return (
    <Box
      {...getRootProps()}
      sx={{
        width: '526px',
        height: '404px',
        flex: '1 0 auto',
        p: 2.5,
        bgcolor: isDragActive ? 'alabaster' : '#FFFFFF',
        backgroundImage: `url(${dashedBorderGreenImage})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: 0.5,
      }}
    >
      <input {...getInputProps()} />

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Box
          component="img"
          src={file ? importFileCheckmarkImage : importFromFileImage}
          maxWidth="194px"
          mb={1.5}
        />

        {file ? fileDetails : filePrompt}
      </Box>
    </Box>
  )
}
