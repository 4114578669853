import { Box, Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { qk } from 'api'
import { getEmailTemplateRequest } from 'api/email-templates'
import { IconCloseLarge } from 'assets/icons'
import DOMPurify from 'dompurify'
import { renderQueryResult } from 'lib/react-query-utils'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'ui/inputs/icon-button'

import { UpdateEmailTemplateIconButton } from './update-email-template-icon-button'

export type Props = {
  previewId: string
  onOpenDialog?: () => void
  onCloseDialog: () => void
  onUpdate: () => void
}

export const EmailTemplatePreview = ({
  previewId,
  onCloseDialog,
  onUpdate,
}: Props) => {
  const { t } = useTranslation()

  const $emailTemplate = useQuery(
    qk.emailTemplates.details.toKeyWithArgs({ templateId: previewId }),
    () => getEmailTemplateRequest({ templateId: previewId }),
  )

  return (
    <Box p={3}>
      {renderQueryResult($emailTemplate, {
        success: data => {
          return (
            <>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '16px',
                }}
              >
                <Typography variant="h2">
                  {t('common.template_validation')}
                </Typography>
                <Box display="flex" gap="8px">
                  <UpdateEmailTemplateIconButton
                    emailTemplate={data}
                    onUpdate={() => {
                      $emailTemplate.refetch()
                      onUpdate()
                    }}
                  />

                  <IconButton onClick={() => onCloseDialog()}>
                    <IconCloseLarge sx={{ fontSize: '16px' }} />
                  </IconButton>
                </Box>
              </Box>
              <Box>
                <Stack spacing="16px" marginBottom="32px">
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      {t('common.name')}:
                    </Typography>
                    <Typography variant="body1">{data.name}</Typography>
                  </Box>

                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      {t('common.subject')}:
                    </Typography>
                    <Typography variant="body1">{data.emailSubject}</Typography>
                  </Box>

                  <Typography variant="h2" marginBottom="16px">
                    {t('common.body')}
                  </Typography>

                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ wordWrap: 'break-word' }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(data.emailBody),
                    }}
                  />
                </Stack>
              </Box>
            </>
          )
        },
      })}
    </Box>
  )
}
