import { CSSObject } from '@mui/material'

/**
 * Nifty trick to create bold text transition without layout shift.
 * IMPORTANT: pass text value as data-text attribute
 */
export const boldOnHover: CSSObject = {
  display: 'inline-flex',
  flexDirection: 'column',

  ':hover': {
    fontWeight: 500,
  },

  '::after': {
    content: 'attr(data-text)',
    height: 0,
    visibility: 'hidden',
    overflow: 'hidden',
    fontWeight: 500,
  },
}

export const noWrap: CSSObject = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

export const arrowBottom: CSSObject = {
  '&::before': {
    content: '""',
    position: 'absolute',
    bottom: -6,
    left: '50%',
    width: 0,
    height: 0,
    border: '6px solid',
    borderColor: 'transparent transparent #fff #fff',
    transformOrigin: '0 0',
    transform: 'rotate(-45deg) translateX(-50%)',
    boxShadow: '-2px 2px 4px 0 rgba(0, 0, 0, 0.1)',
  },
  boxShadow:
    '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 2px 5px rgba(50, 50, 93, 0.25)',
}
