import { dash, formatMonthYear } from 'lib/js-utils'

export const getPeriod = (startDate?: Date | null, endDate?: Date | null) => {
  if (startDate && endDate) {
    return `${formatMonthYear(startDate)} ${dash} ${formatMonthYear(endDate)}`
  }

  if (startDate) {
    return 'Start date: ' + formatMonthYear(startDate)
  }

  if (endDate) {
    return 'End date: ' + formatMonthYear(endDate)
  }

  return null
}
