import { Skeleton } from '@mui/material'
import { range } from 'fp-ts/NonEmptyArray'

import { BadgeCollection } from './badge-collection'

type Props = {
  count?: number
}

export const BadgeSkeletons = ({ count = 5 }: Props) => {
  return (
    <BadgeCollection>
      {range(0, count - 1).map(index => (
        <Skeleton key={index} variant="rectangular" height={24} width={100} />
      ))}
    </BadgeCollection>
  )
}
