import { ToastContainerId } from 'app/enums'
import { toast, ToastOptions } from 'react-toastify'
import { ToastContent, ToastContentProps } from 'ui/feedback'

/**
 * Displays a toast message using the `react-toastify` library.
 *
 * @param {ToastContentProps} params - The properties of the toast content.
 * @param {Omit<ToastOptions, 'containerId'>} options - Additional options for the toast.
 * @returns {string | number} - The toast ID or the toast container ID.
 */
export const showToast = (
  { type = 'success', ...params }: ToastContentProps,
  options?: Omit<ToastOptions, 'containerId'> & {
    containerId?: ToastContainerId
  },
) => {
  // Display a toast of the specified type with the provided content and options
  return toast[type](<ToastContent {...params} type={type} />, {
    ...options,
    // Use the specified container ID or default to ToastContainerId.System
    containerId: (options && options.containerId) || ToastContainerId.System,
  })
}

/**
 * Displays a promise-based toast message using the `react-toastify` library.
 *
 * @param {Promise<unknown>} promise - The promise to track.
 * @param {string} title - The title for the toast.
 * @param {Omit<ToastOptions, 'containerId'>} options - Additional options for the toast.
 * @returns {string | number} - The toast ID or the toast container ID.
 */
export const showPromiseToast = (
  promise: Promise<unknown>,
  title: string,
  options?: Omit<ToastOptions, 'containerId'> & {
    containerId?: ToastContainerId
  },
) => {
  // Display a promise-based toast with pending state and the provided title
  return toast.promise(
    promise,
    {
      pending: {
        render: () => <ToastContent title={title} type="promise" />,
      },
    },
    {
      ...options,
      // Use the specified container ID or default to ToastContainerId.System
      containerId: (options && options.containerId) || ToastContainerId.System,
    },
  )
}
