import { alpha, Box } from '@mui/material'

type Props = Readonly<{
  checked?: boolean
  disabled?: boolean
  small?: boolean
  color?: string
}>

export const RadioIcon = ({ checked, disabled, small, color }: Props) => {
  return (
    <Box
      component="span"
      sx={{
        position: 'relative',
        width: small ? 16 : 24,
        height: small ? 16 : 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid',
        borderColor: 'mischka',
        bgcolor: disabled ? 'alabaster' : '#FFFFFF',
        borderRadius: '50%',
        boxShadow: `inset 0px 1px 4px ${alpha('#000000', 0.04)}`,

        ...(checked && {
          '&::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: small ? 9 : 14,
            height: small ? 9 : 14,
            borderRadius: '50%',
            backgroundColor: disabled
              ? 'greyBlue.light'
              : color ?? 'primary.main',
          },
        }),
      }}
    />
  )
}
