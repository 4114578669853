import { TJobPerformingStatuses, TJobStatusCode } from 'api/jobs'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

import { TReportRecord } from '../common'

export const TRecruiterJobsClosureInfos = t.array(
  t.strict({
    hiredContactQualificationLevelName: t.string,
    jobsCountByClosureStatus: t.array(
      t.union([
        t.strict({
          qualifier: t.literal('OVERDUE'),
          amount: t.number,
        }),
        t.strict({
          qualifier: t.literal('IN_TIME'),
          amount: t.number,
        }),
      ]),
    ),
  }),
)

export type RecruiterJobsClosureInfos = t.TypeOf<
  typeof TRecruiterJobsClosureInfos
>

export const TRecruiterJobsClosureReport = t.strict({
  totalJobsClosed: t.number,
  jobsClosureInfos: TRecruiterJobsClosureInfos,
})

export const TJobPipelineReports = t.array(
  t.intersection([
    t.strict({
      stageName: t.string,
      totalContactsAmount: t.number,
      contactsAppliedAmount: t.number,
      contactsRejectedAmount: t.number,
      rejectReasonToUsageAmount: TReportRecord,
    }),
    t.partial({
      hiredContactName: t.string,
      hiredContactImage: t.string,
    }),
  ]),
)

export type JobPipelineReports = t.TypeOf<typeof TJobPipelineReports>

export const TRecruiterJobPerformanceReport = t.array(
  t.intersection([
    t.strict({
      jobId: t.string,
      jobName: t.string,
      jobStatus: TJobStatusCode,
      departmentName: t.string,
      targetDate: DateFromISOString,
      jobPerforming: TJobPerformingStatuses,
      jobPipelineReport: t.strict({
        pipelineName: t.string,
        jobPipelineStepReports: TJobPipelineReports,
      }),
    }),
    t.partial({
      projectName: t.string,
      hiringManagerName: t.string,
    }),
  ]),
)

export const TJobsAmountByStatusReport = t.array(
  t.intersection([
    t.strict({
      qualifier: TJobStatusCode,
      amount: t.number,
    }),
    t.partial({
      percentage: t.number,
    }),
  ]),
)
