import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconArrowRight = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 10C5 9.65482 5.27982 9.375 5.625 9.375H12.8661L10.1831 6.69194C9.93898 6.44786 9.93898 6.05214 10.1831 5.80806C10.4271 5.56398 10.8229 5.56398 11.0669 5.80806L14.8169 9.55806C15.061 9.80213 15.061 10.1979 14.8169 10.4419L11.0669 14.1919C10.8229 14.436 10.4271 14.436 10.1831 14.1919C9.93898 13.9479 9.93898 13.5521 10.1831 13.3081L12.8661 10.625H5.625C5.27982 10.625 5 10.3452 5 10Z"
      fill="currentColor"
    />
  </SvgIcon>
)
